import React from 'react';

import Pagination from 'UI/display/Table/pagination';

import useManageHistory from 'Hooks/useManageHistory';

type TTablePaginationProps = {
	count: number;
	loading: boolean;
};

const TablePagination = (props: TTablePaginationProps) => {
	const { paramsFromURL: searchParams, historyPush } = useManageHistory();
	const pageInput = searchParams.pageInput;
	const { count, loading } = props;

	const handleChangePage = (_: unknown, newPage: number) => {
		const newSearchParams = {
			...searchParams,
			pageInput: {
				...pageInput,
				offset: newPage * pageInput.limit,
			},
		};

		historyPush(newSearchParams);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newSearchParams = {
			...searchParams,
			pageInput: {
				...pageInput,
				limit: Number(event.target.value),
			},
		};

		historyPush(newSearchParams);
	};

	return (
		<Pagination
			rowsPerPageOptions={[5, 10, 25, 100]}
			count={count}
			rowsPerPage={pageInput.limit || 10}
			page={loading ? 0 : Math.floor(pageInput.offset / pageInput.limit)}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
		/>
	);
};

export default TablePagination;
