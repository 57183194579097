import { TJobTreatment } from './isJobTreatment';

function jobActiveIngredient(
	treatment?: Pick<TJobTreatment, 'originalProduct'>
) {
	const originalProductIngredients = treatment?.originalProduct?.ingredients;

	return originalProductIngredients?.length
		? originalProductIngredients.map((ingredient) => ingredient.name).join(', ')
		: '-';
}

export default jobActiveIngredient;
