import isShape from 'vet/objects/isShape';
import isString from 'vet/strings/isString';

import { TImage } from '../image/isImage';

const _isCause = isShape({
	id: isString,
	name: isString,
	causeType: isString,
});

// https://github.com/microsoft/TypeScript/issues/34596
const isCause: typeof _isCause = _isCause;

export default isCause;

const CauseDiscriminator = {
	LOCATION: 'LOCATION',
	ACCOUNT: 'ACCOUNT',
	GOVERNMENT: 'GOVERNMENT',
} as const;

export const CauseType = {
	WEEDS: 'WEEDS',
	DISEASE: 'DISEASE',
	PEST: 'PEST',
	THINNING: 'THINNING',
	NUTRITION: 'NUTRITION',
	OTHER: 'OTHER',
} as const;

export type TCauseDiscriminator = keyof typeof CauseDiscriminator;
export type TCauseType = keyof typeof CauseType;

type SpanishCauseSpec = {
	scientificName?: string | null;
	siexCode?: number | null;
	eppoCode?: string | null;
};

export type TCause = {
	id: string;
	name: string;
	createdAt: string;
	discriminator: TCauseDiscriminator;
	causeType: TCauseType;
	description?: string | null;
	spanishSpec?: SpanishCauseSpec | null;
	causeImages?: TImage[] | null;
};

export type TCauseInput = Pick<TCause, 'description' | 'name'> & {
	type: TCause['causeType'];
};
