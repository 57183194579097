import { gql, TypedDocumentNode } from '@apollo/client';
import { TField } from 'Models/fields/isField';

export type TMemberDashboardFieldsResponse = {
	getFields: {
		fields: Pick<
			TField,
			| 'id'
			| 'name'
			| 'geometry'
			| 'varieties'
			| 'areaSize'
			| 'reentryAllowedDate'
			| 'harvestAllowedDate'
		>[];
	};
};

type TMemberDashboardFieldsRequest = {
	locationId: string;
};

const GET_MEMBER_DASHBOARD_FIELDS: TypedDocumentNode<
	TMemberDashboardFieldsResponse,
	TMemberDashboardFieldsRequest
> = gql`
	query getMemberDashboardFields($locationId: ID!) {
		getFields(locationId: $locationId) {
			fields {
				id
				name
				geometry {
					x
					y
				}
				varieties {
					identifier
					name
					category
					percentage
					sowDate
				}
				areaSize
				reentryAllowedDate
				harvestAllowedDate
			}
		}
	}
`;

export default GET_MEMBER_DASHBOARD_FIELDS;
