import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Permissions from 'Models/permissions/Permissions';
import Roles from 'Models/permissions/Roles';
import Box from 'UI/layout/Box';
import Breakpoints from 'UI/theme/breakpoints';
import useBreakpoint from 'UI/utils/useBreakpoint';

import usePermission from 'Hooks/usePermission';
import getFeatureFlag from 'Utils/getFeatureFlag';

import Template from '../../../../../components/DashboardPageTemplate';
import ProfitChart from '../../../common/components/ProfitChart';
import RecentEvents from '../../../common/components/RecentEvents';
import SearchMap from '../../../common/components/SearchMap';
import CropSummary from '../CropSummary';
import TemperatureChartSection from '../TemperatureChartSection';
import Breadcrumbs from './../../../common/components/Breadcrumbs';

const PageBody = () => {
	const { t } = useTranslation();
	const hasEnabledGDDChart = getFeatureFlag('DASHBOARD_GDD_CHART');
	const hasProfitChartPermissions = usePermission(
		Permissions.DASHBOARD_PAGE_PROFIT_CHART_ACCESS
	);
	const isMember = usePermission(Permissions[Roles.PA_MEMBER]);
	const isTablet = useBreakpoint(Breakpoints.TABLET);

	const getGDDChart = function (width: 4 | 6 | 8 | 12): ReactElement {
		return (
			<Template.Card
				size={width}
				title={t('farmDashboardPage.temperatureChart.title')}
			>
				<TemperatureChartSection />
			</Template.Card>
		);
	};

	const getProfitChart = function (width: 4 | 6 | 8 | 12) {
		return hasProfitChartPermissions ? (
			<Template.Card size={width} noPadding noHeaderBorder>
				<Box height="28.75rem" data-testid="profit-chart">
					<ProfitChart />
				</Box>
			</Template.Card>
		) : null;
	};

	const recentEvents = (
		<Template.Card
			size={isTablet ? 6 : 4}
			title={t('farmDashboardPage.recentEvents.title')}
			noPadding
		>
			<RecentEvents />
		</Template.Card>
	);

	// Compose layout depending on which features are available
	const getBottomSection = function () {
		if (hasEnabledGDDChart) {
			return (
				<>
					{getGDDChart(isTablet ? 6 : 8)}
					{!isMember && recentEvents}
					{getProfitChart(12)}
				</>
			);
		} else {
			return (
				<>
					{getProfitChart(isTablet ? 12 : 8)}
					{!isMember && recentEvents}
				</>
			);
		}
	};

	return (
		<Template>
			<Template.Navigation>
				<Breadcrumbs />
			</Template.Navigation>
			<Template.Board>
				<Template.Card
					size={isTablet ? 6 : 4}
					title={t('farmDashboardPage.summarySection.title')}
					noHeaderBorder
					noPadding
				>
					<CropSummary />
				</Template.Card>
				<Template.Card size={isTablet ? 6 : 8} noPadding noHeaderBorder>
					<SearchMap />
				</Template.Card>
				{getBottomSection()}
			</Template.Board>
		</Template>
	);
};

export default PageBody;
