import { TAggregatedHarvestBatch } from 'Models/harvestBatch/isAggregatedHarvestBatch';
import { SalesVolumeUnits } from 'Models/units';
import convertWeight from 'Models/units/convertWeight';
import round from 'Models/units/round';
import { toDisplayString } from 'Models/units/transpileUnits';

export type TSalesStats = Pick<
	TAggregatedHarvestBatch,
	'totalVolume' | 'soldVolume' | 'wasteVolume'
>;

type TParsedSalesStats = {
	harvestedAmount: string;
	storageAmount: string;
	soldAmount: string;
};

function getParsedSalesStats(
	queryResult: TSalesStats[],
	isImperial?: boolean
): TParsedSalesStats {
	if (queryResult.length) {
		const { totalVolume, soldVolume, wasteVolume } = queryResult[0];
		const unit = isImperial
			? SalesVolumeUnits.THOUSANDS_OF_POUNDS
			: SalesVolumeUnits.TON;
		const unitShortHand = toDisplayString(unit);
		const formatter = (value: number) => round(value, 1);

		return {
			harvestedAmount: `${convertWeight(
				totalVolume,
				SalesVolumeUnits.KILOGRAM,
				unit,
				formatter
			)} ${unitShortHand}`,
			storageAmount: `${convertWeight(
				totalVolume - soldVolume - wasteVolume,
				SalesVolumeUnits.KILOGRAM,
				unit,
				formatter
			)} ${unitShortHand}`,
			soldAmount: `${convertWeight(
				soldVolume,
				SalesVolumeUnits.KILOGRAM,
				unit,
				formatter
			)} ${unitShortHand}`,
		};
	} else {
		const EMPTY_ITEM_VALUE = '-';

		return {
			harvestedAmount: EMPTY_ITEM_VALUE,
			storageAmount: EMPTY_ITEM_VALUE,
			soldAmount: EMPTY_ITEM_VALUE,
		};
	}
}

export default getParsedSalesStats;
