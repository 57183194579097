import { gql, TypedDocumentNode } from '@apollo/client';
import { TSerializedFieldInput } from 'Models/fields/isFieldInput';

export type TCreateFieldResponse = {
	createField: {
		id: string;
	};
};

type TRequest = { locationId: string; inputs: TSerializedFieldInput[] };

export const CREATE_FIELDS_MUTATION: TypedDocumentNode<
	TCreateFieldResponse,
	TRequest
> = gql`
	mutation CreateFields($locationId: ID!, $inputs: [FieldInput!]!) {
		createFields(locationId: $locationId, inputs: $inputs) {
			id
		}
	}
`;
