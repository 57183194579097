import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	ProductionMethod,
	TProductionMethod,
} from 'Models/fields/isProductionMethod';
import Text from 'UI/display/Text';
import Select, { MenuItem } from 'UI/inputs/Select';

import styles from './styles.module.scss';

type TProductionMethodProps = {
	productionMethod?: TProductionMethod | null;
	onChange?: (value?: ProductionMethod | null | undefined) => void;
};

const ProductionMethodSelect = (props: TProductionMethodProps) => {
	const { productionMethod, onChange } = props;
	const [selected, setSelected] = useState(productionMethod);
	const { t } = useTranslation();

	function handleOnChange(productionmethod) {
		setSelected(productionmethod);
		onChange?.(productionmethod);
	}

	const options = Object.keys(ProductionMethod).map((method) => (
		<MenuItem key={method} value={method}>
			<Text variant="label4">
				{t(`productionMethods.${method as TProductionMethod}`)}
			</Text>
		</MenuItem>
	));

	return (
		<Select
			value={selected || ''}
			changeHandler={handleOnChange}
			label={t('labels.productionMethod')}
			MUIFormControlProps={{
				className: styles.resetLabelMargin,
			}}
		>
			{options}
		</Select>
	);
};

export default ProductionMethodSelect;
