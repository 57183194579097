import React from 'react';

import { useQuery } from '@apollo/client';
import JobTypes from 'Models/jobs/JobTypes';
import Divider from 'UI/display/Divider';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import useSpanishPartner from 'Hooks/useSpanishPartner';

import GET_GOVERNMENT_CAUSES from '../../api/getGovernmentCauses';
import GET_JUSTIFICATIONS from '../../api/getJustifications';
import GET_PARTNERSHIP_CAUSES from '../../api/getPartnershipCauses';
import { useJobInputData } from '../../state';
import AddCauses from './components/AddCauses';
import AddSpainJustifications from './components/AddSpainJustifications';
import AddTreatments from './components/AddTreatments';

const CauseAndTreatments = () => {
	const { company } = usePartnerCompany();
	const jobInput = useJobInputData();
	const { isSpanishPartner } = useSpanishPartner();

	const isSprayingSelected = jobInput.jobDetails.jobType === JobTypes.SPRAYING;

	const { loading: loadingGovCauses } = useQuery(GET_GOVERNMENT_CAUSES, {
		variables: company?.country
			? {
					filterBy: {
						country: company?.country,
					},
			  }
			: undefined,
		skip: !isSprayingSelected || !company?.country,
	});

	const { loading: loadingPartnerCauses } = useQuery(GET_PARTNERSHIP_CAUSES, {
		skip: !isSprayingSelected,
	});

	const { loading: loadingJustifications } = useQuery(GET_JUSTIFICATIONS, {
		skip: !(isSpanishPartner && isSprayingSelected),
	});

	const isLoading = isSprayingSelected
		? loadingGovCauses || loadingPartnerCauses || loadingJustifications
		: false;

	if (isLoading) {
		return (
			<Box
				display="flex"
				width="100%"
				justifyContent="center"
				alignItems="center"
				overflow="hidden"
			>
				<LoadingSpinner width={80} height={80} />
			</Box>
		);
	}

	return isSprayingSelected ? (
		<div>
			<AddCauses />
			<Divider />
			<AddTreatments />
			{isSpanishPartner ? (
				<>
					<Divider />
					<AddSpainJustifications />
				</>
			) : null}
		</div>
	) : (
		<div>
			<AddTreatments />
		</div>
	);
};

export default CauseAndTreatments;
