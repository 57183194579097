import { ApolloError, useQuery } from '@apollo/client';

import useLocationID from 'Hooks/useLocationID';
import useManageHistory from 'Hooks/useManageHistory';

import GET_VOLUME_CHART_DATA from '../api/getVolumeChartData';

const useVolumeChartData = (year: number) => {
	const locationId = useLocationID();
	const { paramsFromURL } = useManageHistory();
	const { data, loading, error } = useQuery(GET_VOLUME_CHART_DATA, {
		variables: {
			locationId,
			query: {
				filterBy: {
					dateFrom: `${year}-01-01`,
					dateTo: `${year}-12-31`,
					varieties: paramsFromURL.filters.fieldVarieties,
				},
				groupBy: 'DATE',
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	return { data, loading };
};

export default useVolumeChartData;
