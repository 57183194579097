import { TRoles } from 'Models/permissions/Roles';

import getCompanyIdFromUrl from 'Utils/getCompanyIdFromURL';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';
import {
	isLocationRole,
	isPartnershipCompanyRole,
} from 'Utils/hasPartnerCompany';

import useProfileRoles from './useProfileRoles';

const usePermission = (permission: TRoles[], roles?: TRoles[]): boolean => {
	const { profileRoles } = useProfileRoles();
	const locationId = getLocationIdFromUrl();
	const companyId = getCompanyIdFromUrl();

	if (locationId) {
		const farmRoles =
			roles ||
			profileRoles
				.filter(
					(profileRole) =>
						isLocationRole(profileRole) && profileRole.locationId === locationId
				)
				.map((profileRole) => profileRole.role);

		return !!farmRoles.find((role) => permission.includes(role));
	}

	if (companyId) {
		const role = profileRoles.find(
			(profileRole) =>
				isPartnershipCompanyRole(profileRole) &&
				profileRole.companyId === companyId
		)?.role;

		return !!role && permission.includes(role);
	}

	return false;
};

export default usePermission;
