import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TSupportedJobs, SUPPORTED_JOB_TYPES } from 'Models/jobs/JobInput';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useManageHistory from 'Hooks/useManageHistory';
import getPartnerVarietyFromURL from 'Utils/getPartnerVarietyFromURL';

import AddJobModal from './modals/AddJobModal';

const AddJobDropdown = () => {
	const { t } = useTranslation();
	const { paramsFromURL } = useManageHistory();

	const variety = getPartnerVarietyFromURL(paramsFromURL);
	const [jobType, setJobType] = useState<TSupportedJobs | null>(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isModalOpen, setModalOpen] = useState(false);

	const isMenuOpen = Boolean(anchorEl);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const handleModalOpen = (jobType: TSupportedJobs) => {
		setJobType(jobType);
		setModalOpen(true);
		handleClose();
	};
	const toggleModalOpen = () => setModalOpen(!isModalOpen);

	return (
		<>
			<Button
				variant="secondary"
				color="primary"
				disabled={!variety}
				startIcon={<AddIcon />}
				onClick={handleClick}
			>
				{t('buttons.addJob')}
			</Button>
			<Menu.Menu
				id="job-type-select"
				open={isMenuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				narrow
			>
				{SUPPORTED_JOB_TYPES.map((jobType) => (
					<Menu.Item key={jobType} onClick={() => handleModalOpen(jobType)}>
						{t(`common.jobs.types.${jobType}`)}
					</Menu.Item>
				))}
			</Menu.Menu>
			<AddJobModal
				jobType={jobType}
				isOpen={isModalOpen}
				toggleHandler={toggleModalOpen}
			/>
		</>
	);
};

export default AddJobDropdown;
