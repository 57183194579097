import { ReactNode } from 'react';

const PARTNER_HOURS_LOGS_TABLE_HEADERS = [
	'date',
	'location',
	'start',
	'stop',
	'duration',
	'team',
	'who',
	'task',
	'fields',
] as const;

const PartnerHoursLogsTableHeaderValues = [...PARTNER_HOURS_LOGS_TABLE_HEADERS];

export type TPartnerHoursLogsTableHeader =
	typeof PARTNER_HOURS_LOGS_TABLE_HEADERS[number];

export default PartnerHoursLogsTableHeaderValues;

export type THoursLogsTableData = {
	[key in typeof PARTNER_HOURS_LOGS_TABLE_HEADERS[number]]: ReactNode;
};
