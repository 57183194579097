import isShape from 'vet/objects/isShape';
import isString from 'vet/strings/isString';

export enum RunStatus {
	NEW = 'NEW',
	RUNNING = 'RUNNING',
	PAUSED = 'PAUSED',
	COMPLETED = 'COMPLETED',
}

const _isRunStatusLog = isShape({
	runId: isString,
	status: isString,
	timestamp: isString,
});

const isRunStatusLog: typeof _isRunStatusLog = _isRunStatusLog;

export default isRunStatusLog;

export type TRunStatusLog = {
	runId: string;
	timestamp: string;
	status: keyof typeof RunStatus;
};
