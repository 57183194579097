import { gql, TypedDocumentNode } from '@apollo/client';
import { TTeam } from 'Models/teams/isTeam';

export type TDeleteTeamResult = TTeam;

const DELETE_TEAM: TypedDocumentNode<
	{
		deleteTeam: Pick<TTeam, 'id'>;
	},
	{
		locationId: string;
		teamId: string;
	}
> = gql`
	mutation deleteTeam($locationId: ID!, $teamId: ID!) {
		deleteTeam(locationId: $locationId, teamId: $teamId) {
			id
		}
	}
`;

export default DELETE_TEAM;
