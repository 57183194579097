// As the north can be specified as the 0, and 360 degrees on a compass,
// we need to have two data bins for it.
const SECTORS = [
	'N',
	'N-NE',
	'NE',
	'E-NE',
	'E',
	'E-SE',
	'SE',
	'S-SE',
	'S',
	'S-SW',
	'SW',
	'W-SW',
	'W',
	'W-NW',
	'NW',
	'N-NW',
	'N',
];

function getCompassDirection(
	degrees: number,
	use16PointsPrecision?: boolean
): string {
	const range = use16PointsPrecision ? 22.5 : 45;

	// Avoid situations when we would receive degrees value
	// higher than 360. Start counting from 0 after achieving 360.
	const direction = degrees % 360;
	const index = Math.round(direction / range);

	return use16PointsPrecision
		? SECTORS[index]
		: SECTORS.filter((d) => d.length < 3)[index];
}

export default getCompassDirection;
