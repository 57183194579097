import { AreaUnits, TAreaUnits } from './index';
import roundToTwoDecimal from './roundToTwoDecimal';

function convertAreaUnit(area: number, areaUnit?: TAreaUnits | null): number {
	switch (areaUnit) {
		case AreaUnits.SQUARE_METERS:
			return Math.round(area);
		case AreaUnits.ACRES:
			return roundToTwoDecimal(area / 4047);
		case AreaUnits.DECARE:
			return roundToTwoDecimal(area / 1000);
		case AreaUnits.HECTARE:
			return roundToTwoDecimal(area / 10000);
		default:
			return Math.round(area);
	}
}

export default convertAreaUnit;
