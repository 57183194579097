import isArrayOf from 'vet/arrays/isArrayOf';
import isBoolean from 'vet/booleans/isBoolean';
import isOneOf from 'vet/isOneOf';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import { ExtendedValidator } from 'vet/types';
import ValidatorType from 'vet/types/ValidatorType';

import { Brand } from '../customTypes/Brand';

const SubscriptionType = {
	TREATMENT_REPORT: 'TREATMENT_REPORT',
	TIMESHEET: 'TIMESHEET',
	SAFE_SPRAYING: 'SAFE_SPRAYING',
	SALE: 'SALE',
	CUADERNO_DIGITAL: 'CUADERNO_DIGITAL',
	FARMABLE_PRO: 'FARMABLE_PRO',
	PRODUCT_INVENTORY: 'PRODUCT_INVENTORY',
} as const;

export type UNKNOWN_FEATURE_FLAG = Brand<string, 'unknown subscription'>;

const _isSubscriptionType = isOneOf(
	SubscriptionType.TREATMENT_REPORT,
	SubscriptionType.TIMESHEET,
	SubscriptionType.SAFE_SPRAYING,
	SubscriptionType.SALE,
	SubscriptionType.CUADERNO_DIGITAL,
	SubscriptionType.FARMABLE_PRO,
	SubscriptionType.PRODUCT_INVENTORY,
	isString as ExtendedValidator<UNKNOWN_FEATURE_FLAG>
);

const _isFeatureFlag = isShape({
	name: _isSubscriptionType,
	active: isBoolean,
});

const _isLocationSubscriptions = isShape({
	id: isString,
	featureFlags: optional(isArrayOf(_isFeatureFlag)),
});

// https://github.com/microsoft/TypeScript/issues/34596
const isLocationSubscriptions: typeof _isLocationSubscriptions =
	_isLocationSubscriptions;
const isFeatureFlag: typeof _isFeatureFlag = _isFeatureFlag;
const isSubscriptionType: typeof _isSubscriptionType = _isSubscriptionType;

export type TLocationSubscriptions = ValidatorType<
	typeof isLocationSubscriptions
>;
export type TSubscriptionType = ValidatorType<typeof isSubscriptionType>;
export type TFeatureFlag = ValidatorType<typeof isFeatureFlag>;

export default isLocationSubscriptions;

export { SubscriptionType, isSubscriptionType };
