import React, { ReactElement, ReactNode, Fragment } from 'react';

import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Container from 'UI/layout/Container';
import Grid from 'UI/layout/Grid';
import Breakpoints from 'UI/theme/breakpoints';
import useBreakpoint from 'UI/utils/useBreakpoint';

type Props = {
	children: ReactNode;
	header: string | ReactElement;
	subheader?: string;
};

const StorePageTemplate = (props: Props): ReactElement => {
	const { children, header, subheader } = props;

	const isMobile = useBreakpoint(Breakpoints.MOBILE);

	return (
		<Fragment>
			<Box overflow="auto">
				<Container maxWidth={'sm'}>
					<Box padding={isMobile ? '1rem 0' : '2.5rem'}>
						<Grid
							container
							direction="column"
							justifyContent="flex-start"
							alignItems={isMobile ? 'left' : 'center'}
						>
							<Text variant="h2" component="h1">
								{header}
							</Text>
							<Box marginTop={1}>
								{subheader ? (
									<Text
										variant={isMobile ? 'body2' : 'body1'}
										color="textSecondary"
									>
										{subheader}
									</Text>
								) : null}
							</Box>
							<Box marginTop={isMobile ? 0 : 3}>{children}</Box>
						</Grid>
					</Box>
				</Container>
			</Box>
		</Fragment>
	);
};

export default StorePageTemplate;
