import { ApolloError, useQuery } from '@apollo/client';
import { gql, TypedDocumentNode } from '@apollo/client';
import { TImage } from 'Models/image/isImage';
import { TPartnershipCompany } from 'Models/partnershipCompany/isPartnershipCompany';

import hasPartnerCompany from 'Utils/hasPartnerCompany';

import useProfileRoles from './useProfileRoles';

export type TPartnershipCompanyResponse = {
	partnershipCompany:
		| (Pick<
				TPartnershipCompany,
				| 'id'
				| 'name'
				| 'type'
				| 'country'
				| 'city'
				| 'address'
				| 'zip'
				| 'email'
				| 'mobilePhone'
				| 'createdAt'
		  > & {
				image: Pick<TImage, 'id' | 'path'>;
		  })
		| null;
};

const GET_COMPANY_PROFILE: TypedDocumentNode<
	TPartnershipCompanyResponse,
	null
> = gql`
	query partnershipCompany {
		partnershipCompany {
			id
			name
			type
			country
			city
			address
			zip
			email
			mobilePhone
			image {
				id
				path
			}
			createdAt
		}
	}
`;

function usePartnerCompany() {
	const { profileRoles } = useProfileRoles();
	const { data, loading, error } = useQuery(GET_COMPANY_PROFILE, {
		skip: !hasPartnerCompany(profileRoles),
	});

	if (error) {
		throw new ApolloError(error);
	}

	return {
		company: data?.partnershipCompany || null,
		loading,
	};
}

export default usePartnerCompany;
