import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { ExportType, TExportType } from 'Models/exports/ExportType';
import { TLocation } from 'Models/locations/isLocation';
import { LogTypes } from 'Models/logs/LogType';
import format from 'date-fns/format';
import { utils, writeFile } from 'xlsx';

import truncateString from '../../../../utils/truncateString';
import getHarvestExportData from '../../location/harvest/utils/getExportData';
import {
	getTeamsColumnsMerges,
	getTeamsHeaderColumnsMerges,
} from '../../location/harvest/utils/harvestMerges';
import getHoursExportData from '../../location/hours/utils/getExportData';
import {
	getHeaderColumnsMerges,
	getProductColumnsMerges,
} from '../../location/jobs/utils/jobMerges';

// s: start, e:end, r: rows, c: columns
export type TMerge = {
	s: {
		r: number;
		c: number;
	};
	e: {
		r: number;
		c: number;
	};
};

type TJobsExportSetup = {
	logType: typeof LogTypes.Jobs;
	exportData: {
		exportData: Record<string, string | number>[];
		additionalProductsMerges?: TMerge[];
	};
	location: TLocation;
	fileType: TExportType;
	userID: string;
	totalColumns: number;
};

type THoursExportSetup = {
	logType: typeof LogTypes.Hours;
	exportData: ReturnType<typeof getHoursExportData>;
	location: TLocation;
	fileType: TExportType;
	userID: string;
};

type THarvestExportSetup = {
	logType: typeof LogTypes.Harvest;
	exportData: ReturnType<typeof getHarvestExportData>;
	location: TLocation;
	fileType: TExportType;
	userID: string;
	hasTimesheetsSubscription?: boolean;
};

type TExportFunctionSetup =
	| TJobsExportSetup
	| THoursExportSetup
	| THarvestExportSetup;

const MAX_REPORT_NAME_LENGTH = 30;

export function handleExport(setup: TExportFunctionSetup): void {
	const { logType, exportData, location, fileType, userID } = setup;

	const reportDate = format(new Date(), 'yyyy-MM-dd');
	const reportName = truncateString(
		`${location.name} - ${reportDate}`,
		MAX_REPORT_NAME_LENGTH
	);
	const tabName = reportName;
	const isCSV = fileType === ExportType.CSV;
	const data = exportData.exportData;
	const workSheet = utils.json_to_sheet(data);

	if (setup.logType === LogTypes.Jobs) {
		const headerColumnsMerges = getHeaderColumnsMerges(setup.totalColumns);
		const productColumnsMerges = getProductColumnsMerges();
		const additionalProductsMerges = setup.exportData.additionalProductsMerges;

		if (!isCSV && additionalProductsMerges) {
			workSheet['!merges'] = [
				...productColumnsMerges,
				...headerColumnsMerges,
				...additionalProductsMerges,
			];
		}
	}

	if (setup.logType === LogTypes.Harvest) {
		const headerColumnsMerges = getTeamsHeaderColumnsMerges();
		const teamsColumnsMerges = getTeamsColumnsMerges();

		if (!isCSV && setup.hasTimesheetsSubscription) {
			workSheet['!merges'] = [...teamsColumnsMerges, ...headerColumnsMerges];
		}
	}

	const workBook = utils.book_new();
	utils.book_append_sheet(workBook, workSheet, tabName);
	writeFile(workBook, `${reportName}.${fileType.toLowerCase()}`);
	analyticsTrack(TrackEvents.DataDownloaded, {
		userId: userID,
		groupId: location.id,
		exportType: fileType,
		logType: logType,
	});
}

export default handleExport;
