import React, { ReactElement } from 'react';

import Link from 'Features/router/components/Link';
import List from 'UI/display/List';
import { TSvgIconComponent, TSvgIconProps } from 'UI/icons';
import MenuItem from 'UI/navigation/Menu/item';

type DropdownPageItemProps = {
	icon: TSvgIconComponent | ((props: TSvgIconProps) => ReactElement);
	label: string;
	link?: string;
	onClick?: () => void;
};

const DropdownPageItem = (props: DropdownPageItemProps): ReactElement => {
	const { link } = props;

	const content = (
		<MenuItem onClick={!link ? props.onClick : undefined}>
			<List.ItemIcon>
				<props.icon />
			</List.ItemIcon>
			<List.ItemText>{props.label}</List.ItemText>
		</MenuItem>
	);

	if (link) {
		return (
			<Link to={link} onClick={props.onClick}>
				{content}
			</Link>
		);
	}

	return content;
};

export default DropdownPageItem;
