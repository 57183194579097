import React, { ReactElement } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';
import { TTreatmentLibraryInput } from 'Models/treatmentLibrary/isTreatmentLibraryInput';
import Divider from 'UI/display/Divider';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';
import CardHeader from 'UI/surfaces/Card/CardHeader';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import AddOrEditLibraryModal from '../../common/components/TreatmentLibrary/AddOrEditLibraryModal';
import CREATE_TREATMENT_LIBRARY, {
	addCreatedTreatmentLibraryInCache,
} from '../api/createTreatmentLibrary';
import LibraryListItem from './LibraryListItem';
import styles from './styles.module.scss';

type TTreatmentLibrariesCardProps = {
	treatments: TTreatmentLibrary[];
};

const TreatmentLibrariesCard = (
	props: TTreatmentLibrariesCardProps
): ReactElement => {
	const { treatments } = props;
	const { t } = useTranslation();
	const cardTitle = t('treatmentsLibraryPage.card.libraryWithCount', {
		count: treatments.length,
	});
	const [createTreatmentLibrary, createTreatmentLibraryResult] = useMutation(
		CREATE_TREATMENT_LIBRARY
	);

	useSnackbarNotifications({
		mutationResult: createTreatmentLibraryResult,
		messageSuccess: t('treatmentLibrary.addLibraryModal.createLibrary.success'),
		messageFailure: t('treatmentLibrary.addLibraryModal.createLibrary.failure'),
	});

	const treatmentsList = treatments.map((treatment, i) => {
		const isLast = i === treatments.length - 1;

		return (
			<div key={treatment.id} className={styles.treatmentItem}>
				<LibraryListItem key={treatment.id} treatment={treatment} />
				{isLast ? null : <Divider />}
			</div>
		);
	});
	const createTreatmentLibraryAction = (
		input: TTreatmentLibraryInput,
		callback: () => void
	) => {
		void createTreatmentLibrary({
			variables: {
				input: {
					...input,
				},
			},
			update: addCreatedTreatmentLibraryInCache,
			onCompleted() {
				callback();
			},
		});
	};

	const addLibraryModal = (
		<AddOrEditLibraryModal
			actionHandler={createTreatmentLibraryAction}
			isLoading={createTreatmentLibraryResult.loading}
		/>
	);

	return (
		<Card>
			<CardHeader
				title={cardTitle}
				action={addLibraryModal}
				classes={{
					root: styles.treatmentsTitleSection,
					action: styles.treatmentsAction,
					title: styles.treatmentsTitle,
				}}
			/>
			<CardContent className={styles.treatmentsContent}>
				{treatmentsList}
			</CardContent>
		</Card>
	);
};

export default TreatmentLibrariesCard;
