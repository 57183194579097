import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { ProductUnitsArray, TProductUnits } from 'Models/units';
import NumericField from 'UI/inputs/NumericField';
import Select from 'UI/inputs/Select';
import TextField from 'UI/inputs/TextField';
import Grid from 'UI/layout/Grid';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	addTreatmentPageState,
	getError,
	treatmentInputState,
	treatmentInputValidationError,
} from '../../../../../state';
import { AddTreatmentModalStatus } from '../../../../../types';

function Details() {
	const { t } = useTranslation();

	const state = useReactiveVar(addTreatmentPageState);
	const treatmentInput = useReactiveVar(treatmentInputState);
	const inputErrors = useReactiveVar(treatmentInputValidationError);
	const isGovTreatment = !!treatmentInput?.governmentTreatmentId;
	const isNewGovTreatmentCreationFlow =
		AddTreatmentModalStatus.GOV_TREATMENT_DETAILS === state.status;

	if (!treatmentInput) {
		return null;
	}

	const { withholdingPeriod, amount: applicationRate, unit } = treatmentInput;

	const amountError = getError('amount', inputErrors);
	const withholdingPeriodError = getError('withholdingPeriod', inputErrors);

	return (
		<ModalSection title={t('labels.details')}>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<NumericField
						placeholder={t('treatmentLibrary.cropModal.placeholder.rate')}
						helperText={t('treatmentLibrary.cropModal.hint.rate')}
						errorHelperText={t(
							'treatmentLibrary.cropModal.error.valueBelowZero'
						)}
						InputProps={{ inputProps: { min: 0 } }}
						value={applicationRate?.toString()}
						margin="dense"
						onValueParsed={(value: number) =>
							treatmentInputState({
								...treatmentInput,
								amount: value,
							})
						}
						required
						maxDecimals={4}
						inputValidator={() => !amountError}
					/>
				</Grid>
				<Grid item xs={4}>
					<Select
						value={unit}
						label={t('labels.value')}
						options={ProductUnitsArray}
						optionFormatter={(value) =>
							t(`apiConstants.productUnits.${value as TProductUnits}`)
						}
						changeHandler={(value: TProductUnits) =>
							treatmentInputState({
								...treatmentInput,
								unit: value,
							})
						}
						small
						required
					/>
				</Grid>
				{!isNewGovTreatmentCreationFlow && (
					<Grid item xs={12}>
						<TextField
							placeholder={t('treatmentLibrary.cropModal.placeholder.period')}
							disabled={isGovTreatment}
							helperText={
								withholdingPeriodError
									? t('treatmentLibrary.cropModal.error.valueBelowZero')
									: t('treatmentLibrary.cropModal.hint.period')
							}
							onChange={(value: string) =>
								treatmentInputState({
									...treatmentInput,
									withholdingPeriod: Number(value),
								})
							}
							InputProps={{ inputProps: { min: 0, max: 365 } }}
							value={withholdingPeriod?.toString()}
							margin="dense"
							type="number"
							error={Boolean(withholdingPeriodError)}
						/>
					</Grid>
				)}
			</Grid>
		</ModalSection>
	);
}

export default Details;
