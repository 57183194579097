import format from 'date-fns/format';

import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const jobDateCompleted = (job: Pick<TJob, 'completedDate'>): string => {
	return job.completedDate != null
		? format(toLocalDate(job.completedDate), 'dd.MM.yy')
		: '-';
};

export default jobDateCompleted;
