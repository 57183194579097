import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const jobInitialDateStart = (jobs: Pick<TJob, 'startDate'>[]) => {
	const earliestTimeinMs = Math.min(
		...jobs.map((job) =>
			job.startDate ? toLocalDate(job.startDate).getTime() : Infinity
		)
	);
	return earliestTimeinMs < Infinity ? new Date(earliestTimeinMs) : null;
};

export default jobInitialDateStart;
