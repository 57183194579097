import { TNote } from './isNotes';

function _sortingFunction(a: TNote, b: TNote) {
	return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt));
}

function sortJobNotes(notes: TNote[]): TNote[] {
	return notes.slice().sort(_sortingFunction);
}

export default sortJobNotes;
