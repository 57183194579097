import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import TextField from 'UI/inputs/TextField';

import getDebounce from 'Utils/getDebounce';

const debounced = getDebounce();

type TPlantSupplierProps = {
	plantSupplier: string | null | undefined;
	setPlantSupplier: (plantSupplier: string) => void;
};

const PlantSupplier = (props: TPlantSupplierProps): ReactElement => {
	const { plantSupplier, setPlantSupplier } = props;
	const [inputValue, setInputValue] = useState(plantSupplier);

	const { t } = useTranslation();

	function changeInputHandler(value) {
		setInputValue(value);
		const updateInput = () => setPlantSupplier(value);

		debounced(updateInput);
	}

	return (
		<TextField
			value={inputValue || ''}
			label={t('labels.supplier')}
			onChange={changeInputHandler}
		/>
	);
};

export default PlantSupplier;
