import { createTheme } from '@mui/material/styles';

import { breakpointsValues } from './breakpoints';
import components from './components';
import palette from './palette';
import typography from './typography';

const farmableTheme = createTheme({
	palette,
	typography: typography,
	breakpoints: breakpointsValues,
	components,
});

export default farmableTheme;
