// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VxeNTFeUcS_Wyg5FFwXjx {\n  margin: .5rem;\n}\n\n._22Kdw3p_42rtECRycPH7XQ {\n  position: absolute !important;\n  left: 1rem;\n}\n\n._7LKG5mS29NAvg41sPQcPP {\n  display: flex;\n  margin-left: .125rem;\n  align-items: center;\n}\n\n", ""]);
// Exports
exports.locals = {
	"modalContent": "VxeNTFeUcS_Wyg5FFwXjx",
	"backButton": "_22Kdw3p_42rtECRycPH7XQ",
	"tooltipBase": "_7LKG5mS29NAvg41sPQcPP"
};
module.exports = exports;
