import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TImage } from 'Models/image/isImage';

import GET_PARTNER_CAUSES from './getPartnerCauses';

type TUploadPartnershipCauseImageResponse = {
	uploadPartnershipCauseImage: TImage;
};

type TUploadPartnershipCauseImageInput = {
	causeId: string;
	file: File;
};

const UPLOAD_PARTNERSHIP_CAUSE_IMAGE: TypedDocumentNode<
	TUploadPartnershipCauseImageResponse,
	TUploadPartnershipCauseImageInput
> = gql`
	mutation uploadPartnershipCauseImage($causeId: ID!, $file: Upload!) {
		uploadPartnershipCauseImage(causeId: $causeId, file: $file) {
			id
			path
			filename
			mimetype
			createdAt
		}
	}
`;

export default UPLOAD_PARTNERSHIP_CAUSE_IMAGE;

export const updateCauseImagesCache: MutationUpdaterFunction<
	TUploadPartnershipCauseImageResponse,
	TUploadPartnershipCauseImageInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }, options) => {
	const query = {
		query: GET_PARTNER_CAUSES,
	};
	const causeId = options.variables?.causeId;
	const newImage = data?.uploadPartnershipCauseImage;
	const createdCauses = cache.readQuery(query)?.getPartnershipCauses;

	if (createdCauses && newImage) {
		const updatedCauses = createdCauses.causes.map((cause) => {
			if (cause.id === causeId) {
				return {
					...cause,
					causeImages: (cause.causeImages || []).concat({
						...newImage,
					}),
				};
			}
			return cause;
		});

		cache.writeQuery({
			...query,
			data: {
				getPartnershipCauses: {
					causes: updatedCauses,
				},
			},
		});
	}
};
