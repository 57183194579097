import { gql, TypedDocumentNode } from '@apollo/client';

import { TUpdatePartnerCompanyInput } from '../types';

const UPDATE_PARTNER_COMPANY_MUTATION: TypedDocumentNode<
	{
		updatePartnershipCompany: { id: string };
	},
	TUpdatePartnerCompanyInput
> = gql`
	mutation updatePartnerCompany($input: UpdatePartnershipCompanyInput!) {
		updatePartnershipCompany(input: $input) {
			id
		}
	}
`;

export default UPDATE_PARTNER_COMPANY_MUTATION;
