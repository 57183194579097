import isOneOf from 'vet/isOneOf';
import ValidatorType from 'vet/types/ValidatorType';

export const NotificationType = {
	JOB_RECOMMENDED: 'JOB_RECOMMENDED',
	JOB_ASSIGNED: 'JOB_ASSIGNED',
	JOB_COMPLETED: 'JOB_COMPLETED',
	JOB_NOTE_CREATED: 'JOB_NOTE_CREATED',
	FIELD_NOTE_CREATED: 'FIELD_NOTE_CREATED',
	FIELD_NOTE_COMMENT_CREATED: 'FIELD_NOTE_COMMENT_CREATED',
	HARVEST_CREATED: 'HARVEST_CREATED',
	TIMESHEET_CLOCKED_OUT: 'TIMESHEET_CLOCKED_OUT',
} as const;

const _isNotificationType = isOneOf(...Object.values(NotificationType));

const isNotificationType: typeof _isNotificationType = _isNotificationType;

export type TNotificationType = ValidatorType<typeof isNotificationType>;

export default isNotificationType;
