import React, { useState } from 'react';

import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Link from 'Features/router/components/Link';
import { ExportType, TExportType } from 'Models/exports/ExportType';
import { LogTypes } from 'Models/logs/LogType';
import Divider from 'UI/display/Divider';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useCompanyID from 'Hooks/useCompanyID';
import useManageHistory from 'Hooks/useManageHistory';
import useProfile from 'Hooks/useProfile';

import buildPartnerExportPageURL from '../../common/utils/buildPartnerExportPageURL';

const ExportButton = () => {
	const { t } = useTranslation();
	const { profile } = useProfile();
	const companyId = useCompanyID();
	const { currentLocation } = useManageHistory();

	// dropdown state
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const trackExport = (type: TExportType) => {
		if (profile && companyId) {
			analyticsTrack(TrackEvents.DataExported, {
				userId: profile.id,
				groupId: companyId,
				exportType: type,
				logType: LogTypes.Jobs,
			});
		}
	};

	return (
		<>
			<Button
				id="export-partner-harvests-button"
				variant="outlined"
				onClick={handleClick}
			>
				{t('logsPages.jobLogsPage.exportButton.hasSubscription')}
			</Button>
			<Menu.Menu
				id="export-partner-harvests-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Link
					id="export-xls-button"
					to={buildPartnerExportPageURL(
						'xlsx',
						currentLocation,
						companyId,
						'Harvest'
					)}
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => trackExport(ExportType.XLSX)}
				>
					<Menu.Item onClick={handleClose}>
						{t('logsPages.jobLogsPage.exportButton.items.xls')}
					</Menu.Item>
				</Link>
				<Divider sx={{ marginY: '0.5rem' }} />
				<Link
					id="export-csv-button"
					to={buildPartnerExportPageURL(
						'csv',
						currentLocation,
						companyId,
						'Harvest'
					)}
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => trackExport(ExportType.CSV)}
				>
					<Menu.Item onClick={handleClose}>
						{t('logsPages.jobLogsPage.exportButton.items.csv')}
					</Menu.Item>
				</Link>
			</Menu.Menu>
		</>
	);
};

export default ExportButton;
