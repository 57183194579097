import React, { ReactElement } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

type TStripeProviderProps = {
	children: ReactElement | ReactElement[];
};

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY || '');

const StripeProvider = (props: TStripeProviderProps) => {
	return <Elements stripe={stripePromise}>{props.children}</Elements>;
};

export default StripeProvider;
