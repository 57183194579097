import React, { ReactElement } from 'react';

import { TField } from 'Models/fields/isField';
import Polygon, { TPositions } from 'UI/maps/Polygon';
import { FarmableColors, FarmablePlaceholdersColors } from 'UI/theme/Colors';
import _flatten from 'lodash/flatten';

export enum FieldPolygonStatus {
	FOCUSED = 'FOCUSED',
	NOT_FOCUSED = 'NOT_FOCUSED',
	INACTIVE = 'INACTIVE',
	COMPLETED = 'COMPLETED',
}

type TFieldPolygonStatus = keyof typeof FieldPolygonStatus;

function getFieldPolygonStyle(status?: TFieldPolygonStatus) {
	switch (status) {
		case FieldPolygonStatus.FOCUSED:
			return {
				color: FarmableColors.ORANGE,
				fillColor: FarmableColors.ORANGE,
				opacity: 1.0,
				fillOpacity: 0.67,
			};
		case FieldPolygonStatus.NOT_FOCUSED:
			return {
				color: FarmableColors.ORANGE,
				fillColor: FarmableColors.ORANGE,
				opacity: 0.5,
				fillOpacity: 0.25,
			};
		case FieldPolygonStatus.INACTIVE:
			return {
				color: FarmableColors.WHITE,
				fillColor: FarmableColors.WHITE,
				opacity: 1.0,
				fillOpacity: 0.16,
				interactive: false,
			};
		case FieldPolygonStatus.COMPLETED:
			return {
				color: FarmablePlaceholdersColors.LIGHT_GREEN,
				fillColor: FarmablePlaceholdersColors.LIGHT_GREEN,
				opacity: 1.0,
				fillOpacity: 0.67,
			};
		default:
			return {
				color: FarmableColors.ORANGE,
				fillColor: FarmableColors.ORANGE,
				opacity: 1.0,
				fillOpacity: 0.5,
			};
	}
}

type TFieldPolygonProps = {
	field: Pick<TField, 'geometry'>;
	onClick?: () => void;
	status?: TFieldPolygonStatus;
	tooltipContent?: ReactElement[] | null;
};

const FieldPolygon = (props: TFieldPolygonProps): ReactElement => {
	const { field, onClick, status, tooltipContent } = props;

	const positions: TPositions = _flatten(field.geometry).map((pos) => [
		pos.y,
		pos.x,
	]);
	const fieldPolygonStyle = getFieldPolygonStyle(status);

	return (
		<Polygon
			onClick={onClick}
			positions={positions}
			{...fieldPolygonStyle}
			tooltipContent={tooltipContent}
		/>
	);
};

export default FieldPolygon;
