import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJobTreatment } from 'Models/jobs/isJobTreatment';
import { TOriginalProduct } from 'Models/jobs/isOriginalProduct';
import jobProductDetails from 'Models/jobs/jobProductDetails';
import Text from 'UI/display/Text';

import DetailModalSection from '../../../../../components/ModalSection';

type TProductDetailsProps = {
	job: {
		treatments: (Pick<TJobTreatment, 'amount' | 'unit'> & {
			originalProduct?: Pick<TOriginalProduct, 'name'> | null;
		})[];
	};
};

const ProductDetails = (props: TProductDetailsProps): ReactElement | null => {
	const { job } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.jobLogsPage.detailsModal.sections.';

	return (
		<DetailModalSection title={t(`${prefix}products`)}>
			{jobProductDetails(job).map((product) => (
				<Text component="p">{product}</Text>
			))}
		</DetailModalSection>
	);
};

export default ProductDetails;
