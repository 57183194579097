import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductUnits } from 'Models/units';
import NumericField from 'UI/inputs/NumericField';
import TextField from 'UI/inputs/TextField';
import Grid from 'UI/layout/Grid';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	getError,
	treatmentInputState,
	treatmentInputValidationError,
} from '../../../../../state';

function ApplicationRequirements() {
	const { t } = useTranslation();
	const treatmentInput = useReactiveVar(treatmentInputState);
	const inputErrors = useReactiveVar(treatmentInputValidationError);
	const isGovTreatment = !!treatmentInput?.governmentTreatmentId;

	if (!treatmentInput) {
		return null;
	}

	const { maxVolume, applicationInterval, unit, maxApplicationsCategory } =
		treatmentInput;

	const maxApplicationsError = getError('maxApplicationsCategory', inputErrors);
	const maxVolumeError = getError('maxVolume', inputErrors);
	const intervalError = getError('applicationInterval', inputErrors);

	return (
		<ModalSection title={t('labels.applicationRequirements')}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						placeholder={t(
							'treatmentLibrary.cropModal.placeholder.maxApplications'
						)}
						helperText={
							maxApplicationsError
								? t('treatmentLibrary.cropModal.error.valueBelowZero')
								: t('treatmentLibrary.cropModal.hint.maxApplications')
						}
						value={maxApplicationsCategory?.toString()}
						onChange={(value: string) =>
							treatmentInputState({
								...treatmentInput,
								maxApplicationsCategory: Number(value),
							})
						}
						disabled={isGovTreatment}
						InputProps={{ inputProps: { min: 0 } }}
						margin="dense"
						type="number"
						error={Boolean(maxApplicationsError)}
					/>
				</Grid>
				<Grid item xs={8}>
					<NumericField
						placeholder={t('treatmentLibrary.cropModal.placeholder.maxVolume')}
						errorHelperText={t(
							'treatmentLibrary.cropModal.error.valueBelowZero'
						)}
						helperText={t('treatmentLibrary.cropModal.hint.maxVolume')}
						value={maxVolume?.toString()}
						onValueParsed={(value: number) =>
							treatmentInputState({
								...treatmentInput,
								maxVolume: value,
							})
						}
						disabled={isGovTreatment}
						InputProps={{ inputProps: { min: 0 } }}
						margin="dense"
						inputValidator={() => !maxVolumeError}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						value={unit ? t(`apiConstants.productUnits.${unit}`) : undefined}
						disabled
						margin="dense"
						type="string"
						onChange={(value: TProductUnits) =>
							treatmentInputState({
								...treatmentInput,
								unit: value,
							})
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder={t('treatmentLibrary.cropModal.placeholder.interval')}
						helperText={
							intervalError
								? t('treatmentLibrary.cropModal.error.valueBelowZero')
								: t('treatmentLibrary.cropModal.hint.minPeriod')
						}
						value={applicationInterval?.toString()}
						onChange={(value: string) =>
							treatmentInputState({
								...treatmentInput,
								applicationInterval: Number(value),
							})
						}
						disabled={isGovTreatment}
						InputProps={{ inputProps: { min: 0 } }}
						margin="dense"
						type="number"
						error={Boolean(intervalError)}
					/>
				</Grid>
			</Grid>
		</ModalSection>
	);
}

export default ApplicationRequirements;
