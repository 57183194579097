import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TMembers } from 'Models/profile/isMembers';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';
import _uniqBy from 'lodash/uniqBy';

import useProfile from 'Hooks/useProfile';

// import InviteMember from './InviteMember';
import Member from './Member';

const mergeDuplicatedMembers = (members: TMembers) => {
	return _uniqBy(members, (m) => m.account.id);
};

type TFarmMembersListProps = {
	members: TMembers;
};

const FarmMembersList = (props: TFarmMembersListProps): ReactElement => {
	const { t } = useTranslation();
	const { profile } = useProfile();

	const { members } = props;
	const membersCount = t('membersPage.card.memberWithCount', {
		count: members.length,
	});

	return (
		<Card>
			<CardContent>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Text variant="h3" marginBottom="1rem">
							{membersCount}
						</Text>
					</Grid>
					<Grid item textAlign="right">
						{/* not implemented yet
						<InviteMember /> */}
					</Grid>
				</Grid>
				{mergeDuplicatedMembers(members).map((member) => (
					<Member {...member} isYou={profile?.id === member.account.id} />
				))}
			</CardContent>
		</Card>
	);
};

export default FarmMembersList;
