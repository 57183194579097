import React from 'react';

import { useReactiveVar } from '@apollo/client';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductInput } from 'Models/products/ProductInput';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import { TIngredientProps } from '.';
import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../../state';

const ActiveIngredient = (props: TIngredientProps) => {
	const { t } = useTranslation();
	const { ingredient } = props;

	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { status, input } = state;
	const isDisabled =
		Boolean(selectedProduct) || input.selectedGovernmentProduct;

	if (status === 'SET_PRODUCT_DETAILS') {
		const onChangeActivity = (value: boolean) => {
			if (
				!input.selectedGovernmentProduct &&
				'ingredients' in input.product &&
				input.product.ingredients
			) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: input.product.ingredients?.map((innerIngredient) =>
								ingredient.id === innerIngredient.id
									? {
											...innerIngredient,
											active: value,
									  }
									: innerIngredient
							),
						},
					},
				});
			}
		};

		const onChangeRegistrationNumber = (value: string) => {
			if (
				!input.selectedGovernmentProduct &&
				'ingredients' in input.product &&
				input.product.ingredients
			) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: input.product.ingredients?.map((innerIngredient) =>
								ingredient.id === innerIngredient.id
									? {
											...innerIngredient,
											registrationNumber: value,
									  }
									: innerIngredient
							),
						},
					},
				});
			}
		};

		const onChangeGroupName = (value: string) => {
			if (
				!input.selectedGovernmentProduct &&
				'ingredients' in input.product &&
				input.product.ingredients
			) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: (input.product as TProductInput).ingredients?.map(
								(innerIngredient) =>
									ingredient.id === innerIngredient.id
										? {
												...innerIngredient,
												groupName: value,
										  }
										: innerIngredient
							),
						},
					},
				});
			}
		};

		return (
			<Box
				sx={{
					border: `1px solid ${FarmableColors.BLACK_12}`,
					borderRadius: '0.325rem',
					padding: '0.125rem 1rem ',
				}}
			>
				<FormControlLabel
					value="start"
					control={<Switch checked={ingredient.active} color="primary" />}
					label="Active ingredient"
					labelPlacement="start"
					onClick={() => onChangeActivity(!ingredient.active)}
					disabled={isDisabled}
					sx={{
						justifyContent: 'space-between',
						margin: 0,
						width: '100%',
						'& .MuiTypography-root': {
							color: ingredient.active ? undefined : 'grey',
						},
					}}
				/>
				{ingredient.active && (
					<>
						<TextField
							type="string"
							value={ingredient.registrationNumber || ''}
							margin="dense"
							onChange={onChangeRegistrationNumber}
							label={t('labels.registrationNumber')}
							sx={{ marginBottom: '0.75rem' }}
							disabled={isDisabled}
						/>
						<TextField
							type="string"
							value={ingredient.groupName || ''}
							margin="dense"
							onChange={onChangeGroupName}
							label={t('labels.chemicalGroup')}
							sx={{ marginBottom: '0.75rem' }}
							disabled={isDisabled}
						/>
					</>
				)}
			</Box>
		);
	}
	return null;
};

export default ActiveIngredient;
