import React, { useEffect, useState, ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useParams from 'Features/router/hooks/useParams';
import fieldsBoundingBox, {
	TMapBoundingBox,
} from 'Models/fields/fieldsBoundingBox';
import { TField } from 'Models/fields/isField';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Box from 'UI/layout/Box';
import Map from 'UI/maps/Map';
import { refetchMaxZoom } from 'UI/maps/Map/events';
import GMapLayer from 'UI/maps/layers/GMapLayer';
import { useHistory } from 'react-router-dom';

import OverlayLoader from '../../components/OverlayLoader';
import { TFieldSearchMap } from '../../components/fields/FieldLayers';
import { fetchMaxZoom } from '../../features/google/initGoogleMapsAPILoader';
import FieldLayers from '../fields/FieldLayers';
import hideOverlappingTooltips from './utils/hideOverlappingTooltips';

type TSearchMapComponent = {
	fields: TFieldSearchMap[];
	defaultFieldId?: string | null;
	isLoading?: boolean;
	hideSearchInput?: boolean;
	disableFieldClick?: boolean;
	onFieldClick?: (field: Pick<TField, 'id'>) => void;
};

const FieldsSearchMap = (props: TSearchMapComponent): ReactElement => {
	const {
		fields,
		defaultFieldId,
		isLoading,
		hideSearchInput,
		disableFieldClick,
		onFieldClick,
	} = props;
	const history = useHistory();
	const [bounds, setBounds] = useState<TMapBoundingBox>();
	const { t } = useTranslation();
	const { locationID, fieldID, companyID } = useParams();

	const [filteredFields, setFilteredFields] =
		useState<TFieldSearchMap[] | null>(null);
	const [searchValue, setSearchValue] = useState('');
	const onChange = (newValue: string) => {
		if (fields) {
			const filteredValue = fields.filter((field) =>
				field.name?.toUpperCase().includes(newValue.toUpperCase())
			);
			setFilteredFields(filteredValue.length ? filteredValue : fields);
		}

		setSearchValue(newValue);
	};

	const onClear = () => {
		setSearchValue('');
		setFilteredFields(null);
	};

	const defaultOnFieldClick = (field: Pick<TField, 'id'>) => {
		if (fieldID !== field.id) {
			const newLocationURL = `/locations/${locationID}/dashboard/fields/${field.id}${history.location.search}`;
			const newPartnerURL = `/partners/${companyID}/dashboard/farm/${locationID}/fields/${field.id}${history.location.search}`;

			history.push(companyID ? newPartnerURL : newLocationURL);
		}
	};

	useEffect(() => {
		if (fields.length) {
			const defaultField = fields.find((field) => field.id === defaultFieldId);
			const zoomOn = filteredFields || defaultField || fields;

			const boundingBox = fieldsBoundingBox(zoomOn);
			setBounds(boundingBox);
		}
	}, [fields, filteredFields, defaultFieldId]);

	const getMapContent = () =>
		isLoading ? (
			<OverlayLoader />
		) : (
			<>
				{hideSearchInput ? null : (
					<Box
						maxWidth="25rem"
						position="relative"
						margin="0.5rem"
						zIndex={9999}
					>
						<FreeTextSearch
							inputPlaceholder={t('searchMap.freeTextSearchPlaceholder')}
							onChange={onChange}
							onClear={onClear}
							value={searchValue}
						/>
					</Box>
				)}
				<FieldLayers
					fields={fields}
					showFieldName
					onFieldClick={
						disableFieldClick ? undefined : onFieldClick || defaultOnFieldClick
					}
					currentFieldId={defaultFieldId}
				/>
			</>
		);

	return (
		<Box data-testid="search-map" height="27.125rem" margin="0.5rem">
			<Map.Controlled
				bounds={bounds}
				boundsOptions={{ padding: [50, 50] }}
				maxBoundsViscosity={1}
				onMapZoomEnd={hideOverlappingTooltips}
				onMapReflow={hideOverlappingTooltips}
				onDragEnd={() => refetchMaxZoom(fetchMaxZoom)}
			>
				<>
					{getMapContent()}
					<GMapLayer />
				</>
			</Map.Controlled>
		</Box>
	);
};

export default FieldsSearchMap;
