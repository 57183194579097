import React, { ReactElement } from 'react';

import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';

const LoadingContent = (): ReactElement => {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			height="100%"
			overflow="hidden"
		>
			<LoadingSpinner />
		</Box>
	);
};

export default LoadingContent;
