import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, TFunction } from 'react-i18next';

import translations from './lang';

export type TTranslateFunction = TFunction;

declare module 'react-i18next' {
	interface CustomTypeOptions {
		// en language is set to fallback lang, so it's enough
		// to check only does it exist
		resources: typeof translations['en'];
	}
}

const i18n = i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: translations,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: ['navigator', 'htmlTag'],
		},
	});

export { i18n };
