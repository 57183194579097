import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';
import Page from 'UI/layout/Page';

import useParsedSearchParams from 'Hooks/useParsedSearchParams';

const ExportLoadingSpinner = () => {
	const { t } = useTranslation();

	const parsedSearchParams = useParsedSearchParams();

	return (
		<Page noMinHeight center>
			<LoadingSpinner width={64} height={64} />
			<Box
				paddingTop={4}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<Text variant="h1" paragraph>
					{t('exportPages.creatingReport')}
				</Text>
				<Text>{parsedSearchParams}</Text>
			</Box>
		</Page>
	);
};

export default ExportLoadingSpinner;
