import React, { ReactElement } from 'react';

import Text from 'UI/display/Text';
import FailedIcon from 'UI/icons/Failed';
import Box from 'UI/layout/Box';

type TFailureMessageProps = {
	message?: string;
	children?: ReactElement | null;
};

const FailureMessage = (props: TFailureMessageProps) => {
	const { children, message } = props;
	return (
		<Box padding={2} display="flex" flexDirection="column" alignItems="center">
			<FailedIcon />
			{message ? (
				<Box padding={2} textAlign="center">
					<Text variant="h2">{message}</Text>
				</Box>
			) : null}
			{children}
		</Box>
	);
};

export default FailureMessage;
