import { THoursExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';

import { TTimesheetExportData } from '../types';
import getHoursLogsRowDataForExport from './getHoursLogsRowDataForExport';

function getExportData(
	timesheets: TTimesheetExportData[],
	dictionary: THoursExportHeadersDictionary
) {
	const exportData = timesheets.map((timesheet) =>
		getHoursLogsRowDataForExport(timesheet, dictionary)
	);

	return {
		exportData,
	};
}

export default getExportData;
