import React, { useCallback, useEffect, useMemo } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TSupportedJobs } from 'Models/jobs/JobInput';
import Modal from 'UI/display/modal/dialog';

import useManageHistory from 'Hooks/useManageHistory';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import getPartnerVarietyFromURL from 'Utils/getPartnerVarietyFromURL';

import ErrorBoundary from '../../../../../components/ErrorBoundary';
import CREATE_PLANNER_JOB from '../../api/createPlannerJob';
import SHARE_PLANNER_JOB_MUTATION from '../../api/sharePlannerJobMutation';
import getInitialInput from '../../utils/getInitialInput';
import parseCreatePlannerJobInput from '../../utils/parseCreatePlannerJobInput';
import ModalContent from './components/ModalContent';
import NextButton from './components/NextButton';
import PreviousButton from './components/PreviousButton';
import { addJobModalState, selectedLocationIds } from './state';
import styles from './styles.module.scss';

type TAddJobModal = {
	isOpen: boolean;
	jobType: TSupportedJobs | null;
	toggleHandler: () => void;
};

const AddJobModal = (props: TAddJobModal) => {
	const { t } = useTranslation();
	const { jobType, isOpen, toggleHandler } = props;
	const { jobInputData } = useReactiveVar(addJobModalState);
	const selectedJobLocationIds = useReactiveVar(selectedLocationIds);
	const [createJob, createAction] = useMutation(CREATE_PLANNER_JOB, {
		refetchQueries: ['getPartnerPlannerJobs'],
	});
	const [sharePlannerJob, shareAction] = useMutation(
		SHARE_PLANNER_JOB_MUTATION
	);
	const { paramsFromURL } = useManageHistory();

	const variety = useMemo(
		() => getPartnerVarietyFromURL(paramsFromURL),
		[paramsFromURL]
	);

	const onActionEnd = useCallback(() => {
		toggleHandler();
		addJobModalState({
			status: 'SET_CAUSE_AND_TREATMENTS',
			jobInputData: undefined,
		});
	}, [toggleHandler]);

	useSnackbarNotifications({
		mutationResult: createAction,
		messageFailure: t('errors.createPlannerJobFailed'),
		messageSuccess: t('info.createPlannerJobSuccess'),
	});

	useSnackbarNotifications({
		mutationResult: shareAction,
		messageFailure: t('errors.sharePlannerJobFailed'),
	});

	useEffect(() => {
		if (!isOpen) {
			createAction.called && createAction.reset();
			shareAction.called && shareAction.reset();
		} else {
			if (
				jobType &&
				variety &&
				(!jobInputData || jobInputData.jobDetails.jobType !== jobType)
			) {
				addJobModalState({
					status: 'SET_CAUSE_AND_TREATMENTS',
					jobInputData: getInitialInput(jobType, variety),
				});
			}
		}
	}, [isOpen, jobType, variety, jobInputData, createAction, shareAction]);

	useEffect(() => {
		if (!isOpen) return;

		if (createAction.error || shareAction.error) {
			onActionEnd();
			return;
		}

		if (
			shareAction.data ||
			(createAction.data && !selectedJobLocationIds?.length)
		) {
			onActionEnd();
			return;
		}

		// share job once it is created if user selected locations to share
		if (
			createAction.data &&
			selectedJobLocationIds?.length &&
			!shareAction.called
		) {
			void sharePlannerJob({
				variables: {
					plannerJobId: createAction.data.createPartnerPlannerJob.id,
					locationIds: selectedJobLocationIds,
				},
			});
		}
	}, [
		t,
		createAction.data,
		toggleHandler,
		selectedJobLocationIds,
		isOpen,
		sharePlannerJob,
		shareAction,
		createAction,
		onActionEnd,
	]);

	if (!jobType || !jobInputData) {
		return null;
	}

	const addAndSend = () => {
		const sanitizedInput = parseCreatePlannerJobInput(jobInputData);
		void createJob({ variables: { input: sanitizedInput } });
	};

	const translatedJobType = t(`common.jobs.types.${jobType}`);

	return (
		<Modal.UncontrolledModal toggleOpen={toggleHandler} open={isOpen}>
			<Modal.DialogTitle
				title={
					t('plannerPage.addJobModal.title', {
						jobType: translatedJobType,
						variety: variety?.name,
					}) || ''
				}
				closeIconHandler={onActionEnd}
			/>
			<Modal.DialogContent>
				<ErrorBoundary>
					<ModalContent />
				</ErrorBoundary>
			</Modal.DialogContent>
			<Modal.DialogActions className={styles.dialogActions}>
				<PreviousButton
					isLoading={createAction.loading || shareAction.loading}
					onClose={onActionEnd}
				/>
				<NextButton
					isLoading={createAction.loading || shareAction.loading}
					actionHandler={addAndSend}
				/>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default AddJobModal;
