import { gql, TypedDocumentNode } from '@apollo/client';
import { THarvestBatch } from 'Models/harvestBatch/isHarvestBatch';

type TGetSalesStatsResponse = {
	getAggregatedHarvestBatches: Pick<
		THarvestBatch,
		'totalVolume' | 'soldVolume' | 'wasteVolume'
	>[];
};
type TGetSalesStatsRequest = {
	locationId: string;
	query: {
		filterBy?: {
			dateFrom?: string;
			dateTo?: string;
			varieties?: string[];
			onlySoldBatches?: boolean;
			customerId?: string;
		};
	};
};

const GET_SALES_STATS: TypedDocumentNode<
	TGetSalesStatsResponse,
	TGetSalesStatsRequest
> = gql`
	query getSalesStats($locationId: ID!, $query: AggregatedHarvestBatchQuery) {
		getAggregatedHarvestBatches(locationId: $locationId, query: $query) {
			totalVolume
			soldVolume
			wasteVolume
		}
	}
`;

export default GET_SALES_STATS;
