import { format } from 'date-fns';

import ISOToLocal from '../dates/ISOToLocal';
import { TTimesheet } from './isTimesheet';

function timesheetStartTimeString<TGenericTimesheet>(
	timesheet: TGenericTimesheet & Pick<TTimesheet, 'startTime'>
): string {
	return timesheet.startTime
		? format(ISOToLocal(timesheet.startTime), 'HH:mm')
		: '-';
}

export default timesheetStartTimeString;
