import DrawerModal, { TDrawerModal } from './body';
import UncontrolledDrawer, { TUncontrolledDrawer } from './uncontrolled';

export type Theme = TDrawerModal & TUncontrolledDrawer;

const Export = {
	DrawerModal,
	UncontrolledDrawer,
};

export default Export;
