import { TTeam } from 'Models/teams/isTeam';
import { TTeamInputPartial } from 'Models/teams/isTeamInput';
import _flatMap from 'lodash/flatMap';
import _uniq from 'lodash/uniq';

export default function getOverlappingIDs(
	teams: Pick<TTeam, 'teamMembers' | 'id'>[],
	teamInput: TTeamInputPartial,
	currentTeamId: string | null
) {
	if (teamInput.teamMemberInput) {
		const locationMemberIDs = _uniq(
			_flatMap(
				teams.filter((team) => team.id !== currentTeamId),
				(team) => team.teamMembers
			).map((member) => member.customId)
		);
		const overlappingIDs = teamInput.teamMemberInput
			.filter(
				(member) =>
					member.customId && locationMemberIDs.includes(member.customId)
			)
			.map((member) => member.customId);

		return overlappingIDs;
	} else {
		return [];
	}
}
