import React from 'react';

import { MutationResult } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { hasNestedGraphQLErrors } from 'Models/graphql/ErrorUtils';
import { ERROR_STATUSES } from 'Models/graphql/errorTypes';
import Alert from 'UI/display/Alert';

type TProps = {
	task?: MutationResult;
};

function GraphQLError(props: TProps) {
	const { task } = props;
	const { t } = useTranslation();
	const hasUserAlreadyInvitedError =
		hasNestedGraphQLErrors(task) &&
		task.error.graphQLErrors?.some(
			(error) =>
				error?.extensions?.status ===
				ERROR_STATUSES.PARTNERSHIP_USER_IS_ALREADY_INVITED
		);

	if (hasUserAlreadyInvitedError) {
		return (
			<Alert severity="error" sx={{ marginBottom: '1rem' }}>
				{t('errors.PARTNERSHIP_USER_IS_ALREADY_INVITED')}
			</Alert>
		);
	} else if (task?.error) {
		return (
			<Alert severity="error" sx={{ marginBottom: '1rem' }}>
				{task.error.message}
			</Alert>
		);
	}

	return null;
}

export default GraphQLError;
