import { gql, TypedDocumentNode } from '@apollo/client';
import { TProduct } from 'Models/products/Product';
import { TGovernmentProductFilterInput } from 'Models/products/types/GovernmentProductFilterInput';

import { treatmentLibraryProductFragmentFields } from '../../../../../profile/PartnerTreatmentLibraryPage/api';

type TGetGovernmentProductsInput = {
	filterBy: TGovernmentProductFilterInput;
};

const GET_GOVERNMENT_PRODUCTS: TypedDocumentNode<
	{
		getGovernmentProducts: {
			products: TProduct[];
		};
	},
	TGetGovernmentProductsInput
> = gql`
	query getGovernmentProducts($filterBy: GovernmentProductFilterInput!) {
		getGovernmentProducts(filterBy: $filterBy) {
			products {
				${treatmentLibraryProductFragmentFields}
			}
		}
	}
`;

export default GET_GOVERNMENT_PRODUCTS;
