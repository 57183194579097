import React, { ReactElement, ReactNode } from 'react';

import Permissions from 'Models/permissions/Permissions';

import usePermission from 'Hooks/usePermission';

import NoAccessPage from '../../no-access/NoAccessPage';

type TExportPageValidatorProps = {
	children?: ReactNode;
};

function StorePageValidator(props: TExportPageValidatorProps): ReactElement {
	const { children } = props;

	const hasSeeStorePermission = usePermission(Permissions.STORE_PAGE_VIEW);

	return <>{hasSeeStorePermission ? children : <NoAccessPage />}</>;
}

export default StorePageValidator;
