import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import NavLink from 'Features/router/components/NavLink';
import UIKitBreadcrumbs from 'UI/navigation/Breadcrumbs';
import URL from 'urijs';

import useCompanyID from 'Hooks/useCompanyID';
import useFieldID from 'Hooks/useFieldID';
import useLocationID from 'Hooks/useLocationID';
import usePartnerLocations from 'Hooks/usePartnerLocations';

import './styles.scss';
import { useQuery } from '@apollo/client';
import useHistory from 'Features/router/hooks/useHistory';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import usePartnerCompany from 'Hooks/usePartnerCompany';

import GET_FIELD_NAME_QUERY from './api/getFieldName';

const Breadcrumbs = () => {
	const { t } = useTranslation();
	const companyID = useCompanyID();
	const { company } = usePartnerCompany();
	const location = useCurrentLocation();
	const locationId = useLocationID();
	const fieldID = useFieldID();
	const { partnerLocations } = usePartnerLocations();

	const history = useHistory();
	const segments = URL(history.location.pathname).segment();
	const isPartnershipLocationRoute = !!companyID;
	const isLocationRoute = !!locationId;

	const locationNameFallback = t('common.navbar.farmNameFallback');

	const { data } = useQuery(GET_FIELD_NAME_QUERY, {
		skip: !fieldID,
		variables: {
			id: fieldID || '',
			locationId,
		},
	});

	//Partner dashboard e.g.: /partners/1/dashboard/partner-locations/2137/fields/420
	if (isPartnershipLocationRoute) {
		// This route is not implemented yet.
		if (segments[5] === 'fields' && fieldID) {
			// filedID should be checked here instead of a segment
			return (
				<UIKitBreadcrumbs>
					<NavLink to={`/partners/${companyID}/dashboard`}>
						{company?.name}
					</NavLink>
					<NavLink
						to={`/partners/${companyID}/dashboard/locations/${segments[4]}`}
					>
						{locationNameFallback || ''}
					</NavLink>
					<span className="active">{data?.getField.name || fieldID}</span>
				</UIKitBreadcrumbs>
			);
		}

		if (segments[3] === 'partner-locations') {
			const partnerLocationName = partnerLocations?.find(
				(location) => location.id === locationId
			);
			return (
				<UIKitBreadcrumbs>
					<NavLink to={`/partners/${companyID}/dashboard`}>
						{company?.name}
					</NavLink>
					<span className="active">
						{partnerLocationName?.name || locationNameFallback}
					</span>
				</UIKitBreadcrumbs>
			);
		}

		return (
			<UIKitBreadcrumbs>
				<span className="active">{company?.name}</span>
			</UIKitBreadcrumbs>
		);
	}

	// Location dashboard e.g.: locations/2137/fields/420/dashboard
	if (isLocationRoute) {
		if (fieldID) {
			return (
				<UIKitBreadcrumbs>
					<NavLink to={`/locations/${locationId}/dashboard`}>
						{location?.name}
					</NavLink>
					<span className="active">{data?.getField.name}</span>
				</UIKitBreadcrumbs>
			);
		}

		return (
			<UIKitBreadcrumbs>
				<span className="active">{location?.name}</span>
			</UIKitBreadcrumbs>
		);
	}

	return null;
};

export default Breadcrumbs;
