import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TCauseInput } from 'Models/causes/isCause';

import { TPartnerCause } from '../types';
import GET_CAUSES_DATA from './getPartnerCauses';

type TEditPartnershipCauseResponse = {
	editPartnershipCause: TPartnerCause;
};

type TEditPartnershipCauseInput = {
	causeId: string;
	input: TCauseInput;
};

const EDIT_PARTNERSHIP_CAUSE: TypedDocumentNode<
	TEditPartnershipCauseResponse,
	TEditPartnershipCauseInput
> = gql`
	mutation EditPartnershipCause($causeId: ID!, $input: CauseInput!) {
		editPartnershipCause(causeId: $causeId, input: $input) {
			id
			name
			causeType
			description
			causeImages {
				id
				path
				filename
				mimetype
				createdAt
			}
		}
	}
`;

export default EDIT_PARTNERSHIP_CAUSE;

export const updateCauseCache: MutationUpdaterFunction<
	TEditPartnershipCauseResponse,
	TEditPartnershipCauseInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }) => {
	const query = { query: GET_CAUSES_DATA };
	const editedCause = data?.editPartnershipCause;
	const existingCauses = cache.readQuery(query)?.getPartnershipCauses.causes;

	if (existingCauses && editedCause) {
		cache.writeQuery({
			...query,
			data: {
				getPartnershipCauses: {
					causes: existingCauses.map((cause) =>
						cause.id === editedCause.id ? editedCause : cause
					),
				},
			},
		});
	}
};
