import React, { memo } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Permissions from 'Models/permissions/Permissions';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';
import { FarmableTextColors } from 'UI/theme/Colors';

import usePermission from 'Hooks/usePermission';

import GET_COMPANY_INVITED_USERS from '../../api/GetCompanyInvitedUsers';
import InviteModal from '../InviteModal';

const ContentHeader = memo(() => {
	const { t } = useTranslation();

	const { data, loading, error } = useQuery(GET_COMPANY_INVITED_USERS);

	const hasInviteMemberAccess = usePermission(
		Permissions.PARTNER_PORTAL_INVITE_MEMBER
	);

	if (loading) {
		return <LoadingSpinner />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const membersCount = data.getCompanyInvitedUsers.length || 0;

		return (
			<Box display="flex" justifyContent="space-between" m="1.5rem">
				<Text color={FarmableTextColors.PRIMARY} variant="h3">
					{t('managePartnerMembers.membersAmount', { count: membersCount })}
				</Text>
				{hasInviteMemberAccess ? <InviteModal /> : null}
			</Box>
		);
	}

	return null;
});

export default ContentHeader;
