import { TAccount } from './isAccount';

function getAccountName(
	account: Pick<TAccount, 'firstName' | 'lastName'>
): string | undefined {
	const { firstName, lastName } = account;

	if (firstName && lastName) {
		return `${firstName} ${lastName}`;
	} else if (firstName) {
		return firstName;
	} else if (lastName) {
		return lastName;
	} else {
		return undefined;
	}
}

export default getAccountName;
