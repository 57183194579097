import React from 'react';

import QS from 'qs';
import URI from 'urijs';

function useAuth0Error(): void {
	const currentURL = window.location.href;
	const parsed = new URI(currentURL);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setError] = React.useState(undefined);

	const query = QS.parse(parsed.query(), { arrayLimit: 2000 });

	React.useEffect(() => {
		if (query.error && query.error_description) {
			const { error, error_description } = query;

			// https://github.com/facebook/react/issues/14981#issuecomment-468460187
			setError(() => {
				// We should consider better implementation of throwing the errors below #3454
				throw new Error(`${error}: ${error_description}`);
			});
		}
	}, [currentURL]);
}

export default useAuth0Error;
