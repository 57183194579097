import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';

type TDeleteLibraryTreatmentResult = {
	deleteLibraryTreatment: TTreatmentLibrary;
};

type TDeleteLibraryTreatmentInput = {
	libraryId: string;
	productId: string;
	treatmentId: string;
};

export const DELETE_LIBRARY_TREATMENT: TypedDocumentNode<
	TDeleteLibraryTreatmentResult,
	TDeleteLibraryTreatmentInput
> = gql`
	mutation deleteLibraryTreatment(
		$libraryId: ID!
		$productId: ID!
		$treatmentId: ID!
	) {
		deleteLibraryTreatment(
			libraryId: $libraryId
			productId: $productId
			treatmentId: $treatmentId
		) {
			id
		}
	}
`;

export const removeDeletedLibraryTreatmentFromCache: MutationUpdaterFunction<
	TDeleteLibraryTreatmentResult,
	TDeleteLibraryTreatmentInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, data, { variables }) => {
	const productCacheId = cache.identify({
		__typename: 'Product',
		id: variables?.productId,
	});

	if (!productCacheId) {
		return;
	}

	cache.evict({
		id: productCacheId,
		fieldName: 'treatments',
	});
};
