import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import useCurrentLanguage from 'Features/localization/hooks/useCurrentLanguage';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	PlantMilestones,
	TPlantMilestone,
} from 'Models/products/PlantMilestone';
import Text from 'UI/display/Text';
import Select from 'UI/inputs/Select';
import Grid from 'UI/layout/Grid';
import { FarmableTextColors } from 'UI/theme/Colors';

import GDDTimeline from '../../../../../../../../../../../../components/GDDTimeline';
import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import { treatmentInputState } from '../../../../../state';

function RelevantDevelopmentStage() {
	const { t } = useTranslation();
	const treatmentInput = useReactiveVar(treatmentInputState);
	const currentLang = useCurrentLanguage();

	const [fromMilestone, toMilestone] = treatmentInput?.plantMilestones || [];
	const [from, setFrom] = useState<TPlantMilestone | undefined>(fromMilestone);
	const [to, setTo] = useState<TPlantMilestone | undefined>(toMilestone);
	const milestonesTranslation = t('plantMilestone', {
		returnObjects: true,
		lng: currentLang,
	});

	useEffect(() => {
		if (treatmentInput) {
			treatmentInputState({
				...treatmentInput,
				plantMilestones: from && to ? [from, to] : undefined,
			});
		}

		if (treatmentInput && fromMilestone && toMilestone) {
			setFrom(fromMilestone);
			setTo(toMilestone);
		}
	}, [from, to, treatmentInput]);

	return (
		<ModalSection title={t('labels.relevantDevelopmentStage')}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Select
						value={from || ''}
						label={
							<Text color={FarmableTextColors.SECONDARY}>
								{t('labels.from')}
							</Text>
						}
						options={PlantMilestones}
						optionFormatter={(mil) => milestonesTranslation[mil]}
						changeHandler={setFrom}
						small
					/>
				</Grid>
				<Grid item xs={6}>
					<Select
						value={to || ''}
						label={
							<Text color={FarmableTextColors.SECONDARY}>{t('labels.to')}</Text>
						}
						options={PlantMilestones}
						optionFormatter={(mil) => milestonesTranslation[mil]}
						changeHandler={setTo}
						small
					/>
				</Grid>
			</Grid>
			<GDDTimeline from={fromMilestone} to={toMilestone} />
			<Text variant="secondaryBody2">
				{t('treatmentLibrary.cropModal.hint.gdd')}
			</Text>
		</ModalSection>
	);
}

export default RelevantDevelopmentStage;
