import React, { Fragment, ReactNode, ReactElement } from 'react';

import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

type TProps = {
	title?: string;
	children: ReactNode;
	noDivider?: boolean;
};

function ModalSection(props: TProps): ReactElement {
	const { title, noDivider, children } = props;
	const divider = noDivider ? null : (
		<Box paddingTop={2} paddingBottom={2}>
			<Divider variant="fullWidth" />
		</Box>
	);

	return (
		<Fragment>
			{title && (
				<Text variant="label" component="p" gutterBottom>
					{title}
				</Text>
			)}
			{children}
			{divider}
		</Fragment>
	);
}

export default ModalSection;
