import { gql, TypedDocumentNode } from '@apollo/client';
import { TCause, TCauseType } from 'Models/causes/isCause';

type TGetPartnershipCausesResponse = {
	getPartnershipCauses: { causes: Pick<TCause, 'id' | 'name'>[] };
};

type TGetPartnershipCausesInput = {
	filterBy: {
		name?: string;
		type?: TCauseType;
	};
};

const GET_PARTNERSHIP_CAUSES: TypedDocumentNode<
	TGetPartnershipCausesResponse,
	TGetPartnershipCausesInput
> = gql`
	query getPartnershipCauses(
		$filterBy: CauseFilterInput
		$pageInput: PageInput
	) {
		getPartnershipCauses(filterBy: $filterBy, pageInput: $pageInput) {
			causes {
				id
				name
			}
		}
	}
`;

export default GET_PARTNERSHIP_CAUSES;
