import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import useHistory from 'Features/router/hooks/useHistory';
import useLocation from 'Features/router/hooks/useLocation';
import URI from 'urijs';

const Auth0ProviderWithHistory: React.FC = ({ children }) => {
	const history = useHistory();
	const location = useLocation();

	const onRedirectCallback = () => {
		const { destination = '' } = URI.parseQuery(location.search);

		history.push(destination);
	};

	return (
		<Auth0Provider
			domain={process.env.AUTH0_DOMAIN || ''}
			clientId={process.env.AUTH0_CLIENT_ID || ''}
			authorizationParams={{
				redirect_uri: process.env.AUTH0_REDIRECT_URI,
				audience: process.env.AUTH0_AUDIENCE,
			}}
			onRedirectCallback={onRedirectCallback}
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithHistory;
