import { gql, TypedDocumentNode } from '@apollo/client';
import { TCustomer } from 'Models/customers/isCustomer';
import { TCustomerInput } from 'Models/customers/isCustomerInput';

const CREATE_CUSTOMER_MUTATION: TypedDocumentNode<
	{
		createCustomer: Pick<TCustomer, 'id'>;
	},
	{
		locationId: string;
		input: TCustomerInput;
	}
> = gql`
	mutation CreateCustomer($locationId: ID!, $input: CustomerInput!) {
		createCustomer(locationId: $locationId, input: $input) {
			id
		}
	}
`;

export default CREATE_CUSTOMER_MUTATION;
