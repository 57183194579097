import { THoursExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';

import { TPartnerTimesheets } from '../types';
import getPartnerTimesheetsLogsRowDataForExport from './getPartnerTimesheetsLogsRowDataForExport';

function getExportData(
	timesheets: TPartnerTimesheets[],
	dictionary: THoursExportHeadersDictionary
) {
	const exportData = timesheets.map((timesheet) =>
		getPartnerTimesheetsLogsRowDataForExport(timesheet, dictionary)
	);

	return {
		exportData,
	};
}

export default getExportData;
