import React from 'react';

import { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Grid from 'UI/layout/Grid';

import useLocationID from 'Hooks/useLocationID';

import CropSummary from '../../../common/components/CropSummary';
import getSummarySectionData from '../../../common/utils/getSummarySectionData';
import GET_PARTNER_FIELDS_DATA from './api/getPartnerFieldsData';

export default function CropSummarySection() {
	const locationID = useLocationID();
	const { data, loading, error } = useQuery(GET_PARTNER_FIELDS_DATA, {
		skip: !locationID,
		variables: {
			filterBy: {
				locationIds: [locationID],
			},
		},
	});
	const { t } = useTranslation();

	if (loading) {
		return (
			<Grid container justifyContent="center" alignItems="center">
				<LoadingSpinner width={64} height={64} />
			</Grid>
		);
	}

	if (data) {
		const fields = data.getPartnerFields.fields.map((field) => field.field);
		const summarySectionData = getSummarySectionData(fields, t);

		return <CropSummary varieties={summarySectionData} />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
}
