import { gql, TypedDocumentNode } from '@apollo/client';
import { TGovernmentProductTreatmentInput } from 'Models/products/ProductTreatmentInput';

type TCreateTreatmentLibraryGovernmentTreatmentResult = {
	createTreatmentLibraryGovernmentProduct: { id: string };
};

type TCreateTreatmentLibraryGovernmentTreatmentInput = {
	libraryId: string;
	governmentTreatmentId: string;
	input: TGovernmentProductTreatmentInput;
};

export const CREATE_TREATMENT_LIBRARY_GOVERNMENT_TREATMENT: TypedDocumentNode<
	TCreateTreatmentLibraryGovernmentTreatmentResult,
	TCreateTreatmentLibraryGovernmentTreatmentInput
> = gql`
	mutation createTreatmentLibraryGovernmentTreatment(
		$libraryId: ID!
		$governmentTreatmentId: ID!
		$input: GovernmentProductTreatmentInput!
	) {
		createTreatmentLibraryGovernmentTreatment(
			libraryId: $libraryId
			governmentTreatmentId: $governmentTreatmentId
			input: $input
		) {
			id
		}
	}
`;
