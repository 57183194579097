import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { GovernmentProductTreatment } from 'Models/treatmentLibrary/governmentProductTreatment';

type TGetGovernmentTreatmentForTreatmentLibraryResponse = {
	getGovernmentTreatmentsForTreatmentLibrary: {
		totalPages: number;
		totalElements: number;
		content: GovernmentProductTreatment[];
	};
};

type TGetGovernmentTreatmentForTreatmentLibraryRequest = {
	libraryId: string;
	filterBy?: {
		categoryId?: string;
		productId?: string;
		productName?: string;
		productRegistrationNumber?: string;
		causeIds?: string[];
	};
	pageInput?: TPageInput;
};

export const GET_GOVERNMENT_TREATMENTS_FOR_TREATMENT_LIBRARY: TypedDocumentNode<
	TGetGovernmentTreatmentForTreatmentLibraryResponse,
	TGetGovernmentTreatmentForTreatmentLibraryRequest
> = gql`
	query GetGovernmentTreatmentsForTreatmentLibrary(
		$libraryId: ID!
		$filterBy: TreatmentFilterInput
		$pageInput: PageInput!
	) {
		getGovernmentTreatmentsForTreatmentLibrary(
			libraryId: $libraryId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			content {
				id
				productName
				amount
				unit
				varietyCategory {
					id
					name
					localizedName
					label
					categoryType
					categoryGroup
					faoCode
					siexCode
					swissCode
					swissLanguage
					germanyCode
					discriminator
				}
				treatmentCauses {
					id
					name
					createdAt
					discriminator
					type
					causeType
					description
					spanishSpec {
						scientificName
						siexCode
						eppoCode
					}
					governmentCauseSpec {
						governmentCauseId
						country
					}
					causeImages {
						id
						path
						filename
						mimetype
						createdAt
					}
				}
			}
		}
	}
`;
