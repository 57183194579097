import React, { ReactElement } from 'react';

import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

type StatementContentProps = {
	statementText: string;
	helperText: string;
};

const StatementContent = (props: StatementContentProps): ReactElement => {
	const { statementText, helperText } = props;

	return (
		<>
			<Box marginBottom="0.5rem">
				<Text variant="h3">{statementText}</Text>
			</Box>
			<Box marginBottom="0.5rem">
				<Text variant="secondaryBody1">{helperText}</Text>
			</Box>
		</>
	);
};

export default StatementContent;
