import React, { ReactElement } from 'react';

import MUITypography, { TypographyProps } from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { FarmableTextColors, TTextColors } from '../../theme/Colors';

const CustomVariants = {
	modalHeading: {
		variant: 'modalHeading',
		defaultVariant: 'h4',
		styles: {
			fontSize: '1.5rem',
		},
	},
	label: {
		variant: 'label',
		defaultVariant: 'caption',
		styles: {
			fontSize: '0.875rem',
			letterSpacing: '0.25px',
		},
	},
	label1: {
		variant: 'label1',
		defaultVariant: 'caption',
		styles: {
			fontSize: '0.75rem',
			letterSpacing: '0.25px',
		},
	},
	label2: {
		variant: 'label2',
		defaultVariant: 'caption',
		styles: {
			fontSize: '1rem',
			letterSpacing: '0.25px',
		},
	},
	label3: {
		variant: 'label3',
		defaultVariant: 'body1',
		styles: {
			fontSize: '0.625rem',
			letterSpacing: '0.2px',
			fontWeight: 'bold',
		},
	},
	label4: {
		variant: 'label4',
		defaultVariant: 'body1',
		styles: {
			fontSize: '1rem',
			lineHeight: '1.25',
			fontWeight: 'normal',
		},
	},
	label5: {
		variant: 'label5',
		defaultVariant: 'caption',
		styles: {
			fontSize: '0.875rem',
			lineHeight: '1rem',
		},
	},
	label6: {
		variant: 'label6',
		defaultVariant: 'body1',
		styles: {
			fontSize: '0.875rem',
			lineHeight: '1rem',
			fontWeight: 'normal',
		},
	},
	secondaryBody1: {
		variant: 'secondaryBody1',
		defaultVariant: 'body1',
		styles: {
			fontSize: '1rem',
			color: FarmableTextColors.SECONDARY,
			fontWeight: 'normal',
		},
	},
	secondaryBody2: {
		variant: 'secondaryBody2',
		defaultVariant: 'body2',
		styles: {
			fontSize: '0.875rem',
			color: FarmableTextColors.SECONDARY,
			fontWeight: 'normal',
		},
	},
	body3: {
		variant: 'body3',
		defaultVariant: 'body2',
		styles: {
			fontSize: '0.75rem',
			fontWeight: 'normal',
		},
	},
	secondaryBody3: {
		variant: 'secondaryBody3',
		defaultVariant: 'body2',
		styles: {
			fontSize: '0.75rem',
			color: FarmableTextColors.SECONDARY,
			fontWeight: 'normal',
			lineHeight: '1.25rem',
		},
	},
	body4: {
		variant: 'body4',
		defaultVariant: 'body2',
		styles: {
			fontSize: '1.25rem',
			fontWeight: 'normal',
		},
	},
} as const;

const VARIANT_MAPPING = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	modalHeading: 'h4',
	h5: 'h5',
	h6: 'h6',
	subtitle: 'h6',
	label: 'p',
	label1: 'p',
	label2: 'p',
	label3: 'p',
	label4: 'p',
	label5: 'p',
	body1: 'p',
	body2: 'p',
	body3: 'p',
	body4: 'p',
	secondaryBody1: 'p',
	secondaryBody2: 'p',
	secondaryBody3: 'p',
} as const;

type TTypographyCustomVariants = keyof typeof CustomVariants;

type TCustomTypographyProps = {
	component?: keyof HTMLElementTagNameMap;
	variant?: TypographyProps['variant'] | TTypographyCustomVariants;
	overrideMUIColor?: TTextColors | 'INHERIT';
	textTransform?: 'capitalize' | 'uppercase' | 'lowercase';
};

type TTextProps = Omit<TypographyProps, 'variant'> & TCustomTypographyProps;

function _isCustomVariant(
	variant: TTextProps['variant']
): variant is TTypographyCustomVariants {
	return variant ? variant in CustomVariants : false;
}

type TStyleArgs = {
	overrideMUIColor?: TTextColors | 'INHERIT';
	textTransform?: 'capitalize' | 'uppercase' | 'lowercase';
};

const useStyles = makeStyles({
	root: {
		color: ({ overrideMUIColor }: TStyleArgs) =>
			overrideMUIColor
				? overrideMUIColor === 'INHERIT'
					? 'inherit'
					: FarmableTextColors[overrideMUIColor]
				: undefined,
		textTransform: ({ textTransform }: TStyleArgs) => textTransform,
	},
});

const Text = (props: TTextProps): ReactElement => {
	const { variant, textTransform, overrideMUIColor, classes, ...MUIProps } =
		props;

	const TypographyVariant = _isCustomVariant(variant)
		? CustomVariants[variant].defaultVariant
		: variant;

	function setCustomVariants(globalTheme) {
		const customVariant = variant && CustomVariants[variant];

		return customVariant
			? {
					...globalTheme,
					typography: {
						[customVariant.defaultVariant]: {
							lineHeight: '1.5rem',
							fontWeight: 'bold',
							color: FarmableTextColors.PRIMARY,
							...customVariant.styles,
						},
					},
			  }
			: globalTheme;
	}

	const style = useStyles({ overrideMUIColor, textTransform });

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={setCustomVariants}>
				<MUITypography
					{...MUIProps}
					variant={TypographyVariant}
					variantMapping={VARIANT_MAPPING}
					classes={{
						root: style.root,
						...classes,
					}}
				/>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default Text;
