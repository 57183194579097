import { Locale } from 'date-fns';

import getDurationTimeString from 'Utils/durationTimeString';

import { TJob } from './isJob';
import jobDurationTimeInMs from './jobDurationTimeInMs';

function jobDurationTimeString(
	job: Pick<
		TJob,
		'runStatusHistory' | 'startDate' | 'completedDate' | 'trackRaw'
	>,
	locale?: Locale
): string {
	if (job.startDate && job.completedDate) {
		const jobDurationInMs = jobDurationTimeInMs(job);

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const hasPositiveDatesDifference = jobDurationInMs! > 0;
		const duration = jobDurationInMs
			? getDurationTimeString(0, jobDurationInMs, locale)
			: null;

		return hasPositiveDatesDifference && duration ? duration : '-';
	}

	return '-';
}

export default jobDurationTimeString;
