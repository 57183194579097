import React, { ReactNode } from 'react';

import Container, { ContainerProps } from '@mui/material/Container';
import classnames from 'classnames';

import STYLES from './styles.module.scss';

type TPageProps = {
	children?: ReactNode;
	center?: boolean;
	noMinHeight?: boolean;
};

const Page = (props: TPageProps & ContainerProps) => {
	const { center, noMinHeight, children, ...rest } = props;

	const className = classnames(
		STYLES.base,
		center ? STYLES.center : null,
		noMinHeight ? STYLES.noMinHeight : null
	);

	return (
		<Container className={className} {...rest}>
			{children}
		</Container>
	);
};

Page.defaultProps = {
	center: false,
};

export default Page;
