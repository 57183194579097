import React, { Fragment } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldVariety } from 'Models/fields/isFieldVariety';
import getJobFieldVarieties from 'Models/jobs/getJobFieldVarieties';
import { TJobFieldSnapshot } from 'Models/jobs/isJobFieldSnapshot';
import jobAreaWithUnit from 'Models/jobs/jobAreaWithUnit';
import { AreaUnits } from 'Models/units';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';

import truncateString from 'Utils/truncateString';

import DetailModalSection from '../../../../../components/ModalSection';

type TFieldsProps = {
	job: {
		jobFields?:
			| (Pick<TJobFieldSnapshot, 'id' | 'name' | 'areaSize'> & {
					fieldVarieties?: Pick<TFieldVariety, 'name' | 'category'>[] | null;
			  })[]
			| null;
	};
};

const MAX_FIELD_NAME_LENGTH = 20;

const Fields = (props: TFieldsProps) => {
	const { job } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.jobLogsPage.detailsModal.';

	const titleSuffix =
		t(`${prefix}sections.fields`) + job.jobFields
			? ` (${job.jobFields?.length})`
			: '';

	const fieldRows = job.jobFields?.map((jobField) => (
		<Fragment key={`row_${jobField.id}`}>
			<Grid item xs={4}>
				{truncateString(jobField.name || '', MAX_FIELD_NAME_LENGTH)}
			</Grid>
			<Grid item xs={8}>
				{getJobFieldVarieties(jobField)}
			</Grid>
		</Fragment>
	));

	const jobArea = jobAreaWithUnit(job, { areaUnit: AreaUnits.HECTARE });

	return (
		<DetailModalSection title={t(`${prefix}sections.fields`) + titleSuffix}>
			<Text component="p" gutterBottom>
				{t(`${prefix}props.totalArea`)}: {jobArea}
			</Text>
			<Grid container spacing={0.8}>
				<Grid item xs={4}>
					<Text variant="label" component="p">
						{t(`${prefix}sections.fieldName`)}
					</Text>
				</Grid>
				<Grid item xs={8}>
					<Text variant="label" component="p">
						{t(`${prefix}sections.varieties`)}
					</Text>
				</Grid>
				{fieldRows}
			</Grid>
		</DetailModalSection>
	);
};

export default Fields;
