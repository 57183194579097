import getTimezoneOffsetInMs from './getTimezoneOffsetInMs';

type TGetTimezoneOffset = number | (() => number);

function UTCToLocalTime(
	UTCDateString: string,
	timezoneOffsetInMs: TGetTimezoneOffset = getTimezoneOffsetInMs
): Date {
	const UTCDate = new Date(UTCDateString);
	const UTCDateInMs = UTCDate.getTime();
	let timezoneOffset: number;

	if (typeof timezoneOffsetInMs === 'number') {
		timezoneOffset = timezoneOffsetInMs;
	} else {
		timezoneOffset = timezoneOffsetInMs();
	}

	return new Date(UTCDateInMs - timezoneOffset);
}

export default UTCToLocalTime;
