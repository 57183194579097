import isArrayOf from 'vet/arrays/isArrayOf';
import isAllOf from 'vet/isAllOf';
import isOneOf from 'vet/isOneOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import { ValidatorType } from 'vet/types';

import { isCoordinatePoint, TCoordinatePoint } from './isCoordinatePoint';
import { isFieldType } from './isFieldType';
import { ProductionMethod } from './isProductionMethod';
import isVariety from './isVariety';

export const isGeometry = isArrayOf(isArrayOf(isCoordinatePoint));
export type TGeometry = TCoordinatePoint[][];

export const fieldSchema = {
	id: isString,
	areaSize: optional(isNumber),
	averageDistanceBetweenTreesInRows: optional(isNumber),
	averageDistanceBetweenRows: optional(isNumber),
	name: optional(isString),
	subCategory: optional(isString),
	varieties: optional(isArrayOf(isVariety)),
	rootstock: optional(isString),
	treeSupplier: optional(isString),
	productiveArea: optional(isNumber),
	reentryAllowedDate: optional(isString),
	harvestAllowedDate: optional(isString),
	fieldType: optional(isFieldType),
	plantHeight: optional(isNumber),
	numberOfPlants: optional(isNumber),
	productionMethod: optional(isOneOf(...Object.values(ProductionMethod))),
};

const geometrySchema = {
	geometry: isGeometry,
};

const _isField = isShape({ ...fieldSchema, ...geometrySchema });
const _isFieldWithoutGeometry = isAllOf(
	isShape(fieldSchema),
	isShape.partial(geometrySchema)
);

// https://github.com/microsoft/TypeScript/issues/34596
const isField: typeof _isField = _isField;
export const isFieldWithoutGeometry: typeof _isFieldWithoutGeometry =
	_isFieldWithoutGeometry;

export type TField = ValidatorType<typeof isField>;
export type TFieldWithoutGeometry = ValidatorType<
	typeof isFieldWithoutGeometry
>;

export default isField;
