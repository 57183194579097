import React, { ReactElement } from 'react';

import { TAccount } from 'Models/account/isAccount';
import Avatar from 'UI/display/Avatar';

import UploadInput from '../../../../components/UploadInput';
import styles from './styles.module.scss';

type ImageProps = { account: TAccount };

function ProfileAvatar(props: ImageProps): ReactElement {
	const account = props.account;
	const ProfileAvatarIcon = (
		<Avatar
			size="xxl"
			src={account.profileImage?.path}
			alt={account.firstName || undefined}
		/>
	);

	return (
		// After enabling UploadInput, delete profileAvatar class!
		<div className={styles.profileAvatar}>
			{ProfileAvatarIcon}
			{
				// Hidden since the functionality is not ready yet.
				// <UploadInput text="Update image" />
			}
		</div>
	);
}

export default ProfileAvatar;
