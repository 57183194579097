import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Button from 'UI/inputs/Button';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../state';
import styles from '../styles.module.scss';

type TNextButtonProps = {
	isLoading: boolean;
	onClose: () => void;
};

function PreviousButton(props: TNextButtonProps) {
	const { isLoading, onClose } = props;
	const status = useAddJobModalStatus();
	const jobInput = useJobInputData();
	const { t } = useTranslation();

	const previousHandler = () => {
		if (status === 'SET_CAUSE_AND_TREATMENTS') {
			onClose();
		}

		if (status === 'SET_DATE_AND_COMMENT') {
			addJobModalState({
				jobInputData: jobInput,
				status: 'SET_CAUSE_AND_TREATMENTS',
			});
		}

		if (status === 'CREATE_AND_SHARE_LOCATIONS') {
			addJobModalState({
				jobInputData: jobInput,
				status: 'SET_DATE_AND_COMMENT',
			});
		}
	};

	return (
		<Button
			variant="text"
			onClick={previousHandler}
			disabled={isLoading}
			className={styles.backButton}
		>
			{status === 'SET_CAUSE_AND_TREATMENTS'
				? t('buttons.cancel')
				: t('buttons.back')}
		</Button>
	);
}

export default PreviousButton;
