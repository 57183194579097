import React from 'react';

import useHistory from 'Features/router/hooks/useHistory';

import useLocations from 'Hooks/useLocations';
import usePartnerCompany from 'Hooks/usePartnerCompany';
import usePartnerLocations from 'Hooks/usePartnerLocations';
import useProfile from 'Hooks/useProfile';
import useProfileRoles from 'Hooks/useProfileRoles';
import hasPartnerCompany from 'Utils/hasPartnerCompany';

import SplashPage from '../../SplashPage';

const PageBody = () => {
	const history = useHistory();
	const { profile, loading: profileLoading } = useProfile();
	const { profileRoles, loading: profileRolesLoading } = useProfileRoles();
	const { locations, loading: locationsLoading } = useLocations();
	const { company, loading: companyLoading } = usePartnerCompany();
	const { partnerLocations, loading: partnerLocationsLoading } =
		usePartnerLocations();

	if (
		profileLoading ||
		profileRolesLoading ||
		companyLoading ||
		locationsLoading ||
		partnerLocationsLoading
	) {
		return <SplashPage />;
	}

	if (company && partnerLocations) {
		const subpath = partnerLocations.length ? 'dashboard' : 'welcome';

		history.push(`/partners/${company.id}/${subpath}`);
		return null;
	}

	if (!hasPartnerCompany(profileRoles) && profile?.enabledCompanyType) {
		// no company yet but has enabled company creation
		history.push('/create-company');
		return null;
	}

	if (locations && !hasPartnerCompany(profileRoles)) {
		const firstLocation = locations[0];
		const path = firstLocation
			? `/locations/${firstLocation.id}/dashboard`
			: '/no-access';

		history.push(path);
		return null;
	}

	return null;
};

export default PageBody;
