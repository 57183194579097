import { ReactNode } from 'react';

const JOB_LOGS_TABLE_HEADERS = [
	'completedDate',
	'category',
	'fields',
	'area',
	'cause',
	'treatmentType',
	'product',
	'jobStatus',
] as const;

const LogsTableHeaderValues = [...JOB_LOGS_TABLE_HEADERS];

export type TJobLogsTableHeader = typeof JOB_LOGS_TABLE_HEADERS[number];

export default LogsTableHeaderValues;

export type TJobLogsTableData = {
	[key in typeof JOB_LOGS_TABLE_HEADERS[number]]: ReactNode;
};
