import { gql, TypedDocumentNode } from '@apollo/client';
import { TLocationInput } from 'Models/locations/isLocationInput';

type TInput = Pick<
	TLocationInput,
	'name' | 'businessName' | 'address' | 'zip' | 'city'
>;

const UPDATE_LOCATION_MUTATION: TypedDocumentNode<
	{
		updatePartnershipCompany: { id: string };
	},
	{
		id: string;
		input: TInput;
	}
> = gql`
	mutation updateCurrentLocation($id: ID!, $input: LocationInput!) {
		updateLocation(id: $id, input: $input) {
			id
			name
			businessName
			address
			city
			zip
			country
		}
	}
`;

export default UPDATE_LOCATION_MUTATION;
