import { gql, TypedDocumentNode } from '@apollo/client';

import { TEditPlannerJobTreatmentInput } from '../types';

const EDIT_PLANNER_JOB_TREATMENT: TypedDocumentNode<
	{
		editPlannerJobTreatment: { id: string };
	},
	TEditPlannerJobTreatmentInput
> = gql`
	mutation editPlannerJobTreatment(
		$plannerJobId: ID!
		$treatmentId: ID!
		$input: JobTreatmentInput!
	) {
		editPlannerJobTreatment(
			plannerJobId: $plannerJobId
			treatmentId: $treatmentId
			input: $input
		) {
			id
		}
	}
`;

export default EDIT_PLANNER_JOB_TREATMENT;
