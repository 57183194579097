import { Locale } from 'date-fns';

function toCurrencyDisplay(
	value: number,
	locale: Locale['code'],
	currency?: string
) {
	return currency
		? Intl.NumberFormat(locale, {
				style: 'currency',
				currency,
		  }).format(value)
		: value;
}
export default toCurrencyDisplay;
