import React, { FC, useCallback, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	ReportBreakdown,
	ReportFormat,
	ReportType,
	TReportFormat,
} from 'Models/exports/Report';
import { JobStatuses } from 'Models/jobs/JobStatuses';
import getSeasonPerDay from 'Models/seasons/getSeasonPerDay';
import Modal from 'UI/display/modal/dialog';

import useCurrentLocation from 'Hooks/useCurrentLocation';

import {
	CREATE_REPORT_TAB_STATE,
	ReportFormInterface,
	TCreateReportType,
} from '../CreateReport.types';
import { CreateReportModalBody } from './CreateReortModalBody';
import { CreateReportModalControls } from './CreateReportModalControls';

interface CreateReportModalInterface {
	reportType: TCreateReportType;
	onClose: () => void;
	onSubmit: ({
		reportInput,
		reportFormat,
	}: {
		reportInput: ReportFormInterface;
		reportFormat: TReportFormat;
	}) => void;
}

export const CreateReportModal: FC<CreateReportModalInterface> = ({
	reportType,
	onClose,
	onSubmit,
}) => {
	const location = useCurrentLocation();
	const currentSeason = getSeasonPerDay(
		location?.seasonCutoffDate || '01-01',
		new Date()
	);
	const { t } = useTranslation();
	const [tabState, setTabState] = useState(CREATE_REPORT_TAB_STATE.form);
	const [reportInput, setReportInput] = useState<ReportFormInterface>({
		dateFrom: new Date(currentSeason.from).toISOString(),
		dateTo: new Date(currentSeason.to).toISOString(),
		status: [JobStatuses.COMPLETED],
		categories: [],
		fields: [],
		teamId: [],
		teamMemberIds: [],
		varieties: [],
		breakdown: ReportBreakdown.JOB,
		format: [ReportType.HARVEST, ReportType.PRODUCT_INVENTORY].includes(
			reportType
		)
			? ReportFormat.XLSX
			: ReportFormat.PDF,
		quality: [],
		products: [],
	});

	const handleNextClick = useCallback(() => {
		setTabState(CREATE_REPORT_TAB_STATE.summary);
	}, []);

	const handleBackClick = useCallback(() => {
		setTabState(CREATE_REPORT_TAB_STATE.form);
	}, []);

	return (
		<Modal.UncontrolledModal open={true} toggleOpen={onClose}>
			<Modal.DialogTitle
				title={t(`treatmentLogsExportPage.reportTitle.${reportType}`)}
				closeIconHandler={onClose}
			/>
			<Modal.DialogContent>
				<CreateReportModalBody
					reportInput={reportInput}
					onChange={setReportInput}
					tabState={tabState}
					reportType={reportType}
				/>
			</Modal.DialogContent>
			<Modal.DialogActions>
				<CreateReportModalControls
					reportInput={reportInput}
					onNextClick={handleNextClick}
					onBackClick={handleBackClick}
					onCancel={onClose}
					onSubmit={onSubmit}
					tabState={tabState}
					onChange={setReportInput}
					reportType={reportType}
				/>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};
