import React from 'react';

import Divider from 'UI/display/Divider';

import AdditionalInfo from './components/AdditionalInfo';
import ApplicationRequirements from './components/ApplicationRequirements';
import Composition from './components/Composition';
import GeneralInfo from './components/GeneralInfo';
import OptionsAccordion from './components/OptionsAccordion';
import ProductName from './components/ProductName';
import ProductType from './components/ProductType';
import SpanishProductDetails from './components/SpanishProductDetails';

function ProductDetails() {
	return (
		<div style={{ minHeight: '26rem', maxHeight: '26rem' }}>
			<ProductName />
			<ProductType />
			<SpanishProductDetails />
			<Divider sx={{ marginTop: '1rem', marginBottom: '0.5rem' }} />
			<OptionsAccordion>
				<GeneralInfo />
				<Composition />
				<ApplicationRequirements />
				<AdditionalInfo />
			</OptionsAccordion>
		</div>
	);
}

export default ProductDetails;
