import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import NavbarLink from '../../NavbarLink';

const PartnerNavigationItems = () => {
	const { company } = usePartnerCompany();

	const { t } = useTranslation();

	if (!company) {
		return null;
	}

	const dashboardLink = (
		<NavbarLink
			label={t('common.navbar.dashboard')}
			link={`/partners/${company?.id}/dashboard`}
			checkActiveHandler={(_, location) =>
				location.pathname.includes('dashboard')
			}
		/>
	);

	const logsLink = (
		<NavbarLink
			label={t('common.navbar.logs')}
			link={`/partners/${company.id}/logs/jobs`}
			checkActiveHandler={(_, location) => location.pathname.includes('logs')}
		/>
	);

	const plannerLink = (
		<NavbarLink
			label={t('common.navbar.planning')}
			link={`/partners/${company.id}/planner`}
			checkActiveHandler={(_, location) =>
				location.pathname.includes('planner')
			}
		/>
	);

	return (
		<>
			{dashboardLink}
			{logsLink}
			{plannerLink}
		</>
	);
};

export default PartnerNavigationItems;
