import React, { ReactElement, ReactNode } from 'react';

import MUITabs, { TabsProps as MUITabsProps } from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';

import { FarmableColors } from '../../theme/Colors';

type TControlledTabsProps = {
	children: ReactNode;
	value: number | string;
	valueChangeHandler?: (
		event: React.ChangeEvent<unknown>,
		value: number | string
	) => void;
	className?: string;
	disableIndicator?: boolean;
	variant?: 'rounded' | MUITabsProps['variant'];
	firstValue?: string | number;
};

type TTabsTheme = Pick<
	TControlledTabsProps,
	'disableIndicator' | 'variant' | 'value' | 'firstValue'
>;

const useStyles = makeStyles({
	root: {
		minHeight: '2.25rem',
		width: '100%',
	},
	indicator: {
		height: '3px',
		display: ({ disableIndicator }: TTabsTheme) =>
			disableIndicator ? 'none' : 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& > span': {
			backgroundColor: FarmableColors.ORANGE,
			borderTopLeftRadius: '0.5rem',
			borderTopRightRadius: '0.5rem',
			width: '100%',
			margin: '0 1.5rem',
		},
	},
	flexContainer: {
		alignItems: 'flex-end',
	},
	tabsContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	bottomLine: {
		borderColor: FarmableColors.DIVIDER,
		borderStyle: 'solid',
		borderWidth: 1,
		borderTopRightRadius: ({ variant }: TTabsTheme) =>
			variant === 'rounded' ? '0.5rem' : undefined,
		borderTopLeftRadius: ({ variant, value, firstValue }: TTabsTheme) =>
			variant === 'rounded' && value !== firstValue ? '0.5rem' : undefined,
		borderBottomWidth: 0,
		marginTop: -1,
		width: '100%',
		height: '10px',
	},
});

const Tabs = (
	props: TControlledTabsProps & Omit<MUITabsProps, 'variant'>
): ReactElement => {
	const {
		children,
		className,
		value,
		valueChangeHandler,
		disableIndicator,
		variant,
		scrollButtons,
		firstValue,
	} = props;
	const { root, indicator, flexContainer, bottomLine, tabsContainer } =
		useStyles({ disableIndicator, value, variant, firstValue });

	const divider =
		variant === 'rounded' ? <div className={bottomLine}></div> : null;

	return (
		<div className={tabsContainer}>
			<MUITabs
				value={value}
				onChange={valueChangeHandler}
				textColor="primary"
				indicatorColor="primary"
				className={className}
				classes={{
					root,
					indicator,
					flexContainer: variant === 'rounded' ? flexContainer : undefined,
				}}
				TabIndicatorProps={{ children: <span /> }}
				variant={variant === 'rounded' ? 'fullWidth' : variant} // rounded variant is a wrapper for fullWidth variant
				scrollButtons={scrollButtons}
			>
				{children}
			</MUITabs>
			{divider}
		</div>
	);
};

export default Tabs;
