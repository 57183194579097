// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._13cT7Vn9DcFqRmxEgC1tci {\n  width: 100%;\n  padding-left: 12px;\n  border-left: 1px solid rgba(0,0,0,.12);\n}\n\n", ""]);
// Exports
exports.locals = {
	"divider": "_13cT7Vn9DcFqRmxEgC1tci"
};
module.exports = exports;
