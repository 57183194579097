import { gql, TypedDocumentNode } from '@apollo/client';
import { TPartnerPlannerJobInput } from 'Models/planner/PartnerPlannerJobInput';

import { TCreateJobResponse } from '../types';

const CREATE_PLANNER_JOB: TypedDocumentNode<
	{ createPartnerPlannerJob: TCreateJobResponse },
	{
		input: TPartnerPlannerJobInput;
	}
> = gql`
	mutation createPartnerPlannerJob($input: PartnerPlannerJobInput!) {
		createPartnerPlannerJob(input: $input) {
			id
		}
	}
`;

export default CREATE_PLANNER_JOB;
