import React, { useState, useEffect } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CLEARED_FILTERS, URLFiltersType } from 'Models/history/isURLFilters';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import useManageHistory from 'Hooks/useManageHistory';

import Date from '../../../../components/filters/Date';
import PartnerCategories from '../../../../components/filters/PartnerCategories';
import PartnerFieldsList from '../../../../components/filters/PartnerFieldsList';
import PartnerLocations from '../../../../components/filters/PartnerLocations';
import PartnerVarieties from '../../../../components/filters/PartnerVarieties';
import Drawer from '../../common/components/Drawer';

const FilterModal = () => {
	const [open, setOpen] = useState(false);

	function toggleOpen() {
		setOpen((prevState) => !prevState);
	}

	const { t } = useTranslation();
	const { paramsFromURL: searchParams, historyPush } = useManageHistory();

	const paramsFilters = searchParams.filters;

	const [filters, setFilters] = useState<URLFiltersType>({
		...CLEARED_FILTERS,
	});

	useEffect(() => {
		setFilters({
			...paramsFilters,
			dateFrom: paramsFilters.dateFrom,
			dateTo: paramsFilters.dateTo,
			fieldCategories: paramsFilters.fieldCategories,
			locations: paramsFilters.locations,
			fieldVarieties: paramsFilters.fieldVarieties,
			fieldIds: filters.fieldIds,
		});
	}, [open]);

	const onSave = () => {
		const newSearchParams = {
			...searchParams,
			filters: {
				...searchParams.filters,
				dateFrom: filters.dateFrom,
				dateTo: filters.dateTo,
				fieldCategories: filters.fieldCategories,
				locations: filters.locations,
				fieldVarieties: filters.fieldVarieties,
				fieldIds: filters.fieldIds,
			},
		};

		historyPush(newSearchParams);
		toggleOpen();
	};

	const onClear = () => {
		setFilters({ ...CLEARED_FILTERS });
	};

	return (
		<>
			<Button
				startIcon={<OptionsIcon />}
				variant="secondary"
				onClick={toggleOpen}
			>
				{t('buttons.filters')}
			</Button>
			<Drawer.Container onHideClick={toggleOpen} open={open}>
				<Drawer.Header onHideClick={toggleOpen} />
				<Drawer.Content>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.date')}
					>
						<Date filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.filters')}
					>
						<Box>
							<PartnerCategories filters={filters} updateFilters={setFilters} />
							<PartnerLocations filters={filters} updateFilters={setFilters} />
							<PartnerVarieties
								filters={filters}
								updateFilters={setFilters}
								paramName="fieldVarieties"
							/>
						</Box>
					</Drawer.ContentSection>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.fields')}
					>
						<PartnerFieldsList filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
				</Drawer.Content>
				<Drawer.Footer>
					<Button variant="text" onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={onSave}>{t('buttons.save')}</Button>
				</Drawer.Footer>
			</Drawer.Container>
		</>
	);
};

export default FilterModal;
