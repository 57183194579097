import { gql, TypedDocumentNode } from '@apollo/client';

import {
	TPartnerFieldsSearchMapResponse,
	TPartnerFieldsSearchMapFilterInput,
} from '../types';

const GET_PARTNER_FIELDS_SEARCH_MAP_DATA: TypedDocumentNode<
	{
		getPartnerFields: TPartnerFieldsSearchMapResponse;
	},
	TPartnerFieldsSearchMapFilterInput
> = gql`
	query getPartnerFields($filterBy: PartnerFieldFilterInput) {
		getPartnerFields(filterBy: $filterBy) {
			fields {
				field {
					id
					name
					geometry {
						x
						y
					}
					reentryAllowedDate
					harvestAllowedDate
				}
			}
		}
	}
`;

export default GET_PARTNER_FIELDS_SEARCH_MAP_DATA;
