import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Step, StepIcon, Stepper } from '@mui/material';
import {
	PlantMilestones,
	TPlantMilestone,
} from 'Models/products/PlantMilestone';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

type TGDDTimelineProps = {
	from?: TPlantMilestone;
	to?: TPlantMilestone;
};
const getIndex = (milestone: TPlantMilestone | undefined) =>
	milestone ? PlantMilestones.findIndex((m) => m === milestone) : undefined;

function GDDTimeline(props: TGDDTimelineProps) {
	const { from, to } = props;
	const isInRange = (milIndex: number): boolean => {
		const fromIndex = getIndex(from);
		const toIndex = getIndex(to);

		if (fromIndex !== undefined && toIndex !== undefined) {
			if (fromIndex <= toIndex) {
				return fromIndex <= milIndex && milIndex <= toIndex;
			}
			if (fromIndex > toIndex) {
				return milIndex <= toIndex || fromIndex <= milIndex;
			}
		}

		return false;
	};

	return (
		<Box
			sx={{
				margin: '1rem 0 0.5rem 0',
				'& .MuiStepConnector-root': {
					height: '1px',
					color: FarmableColors.BLACK_38,
				},
			}}
		>
			<Stepper>
				{PlantMilestones.map((milestone, i) => (
					<Step
						key={milestone}
						sx={{
							padding: 0,
							margin: '0 -2px -3px -2px',
						}}
					>
						<StepIcon
							active={isInRange(i)}
							icon={
								isInRange(i) ? (
									<CircleIcon
										sx={{
											width: '1.125rem',
											height: '1.125rem',
											color: FarmableColors.ORANGE,
										}}
									/>
								) : (
									<RadioButtonUncheckedIcon
										color="primary"
										sx={{
											width: '1.125rem',
											height: '1.125rem',
											color: FarmableColors.BLACK_38,
										}}
									/>
								)
							}
						/>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}

export default GDDTimeline;
