import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SVG = require('./icon.react.svg').default;

const Icon = (props) => {
	const { ...rest } = props;

	return <SVG {...rest} />;
};

export default Icon;
