import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ErrorBoundary from '../../../components/ErrorBoundary';
import ProfilePageTemplate from '../common/components/ProfilePageTemplate';
import PageBody from './components/PageBody';

function PartnerCausesPage() {
	const { t } = useTranslation();

	return (
		<ProfilePageTemplate
			header={t('partnerCausesPage.title')}
			subheader={t('partnerCausesPage.subheader')}
		>
			<ErrorBoundary>
				<PageBody />
			</ErrorBoundary>
		</ProfilePageTemplate>
	);
}

export default PartnerCausesPage;
