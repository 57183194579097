import { TTeamMemberInput } from 'Models/teams/isTeamMemberInput';
import { TTeamMember } from 'Models/timesheet/isTeamMember';

function teamMembersByPosition<T extends TTeamMember | TTeamMemberInput>(
	a: T,
	b: T
) {
	if (typeof a.position === 'number' && typeof b.position === 'number') {
		return a.position - b.position;
	}
	return 0;
}

export default teamMembersByPosition;
