import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import fieldsBoundingBox from 'Models/fields/fieldsBoundingBox';
import getTotalFieldsArea from 'Models/fields/getTotalFieldsArea';
import getHarvestFields from 'Models/harvest/getHarvestFields';
import harvestFieldsNameString from 'Models/harvest/harvestFieldsNameString';
import { AreaUnits } from 'Models/units';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Map from 'UI/maps/Map';
import GMapLayer from 'UI/maps/layers/GMapLayer';

import getErrorMessage from 'Utils/getErrorMessage';

import DetailModalSection from '../../../../../../components/ModalSection';
import FieldPolygon from '../../../../../../components/fields/FieldPolygon';
import GET_PARTNER_HARVEST_MODAL_FIELDS_DATA from '../../../api/getPartnerFieldsModalData';
import { TPartnerHarvestTableData } from '../../../api/getPartnerHarvestsTableData';
import styles from '../styles.module.scss';

type InformationProps = {
	harvest: TPartnerHarvestTableData;
};

const Fields = (props: InformationProps) => {
	const { harvest } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.harvestLogsPage.detailsModal.';
	const { data, loading, error } = useQuery(
		GET_PARTNER_HARVEST_MODAL_FIELDS_DATA,
		{
			skip: harvest.fields.length < 1,
			variables: {
				filterBy: {
					locationIds: [harvest.location.id],
				},
			},
		}
	);

	if (loading) {
		return null;
	}

	if (error) {
		return (
			<DetailModalSection title={t(`${prefix}sections.fields`)} noDivider>
				<Text className={styles.fieldInformation}>
					{getErrorMessage(error)}
				</Text>
			</DetailModalSection>
		);
	}

	const locationFields = data?.getPartnerFields.fields;

	const harvestFields =
		locationFields && getHarvestFields(harvest, locationFields);

	const fieldLayers = locationFields?.map(({ field }) => (
		<FieldPolygon
			field={field}
			key={field.id}
			status={harvestFields?.includes(field) ? 'FOCUSED' : 'NOT_FOCUSED'}
		/>
	));

	const bounds = harvestFields && fieldsBoundingBox(harvestFields);

	return (
		<DetailModalSection title={t(`${prefix}sections.fields`)} noDivider>
			<Text className={styles.fieldInformation}>
				{harvestFields
					? `${harvestFieldsNameString(harvest)} · ${t(
							`${prefix}props.totalArea`
					  )} ${getTotalFieldsArea(harvestFields, AreaUnits.HECTARE)} ha`
					: '-'}
			</Text>
			{harvestFields?.length && bounds && (
				<Box className={styles.mapContainer}>
					<Map.Controlled
						bounds={bounds}
						boundsOptions={{ padding: [50, 50] }}
						maxBoundsViscosity={1}
						roundedCorners
					>
						<>
							{fieldLayers}
							<GMapLayer />
						</>
					</Map.Controlled>
				</Box>
			)}
		</DetailModalSection>
	);
};

export default Fields;
