import Content from './content';
import Controlled from './controlled';
import Header from './header';
import Uncontrolled from './uncontrolled';

const Export = {
	Controlled,
	Header,
	Content,
	Uncontrolled,
};

export default Export;
