/**
 * This function matches string's which are percentages from 1 to 100.
 * @param {number} value - string representation of percentage.
 * @returns {boolean}
 */
export default function isValidPercentageInput(value: string): boolean {
	const regexp = /\b^([1-9]|[1-9][0-9]|100)$\b/;

	return Boolean(regexp.exec(value) || value === '');
}
