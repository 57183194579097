import { gql, TypedDocumentNode } from '@apollo/client';
import { TTeamMember } from 'Models/timesheet/isTeamMember';

export type TGetTeamMembersResponse = {
	getTeamMembersPageable: {
		teamMembers: Pick<TTeamMember, 'id' | 'fullName'>[];
	};
};

type TGetTeamMembersInput = {
	locationId: string;
	filterBy?: {
		teamIds?: string[] | null;
	};
};

export const GET_TEAMS_MEMBERS_QUERY: TypedDocumentNode<
	TGetTeamMembersResponse,
	TGetTeamMembersInput
> = gql`
	query getTeamMembersPageable(
		$locationId: ID!
		$filterBy: TeamMembersFilterInput
	) {
		getTeamMembersPageable(locationId: $locationId, filterBy: $filterBy) {
			teamMembers {
				id
				fullName
			}
		}
	}
`;
