import React, { useRef, useEffect } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Container from 'UI/layout/Container';
import Grid from 'UI/layout/Grid';

import useManageHistory from '../../../../../hooks//useManageHistory';
import usePartnerCompany from '../../../../../hooks/usePartnerCompany';
import ExportLoadingSpinner from '../../../common/components/ExportLoadingSpinner';
import FieldLogs from '../../../common/components/FieldLogs';
import ProductsConsumption from '../../../common/components/ProductsConsumption';
import SprayLogFiltersCard from '../../../common/components/SprayLogFiltersCard';
import SprayLogHeader from '../../../common/components/SprayLogHeader';
import SprayLogInfoCard from '../../../common/components/SprayLogInfoCard';
import SprayLogTitle from '../../../common/components/SprayLogTitle';
import GET_PARTNER_JOBS_EXPORT_DATA from '../api/getPartnerJobsExportData';
import { TPartnerPDFExportData } from '../types';
import styles from './styles.module.scss';

const LIMIT = 50;

const ExportToPDF = () => {
	const printRef = useRef(null);
	const { company } = usePartnerCompany();
	const manageHistory = useManageHistory();
	const { t } = useTranslation();

	const searchParams = manageHistory.paramsFromURL;

	const { data, loading, error, fetchMore } = useQuery(
		GET_PARTNER_JOBS_EXPORT_DATA,
		{
			variables: {
				filterBy: {
					jobFilters: {
						jobStatuses: searchParams.filters.jobStatuses,
						jobTypes: searchParams.filters.jobTypes,
						completedDateFrom: searchParams.filters.completedDateFrom,
						completedDateTo: searchParams.filters.completedDateTo,
						fieldCategories: searchParams.filters.fieldCategories,
					},
					locationIds: searchParams.filters.locations,
				},
				pageInput: {
					offset: 0,
					limit: LIMIT,
				},
			},
		}
	);
	const total = data?.getPartnerJobs.totalElements || 0;
	const rawJobs = data?.getPartnerJobs.jobs;

	useEffect(() => {
		if (rawJobs && rawJobs.length < total) {
			void fetchMore({
				variables: { pageInput: { offset: rawJobs.length, limit: LIMIT } },
			});
		}
	}, [rawJobs, fetchMore]);

	if (data && total === 0) {
		return (
			<Box padding="1.5rem">
				<Text>{t('labels.noLogsFound')}</Text>
			</Box>
		);
	}

	if (loading || (rawJobs && rawJobs.length < total)) {
		return <ExportLoadingSpinner />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	const locationJobs = rawJobs?.reduce((locationJobs, rawJob) => {
		const location = locationJobs.find(
			(location) => location.location.name === rawJob.location.name
		);
		if (!location) {
			locationJobs.push({
				location: { ...rawJob.location },
				jobs: [rawJob.job],
			});
		} else {
			location.jobs.push(rawJob.job);
		}
		return locationJobs;
	}, [] as TPartnerPDFExportData[]);

	return (
		<Container
			fixed
			sx={{
				padding: '1.5rem',
				maxWidth: '55.625rem !important',
				overflow: 'auto',
			}}
		>
			<SprayLogHeader printRef={printRef} />
			<div className={styles.logLayout} ref={printRef}>
				<SprayLogTitle name={company?.name} />
				{locationJobs?.map((locationJob) => (
					<Box padding="1.5rem" key={locationJob.location.name}>
						<Grid container spacing={2} marginBottom="1.5rem">
							<Grid item xs={4}>
								<SprayLogInfoCard location={locationJob.location} />
							</Grid>
							<Grid item xs={8}>
								<SprayLogFiltersCard jobs={locationJob.jobs} />
							</Grid>
						</Grid>
						<ProductsConsumption jobs={locationJob.jobs} />
						<FieldLogs jobs={locationJob.jobs} />
					</Box>
				))}
			</div>
		</Container>
	);
};

export default ExportToPDF;
