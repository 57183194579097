import { TTranslateFunction } from 'Features/localization';
import { KILOGRAM, POUND } from 'Models/units';
import { toDisplayString } from 'Models/units/transpileUnits';
import { TChartProps } from 'UI/display/Chart';
import { FarmableTextColors } from 'UI/theme/Colors';

const chartConfig = (
	isImperial: boolean | null,
	t: TTranslateFunction
): TChartProps['options'] => {
	const unit = isImperial ? toDisplayString(POUND) : toDisplayString(KILOGRAM);

	const initialChartOptions: TChartProps['options'] = {
		chart: {
			type: 'bar',
			marginRight: 40,
			marginBottom: 35,
			style: {
				fontFamily: 'Lato',
			},
		},
		legend: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		series: [],
		plotOptions: {
			bar: {
				pointWidth: 24,
			},
		},
		title: {
			align: 'left',
			text: t('farmSalesPage.volumeByYearChart.title'),
		},
		xAxis: {
			type: 'category',
			labels: {
				style: {
					fontSize: '0.875rem',
					color: FarmableTextColors.PRIMARY,
				},
			},
		},
		tooltip: {
			pointFormatter() {
				return t('farmSalesPage.volumeByYearChart.tooltipContent', {
					value: this.y,
					unit: unit,
				});
			},
			followPointer: true,
		},
		lang: {
			noData: t('common.charts.noData'),
		},
		yAxis: {
			title: {
				text: null,
			},
			labels: {
				style: {
					fontSize: '0.75rem',
					color: FarmableTextColors.SECONDARY,
				},
			},
		},
	};

	return initialChartOptions;
};

export default chartConfig;
