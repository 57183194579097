import React, { useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCustomerInput } from 'Models/customers/isCustomerInput';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import { enqueueSnackbar } from 'notistack';

import useLocationID from 'Hooks/useLocationID';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import throwErrorSilently from 'Utils/throwErrorSilently';

import { TSelectedFile } from '../../../../components/UploadImage';
import CREATE_CUSTOMER_MUTATION from '../api/createCustomerMutation';
import GET_CUSTOMERS_DATA from '../api/getCustomersData';
import UPLOAD_CUSTOMER_IMAGE from '../api/uploadCustomerImage';
import CustomerModalContainer from './CustomerModalContainer';

const AddCustomerModal = () => {
	const { t } = useTranslation();
	const locationId = useLocationID();
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);

	const [createCustomer, createCustomerTask] = useMutation(
		CREATE_CUSTOMER_MUTATION,
		{
			refetchQueries: [GET_CUSTOMERS_DATA],
		}
	);

	const [uploadImage, uploadImageTask] = useMutation(UPLOAD_CUSTOMER_IMAGE);

	useSnackbarNotifications({
		mutationResult: createCustomerTask,
		messageSuccess: t('customersPage.addCustomerSuccess'),
		messageFailure: t('customersPage.addCustomerFail'),
	});

	const handleSave = (input: TCustomerInput, image?: TSelectedFile) => {
		if (image) {
			void uploadImage({
				variables: {
					locationId,
					file: image.file,
				},
				onCompleted: (data) => {
					const inputWithImage = {
						...input,
						imageName: data.uploadCustomerImage.filename,
					};
					void createCustomer({
						variables: {
							locationId,
							input: inputWithImage,
						},
						onCompleted: toggleOpen,
					});
				},
			}).catch((error: ApolloError) => {
				enqueueSnackbar(t('customersPage.addCustomerFail'), {
					variant: 'error',
				});
				toggleOpen();
				throwErrorSilently(error);
			});
		} else {
			void createCustomer({
				variables: {
					locationId,
					input,
				},
				onCompleted: toggleOpen,
			});
		}
	};

	const isMutationLoading =
		createCustomerTask.loading || uploadImageTask.loading;

	return (
		<>
			<Button startIcon={<AddIcon />} variant="secondary" onClick={toggleOpen}>
				{t('customersPage.addCustomer.action')}
			</Button>
			<CustomerModalContainer
				isModalOpen={open}
				onCloseModal={toggleOpen}
				onSaveHandler={handleSave}
				isMutationLoading={isMutationLoading}
			/>
		</>
	);
};

export default AddCustomerModal;
