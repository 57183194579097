import { getConfigValue } from '../Firebase';

//  usage:
//  for 'FEATURE_FLAG_PARTNER_DASHBOARD'
//  const flag = getFeatureFlag('PARTNER_DASHBOARD')

function getFeatureFlag(flagName: string) {
	return getConfigValue(`FEATURE_FLAG_${flagName}`).asBoolean();
}

export default getFeatureFlag;
