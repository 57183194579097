import { gql, TypedDocumentNode } from '@apollo/client';
import { TTeam } from 'Models/teams/isTeam';
import { TTeamInput } from 'Models/teams/isTeamInput';

export type TEditTeamResult = TTeam;

const EDIT_TEAM: TypedDocumentNode<
	{
		team: TTeam;
	},
	{
		locationId: string;
		teamId: string;
		input: TTeamInput;
	}
> = gql`
	mutation editTeam($input: TeamInput!, $teamId: ID!, $locationId: ID!) {
		editTeam(input: $input, teamId: $teamId, locationId: $locationId) {
			id
			name
			teamLead {
				id
				firstName
				lastName
				profileImage {
					id
					path
					filename
					mimetype
					createdAt
				}
			}
			hasTeamLeadUnclosedTimesheets
			teamMembers {
				id
				customId
				fullName
				position
				hasUnclosedTimesheets
			}
		}
	}
`;

export default EDIT_TEAM;
