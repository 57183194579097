import { TTranslateFunction } from 'Features/localization';

import calculateProductConsumption from '../products/calculateProductConsumption';
import { toDisplayString } from '../units/transpileUnits';
import { TJob } from './isJob';
import { TJobTreatment } from './isJobTreatment';

/**
 * @returns total amount of used product in job area, in units specified for given product's application rate
 */
function jobTotalUsedProduct(
	job: TJob,
	treatment?: Pick<TJobTreatment, 'amount' | 'unit' | 'originalProduct'>,
	t?: TTranslateFunction
) {
	const { jobFields } = job;
	const equipment = job.driversWithEquipmentConfig[0]?.equipment;

	const consumptions = jobFields?.map((field) => {
		const consumption =
			field.areaSize && treatment?.amount
				? calculateProductConsumption(
						treatment,
						field.areaSize,
						equipment,
						field.numberOfPlants,
						field.plantHeight
				  )
				: { amount: 0, unit: '' };
		return consumption;
	});

	const unitToConsumptionMapping: Record<string, number> = {};

	consumptions?.forEach((consumption) => {
		unitToConsumptionMapping[consumption.unit] =
			(unitToConsumptionMapping[consumption.unit] || 0) + consumption.amount;
	});

	const units = Object.getOwnPropertyNames(unitToConsumptionMapping).map(
		(unit) => toDisplayString(unit)
	);
	const totals = Object.values(unitToConsumptionMapping);

	if (units.length === 1) {
		return {
			amount: totals[0] > 0 ? totals[0].toFixed(2) : '-',
			unit: units[0],
		};
	}

	if (units.length > 1 && t) {
		const multipleUnitsInfo =
			'(' + t('logsPages.jobTotalUsedProduct.multipleUnitsInfo') + ')';

		return {
			amount:
				totals.map((total) => total.toFixed(2)).join(', ') +
				' ' +
				multipleUnitsInfo,
			unit: units.join(', ') + ' ' + multipleUnitsInfo,
		};
	}

	return {
		amount: '-',
		unit: '-',
	};
}

export default jobTotalUsedProduct;
