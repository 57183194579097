import { gql, TypedDocumentNode } from '@apollo/client';
import { TPartnerLocations } from 'Models/partnershipCompany/isPartnerLocation';

const GET_PARTNER_LOCATIONS: TypedDocumentNode<
	{
		getPartnerLocations: TPartnerLocations;
	},
	null
> = gql`
	query getPartnerLocations($filterBy: PartnerLocationFilterInput) {
		getPartnerLocations(filterBy: $filterBy) {
			id
			name
			coordinate {
				x
				y
			}
		}
	}
`;

export default GET_PARTNER_LOCATIONS;
