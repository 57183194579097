import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';

import { fieldImportsPageState } from '../../../../state';
import FailureFallback from '../../../FailureFallback';
import ContentLayout from '../ContentLayout';

type TCreateFieldsFailureProps = {
	fieldsInput: TFieldInput[];
	error: unknown;
};

function CreateFieldsFailure(props: TCreateFieldsFailureProps) {
	const { fieldsInput, error } = props;

	const { t } = useTranslation();

	return (
		<ContentLayout
			previousArrowHandler={() =>
				fieldImportsPageState({ status: 'EDIT_FIELDS', fieldsInput })
			}
		>
			<FailureFallback
				error={error}
				title={t('importPages.fieldImportsPage.createFields.failureMessage', {
					count: fieldsInput.length,
				})}
			/>
		</ContentLayout>
	);
}

export default CreateFieldsFailure;
