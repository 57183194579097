import React from 'react';

import UserProfileDropdown from '../../../../../components/FarmableNavbar/components/UserProfileDropdown';
import Navbar from '../../../../../components/Navbar';
import CompanyName from './components/CompanyName';
import LibraryMenu from './components/LibraryMenu';

function PageHeader({ companyName }: { companyName: string }) {
	return (
		<Navbar
			leftPlaceholder={<CompanyName />}
			centerPlaceholder={<LibraryMenu companyName={companyName} />}
			rightPlaceholder={<UserProfileDropdown />}
		/>
	);
}

export default PageHeader;
