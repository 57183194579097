import React, { useEffect } from 'react';

import useLocationID from 'Hooks/useLocationID';

import { fieldImportsPageState } from '../../state';
import FieldImportsStepper from '../FieldImportStepper';
import FieldImportsContent from '../FieldImportsContent';

const PageBody = () => {
	const locationId = useLocationID();

	useEffect(() => {
		fieldImportsPageState({ status: 'ADD_FIELDS_FORM' });
	}, [locationId]);

	return (
		<>
			<FieldImportsStepper />
			<FieldImportsContent />
		</>
	);
};

export default PageBody;
