import React, { ReactElement } from 'react';

import { useQuery } from '@apollo/client';
import { TPaymentProduct } from 'Models/stripe/isPaymentProduct';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';

import useLocationID from 'Hooks/useLocationID';
import getErrorMessage from 'Utils/getErrorMessage';

import StripeProductCard from '../../stripe/StripeProductCard';
import GET_MODAL_PAYMENT_PRODUCTS from '../api/getPaymentProducts';

export type TProductIdWithDemo = {
	productId: string;
	demoLink?: ReactElement;
};

type ProductWithDemo = TPaymentProduct & {
	demoLink?: ReactElement;
};

type TContentProps = {
	productIdsWithDemo: TProductIdWithDemo[];
	onStripeProcessingStarted: () => void;
	onErrorHandler: () => void;
};

const Content = (props: TContentProps) => {
	const { productIdsWithDemo, onStripeProcessingStarted, onErrorHandler } =
		props;

	const locationID = useLocationID();

	const { data, loading, error } = useQuery(GET_MODAL_PAYMENT_PRODUCTS, {
		variables: {
			locationId: locationID,
		},
	});

	if (loading) {
		return (
			<Box textAlign="center">
				<LoadingSpinner />
			</Box>
		);
	}

	if (error) {
		throw new Error(getErrorMessage(error));
	}

	if (data) {
		const products = data.getPaymentProducts.reduce<ProductWithDemo[]>(
			(products, taskProduct) => {
				const includes = productIdsWithDemo.find(
					(productId) => productId.productId === taskProduct.id
				);
				if (includes) {
					products.push({
						...taskProduct,
						demoLink: includes.demoLink,
					});
				}
				return products;
			},
			[]
		);
		return (
			<>
				{products.map((product) => (
					<StripeProductCard
						stripeProduct={product}
						stripePrices={product.prices}
						onStripeProcessingStarted={onStripeProcessingStarted}
						onError={onErrorHandler}
						key={product.id}
					>
						{product.demoLink || null}
					</StripeProductCard>
				))}
			</>
		);
	}

	return null;
};

export default Content;
