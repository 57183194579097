import { ApolloError, gql, TypedDocumentNode, useQuery } from '@apollo/client';
import { TFeatureFlag } from 'Models/locations/isLocationSubscriptions';

import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';

export type TGetLocationSubscriptionsResult = {
	id: string;
	featureFlags: TFeatureFlag[];
};

export type TGetLocationSubscriptionsResponse = {
	location: TGetLocationSubscriptionsResult;
};

type TGetLocationSubscriptions = {
	locationId: string;
};

const GET_LOCATION_SUBSCRIPTIONS: TypedDocumentNode<
	TGetLocationSubscriptionsResponse,
	TGetLocationSubscriptions
> = gql`
	query getLocationSubscriptions($locationId: ID!) {
		location(id: $locationId) {
			id
			featureFlags {
				name
				active
			}
		}
	}
`;

function useLocationSubscriptions() {
	const locationId = getLocationIdFromUrl();
	const { data, loading, error } = useQuery(GET_LOCATION_SUBSCRIPTIONS, {
		skip: !locationId,
		variables: { locationId },
	});

	if (error) {
		throw new ApolloError(error);
	}
	return { locationSubscriptions: data?.location || null, loading };
}

export default useLocationSubscriptions;
