import React from 'react';

import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import farmableMUITheme from '.';
import './main.scss';

const ThemeProvider = (props) => (
	<StyledEngineProvider injectFirst>
		<MUIThemeProvider theme={farmableMUITheme}>
			{props.children}
		</MUIThemeProvider>
	</StyledEngineProvider>
);

export default ThemeProvider;
