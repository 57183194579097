import {
	getGraphQLErrorMessage,
	hasGraphQLErrors,
} from 'Models/graphql/ErrorUtils';

const getErrorMessage = (e: unknown): string => {
	if (hasGraphQLErrors(e)) {
		return getGraphQLErrorMessage(e.errors);
	}

	if (e instanceof TypeError || e instanceof Error) {
		return e.message;
	}

	return 'unknown error';
};

export default getErrorMessage;
