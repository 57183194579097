export const FieldImportsStatus = {
	ADD_FIELDS_FORM: 'ADD_FIELDS_FORM',
	ADD_FIELDS_SUCCESS: 'ADD_FIELDS_SUCCESS',
	ADD_FIELDS_LOADING: 'ADD_FIELDS_LOADING',
	ADD_FIELDS_FAILURE: 'ADD_FIELDS_FAILURE',
	EDIT_FIELDS: 'EDIT_FIELDS',
	REVIEW_FIELDS: 'REVIEW_FIELDS',
	CREATE_FIELDS_SUCCESS: 'CREATE_FIELDS_SUCCESS',
	CREATE_FIELDS_LOADING: 'CREATE_FIELDS_LOADING',
	CREATE_FIELDS_FAILURE: 'CREATE_FIELDS_FAILURE',
} as const;
