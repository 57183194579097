import React from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCauseInput } from 'Models/causes/isCause';
import { enqueueSnackbar } from 'notistack';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import throwErrorSilently from 'Utils/throwErrorSilently';

import { TSelectedFile } from '../../../../components/UploadImage';
import DELETE_PARTNERSHIP_CAUSE_IMAGE from '../api/deletePartnershipCauseImage';
import EDIT_PARTNERSHIP_CAUSE, {
	updateCauseCache,
} from '../api/editPartnershipCause';
import UPLOAD_PARTNERSHIP_CAUSE_IMAGE from '../api/uploadPartnershipCauseImage';
import { TPartnerCause } from '../types';
import CauseModalContainer from './CauseModalContainer';

type TEditCauseModalProps = {
	isModalOpen: boolean;
	modalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
	cause: TPartnerCause;
};

const EditCauseModal = React.memo((props: TEditCauseModalProps) => {
	const { modalStateHandler, isModalOpen, cause } = props;

	const { t } = useTranslation();

	const [editPartnershipCause, editPartnershipCauseResult] = useMutation(
		EDIT_PARTNERSHIP_CAUSE
	);
	const [uploadPartnershipCauseImage, uploadPartnershipCauseImageResult] =
		useMutation(UPLOAD_PARTNERSHIP_CAUSE_IMAGE);
	const [deletePartnershipCauseImage, deletePartnershipCauseImageResult] =
		useMutation(DELETE_PARTNERSHIP_CAUSE_IMAGE);

	const closeModal = () => {
		modalStateHandler(false);
	};

	useSnackbarNotifications({
		mutationResult: editPartnershipCauseResult,
		messageFailure: t('partnerCausesPage.editCauseModal.editCauseFailure'),
		messageSuccess: t('partnerCausesPage.editCauseModal.editCauseSuccess'),
	});

	async function uploadImages(causeId: string, images: TSelectedFile[]) {
		for (const image of images) {
			await uploadPartnershipCauseImage({
				variables: {
					causeId,
					file: image.file,
				},
			});
		}
	}

	async function deleteImages(causeId: string, imageIds: string[]) {
		for (const imageId of imageIds) {
			await deletePartnershipCauseImage({
				variables: {
					causeId,
					fileId: imageId,
				},
			});
		}
	}

	const handleEditCause = async (
		input: TCauseInput,
		images: TSelectedFile[],
		deleteImageIDs?: string[] | null
	) => {
		try {
			await uploadImages(cause.id, images);
			if (deleteImageIDs?.length) {
				await deleteImages(cause.id, deleteImageIDs);
			}
			await editPartnershipCause({
				variables: {
					causeId: cause.id,
					input,
				},
				update: updateCauseCache,
				onCompleted: closeModal,
			});
		} catch (e) {
			if (e instanceof Error) {
				throwErrorSilently(e);
			}
			closeModal();
			enqueueSnackbar(t('partnerCausesPage.editCauseModal.editCauseFailure'), {
				variant: 'error',
			});
		}
	};

	const isMutationLoading =
		editPartnershipCauseResult.loading ||
		deletePartnershipCauseImageResult.loading ||
		uploadPartnershipCauseImageResult.loading;

	return (
		<>
			<CauseModalContainer
				isModalOpen={isModalOpen}
				onCloseModal={closeModal}
				onBackHandler={closeModal}
				isMutationLoading={isMutationLoading}
				onSaveHandler={handleEditCause}
				cause={cause}
			/>
		</>
	);
});

export default EditCauseModal;
