import React, { Fragment, ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TAccount } from 'Models/account/isAccount';
import Avatar from 'UI/display/Avatar';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import { makeStyles } from 'UI/utils/makeStyles';

import Status from './status';

export type TLeadProps = {
	profile: TAccount;
	clockedIn: boolean;
};

const useStyles = makeStyles({
	itemRoot: {
		padding: '0.5rem 1.5rem',
	},
	textItemRoot: {
		margin: 0,
	},
	textItemPrimary: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& > p': {
			lineHeight: 1.25,
		},
	},
	textItemSecondary: {
		lineHeight: 1.43,
	},
});

function Lead(props: TLeadProps): ReactElement {
	const { profile, clockedIn } = props;
	const { itemRoot, textItemRoot, textItemPrimary, textItemSecondary } =
		useStyles();
	const { t } = useTranslation();

	const firstName = profile.firstName || '';
	const lastName = profile.lastName || '';
	const fullName = firstName ? firstName + ' ' + lastName : lastName;

	const avatarImgPath = profile.profileImage?.path;
	const avatarFallback = firstName.charAt(0) + lastName.charAt(0);

	const primary = (
		<Fragment>
			<Text>{fullName}</Text>
			<Status clockedIn={clockedIn} />
		</Fragment>
	);

	return (
		<List.Item className={itemRoot}>
			<List.ItemAvatar>
				<Avatar src={avatarImgPath || undefined} alt={fullName}>
					{avatarFallback}
				</Avatar>
			</List.ItemAvatar>
			<List.ItemText
				primary={primary}
				secondary={t('labels.teamLead')}
				classes={{
					root: textItemRoot,
					primary: textItemPrimary,
					secondary: textItemSecondary,
				}}
			/>
		</List.Item>
	);
}

export default Lead;
