import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { isPartnerPlannerJobInput } from 'Models/planner/PartnerPlannerJobInput';
import Button from 'UI/inputs/Button';

import useSpanishPartner from 'Hooks/useSpanishPartner';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../state';

type TNextButtonProps = {
	isLoading: boolean;
	actionHandler: () => void;
	isEditModal?: boolean;
};

function NextButton(props: TNextButtonProps) {
	const { isLoading, actionHandler, isEditModal } = props;
	const status = useAddJobModalStatus();
	const jobInput = useJobInputData();
	const { t } = useTranslation();
	const { isSpanishPartner } = useSpanishPartner();

	const nextHandler = () => {
		if (status === 'SET_CAUSE_AND_TREATMENTS' && jobInput) {
			addJobModalState({
				jobInputData: jobInput,
				status: 'SET_DATE_AND_COMMENT',
			});
		}

		if (status === 'SET_DATE_AND_COMMENT') {
			addJobModalState({
				jobInputData: jobInput,
				status: 'CREATE_AND_SHARE_LOCATIONS',
			});
		}

		if (status === 'CREATE_AND_SHARE_LOCATIONS') {
			actionHandler();
		}
	};
	const isValid = isPartnerPlannerJobInput(jobInput, isSpanishPartner);
	const actionText = isEditModal
		? t('buttons.updateJob')
		: t('buttons.addAndSend');

	return (
		<Button
			variant="outlined"
			onClick={nextHandler}
			disabled={isLoading || !isValid}
		>
			{status === 'CREATE_AND_SHARE_LOCATIONS' ? actionText : t('buttons.next')}
		</Button>
	);
}

export default NextButton;
