import React, { ReactElement, useEffect, useState } from 'react';

import {
	ApolloProvider as _ApolloProvider,
	ApolloClient,
	InMemoryCache,
	ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { useAuth0 } from '@auth0/auth0-react';
import { withScope } from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';

import getErrorMessage from 'Utils/getErrorMessage';

import addAnalytics from '../../auth0/utils/addAnalytics';
import cacheConfig from '../cacheConfig';

type TProps = {
	children: ReactElement;
};

const API_PATH = process.env.API_PATH;

function ApolloProvider(props: TProps) {
	const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
	const [bearerToken, setBearerToken] = useState('');

	useEffect(() => {
		const getToken = async () => {
			const accessToken = isAuthenticated ? await getAccessTokenSilently() : '';
			setBearerToken(`Bearer ${accessToken}`);
		};
		try {
			void getToken();
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}, [getAccessTokenSilently, isAuthenticated]);

	if (isAuthenticated && user) {
		addAnalytics(user);
	}

	const errorLink = onError(({ operation }) => {
		withScope((scope) => {
			scope.setTransactionName(operation.operationName);
			scope.setContext('apolloGraphQLOperation', {
				variables: operation.variables,
				extensions: operation.extensions,
			});
		});
	});

	const authLink = setContext((_, { headers }) => {
		if (!bearerToken) return { headers };

		return {
			headers: {
				...headers,
				authorization: bearerToken,
			},
		};
	});

	// its an extension for createHttpLink that supports file uploads
	const httpLink = createUploadLink({
		uri: API_PATH,
	});

	// retry at most 3 times if there is a network error and user is authorized (not 401 status),
	// which is the only case when it makes sense - other errors likely won't be solved by retrying
	const retryLink = new RetryLink({
		delay: {
			initial: 500,
			max: 50000,
			jitter: true,
		},
		attempts: {
			max: 3,
			retryIf: (error) =>
				!!error.networkError && error.networkError.statusCode !== 401,
		},
	});

	const sentryLink = new SentryLink({
		attachBreadcrumbs: {
			includeError: true,
			includeQuery: true,
			includeVariables: true,
		},
	});

	const apolloClient = new ApolloClient({
		link: ApolloLink.from([
			authLink,
			retryLink,
			errorLink,
			sentryLink,
			httpLink,
		]),
		cache: new InMemoryCache(cacheConfig),
		connectToDevTools: true,
	});

	return (
		<_ApolloProvider client={apolloClient}>{props.children}</_ApolloProvider>
	);
}

export default ApolloProvider;
