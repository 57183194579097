import React, { ReactElement } from 'react';

import MUIChip, { ChipProps } from '@mui/material/Chip';
import { alpha, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import Check from '../../icons/Check';
import { FarmableColors } from '../../theme/Colors';

type TChipProps = {
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	checked?: boolean;
	startIcon?: ReactElement;
	chipColor?: FarmableColors;
	dense?: boolean;
};

const Chip = (props: TChipProps & ChipProps): ReactElement => {
	const {
		className,
		variant,
		checked,
		startIcon,
		clickable,
		onClick,
		onDelete,
		chipColor,
		dense,
		...MUIChipProps
	} = props;

	const backgroundColorFade = dense ? 0.08 : 0.12;

	const useStyles = makeStyles({
		root: {
			color: chipColor,
			borderColor: chipColor || FarmableColors.BLACK_12,
			backgroundColor: chipColor
				? alpha(chipColor, backgroundColorFade)
				: undefined,
			borderRadius: dense ? '0.25rem' : undefined,
			fontSize: dense ? '0.625rem' : undefined,
			fontWeight: dense ? 'bold' : undefined,
			height: dense ? '1rem' : undefined,
			minWidth: dense ? '4.5rem' : undefined,
			textAlign: 'center',
			'& .MuiChip-label': {
				padding: dense ? '0.25rem 0' : undefined,
			},
		},
		outlined: {
			'&&:hover, &&:focus, &&:focus': {
				backgroundColor:
					(clickable || onClick) && chipColor
						? alpha(chipColor, 0.18)
						: undefined,
			},
		},
		deleteIcon: checked
			? {
					width: 14,
					height: 12,
			  }
			: {},
	});

	const { root, deleteIcon, outlined } = useStyles();

	return (
		<StyledEngineProvider injectFirst>
			<MUIChip
				className={className}
				classes={{ root, deleteIcon, outlined }}
				onClick={onClick}
				{...MUIChipProps}
				variant={variant || 'outlined'}
				onDelete={onDelete}
				deleteIcon={checked ? <Check /> : undefined}
				icon={startIcon ? { ...startIcon } : undefined}
			/>
		</StyledEngineProvider>
	);
};

export default Chip;
