import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	TProductIngredientInput,
	isAmountInput,
} from 'Models/products/ProductIngredientInput';
import NumericField from 'UI/inputs/NumericField';

import numberToDisplayString from 'Utils/numberToDisplayString';

import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../../state';

type TProps = {
	amount: TProductIngredientInput['amount'];
	id: TProductIngredientInput['id'];
};

function Amount(props: TProps) {
	const { amount, id } = props;
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { status, input } = state;

	if (status === 'SET_PRODUCT_DETAILS') {
		const handleValueParsed = (parsedAmount: number) => {
			if (
				!input.selectedGovernmentProduct &&
				'ingredients' in input.product &&
				input.product.ingredients
			) {
				const newIngredients = input.product.ingredients?.map(
					(innerIngredient) => {
						if (innerIngredient.id === id) {
							return {
								...innerIngredient,
								amount: parsedAmount,
							};
						}

						return innerIngredient;
					}
				);

				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: newIngredients,
						},
					},
				});
			}
		};

		return (
			<NumericField
				value={numberToDisplayString(amount)}
				label={t('labels.amount')}
				onValueParsed={handleValueParsed}
				margin="dense"
				disabled={Boolean(selectedProduct)}
				inputValidator={isAmountInput}
				errorHelperText={t('helpers.positiveValuesOnly')}
				required
			/>
		);
	}
	return null;
}

export default Amount;
