import { TDriverWithEquipment } from './isJob';

export type TJobForDriverString = {
	driversWithEquipmentConfig: TDriverWithEquipment[];
};

export default (job: TJobForDriverString): string => {
	const drivers = job.driversWithEquipmentConfig;

	return (
		drivers
			?.map(
				({ driver }) =>
					(driver?.firstName || '') + ' ' + (driver?.lastName || '')
			)
			.filter(Boolean)
			.join(', ') || '-'
	);
};
