import { gql, TypedDocumentNode } from '@apollo/client';
import { TField } from 'Models/fields/isField';

export type TAdvisorDashboardFieldsResponse = {
	getFieldsByUsersFieldCategories: Pick<
		TField,
		| 'id'
		| 'name'
		| 'geometry'
		| 'varieties'
		| 'areaSize'
		| 'reentryAllowedDate'
		| 'harvestAllowedDate'
	>[];
};

type TAdvisorDashboardFieldsRequest = {
	locationId: string;
};
const GET_ADVISOR_DASHBOARD_FIELDS: TypedDocumentNode<
	TAdvisorDashboardFieldsResponse,
	TAdvisorDashboardFieldsRequest
> = gql`
	query getAdvisorDashboardFields($locationId: ID!) {
		getFieldsByUsersFieldCategories(locationId: $locationId) {
			id
			name
			geometry {
				x
				y
			}
			varieties {
				identifier
				name
				category
				percentage
				sowDate
			}
			areaSize
			reentryAllowedDate
			harvestAllowedDate
		}
	}
`;

export default GET_ADVISOR_DASHBOARD_FIELDS;
