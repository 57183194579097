import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	TProductTreatmentTime,
	TreatmentTimes,
} from 'Models/products/productTreatmentTime';
import Select from 'UI/inputs/Select';
import TextField from 'UI/inputs/TextField';

import numberToDisplayString from 'Utils/numberToDisplayString';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../state';
import {
	AddTreatmentModalStatus,
	TSetProductDetailsInput,
} from '../../../../../types';

const ApplicationRequirements = () => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { status, input } = state;

	const isDisabled =
		Boolean(selectedProduct) || input.selectedGovernmentProduct;

	if (status === AddTreatmentModalStatus.SET_PRODUCT_DETAILS) {
		const product = input.product;
		const hasSpec = 'productSpec' in product;
		const reEntryInterval =
			selectedProduct?.productSpec.reEntryInterval ||
			(hasSpec && product.productSpec?.reEntryInterval) ||
			'';

		const treatmentTime =
			selectedProduct?.productSpec.treatmentTime ||
			(hasSpec && product.productSpec?.treatmentTime) ||
			'';

		const sanitizedInterval = numberToDisplayString(reEntryInterval);

		const onChangeReEntryInterval = (value: string) => {
			if (!input.selectedGovernmentProduct) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							productSpec: {
								...input.product.productSpec,
								reEntryInterval: parseFloat(value),
							},
						},
					},
				} as TSetProductDetailsInput);
			}
		};

		const onChangeTreatmentTime = (value: TProductTreatmentTime) => {
			if (!input.selectedGovernmentProduct) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							productSpec: {
								...input.product.productSpec,
								treatmentTime: value,
							},
						},
					},
				} as TSetProductDetailsInput);
			}
		};

		return (
			<ModalSection title={t('labels.applicationRequirements')}>
				<TextField
					value={sanitizedInterval}
					label={t('labels.reEntryInterval')}
					onChange={onChangeReEntryInterval}
					type="number"
					margin="dense"
					sx={{
						marginBottom: '1rem',
					}}
					disabled={isDisabled}
				/>
				<Select
					value={treatmentTime}
					label={t('labels.treatmentTime')}
					options={[...TreatmentTimes]}
					optionFormatter={(value: TProductTreatmentTime) =>
						t(`products.treatmentTimes.${value}`)
					}
					changeHandler={onChangeTreatmentTime}
					small
					disabled={isDisabled}
				/>
			</ModalSection>
		);
	}

	return null;
};

export default ApplicationRequirements;
