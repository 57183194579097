import React, { ReactElement } from 'react';

import MUIStep from '@mui/material/Step';
import MUIStepLabel from '@mui/material/StepLabel';
import MUIStepper from '@mui/material/Stepper';

type TStepperProps = {
	activeStep: number;
	className?: string;
	steps: string[];
};

const Stepper = (props: TStepperProps): ReactElement => {
	const { activeStep, className, steps } = props;

	return (
		<MUIStepper className={className} activeStep={activeStep} alternativeLabel>
			{steps.map((step, index) => (
				<MUIStep key={index}>
					<MUIStepLabel>{step}</MUIStepLabel>
				</MUIStep>
			))}
		</MUIStepper>
	);
};

export default Stepper;
