import React from 'react';

import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';

const OverlayLoader = () => (
	<Box
		height="100%"
		position="relative"
		display="flex"
		justifyContent="center"
		alignItems="center"
		zIndex={9999}
		bgcolor="rgba(0, 0, 0, 0.5)"
	>
		<LoadingSpinner />
	</Box>
);

export default OverlayLoader;
