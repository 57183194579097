import format from 'date-fns/format';

import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const DEFAULT_FORMAT = 'yyyy-MM-dd HH:mm';

const jobFinishedFullDateString = (
	job: Pick<TJob, 'completedDate'>,
	dateFormat?: string
): string =>
	job.completedDate
		? format(toLocalDate(job.completedDate), dateFormat || DEFAULT_FORMAT)
		: '';

export default jobFinishedFullDateString;
