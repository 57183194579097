import React, { HTMLProps, ReactElement, forwardRef, RefObject } from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts, {
	setOptions,
	Options,
	ChartCallbackFunction,
} from 'highcharts/highstock';
import highchartsNoData from 'highcharts/modules/no-data-to-display';

export const highchartsInstance = Highcharts;
export const noDataPlugin = highchartsNoData;

export const setGlobalOptions = setOptions;

export type TChartProps = {
	options: Options;
	updateArgs?: boolean[];
	callback?: ChartCallbackFunction;
	constructorType?: 'chart' | 'stockChart' | 'mapChart';
	containerProps?: HTMLProps<HTMLDivElement>;
};

export type TChartRef = {
	chart: Highcharts.Chart;
	container: RefObject<HTMLDivElement>;
};

const Chart = forwardRef<TChartRef, TChartProps>(
	(props, ref?): ReactElement => {
		return <HighchartsReact ref={ref} highcharts={Highcharts} {...props} />;
	}
);

export default Chart;
