import { gql, TypedDocumentNode } from '@apollo/client';

import {
	TPartnerPlannerJobsFilterInput,
	TPlannerChartDataResponse,
} from '../types';

const GET_PLANNER_CHART_DATA: TypedDocumentNode<
	{
		getPartnerPlannerJobs: TPlannerChartDataResponse;
	},
	TPartnerPlannerJobsFilterInput
> = gql`
	query getPartnerPlannerJobs($filterBy: PartnerPlannerJobsFilterInput) {
		getPartnerPlannerJobs(filterBy: $filterBy) {
			id
			dueDate
			jobDetails {
				jobType
			}
		}
	}
`;

export default GET_PLANNER_CHART_DATA;
