import { convertToShortRateUnit } from '../units/transpileUnits';
import { TDriverWithEquipment, TJob } from './isJob';

export default (job: Pick<TJob, 'driversWithEquipmentConfig'>): string => {
	const drivers = job.driversWithEquipmentConfig;
	const parsedEquipments = drivers
		.map((driver: TDriverWithEquipment) => {
			const rateConfig = driver.equipment?.rate;

			const name = driver.equipment?.name;

			return name && rateConfig
				? `${name} (${rateConfig?.value} ${convertToShortRateUnit(
						rateConfig?.unit
				  )})`
				: name;
		})
		.filter((name) => name);

	return parsedEquipments.length ? parsedEquipments.join(', ') : '-';
};
