import React from 'react';

import Box from '../../layout/Box';
import Grid from '../../layout/Grid';
import { FarmableColors } from '../../theme/Colors';
import Text from '../Text';

type TItem = {
	value?: number | string;
	description: string;
	variant?: 'GREEN' | 'ORANGE';
};

const Item = (props: TItem) => {
	const { value, description, variant } = props;
	const DEFAULT_VARIANT = 'GREEN';

	return (
		<Grid
			item
			xs
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				'&:not(:last-child)': {
					borderRight: `1px solid ${FarmableColors.DIVIDER}`,
				},
			}}
		>
			<Box paddingY={1.5}>
				<Text
					variant="h3"
					overrideMUIColor={variant || DEFAULT_VARIANT}
					align="center"
				>
					{value || '-'}
				</Text>
				<Text variant="body2" align="center">
					{description}
				</Text>
			</Box>
		</Grid>
	);
};

export default Item;
