import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';

import ContentLayout from '../ContentLayout';

function AddFieldsLoading(): ReactElement {
	const { t } = useTranslation();
	const title = (
		<Text variant="h3" component="h2">
			{t('importPages.fieldImportsPage.uploadFilesStep.title')}
		</Text>
	);

	const description = (
		<Text variant="body1" color="textSecondary">
			{t('importPages.fieldImportsPage.uploadFilesStep.description')}
		</Text>
	);

	return (
		<ContentLayout>
			<Box marginY={2}>
				{title}
				<Box paddingTop={2} paddingBottom={4}>
					{description}
				</Box>
				<Box textAlign="center" padding={4}>
					<LoadingSpinner width={64} height={64} />
				</Box>
			</Box>
		</ContentLayout>
	);
}

export default AddFieldsLoading;
