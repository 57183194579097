import { gql, TypedDocumentNode } from '@apollo/client';

import {
	TPartnerTimesheetsExportFileResponse,
	TPartnerTimesheetsFilterInput,
} from '../types';

const GET_PARTNER_HOURS_EXPORT_DATA: TypedDocumentNode<
	{
		getPartnerTimesheets: TPartnerTimesheetsExportFileResponse;
	},
	TPartnerTimesheetsFilterInput
> = gql`
	query GetPartnerTimesheets(
		$filterBy: PartnerTimesheetsFilterInput
		$pageInput: PageInput
	) {
		getPartnerTimesheets(filterBy: $filterBy, pageInput: $pageInput) {
			totalElements
			totalPages
			timesheets {
				timesheet {
					id
					endTime
					startTime
					team {
						id
						name
					}
					teamMember {
						id
						customId
						fullName
					}
					teamLead {
						id
						firstName
						lastName
					}
					workLogs {
						id
						workType
						duration
					}
					fields {
						id
						name
					}
					comment
					timesheetFieldHours {
						fieldId
						duration
						isLunchTimeSubtracted
					}
				}
				location {
					id
					name
				}
			}
		}
	}
`;

export default GET_PARTNER_HOURS_EXPORT_DATA;
