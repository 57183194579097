import React, { createElement, PropsWithChildren } from 'react';

import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';
import Breakpoints from 'UI/theme/breakpoints';
import useBreakpoint from 'UI/utils/useBreakpoint';

import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import NoMobilesPage from '../../pages/no-access/NoMobilesPage';
import AppNavigation from '../AppNavigation';
import ErrorBoundary from '../ErrorBoundary';
import LocationsDropdown from '../FarmableNavbar/components/LocationsDropdown';

const withDisabledResponsiveness =
	<P extends object>(Component: React.FC<P>) =>
	(props: PropsWithChildren<P>) => {
		const { children } = props;
		const isMobile = useBreakpoint(Breakpoints.MOBILE);
		const isCampaignLandingPage =
			window.location.pathname === '/campaign/select';
		const isMobileFriendlyRoute =
			window.location.pathname.startsWith('/mobile');
		const isAuthCallback =
			window.location.pathname.startsWith('/auth-callback');
		const component = createElement(Component, props, children);
		const treatmentLibraryID = getTreatmentLibraryIdFromURL();

		if (isCampaignLandingPage || isAuthCallback) {
			// mailing page should be the same for both cases - a simple page
			// that just redirects where necessary, no navigation
			return <ErrorBoundary>{component}</ErrorBoundary>;
		}

		if (!isMobile) {
			// treatment library page doesn't display navigation
			if (treatmentLibraryID) {
				return <ErrorBoundary>{component}</ErrorBoundary>;
			}

			// show navigation when not mobile
			return (
				<AppNavigation>
					<ErrorBoundary>{component}</ErrorBoundary>
				</AppNavigation>
			);
		}

		return isMobileFriendlyRoute ? (
			// show only mobile friendly pages on mobile
			<ErrorBoundary>
				<Box
					height="3.5rem"
					sx={{ borderBottom: `1px solid ${FarmableColors.BLACK_12}` }}
					display="flex"
					paddingLeft="1rem"
				>
					<LocationsDropdown />
				</Box>
				{component}
			</ErrorBoundary>
		) : (
			<NoMobilesPage />
		);
	};

export default withDisabledResponsiveness;
