import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import { GRAM_PER_SQUARE_METER } from 'Models/units';

import { addTreatmentPageState, treatmentInputState } from '../../../../state';
import { AddTreatmentModalStatus } from '../../../../types';
import AdditionalDetails from './components/AdditionalDetails';
import ApplicationRequirements from './components/ApplicationRequirements';
import Causes from './components/Causes';
import ApplicationRate from './components/Details';
import RelevantDevelopmentStage from './components/RelevantDevelopmentStage';
import WaterMixRate from './components/WaterMixRate';

function TreatmentDetails() {
	const state = useReactiveVar(addTreatmentPageState);
	const treatmentInput = useReactiveVar(treatmentInputState);
	const treatments = state.input.product.treatments || [];
	const createdTreatment = treatments.find(
		(treatment) =>
			(treatment as TProductTreatmentInput)?.category ===
			state.editedCategoryName
	);
	const isNewGovTreatmentCreationFlow =
		AddTreatmentModalStatus.GOV_TREATMENT_DETAILS === state.status;

	useEffect(() => {
		if (
			![
				AddTreatmentModalStatus.TREATMENT_DETAILS,
				AddTreatmentModalStatus.GOV_TREATMENT_DETAILS,
			].includes(state.status)
		) {
			treatmentInputState(undefined);
		} else {
			treatmentInputState(
				(treatmentInput as TProductTreatmentInput) ||
					createdTreatment || {
						category: state.editedCategoryName,
						unit: GRAM_PER_SQUARE_METER,
						amount: 0,
					}
			);
		}
	}, [
		state.status,
		state.editedCategoryName,
		createdTreatment,
		treatmentInput,
	]);

	return (
		<>
			<ApplicationRate />
			<Causes />
			{!isNewGovTreatmentCreationFlow && <RelevantDevelopmentStage />}
			{!isNewGovTreatmentCreationFlow && <ApplicationRequirements />}
			{!isNewGovTreatmentCreationFlow && <AdditionalDetails />}
			<WaterMixRate />
		</>
	);
}

export default TreatmentDetails;
