import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Box from 'UI/layout/Box';

import NoAccessPageTemplate from '../NoAccessPageTemplate';

const NoMobilePage = (): ReactElement => {
	const { t } = useTranslation();

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="100vh"
		>
			<NoAccessPageTemplate
				title={t('noAccess.noMobilesPage.title')}
				description={t('noAccess.noMobilesPage.description')}
			/>
		</Box>
	);
};

export default NoMobilePage;
