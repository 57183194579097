import React, { useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import useCurrentLanguage from 'Features/localization/hooks/useCurrentLanguage';
import { isAlpha2Code } from 'Models/partnershipCompany/isPartnershipCompanyInput';
import countries from 'i18n-iso-countries';
import { Alpha2Code } from 'i18n-iso-countries';

import TextField from '../TextField';

type TOption = { code: string; translation: string };
type TCountryDropdownProps = {
	label: string;
	name: string;
	value?: string;
	disabled?: boolean;
	onChange?: (input: Alpha2Code) => void;
	required?: boolean;
	error?: boolean;
};

const CountryDropdown = (props: TCountryDropdownProps) => {
	const { value, onChange, label, disabled, required, error } = props;
	const currentLang = useCurrentLanguage();

	const options: TOption[] = useMemo(
		() =>
			Object.entries(
				countries.getNames(currentLang, { select: 'official' })
			).map(([key, value]) => ({
				code: key,
				translation: String(value),
			})),
		[currentLang]
	);

	const onChangeEvent = (_, selectedOption: TOption) => {
		if (onChange && !disabled && isAlpha2Code(selectedOption.code)) {
			onChange(selectedOption.code);
		}
	};

	return (
		<Autocomplete
			id="country_dropdown"
			options={options}
			disableClearable
			readOnly={disabled}
			getOptionLabel={(option) => option.translation}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					required={required}
					inputProps={{
						...params.inputProps,
						autoComplete: 'new-password', // disable autocomplete and autofill
					}}
					error={error}
				/>
			)}
			disabled={disabled}
			value={
				options.find((opt) => opt.code === value) || {
					code: '',
					translation: '',
				}
			}
			onChange={onChangeEvent}
		/>
	);
};

export default CountryDropdown;
