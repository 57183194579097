import { gql, TypedDocumentNode } from '@apollo/client';

import { TDeletePlannerJobTreatmentInput } from '../types';

const DELETE_PLANNER_JOB_TREATMENT: TypedDocumentNode<
	{
		deletePlannerJobTreatment: boolean;
	},
	TDeletePlannerJobTreatmentInput
> = gql`
	mutation deletePlannerJobTreatment($plannerJobId: ID!, $treatmentId: ID!) {
		deletePlannerJobTreatment(
			plannerJobId: $plannerJobId
			treatmentId: $treatmentId
		)
	}
`;

export default DELETE_PLANNER_JOB_TREATMENT;
