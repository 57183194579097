import React, { ReactElement, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { ExportType, TExportType } from 'Models/exports/ExportType';
import { TLogFormat } from 'Models/exports/Logs';
import { LogTypes } from 'Models/logs/LogType';
import LoadingSpinnerIcon from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useLocationID from 'Hooks/useLocationID';
import useManageHistory from 'Hooks/useManageHistory';
import useProfile from 'Hooks/useProfile';

import exportJobsLog from '../utils/exportJobsLog';

const ExportButton = (): ReactElement => {
	const locationID = useLocationID();
	const { profile } = useProfile();
	const { t } = useTranslation();
	const location = useCurrentLocation();
	const { paramsFromURL } = useManageHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { getAccessTokenSilently } = useAuth0();
	const tokenPromise = !window.Cypress ? getAccessTokenSilently() : null;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const trackExport = (type: TExportType) => {
		if (profile) {
			analyticsTrack(TrackEvents.DataExported, {
				userId: profile.id,
				groupId: locationID,
				exportType: type,
				logType: LogTypes.Jobs,
			});
		}
	};

	const exportJobsLogs = async (exportType: TLogFormat) => {
		if (location) {
			trackExport(exportType);
			setIsLoading(true);
			handleClose();
			const token = await tokenPromise;
			await exportJobsLog(
				paramsFromURL,
				location,
				t,
				`Bearer ${token}`,
				exportType
			);
			setIsLoading(false);
		}
	};

	return (
		<>
			<Button
				disabled={isLoading}
				id="export-jobs-button"
				variant="outlined"
				onClick={handleClick}
			>
				{isLoading ? (
					<LoadingSpinnerIcon width={24} height={24} />
				) : (
					t('logsPages.jobLogsPage.exportButton.hasSubscription')
				)}
			</Button>
			<Menu.Menu
				id="export-jobs-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Menu.Item
					onClick={() => {
						void exportJobsLogs(ExportType.XLSX);
					}}
				>
					{t('logsPages.jobLogsPage.exportButton.download.xlsx')}
				</Menu.Item>
			</Menu.Menu>
		</>
	);
};

export default ExportButton;
