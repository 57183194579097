import React from 'react';

import Text from 'UI/display/Text';
import Checkbox from 'UI/inputs/Checkbox';
import { useTranslation } from 'react-i18next';

type TProps = {
	id: string;
	name: string;
	isActive: boolean;
	onClick: (id: string) => void;
	isPending?: boolean;
};

function FarmsListItem(props: TProps) {
	const { id, name, isActive, onClick, isPending } = props;
	const { t } = useTranslation();

	return (
		<Checkbox.Controlled
			label={
				<Text>
					{name}
					{isPending
						? ` (${t('common.partnershipLocationStatus.PENDING')})`
						: null}
				</Text>
			}
			checked={isActive}
			size="medium"
			centerRipple
			onClick={() => onClick(id)}
			sx={{ marginRight: '0.5rem' }}
		/>
	);
}

export default FarmsListItem;
