import Body from './body';
import Cell from './cell';
import Container from './container';
import Footer from './footer';
import Head from './head';
import Pagination from './pagination';
import Row from './row';
import Table from './table';

const Export = {
	Container,
	Table,
	Body,
	Head,
	Row,
	Cell,
	Pagination,
	Footer,
};

export default Export;
