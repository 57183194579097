import { TFeatureFlag } from 'Models/locations/isLocationSubscriptions';

const _isActive = (feature: string, featureFlags: TFeatureFlag[]) => {
	const featureFlag = featureFlags.find((flag) => flag.name === feature);

	return Boolean(featureFlag?.active);
};

const isPurchasedProduct = (
	envProductFeatures: string[],
	featureFlags: TFeatureFlag[]
): boolean => {
	return envProductFeatures.every((feature) =>
		_isActive(feature, featureFlags)
	);
};

export { isPurchasedProduct };
