import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TTimesheetsFilterInput } from 'Models/timesheet/types/TimesheetsFilterInput';

import { TTimesheetExportQueryResponse } from '../types';

export type TGetHoursExportData = {
	getTimesheets: Omit<TTimesheetExportQueryResponse, 'totalPages'>;
};

type TGetHoursExportDataInput = {
	locationId: string;
	filterBy?: Partial<TTimesheetsFilterInput>;
	pageInput?: TPageInput;
};

const GET_HOURS_EXPORT_DATA: TypedDocumentNode<
	TGetHoursExportData,
	TGetHoursExportDataInput
> = gql`
	query getHoursExportData(
		$locationId: ID!
		$filterBy: TimesheetsFilterInput
		$pageInput: PageInput!
	) {
		getTimesheets(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalElements
			timesheets {
				id
				startTime
				endTime
				team {
					id
					name
				}
				teamLead {
					id
					firstName
					lastName
				}
				teamMember {
					id
					fullName
					customId
				}
				fields {
					id
					name
				}
				workLogs {
					id
					workType
				}
				timesheetFieldHours {
					fieldId
					duration
					isLunchTimeSubtracted
				}
				comment
			}
		}
	}
`;

export default GET_HOURS_EXPORT_DATA;
