import React, { ReactElement } from 'react';

import MUIGrid, {
	GridProps,
	GridSize as MUIGridSize,
} from '@mui/material/Grid';

export type GridSize = MUIGridSize;

const Grid = (props: GridProps): ReactElement => {
	const { className, children, ...MUIGridProps } = props;

	return (
		<MUIGrid className={className} {...MUIGridProps}>
			{children}
		</MUIGrid>
	);
};

export default Grid;
