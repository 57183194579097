import React from 'react';

import { ApolloError, useMutation, useReactiveVar } from '@apollo/client';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTeamInput } from 'Models/teams/isTeamInput';

import useLocationID from 'Hooks/useLocationID';
import useProfile from 'Hooks/useProfile';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import ErrorModal from '../../../../components/ErrorBoundary/ErrorModal';
import EDIT_TEAM from '../api/editTeam';
import { editingTeamIdVar, teamInputVar } from '../state';
import ManageTeamTemplate from './ManageTeamTemplate';

type TProps = {
	open: boolean;
	toggleOpen: () => void;
};

const TEAM_LEAD_COUNT = 1;

const EditTeamModal = (props: TProps) => {
	const { toggleOpen } = props;
	const locationId = useLocationID();
	const { profile } = useProfile();
	const { t } = useTranslation();
	const teamId = useReactiveVar(editingTeamIdVar);

	const [editTeam, { loading, error }] = useMutation(EDIT_TEAM, {
		onCompleted: handleCompleted,
	});

	function handleCompleted() {
		toggleOpen();
		teamInputVar({});
	}

	const saveTeam = (input: TTeamInput) => {
		if (profile && locationId) {
			analyticsTrack(TrackEvents.TeamModified, {
				userId: profile.id,
				groupId: locationId,
				teamSize: input.teamMemberInput
					? (input.teamMemberInput.length + TEAM_LEAD_COUNT).toString()
					: TEAM_LEAD_COUNT.toString(),
			});
		}

		if (teamId) {
			void editTeam({ variables: { locationId, input, teamId } });
		}
	};

	if (error) {
		throw new ApolloError(error);
	}

	return (
		<ErrorBoundary fallback={ErrorModal}>
			<ManageTeamTemplate
				modalTitle={t('teamsPage.editTeamModal.title')}
				isLoading={loading}
				saveTeam={saveTeam}
				{...props}
			/>
		</ErrorBoundary>
	);
};

export default EditTeamModal;
