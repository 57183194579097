import { URLFiltersType } from 'Models/history/isURLFilters';
import getSeasonPerDay from 'Models/seasons/getSeasonPerDay';

function getSeasonParams(
	filters: URLFiltersType,
	seasonCutoffDate?: string | null
) {
	if (filters.season && filters.dateTo && filters.dateFrom) {
		return {
			seasonName: filters.season,
			seasonCutoffDate: filters.dateTo.slice(-5),
			to: filters.dateTo,
			from: filters.dateFrom,
		};
	} else {
		const cutoffDate = seasonCutoffDate || '01-01';
		return getSeasonPerDay(cutoffDate, new Date());
	}
}

export default getSeasonParams;
