import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useLocationID from 'Hooks/useLocationID';

import DropdownContent from '../DropdownContent';
import { GET_TEAMS_MEMBERS_QUERY } from './api/getTeamMembers';

type TTeamMembersProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

function TeamMembers(props: TTeamMembersProps) {
	const { filters, updateFilters } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();
	const locationId = useLocationID();
	const { data, loading, error } = useQuery(GET_TEAMS_MEMBERS_QUERY, {
		variables: {
			locationId: locationId,
			filterBy: {
				teamIds: filters.teamId,
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	const open = Boolean(anchorEl);
	const teamMembers = data?.getTeamMembersPageable.teamMembers || [];
	const dropdownContentData = teamMembers.map(
		(teamMember) => teamMember.fullName
	);

	const appliedMemberIds = filters.teamMemberIds || [];
	const appliedTeamMembers = teamMembers
		.filter((teamMember) => appliedMemberIds.includes(teamMember.id))
		.map((teamMember) => teamMember.fullName);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const onItemClick = (teamMemberName: string, active: boolean) => {
		const teamMemberId = teamMembers?.find(
			(teamMember) => teamMember.fullName === teamMemberName
		)?.id;
		if (teamMemberId) {
			const newMemberIds = active
				? appliedMemberIds.filter(
						(appliedMemberId) => appliedMemberId !== teamMemberId
				  )
				: [...appliedMemberIds, teamMemberId];

			updateFilters({
				...filters,
				teamMemberIds: newMemberIds,
			});
		}
	};

	const onClear = () => {
		updateFilters({
			...filters,
			teamMemberIds: undefined,
		});
	};

	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{t('labels.teamMembers')}
				{appliedMemberIds?.length ? ` (${appliedMemberIds?.length})` : ''}
			</Button>
			<Menu.Menu
				id="category-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={loading}
					dropdownItems={dropdownContentData}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={appliedTeamMembers}
				/>
			</Menu.Menu>
		</>
	);
}

export default TeamMembers;
