import isOneOf from 'vet/isOneOf';

import Roles from '../permissions/Roles';

const ADMIN: keyof typeof Roles = Roles[Roles.ADMIN];
const FARMER: keyof typeof Roles = Roles[Roles.FARMER];
const MANAGER: keyof typeof Roles = Roles[Roles.MANAGER];
const ADVISOR: keyof typeof Roles = Roles[Roles.ADVISOR];
const DRIVER: keyof typeof Roles = Roles[Roles.DRIVER];
const PA_ADMIN: keyof typeof Roles = Roles[Roles.PA_ADMIN];
const PA_OWNER: keyof typeof Roles = Roles[Roles.PA_OWNER];
const PA_MEMBER: keyof typeof Roles = Roles[Roles.PA_MEMBER];

const AllRoles = {
	ADMIN,
	FARMER,
	MANAGER,
	ADVISOR,
	DRIVER,
	PA_ADMIN,
	PA_MEMBER,
	PA_OWNER,
} as const;

const isRole = isOneOf(...Object.values(AllRoles));

export type TRoles = keyof typeof Roles;
export { isRole };
