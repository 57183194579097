import { gql, TypedDocumentNode } from '@apollo/client';

import { TPartnerCause } from '../types';

type TGetPartnerCausesResponse = {
	causes: TPartnerCause[];
};

const GET_PARTNER_CAUSES: TypedDocumentNode<
	{
		getPartnershipCauses: TGetPartnerCausesResponse;
	},
	null
> = gql`
	query getPartnerCauses {
		getPartnershipCauses {
			causes {
				id
				name
				causeType
				description
				causeImages {
					id
					path
					filename
					mimetype
					createdAt
				}
			}
		}
	}
`;

export default GET_PARTNER_CAUSES;
