import React, { ReactElement } from 'react';

import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';

import CloseIcon from '../../../icons/Close';
import IconButton from '../../../inputs/IconButton';

export type TDialogTitle = {
	title?: string;
	closeIcon?: boolean;
	closeIconHandler?: () => void;
} & DialogTitleProps;

const DialogTitle = (props: TDialogTitle): ReactElement => {
	const { children, title, closeIcon, closeIconHandler, ...MUIProps } = props;

	const useTitleStyles = makeStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '1.25rem 1.5rem',
			fontWeight: 'bold',
		},
		closeButton: {
			position: 'absolute',
			right: 12,
			top: 10,
		},
	});
	const { root, closeButton } = useTitleStyles();

	const content = title || children;

	return (
		<MuiDialogTitle classes={{ root }} {...MUIProps}>
			{content}
			{closeIcon === false ? null : (
				<IconButton
					onClick={closeIconHandler}
					className={closeButton}
					size="large"
				>
					<CloseIcon />
				</IconButton>
			)}
		</MuiDialogTitle>
	);
};

export default DialogTitle;
