// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1NJ_gPPkUYdy0gCexZ6X84 {\n  border: 0 !important;\n  padding-left: .375rem;\n}\n\n._2gWTgGFBlRMFawBGgTbUY_ td {\n  font-size: .6rem !important;\n  padding: .25rem .375rem;\n  border-bottom: .3px solid #e0e0e0;\n  line-height: 1rem;\n}\n\n._2gWTgGFBlRMFawBGgTbUY_:last-child td {\n  border-bottom: 0;\n  padding-bottom: .5rem;\n}\n\n._2gWTgGFBlRMFawBGgTbUY_ th {\n  font-size: .725rem !important;\n  padding: .25rem .375rem;\n  line-height: .8rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"tableHeading": "_1NJ_gPPkUYdy0gCexZ6X84",
	"row": "_2gWTgGFBlRMFawBGgTbUY_"
};
module.exports = exports;
