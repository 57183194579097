import { Map as TMap } from 'leaflet';

function overlap(rect1: DOMRect, rect2: DOMRect, paddingValue: number) {
	return !(
		rect1.right - rect2.left < 2 * paddingValue ||
		rect2.right - rect1.left < 2 * paddingValue ||
		rect1.bottom - rect2.top < 2 * paddingValue ||
		rect2.bottom - rect1.top < 2 * paddingValue
	);
}

//default padding set by leaflet
const DEFAULT_TOOLTIP_PADDING = 6;

function hideOverlappingTooltips(map: TMap): void {
	const tooltips: HTMLElement[] = [];
	const rects: DOMRect[] = [];

	map.eachLayer((l) => {
		const tooltip = l.getTooltip();
		if (tooltip) {
			const tooltipContainer = tooltip.getElement();
			if (tooltipContainer) {
				tooltipContainer.style.visibility = '';

				rects.push(tooltipContainer.getBoundingClientRect());
				tooltips.push(tooltipContainer);
			}
		}
	});

	tooltips.forEach((tooltip, i) => {
		if (tooltip.style.visibility != 'hidden') {
			for (let j = i + 1; j < tooltips.length; j++) {
				if (overlap(rects[i], rects[j], DEFAULT_TOOLTIP_PADDING))
					tooltips[j].style.visibility = 'hidden';
			}
		}
	});
}

export default hideOverlappingTooltips;
