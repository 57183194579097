import { TChartProps } from 'UI/display/Chart';
import { FarmableColors, FarmableTextColors } from 'UI/theme/Colors';

export const initialChartOptions: TChartProps['options'] = {
	chart: {
		type: 'column',
		spacing: [0, 0, 0, 0],
		marginTop: 55,
		marginBottom: 55,
		style: {
			fontFamily: 'Lato',
		},
	},
	plotOptions: {
		series: {
			marker: {
				enabled: false,
			},
		},
	},
	legend: {
		align: 'left',
		padding: 1,
	},
	credits: {
		enabled: false,
	},
	series: [],
	title: {
		align: 'left',
	},
	xAxis: {
		type: 'datetime',
		labels: {
			y: 24,
			style: {
				fontSize: '0.75rem',
				color: FarmableTextColors.SECONDARY,
			},
		},
		tickLength: 0,
		lineColor: FarmableColors.BLACK_12,
	},
	yAxis: {
		title: {
			text: null,
		},
		opposite: true,
		labels: {
			style: {
				fontSize: '0.75rem',
				color: FarmableTextColors.SECONDARY,
			},
			x: 8,
			align: 'left',
			reserveSpace: true,
		},
	},
};
