import { TTeam } from 'Models/teams/isTeam';
import { TTeamInputPartial } from 'Models/teams/isTeamInput';

const cachedIDs: number[] = [];

function _getCache() {
	return [...cachedIDs];
}

function addId(id: number) {
	if (!cachedIDs.includes(id)) cachedIDs.push(id);
}

function removeID(idString?: string | null) {
	if (idString) {
		const id = Number(idString);
		const index = cachedIDs.indexOf(id);
		if (index >= 0) cachedIDs.splice(index, 1);
	}
}

function resetCache(teamInput: TTeamInputPartial) {
	if (teamInput.teamMemberInput?.length) {
		teamInput.teamMemberInput.forEach((member) => removeID(member.customId));
	}
}

function getTeamMemberId<TGenericTeam>(
	teams: (TGenericTeam & Pick<TTeam, 'teamMembers'>)[]
): number {
	const locationTeamMembers = teams
		.map((team) => team.teamMembers)
		// flatten the array (polyfill)
		.reduce((acc, val) => acc.concat(val), []);

	const locationTeamMemberIDs = locationTeamMembers
		.reduce((memberIDs: number[], member) => {
			const id = Number(member.customId);

			if (id) memberIDs.push(id);

			return memberIDs;
		}, [])
		.concat(cachedIDs);

	const lastID = Math.max(0, ...locationTeamMemberIDs) + 1;

	addId(lastID);

	return lastID;
}

export { getTeamMemberId, removeID, resetCache, _getCache };
