import teamName from 'Models/teams/teamName';
import timesheetDateString from 'Models/timesheet/timesheetDateString';
import timesheetDurationString from 'Models/timesheet/timesheetDurationString';
import timesheetEndTimeString from 'Models/timesheet/timesheetEndTimeString';
import timesheetFieldsNumber from 'Models/timesheet/timesheetFieldsNumber';
import timesheetMember from 'Models/timesheet/timesheetMember';
import timesheetStartTimeString from 'Models/timesheet/timesheetStartTimeString';
import timesheetTaskString from 'Models/timesheet/timesheetTaskString';

import { TTimesheetTableData } from '../types';

function getHoursLogsRowData(timesheet: TTimesheetTableData) {
	return Object.values({
		date: timesheetDateString(timesheet),
		start: timesheetStartTimeString(timesheet),
		stop: timesheetEndTimeString(timesheet),
		duration: timesheetDurationString(timesheet),
		team: teamName(timesheet.team),
		who: timesheetMember(timesheet),
		task: timesheetTaskString(timesheet),
		fields: timesheetFieldsNumber(timesheet),
	});
}

export default getHoursLogsRowData;
