import { TField } from '../fields/isField';
import { TPartnerField } from '../fields/isPartnerField';
import { THarvest } from './types/Harvest';

export default function getHarvestFields(
	{ fields }: Pick<THarvest, 'fields'>,
	locationFields: TField[] | TPartnerField[]
) {
	const harvestFieldsId = fields.map((field) => field.id);

	return locationFields
		.map((field: TField | TPartnerField) =>
			'field' in field ? field.field : field
		)
		.filter((field) => harvestFieldsId.includes(field.id));
}
