import React, { ReactElement, ReactNode } from 'react';

import { DivIcon, Icon, LatLngExpression } from 'leaflet';
import { Marker as LeafletMarker } from 'react-leaflet';

type TMarkerProps = {
	icon?: Icon | DivIcon;
	children?: ReactNode;
	position: LatLngExpression;
	onClick?: () => void;
};

const Marker = (props: TMarkerProps): ReactElement => {
	const { children, onClick, ...rest } = props;
	return (
		<LeafletMarker
			eventHandlers={{ click: onClick ? onClick : () => null }}
			{...rest}
		>
			{children}
		</LeafletMarker>
	);
};

export default Marker;
