import { gql, TypedDocumentNode } from '@apollo/client';

import { TUploadPartnerCompanyImageInput } from '../types';

const UPLOAD_PARTNER_IMAGE_MUTATION: TypedDocumentNode<
	{
		uploadPartnershipCompanyImage: { name: string };
	},
	TUploadPartnerCompanyImageInput
> = gql`
	mutation uploadPartnerImage($file: Upload!) {
		uploadPartnershipCompanyImage(file: $file) {
			id
		}
	}
`;

export default UPLOAD_PARTNER_IMAGE_MUTATION;
