import { TTranslateFunction } from 'Features/localization';

import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

import JobTypes from './JobTypes';
import { TJob } from './isJob';

//
// Internal note
//
// According to the required changes - the jobTreatment is treated as a job.jobType
// - we cannot change the backend name. #488
//

export default function jobTreatment(
	job: Pick<TJob, 'jobType' | 'customJobType'>,
	translateFunction?: TTranslateFunction
): string {
	if (job.jobType === JobTypes.CUSTOM) {
		return job.customJobType?.name
			? stringToFirstUppercase(job.customJobType.name)
			: '-';
	}

	return translateFunction
		? // TJob.jobType is just a string type for now, so it's much safer
		  // to cast it here at this moment, and then (in the near future)
		  // work on the validator in a separate PR.
		  translateFunction(`common.jobs.types.${job.jobType}`)
		: stringToFirstUppercase(job.jobType);
}
