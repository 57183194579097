import React, { useEffect, useMemo, useState } from 'react';

import useLocation from 'Features/router/hooks/useLocation';
import { TLocation } from 'Models/locations/isLocation';
import sortByRole from 'Models/locations/sortByRole';
import { TPartnerLocation } from 'Models/partnershipCompany/isPartnerLocation';
import { Container, Divider, Item } from 'UI/navigation/Sidebar';

import useLocations from 'Hooks/useLocations';
import usePartnershipCompany from 'Hooks/usePartnerCompany';
import useProfileRoles from 'Hooks/useProfileRoles';

import getCompanyIdFromURL from '../../utils/getCompanyIdFromURL';
import getLocationIdFromUrl from '../../utils/getLocationIdFromUrl';
import hasPartnerCompany from '../../utils/hasPartnerCompany';
import SidebarItem from './components/SidebarItem';

type TLocationItem = Pick<TLocation | TPartnerLocation, 'id' | 'name'>;

export type TActiveValue = number | 'none';

// We need to take into account the fact that inactive item, company item,
// and separator also have their own indexes, so it calls for increasing
// the index of location items.
const setIndex = (index: number, hasPartnerButton: boolean) => {
	const existingItems = hasPartnerButton
		? ['inactiveItem', 'partnerCompany', 'partnerDivider']
		: ['inactiveItem'];

	return existingItems.length + index;
};

const INACTIVITY_ITEM_STYLES = {
	minHeight: 0,
	'&&': {
		padding: 0,
	},
};

const FarmSidebar = () => {
	const { locations } = useLocations();
	const location = useLocation();
	const currentUrl = location.pathname;
	const [activeItem, setActiveItem] = useState<TActiveValue>('none');
	const hasActiveItem = typeof activeItem === 'number';

	const { company } = usePartnershipCompany();
	const sortedLocations = useMemo(
		() => (locations?.length && sortByRole(locations)) || [],
		[locations]
	);

	const { profileRoles } = useProfileRoles();
	const isPartner = hasPartnerCompany(profileRoles);

	const shouldDisplayPartner = isPartner;
	const isPartnerDataLoaded = Boolean(company);

	function handleActiveItem() {
		const locationId = getLocationIdFromUrl();
		const companyId = getCompanyIdFromURL();

		if (companyId) {
			setActiveItem(1);
		} else if (locationId) {
			const index =
				sortedLocations?.findIndex((loc) => loc.id === locationId) || 0;

			setActiveItem(
				setIndex(index, shouldDisplayPartner && !!isPartnerDataLoaded)
			);
		} else {
			setActiveItem('none');
		}
	}

	useEffect(handleActiveItem, [
		sortedLocations,
		currentUrl,
		isPartnerDataLoaded,
	]);

	const onItemClick = (val: TActiveValue) => {
		setActiveItem(val);
	};

	const locationItems = useMemo(
		() =>
			sortedLocations.map((location: TLocationItem, index: number) => {
				const tabIndex = setIndex(
					index,
					shouldDisplayPartner && !!isPartnerDataLoaded
				);
				const isActive = hasActiveItem && activeItem === tabIndex;

				return (
					<SidebarItem
						{...location}
						baseRoute="/locations"
						defaultRoute="dashboard"
						index={tabIndex}
						key={tabIndex}
						active={isActive}
						onClick={onItemClick}
					/>
				);
			}),
		[isPartnerDataLoaded, sortedLocations, activeItem]
	);

	const shouldDisplaySidebar = shouldDisplayPartner
		? isPartnerDataLoaded && locations
		: locations;

	const partnerCompanyItem =
		shouldDisplayPartner && isPartnerDataLoaded && company ? (
			<SidebarItem
				{...company}
				baseRoute="/partners"
				directRoute={`/partners/${company.id}/dashboard`}
				index={1}
				key={1}
				active={activeItem === 1}
				onClick={onItemClick}
			/>
		) : null;

	const partnerDivider =
		shouldDisplayPartner && isPartnerDataLoaded ? <Divider key={2} /> : null;

	const indicatorProps = {
		style: {
			display: !hasActiveItem ? 'none' : undefined,
		},
	};
	const inactivityItem = <Item value={'none'} sx={INACTIVITY_ITEM_STYLES} />;

	return shouldDisplaySidebar ? (
		<Container
			value={activeItem}
			variant="scrollable"
			TabIndicatorProps={indicatorProps}
			data-testid="sidebar"
			visibleScrollbar
			scrollButtons={false}
			sx={{
				height: '100vh',
			}}
		>
			{inactivityItem}
			{partnerCompanyItem}
			{partnerDivider}
			{locationItems}
		</Container>
	) : null;
};

export default FarmSidebar;
