import React, { ReactElement } from 'react';

import Grid from '../../layout/Grid';

type TSection = {
	children: ReactElement | ReactElement[];
};

const Section = (props: TSection) => {
	const { children } = props;

	return (
		<Grid container direction="row">
			{children}
		</Grid>
	);
};

export default Section;
