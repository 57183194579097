import React, { ReactElement } from 'react';

import MUICard, { CardProps } from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';

const useCardStyles = makeStyles({
	root: {
		border: 'solid 1px #e0e0e0',
		boxShadow: 'none',
		borderRadius: '0.5rem',
	},
});

const Card = (props: CardProps): ReactElement => {
	const { children, className, ...MUIProps } = props;
	const { root } = useCardStyles();

	return (
		<MUICard className={className} classes={{ root }} {...MUIProps}>
			{children}
		</MUICard>
	);
};

export default Card;
