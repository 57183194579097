// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2BFBUEy90VIpja7XqPq33t {\n  min-height: 17.5rem;\n  flex-grow: 1;\n  flex-basis: 0;\n  flex-shrink: 1;\n}\n\n", ""]);
// Exports
exports.locals = {
	"mapContainer": "_2BFBUEy90VIpja7XqPq33t"
};
module.exports = exports;
