const DRAFT = 'DRAFT';
const NOT_STARTED = 'NOT_STARTED';
const IN_PROGRESS = 'IN_PROGRESS';
const PAUSED = 'PAUSED';
const COMPLETED = 'COMPLETED';
const CANCELED = 'CANCELED';

export const JobStatuses = {
	DRAFT,
	NOT_STARTED,
	IN_PROGRESS,
	PAUSED,
	COMPLETED,
	CANCELED,
} as const;

const ALL = Object.values(JobStatuses)
	// used type guard to get appropriate type for validator
	.filter((value): value is TJobStatuses => typeof value === 'string');

const JOB_STATUSES = {
	DRAFT,
	NOT_STARTED,
	IN_PROGRESS,
	PAUSED,
	COMPLETED,
	CANCELED,
	ALL,
};

export type TJobStatuses = keyof typeof JobStatuses;

export default JOB_STATUSES;

export { DRAFT, NOT_STARTED, IN_PROGRESS, PAUSED, COMPLETED, CANCELED, ALL };
