import ISOToLocal from '../dates/ISOToLocal';
import standardFormat from '../dates/standardFormat';
import { TTimesheet } from './isTimesheet';

function timesheetDateString(timesheet: Pick<TTimesheet, 'startTime'>): string {
	return timesheet.startTime
		? standardFormat(ISOToLocal(timesheet.startTime))
		: '-';
}

export default timesheetDateString;
