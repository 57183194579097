import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';

import { teamInputVar } from '../../../state';

const TeamNameInput = () => {
	const teamInput = useReactiveVar(teamInputVar);
	const { t } = useTranslation();

	function onChangeHandler(value: string) {
		const newTeamInput = {
			...teamInput,
			name: value,
		};
		teamInputVar(newTeamInput);
	}

	return (
		<Box mb={2}>
			<Text variant="label">Team name</Text>
			<TextField
				placeholder={t('labels.enterName')}
				value={teamInput.name || ''}
				onChange={(val) => onChangeHandler(val)}
			/>
		</Box>
	);
};

export default TeamNameInput;
