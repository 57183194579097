import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TProduct } from 'Models/products/Product';

import { treatmentLibraryProductFragmentFields } from './fragments';

type TGetTreatmentLibraryProductsResponse = {
	getTreatmentLibraryProducts: {
		products: TProduct[];
	};
};

type TGetTreatmentLibraryProductsRequest = {
	libraryId: string;
	pageInput?: TPageInput;
};

export const GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS: TypedDocumentNode<
	TGetTreatmentLibraryProductsResponse,
	TGetTreatmentLibraryProductsRequest
> = gql`
	query getTreatmentLibraryProducts($libraryId: ID!, $pageInput: PageInput) {
		getTreatmentLibraryProducts(libraryId: $libraryId, pageInput: $pageInput) {
			products {
				${treatmentLibraryProductFragmentFields}
			}
		}
	}
`;
