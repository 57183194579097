import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TProduct } from 'Models/products/Product';
import { TProductInput } from 'Models/products/ProductInput';

import { treatmentLibraryProductFragmentFields } from './fragments/productFragment';
import { GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS } from './getTreatmentLibraryProducts';

type TUpdateTreatmentLibraryProductResult = {
	updateTreatmentLibraryProduct: TProduct;
};

type TUpdateTreatmentLibraryProductInput = {
	libraryId: string;
	productId: string;
	input: Omit<TProductInput, 'treatments'>;
};

export const UPDATE_TREATMENT_LIBRARY_PRODUCT: TypedDocumentNode<
	TUpdateTreatmentLibraryProductResult,
	TUpdateTreatmentLibraryProductInput
> = gql`
	mutation UpdateTreatmentLibraryProduct(
		$libraryId: ID!
		$productId: ID!
		$input: UpdateProductInput!
	) {
		updateTreatmentLibraryProduct(
			libraryId: $libraryId
			productId: $productId
			input: $input
		) {
			${treatmentLibraryProductFragmentFields}
		}
	}
`;
