import React, { ReactElement } from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { TSvgIconProps } from '..';

function ToxicWarningIcon(props: TSvgIconProps): ReactElement {
	return (
		<SvgIcon viewBox="0 0 48 48" sx={{ width: 64, height: 64 }} {...props}>
			<g stroke="#000" fill="none" fillRule="evenodd">
				<path
					strokeWidth=".5"
					fill="#FFF"
					d="m9.538 23.99 12.734-12.72 12.706 12.715-12.712 12.733z"
				/>
				<path
					d="m18.031 32.466 4.242 4.244 4.244-4.244 1.414 1.414-5.657 5.657-5.657-5.657 1.414-1.414zm15.264-13.02 4.535 4.535-3.95 3.95-1.414-1.414L35 23.98l-3.12-3.121 1.415-1.415zM22.274 8.424l4.95 4.95-1.415 1.414-3.536-3.535-3.535 3.535-1.414-1.415 4.95-4.95zm-11.021 11.02 1.414 1.415-3.122 3.12 2.536 2.537-1.414 1.414-3.95-3.95 4.536-4.536z"
					strokeWidth=".5"
					fill="red"
					fillRule="nonzero"
				/>
				<path
					d="m29.316 18.848-2.475 2.474m-1.414-1.414 2.475-2.475m0 0-.354-.353a1.5 1.5 0 0 1 0-2.122l.072-.07a1.5 1.5 0 0 1 2.537 1.326c-.03.163-.074.334-.134.512.178-.06.35-.104.512-.133a1.5 1.5 0 0 1 1.327 2.537l-.07.071a1.5 1.5 0 0 1-2.122 0l-.354-.353M17.034 17.433l2.475 2.475m-1.414 1.414-2.475-2.474m0 0-.354.353a1.5 1.5 0 0 1-2.121 0l-.071-.071a1.5 1.5 0 0 1 1.327-2.537c.163.03.334.074.512.133a3.705 3.705 0 0 1-.133-.512 1.5 1.5 0 0 1 2.536-1.327l.072.071a1.5 1.5 0 0 1 0 2.122l-.354.353M15.62 27.715l2.086-2.086m.76 2.069-1.432 1.432m0 0 .354.353a1.5 1.5 0 0 1 0 2.122l-.072.07a1.5 1.5 0 0 1-2.536-1.326c.029-.163.073-.334.133-.512-.178.06-.349.104-.512.133a1.5 1.5 0 0 1-1.327-2.537l.071-.071a1.5 1.5 0 0 1 2.121 0l.354.353M27.902 29.13l-1.433-1.433m.765-2.063 2.082 2.081m0 0 .354-.353a1.5 1.5 0 0 1 2.121 0l.071.071a1.5 1.5 0 0 1-1.327 2.537 3.705 3.705 0 0 1-.512-.133c.06.178.104.349.134.512a1.5 1.5 0 0 1-2.537 1.327l-.072-.071a1.5 1.5 0 0 1 0-2.122l.354-.353"
					fill="#FFF"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					strokeLinejoin="round"
					d="M23.468 23.082h2l-1 1zM19.468 23.082h2l-1 1z"
				/>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="m21.468 27.082 1-1 1 1"
				/>
				<path
					d="M25.467 31.082v-2.999a5 5 0 1 0-6-.003l.001 3.002h5.999z"
					strokeLinejoin="round"
				/>
				<path strokeLinecap="round" d="M21.468 29.082v2M23.468 29.082v2" />
			</g>
		</SvgIcon>
	);
}

export default ToxicWarningIcon;
