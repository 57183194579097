import Countries, { TCountrySignatures } from './LocationCountries';
import isLocation, { TLocation } from './isLocation';
import { TLocations } from './isLocations';

export const NONE = '-';

type TNone = typeof NONE;

function locationsCountry(location: TLocations): TNone;
function locationsCountry(location: TLocation): TCountrySignatures | TNone;
function locationsCountry(
	location: TLocations | TLocation
): TCountrySignatures | TNone;
function locationsCountry(
	location: TLocations | TLocation
): TCountrySignatures | TNone {
	if (isLocation(location)) {
		const signature = location.country || NONE;

		return Countries[signature] || NONE;
	}

	return NONE;
}

export default locationsCountry;
