import React, { ReactElement } from 'react';

import { TFieldVarietyInput } from 'Models/fields/isFieldVarietyInput';
import Grid from 'UI/layout/Grid';

import GreenHouseVariety from './GreenHouseVariety';

type TGreenHouseVarietiesProps = {
	varieties: TFieldVarietyInput[];
	setVarieties: (varieties: TFieldVarietyInput[]) => void;
};

function GreenHouseVarieties(props: TGreenHouseVarietiesProps): ReactElement {
	const { varieties, setVarieties } = props;
	const varietiesLen = varieties.length;
	const hasSingleVariety = varietiesLen === 1;

	return (
		<Grid container spacing={3}>
			{varieties.map((variety, index) => {
				return (
					<GreenHouseVariety
						key={variety._id}
						itemIndex={index}
						variety={variety}
						varieties={varieties}
						setVarieties={setVarieties}
						isSingle={hasSingleVariety}
					/>
				);
			})}
		</Grid>
	);
}

export default GreenHouseVarieties;
