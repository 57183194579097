import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import jobDateCompletedString from 'Models/jobs/jobDateCompletedString';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';

import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import Causes from '../../common/components/DetailModal/Causes';
import Comments from '../../common/components/DetailModal/Comments';
import Fields from '../../common/components/DetailModal/Fields';
import Information from '../../common/components/DetailModal/Information';
import Photos from '../../common/components/DetailModal/Photos';
import ProductDetails from '../../common/components/DetailModal/ProductDetails';
import SafeSpraying from '../../common/components/DetailModal/SafeSpraying';
import Stats from '../../common/components/DetailModal/Stats';
import Weather from '../../common/components/DetailModal/Weather';
import { detailsModalData, isDetailsModalOpen } from '../state';

const JobDetailsModal = () => {
	const { t } = useTranslation();
	const isOpen = useReactiveVar(isDetailsModalOpen);
	const jobData = useReactiveVar(detailsModalData);

	function onClose() {
		isDetailsModalOpen(false);
	}

	const hasSprayingSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.SAFE_SPRAYING
	);
	const hasFarmableProSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.FARMABLE_PRO
	);

	return jobData ? (
		<Modal.UncontrolledModal open={isOpen} toggleOpen={onClose}>
			<Modal.DialogTitle
				title={jobDateCompletedString(jobData)}
				closeIconHandler={onClose}
			/>
			<Modal.DialogContent>
				<Information job={jobData} />
				<Stats job={jobData} />
				<Weather job={jobData} />
				<Fields job={jobData} />
				<Causes job={jobData} />
				<ProductDetails job={jobData} />
				<Photos job={jobData} />
				{hasSprayingSubscription || hasFarmableProSubscription ? (
					<SafeSpraying job={jobData} />
				) : null}
				<Comments job={jobData} />
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Button variant={'outlined'} onClick={onClose}>
					{t('buttons.close')}
				</Button>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	) : null;
};

export default JobDetailsModal;
