import replaceYearToCurrent from 'Utils/replaceYearToCurrent';

type TObjectWithDate = {
	date?: string | null;
};

function getOldestItem(
	currentYear?: TObjectWithDate,
	previousYear?: TObjectWithDate
) {
	if (currentYear?.date && previousYear?.date) {
		const currentYearDate = new Date(currentYear.date).getTime();
		const previousYearDate = new Date(
			replaceYearToCurrent(previousYear.date)
		).getTime();

		return currentYearDate > previousYearDate
			? previousYearDate
			: currentYearDate;
	}

	if (previousYear?.date) {
		return new Date(replaceYearToCurrent(previousYear.date)).getTime();
	}

	if (currentYear?.date) {
		return new Date(currentYear.date).getTime();
	}

	return undefined;
}

export default getOldestItem;
