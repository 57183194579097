import { gql, TypedDocumentNode } from '@apollo/client';
import { TAccountWithId } from 'Models/account/isAccount';
import { TAccountInput } from 'Models/account/isAccountInput';

const UPDATE_ACCOUNT: TypedDocumentNode<
	TAccountWithId,
	{ input: Omit<TAccountInput, 'notes' | 'hasEmailSubscription'> }
> = gql`
	mutation updateAccount($input: AccountInput!) {
		updateAccount(input: $input) {
			id
			email
			firstName
			lastName
			mobilePhone
			profileImage {
				path
			}
		}
	}
`;

export default UPDATE_ACCOUNT;
