import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TPartnerVariety } from 'Models/planner/PartnerVariety';
import Text from 'UI/display/Text';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu/menu';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';

import useManageHistory from 'Hooks/useManageHistory';
import getPartnerVarietyFromURL from 'Utils/getPartnerVarietyFromURL';

import GET_PARTNER_VARIETIES_DATA from '../../../../../features/api/getPartnerVarieties';
import CollapsedCategory from './CollapsedCategory';
import styles from './styles.module.scss';

const dropdownStyles = {
	left: '0.5rem',
	top: '-0.25rem',
	'.MuiList-root': {
		padding: 0,
		maxHeight: '18.125rem',
	},
};

export default function CategoryAndVarietyDropdown() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { t } = useTranslation();

	const { historyPush, paramsFromURL } = useManageHistory();

	const selectedValue = getPartnerVarietyFromURL(paramsFromURL);
	const onCategoryClick = (variety: TPartnerVariety) => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				partnerVarietyCategory: variety.category,
				partnerVarietyName: variety.name,
			},
		};

		historyPush(newSearchParams);
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { data, loading, error } = useQuery(GET_PARTNER_VARIETIES_DATA);

	if (loading) {
		return null;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const partnerVarieties = data.getPartnerVarieties;

		const varietiesByCategories = _map(
			_groupBy(partnerVarieties, 'category'),
			(varieties, name) => ({ name, varieties })
		);

		const collapsedCategories = varietiesByCategories.map(
			({ name, varieties }) => {
				return (
					<CollapsedCategory
						category={name}
						varieties={varieties}
						selectedValue={selectedValue}
						onChangeHandler={onCategoryClick}
						key={name}
					/>
				);
			}
		);

		const selectCropTypeLabel = (
			<div className={styles.selectCropTypeLabel}>
				<Text variant="label">{t('plannerPage.cropTypeDropdownTitle')}</Text>
			</div>
		);

		return (
			<>
				<Box className={styles.dropdownBox} onClick={handleClick}>
					<p className={styles.dropdownTitle}>
						{selectedValue
							? `${selectedValue.category}: ${selectedValue.name}`
							: t('plannerPage.cropTypeDropdownTitle')}
					</p>
					<ArrowDropDownIcon />
				</Box>
				<Menu
					id="navbar-profile-menu"
					anchorEl={anchorEl}
					transitionDuration={0}
					open={open}
					sx={dropdownStyles}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'navbar-profile-menu',
						dense: true,
					}}
				>
					{selectCropTypeLabel}
					{collapsedCategories}
				</Menu>
			</>
		);
	}

	return null;
}
