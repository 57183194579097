import { getBoundingBox } from 'geolocation-utils';

import { TPartnerLocations } from '../partnershipCompany/isPartnerLocation';
import hasSharedLocationCoordinate from './hasSharedLocationCoordinate';

type TMapBoundingBox = [[number, number], [number, number]];

const getSharedLocationsBBox = (
	sharedLocations: TPartnerLocations
): TMapBoundingBox | null => {
	const points = sharedLocations
		.filter(hasSharedLocationCoordinate)
		.map((location) => ({
			longitude: location.coordinate.x,
			latitude: location.coordinate.y,
		}));

	if (!points.length) {
		return null;
	}

	// set margin as 1km if there is only one shared location to avoid high zoom
	const bounds = getBoundingBox(points, points.length > 1 ? 0 : 1000);

	return [
		[bounds.topLeft.latitude, bounds.topLeft.longitude],
		[bounds.bottomRight.latitude, bounds.bottomRight.longitude],
	];
};

export default getSharedLocationsBBox;
