import { GRAM, KILOGRAM, VolumeUnits } from '../units';
import { TProductConsumption } from './calculateProductConsumption';

export default (product: TProductConsumption): TProductConsumption => {
	const { name, unit, amount } = product;
	switch (unit) {
		case GRAM:
			return {
				name,
				unit: KILOGRAM,
				amount: amount / 1000.0,
			};

		case VolumeUnits.MILLILITER:
			return {
				name,
				unit: VolumeUnits.LITER,
				amount: amount / 1000.0,
			};

		default:
			return product;
	}
};
