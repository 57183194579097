import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { FarmableColors } from 'UI/theme/Colors';

import useCompanyID from 'Hooks/useCompanyID';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import GET_PARTNER_TREATMENT_LIBRARIES from '../../../../PartnerTreatmentsPage/api/getPartnerTreatmentLibraries';
import {
	DELETE_TREATMENT_LIBRARY,
	removeDeletedLibraryFromCache,
} from '../../../api';

function DeleteLibraryModal() {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const companyID = useCompanyID();
	const history = useHistory();

	const libraryId = getTreatmentLibraryIdFromURL();

	const toggleOpen = () => {
		setOpen(!open);
	};

	const [deleteLibrary, mutationResult] = useMutation(
		DELETE_TREATMENT_LIBRARY,
		{
			refetchQueries: [GET_PARTNER_TREATMENT_LIBRARIES],
			update: removeDeletedLibraryFromCache,
			onCompleted() {
				history.push(`/partners/${companyID}/settings/treatments`);
			},
		}
	);

	useSnackbarNotifications({
		mutationResult,
		messageSuccess: t('info.deletePartnerLibrary.success'),
		messageFailure: t('info.deletePartnerLibrary.failure'),
	});

	const onDeleteLibrary = () => {
		void deleteLibrary({ variables: { libraryId } });
	};

	return (
		<>
			<Menu.Item sx={{ color: FarmableColors.RED }} onClick={toggleOpen}>
				{t('buttons.deleteLibrary')}
			</Menu.Item>
			<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
				<Modal.DialogTitle
					title={t('treatmentLibrary.deleteLibraryModal.title')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					<Text>{t('treatmentLibrary.deleteLibraryModal.message')}</Text>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant="text" onClick={toggleOpen}>
							{t('buttons.back')}
						</Button>
						<Button
							variant="outlined"
							onClick={onDeleteLibrary}
							disabled={mutationResult.loading}
							startIcon={mutationResult.loading ? <LoadingIcon /> : null}
						>
							{t('buttons.delete')}
						</Button>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}

export default DeleteLibraryModal;
