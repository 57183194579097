import React, { ReactElement } from 'react';

import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

type TNavbarProps = {
	leftPlaceholder?: ReactElement | null;
	centerPlaceholder?: ReactElement | null;
	rightPlaceholder?: ReactElement | null;
};

const Navbar = (props: TNavbarProps) => {
	const { leftPlaceholder, centerPlaceholder, rightPlaceholder } = props;

	return (
		<Grid
			container
			item
			borderBottom={`1px solid ${FarmableColors.BLACK_12}`}
			minHeight="3.5rem"
			alignItems="center"
			data-testid="navbar"
		>
			<Grid item xs alignSelf="flex-start">
				<Box display="flex" justifyContent="flex-start" height="100%">
					{leftPlaceholder}
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box display="flex" justifyContent="center" height="100%">
					{centerPlaceholder}
				</Box>
			</Grid>
			<Grid item xs alignSelf="flex-start">
				<Box display="flex" justifyContent="flex-end" height="100%">
					{rightPlaceholder}
				</Box>
			</Grid>
		</Grid>
	);
};

export default Navbar;
