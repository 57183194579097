import React from 'react';

import { useQuery } from '@apollo/client';
import Chip from 'UI/display/Chip';
import CheckIcon from 'UI/icons/Check';
import { FarmableColors } from 'UI/theme/Colors';

import GET_PARTNERSHIP_LOCATIONS_DATA from '../../../../features/api/getPartnerLocations';

type TLocationFilterTag = {
	label: string;
	locations: string[];
};

const LocationFilterTag = (props: TLocationFilterTag) => {
	const { locations } = props;
	const { data } = useQuery(GET_PARTNERSHIP_LOCATIONS_DATA, {
		variables: {
			filterBy: {
				statuses: ['ACTIVE'],
			},
		},
	});

	const locationName = (locationId: string) =>
		data?.getPartnerLocations?.find((location) => location?.id === locationId)
			?.name || locationId;

	const label = `${props.label}: ${
		locations.length == 1 ? locationName(locations[0]) : locations.length
	}`;

	return (
		<Chip
			chipColor={FarmableColors.ORANGE}
			label={label}
			startIcon={<CheckIcon sx={{ fill: FarmableColors.ORANGE }} />}
		/>
	);
};

export default LocationFilterTag;
