import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TJobFilterInput } from 'Models/jobs/types/JobFilterInput';

import { TTableQueryResponse } from '../types';

export type TGetJobsTableDataResponse = {
	getJobs: TTableQueryResponse;
};

type TGetJobsDataInput = {
	locationID: string;
	filterBy?: Partial<TJobFilterInput>;
	pageInput?: TPageInput;
	hasPagination?: boolean;
};

const GET_JOBS_TABLE_DATA: TypedDocumentNode<
	TGetJobsTableDataResponse,
	TGetJobsDataInput
> = gql`
	query getJobs(
		$locationID: ID!
		$filterBy: JobFilterInput
		$pageInput: PageInput!
	) {
		getJobs(
			locationId: $locationID
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			jobs {
				id
				jobType
				jobStatus
				startDate
				completedDate
				jobCauses {
					id
					name
					causeType
				}
				customJobType {
					id
					name
				}
				treatments {
					id
					amount
					unit
					originalProduct {
						name
						productSpec {
							reEntryInterval
						}
					}
					originalTreatment {
						withholdingPeriod
					}
				}
				fields {
					id
					name
					areaSize
					varieties {
						id
						name
						percentage
						sowDate
					}
				}
				jobFields {
					id
					name
					areaSize
					fieldVarieties {
						id
						name
						category
						sowDate
					}
				}
				author {
					firstName
					lastName
				}
				driversWithEquipmentConfig {
					driver {
						firstName
						lastName
					}
					equipment {
						id
						name
						equipmentType
						rate {
							value
							unit
						}
						tankSize {
							value
							unit
						}
					}
				}
				runStatusHistory {
					runId
					timestamp
					status
				}
				weather {
					temp
					windSpeed
					windDeg
					rain
				}
				productsImages {
					id
					path
					filename
					mimetype
					createdAt
				}
				safetyChecklist {
					name
					checked
				}
				notes {
					id
					account {
						id
						firstName
						lastName
						profileImage {
							path
						}
					}
					note
					createdAt
				}
			}
		}
	}
`;

export default GET_JOBS_TABLE_DATA;
