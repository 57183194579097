import replaceYearToCurrent from 'Utils/replaceYearToCurrent';

type TRevenueChartData = {
	x: string;
	y: number;
};

export type TRevenueChartPoint = {
	x: number;
	y: number;
};

export type TParsedRevenueChartData = {
	year: number;
	point: TRevenueChartPoint;
};

export const parseRevenueChartData = (
	revenueChartData: TRevenueChartData
): TParsedRevenueChartData => {
	return {
		point: {
			x: new Date(replaceYearToCurrent(revenueChartData.x)).getTime(),
			y: revenueChartData.y,
		},
		year: new Date(revenueChartData.x).getFullYear(),
	};
};
