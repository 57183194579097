import _flattenDeep from 'lodash/flattenDeep';
import _uniq from 'lodash/uniq';

import { TVariety } from './isVariety';

function getLocationVarieties(
	fields: {
		varieties?: Pick<TVariety, 'name'>[] | null;
	}[]
): string[] {
	return _uniq(
		_flattenDeep(
			fields.map((field) =>
				field.varieties?.length
					? field.varieties.map((variety) => variety.name)
					: []
			)
		)
	);
}

export default getLocationVarieties;
