import React, { useState } from 'react';

import { ApolloError, useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getAccountName from 'Models/account/getAccountName';
import Permissions from 'Models/permissions/Permissions';
import { TRoles } from 'Models/permissions/Roles';
import { TMember } from 'Models/profile/isMember';
import Text from 'UI/display/Text';
import Select, { MenuItem } from 'UI/inputs/Select';
import Box from 'UI/layout/Box';

import useLocationID from 'Hooks/useLocationID';

import LoadingContent from '../../../../../../components/LoadingContent';
import GET_LOCATION_MEMBERS from '../../../api/getLocationMembers';
import { teamInputVar } from '../../../state';

function getTeamLeadCandidates(members: TMember[] | null) {
	return (members || []).filter((member) => {
		const permissions: TRoles[] = Permissions.BECOME_TEAM_LEAD;

		return permissions.includes(member.accountRole);
	});
}

const TeamLeadSelect = () => {
	const teamInput = useReactiveVar(teamInputVar);
	const [labelVisible, setLabelVisibility] = useState(!!teamInput?.teamLeadId);
	const { t } = useTranslation();
	const locationId = useLocationID();
	const { data, error, loading } = useQuery(GET_LOCATION_MEMBERS, {
		variables: { locationId: locationId },
	});

	const members = data?.members || [];

	if (error) {
		throw new ApolloError(error);
	}

	const teamLeadCandidates = getTeamLeadCandidates(members)
		.sort((a, b) =>
			(getAccountName(a.account) || a.account.id).localeCompare(
				getAccountName(b.account) || b.account.id
			)
		)
		.map((member, i) => (
			<MenuItem value={member.account.id} key={`select-menu-item-${i}`}>
				<Text variant="body2">
					{getAccountName(member.account) || member.account.id}
				</Text>
			</MenuItem>
		));

	const noCandidates = teamLeadCandidates.length === 0;
	const disabled = !members || noCandidates;

	function handleOnChange(value: string) {
		const newTeamInput = {
			...teamInput,
			teamLeadId: value,
		};
		teamInputVar(newTeamInput);
		setLabelVisibility(!!value);
	}

	function getHelperText(): string {
		if (!members) {
			return t('labels.loadingMembers');
		} else if (members && noCandidates) {
			return t('prompts.noTeamLeadCandidates');
		} else return '';
	}

	return (
		<Box mb={2}>
			<Text variant="label">{t('labels.teamLead')}</Text>
			<Select
				variant="outlined"
				label={disabled ? '' : t('labels.selectPerson')}
				hideLabel={labelVisible}
				value={teamInput?.teamLeadId || ''}
				changeHandler={handleOnChange}
				helperText={getHelperText()}
				disabled={loading || disabled}
			>
				{loading ? <LoadingContent /> : teamLeadCandidates}
			</Select>
		</Box>
	);
};

export default TeamLeadSelect;
