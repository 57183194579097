import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';

import FarmsMultiselectList from '../../../../../../components/FarmsMultiselectList';
import { selectedLocationIds } from '../state';

const LocationsStep = (): ReactElement => {
	const { t } = useTranslation();

	const handleFarmsChange = (selectedValues: string[]) => {
		selectedLocationIds(selectedValues);
	};

	return (
		<>
			<Text sx={{ marginBottom: '1rem' }}>
				{t('plannerPage.addJobModal.locationsText')}
			</Text>
			<FarmsMultiselectList onChangeCallback={handleFarmsChange} />
		</>
	);
};

export default LocationsStep;
