import useParams from 'Features/router/hooks/useParams';
import ParamTypes from 'Models/history/paramTypes';

function useCompanyID(): ParamTypes['companyID'] {
	const { companyID } = useParams();

	return companyID || '';
}

export default useCompanyID;
