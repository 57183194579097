import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TProductInventory } from 'Models/inventory/ProductsInventory';

export type TGetProductInventoryResponse = {
	getProductInventory: {
		totalPages: number;
		totalElements: number;
		inventory: TProductInventory[];
	};
};

const GET_CURRENT_INV_TABLE_DATA: TypedDocumentNode<
	TGetProductInventoryResponse,
	{
		locationId: string;
		pageInput: TPageInput;
		hasPagination: boolean;
		filterBy: {
			productName?: string;
		};
	}
> = gql`
	query getCurrentInventoryTableData(
		$locationId: ID!
		$filterBy: ProductInventoryFilterInput
		$pageInput: PageInput!
	) {
		getProductInventory(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			inventory {
				product {
					id
					name
					createdAt
					discriminator
				}
				stockAmount
				stockUnit
				totalValue
				currency
				lastPurchase
				lastUsage
			}
		}
	}
`;

export default GET_CURRENT_INV_TABLE_DATA;
