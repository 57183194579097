import React, { ReactElement } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SearchIcon(props: SvgIconProps): ReactElement {
	return (
		<SvgIcon {...props}>
			<path
				d="M11 4a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm4.293 9.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414z"
				fillRule="nonzero"
			/>
		</SvgIcon>
	);
}

export default SearchIcon;
