import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { isAmountInput, TJobInputTreatment } from 'Models/jobs/JobInput';
import NumericField from 'UI/inputs/NumericField';

import numberToDisplayString from 'Utils/numberToDisplayString';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../../../../state';

type TTreatmentAmount = {
	treatment: Pick<TJobInputTreatment, 'amount' | 'originalTreatmentId'>;
	disabled: boolean;
};

const TreatmentAmount = (props: TTreatmentAmount) => {
	const { t } = useTranslation();
	const { amount, originalTreatmentId } = props.treatment;
	const jobInput = useJobInputData();
	const status = useAddJobModalStatus();

	const handleValueParsed = (parsedAmount: number) => {
		const treatments = jobInput.jobDetails.treatments.map((treatment) => {
			if (treatment.originalTreatmentId === originalTreatmentId) {
				return {
					...treatment,
					amount: parsedAmount,
				};
			}

			return treatment;
		});

		addJobModalState({
			status,
			jobInputData: {
				...jobInput,
				jobDetails: {
					...jobInput.jobDetails,
					treatments,
				},
			},
		});
	};

	return (
		<NumericField
			value={numberToDisplayString(amount.toString())}
			label={t('labels.amount')}
			onValueParsed={handleValueParsed}
			inputValidator={isAmountInput}
			disabled={props.disabled}
			errorHelperText={t('helpers.positiveValuesOnly')}
		/>
	);
};

export default TreatmentAmount;
