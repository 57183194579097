import React, { ReactElement, ReactNode, useState } from 'react';

import MUIAvatar, { AvatarProps } from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import AccountIcon from '../../icons/Account';
import { FarmableColors } from '../../theme/Colors';
import { AvatarSizes } from '../../theme/Sizes';

type TAvatarProps = Omit<AvatarProps, 'src'> & {
	children?: ReactNode;
	className?: string;
	size?: keyof typeof AvatarSizes;
	src?: string | null;
	color?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Avatar = (props: TAvatarProps): ReactElement => {
	const { children, className, size, onClick, src, color, ...MUIAvatarProps } =
		props;
	const avatarSize = AvatarSizes[size || 'md'];
	const iconSize = avatarSize * 0.6;

	const useStyles = makeStyles({
		root: {
			width: avatarSize,
			height: avatarSize,
			backgroundColor: src
				? FarmableColors.BLACK_38
				: color || FarmableColors.ORANGE,
			fontSize: avatarSize * 0.4,
			fontWeight: 'bold',
			transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			'&:hover': onClick
				? { boxShadow: `0 0 0.3rem ${FarmableColors.BLACK_38}` }
				: null,
		},
		rounded: {
			borderRadius: '25%',
		},
		noDisplay: {
			display: 'none',
		},
	});

	const [avatarImgLoaded, setAvatarImageLoaded] = useState(false);

	const onLoad = () => {
		setAvatarImageLoaded(true);
	};

	const { root, rounded, noDisplay } = useStyles();
	const mainAvatarClassName = cx(
		className,
		!avatarImgLoaded && src ? noDisplay : null
	);
	const auxiliaryAvatarClassName = cx(
		className,
		avatarImgLoaded ? noDisplay : null
	);

	// The returned component consists of two separate MUIAvatar components.
	// The second component is displayed while the image of the fist component is being loaded.
	// The first component is hidden until the loading finishes.
	// This allows to display children in avatar during the loading process (otherwise avatar is just an empty circle).
	return (
		<>
			<MUIAvatar
				className={mainAvatarClassName}
				classes={{ root, rounded }}
				onClick={onClick}
				imgProps={{
					onLoad,
				}}
				{...MUIAvatarProps}
			>
				{children || props.alt?.charAt(0).toUpperCase() || (
					<AccountIcon
						fill={FarmableColors.WHITE}
						sx={{ width: iconSize, height: iconSize }}
					/>
				)}
			</MUIAvatar>
			{src ? (
				<MUIAvatar
					className={auxiliaryAvatarClassName}
					classes={{ root, rounded }}
					onClick={onClick}
					src={src}
					{...MUIAvatarProps}
				>
					{children}
				</MUIAvatar>
			) : null}
		</>
	);
};

export default Avatar;
