import { TTranslateFunction } from '../../features/localization';
import FromUpperCase from '../units/FromUpperCase';
import { TJob } from './isJob';

export default function jobCauseCategory(
	job: Pick<TJob, 'jobCauses'>,
	translateFunction?: TTranslateFunction
): string {
	return (
		job.jobCauses
			?.map((cause) => {
				if (translateFunction) {
					return translateFunction(`common.jobs.treatments.${cause.causeType}`);
				}
				return FromUpperCase(cause.causeType);
			})
			.join(', ') || '-'
	);
}
