import { parseISO } from 'date-fns';

/**
 * Api returns dates in format: yyyy-mm-ddThh:mm:ss.sss
 * The dates are returned in UTC
 * This function takes this fact into account and converts date to local Date object
 */
function ISOToLocal(date: string): Date {
	return date.endsWith('Z') ? parseISO(date) : parseISO(`${date}Z`);
}

export default ISOToLocal;
