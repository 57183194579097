import _intersection from 'lodash/intersection';

import { TFieldVariety } from './isFieldVariety';

type _TField<TGenericField> = TGenericField & {
	varieties: Pick<TFieldVariety, 'category'>[] | null;
};

export function filterFieldsByCategory<TGenericField>(
	fields: _TField<TGenericField>[],
	categories?: string[]
): TGenericField[] {
	if (!categories || categories.length == 0) {
		return fields;
	}

	const filteredFields = fields.filter((field) => {
		const fieldCategories =
			field.varieties?.map((variety) => variety.category) || [];
		return _intersection(fieldCategories, categories).length;
	});

	return filteredFields;
}
