import React, { ReactElement, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Trans } from 'Features/localization/components/Trans';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getAccountName from 'Models/account/getAccountName';
import { TPartnershipCompanyUser } from 'Models/partnershipCompany/isPartnershipCompanyUser';
import { PartnerRoles } from 'Models/permissions/Roles';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import EDIT_PARTNERSHIP_USER_ROLE from '../../../api/EditPartnershipUserRole';

type TProps = {
	open: boolean;
	member: TPartnershipCompanyUser;
	toggleOpen: () => void;
};

const ChangeToAdminAccessModal = (props: TProps): ReactElement => {
	const { toggleOpen, open, member } = props;
	const { id, firstName, lastName, email } = member;
	const { t } = useTranslation();
	const name = getAccountName({ firstName, lastName }) || email;

	const [editPartnershipUserRole, mutationResult] = useMutation(
		EDIT_PARTNERSHIP_USER_ROLE,
		{
			refetchQueries: ['getCompanyInvitedUsersOnManageMembersPage'],
		}
	);

	useSnackbarNotifications({
		mutationResult,
		messageSuccess: t('managePartnerMembers.editPartnershipUserRole.success'),
		messageFailure: t('managePartnerMembers.editPartnershipUserRole.failure'),
	});

	const { data, loading } = mutationResult;

	const onSubmit = () => {
		void editPartnershipUserRole({
			variables: {
				partnershipUserId: id,
				partnershipRole: PartnerRoles.PA_ADMIN,
			},
		});
	};

	useEffect(() => {
		if (open && data) {
			toggleOpen();
			mutationResult.reset();
		}
	}, [data, open, toggleOpen]);

	return (
		<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
			<Modal.DialogTitle
				title={t('managePartnerMembers.changeToAdmin')}
				closeIconHandler={toggleOpen}
			/>
			<Modal.DialogContent>
				<Trans i18nKey={'managePartnerMembers.changeToAdminInfo'}>
					Are you sure you want to change <strong>{{ name }}</strong> to admin?
					Only the owner can revert this change later.
				</Trans>
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Box
					display="flex"
					justifyContent="space-between"
					flexDirection="row"
					width="100%"
				>
					<Button variant="text" onClick={toggleOpen}>
						{t('buttons.cancel')}
					</Button>
					<Button onClick={onSubmit} variant="outlined" disabled={loading}>
						{t('buttons.confirm')}
					</Button>
				</Box>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default ChangeToAdminAccessModal;
