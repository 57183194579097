import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import List from 'UI/display/List';
import PestControl from 'UI/icons/PestControl';
import ProductsIcon from 'UI/icons/Products';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import getFeatureFlag from 'Utils/getFeatureFlag';

import { ICONS_FILL, getSettingsRoutes } from '../';

function LibrarySection(props: { companyID?: string }) {
	const { companyID } = props;
	const { t } = useTranslation();
	const history = useHistory();
	const { company } = usePartnerCompany();

	if (!companyID) {
		return null;
	}
	const hasEnabledPartnerTreatments = getFeatureFlag('PARTNER_TREATMENTS');
	const routes = getSettingsRoutes({ companyID });
	const hasAccessToCauses =
		company?.country !== 'ES' || process.env.NODE_ENV !== 'production';

	return (
		<List.List
			isMenuVariant
			subheader={<List.Subheader>{t('common.sidebar.library')}</List.Subheader>}
		>
			{hasEnabledPartnerTreatments ? (
				<List.Item disablePadding>
					<List.ItemButton
						isActive={history.location.pathname === routes.partnerTreatments}
						onClick={() => history.push(routes.partnerTreatments)}
					>
						<List.ItemIcon>
							<ProductsIcon fill={ICONS_FILL} />
						</List.ItemIcon>
						<List.ItemText primary={t('common.sidebar.treatments')} />
					</List.ItemButton>
				</List.Item>
			) : null}
			{hasAccessToCauses ? (
				<List.Item disablePadding>
					<List.ItemButton
						isActive={history.location.pathname === routes.partnerCauses}
						onClick={() => history.push(routes.partnerCauses)}
					>
						<List.ItemIcon>
							<PestControl fill={ICONS_FILL} />
						</List.ItemIcon>
						<List.ItemText primary={t('common.sidebar.causes')} />
					</List.ItemButton>
				</List.Item>
			) : null}
		</List.List>
	);
}

export default LibrarySection;
