import React, { ReactElement } from 'react';

import { TTranslateFunction } from 'Features/localization';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TLogType } from 'Models/logs/LogType';
import NoDataIcon from 'UI/icons/NoData';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import useManageHistory from 'Hooks/useManageHistory';
import hasAppliedFilters from 'Utils/hasAppliedFilters';

import StatementContent from './components/StatementContent';

type NoJobsStatementProps = {
	logsType?: TLogType;
};

const getStatementContent = (
	t: TTranslateFunction,
	logsType?: TLogType,
	hasAppliedFilters?: boolean
) => {
	switch (logsType) {
		case 'Jobs':
			return (
				<StatementContent
					helperText={t('labels.createNewJobs')}
					statementText={
						hasAppliedFilters
							? t('labels.noJobsFound')
							: t('labels.noRecordedJobs')
					}
				/>
			);
		case 'Hours':
			return (
				<StatementContent
					helperText={t('labels.createNewHoursLogs')}
					statementText={
						hasAppliedFilters
							? t('labels.noHoursLogsFound')
							: t('labels.noRecordedHoursLogs')
					}
				/>
			);
		case 'Harvest':
			return (
				<StatementContent
					helperText={t('labels.createNewHarvestLogs')}
					statementText={
						hasAppliedFilters
							? t('labels.noHarvestLogsFound')
							: t('labels.noRecordedHarvestLogs')
					}
				/>
			);
		case 'InventoryMovement':
			return (
				<StatementContent
					helperText={t('labels.createInventoryMovementLogs')}
					statementText={
						hasAppliedFilters
							? t('labels.noInventoryMovementLogsFound')
							: t('labels.noRecordedInventoryMovementLogs')
					}
				/>
			);
		case 'CurrentInventory':
			return (
				<StatementContent
					helperText={t('labels.createCurrentInventoryLogs')}
					statementText={
						hasAppliedFilters
							? t('labels.noCurrentInventoryLogsFound')
							: t('labels.noRecordedCurrentInventoryLogs')
					}
				/>
			);
		case 'Sales':
			return (
				<StatementContent
					helperText={t('labels.createSalesLogs')}
					statementText={
						hasAppliedFilters
							? t('labels.noSalesLogsFound')
							: t('labels.noRecordedSalesLogs')
					}
				/>
			);
		default:
			return (
				<StatementContent
					helperText={t('labels.createLogs')}
					statementText={t('labels.noLogsFound')}
				/>
			);
	}
};

const NoLogsStatement = (props: NoJobsStatementProps): ReactElement => {
	const { logsType } = props;
	const { t } = useTranslation();
	const { paramsFromURL: searchParams } = useManageHistory();

	return (
		<Grid alignItems="center" container direction="column">
			<Box marginBottom="1rem" marginTop="3rem">
				<NoDataIcon sx={{ width: 128, height: 128 }} />
			</Box>
			{getStatementContent(
				t,
				logsType,
				hasAppliedFilters(searchParams.filters)
			)}
		</Grid>
	);
};

export default NoLogsStatement;
