import React from 'react';

import { withProfiler } from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ApolloProvider from './features/apollo/components/ApolloProvider';
import Auth0ProviderWithHistory from './features/auth0/components/Auth0ProviderWithHistory';
import RequireAuth from './features/auth0/components/RequireAuth';

const AppContainer = () => {
	return (
		<BrowserRouter>
			<Auth0ProviderWithHistory>
				<ApolloProvider>
					<RequireAuth>
						<App />
					</RequireAuth>
				</ApolloProvider>
			</Auth0ProviderWithHistory>
		</BrowserRouter>
	);
};

export default withProfiler(AppContainer);
