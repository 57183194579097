import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import getAllAvailableSeasons from 'Models/seasons/getAllAvailableSeasons';
import { TSeasonParams } from 'Models/seasons/isSeasonParams';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useLocationID from 'Hooks/useLocationID';

import GET_THE_OLDEST_JOB_QUERY from './api/getTheOldestJob';

type TSeasonFilterProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const SeasonFilter = (props: TSeasonFilterProps) => {
	const { updateFilters, filters } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const location = useCurrentLocation();
	const locationId = useLocationID();

	const { data, loading, error } = useQuery(GET_THE_OLDEST_JOB_QUERY, {
		variables: {
			locationId: locationId,
			pageInput: {
				limit: 1,
				offset: 0,
				sortBy: [{ direction: 'ASC', field: 'createdAt' }],
			},
		},
	});
	const { t } = useTranslation();

	const open = Boolean(anchorEl);
	const appliedSeason = filters.season;

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	if (error) {
		throw new ApolloError(error);
	}

	const theOldestJob = data?.getJobs.jobs[0];
	const seasons = theOldestJob
		? getAllAvailableSeasons(
				location?.seasonCutoffDate || '01-01',
				new Date(theOldestJob.createdAt)
		  )
		: [new Date().getFullYear()];

	const selectSeasonHandler = (selectedSeason: TSeasonParams) => {
		const newFilters = {
			...filters,
			season: selectedSeason.seasonName,
			completedDateFrom: selectedSeason.from,
			completedDateTo: selectedSeason.to,
		};
		updateFilters(newFilters);
		setAnchorEl(null);
	};

	const clearSeasonHandler = () => {
		const newFilters = {
			...filters,
			season: undefined,
			completedDateFrom: undefined,
			completedDateTo: undefined,
		};
		updateFilters(newFilters);
		setAnchorEl(null);
	};

	const menuContent = loading ? (
		<LoadingSpinner style={{ margin: '0 0 -0.5rem 0.6rem' }} />
	) : (
		<div>
			<Menu.Item onClick={clearSeasonHandler} key="default">
				{t('logsPages.filtersModal.allSeasons')}
			</Menu.Item>
			{seasons.map((season) => (
				<Menu.Item
					onClick={() => selectSeasonHandler(season)}
					key={season.seasonName}
				>
					{season.seasonName}
				</Menu.Item>
			))}
		</div>
	);

	return (
		<Box>
			<Box component="span">
				<Button
					endIcon={<ArrowDropDownIcon />}
					variant="secondary"
					onClick={handleClick}
				>
					{appliedSeason || t('logsPages.filtersModal.allSeasons')}
				</Button>
			</Box>
			<Menu.Menu
				id="job-logs-season-filter-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				{menuContent}
			</Menu.Menu>
		</Box>
	);
};

export default SeasonFilter;
