import { formatDuration, intervalToDuration, Locale } from 'date-fns';

function getDurationTimeString(
	startDate: Date | number,
	endDate: Date | number,
	locale?: Locale
): string {
	const OPTIONS = {
		locale: locale,
	};

	const interval = {
		start: startDate,
		end: endDate,
	};

	const duration = intervalToDuration(interval);
	return formatDuration(duration, OPTIONS);
}

export default getDurationTimeString;
