import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Page from 'UI/layout/Page';

import Logo from '../../components/icons/misc/LogoBig';
import useLocations from '../../hooks/useLocations';
import SplashPage from '../SplashPage';
import NoFarmsAccessPage from '../no-access/NoFarmsAccessPage';
import FarmItem from './components/FarmItem';

const SelectFarmPage = () => {
	const { locations } = useLocations();
	const { t } = useTranslation();
	const history = useHistory();

	if (!locations) {
		return <SplashPage />;
	}

	const filteredLocations = locations.filter(
		(location) =>
			location.relation.includes('ADMIN') ||
			location.relation.includes('FARMER')
	);

	if (!filteredLocations.length) {
		const logo = (
			<Box paddingBottom="4rem">
				<Logo sx={{ width: 96, height: 58 }} viewBox="0 0 160 80" />
			</Box>
		);

		return (
			<Page center>
				<Box
					display="flex"
					flexDirection="column"
					marginX="0.5rem"
					width="100%"
					maxWidth="21.37rem"
				>
					<NoFarmsAccessPage headerIcon={logo} />
				</Box>
			</Page>
		);
	}

	if (filteredLocations.length === 1) {
		history.push(`/mobile/${filteredLocations[0].id}/store`);
	}

	return (
		<Page center>
			<Text variant="h2" marginBottom="1.5rem">
				{t('selectFarmPage.selectFarm')}
			</Text>
			<Box
				display="flex"
				flexDirection="column"
				marginX="0.5rem"
				width="100%"
				maxWidth="21.375rem"
			>
				{filteredLocations.map((location) => (
					<FarmItem {...location} key={location.id} />
				))}
			</Box>
		</Page>
	);
};

export default SelectFarmPage;
