import { TSeasonParams } from 'Models/seasons/isSeasonParams';
import _range from 'lodash/range';

function getYearsForTooltip(seasonParams: TSeasonParams): string[] {
	const fromDate = new Date(seasonParams.from);
	const fromYear = fromDate.getFullYear();
	const countFromMonth =
		parseInt(seasonParams.seasonCutoffDate.split('-')[0]) - 1;

	return _range(0, 12).map((monthIndex) => {
		const year = new Date(fromYear, countFromMonth + monthIndex)
			.getFullYear()
			.toString();

		return year;
	});
}

export default getYearsForTooltip;
