import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import NoAccessPageTemplate from '../NoAccessPageTemplate';

type TNoSubscriptionPage = {
	canPurchase: boolean;
};

const NoSubscriptionPage = (props: TNoSubscriptionPage): ReactElement => {
	const { t } = useTranslation();

	return (
		<NoAccessPageTemplate
			title={t('noAccess.noAccessPage.title')}
			description={
				props.canPurchase
					? t('noAccess.noSubscriptionPage.hasPermissionToBuy')
					: t('noAccess.noSubscriptionPage.noPermissionToBuy')
			}
		/>
	);
};

export default NoSubscriptionPage;
