import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJob } from 'Models/jobs/isJob';
import jobCausesString from 'Models/jobs/jobCausesString';

import DetailModalSection from '../../../../../components/ModalSection';

type TCausesProps = {
	job: Pick<TJob, 'jobCauses'>;
};

const Causes = (props: TCausesProps) => {
	const { job } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.jobLogsPage.detailsModal.sections.';

	return (
		<DetailModalSection title={t(`${prefix}cause`)}>
			{jobCausesString(job)}
		</DetailModalSection>
	);
};

export default Causes;
