import React, { ReactElement, ReactNode } from 'react';

import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';

export type TItem = Omit<MenuItemProps, 'button'> & {
	children?: ReactNode;
	button?: true | undefined;
};

const Item = React.forwardRef<HTMLLIElement, TItem>(
	(props: TItem, ref): ReactElement => {
		const { children, ...MUIProps } = props;

		return (
			<MenuItem {...MUIProps} ref={ref}>
				{children}
			</MenuItem>
		);
	}
);

export default Item;
