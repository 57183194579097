const GREEN_HOUSE = 'GREEN_HOUSE';
const FIELD = 'FIELD';
const ROWS = 'ROWS';
const ECO_ZONE = 'ECO_ZONE';

export const FieldTypes = {
	GREEN_HOUSE,
	FIELD,
	ROWS,
	ECO_ZONE,
} as const;

const ALL = Object.values(FieldTypes).filter(
	(value): value is TFieldType => typeof value === 'string'
);

export type TFieldType = keyof typeof FieldTypes;

export const isFieldType = (value: string): value is TFieldType =>
	Object.values(FieldTypes).filter((fieldType) => fieldType === value).length
		? true
		: false;

export { ALL, GREEN_HOUSE, FIELD, ROWS, ECO_ZONE };
