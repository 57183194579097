import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { isJobTypeArray, JobTypes, TJobType } from 'Models/jobs/JobTypes';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import Logo from '../../../../components/icons/misc/LogoDark';
import useManageHistory from '../../../../hooks/useManageHistory';

type TSprayLogSubheaderProps = {
	name?: string | null;
};

const SprayLogSubheader = (props: TSprayLogSubheaderProps) => {
	const { t } = useTranslation();
	const { name } = props;
	const manageHistory = useManageHistory();
	const searchParams = manageHistory.paramsFromURL;
	const filteredJobTypes = isJobTypeArray(searchParams.filters.jobTypes)
		? searchParams.filters.jobTypes
		: undefined;

	const getReportName = (filteredJobTypes: TJobType[] | undefined) => {
		if (filteredJobTypes?.length === 1) {
			const reportTitleType = `treatmentLogsExportPage.reportTitle.${
				JobTypes[filteredJobTypes[0]]
			}` as const;
			return t(reportTitleType);
		} else {
			return t(`treatmentLogsExportPage.reportTitle.default`);
		}
	};
	return (
		<Box
			display="flex"
			width="100%"
			justifyContent="space-between"
			padding="1.5rem"
		>
			<Text variant="h2">
				{getReportName(filteredJobTypes)}
				{name
					? t(`treatmentLogsExportPage.sprayLog.reportTitleTemplate`) +
					  ` ${name}`
					: null}
			</Text>
			<Logo />
		</Box>
	);
};

export default SprayLogSubheader;
