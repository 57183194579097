import React, { ReactNode } from 'react';

import Box from 'UI/layout/Box';

type TBoardProps = {
	children: ReactNode;
};

const Board = (props: TBoardProps) => {
	return (
		<Box
			display="grid"
			gridTemplateColumns="repeat(12, 1fr)"
			gap="2rem"
			paddingTop={4}
		>
			{props.children}
		</Box>
	);
};

export default Board;
