import React, { ReactElement } from 'react';

import MUIButton, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

type TButtonCustomVariants = ButtonProps['variant'] | 'secondary';

type TButtonCustomProps = {
	variant?: TButtonCustomVariants;
	customTextColor?: string;
};

export type TButtonProps = Omit<ButtonProps, 'variant'> & TButtonCustomProps;

function _isSecondaryVariant(
	variant: TButtonCustomVariants | undefined
): variant is 'secondary' {
	return variant === 'secondary';
}

const Button = (props: TButtonProps): ReactElement => {
	const { children, variant, color, customTextColor, sx, ...MUIProps } = props;
	const isSecondaryVariant = _isSecondaryVariant(variant);
	const MUIButtonVariant = isSecondaryVariant
		? 'outlined'
		: variant || 'contained';
	const MUIButtonColor = isSecondaryVariant ? 'secondary' : color;

	return (
		<MUIButton
			variant={MUIButtonVariant}
			color={MUIButtonColor}
			sx={{ ...sx, color: customTextColor || undefined }}
			{...MUIProps}
		>
			{children}
		</MUIButton>
	);
};

export default Button;
