import React, { ReactElement, ReactNode } from 'react';

import { TPaymentProduct } from 'Models/stripe/isPaymentProduct';
import { TPaymentProductPrice } from 'Models/stripe/isPaymentProduct';
import stripePriceUnitAmountString from 'Models/stripe/stripePriceUnitAmountString';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import StripePlaceholderIcon from 'UI/icons/StipePlaceholder';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import Breakpoints from 'UI/theme/breakpoints';
import { makeStyles } from 'UI/utils/makeStyles';
import useBreakpoint from 'UI/utils/useBreakpoint';

import useStripe from '../../features/stripe/hooks/useStripe';
import getStripeModules from '../../features/stripe/utils/getStripeModules';
import ProductPrice from './ProductPrice';
import RedirectToStripeButton from './RedirectToStripeButton';
import style from './styles.module.scss';

type TStripeProductCardProps = {
	stripeProduct: TPaymentProduct;
	stripePrices?: TPaymentProductPrice[] | null;
	onStripeProcessingStarted?: () => void;
	onError?: (error) => void;
	children?: ReactNode;
	isPurchased?: boolean;
};

const useStyles = makeStyles({
	root: {
		width: '100%',
		padding: '1.5rem',
		margin: '1.5rem 0',
		'&:hover': {
			boxShadow: (isPurchased) =>
				isPurchased
					? ''
					: '0 -6px 16px -6px rgba(0, 0, 0, 0.02), 0 8px 16px -8px rgba(0, 0, 0, 0.16), 0 12px 24px -6px rgba(0, 0, 0, 0.08)',
		},
	},
});

const StripeProductCard = (
	props: TStripeProductCardProps
): ReactElement | null => {
	const {
		stripeProduct,
		stripePrices,
		onStripeProcessingStarted,
		children,
		isPurchased,
	} = props;
	const stripe = useStripe();
	const isMobile = useBreakpoint(Breakpoints.MOBILE);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [error, setError] = React.useState('');
	const cardStyles = useStyles(isPurchased);

	const yearlySubscriptionPrice = stripePrices?.find(
		(price) => price.recurring?.interval === 'year'
	);

	if (!stripePrices || stripePrices.length === 0) {
		return null;
	}

	// Make sure that Stripe.js has been loaded.
	if (stripe && stripeProduct && yearlySubscriptionPrice) {
		const stripeModules = getStripeModules();
		const moduleName = stripeModules[stripeProduct.id].features[0];
		const price = stripePriceUnitAmountString(yearlySubscriptionPrice);
		const currency = yearlySubscriptionPrice?.currency.toUpperCase();
		const interval = yearlySubscriptionPrice?.recurring?.interval;

		const renderProductImages = (image) => (
			<img
				key={image}
				src={image}
				alt={`Icon of ${stripeProduct?.name} subscription`}
			/>
		);

		if (!interval) {
			throw new Error(
				`Subscription interval for ${stripeProduct.name} not found.`
			);
		}

		const purchaseButton = isPurchased ? null : (
			<Grid item>
				<RedirectToStripeButton
					priceId={yearlySubscriptionPrice.id}
					moduleName={moduleName}
					stripe={stripe}
					productId={stripeProduct.id}
					onStripeProcessingStarted={onStripeProcessingStarted}
					onError={(error) => {
						if (props.onError) {
							props.onError(error);
						}
						setError(() => {
							throw new Error(error);
						});
					}}
				/>
			</Grid>
		);

		const productIcon = (
			<Box
				width={isMobile ? '5.5rem' : '7rem'}
				height={isMobile ? '5.5rem' : '7rem'}
			>
				{stripeProduct?.images?.length ? (
					stripeProduct.images.map(renderProductImages)
				) : (
					<StripePlaceholderIcon
						sx={{ width: isMobile ? 88 : 112, height: isMobile ? 88 : 112 }}
					/>
				)}
			</Box>
		);

		return (
			<Card
				variant="outlined"
				id={stripeProduct.id}
				className={cardStyles.root}
			>
				<Grid
					container
					flexDirection={isMobile ? 'column' : undefined}
					wrap={isMobile ? 'wrap' : 'nowrap'}
					spacing={isMobile ? 1 : 3}
				>
					<Grid item alignSelf={isMobile ? 'auto' : 'center'}>
						{productIcon}
					</Grid>
					<Grid item>
						<Text variant="h3">{stripeProduct.name}</Text>
						<ProductPrice
							price={price}
							currency={currency}
							interval={interval}
							isPurchased={isPurchased}
						/>
						<Text variant="body2">{stripeProduct.description}</Text>
						{children}
					</Grid>
					<Grid item xs>
						<Box
							height="100%"
							display="flex"
							justifyContent={isMobile ? 'flex-start' : 'flex-end'}
							alignItems="center"
						>
							{purchaseButton}
						</Box>
					</Grid>
				</Grid>
			</Card>
		);
	}

	return (
		<Card variant={'outlined'} className={style.productCard}>
			<Grid container justifyContent="center" alignItems="center">
				<LoadingSpinner width={64} height={64} />
			</Grid>
		</Card>
	);
};

export default StripeProductCard;
