import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import EnterpriseFarmIcon from 'UI/icons/EnterpriseFarm';
import Button from 'UI/inputs/Button';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import Breakpoints from 'UI/theme/breakpoints';
import DefaultTheme from 'UI/utils/DefaultTheme';
import { makeStyles } from 'UI/utils/makeStyles';
import useBreakpoint from 'UI/utils/useBreakpoint';

import getContactLink from '../utils/getContactLink';

type TUseStylesProps = {
	isMobile: boolean;
};

const useStyles = makeStyles<DefaultTheme, TUseStylesProps>({
	root: {
		width: '100%',
		padding: (props) => (props.isMobile ? '1rem' : '1.5rem'),
		margin: '1.5rem 0',
		'& .MuiTypography-body2': {
			lineHeight: 1.43,
			margin: '8px 0',
		},
		'& .MuiButton-root': {
			marginTop: 6,
		},
	},
});

const EnterpriseFarmsInfo = () => {
	const { t, i18n } = useTranslation();
	const isMobile = useBreakpoint(Breakpoints.MOBILE);

	const useStylesProps = { isMobile };
	const cardStyles = useStyles(useStylesProps);

	return (
		<Card variant="outlined" className={cardStyles.root}>
			<Grid
				container
				wrap={isMobile ? 'wrap' : 'nowrap'}
				spacing={isMobile ? 1 : 3}
			>
				<Grid item alignSelf="center">
					<EnterpriseFarmIcon
						sx={{ width: isMobile ? 88 : 112, height: isMobile ? 88 : 112 }}
					/>
				</Grid>
				<Grid item>
					<Text variant="h4">{t('storePage.enterpriseFarms.title')}</Text>
					<Text variant="body2">
						{t('storePage.enterpriseFarms.description')}
					</Text>
					<Button href={getContactLink(i18n.languages)}>
						{t('storePage.buttons.contactUs')}
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default EnterpriseFarmsInfo;
