const xxxs = 16;
const xxs = 20;
const xs = 24;
const sm = 32;
const md = 40;
const lg = 64;
const xl = 80;
const xxl = 96;

const AvatarSizes = {
	xxxs,
	xxs,
	xs,
	sm,
	md,
	lg,
	xl,
	xxl,
} as const;

const IconSizes = {
	xxxs,
	xxs,
	xs,
	sm,
	md,
	lg,
	xl,
	xxl,
} as const;

export type TIconSizes = keyof typeof IconSizes;
export type TAvatarSizes = keyof typeof AvatarSizes;

export { AvatarSizes, IconSizes };
