import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

const NoSharedLocationsText = (): ReactElement => {
	const { t } = useTranslation();

	return (
		<Box
			height="100%"
			position="relative"
			display="flex"
			justifyContent="center"
			alignItems="center"
			zIndex={9999}
			bgcolor="rgba(0, 0, 0, 0.5)"
		>
			<Text variant="h4" overrideMUIColor="WHITE">
				{t('partnerDashboardPage.noSharedLocations')}
			</Text>
		</Box>
	);
};

export default NoSharedLocationsText;
