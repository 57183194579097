// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3cxPuJUuqVScoEWHmwhjSD {\n  margin: .5rem;\n}\n\n._261uOgtmv15op1ZiOWVI-U {\n  position: absolute !important;\n  left: 1rem;\n}\n\n._10DMM8-O9-6CvZmi0KDoPv {\n  display: flex;\n  margin-left: .125rem;\n  align-items: center;\n}\n\n", ""]);
// Exports
exports.locals = {
	"modalContent": "_3cxPuJUuqVScoEWHmwhjSD",
	"backButton": "_261uOgtmv15op1ZiOWVI-U",
	"tooltipBase": "_10DMM8-O9-6CvZmi0KDoPv"
};
module.exports = exports;
