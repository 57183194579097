import { TTranslateFunction } from 'Features/localization';

import { JobStatuses, TJobStatuses } from './JobStatuses';

export default function translateJobStatus(
	jobStatus: TJobStatuses,
	t: TTranslateFunction,
	isPartner?: boolean
) {
	if (isPartner && jobStatus === JobStatuses.CANCELED) {
		return t('partnerJobStatuses.CANCELED');
	} else if (isPartner && jobStatus === JobStatuses.DRAFT) {
		return t('partnerJobStatuses.DRAFT');
	}
	return t(`common.jobs.statuses.${jobStatus}`);
}
