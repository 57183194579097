import _flatten from 'lodash/flatten';
import _isEqual from 'lodash/isEqual';
import proj4 from 'proj4';

import { TTranslateFunction } from '../../features/localization';
import {
	TGeoJSONField,
	coordinatesType,
	TGeometryPoint,
	TSupportedGeometry,
	isSupportedGeometry,
	TProperty,
} from '../geoJSONFiles/isGeoJSONField';

const LEAFLET_CRS_STANDARD = 'WGS84';

function parseCoordinates(
	geometry: TGeometryPoint[],
	crs: string
): TGeometryPoint[] {
	return geometry.map((coordinates) =>
		proj4(crs, LEAFLET_CRS_STANDARD, [coordinates[0], coordinates[1]])
	);
}

function getNameFromProperties(
	t: TTranslateFunction,
	properties?: TProperty
): string {
	return (
		properties?.name ||
		properties?.Name ||
		properties?.NAME ||
		t('importPages.fieldImportsPage.uploadFilesStep.newFieldTitle')
	);
}

const filterGeometry = (geometry: TSupportedGeometry) =>
	geometry.type === coordinatesType.MULTIPOLYGON
		? geometry.coordinates[0][0]
		: geometry.coordinates[0];

function getFieldsGeometryFromUploadedFiles(
	uploadedFiles: TGeoJSONField[],
	t: TTranslateFunction
) {
	const parsedFiles = uploadedFiles.map((file) =>
		file.features
			.filter((feature) => isSupportedGeometry(feature.geometry))
			.map((feature) => ({
				crs: file.crs,
				geometry: filterGeometry(feature.geometry as TSupportedGeometry),
				properties: feature.properties,
			}))
			// backend requires at least three points
			.filter((feature) => feature.geometry[0].length >= 2)
	);

	return _flatten(parsedFiles).map((file) => {
		if (file.crs) {
			const fileCRS = file.crs?.properties.name;
			const definedCRS = Object.keys(proj4.defs);
			const crs = definedCRS.find((crs) => fileCRS?.includes(crs));

			if (crs) {
				file.geometry = parseCoordinates(file.geometry, crs);
			} else {
				throw new Error(t('uploadGeoJSON.wrongCRSFileFormat'));
			}
		}

		const geometryPoints = file.geometry.map((coordinates) => ({
			x: coordinates[0],
			y: coordinates[1],
		}));

		//backend requires that the last and the first points should be the same
		if (
			!_isEqual(geometryPoints[0], geometryPoints[geometryPoints.length - 1])
		) {
			geometryPoints.push(geometryPoints[0]);
		}

		return {
			name: getNameFromProperties(t, file.properties),
			geometry: [geometryPoints],
		};
	});
}

export default getFieldsGeometryFromUploadedFiles;
