import { ApolloError, useQuery } from '@apollo/client';
import { AggregatedSalesGroupOption } from 'Models/sales/isAggregatedSalesGroupOption';

import useManageHistory from 'Hooks/useManageHistory';

import GET_REVENUE_CHART_DATA from '../../../api/getRevenueChartData';

const useRevenueChartData = (year: number) => {
	const { paramsFromURL } = useManageHistory();
	const { data, loading, error } = useQuery(GET_REVENUE_CHART_DATA, {
		variables: {
			locationId: paramsFromURL.locationID,
			query: {
				groupBy: AggregatedSalesGroupOption.DATE,
				filterBy: {
					dateFrom: `${year}-01-01`,
					dateTo: `${year}-12-31`,
					varieties: paramsFromURL.filters.fieldVarieties,
				},
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	return { data, loading };
};

export default useRevenueChartData;
