import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import TextField from 'UI/inputs/TextField';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../state';
import {
	AddTreatmentModalStatus,
	TSetProductDetailsInput,
} from '../../../../../types';

const AdditionalInfo = () => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { status, input } = state;

	if (status === AddTreatmentModalStatus.SET_PRODUCT_DETAILS) {
		const product = state.input.product;
		const hasSpec = 'productSpec' in product;
		const note =
			selectedProduct?.productSpec.note ||
			(hasSpec && product.productSpec?.note) ||
			'';

		const onChangeNote = (value: string) => {
			if (!input.selectedGovernmentProduct) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							productSpec: {
								...input.product.productSpec,
								note: value,
							},
						},
					},
				} as TSetProductDetailsInput);
			}
		};

		return (
			<ModalSection title={t('labels.additionalInfo')} noDivider>
				<TextField
					multiline
					minRows={2}
					maxRows={5}
					value={note}
					placeholder={t('labels.Comment')}
					onChange={onChangeNote}
					type="string"
					margin="dense"
					sx={{ '.MuiInputBase-root': { padding: 0 } }}
					disabled={Boolean(selectedProduct)}
				/>
			</ModalSection>
		);
	}

	return null;
};

export default AdditionalInfo;
