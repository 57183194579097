import { gql, TypedDocumentNode } from '@apollo/client';

import { TSharePartnerPlannerInput } from '../types';

const SHARE_PLANNER_JOB_MUTATION: TypedDocumentNode<
	{
		sharePartnerPlannerJob: boolean;
	},
	TSharePartnerPlannerInput
> = gql`
	mutation sharePartnerPlannerJob($plannerJobId: ID!, $locationIds: [ID!]!) {
		sharePartnerPlannerJob(
			plannerJobId: $plannerJobId
			locationIds: $locationIds
		)
	}
`;

export default SHARE_PLANNER_JOB_MUTATION;
