import _range from 'lodash/range';
import { useTranslation } from 'react-i18next';

import { findSupportedLanguage } from '../utils/findSupportedLanguage';

type TMonthFormat =
	| 'numeric'
	| '2-digit'
	| 'long'
	| 'short'
	| 'narrow'
	| undefined;

export default (format: TMonthFormat, countFrom?: string): string[] => {
	const { i18n } = useTranslation();
	const supportedLanguage = findSupportedLanguage(i18n.languages);

	const countFromMonth = countFrom ? parseInt(countFrom.split('-')[0]) - 1 : 0;

	return _range(0, 12).map((monthIndex) => {
		const month = new Date(1994, countFromMonth + monthIndex);
		return month.toLocaleString(supportedLanguage, {
			month: format,
		});
	});
};
