import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import { fieldImportsPageState } from '../../../../state';
import FailureMessage from '../../../FailureMessage';
import SuccessMessage from '../../../SuccessMessage';
import ContentLayout from '../ContentLayout';
import UploadGeoJSON from '../UploadGeoJSON';

const FIELDS_LIMIT = 25;

type TAddFieldsSuccessProps = {
	fieldsInput: TFieldInput[];
};

const AddFieldsSuccess = (props: TAddFieldsSuccessProps) => {
	const { fieldsInput } = props;
	const { t } = useTranslation();

	const title = (
		<Text variant="h3" component="h2">
			{t('importPages.fieldImportsPage.uploadFilesStep.title')}
		</Text>
	);

	const description = (
		<Text variant="body1" color="textSecondary">
			{t('importPages.fieldImportsPage.uploadFilesStep.description')}
		</Text>
	);

	const goToNextStep = () =>
		fieldImportsPageState({
			status: 'EDIT_FIELDS',
			fieldsInput,
		});

	const isFieldsLimitExceeded = fieldsInput.length > FIELDS_LIMIT;

	return (
		<ContentLayout
			nextArrowHandler={
				isFieldsLimitExceeded || !fieldsInput.length ? null : goToNextStep
			}
		>
			<Box marginY={2}>
				{title}
				<Box paddingTop={2} paddingBottom={4}>
					{description}
				</Box>
				{isFieldsLimitExceeded ? (
					<FailureMessage
						message={t('importPages.fieldImportsPage.fieldsLimitExceeded', {
							limit: FIELDS_LIMIT,
						})}
					>
						<UploadGeoJSON />
					</FailureMessage>
				) : (
					<SuccessMessage
						message={t('importPages.fieldImportsPage.processedFields', {
							count: fieldsInput.length,
						})}
					>
						<UploadGeoJSON />
					</SuccessMessage>
				)}
			</Box>
		</ContentLayout>
	);
};

export default AddFieldsSuccess;
