import { z } from 'zod';

import { TProductUnits, isProductUnit } from '../units';
import { TProductTreatmentTime, TreatmentTimes } from './productTreatmentTime';

export const ProductSpecInputSchema = z.object({
	registrationNumber: z.string().optional(),
	fracNumber: z.string().optional(),
	reEntryInterval: z.number().optional(),
	treatmentTime: z.enum(TreatmentTimes).optional(),
	packageSize: z.number().optional(),
	packageUnit: z.string().refine(isProductUnit).optional(),
	packagePrice: z.number().optional(),
	packageCurrency: z.string().optional(),
	note: z.string().optional(),
	manufacturerName: z.string().optional(),
	licenseExpirationDate: z.string().datetime().optional(),
	usageExpirationDate: z.string().datetime().optional(),
	chemicalFormulation: z.string().optional(),
	lowRisk: z.boolean().optional(),
	hazardNotes: z.array(z.string()).optional(),
	governmentRemarks: z.array(z.string()).optional(),
});

export const SpanishProductSpecInputSchema =
	ProductSpecInputSchema &&
	z.object({
		traderRegistrationNumber: z.string(),
		fertilizerMaterialId: z.string(),
		organicCarbon: z.number(),
		manufacturerName: z.string(),
	});

export type TProductSpecInput = {
	registrationNumber?: string;
	fracNumber?: string;
	reEntryInterval?: number;
	treatmentTime?: TProductTreatmentTime;
	packageSize?: number;
	packageUnit?: TProductUnits;
	packagePrice?: number;
	packageCurrency?: string;
	note?: string;
	manufacturerName?: string;
	licenseExpirationDate?: string | null;
	usageExpirationDate?: string | null;
	chemicalFormulation?: string;
	lowRisk?: boolean;
	hazardNotes?: string[];
	governmentRemarks?: string[];
	traderRegistrationNumber?: string;
	fertilizerMaterialId?: string;
	organicCarbon?: number;
};
