import * as React from 'react';

import { PopperProps, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	DatePicker as MuiDatePicker,
	DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './styles.scss';

export type TDatePickerProps = {
	inputProps?: TextFieldProps;
	popperProps?: Partial<PopperProps>;
	dense?: boolean;
} & Omit<DatePickerProps<Date>, 'renderInput'>;

export default function Datepicker(props: TDatePickerProps) {
	const { inputProps, format, dense, popperProps = {}, ...rest } = props;

	const mergedPopperProps = {
		className: dense ? 'mui-dense-date-picker' : undefined,
		...popperProps,
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<MuiDatePicker
				format={format || 'dd/MM/yyyy'}
				slotProps={{ textField: inputProps, popper: mergedPopperProps }}
				{...rest}
			/>
		</LocalizationProvider>
	);
}
