import React from 'react';

import Box from 'UI/layout/Box';

import TextSearchInput from '../../../components/filters/TextSearchInput';
import LogsPageHeader from '../../../components/logs/LogsPageHeader';
import LogsPageLayout from '../../../components/logs/LogsPageLayout';
import { CreateReportButton } from '../CreateReport';
import Filters from '../common/components/Filters';
import TableTabs from '../common/components/TableTabs';
import EmployeesLogsTable from './components/EmployeesLogsTable';
import FilterModal from './components/FilterModal';
import Pagination from './components/Pagination';

function EmployeesLogsPage() {
	return (
		<LogsPageLayout>
			<LogsPageHeader>{<CreateReportButton />}</LogsPageHeader>
			<TableTabs>
				<Box display="flex" justifyContent="space-between">
					<Box>
						<FilterModal />
						<Filters />
					</Box>
					<Box margin="0 1rem" alignSelf="center">
						<TextSearchInput />
					</Box>
				</Box>
				<EmployeesLogsTable />
				<Pagination />
			</TableTabs>
		</LogsPageLayout>
	);
}

export default EmployeesLogsPage;
