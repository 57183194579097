import { TTranslateFunction } from 'Features/localization';
import { CurrencyCodeRecord } from 'currency-codes';
import {
	TooltipFormatterCallbackFunction,
	TooltipFormatterContextObject,
} from 'highcharts';

import parseTooltipPoints from './parseTooltipPoints';

type TPointsGroupedByCurrency = {
	code: CurrencyCodeRecord['code'];
	points: TTooltipPoints;
};

type TTooltipPoints =
	| [TooltipFormatterContextObject]
	| [TooltipFormatterContextObject, TooltipFormatterContextObject];

export default (t: TTranslateFunction) => {
	const formatter: TooltipFormatterCallbackFunction = function () {
		const pointsByCurrencies = this.points
			?.reduce<TPointsGroupedByCurrency[]>((pointsByCurrencies, point) => {
				const code = point.series.options.custom
					?.currency as CurrencyCodeRecord['code'];

				const currency = pointsByCurrencies.find(
					(currency) => point.series.options.custom?.currency === currency.code
				);

				if (currency) {
					return pointsByCurrencies.map((currency) => ({
						...currency,
						points: [currency.points[0], point],
					}));
				}
				return [...pointsByCurrencies, { code, points: [point] }];
			}, [])
			.map((pointsByCurrencies) => parseTooltipPoints(pointsByCurrencies, t));

		// Handle multiple currencies, and group them
		if (pointsByCurrencies && pointsByCurrencies.length > 1) {
			return pointsByCurrencies
				.map((currency) => {
					const header = `<p><b>${currency.code}</b></p>`;
					return `
				${header}
				${currency.points.join(' ')}
			`;
				})
				.join(' ');
		}

		return pointsByCurrencies?.[0].points.join(' ');
	};

	return formatter;
};
