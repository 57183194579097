import countries from 'i18n-iso-countries';
import de from 'i18n-iso-countries/langs/de.json';
import en from 'i18n-iso-countries/langs/en.json';
import es from 'i18n-iso-countries/langs/es.json';
import nb from 'i18n-iso-countries/langs/nb.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import { useTranslation } from 'react-i18next';

import { findSupportedLanguage } from '../utils/findSupportedLanguage';

const i18nLangs = {
	en,
	de,
	es,
	nb,
	nl,
};

export default (): string => {
	const { i18n } = useTranslation();
	const supportedLanguage = findSupportedLanguage(i18n.languages);

	if (!countries.langs().includes(supportedLanguage)) {
		countries.registerLocale(i18nLangs[supportedLanguage]);
	}

	return supportedLanguage.split('-')[0];
};
