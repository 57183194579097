import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import useParsedSearchParams from 'Hooks/useParsedSearchParams';

type TLogsExportPageLayoutProps = {
	downloadTrigger: () => void;
	title: string;
	children?: ReactElement;
};

const LogsExportPageLayout = (
	props: TLogsExportPageLayoutProps
): ReactElement => {
	const { downloadTrigger, title, children } = props;
	const { t } = useTranslation();
	const parsedSearchParams = useParsedSearchParams();

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			margin="auto"
			gap="1rem"
		>
			<Text variant="h1">{title}</Text>
			<Text>{parsedSearchParams}</Text>
			<Button
				id="download-report-button"
				onClick={downloadTrigger}
				sx={{ margin: '0.75rem' }}
			>
				{t('exportPages.downloadButton')}
			</Button>
			{children}
		</Box>
	);
};

export default LogsExportPageLayout;
