import { TPartnerFieldsResponse } from '../../common/api/getPartnerFields';

function parseData(response?: TPartnerFieldsResponse) {
	if (response) {
		if ('getPartnerFields' in response) {
			return response.getPartnerFields.fields.map((field) => field.field);
		}
	}
	return [];
}

export default parseData;
