import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import ContentLayout from '../ContentLayout';
import UploadGeoJSON from '../UploadGeoJSON';

function AddFieldsForm() {
	const { t } = useTranslation();

	const title = (
		<Text variant="h3" component="h2">
			{t('importPages.fieldImportsPage.uploadFilesStep.title')}
		</Text>
	);

	const description = (
		<Text variant="body1" color="textSecondary">
			{t('importPages.fieldImportsPage.uploadFilesStep.description')}
		</Text>
	);

	return (
		<ContentLayout nextArrowHandler={undefined}>
			<Box marginY={2}>
				{title}
				<Box paddingTop={2} paddingBottom={4}>
					{description}
				</Box>
				<UploadGeoJSON />
			</Box>
		</ContentLayout>
	);
}

export default AddFieldsForm;
