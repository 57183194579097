import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTimesheetSettings } from 'Models/timesheet/isTimesheetSettings';
import { lunchDurationString } from 'Models/timesheet/lunchDurationString';
import Text from 'UI/display/Text';
import Select from 'UI/inputs/Select';
import Grid from 'UI/layout/Grid';
import Menu from 'UI/navigation/Menu';

import styles from './styles.module.scss';

const SUBTRACTING_LUNCH_DURATIONS = [300, 360, 420, 480];
const LUNCH_TIME_DURATIONS = [15, 30, 45, 60, 120];
const DEFAULT_MIN_DURATION_FOR_SUBTRACTING_LUNCH = 300;
const DEFAULT_LUNCH_TIME_DURATION = 30;

function getDurationSelectItem(value: number): ReactElement {
	const duration = lunchDurationString(value);
	return (
		<Menu.Item key={'select-item-' + value.toString()} value={value}>
			<Text variant="body2">{duration}</Text>
		</Menu.Item>
	);
}

export type TLunchTimeSettingsInputs = Pick<
	TTimesheetSettings,
	'minDurationForSubtractingLunch' | 'lunchTimeDuration'
>;

type TLunchTimeSettingsInputsProps = {
	lunchTimeSettingsInputs: TLunchTimeSettingsInputs;
	setLunchTimeSettingsInputs: React.Dispatch<
		React.SetStateAction<TLunchTimeSettingsInputs>
	>;
};

const LunchTimeSettingsInputs = (props: TLunchTimeSettingsInputsProps) => {
	const { lunchTimeSettingsInputs, setLunchTimeSettingsInputs } = props;
	const { t } = useTranslation();

	const minDurationItems = SUBTRACTING_LUNCH_DURATIONS.map(
		getDurationSelectItem
	);
	const lunchBreakItems = LUNCH_TIME_DURATIONS.map(getDurationSelectItem);

	function handleMinDurationChange(newValue: string) {
		setLunchTimeSettingsInputs({
			...lunchTimeSettingsInputs,
			minDurationForSubtractingLunch: parseInt(newValue),
		});
	}

	function handleLunchBreakChange(newValue: string) {
		setLunchTimeSettingsInputs({
			...lunchTimeSettingsInputs,
			lunchTimeDuration: parseInt(newValue),
		});
	}

	return (
		<Grid container spacing={2} className={styles.lunchTimeInputsWrapper}>
			<Grid item xs={6}>
				<Select
					label={t('labels.minDurationForSubtractingLunch')}
					value={
						lunchTimeSettingsInputs.minDurationForSubtractingLunch ||
						DEFAULT_MIN_DURATION_FOR_SUBTRACTING_LUNCH
					}
					changeHandler={handleMinDurationChange}
				>
					{minDurationItems}
				</Select>
			</Grid>
			<Grid item xs={6}>
				<Select
					label={t('labels.lunchTimeDuration')}
					value={
						lunchTimeSettingsInputs.lunchTimeDuration ||
						DEFAULT_LUNCH_TIME_DURATION
					}
					changeHandler={handleLunchBreakChange}
				>
					{lunchBreakItems}
				</Select>
			</Grid>
		</Grid>
	);
};

export default LunchTimeSettingsInputs;
