import classicFormat from '../dates/classicFormat';
import { TPartnerHarvest } from './isPartnerHarvest';
import { THarvest } from './types/Harvest';

function harvestDateString(
	harvest: Pick<THarvest | TPartnerHarvest, 'date'>
): string {
	return classicFormat(new Date(harvest.date));
}

export default harvestDateString;
