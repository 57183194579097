import TPageInput from 'Models/api/PageInput';

export default function readPaginatedNestedValue(field) {
	return function (existing, params) {
		const { args, variables } = params;
		if (!args) {
			throw new Error('No args provided to paginated field');
		}
		const pageInput: TPageInput = args.pageInput;

		if (!existing) {
			return undefined;
		}
		const paginatedAmount = pageInput.offset + pageInput.limit;
		const page = existing[field].slice(pageInput.offset, paginatedAmount);

		// if no match - undefined triggers refetch
		if (
			!page.length ||
			Object.keys(page).length === 0 ||
			existing[field].length < paginatedAmount
		) {
			return undefined;
		}

		return variables.hasPagination
			? {
					...existing,
					[field]: page,
			  }
			: existing;
	};
}
