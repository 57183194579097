function getUTCTime(date: Date | string | number): number {
	try {
		if (typeof date === 'string') {
			return new Date(date).getTime();
		}

		if (date instanceof Date) {
			return date.getTime();
		}

		if (typeof date === 'number') {
			return date;
		}

		return -1;
	} catch (e) {
		throw new Error('Cannot convert value to UTC time');
	}
}

export default getUTCTime;
