import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import ErrorBoundary from '../../../components/ErrorBoundary';
import CreateCompanyModal from './components/CreateCompanyModal';
import PageAccessValidator from './components/PageAccessValidator';

function CreateCompanyPage() {
	const { t } = useTranslation();

	return (
		<ErrorBoundary>
			<PageAccessValidator>
				<Box
					display="flex"
					height="100vh"
					alignItems="center"
					flexDirection="column"
					justifyContent="center"
				>
					<Text variant="h1" marginBottom="1rem">
						{t('createCompanyPage.welcome')}
					</Text>
					<Text mb="1rem">{t('createCompanyPage.shareInformation')}</Text>
					<Text paddingBottom="1.5rem">
						{t('createCompanyPage.contactInformation')}
					</Text>
					<CreateCompanyModal />
				</Box>
			</PageAccessValidator>
		</ErrorBoundary>
	);
}

export default CreateCompanyPage;
