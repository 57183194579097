import { gql, TypedDocumentNode } from '@apollo/client';
import { TAggregatedSales } from 'Models/sales/isAggregatedSales';
import { TAggregatedSalesGroupOption } from 'Models/sales/isAggregatedSalesGroupOption';

export type TAggregatedSale = Pick<
	TAggregatedSales,
	'currency' | 'totalRevenue' | 'month'
>;

export type TGetAggregatedSalesResponse = {
	getAggregatedSales: TAggregatedSale[];
};

type TGetAggregatedSalesRequest = {
	locationId: string;
	query: {
		filterBy: {
			dateFrom: string;
			dateTo: string;
			fieldId?: string;
		};
		groupBy: TAggregatedSalesGroupOption;
	};
};

const GET_AGGR_SALES_QUERY: TypedDocumentNode<
	TGetAggregatedSalesResponse,
	TGetAggregatedSalesRequest
> = gql`
	query getProfitChartAggregatedSales(
		$locationId: ID!
		$query: AggregatedSalesQuery
	) {
		getAggregatedSales(locationId: $locationId, query: $query) {
			totalRevenue
			currency
			month
		}
	}
`;

export default GET_AGGR_SALES_QUERY;
