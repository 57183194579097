import React, { ReactElement } from 'react';

import Box from 'UI/layout/Box';
import UIKitCard from 'UI/surfaces/Card';
import UIKitCardContent from 'UI/surfaces/Card/CardContent';
import UIKitCardHeader from 'UI/surfaces/Card/CardHeader';
import { FarmableColors } from 'UI/theme/Colors';
import _uniqueId from 'lodash/uniqueId';

import ErrorBoundary from '../../ErrorBoundary';

type TDashboardCard = {
	children: ReactElement;
	size: 4 | 6 | 8 | 12;
	title?: string;
	noHeaderBorder?: boolean;
	noHeaderHeight?: boolean;
	noPadding?: boolean;
	height?: string;
	action?: ReactElement;
};

const Card = (props: TDashboardCard) => {
	const {
		size,
		title,
		noPadding,
		height,
		action,
		noHeaderBorder,
		noHeaderHeight,
	} = props;

	return (
		<Box
			gridColumn={`span ${size}`}
			position="relative"
			width="100%" // solves Firefox bug - #2710
		>
			<UIKitCard sx={{ height: height || '28.125rem' }}>
				{title ? (
					<UIKitCardHeader
						title={title}
						action={action}
						sx={{
							'& .MuiCardHeader-title': { fontSize: '1.25rem' },
							'& .MuiCardHeader-action': { margin: 0 },
							height: noHeaderHeight ? undefined : '4rem',
							padding: '1.25rem 1rem',
						}}
					/>
				) : null}
				<UIKitCardContent
					sx={{
						padding: noPadding ? 0 : '1rem 1rem 0 1rem',
						borderTop: noHeaderBorder
							? undefined
							: `1px solid ${FarmableColors.DIVIDER}`,
						'&:last-child': {
							paddingBottom: 0,
						},
					}}
				>
					<ErrorBoundary key={_uniqueId()} withinComponent>
						{props.children}
					</ErrorBoundary>
				</UIKitCardContent>
			</UIKitCard>
		</Box>
	);
};

export default Card;
