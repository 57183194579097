import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';
import Table from 'UI/display/Table';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import ReviewTableRow from './components/ReviewTableRow';

type TFieldReviewTableProps = {
	fieldsInput: TFieldInput[];
};

const useStyles = makeStyles({
	logsTableContainer: {
		border: `1px solid ${FarmableColors.DIVIDER}`,
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},
});

const FieldsReviewTable = (props: TFieldReviewTableProps) => {
	const { fieldsInput } = props;
	const { t } = useTranslation();

	const styles = useStyles();

	return (
		<Box className={styles.logsTableContainer}>
			<Table.Container>
				<Table.Table stickyHeader aria-label="sticky header table">
					<Table.Head>
						<Table.Row>
							<Table.Cell>
								{t('importPages.fieldImportsPage.reviewTable.header.name')}
							</Table.Cell>
							<Table.Cell align="right">
								{t('importPages.fieldImportsPage.reviewTable.header.type')}
							</Table.Cell>
							<Table.Cell align="right">
								{t('importPages.fieldImportsPage.reviewTable.header.category')}
							</Table.Cell>
							<Table.Cell align="right">
								{t('importPages.fieldImportsPage.reviewTable.header.area')}
							</Table.Cell>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{fieldsInput.map((row, index) => (
							<ReviewTableRow key={index} fieldInput={row} />
						))}
					</Table.Body>
				</Table.Table>
			</Table.Container>
		</Box>
	);
};

export default FieldsReviewTable;
