import React from 'react';

import { useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_HARVEST_TABLE_DATA from '../api/getHarvestTableData';
import getQueryVariables from '../utils/getQueryVariables';

const Pagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();
	const { data, loading } = useQuery(GET_HARVEST_TABLE_DATA, {
		variables: getQueryVariables(searchParams),
	});

	return (
		<TablePagination
			count={data?.getHarvest.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
