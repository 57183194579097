import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import CREATE_PARTNERSHIP_CAUSE from '../../api/createPartnershipCause';
import { TGovernmentCause } from './api/getGovernmentCauses';
import SelectCause from './components/SelectCause';

type TAddExistingCauseModal = {
	isModalOpen: boolean;
	parentModalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
	modalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AddExistingCauseModal(props: TAddExistingCauseModal) {
	const [selectedCause, setSelectedCause] =
		useState<TGovernmentCause | null>(null);
	const { modalStateHandler, parentModalStateHandler, isModalOpen } = props;

	const { t } = useTranslation();

	useEffect(() => setSelectedCause(null), [isModalOpen]);

	const [createPartnershipCause, createPartnershipCauseResult] = useMutation(
		CREATE_PARTNERSHIP_CAUSE
	);

	useSnackbarNotifications({
		mutationResult: createPartnershipCauseResult,
		messageSuccess: t('partnerCausesPage.addNewCauseModal.createCauseSuccess'),
		messageFailure: t('partnerCausesPage.addNewCauseModal.createCauseFailure'),
	});

	const closeModal = () => {
		modalStateHandler(false);
	};

	const backToParentModal = () => {
		modalStateHandler(false);
		parentModalStateHandler(true);
	};

	const handleCreateCause = () => {
		if (selectedCause) {
			void createPartnershipCause({
				variables: {
					input: {
						name: selectedCause.name,
						type: selectedCause.causeType,
					},
				},
				refetchQueries: ['getPartnerCauses'],
				onCompleted: closeModal,
			});
		}
	};

	return (
		<>
			<Modal.UncontrolledModal toggleOpen={closeModal} open={isModalOpen}>
				<Modal.DialogTitle
					title={t('causesPage.modal.existingCause.title')}
					closeIconHandler={closeModal}
				/>
				<Modal.DialogContent>
					<SelectCause
						selectedCause={selectedCause}
						parentModalStateHandler={setSelectedCause}
					/>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant="text" onClick={backToParentModal}>
							{t('buttons.back')}
						</Button>
						<Box display="flex" flexDirection="row" alignItems="center">
							<Button
								variant="outlined"
								disabled={
									!selectedCause || createPartnershipCauseResult.loading
								}
								onClick={handleCreateCause}
								startIcon={
									createPartnershipCauseResult.loading ? (
										<LoadingIcon fontSize="small" />
									) : null
								}
							>
								{t('buttons.save')}
							</Button>
						</Box>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}
