import { gql, TypedDocumentNode } from '@apollo/client';
import { TMember } from 'Models/profile/isMember';

export type TGetMembersResult = TMember[];

const GET_LOCATION_MEMBERS: TypedDocumentNode<
	{
		members: TGetMembersResult;
	},
	{
		locationId: string;
	}
> = gql`
	query getLocationMembers($locationId: ID!) {
		members(locationId: $locationId) {
			account {
				id
				email
				firstName
				lastName
			}
			accountRole
		}
	}
`;

export default GET_LOCATION_MEMBERS;
