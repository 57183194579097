import React from 'react';

import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

function BetaTag() {
	const styles = {
		backgroundColor: FarmableColors.GREEN,
		px: '0.5rem',
		py: '0.125rem',
		borderRadius: '0.75rem',
		width: '2.5625rem',
		height: '1.125rem',

		'& > .MuiTypography-root': {
			lineHeight: 1.4,
		},
	};

	return (
		<Box sx={styles}>
			<Text variant="label3" color={FarmableColors.WHITE}>
				BETA
			</Text>
		</Box>
	);
}

export default BetaTag;
