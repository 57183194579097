import numberToDisplayString from 'Utils/numberToDisplayString';

import { KILOGRAM, POUND } from '../units';
import { toDisplayString } from '../units/transpileUnits';
import { THarvestBatch } from './isHarvestBatch';

function harvestBatchStorageVolume<TGenericHarvestBatch>(
	harvestBatch: TGenericHarvestBatch &
		Pick<THarvestBatch, 'totalVolume' | 'soldVolume' | 'wasteVolume'>,
	isImperial?: boolean | null
): string {
	const storageValue =
		harvestBatch.totalVolume -
		harvestBatch.soldVolume -
		harvestBatch.wasteVolume;

	return `${numberToDisplayString(storageValue)} ${toDisplayString(
		isImperial ? POUND : KILOGRAM
	)}`;
}

export default harvestBatchStorageVolume;
