import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ProfilePageTemplate from '../common/components/ProfilePageTemplate';
import ProfileContent from './components/ProfileContent';

function PersonalInfoPage(): ReactElement {
	const { t } = useTranslation();
	return (
		<ProfilePageTemplate
			header={t('personalInfoPage.header')}
			subheader={t('personalInfoPage.subheader')}
		>
			<ProfileContent />
		</ProfilePageTemplate>
	);
}

export default PersonalInfoPage;
