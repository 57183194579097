import { AuthorizationParams } from '@auth0/auth0-react';

import defaultRedirectURI from './defaultRedirectURI';

export const Provider = {
	GOOGLE: 'google-oauth2',
	FACEBOOK: 'facebook',
	APPLE: 'apple',
} as const;

export enum AuthorizeType {
	LOGIN = 'login',
	SIGN_UP = 'signup',
}

enum Prompt {
	NONE = 'none',
	LOGIN = 'login',
	CONSENT = 'consent',
	SELECT_ACCOUNT = 'select_account',
}

type TProviderKeys = keyof typeof Provider;
export type TProvider = typeof Provider[TProviderKeys];

type TParams = {
	mode?: AuthorizeType;
	connection?: TProvider;
	prompt?: AuthorizationParams['prompt'];
	redirect_uri?: string;
};

export default function getLoginParams(params?: TParams) {
	const redirect_uri = params?.redirect_uri || defaultRedirectURI();
	const prompt = params?.prompt || Prompt.LOGIN;
	const connection = params?.connection;
	const login_hint =
		params?.mode === AuthorizeType.SIGN_UP ? AuthorizeType.SIGN_UP : undefined;

	return {
		authorizationParams: {
			redirect_uri,
			prompt,
			connection,
			login_hint,
		},
	};
}
