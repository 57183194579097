import React from 'react';

import Box from 'UI/layout/Box';

import LogsPageHeader from '../../../components/logs/LogsPageHeader';
import LogsPageLayout from '../../../components/logs/LogsPageLayout';
import Filters from '../common/components/Filters';
import TableTabs from '../common/components/PartnerTableTabs';
import { PartnerSalesLogsFilter } from './PartnerSalesLogsFilter';
import { PartnerSalesLogsPagination } from './PartnerSalesLogsPagination';
import { PartnerSalesLogsTable } from './PartnerSalesLogsTable';

export const PartnerHarvestSalesPage = () => {
	return (
		<LogsPageLayout>
			<LogsPageHeader />
			<TableTabs>
				<Box padding="0.5rem 1rem 1rem 1rem" display="flex" gap="0.5rem">
					<PartnerSalesLogsFilter />
					<Filters />
				</Box>
				<PartnerSalesLogsTable />
				<PartnerSalesLogsPagination />
			</TableTabs>
		</LogsPageLayout>
	);
};
