import React, { ReactElement, ReactNode } from 'react';

import Permissions from 'Models/permissions/Permissions';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import usePermission from 'Hooks/usePermission';

import NoAccessPage from '../../../../../no-access/NoAccessPage';

type TImportsPageValidatorProps = {
	children?: ReactNode;
};

function ImportsPageValidator(props: TImportsPageValidatorProps): ReactElement {
	const { children } = props;
	const location = useCurrentLocation();

	const hasFieldsImportPermissions = usePermission(
		Permissions.FIELD_IMPORTS_PAGE_ACCESS
	);

	const isSIEX = location && location.type === 'SIEX';

	return (
		<>{hasFieldsImportPermissions && !isSIEX ? children : <NoAccessPage />}</>
	);
}

export default ImportsPageValidator;
