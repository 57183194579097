import React, { useState, ReactElement } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';

import { assertStateStatus } from 'Utils/assertStateStatus';

import useTransactionalInput from '../../../../../../../../hooks/useTransactionalInput';
import { fieldImportsPageState } from '../../../../state';
import ManageFieldTemplate from './ManageFieldTemplate';
import ModalButtons from './ManageFieldTemplate/components/ModalButtons';
import ModalForm from './ManageFieldTemplate/components/ModalForm';
import styles from './styles.module.scss';

type TEditFieldModalProps = {
	fieldInputIndex: number;
	children?: ReactElement;
};

function preventAccordionCollapse(e: React.MouseEvent | React.FocusEvent) {
	e.stopPropagation();
}

const EditFieldModal = (props: TEditFieldModalProps) => {
	const { fieldInputIndex, children } = props;
	const state = useReactiveVar(fieldImportsPageState);
	assertStateStatus(state, 'EDIT_FIELDS');

	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();

	const editFieldsInput = state.fieldsInput;

	const {
		onTransactionBegin,
		onTransactionCommit,
		onTransactionChange,
		value,
		isInTransaction,
	} = useTransactionalInput(editFieldsInput[fieldInputIndex], handleSave);

	function handleSave(newFieldInput: TFieldInput) {
		const newEditFieldsInput = editFieldsInput.map((oldfieldInput, index) => {
			return index === fieldInputIndex ? newFieldInput : oldfieldInput;
		});

		setIsOpen(!isOpen);
		fieldImportsPageState({
			status: 'EDIT_FIELDS',
			fieldsInput: newEditFieldsInput,
		});
	}

	function handleDelete() {
		const newEditFieldsInput = editFieldsInput.filter(
			(field, index) => index !== fieldInputIndex
		);

		setIsOpen(!isOpen);
		fieldImportsPageState({
			status: 'EDIT_FIELDS',
			fieldsInput: newEditFieldsInput,
		});
	}

	function toggleOpen() {
		if (!isOpen && !isInTransaction) {
			onTransactionBegin();
		}

		setIsOpen(!isOpen);
	}

	const modalButtons = (
		<ModalButtons
			onCancel={toggleOpen}
			onSave={onTransactionCommit}
			fieldInput={value}
			onDelete={handleDelete}
		/>
	);

	const childrenWithAttachedEvent = children
		? React.cloneElement(children, { onClick: toggleOpen })
		: null;

	return (
		<>
			{childrenWithAttachedEvent}
			<div
				onClick={preventAccordionCollapse}
				onFocus={preventAccordionCollapse}
				className={styles.modalContainer}
			>
				<ManageFieldTemplate
					toggleOpen={toggleOpen}
					open={isOpen}
					modalTitle={t('importPages.fieldImportsPage.editFieldModal.title')}
					modalButtons={modalButtons}
				>
					<ModalForm
						fieldInput={value}
						onTransactionChange={onTransactionChange}
					/>
				</ManageFieldTemplate>
			</div>
		</>
	);
};

export default EditFieldModal;
