import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductConsumption } from 'Models/products/calculateProductConsumption';
import roundToTwoDecimal from 'Models/units/roundToTwoDecimal';
import Chart, { TChartProps } from 'UI/display/Chart';
import { FarmableTextColors } from 'UI/theme/Colors';

type TProductsChartProps = {
	products: TProductConsumption[];
};

const ProductsChart = (props: TProductsChartProps) => {
	const { products } = props;
	const { t } = useTranslation();
	const barHeight = 28;
	const minChartHeight = 90;
	const height = Math.max(barHeight * products.length + 48, minChartHeight);

	const chartData = products.map((prod) => ({
		y: roundToTwoDecimal(prod.amount),
		name: prod.name,
	}));

	const options: TChartProps['options'] = {
		chart: {
			type: 'bar',
			marginRight: 40,
			marginBottom: 35,
			style: {
				fontFamily: 'Lato',
			},
			height,
		},
		title: {
			text: '',
		},
		series: [
			{
				color: FarmableTextColors.ORANGE,
				type: 'bar',
				data: chartData,
			},
		],
		legend: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		plotOptions: {
			bar: {
				pointWidth: 20,
				dataLabels: {
					enabled: true,
				},
			},
		},
		xAxis: {
			type: 'category',
			labels: {
				style: {
					fontSize: '0.875rem',
					color: FarmableTextColors.PRIMARY,
				},
			},
		},
		yAxis: {
			title: {
				text: null,
			},
			labels: {
				style: {
					fontSize: '0.75rem',
					color: FarmableTextColors.SECONDARY,
				},
			},
		},
		lang: {
			noData: t('common.charts.noData'),
		},
		tooltip: {
			formatter: function () {
				return `<span style="color: ${this.color}">●</span> ${this.point.name}: <b>${this.y}</b>`;
			},
		},
	};

	return <Chart options={options} />;
};
export default ProductsChart;
