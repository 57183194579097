import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function PinIcon(props: SvgIconProps) {
	const { fill, stroke, width, height, ...MUISvgIconProps } = props;

	return (
		<SvgIcon
			viewBox="0 0 24 24"
			sx={{
				width: width || 20,
				height: height || 29,
				fill: fill || '#F05F38',
				stroke: stroke || '#000',
			}}
			{...MUISvgIconProps}
		>
			<path d="M10 .5a9.47 9.47 0 0 1 6.718 2.782A9.47 9.47 0 0 1 19.5 10c0 4.024-1.798 6.25-4.033 9.074-1.74 2.198-3.748 4.738-5.467 8.635-1.72-3.898-3.727-6.437-5.467-8.635C2.298 16.251.5 14.024.5 10a9.47 9.47 0 0 1 2.782-6.718A9.47 9.47 0 0 1 10 .5z" />
			<circle fillOpacity=".87" fill="#000" cx="10" cy="10.5" r="3.5" />
		</SvgIcon>
	);
}

export default PinIcon;
