import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TErrorType } from 'Models/error/getErrorType';
import Text from 'UI/display/Text';

import FallbackPageTemplate from './FallbackPageTemplate';

type TProps = {
	type?: TErrorType;
	eventId: string;
	statusCode?: number | string;
	message?: string;
	withinComponent?: boolean;
};

const ClientErrorFallback = (props: TProps): ReactElement => {
	const { statusCode, withinComponent } = props;
	const { t } = useTranslation();

	return (
		<FallbackPageTemplate {...props}>
			{withinComponent ? (
				<>
					<Text variant="h1" sx={{ fontSize: '2rem', lineHeight: '1.2' }}>
						{statusCode || null}
					</Text>
					<Text variant="h4" align="center">
						{t(`common.oops`)} {t('common.fallbackComponent.title')}
					</Text>
				</>
			) : (
				<>
					<Text variant="h1" sx={{ fontSize: '8rem', lineHeight: '1.2' }}>
						{statusCode || null}
					</Text>
					<Text variant="h1" sx={{ fontSize: '3rem', lineHeight: '1.2' }}>
						{t(`common.oops`)}
					</Text>
					<Text variant="h2">{t('common.fallbackComponent.title')}</Text>
				</>
			)}
		</FallbackPageTemplate>
	);
};

export default ClientErrorFallback;
