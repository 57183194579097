import React from 'react';

import { useMutation, ApolloError } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCauseInput } from 'Models/causes/isCause';
import { enqueueSnackbar } from 'notistack';

import throwErrorSilently from 'Utils/throwErrorSilently';

import { TSelectedFile } from '../../../../components/UploadImage';
import CREATE_PARTNERSHIP_CAUSE from '../api/createPartnershipCause';
import UPLOAD_PARTNERSHIP_CAUSE_IMAGE, {
	updateCauseImagesCache,
} from '../api/uploadPartnershipCauseImage';
import CauseModalContainer from './CauseModalContainer';

type TAddNewCauseModal = {
	isModalOpen: boolean;
	parentModalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
	modalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddNewCauseModal = React.memo((props: TAddNewCauseModal) => {
	const { modalStateHandler, parentModalStateHandler, isModalOpen } = props;

	const { t } = useTranslation();

	const [createPartnershipCause, createPartnershipCauseResult] = useMutation(
		CREATE_PARTNERSHIP_CAUSE
	);

	const [uploadPartnershipCauseImage, uploadPartnershipCauseImageResult] =
		useMutation(UPLOAD_PARTNERSHIP_CAUSE_IMAGE);

	const backToParentModal = () => {
		modalStateHandler(false);
		parentModalStateHandler(true);
	};

	const closeModal = () => {
		modalStateHandler(false);
	};

	async function uploadImages(causeId: string, images: TSelectedFile[]) {
		for (const image of images) {
			await uploadPartnershipCauseImage({
				variables: {
					causeId,
					file: image.file,
				},
				update: updateCauseImagesCache,
			});
		}
	}

	const handleCreateCause = (input: TCauseInput, images: TSelectedFile[]) => {
		void createPartnershipCause({
			variables: {
				input,
			},
			onCompleted: (data) => {
				void uploadImages(data.createPartnershipCause.id, images)
					.then(() => {
						closeModal();
						enqueueSnackbar(
							t('partnerCausesPage.addNewCauseModal.createCauseSuccess'),
							{
								variant: 'success',
							}
						);
					})
					.catch((error: ApolloError) => {
						enqueueSnackbar(
							t('partnerCausesPage.addNewCauseModal.createCauseFailure'),
							{
								variant: 'error',
							}
						);
						throwErrorSilently(error);
					});
			},
			refetchQueries: ['getPartnerCauses'],
		});
	};

	const isMutationLoading =
		createPartnershipCauseResult.loading ||
		uploadPartnershipCauseImageResult.loading;

	return (
		<CauseModalContainer
			isModalOpen={isModalOpen}
			onCloseModal={closeModal}
			onBackHandler={backToParentModal}
			isMutationLoading={isMutationLoading}
			onSaveHandler={handleCreateCause}
		/>
	);
});

export default AddNewCauseModal;
