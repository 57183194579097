import { gql, TypedDocumentNode } from '@apollo/client';
import { TProfile } from 'Models/profile/isProfile';

const GET_PROFILE: TypedDocumentNode<{ profile: TProfile }, null> = gql`
	query getProfile {
		profile {
			id
			email
			firstName
			lastName
			mobilePhone
			profileImage {
				path
			}
			notes
			hasEmailSubscription
			createdAt
			locale
			isReferralCodeRegistered
			intercomHash {
				intercomHashWeb
			}
		}
	}
`;

export default GET_PROFILE;
