// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3NLTMia8pFMRoibz2OgNTO>*:not(:last-child) {\n  padding-bottom: 1.5rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"optionalDetails": "_3NLTMia8pFMRoibz2OgNTO"
};
module.exports = exports;
