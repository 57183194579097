import React from 'react';

import { useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_PARTNER_HARVEST_TABLE_DATA from '../api/getPartnerHarvestsTableData';

const Pagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const pageInput = searchParams.pageInput;
	const { data, loading } = useQuery(GET_PARTNER_HARVEST_TABLE_DATA, {
		variables: {
			filterBy: {
				harvestFilters: {
					...searchParams.filters,
					teamId: searchParams.filters.teamId?.[0],
				},
				locationIds: searchParams.filters.locations,
			},
			pageInput: pageInput,
			hasPagination: true,
		},
	});

	return (
		<TablePagination
			count={data?.getPartnerHarvests.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
