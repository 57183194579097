import { format } from 'date-fns';

import ISOToLocal from '../dates/ISOToLocal';
import { TTimesheet } from './isTimesheet';

function timesheetEndTimeString<TGenericTimesheet>(
	timesheet: TGenericTimesheet & Pick<TTimesheet, 'endTime'>
): string {
	return timesheet.endTime
		? format(ISOToLocal(timesheet.endTime), 'HH:mm')
		: '-';
}

export default timesheetEndTimeString;
