import { ReactNode } from 'react';

const HOURS_LOGS_TABLE_HEADERS = [
	'date',
	'start',
	'stop',
	'duration',
	'team',
	'who',
	'task',
	'fields',
] as const;

const HoursLogsTableHeaderValues = [...HOURS_LOGS_TABLE_HEADERS];

export type THoursLogsTableHeader = typeof HOURS_LOGS_TABLE_HEADERS[number];

export default HoursLogsTableHeaderValues;

export type THoursLogsTableData = {
	[key in typeof HOURS_LOGS_TABLE_HEADERS[number]]: ReactNode;
};
