export const JOB_FILTER_KEYS = [
	'jobStatuses',
	'jobTypes',
	'customJobTypeIds',
	'completedDateFrom',
	'completedDateTo',
	'fieldCategories',
	'fieldIds',
	'fieldVarieties',
] as const;
