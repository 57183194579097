import React, { ReactElement } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import useLocationID from 'Hooks/useLocationID';

import { CREATE_FIELDS_MUTATION } from '../../../../api/createFieldsMutation';
import { fieldImportsPageState } from '../../../../state';
import ContentLayout from '../ContentLayout';
import getSerializedFieldInput from '../FieldModal/utils/getSerializedFieldInput';
import FieldsReviewTable from '../FieldsReviewTable';

type TReviewFieldsProps = {
	fieldsInput: TFieldInput[];
};

const ReviewFields = (props: TReviewFieldsProps): ReactElement => {
	const { fieldsInput } = props;

	const locationId = useLocationID();
	const [createFields] = useMutation(CREATE_FIELDS_MUTATION);
	const { t } = useTranslation();

	const createFieldsTrigger = () => {
		const serializedInput = fieldsInput.map(getSerializedFieldInput);

		fieldImportsPageState({
			fieldsAmount: fieldsInput.length,
			status: 'CREATE_FIELDS_LOADING',
		});

		createFields({
			variables: { inputs: serializedInput, locationId },
		})
			.then(() => {
				fieldImportsPageState({
					fieldsAmount: fieldsInput.length,
					status: 'CREATE_FIELDS_SUCCESS',
				});
			})
			.catch((e: unknown) => {
				fieldImportsPageState({
					status: 'CREATE_FIELDS_FAILURE',
					fieldsInput: fieldsInput,
					error: e,
				});
			});
	};

	const title = (
		<Text variant="h3" component="h2">
			{t('importPages.fieldImportsPage.reviewStep.title')}
		</Text>
	);

	const description = (
		<Text variant="body1" color="textSecondary">
			{t('importPages.fieldImportsPage.reviewStep.description')}
		</Text>
	);

	return (
		<ContentLayout
			previousArrowHandler={() =>
				fieldImportsPageState({ status: 'EDIT_FIELDS', fieldsInput })
			}
			nextArrowHandler={() => void createFieldsTrigger()}
		>
			<Box marginTop={2}>
				{title}
				<Box paddingTop={2} paddingBottom={4}>
					{description}
				</Box>
				<FieldsReviewTable fieldsInput={fieldsInput} />
			</Box>
		</ContentLayout>
	);
};
export default ReviewFields;
