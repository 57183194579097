import React, { ReactElement, ReactNode } from 'react';

import Permissions from 'Models/permissions/Permissions';

import usePermission from 'Hooks/usePermission';

import NoAccessPage from '../../../no-access/NoAccessPage';
import ProfilePageTemplate from './ProfilePageTemplate';

type Props = {
	children?: ReactNode;
	header?: string | ReactElement;
	subheader?: string;
};

const ProfilePageAccessValidator = (props: Props) => {
	const { children, header, subheader } = props;
	const hasProfilePageAccessPermission = usePermission(
		Permissions.PROFILE_PAGE_ACCESS
	);
	const pageContent = hasProfilePageAccessPermission ? (
		children
	) : (
		<NoAccessPage />
	);

	return (
		<ProfilePageTemplate header={header} subheader={subheader}>
			{pageContent}
		</ProfilePageTemplate>
	);
};

export default ProfilePageAccessValidator;
