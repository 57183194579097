enum Roles {
	ADMIN = 'ADMIN',
	FARMER = 'FARMER',
	MANAGER = 'MANAGER',
	ADVISOR = 'ADVISOR',
	DRIVER = 'DRIVER',
	PA_OWNER = 'PA_OWNER',
	PA_ADMIN = 'PA_ADMIN',
	PA_MEMBER = 'PA_MEMBER',
}

export enum PartnerRoles {
	PA_OWNER = 'PA_OWNER',
	PA_ADMIN = 'PA_ADMIN',
	PA_MEMBER = 'PA_MEMBER',
}

export type TRoles = keyof typeof Roles;

export type TPartnerRoles = keyof typeof PartnerRoles;

export default Roles;
