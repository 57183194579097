import URI from 'urijs';

const getSearchParamsFromURL = (URL: string) => {
	const url = URI(URL);

	const params = {
		pathname: url.path(),
		search: url.query(),
		hash: url.fragment(),
	};
	return params;
};

export default getSearchParamsFromURL;
