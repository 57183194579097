import React, { ReactElement, useEffect } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import getAllAvailableSeasons from 'Models/seasons/getAllAvailableSeasons';
import getSeasonPerDay from 'Models/seasons/getSeasonPerDay';
import { TSeasonParams } from 'Models/seasons/isSeasonParams';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useLocationID from 'Hooks/useLocationID';
import useManageHistory from 'Hooks/useManageHistory';

import GET_OLDEST_HARVEST_BATCH from '../../../api/getOldestHarvestBatch';

const SeasonFilter = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { paramsFromURL, historyPush } = useManageHistory();
	const locationId = useLocationID();
	const location = useCurrentLocation();

	const { data, loading, error } = useQuery(GET_OLDEST_HARVEST_BATCH, {
		variables: {
			locationId,
			pageInput: {
				limit: 1,
				offset: 0,
				sortBy: [{ direction: 'ASC', field: 'date' }],
			},
		},
	});

	const appliedSeason = paramsFromURL.filters.season;

	useEffect(() => {
		if (location && !paramsFromURL.filters.dateFrom) {
			const currentSeason = getSeasonPerDay(
				location.seasonCutoffDate || '01-01',
				new Date()
			);

			const newParams = {
				...paramsFromURL,
				filters: {
					...paramsFromURL.filters,
					dateFrom: currentSeason.from,
					dateTo: currentSeason.to,
					season: currentSeason.seasonName,
				},
			};
			historyPush(newParams);
		}
	}, [location, paramsFromURL.filters.dateFrom]);

	let menuContent: ReactElement | null = null;

	const updateFilters = (params: TSeasonParams) => {
		const newParams = {
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				dateFrom: params.from,
				dateTo: params.to,
				season: params.seasonName,
			},
		};

		setAnchorEl(null);
		historyPush(newParams);
	};

	const selectSeasonHandler = (selectedSeason: TSeasonParams) => {
		updateFilters(selectedSeason);
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	if (error) {
		throw new ApolloError(error);
	}

	if (loading) {
		menuContent = <LoadingSpinner style={{ margin: '0 0 -0.5rem 0.6rem' }} />;
	}

	if (data) {
		const theOldestHarvest = data.getHarvestBatches.harvestBatches[0];

		const seasons = theOldestHarvest
			? getAllAvailableSeasons(
					location?.seasonCutoffDate || '01-01',
					new Date(theOldestHarvest.date)
			  )
			: [new Date().getFullYear()];

		menuContent = (
			<div>
				{seasons.map((season) => (
					<Menu.Item
						onClick={() => selectSeasonHandler(season)}
						key={season.seasonName}
					>
						{season.seasonName}
					</Menu.Item>
				))}
			</div>
		);
	}

	return (
		<Box sx={{ marginRight: '0.5rem' }}>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{appliedSeason}
			</Button>
			<Menu.Menu
				id="sales-page-season-filter-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				{menuContent}
			</Menu.Menu>
		</Box>
	);
};

export default SeasonFilter;
