import React, { Fragment, ReactElement } from 'react';

import isNotEmpty from 'vet/strings/isNotEmpty';

import { TLocation } from './isLocation';

function _getLines(
	a: string | undefined | null,
	b: string | undefined | null,
	separator = ', '
): string | null {
	let result;

	if (isNotEmpty(a) && isNotEmpty(b)) {
		result = a + separator + b;
	} else if (isNotEmpty(a)) {
		result = a;
	} else if (isNotEmpty(b)) {
		result = b;
	} else {
		result = null;
	}

	return result;
}

export default (
	location: Pick<TLocation, 'address' | 'city' | 'country' | 'zip'>
): ReactElement => {
	const { address, city, zip, country } = location;
	const top = _getLines(address, city);
	const bottom = _getLines(zip, country, ' ');

	return (
		<Fragment>
			<p>{top}</p>
			<p>{bottom}</p>
		</Fragment>
	);
};
