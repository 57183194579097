import React, { ReactElement, useEffect, useRef } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import { Trans } from 'Features/localization/components/Trans';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getAccountName from 'Models/account/getAccountName';
import { TPartnershipCompanyUser } from 'Models/partnershipCompany/isPartnershipCompanyUser';
import Roles, { PartnerRoles } from 'Models/permissions/Roles';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import FarmsMultiselectList from '../../../../../../../components/FarmsMultiselectList';
import EDIT_MEMBER_ACCESS_TO_LOCATIONS from '../../../../api/EditMemberAccessToLocations';
import EDIT_PARTNERSHIP_USER_ROLE from '../../../../api/EditPartnershipUserRole';
import styles from './styles.module.scss';

type TProps = {
	member: TPartnershipCompanyUser;
	open: boolean;
	toggleOpen: () => void;
};

type TFarmListRef = {
	selectedFarmsIDs: string[] | null;
};
const ChangeFarmsAccessModal = (props: TProps): ReactElement => {
	const { member, toggleOpen, open } = props;
	const { firstName, lastName } = member;
	const { t } = useTranslation();
	const fullName = getAccountName({ firstName, lastName });
	const farmListRef = useRef<TFarmListRef>(null);

	const [editPartnershipUserRole, editPartnershipUserRoleResult] = useMutation(
		EDIT_PARTNERSHIP_USER_ROLE
	);

	const [editMemberAccessToLocations, editMemberAccessToLocationsResult] =
		useMutation(EDIT_MEMBER_ACCESS_TO_LOCATIONS, {
			refetchQueries: ['getCompanyInvitedUsersOnManageMembersPage'],
		});

	const onSubmit = () => {
		void editPartnershipUserRole({
			variables: {
				partnershipUserId: member.id,
				partnershipRole: PartnerRoles.PA_MEMBER,
			},
			onCompleted: () => {
				const farmIds = farmListRef.current?.selectedFarmsIDs;

				void editMemberAccessToLocations({
					variables: {
						memberId: member.id,
						locationIds: farmIds || [],
					},
				});
			},
		});
	};

	useSnackbarNotifications({
		mutationResult: editPartnershipUserRoleResult,
		messageFailure: t('managePartnerMembers.editPartnershipUserRole.failure'),
	});

	useSnackbarNotifications({
		mutationResult: editMemberAccessToLocationsResult,
		messageFailure: t(
			'managePartnerMembers.editMemberAccessToLocations.failure'
		),
		messageSuccess: t(
			'managePartnerMembers.editMemberAccessToLocations.success'
		),
	});

	useEffect(() => {
		if (open && editMemberAccessToLocationsResult.data) {
			toggleOpen();
			editMemberAccessToLocationsResult.reset();
		}

		if (editMemberAccessToLocationsResult.error) {
			throw new ApolloError(editMemberAccessToLocationsResult.error);
		}
	}, [
		editMemberAccessToLocationsResult.data,
		editMemberAccessToLocationsResult.error,
		open,
		toggleOpen,
	]);

	const adminTitle =
		member.role === Roles.PA_ADMIN
			? t('managePartnerMembers.changeAccessModal.adminTitle')
			: '';

	const memberTitle =
		member.role === Roles.PA_MEMBER
			? t('managePartnerMembers.changeAccessModal.memberTitle')
			: '';

	const subtitle = (
		<Trans
			i18nKey={'managePartnerMembers.changeAccessModal.subtitle'}
			values={{ user: fullName }}
			components={{ bold: <b /> }}
		/>
	);

	return (
		<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
			<Modal.DialogTitle
				title={adminTitle || memberTitle}
				closeIconHandler={toggleOpen}
			/>
			<Modal.DialogContent className={styles.subtitle}>
				<Text>{subtitle}</Text>
			</Modal.DialogContent>
			<Modal.DialogContent>
				<FarmsMultiselectList
					ref={farmListRef}
					selectedFarmsIds={member.availableLocations.map(
						(location) => location.id
					)}
				/>
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Box
					display="flex"
					justifyContent="space-between"
					flexDirection="row"
					width="100%"
				>
					<Button variant="text" onClick={toggleOpen}>
						{t('buttons.cancel')}
					</Button>
					<Button
						onClick={onSubmit}
						variant="outlined"
						disabled={editPartnershipUserRoleResult.loading}
					>
						{t('buttons.confirm')}
					</Button>
				</Box>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default ChangeFarmsAccessModal;
