import React, { ReactElement, ReactNode } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';
import Tabs from 'UI/navigation/Tabs';
import Tab from 'UI/navigation/Tabs/Tab';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

type TProps = {
	children: ReactNode;
};

const useStyles = makeStyles({
	container: {
		flex: 1,
		border: `1px solid ${FarmableColors.DIVIDER}`,
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderTop: 0,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},
});

const TableTabs = (props: TProps): ReactElement => {
	const { children } = props;
	const history = useHistory();
	const locationID = useLocationID();
	const { t } = useTranslation();
	const styles = useStyles();

	const hasHoursViewPermission = usePermission(Permissions.HOURS_LOGS_VIEW);

	const hasHarvestViewPermission = usePermission(Permissions.HARVEST_VIEW);

	const hasInventoryViewPermission = usePermission(Permissions.INVENTORY_VIEW);

	const hasTimesheetsSubscription = Boolean(
		useSubscriptionsFeatureFlag(SubscriptionType.TIMESHEET)
	);

	const hasInventoryManagementSubscription = Boolean(
		useSubscriptionsFeatureFlag(SubscriptionType.PRODUCT_INVENTORY)
	);

	const currentPath = history.location.pathname;

	return (
		<>
			<Tabs
				value={currentPath.split('/').pop() || ''}
				firstValue="jobs"
				variant="rounded"
				disableIndicator
				valueChangeHandler={(_, value) => {
					history.push(`/locations/${locationID}/logs/${value}`);
				}}
			>
				<Tab
					key="jobs-tab"
					label={t('logsPages.tabs.jobs')}
					value="jobs"
					variant="rounded"
					data-testid="jobs-tab"
				/>
				{hasHoursViewPermission && hasTimesheetsSubscription ? (
					<Tab
						key="hours-tab"
						label={t('logsPages.tabs.hours')}
						value="hours"
						variant="rounded"
						data-testid="hours-tab"
					/>
				) : null}
				{hasHarvestViewPermission ? (
					<Tab
						key="harvest-tab"
						label={t('logsPages.tabs.harvest')}
						value="harvest"
						variant="rounded"
						data-testid="harvest-tab"
					/>
				) : null}
				{hasTimesheetsSubscription ? (
					<Tab
						key="employees-tab"
						label={t('logsPages.tabs.employees')}
						value="employees"
						variant="rounded"
					/>
				) : null}
				{hasInventoryManagementSubscription && hasInventoryViewPermission ? (
					<Tab
						key="movement-tab"
						label={t('logsPages.tabs.inventoryMovement')}
						value="movement"
						variant="rounded"
					/>
				) : null}
				{hasInventoryManagementSubscription && hasInventoryViewPermission ? (
					<Tab
						key="current-tab"
						label={t('logsPages.tabs.currentInventory')}
						value="current"
						variant="rounded"
					/>
				) : null}
			</Tabs>
			<div className={styles.container}>{children}</div>
		</>
	);
};

export default TableTabs;
