import { TTranslateFunction } from 'Features/localization';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';

import translateProductCategory from 'Utils/translateProductCategory';

import { TPartnerFieldsResponse } from '../../common/api/getPartnerFields';

const getCategoriesFromFields = (
	t: TTranslateFunction,
	partnerFieldsResponse?: TPartnerFieldsResponse['getPartnerFields']
) =>
	_sortBy(
		_uniq(
			partnerFieldsResponse?.fields.flatMap((field) =>
				field.field.varieties.map((variety) =>
					translateProductCategory(variety.category, t)
				)
			)
		),
		(cat) => cat.toLocaleLowerCase()
	);

export default getCategoriesFromFields;
