import React, { ReactElement } from 'react';

import { TTranslateFunction } from 'Features/localization';
import { NOT_STARTED } from 'Models/jobs/JobStatuses';
import jobAreaWithUnit from 'Models/jobs/jobAreaWithUnit';
import jobCategoriesString from 'Models/jobs/jobCategoriesString';
import jobCausesString from 'Models/jobs/jobCausesString';
import jobDateCompletedString from 'Models/jobs/jobDateCompletedString';
import jobDateStartString from 'Models/jobs/jobDateStartString';
import jobFieldsCount from 'Models/jobs/jobFieldsCount';
import jobProduct from 'Models/jobs/jobProduct';
import jobStatusColor from 'Models/jobs/jobStatusColor';
import jobTreatment from 'Models/jobs/jobTreatment';
import { AreaUnits } from 'Models/units';
import Chip from 'UI/display/Chip';

import { TJobTableData } from '../types';

function getJobRowData(job: TJobTableData, t: TTranslateFunction) {
	const jobStatus: ReactElement = (
		<Chip
			chipColor={jobStatusColor(job)}
			dense
			label={t(`common.jobs.statuses.${job.jobStatus}`).toUpperCase()}
		/>
	);

	return Object.values({
		date:
			job.jobStatus === NOT_STARTED
				? jobDateStartString(job)
				: jobDateCompletedString(job),
		category: jobCategoriesString(job, t),
		fields: jobFieldsCount(job),
		area: jobAreaWithUnit(job, { areaUnit: AreaUnits.HECTARE }),
		cause: jobCausesString(job),
		treatmentType: jobTreatment(job, t),
		product: jobProduct(job),
		jobStatus,
	});
}

export default getJobRowData;
