import React, { ReactElement, ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Point, PointTuple } from 'leaflet';
import { Tooltip as LeafletTooltip, TooltipProps } from 'react-leaflet';

import { FarmableColors } from '../../theme/Colors';

type TTooltipVariant = 'dark' | 'light' | 'noBackground';

type TTooltipProps = {
	permanent?: boolean;
	children?: ReactNode;
	offset?: Point | PointTuple;
	direction?: 'right' | 'left' | 'top' | 'bottom' | 'center' | 'auto';
	variant?: TTooltipVariant;
};

const useStyles = makeStyles({
	tooltip: (args: TTooltipProps) => {
		switch (args.variant) {
			case 'dark':
				return {
					background: FarmableColors.BLACK_87,
					border: `1px solid ${FarmableColors.BLACK_87}`,
					borderRadius: '0.5rem',
					color: FarmableColors.WHITE,
					'&::before': FarmableColors.BLACK_87,
				};
			case 'noBackground':
				return {
					background: 'none',
					border: 'none',
					color: 'none',
					'&::before': {
						display: 'none',
					},
					boxShadow: 'none',
				};
			default:
				return {
					background: FarmableColors.WHITE,
					border: `1px solid ${FarmableColors.WHITE}`,
					borderRadius: '0.5rem',
					color: FarmableColors.BLACK_87,
					'&::before': FarmableColors.WHITE,
				};
		}
	},
});

const TOOLTIP_DEFAULT_VARIANT: TTooltipVariant = 'light';

const Tooltip = (props: TTooltipProps & TooltipProps): ReactElement => {
	const { children, variant, ...rest } = props;
	const tooltipVariant = variant || TOOLTIP_DEFAULT_VARIANT;

	const styles = useStyles({ variant: tooltipVariant });
	return (
		<LeafletTooltip {...rest} className={styles.tooltip}>
			{children}
		</LeafletTooltip>
	);
};

export default Tooltip;
