import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getDateRepresentationFromString from 'Models/dates/getDateRepresentationFromString';
import { URLFiltersType } from 'Models/history/isURLFilters';
import DateRangePicker from 'UI/inputs/DateRangePicker';
import { TDateRange } from 'UI/inputs/DateRangePicker/Controlled';
import { parseISO } from 'date-fns';

import getDateFnsLocale from 'Utils/getDateFnsLocale';

type TTimeVersions = Pick<
	URLFiltersType,
	| 'timeFrom'
	| 'timeTo'
	| 'dateFrom'
	| 'dateTo'
	| 'completedDateFrom'
	| 'completedDateTo'
>;

type TDateProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const getTimeRange = (dateVersion: TTimeVersions) => {
	if (dateVersion.timeFrom || dateVersion.timeTo) {
		return {
			startDate: dateVersion.timeFrom ? parseISO(dateVersion.timeFrom) : null,
			endDate: dateVersion.timeTo ? parseISO(dateVersion.timeTo) : null,
		};
	}
	if (dateVersion.dateFrom || dateVersion.dateTo) {
		return {
			startDate: dateVersion.dateFrom ? parseISO(dateVersion.dateFrom) : null,
			endDate: dateVersion.dateTo ? parseISO(dateVersion.dateTo) : null,
		};
	}
	if (dateVersion.completedDateFrom || dateVersion.completedDateTo) {
		return {
			startDate: dateVersion.completedDateFrom
				? parseISO(dateVersion.completedDateFrom)
				: null,
			endDate: dateVersion.completedDateTo
				? parseISO(dateVersion.completedDateTo)
				: null,
		};
	}
	return {
		startDate: null,
		endDate: null,
	};
};

const Date = (props: TDateProps) => {
	const { t, i18n } = useTranslation();
	const { updateFilters, filters } = props;
	const timeRange = getTimeRange(filters);

	const [open, setOpen] = useState(false);

	const onDateRangeChange = (newValue: TDateRange) => {
		updateFilters({
			...filters,
			timeFrom: getDateRepresentationFromString(newValue?.startDate),
			timeTo: getDateRepresentationFromString(newValue?.endDate),
			dateFrom: getDateRepresentationFromString(newValue?.startDate),
			dateTo: getDateRepresentationFromString(newValue?.endDate),
			completedDateFrom: getDateRepresentationFromString(newValue?.startDate),
			completedDateTo: getDateRepresentationFromString(newValue?.endDate),
		});
	};

	const toggleOpen = () => {
		setOpen((previous) => {
			return !previous;
		});
	};

	return (
		<DateRangePicker.Controlled
			value={timeRange}
			open={open}
			toggleOpen={toggleOpen}
			startDatePlaceholder={t('labels.dateFrom')}
			endDatePlaceholder={t('labels.dateTo')}
			onChangeValue={onDateRangeChange}
			noBackground
			locale={getDateFnsLocale(i18n.language)}
		/>
	);
};

export default Date;
