import { WaterConsumptionUnits } from './index';
import roundToTwoDecimal from './roundToTwoDecimal';

const ONE_GALLON_PER_ACRE_IN_LITERS_PER_HECTARE = 9.354;

function convertVolumePerArea(
	volumePerArea: number,
	from: WaterConsumptionUnits = WaterConsumptionUnits.GALLONS_PER_ACRE,
	to: WaterConsumptionUnits = WaterConsumptionUnits.LITERS_PER_HECTARE,
	formatter = roundToTwoDecimal
): number {
	if (
		from === WaterConsumptionUnits.GALLONS_PER_ACRE &&
		to === WaterConsumptionUnits.LITERS_PER_HECTARE
	) {
		return formatter(volumePerArea * ONE_GALLON_PER_ACRE_IN_LITERS_PER_HECTARE);
	} else if (
		from === WaterConsumptionUnits.LITERS_PER_HECTARE &&
		to === WaterConsumptionUnits.GALLONS_PER_ACRE
	) {
		return formatter(volumePerArea / ONE_GALLON_PER_ACRE_IN_LITERS_PER_HECTARE);
	} else if (from === to) {
		return formatter(volumePerArea);
	} else {
		throw new Error(`${__filename}: Unexpected unit in ${from}, ${to}`);
	}
}

export default convertVolumePerArea;
