import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductInput } from 'Models/products/ProductInput';
import Button from 'UI/inputs/Button';

import {
	addTreatmentPageState,
	govTreatmentsInputState,
	treatmentInputState,
} from '../state';
import { AddTreatmentModalStatus } from '../types';

type TPreviousButtonProps = {
	closeModalHandler: () => void;
};

function PreviousButton(props: TPreviousButtonProps) {
	const { closeModalHandler } = props;
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const status = state.status;

	const onPreviousButtonClick = () => {
		switch (state.status) {
			case AddTreatmentModalStatus.SET_PRODUCT_DETAILS: {
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE,
					editedCategoryName: undefined,
				});
				break;
			}
			case AddTreatmentModalStatus.SET_CROP_CATEGORIES: {
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.SET_PRODUCT_DETAILS,
				});
				break;
			}
			case AddTreatmentModalStatus.SELECT_CROP_CATEGORY: {
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.SET_CROP_CATEGORIES,
					editedCategoryName: undefined,
				});
				treatmentInputState(undefined);
				break;
			}
			case AddTreatmentModalStatus.TREATMENT_DETAILS: {
				const treatmentExists = !!(
					state.input.product as TProductInput
				).treatments?.find(
					(treatment) => treatment.category === state.editedCategoryName
				);
				if (treatmentExists) {
					addTreatmentPageState({
						...state,
						status: AddTreatmentModalStatus.SET_CROP_CATEGORIES,
						editedCategoryName: undefined,
					});
					treatmentInputState(undefined);
				} else {
					addTreatmentPageState({
						...state,
						status: AddTreatmentModalStatus.SELECT_CROP_CATEGORY,
					});
				}
				break;
			}
			case AddTreatmentModalStatus.SELECT_GOV_TREATMENTS: {
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE,
				});
				break;
			}
			case AddTreatmentModalStatus.GOV_TREATMENT_DETAILS: {
				treatmentInputState(undefined);
				govTreatmentsInputState(undefined);
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.SELECT_GOV_TREATMENTS,
				});
				break;
			}

			default:
				closeModalHandler();
		}
	};
	return (
		<Button variant="text" onClick={onPreviousButtonClick}>
			{status === AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE
				? t('buttons.cancel')
				: t('buttons.back')}
		</Button>
	);
}

export default PreviousButton;
