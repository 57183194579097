import React, { useState, useEffect } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CLEARED_FILTERS, URLFiltersType } from 'Models/history/isURLFilters';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';

import useManageHistory from 'Hooks/useManageHistory';

import Date from '../../../../components/filters/Date';
import Drawer from '../../common/components/Drawer';

export default function FilterModal() {
	const [open, setOpen] = useState(false);
	const [filters, setFilters] = useState<URLFiltersType>({
		...CLEARED_FILTERS,
	});

	const { t } = useTranslation();
	const { historyPush, paramsFromURL } = useManageHistory();
	const paramsFilters = paramsFromURL.filters;

	useEffect(() => {
		setFilters({
			...paramsFilters,
			dateFrom: paramsFilters.dateFrom,
			dateTo: paramsFilters.dateTo,
		});
	}, [open]);

	function toggleOpen() {
		setOpen((prevState) => !prevState);
	}

	const onClear = () => {
		setFilters({ ...CLEARED_FILTERS });
	};

	const onSearch = () => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFilters,
				dateFrom: filters.dateFrom,
				dateTo: filters.dateTo,
			},
		};

		historyPush(newSearchParams);
		toggleOpen();
	};

	return (
		<>
			<Button
				startIcon={<OptionsIcon />}
				variant="secondary"
				onClick={toggleOpen}
				sx={{ margin: '1rem 0.175rem 1rem 1rem' }}
			>
				{t('buttons.filters')}
			</Button>
			<Drawer.Container onHideClick={toggleOpen} open={open}>
				<Drawer.Header onHideClick={toggleOpen} />
				<Drawer.Content>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.date')}
					>
						<Date filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
				</Drawer.Content>
				<Drawer.Footer>
					<Button variant={'text'} onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={onSearch}>{t('buttons.save')}</Button>
				</Drawer.Footer>
			</Drawer.Container>
		</>
	);
}
