import React from 'react';

import Box from 'UI/layout/Box';

import LogsPageHeader from '../../../components/logs/LogsPageHeader';
import LogsPageLayout from '../../../components/logs/LogsPageLayout';
import Filters from '../common/components/Filters';
import TableTabs from '../common/components/PartnerTableTabs';
import FilterModal from './components/FilterModal';
import Pagination from './components/Pagination';
import PartnerHoursLogsTable from './components/PartnerHoursLogsTable';

function PartnerHoursLogsPage() {
	return (
		<LogsPageLayout>
			<LogsPageHeader></LogsPageHeader>
			<TableTabs>
				<Box padding="0.5rem 1rem 1rem 1rem" display="flex" gap="0.5rem">
					<FilterModal />
					<Filters />
				</Box>
				<PartnerHoursLogsTable />
				<Pagination />
			</TableTabs>
		</LogsPageLayout>
	);
}

export default PartnerHoursLogsPage;
