import { gql, TypedDocumentNode } from '@apollo/client';
import { TImage } from 'Models/image/isImage';

type TUploadCustomerImageResponse = {
	uploadCustomerImage: Pick<TImage, 'id' | 'filename'>;
};

type TUploadCustomerImageInput = {
	locationId: string;
	file: File;
};
const UPLOAD_CUSTOMER_IMAGE: TypedDocumentNode<
	TUploadCustomerImageResponse,
	TUploadCustomerImageInput
> = gql`
	mutation UploadCustomerImage($locationId: ID!, $file: Upload!) {
		uploadCustomerImage(locationId: $locationId, file: $file) {
			id
			filename
		}
	}
`;

export default UPLOAD_CUSTOMER_IMAGE;
