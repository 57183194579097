import React, { Fragment } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';

import GET_COMPANY_INVITED_USERS from '../../api/GetCompanyInvitedUsers';
import { TMembersFilters } from '../ContentBody';
import MemberItem from '../MemberItem';

type TMemberListProps = {
	filters: TMembersFilters;
	search: string;
};

const MembersList = (props: TMemberListProps) => {
	const { t } = useTranslation();

	const { data, loading, error } = useQuery(GET_COMPANY_INVITED_USERS);

	if (loading) {
		return <LoadingSpinner />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const members = data.getCompanyInvitedUsers;

		const hasAppliedFilters = Object.values(props.filters).some(Boolean);
		const searchValue = props.search.toUpperCase();

		const filteredMembers = members
			.filter((member) => !hasAppliedFilters || props.filters[member.role])
			.filter(
				(member) =>
					!searchValue ||
					member.firstName?.toUpperCase().includes(searchValue) ||
					member.lastName?.toUpperCase().includes(searchValue)
			);

		const membersList = filteredMembers.map((member, i) => {
			const isLast = i === filteredMembers.length - 1;

			return (
				<Fragment key={member.id}>
					<MemberItem member={member} />
					{isLast ? null : <Divider />}
				</Fragment>
			);
		});

		const noMembersInfo = (
			<Text variant="secondaryBody2">
				{t('managePartnerMembers.noMembersInfo')}
			</Text>
		);

		return membersList.length ? (
			<Box m="0 1.5rem">{membersList}</Box>
		) : (
			<Box p="1.5rem">{noMembersInfo}</Box>
		);
	}

	return null;
};

export default MembersList;
