import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TPartnerPlannerJob } from 'Models/planner/PartnerPlannerJob';
import Modal from 'UI/display/modal/dialog';
import DeleteIcon from 'UI/icons/Delete';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import IconButton from 'UI/inputs/IconButton';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import DELETE_PLANNER_JOB, {
	removeDeletedJobFromCache,
} from '../../api/deletePlannerJob';
import { selectedJob, selectedJobId } from '../../state';
import styles from './styles.module.scss';

type TProps = {
	job: TPartnerPlannerJob | null;
};

function DeleteJobModal(props: TProps) {
	const { job } = props;
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const [deleteJob, mutationResult] = useMutation(DELETE_PLANNER_JOB, {
		update: removeDeletedJobFromCache,
	});

	const { data, loading: isDeleting, error } = mutationResult;

	const canDeleteJob = !job?.sharedLocations?.length;

	const toggleHandler = () => setOpen(!isOpen);

	const onDelete = () => {
		if (job?.id) {
			void deleteJob({ variables: { plannerJobId: job.id } });
		}
	};

	useSnackbarNotifications({
		mutationResult,
		messageSuccess: t('info.deletePlannerJobSuccess'),
		messageFailure: t('errors.deletePlannerJobFailed'),
	});

	useEffect(() => {
		if (data) {
			setOpen(false);
			selectedJob(null);
			selectedJobId(null);
		} else if (error) {
			setOpen(false);
		}
	}, [t, error, data, setOpen]);

	return (
		<>
			{canDeleteJob && (
				<IconButton onClick={toggleHandler}>
					<DeleteIcon fontSize="small" />
				</IconButton>
			)}
			<Modal.UncontrolledModal toggleOpen={toggleHandler} open={isOpen}>
				<Modal.DialogTitle
					title={t('plannerPage.deleteModal.title')}
					closeIconHandler={toggleHandler}
				/>
				<Modal.DialogContent>
					<p className={styles.modalContent}>
						{t('plannerPage.deleteModal.message')}
					</p>
				</Modal.DialogContent>
				<Modal.DialogActions className={styles.dialogActions}>
					<Button
						variant="text"
						onClick={toggleHandler}
						className={styles.backButton}
					>
						{t('buttons.back')}
					</Button>
					<Button
						startIcon={isDeleting ? <LoadingIcon /> : null}
						variant="outlined"
						disabled={isDeleting}
						onClick={onDelete}
					>
						{t('buttons.delete')}
					</Button>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}

export default DeleteJobModal;
