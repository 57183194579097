import { TMerge } from '../../../common/utils/handleExport';
import {
	PRODUCTS_COLUMN_END_INDEX,
	PRODUCTS_COLUMN_START_INDEX,
} from './constants';

function getProductColumnsMerges(
	firstRow = 0,
	productColumnStart = PRODUCTS_COLUMN_START_INDEX,
	productColumnEnd = PRODUCTS_COLUMN_END_INDEX
): TMerge[] {
	return [
		{
			s: { r: firstRow, c: productColumnStart },
			e: { r: firstRow, c: productColumnEnd },
		},
	];
}

function getHeaderColumnsMerges(
	totalColumns,
	firstRow = 0,
	secondRow = 1,
	productColumnStart = PRODUCTS_COLUMN_START_INDEX,
	productColumnEnd = PRODUCTS_COLUMN_END_INDEX
): TMerge[] {
	const headerColumnsMerges: TMerge[] = [];

	// The remaining columns should be 2 rows high.
	for (let columnIndex = 0; columnIndex < totalColumns; columnIndex++) {
		// Exclude products cells.
		if (columnIndex < productColumnStart || columnIndex > productColumnEnd)
			headerColumnsMerges.push({
				s: { r: firstRow, c: columnIndex },
				e: { r: secondRow, c: columnIndex },
			});
	}

	return headerColumnsMerges;
}

export { getProductColumnsMerges, getHeaderColumnsMerges };
export type { TMerge };
