import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import {
	isPartnershipCompanyInput,
	TPartnershipCompanyInput,
} from 'Models/partnershipCompany/isPartnershipCompanyInput';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';
import { Alpha2Code } from 'i18n-iso-countries';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import containsNumber from 'Utils/containsNumber';

import CountryDropdown from '../../../../components/CountryDropdown';
import CREATE_COMPANY_MUTATION from '../api/createPartnerCompany';
import UPLOAD_COMPANY_IMAGE_MUTATION from '../api/uploadCompanyImage';
import UploadImage, {
	TSelectedFile,
} from './../../../../components/UploadImage';

const INIT_INPUT: TPartnershipCompanyInput = {
	name: '',
	country: 'NO',
	address: undefined,
	city: undefined,
	zip: undefined,
	email: undefined,
	mobilePhone: undefined,
};

const CreateCompanyModal = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [input, setInput] = useState<TPartnershipCompanyInput>(INIT_INPUT);
	const [isInputError, setInputError] = useState(false);

	const [createCompany, createCompanyTask] = useMutation(
		CREATE_COMPANY_MUTATION
	);
	const [uploadImage, uploadImageTask] = useMutation(
		UPLOAD_COMPANY_IMAGE_MUTATION
	);

	const [selectedImages, setSelectedImages] = useState<TSelectedFile[]>([]);

	useSnackbarNotifications({
		mutationResult: createCompanyTask,
		messageFailure: t('alert.createCompanyFailure'),
	});

	useSnackbarNotifications({
		mutationResult: uploadImageTask,
		messageFailure: t('errors.updatePartnerImageFailed'),
	});

	useEffect(() => {
		if (createCompanyTask.data) {
			if (selectedImages[0]?.file) {
				void uploadImage({ variables: { file: selectedImages[0].file } });
			}
			setOpen(false);
		}
	}, [createCompanyTask.data, selectedImages, uploadImage]);

	const isSaving = createCompanyTask.loading;

	const toggleOpen = () => {
		setOpen(!open);
		setInputError(false);
		if (!open) {
			setTimeout(() => {
				setSelectedImages([]);
				setInput(INIT_INPUT);
			}, 100);
		}
	};

	const handleSave = () => {
		if (isPartnershipCompanyInput(input) && input.country) {
			setInputError(false);
			void createCompany({ variables: { input } });
		} else {
			setInputError(true);
		}
	};

	const saveButton = (
		<Button
			variant="outlined"
			disabled={isSaving}
			onClick={handleSave}
			startIcon={isSaving ? <LoadingIcon /> : undefined}
		>
			{t('buttons.create')}
		</Button>
	);

	const onNameChange = (val: string) => setInput({ ...input, name: val });
	const onCountryChange = (val: Alpha2Code) =>
		setInput({ ...input, country: val });
	const onAddressChange = (val: string) => setInput({ ...input, address: val });
	const onCityChange = (val: string) => setInput({ ...input, city: val });
	const onZipChange = (val: string) => setInput({ ...input, zip: val });

	const uploadImageButton = (
		<UploadImage
			selectedImages={selectedImages}
			selectedImagesControl={setSelectedImages}
			maxAmount={1}
			ButtonProps={{
				sx: { '&:hover': { backgroundColor: 'transparent' } },
			}}
		>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Avatar
					size="xl"
					color={FarmableColors.BLACK_38}
					alt={input.name || undefined}
				/>
				<Text
					variant="body2"
					noWrap
					sx={{
						textDecoration: 'underline',
						cursor: 'pointer',
					}}
					paddingTop="0.5rem"
				>
					{t('labels.updateImage')}
				</Text>
			</Box>
		</UploadImage>
	);

	return (
		<>
			<Button onClick={toggleOpen}>
				{t('createCompanyPage.createCompany')}
			</Button>
			<Modal.UncontrolledModal
				onTransitionExited={() => {
					if (createCompanyTask.data) {
						history.push(
							`/partners/${createCompanyTask.data.createPartnershipCompany.id}/welcome`
						);
					}
				}}
				toggleOpen={toggleOpen}
				open={open}
			>
				<Modal.DialogTitle closeIconHandler={toggleOpen}>
					{t('createCompanyPage.createCompany')}
				</Modal.DialogTitle>
				<Modal.DialogContent>
					<Grid
						container
						spacing={2.5}
						paddingTop="0.5rem"
						paddingBottom="1.5rem"
					>
						<Grid xs={8} container item spacing={2.5}>
							<Grid item xs={12}>
								<TextField
									size="small"
									name="company name"
									required
									disabled={isSaving}
									value={input.name || ''}
									label={t('labels.name')}
									margin="dense"
									onChange={(val) => onNameChange(val)}
									helperText={
										isInputError && !input.name
											? `${t('errors.requiredField.message')}`
											: undefined
									}
									error={isInputError && !input.name}
								/>
							</Grid>
							<Grid item xs={12}>
								<CountryDropdown
									name="country"
									required
									disabled={isSaving}
									value={input.country}
									label={t('labels.country')}
									onChange={(val) => onCountryChange(val)}
									error={isInputError && !input.country}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="small"
									name="address"
									disabled={isSaving}
									value={input.address || ''}
									label={t('labels.address')}
									margin="dense"
									onChange={(val) => onAddressChange(val)}
								/>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="flex-start"
								height="100%"
								alignItems="center"
							>
								{uploadImageButton}
							</Box>
						</Grid>
						<Grid xs={12} container item spacing={2.5}>
							<Grid item xs={9}>
								<TextField
									size="small"
									name="city"
									disabled={isSaving}
									value={input.city || ''}
									label={t('labels.city')}
									margin="dense"
									onChange={(val) => onCityChange(val)}
									helperText={
										isInputError && input.city && containsNumber(input.city)
											? `${t('errors.inputContainsNumber')}`
											: undefined
									}
									error={
										isInputError && !!input.city && containsNumber(input.city)
									}
								/>
							</Grid>
							<Grid item xs={3} paddingLeft="0.75rem !important">
								<TextField
									size="small"
									name="zip"
									disabled={isSaving}
									value={input.zip || ''}
									label={t('labels.zip')}
									margin="dense"
									onChange={(val) => onZipChange(val)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box
						width="100%"
						display="flex"
						justifyContent="space-between"
						marginX="0.5rem"
					>
						<Button variant="text" onClick={toggleOpen}>
							{t('buttons.cancel')}
						</Button>
						{saveButton}
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default CreateCompanyModal;
