import { ingredientsFragmentFields } from './ingredientsFragment';
import { treatmentLibraryProductSpecFragmentFields } from './productSpecFragment';
import { treatmentLibraryTreatmentFragmentFields } from './treatmentFragment';

export const treatmentLibraryProductFragmentFields = `
id
name
governmentProductSpec {
	country
	governmentProductId
}
productSpec {
	${treatmentLibraryProductSpecFragmentFields}
}
ingredients {
	${ingredientsFragmentFields}
}
treatments {
  ${treatmentLibraryTreatmentFragmentFields}
}
`;
