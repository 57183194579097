import { gql, TypedDocumentNode } from '@apollo/client';
import { TPartnerLocation } from 'Models/partnershipCompany/isPartnerLocation';

type TInviteLocationRequest = { locationId: string };

type TInviteLocationResult = {
	inviteLocationToPartnershipCompany: Pick<
		TPartnerLocation,
		'id' | 'name' | 'status'
	>;
};

const INVITE_LOCATION: TypedDocumentNode<
	TInviteLocationResult,
	TInviteLocationRequest
> = gql`
	mutation inviteLocationToPartnershipCompany($locationId: ID!) {
		inviteLocationToPartnershipCompany(locationId: $locationId) {
			id
			name
			status
		}
	}
`;

export default INVITE_LOCATION;
