import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import SignOut from 'UI/icons/SignOut';
import MenuItem from 'UI/navigation/Menu/item';

type TDropdownSignOutItem = {
	signOutCallback: () => void;
};

const DropdownSignOutItem = (props: TDropdownSignOutItem) => {
	const { signOutCallback } = props;
	const { t } = useTranslation();

	return (
		<MenuItem onClick={() => signOutCallback()}>
			<List.ItemText>
				<Text variant="secondaryBody3">{t('common.navbar.signOutButton')}</Text>
			</List.ItemText>
			<List.ItemIcon sx={{ minWidth: 'unset !important' }}>
				<SignOut />
			</List.ItemIcon>
		</MenuItem>
	);
};

export default DropdownSignOutItem;
