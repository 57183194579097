import React from 'react';

import Box from 'UI/layout/Box';

import LogsPageHeader from '../../../components/logs/LogsPageHeader';
import LogsPageLayout from '../../../components/logs/LogsPageLayout';
import Filters from '../common/components/Filters';
import TableTabs from '../common/components/PartnerTableTabs';
import ExportButton from './components/ExportButton';
import FilterModal from './components/FilterModal';
import JobDetailsModal from './components/JobDetailsModal';
import JobLogsTable from './components/JobLogsTable';
import Pagination from './components/Pagination';

function PartnerJobLogsPage() {
	return (
		<LogsPageLayout>
			<LogsPageHeader>
				<ExportButton />
			</LogsPageHeader>
			<TableTabs>
				<Box padding="0.5rem 1rem 1rem 1rem" display="flex" gap="0.5rem">
					<FilterModal />
					<Filters />
				</Box>
				<JobLogsTable />
				<Pagination />
			</TableTabs>
			<JobDetailsModal />
		</LogsPageLayout>
	);
}

export default PartnerJobLogsPage;
