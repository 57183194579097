import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCustomer } from 'Models/customers/isCustomer';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import styles from '../styles.module.scss';
import CustomerItem from './CustomerItem';

type TCustomersTableProps = {
	customers: TCustomer[];
};

const CustomersTable = (props: TCustomersTableProps): ReactElement | null => {
	const { customers } = props;
	const { t } = useTranslation();

	const customersTitle = t('customersPage.title', {});

	const customersList = customers.map((customer) => (
		<CustomerItem key={customer.id} customer={customer} />
	));

	return customers.length ? (
		<Card>
			<CardContent>
				<Text variant="h3">{customersTitle}</Text>
				<Box className={styles.customersSection}>{customersList}</Box>
			</CardContent>
		</Card>
	) : null;
};

export default CustomersTable;
