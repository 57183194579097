import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import { errorTypes, TErrorType } from 'Models/error/getErrorType';
import sadTractor from 'UI/assets/sadTractor.svg';
import Text from 'UI/display/Text';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

type TProps = {
	children: React.ReactChild | React.ReactChild[];
	eventId: string;
	actionButtons?: React.ReactChild[];
	fullHeight?: boolean;
	withinComponent?: boolean;
	message?: string;
	type?: TErrorType;
};

const sadTractorStyles = {
	margin: 'auto',
	width: '70%',
	maxWidth: '19rem',
	marginBottom: '1rem',
};

const FallbackPageTemplate = (props: TProps): ReactElement => {
	const {
		message,
		eventId,
		withinComponent,
		actionButtons,
		fullHeight,
		type = errorTypes.UNEXPECTED_ERROR,
		children,
	} = props;
	const history = useHistory();
	const { t } = useTranslation();

	const userMessage =
		message && process.env.ENVIRONMENT === 'development'
			? message
			: t(`common.fallbackComponent.message.${type}`);

	if (withinComponent) {
		return (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				margin="auto"
				padding="1rem"
				flexDirection="column"
			>
				<img src={sadTractor} alt="sad tractor" style={sadTractorStyles} />
				{children}
				<Text
					variant="caption"
					align="center"
					sx={{ marginTop: '0.5rem', wordBreak: 'break-all' }}
				>
					{t('labels.id')}:&nbsp;{eventId}
				</Text>
				<Text
					align="center"
					sx={{ margin: '0.5rem 0 2rem', wordBreak: 'break-word' }}
				>
					{userMessage}
				</Text>
				{actionButtons || (
					<Button
						variant="outlined"
						href="mailto:support@farmable.tech"
						sx={{ textAlign: 'center' }}
					>
						{t(`common.fallbackComponent.support`)}
					</Button>
				)}
			</Box>
		);
	}

	return (
		<Box
			alignItems="center"
			justifyContent="center"
			height="100%"
			margin="auto"
			width={fullHeight ? '100vh' : '100%'}
			maxWidth="1100px"
			padding="10vh 5vh"
		>
			<Grid container>
				<Grid item xs={6}>
					{children}
					<Text sx={{ margin: '0.5rem 0 2rem' }}>{userMessage}</Text>
					<Grid container>
						{actionButtons || (
							<>
								<Grid item>
									<Button
										sx={{ margin: '0 0.5rem 0.5rem 0', textAlign: 'center' }}
										onClick={() => history.push('/')}
									>
										{t(`common.fallbackComponent.return`)}
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="outlined"
										sx={{ margin: '0 0.5rem 0.5rem 0', textAlign: 'center' }}
										href="mailto:support@farmable.tech"
									>
										{t(`common.fallbackComponent.support`)}
									</Button>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
				<Grid item xs={6} padding="auto 0 auto 2rem">
					<img src={sadTractor} alt="sad tractor" style={{ margin: 'auto' }} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default FallbackPageTemplate;
