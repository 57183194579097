import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AddPhotoIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				d="M2 6h3.17L7 4h5a1 1 0 0 1 0 2H7.88L6.05 8H2v12h16v-8a1 1 0 0 1 2 0v8c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2zm13 8c0 2.76-2.24 5-5 5s-5-2.24-5-5 2.24-5 5-5 5 2.24 5 5zm-5-3c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm8-5h-2a1 1 0 0 1 0-2h2V2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0V6z"
				fillRule="nonzero"
			/>
		</SvgIcon>
	);
}

export default AddPhotoIcon;
