import format from 'date-fns/format';

import { TJob } from './isJob';
import { TNote } from './isNotes';

const DEFAULT_FORMAT = 'yyyy-MM-dd HH:mm';

type TJobComments = { [key: string]: string };

export default function jobNotes(job: TJob, headerName: string): TJobComments {
	return (
		job.notes?.reduce((comments: TJobComments, note: TNote, i) => {
			const { firstName, lastName } = note.account;
			const authorName =
				firstName && lastName
					? firstName + ' ' + lastName
					: firstName || lastName || '-';
			const createdAt = format(new Date(note.createdAt), DEFAULT_FORMAT);

			const headerColumnName = `${headerName} ${i + 1}`;
			const cellValue = `${createdAt} ${authorName}: ${note.note}`;

			comments[headerColumnName] = cellValue;
			return comments;
		}, {}) || {}
	);
}

export const jobNotesHeaders = (
	headerName: string,
	columns?: number
): TJobComments => {
	if (!columns) return {};
	const headers = {};
	for (let i = 0; i < columns; ++i) {
		headers[`${headerName} ${i + 1}`] = '';
	}

	return headers;
};
