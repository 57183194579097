import React from 'react';

import { useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_HOURS_TABLE_DATA from '../api/getHoursTableData';

const Pagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();
	const { data, loading } = useQuery(GET_HOURS_TABLE_DATA, {
		variables: {
			filterBy: {
				timeFrom: searchParams.filters.timeFrom,
				timeTo: searchParams.filters.timeTo,
				teamId: searchParams.filters.teamId,
				fieldIds: searchParams.filters.fieldIds,
				categories: searchParams.filters.categories,
				varieties: searchParams.filters.varieties,
				teamMemberIds: searchParams.filters.teamMemberIds,
			},
			pageInput: searchParams.pageInput,
			locationId: searchParams.locationID,
			hasPagination: true,
		},
	});

	return (
		<TablePagination
			count={data?.getTimesheets.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
