import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ErrorBoundary from '../../components/ErrorBoundary';
import OrderStatusModal from '../../components/OrderStatusModal';
import StorePageBody from './components/StorePageBody';
import StorePageValidator from './components/StorePageValidator';
import StorePageTemplate from './components/StoreTemplate';

function StorePage(): ReactElement {
	const { t } = useTranslation();
	const PAGE_HEADER = t('storePage.header');
	const PAGE_SUBHEADER = t('storePage.subheader');

	return (
		<>
			<StorePageValidator>
				<StorePageTemplate header={PAGE_HEADER} subheader={PAGE_SUBHEADER}>
					<ErrorBoundary>
						<StorePageBody />
					</ErrorBoundary>
				</StorePageTemplate>
			</StorePageValidator>
			<OrderStatusModal />
		</>
	);
}

export default StorePage;
