import { TTranslateFunction } from 'Features/localization';
import _uniq from 'lodash/uniq';

import translateProductCategory from 'Utils/translateProductCategory';

import { TJobFieldSnapshot } from '../jobs/isJobFieldSnapshot';
import { TField } from './isField';

export function getFieldCategory<TGenericField>(
	field:
		| (TGenericField & Pick<TField, 'varieties'>)
		| (TGenericField & Pick<TJobFieldSnapshot, 'varieties' | 'fieldVarieties'>),
	t: TTranslateFunction
): string {
	const varieties =
		('fieldVarieties' in field && field.fieldVarieties) || field.varieties;

	const categoriesArray = varieties?.length
		? _uniq(
				varieties
					.map((variety) => translateProductCategory(variety.category, t))
					.filter(Boolean)
		  )
		: [];

	return categoriesArray.length ? categoriesArray.join(', ') : '-';
}

export default getFieldCategory;
