import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Permissions from 'Models/permissions/Permissions';
import Text from 'UI/display/Text';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import usePermission from 'Hooks/usePermission';

import SubscriptionModal from '../../../../components/SubscriptionModal';

const NoSubscription = () => {
	const { t } = useTranslation();

	const canPurchase = usePermission(Permissions.TIMESHEETS_PURCHASE);
	const timesheetsStripeProduct = process.env.STRIPE_TIMESHEETS_PRODUCT;

	const content =
		canPurchase && timesheetsStripeProduct ? (
			<div>
				<Text>{t('noAccess.noSubscriptionPage.hasPermissionToBuy')}</Text>
				<br />
				<SubscriptionModal
					title={t('timesheets.subscriptionModal.title')}
					hasPurchasePermission={canPurchase}
					productIdsWithDemo={[{ productId: timesheetsStripeProduct }]}
					openButtonText={t('timeSettingsPage.enableTimesheetsButton')}
				/>
			</div>
		) : (
			<div>
				<Text>{t('noAccess.noSubscriptionPage.noPermissionToBuy')}</Text>
			</div>
		);

	return (
		<Card>
			<CardContent>{content}</CardContent>
		</Card>
	);
};

export default NoSubscription;
