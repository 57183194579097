import { TProductTreatment } from 'Models/products/ProductTreatment';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';

export const makeTreatmentInput = (
	treatment: TProductTreatment
): TProductTreatmentInput => ({
	category: treatment.category,
	amount: treatment.amount,
	unit: treatment.unit,
	waterMin: treatment?.waterMin || undefined,
	waterMax: treatment?.waterMax || undefined,
	waterUnit: treatment?.waterUnit || undefined,
	causeIds: treatment?.treatmentCauses?.map(({ id }) => id) || undefined,
	withholdingPeriod: treatment?.withholdingPeriod || undefined,
	maxApplicationsVegetation: treatment.maxApplicationsVegetation || undefined,
	maxApplicationsCategory: treatment.maxApplicationsCategory || undefined,
	maxApplicationsCause: treatment.maxApplicationsCause || undefined,
	applicationInterval: treatment.applicationInterval || undefined,
	maxVolume: treatment.maxVolume || undefined,
	plantMilestones: treatment.plantMilestones || undefined,
	registrationNumber: treatment.registrationNumber || undefined,
	licenseExpirationDate: treatment.licenseExpirationDate || undefined,
	areaOfApplication: treatment.areaOfApplication || undefined,
	cultivationType: treatment.cultivationType || undefined,
	regulations: treatment.regulations || undefined,
	fieldsOfUse: treatment.fieldsOfUse || undefined,
	governmentTreatmentId: treatment.governmentTreatmentId || null,
});
