// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bMlWS_i6J-bgCO_VkM0nW {\n  margin: 1rem -1.5rem -0.5rem;\n  overflow: hidden;\n}\n\n.bMlWS_i6J-bgCO_VkM0nW hr:last-child {\n  display: none;\n}\n\n._3KMpQhU49KeHfCGZlMuOhq {\n  margin-bottom: 1.5rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"customersSection": "bMlWS_i6J-bgCO_VkM0nW",
	"addCustomerCard": "_3KMpQhU49KeHfCGZlMuOhq"
};
module.exports = exports;
