import React from 'react';

import { ExportType } from 'Models/exports/ExportType';

import useExportType from 'Hooks/useExportType';

import ExportToFile from './components/ExportToFile';
import ExportToPDF from './components/ExportToPDF';

function JobsExportPage() {
	const type = useExportType();
	if (type === ExportType.CSV || type === ExportType.XLSX) {
		// todo: keep in mind we want to reuse this compoenent for every file export,
		// refactor when finished, only using different data parsing functions
		return <ExportToFile type={type} />;
	}

	if (type === ExportType.PDF) {
		return <ExportToPDF />;
	}

	return null;
}

export default JobsExportPage;
