import { URLParamsType } from 'Models/history/isURLParams';

function getQueryVariables(searchParams: URLParamsType) {
	const paramsFilters = searchParams.filters;

	return {
		filterBy: {
			dateFrom: paramsFilters.dateFrom,
			dateTo: paramsFilters.dateTo,
			fieldCategories: paramsFilters.fieldCategories,
			fieldVarieties: paramsFilters.fieldVarieties,
			fieldIds: paramsFilters.fieldIds,
			teamMemberIds: paramsFilters.teamMemberIds,
		},
		locationID: searchParams.locationID,
		pageInput: searchParams.pageInput,
		hasPagination: true,
	};
}

export default getQueryVariables;
