import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { TrackEvents, analyticsTrack } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useLocationID from 'Hooks/useLocationID';
import useProfile from 'Hooks/useProfile';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import DELETE_TEAM from '../api/deleteTeam';
import GET_TEAMS from '../api/getTeams';
import styles from './styles.module.scss';

type TProps = {
	teamId: string;
};

function DeleteTeamModal(props: TProps) {
	const { teamId } = props;
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const locationId = useLocationID();
	const { profile } = useProfile();

	const [deleteTeam, deleteTeamTask] = useMutation(DELETE_TEAM, {
		update(cache, { data }) {
			const query = {
				query: GET_TEAMS,
				variables: { locationId },
			};
			const deletedTeam = data?.deleteTeam;
			const existingTeams = cache.readQuery(query)?.getTeams;

			if (existingTeams && deletedTeam) {
				cache.writeQuery({
					...query,
					data: {
						getTeams: [
							...existingTeams.filter((team) => team.id !== deletedTeam.id),
						],
					},
				});
			}
		},
	});

	const isDeleting = deleteTeamTask.loading;

	useSnackbarNotifications({
		mutationResult: deleteTeamTask,
		messageSuccess: t('info.deleteTeamSuccess'),
		messageFailure: t('errors.deleteTeamError'),
	});

	function toggleOpen() {
		setOpen(!open);
	}

	function handleDelete() {
		if (profile && locationId) {
			analyticsTrack(TrackEvents.TeamDeleted, {
				userId: profile.id,
				groupId: locationId,
			});
		}

		void deleteTeam({
			variables: { locationId, teamId },
		});
		toggleOpen();
	}

	return (
		<>
			<Menu.Item onClick={toggleOpen}>
				<Text color="error" variant="body2">
					{t('buttons.deleteTeam')}
				</Text>
			</Menu.Item>
			<Modal.UncontrolledModal toggleOpen={toggleOpen} open={open}>
				<Modal.DialogTitle
					title={t('teamsPage.deleteTeamModal.title')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					<p className={styles.modalContent}>
						{t('teamsPage.deleteTeamModal.message')}
					</p>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Button
						variant={'text'}
						onClick={toggleOpen}
						className={styles.backButton}
					>
						{t('buttons.back')}
					</Button>
					<Button
						variant="outlined"
						startIcon={isDeleting ? <LoadingIcon /> : null}
						disabled={isDeleting}
						onClick={handleDelete}
					>
						{t('buttons.delete')}
					</Button>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}

export default DeleteTeamModal;
