import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TLocation } from 'Models/locations/isLocation';
import multilineLocationAddress from 'Models/locations/multilineLocationAddress';
import translateRoles from 'Models/roles/translateRoles';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Card from 'UI/surfaces/Card';

import useProfile from 'Hooks/useProfile';
import useProfileRole from 'Hooks/useProfileRole';

type FarmCardProps = {
	location: Pick<
		TLocation,
		'address' | 'name' | 'city' | 'country' | 'zip'
	> | null;
};

const SprayLogInfoCard = (props: FarmCardProps) => {
	const { location } = props;
	const role = useProfileRole();
	const { profile } = useProfile();
	const { t } = useTranslation();

	return (
		<Card
			sx={{
				height: '100%',
			}}
		>
			<Box padding="1.5rem">
				<Text variant="label2">{location?.name}</Text>
				<Text variant="body2">
					{profile?.firstName} {profile?.lastName} ({translateRoles(role, t)})
				</Text>
				{location && (
					<Text variant="body2">{multilineLocationAddress(location)} </Text>
				)}
			</Box>
		</Card>
	);
};

export default SprayLogInfoCard;
