import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import z from 'zod';

const _isCoordinatePoint = isShape({
	x: isNumber,
	y: isNumber,
});

// https://github.com/microsoft/TypeScript/issues/34596
export const isCoordinatePoint: typeof _isCoordinatePoint = _isCoordinatePoint;

export const CoordinatePointSchema = z.object({
	x: z.number(),
	y: z.number(),
});

export type TCoordinatePoint = z.infer<typeof CoordinatePointSchema>;
