import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import fieldsBoundingBox from 'Models/fields/fieldsBoundingBox';
import getTotalFieldsArea from 'Models/fields/getTotalFieldsArea';
import harvestFieldsNameString from 'Models/harvest/harvestFieldsNameString';
import { THarvest } from 'Models/harvest/types/Harvest';
import Permissions from 'Models/permissions/Permissions';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Grid from 'UI/layout/Grid';
import Map from 'UI/maps/Map';
import GMapLayer from 'UI/maps/layers/GMapLayer';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';

import DetailModalSection from '../../../../../../components/ModalSection';
import FieldPolygon from '../../../../../../components/fields/FieldPolygon';
import {
	GET_FIELDS_GEOMETRY_QUERY,
	GET_FIELDS_GEOMETRY_ADVISOR_QUERY,
} from '../../../api/getFieldsGeometryData';
import styles from '../styles.module.scss';

type TFieldsProps = {
	harvest: Pick<THarvest, 'fields'>;
};

const prefix = 'logsPages.harvestLogsPage.detailsModal.';

const Fields = (props: TFieldsProps) => {
	const { harvest } = props;
	const { t } = useTranslation();
	const locationId = useLocationID();
	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY
	);
	const { data, loading, error } = useQuery(
		hasPermissionsToRegularQuery
			? GET_FIELDS_GEOMETRY_QUERY
			: GET_FIELDS_GEOMETRY_ADVISOR_QUERY,
		{
			variables: {
				locationId: locationId,
			},
		}
	);

	if (loading) {
		return <LoadingSpinner width={40} height={40} />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	const fields = data?.getFields.fields;

	if (fields) {
		const harvestFields = harvest.fields;
		const harvestFieldsId = harvestFields.map((field) => field.id);
		const activeFields = fields.filter((field) =>
			harvestFieldsId.includes(field.id)
		);
		const fieldLayers = fields.map((field) => (
			<FieldPolygon
				field={field}
				key={field.id}
				status={harvestFieldsId.includes(field.id) ? 'FOCUSED' : 'NOT_FOCUSED'}
			/>
		));
		const boundingBox = fieldsBoundingBox(
			activeFields.length ? activeFields : fields
		);

		return (
			<DetailModalSection title={t(`${prefix}sections.fields`)} noDivider>
				<Text className={styles.fieldInformation}>
					{`${harvestFieldsNameString(harvest)} · ${t(
						`${prefix}props.totalArea`
					)} ${getTotalFieldsArea(harvestFields)} m²
				`}
				</Text>
				<Grid item className={styles.mapContainer}>
					<Map.Controlled
						bounds={boundingBox}
						boundsOptions={{ padding: [50, 50] }}
						maxBoundsViscosity={1}
						roundedCorners
					>
						<>
							{fieldLayers}
							<GMapLayer />
						</>
					</Map.Controlled>
				</Grid>
			</DetailModalSection>
		);
	}

	return null;
};

export default Fields;
