import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TField } from 'Models/fields/isField';
import { TFieldVariety } from 'Models/fields/isFieldVariety';
import { TLocation } from 'Models/locations/isLocation';

export type TPartnerFieldsResponse = {
	getPartnerFields: {
		fields: {
			field: Pick<TField, 'id' | 'name'> & {
				varieties: Pick<TFieldVariety, 'name' | 'category'>[];
			};
			location: Pick<TLocation, 'id' | 'name'>;
		}[];
	};
};

type TGetPartnerFieldsInput = {
	filterBy?: {
		locationIds?: string[] | null;
	};
	pageInput?: TPageInput;
};

const GET_PARTNER_FIELDS: TypedDocumentNode<
	TPartnerFieldsResponse,
	TGetPartnerFieldsInput
> = gql`
	query getPartnerFields(
		$filterBy: PartnerFieldFilterInput
		$pageInput: PageInput
	) {
		getPartnerFields(filterBy: $filterBy, pageInput: $pageInput) {
			fields {
				field {
					id
					name
					varieties {
						category
						name
					}
				}
				location {
					id
					name
				}
			}
		}
	}
`;

export default GET_PARTNER_FIELDS;
