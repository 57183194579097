import { gql } from '@apollo/client';

export const GET_PARTNER_SALES_TABLE_DATA = gql`
	query getPartnerSalesData(
		$filterBy: PartnerSaleFilterInput
		$pageInput: PageInput
	) {
		getPartnerSales(filterBy: $filterBy, pageInput: $pageInput) {
			totalPages
			totalElements
			content {
				sale {
					id
					status
					date
					customer {
						id
						name
						archived
						hasSales
						country
						city
						zip
						address
						image {
							id
							path
							filename
							mimetype
							createdAt
						}
						verified
						companyId
					}
					variety
					batchAllocation
					batches {
						batchId
						volume
						date
						variety
						field {
							id
							name
							varieties {
								category
							}
						}
						status
					}
					volume
					boxSize
					boxVolume
					boxName
					countingUnit
					estimatedPrice
					currency
					quality
					comment
					images {
						id
						path
						filename
						mimetype
						createdAt
					}
				}
				location {
					id
					name
					status
					areaUnit
					address
					city
					zip
					country
					coordinate {
						x
						y
					}
					farmOwner {
						firstName
						lastName
						email
					}
				}
			}
		}
	}
`;
