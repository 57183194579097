import { gql, TypedDocumentNode } from '@apollo/client';
import { TFieldVariety } from 'Models/fields/isFieldVariety';

export type TFieldCategory = {
	id: string;
	name: string;
	varieties: Pick<TFieldVariety, 'id' | 'name' | 'category'>[];
};

type TGetFieldsInput = {
	locationId: string;
};

export type TRegularFieldsResponse = {
	getFields: {
		fields: TFieldCategory[];
	};
};

export type TAdvisorFieldsResponse = {
	getFieldsByUsersFieldCategories: TFieldCategory[];
};

export const GET_FIELDS_QUERY: TypedDocumentNode<
	TRegularFieldsResponse,
	TGetFieldsInput
> = gql`
	query getFieldsCategories($locationId: ID!) {
		getFields(locationId: $locationId) {
			fields {
				id
				name
				varieties {
					id
					name
					category
				}
			}
		}
	}
`;

export const GET_FIELDS_ADVISOR_QUERY: TypedDocumentNode<
	TAdvisorFieldsResponse,
	TGetFieldsInput
> = gql`
	query getFieldsCategories($locationId: ID!) {
		getFieldsByUsersFieldCategories(locationId: $locationId) {
			id
			name
			varieties {
				id
				name
				category
			}
		}
	}
`;
