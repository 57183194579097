import getAccountName from '../account/getAccountName';
import { THarvestWithTeams } from './isHarvestWithTeams';

function harvestTeamMembersNameString(
	harvest: Pick<THarvestWithTeams, 'teamMembers' | 'teamLeads'>,
	hasActiveTimesheetsSubscription?: boolean,
	isExport?: boolean
): string {
	if (!hasActiveTimesheetsSubscription && !isExport) {
		return 'N/A';
	}
	const teamMembers = (harvest.teamMembers || []).map(
		(teamMembers) => teamMembers.fullName
	);
	const teamLeads = (harvest.teamLeads || []).map((teamLead) =>
		getAccountName(teamLead)
	);

	const members = [...teamLeads, ...teamMembers].filter(
		(member): member is string => typeof member !== 'undefined'
	);

	return members.join(isExport ? '\n' : ', ') || '-';
}

export default harvestTeamMembersNameString;
