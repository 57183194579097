import isOneOf from 'vet/isOneOf';
import { ExtendedValidator } from 'vet/types';

export const ExportType = {
	PDF: 'PDF',
	XLSX: 'XLSX',
	CSV: 'CSV',
} as const;

export type TExportType = keyof typeof ExportType;

const _isExportType = isOneOf(
	...Object.values(ExportType)
) as ExtendedValidator<TExportType>;

export const isExportType: typeof _isExportType = _isExportType;
