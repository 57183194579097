import React, { ReactElement } from 'react';

import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';

type TLoadingMessageProps = {
	message?: string;
	children?: ReactElement;
};

const LoadingMessage = (props: TLoadingMessageProps) => {
	const { children, message } = props;
	return (
		<Box padding={2} display="flex" flexDirection="column" alignItems="center">
			<LoadingSpinner width={64} height={64} />
			{message ? (
				<Box padding={2} textAlign="center">
					<Text variant="h2">{message}</Text>
				</Box>
			) : null}
			{children}
		</Box>
	);
};

export default LoadingMessage;
