import { URLParamsType } from 'Models/history/isURLParams';
import { TPartnerVariety } from 'Models/planner/PartnerVariety';

const getPartnerVarietyFromURL = (
	paramsFromURL: URLParamsType
): TPartnerVariety | null => {
	const paramsFilters = paramsFromURL.filters;

	return paramsFilters.partnerVarietyName &&
		paramsFilters.partnerVarietyCategory
		? {
				name: paramsFilters.partnerVarietyName,
				category: paramsFilters.partnerVarietyCategory,
		  }
		: null;
};

export default getPartnerVarietyFromURL;
