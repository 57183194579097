import { gql, TypedDocumentNode } from '@apollo/client';
import { TTimesheetSettings } from 'Models/timesheet/isTimesheetSettings';

const GET_TIMESHEET_SETTINGS_DATA: TypedDocumentNode<
	{
		getTimesheetSettings: TTimesheetSettings;
	},
	{ locationId: string }
> = gql`
	query GetTimesheetSettings($locationId: ID!) {
		getTimesheetSettings(locationId: $locationId) {
			subtractLunchTime
			minDurationForSubtractingLunch
			lunchTimeDuration
			pricePerHour
			currency
		}
	}
`;

export default GET_TIMESHEET_SETTINGS_DATA;
