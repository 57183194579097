import { TJob } from './isJob';
import { TJobTreatment } from './isJobTreatment';

type TJobTreatmentWithreEntryInterval = TJobTreatment & {
	originalProduct: {
		productSpec: {
			reEntryInterval: number;
		};
	};
};

function isJobTreatmentWithreEntryInterval(
	treatment: Pick<TJobTreatment, 'amount' | 'unit' | 'originalProduct'>
): treatment is TJobTreatmentWithreEntryInterval {
	return (
		typeof treatment.originalProduct?.productSpec?.reEntryInterval === 'number'
	);
}

function jobReEntryInterval(job: {
	treatments: Pick<TJobTreatment, 'amount' | 'unit' | 'originalProduct'>[];
}): number | null {
	const { treatments } = job;

	if (treatments.length) {
		const reEntryIntervalArray = treatments
			.filter(isJobTreatmentWithreEntryInterval)
			.map(
				(treatment) => treatment.originalProduct.productSpec.reEntryInterval
			);

		return reEntryIntervalArray.length
			? Math.max(...reEntryIntervalArray)
			: null;
	} else {
		return null;
	}
}

export default jobReEntryInterval;
