import React from 'react';

import Divider from '../../display/Divider';

const SidebarDivider = () => (
	<span>
		<Divider />
	</span>
);

export default SidebarDivider;
