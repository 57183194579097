import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { addJobModalState } from '../state';
import CauseAndTreatments from './CauseAndTreatments';
import DateAndComment from './DateAndComment';
import LocationsStep from './LocationsStep';

function ModalContent() {
	const { status } = useReactiveVar(addJobModalState);

	switch (status) {
		case 'SET_CAUSE_AND_TREATMENTS':
			return <CauseAndTreatments />;
		case 'SET_DATE_AND_COMMENT':
			return <DateAndComment />;
		case 'CREATE_AND_SHARE_LOCATIONS':
			return <LocationsStep />;
		default:
			return <CauseAndTreatments />;
	}
}

export default ModalContent;
