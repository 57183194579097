import { gql, TypedDocumentNode } from '@apollo/client';

type TRevokeTreatmentLibraryAccessResult = {
	revokeTreatmentLibraryAccess: boolean;
};

type TRevokeTreatmentLibraryAccessInput = {
	libraryId: string;
	locationId: string;
};

const REVOKE_TREATMENT_LIBRARY_ACCESS: TypedDocumentNode<
	TRevokeTreatmentLibraryAccessResult,
	TRevokeTreatmentLibraryAccessInput
> = gql`
	mutation revokeTreatmentLibraryAccess($libraryId: ID!, $locationId: ID!) {
		revokeTreatmentLibraryAccess(libraryId: $libraryId, locationId: $locationId)
	}
`;

export default REVOKE_TREATMENT_LIBRARY_ACCESS;
