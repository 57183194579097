import React, { ReactElement, ReactNode } from 'react';

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { FallbackRender } from '@sentry/react/types/errorboundary';

import DefaultFallbackComponent from './FallbackComponent';

export type ErrorBoundaryProps = {
	children: ReactNode;
	fallback?: FallbackRender;
	hideOnError?: boolean;
	fullHeight?: boolean;
	withLogout?: boolean;
	withinComponent?: boolean;
};

function ErrorBoundaryWrapper(props: ErrorBoundaryProps): ReactElement {
	const { children, fallback, ...other } = props;
	const FallbackComponent =
		'hideOnError' in props ? () => <></> : fallback || DefaultFallbackComponent;

	return (
		<SentryErrorBoundary
			fallback={(e) => <FallbackComponent {...e} {...other} />}
		>
			{children}
		</SentryErrorBoundary>
	);
}

export default ErrorBoundaryWrapper;
