import React, { ReactElement } from 'react';

import { TTranslateFunction } from 'Features/localization';
import jobAreaWithUnit from 'Models/jobs/jobAreaWithUnit';
import jobAuthorNameString from 'Models/jobs/jobAuthorNameString';
import jobCategoriesString from 'Models/jobs/jobCategoriesString';
import jobCausesString from 'Models/jobs/jobCausesString';
import jobDateCompletedString from 'Models/jobs/jobDateCompletedString';
import jobProduct from 'Models/jobs/jobProduct';
import jobStatusColor from 'Models/jobs/jobStatusColor';
import jobTreatment from 'Models/jobs/jobTreatment';
import translateJobStatus from 'Models/jobs/translateJobStatus';
import { AreaUnits } from 'Models/units';
import Chip from 'UI/display/Chip';

import { TPartnerJobTableData } from '../api/getPartnerJobsTableData';

function getPartnerJobRowData(
	job: TPartnerJobTableData,
	t: TTranslateFunction
) {
	const jobStatus: ReactElement = (
		<Chip
			chipColor={jobStatusColor(job)}
			dense
			label={translateJobStatus(job.jobStatus, t, true).toUpperCase()}
		/>
	);

	return Object.values({
		completedDate: jobDateCompletedString(job),
		category: jobCategoriesString(job, t),
		farm: job.location.name,
		area: jobAreaWithUnit(job, { areaUnit: AreaUnits.HECTARE }),
		cause: jobCausesString(job),
		treatmentType: jobTreatment(job, t),
		product: jobProduct(job),
		recommendedBy: jobAuthorNameString(job),
		jobStatus,
	});
}

export default getPartnerJobRowData;
