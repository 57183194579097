import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Modal from 'UI/display/modal/dialog';

import FallbackComponent from './FallbackComponent';

type TProps = {
	error: Error;
	eventId: string;
};

const ErrorModal = (props: TProps): ReactElement => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(true);
	const handleClose = () => setIsOpen(false);

	return (
		<Modal.UncontrolledModal open={isOpen}>
			<Modal.DialogTitle
				title={t('labels.error')}
				closeIconHandler={handleClose}
			/>
			<Modal.DialogContent>
				<FallbackComponent {...props} />
			</Modal.DialogContent>
			<Modal.DialogActions></Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default ErrorModal;
