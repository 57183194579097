import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductInput } from 'Models/products/ProductInput';
import { ProductUnits } from 'Models/units';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import _uniqueId from 'lodash/uniqueId';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../state';
import Ingredient from './Ingredient';

const Composition = () => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { input, status } = state;

	const isDisabled =
		Boolean(selectedProduct) || input.selectedGovernmentProduct;

	if (status === 'SET_PRODUCT_DETAILS') {
		const product = input.product;
		const hasIngredients = 'ingredients' in product;
		const ingredients =
			selectedProduct?.ingredients || (hasIngredients && product.ingredients);

		const onAddIngredient = () => {
			if (!input.selectedGovernmentProduct) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: [
								...((input.product as TProductInput).ingredients || []),
								{
									id: _uniqueId(),
									name: '',
									active: false,
									amount: 0,
									unit: ProductUnits.GRAM_PER_LITER_WATER,
									registrationNumber: '',
									groupName: '',
								},
							],
						} as TProductInput,
					},
				});
			}
		};

		const ingredientsItems = ingredients
			? ingredients
					.filter((ingredient) => ingredient)
					.map((ingredient) => (
						<Ingredient key={ingredient.id} ingredient={ingredient} />
					))
			: [];

		return (
			<ModalSection title={t('labels.composition')}>
				{ingredientsItems}
				<Box marginTop="1rem">
					<Button
						variant="secondary"
						color="primary"
						startIcon={<AddIcon />}
						onClick={onAddIngredient}
						disabled={isDisabled}
					>
						{t('buttons.addIngredient')}
					</Button>
				</Box>
			</ModalSection>
		);
	}

	return null;
};

export default Composition;
