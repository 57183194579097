export default (p1, p2) => {
	let check = p1.unit.localeCompare(p2.unit);
	if (check != 0) {
		return check;
	}
	check = p2.amount - p1.amount;
	if (check != 0) {
		return check;
	}
	check = p1.name.localeCompare(p2.name);
	return check;
};
