import { TPaymentProductPrice } from './isPaymentProduct';

const DEFAULT_DIVISOR = 100;

function stripePriceUnitAmountString(
	stripePrice: TPaymentProductPrice,
	divisor = DEFAULT_DIVISOR
): string {
	const float = stripePrice.unit_amount / divisor;
	return float.toFixed(2);
}

export default stripePriceUnitAmountString;
