import React, { useState } from 'react';

import { TTranslateFunction } from 'Features/localization';
import {
	PartnershipLocationStatus,
	TPartnershipLocationStatus,
} from 'Models/partnershipCompany/isPartnerLocation';
import Radio from 'UI/inputs/Radio';
import RadioGroup from 'UI/inputs/RadioGroup';
import Box from 'UI/layout/Box';
import { FarmableTagsColors } from 'UI/theme/Colors';
import { useTranslation } from 'react-i18next';

import usePartnerLocations from 'Hooks/usePartnerLocations';

import { TGetLocationsByEmailsSingleResult } from './api/getLocationsByEmails';

function getRadioLabel(
	t: TTranslateFunction,
	name: string,
	locationStatus?: TPartnershipLocationStatus
) {
	return locationStatus
		? `${name} (${t(`common.partnershipLocationStatus.${locationStatus}`)})`
		: name;
}

//each list needs callback to handle chosen location
const FarmsList = (props: {
	taskSingleResult: TGetLocationsByEmailsSingleResult;
	onChange: (val: string, label: string) => void;
	activeValue?: string;
}) => {
	const { taskSingleResult, onChange, activeValue } = props;
	const { partnerLocations } = usePartnerLocations();
	const { t } = useTranslation();
	const [value, setValue] = useState(activeValue || '');
	const farms = taskSingleResult.locations.map(({ id, name }) => {
		const invitedLocation = partnerLocations?.find((loc) => loc.id === id);

		const isEnabled =
			!invitedLocation ||
			invitedLocation?.status === PartnershipLocationStatus.PENDING;

		return (
			<Radio
				key={id}
				id={name}
				value={id}
				label={getRadioLabel(t, name, invitedLocation?.status)}
				checked={value === id}
				disabled={!isEnabled}
			/>
		);
	});

	return (
		<Box
			marginTop="1.5rem"
			sx={{
				backgroundColor: FarmableTagsColors.LIGHT_GREEN,
				borderRadius: '0.5rem',
				padding: '0.5rem 1rem',
			}}
		>
			<RadioGroup.Controlled
				label={taskSingleResult.email}
				value={value}
				valueChangeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
					setValue(event.target.value);
					onChange(event.target.value, event.target.id);
				}}
			>
				{farms}
			</RadioGroup.Controlled>
		</Box>
	);
};

export default FarmsList;
