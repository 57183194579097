import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TPartnerField } from 'Models/fields/isPartnerField';

export type TPartnerLocationFieldsResponse = {
	getPartnerFields: {
		fields: TPartnerField[];
	};
};
export type TPartnerLocationFieldsRequest = {
	filterBy: { locationIds: string[] };
	pageInput?: TPageInput;
};

const GET_PARTNER_HARVEST_MODAL_FIELDS_DATA: TypedDocumentNode<
	TPartnerLocationFieldsResponse,
	TPartnerLocationFieldsRequest
> = gql`
	query getPartnerFieldShapes(
		$filterBy: PartnerFieldFilterInput
		$pageInput: PageInput
	) {
		getPartnerFields(filterBy: $filterBy, pageInput: $pageInput) {
			fields {
				field {
					id
					name
					areaSize
					geometry {
						x
						y
					}
				}
			}
		}
	}
`;

export default GET_PARTNER_HARVEST_MODAL_FIELDS_DATA;
