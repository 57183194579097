import { FieldTypes, TFieldType } from 'Models/fields/isFieldType';
import { TFieldVarietyInput } from 'Models/fields/isFieldVarietyInput';
import EMPTY_VARIETY from 'Models/jobs/mocks/mockEmptyVariety';
import uniqueId from 'lodash/uniqueId';

import getVarietiesWithPercentages from '../../../../utils/getVarietiesWithPercentages';

/**
 * Function which returns varieties with the same order after editing.
 * @param {TFieldVarietyInput[]} [varieties] - array of varieties
 * @param {number} [index] - index of variety to edit.
 * @param {TFieldVarietyInput} [variety] - the new edited variety
 * @returns {TFieldVarietyInput[]}
 */
export const getEditedVarieties = (
	varieties: TFieldVarietyInput[],
	index: number,
	newVariety: Partial<TFieldVarietyInput>
): TFieldVarietyInput[] => {
	const oldVariety = varieties[index];
	return varieties.map((variety, i) =>
		index === i
			? {
					...oldVariety,
					...newVariety,
			  }
			: variety
	);
};

/**
 * Function which removes a variety with a given index.
 * It also overrides variety's percentage when there's only
 * one remaining variety.
 * @param {TFieldVarietyInput[]} [varieties] - array of varieties
 * @param {function} [setVariety] - useState's callback function to set varieties
 * @param {number} [index] - index of variety to remove.
 */
export const removeVariety = (
	varieties: TFieldVarietyInput[],
	setVarieties: (varieties: TFieldVarietyInput[]) => void,
	index: number
): void => {
	const newVarieties = varieties.filter((variety, i) => index !== i);

	if (newVarieties.length === 1) {
		newVarieties[0].percentage = 100;
	}

	setVarieties(getVarietiesWithPercentages(newVarieties));
};

/**
 * Function which adds the new empty variety into varieties
 * passed to the function.
 * @param {TFieldVarietyInput[]} [varieties] - array of varieties
 * @param {function} [setVariety] - useState's callback function to set varieties
 */
export const addVariety = (
	varieties: TFieldVarietyInput[],
	setVarieties: (varieties: TFieldVarietyInput[]) => void,
	category?: string
): void => {
	const newVariety = {
		...EMPTY_VARIETY,
		category: category || '',
		_id: uniqueId(),
	};
	const newVarieties = [...varieties, newVariety];
	setVarieties(getVarietiesWithPercentages(newVarieties));
};

/**
 * Function which indicates whether to reinitialize (or not) the varieties.
 * It should return `false` when the field type changes from
 * percentage type varieties.
 * @param {TFieldType} [previousFieldType] - previously set field type
 * @param {TFieldType} [newFieldType] - the field type to set
 * @returns {boolean}
 */
export const shouldResetVarieties = (
	previousFieldType: TFieldType,
	newFieldType: TFieldType
): boolean => {
	const percentageVarieties: TFieldType[] = [
		FieldTypes.ROWS,
		FieldTypes.FIELD,
		FieldTypes.ECO_ZONE,
	];

	return !(
		percentageVarieties.includes(newFieldType) &&
		percentageVarieties.includes(previousFieldType)
	);
};
