import { TTeam } from 'Models/teams/isTeam';
import _remove from 'lodash/remove';

import populateMembersPositions from './populateMembersPositions';

function moveMemberBetweenTeams(
	sourceIndex,
	sourceTeam: TTeam,
	destinationIndex
): TTeam;
function moveMemberBetweenTeams(
	sourceIndex,
	sourceTeam: TTeam,
	destinationIndex,
	destinationTeam: TTeam
): TTeam[];
function moveMemberBetweenTeams(
	sourceIndex,
	sourceTeam: TTeam,
	destinationIndex,
	destinationTeam?: TTeam
): TTeam | TTeam[] {
	const sourceTeamMembers = [...sourceTeam.teamMembers];
	const [removed] = _remove(
		sourceTeamMembers,
		(member) => member.position === sourceIndex
	);

	if (destinationTeam) {
		const destinationTeamMembers = [...destinationTeam.teamMembers];
		destinationTeamMembers.splice(destinationIndex, 0, removed);
		return [
			{
				...sourceTeam,
				teamMembers: populateMembersPositions(sourceTeamMembers),
			},
			{
				...destinationTeam,
				teamMembers: populateMembersPositions(destinationTeamMembers),
			},
		];
	}

	sourceTeamMembers.splice(destinationIndex, 0, removed);
	const editedSourceTeam = {
		...sourceTeam,
		teamMembers: populateMembersPositions(sourceTeamMembers),
	};
	return editedSourceTeam;
}

export default moveMemberBetweenTeams;
