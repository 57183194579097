import format from 'date-fns/format';

import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const jobDateStartString = (job: Pick<TJob, 'startDate'>): string => {
	return job.startDate != null
		? format(toLocalDate(job.startDate), 'dd.MM.yy')
		: '-';
};

export default jobDateStartString;
