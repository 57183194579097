import { initializeApp } from 'firebase/app';
import {
	fetchAndActivate,
	getRemoteConfig,
	getValue,
} from 'firebase/remote-config';

// Your web app's Firebase configuration
const firebaseProdConfig = {
	apiKey: 'AIzaSyAQV4jw5L_li1Bl5vHUbD1_7C5heKk0060',
	authDomain: 'farmable-prod.firebaseapp.com',
	databaseURL: 'https://farmable-prod.firebaseio.com',
	projectId: 'farmable-prod',
	storageBucket: 'farmable-prod.appspot.com',
	messagingSenderId: '586543698401',
	appId: '1:586543698401:web:e76f1a349f9b046ed682a0',
};

const firebaseDevConfig = {
	apiKey: 'AIzaSyDUH6z7_ZPun7JyjvOjENJuUrLi-rOxNNQ',
	authDomain: 'farmable-218511.firebaseapp.com',
	databaseURL: 'https://farmable-218511.firebaseio.com',
	projectId: 'farmable-218511',
	storageBucket: 'farmable-218511.appspot.com',
	messagingSenderId: '506809487583',
	appId: '1:506809487583:web:2ca4af15780be6bf2b996a',
};

// Initialize Firebase
const app = initializeApp(
	process.env.ENVIRONMENT === 'production'
		? firebaseProdConfig
		: firebaseDevConfig
);

// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app);

if (process.env.ENVIRONMENT !== 'production') {
	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
}

// default config works as a fallback when remote data is not available
// useful for non-boolean values, for booleans we return false
remoteConfig.defaultConfig = {};

// this function applies remote config on top of the default
fetchAndActivate(remoteConfig).catch((err) => {
	window.console.log(err);
});

export const getConfigValue = (key: string) => getValue(remoteConfig, key);

export default app;
