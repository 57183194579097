import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldVarietyInput } from 'Models/fields/isFieldVarietyInput';
import SubtractIcon from 'UI/icons/Subtract';
import IconButton from 'UI/inputs/IconButton';
import TextField from 'UI/inputs/TextField';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import getDebounce from 'Utils/getDebounce';
import numberToDisplayString from 'Utils/numberToDisplayString';

import getVarietiesWithPercentages from '../../../../utils/getVarietiesWithPercentages';
import isValidPercentageInput from '../../../../utils/isValidPercentage';
import styles from '../../styles.module.scss';
import { getEditedVarieties, removeVariety } from '../utils';

const debounced = getDebounce();

type TPercentageVarietyProps = {
	variety: TFieldVarietyInput;
	isSingle: boolean;
	varieties: TFieldVarietyInput[];
	itemIndex: number;
	setVarieties: (varieties: TFieldVarietyInput[]) => void;
	error?: boolean;
};

const useStyles = makeStyles({
	iconButton: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: FarmableColors.BLACK_12,
	},
});

function PercentageVariety(props: TPercentageVarietyProps): ReactElement {
	const { variety, isSingle, error, itemIndex, varieties, setVarieties } =
		props;
	const [inputVarietyName, setInputVarietyName] = useState(variety.name || '');
	const [inputVarietyPercentage, setInputVarietyPercentage] = useState(
		numberToDisplayString(variety.percentage)
	);

	const varietiesLen = varieties.length;
	const MUIStyles = useStyles();
	const { t } = useTranslation();

	const handleNameChange = (name) => {
		const newVarieties = getEditedVarieties(varieties, itemIndex, { name });
		const updateInput = () => setVarieties(newVarieties);

		debounced(updateInput);
		setInputVarietyName(name);
	};

	const handleDeleteVariety = () =>
		removeVariety(varieties, setVarieties, itemIndex);

	const handlePercentageChange = (newValue) => {
		if (isValidPercentageInput(newValue)) {
			const parsedPercents = parseInt(newValue);
			const varietiesToReCalculate = varietiesLen - 1;
			const requireRecalculation =
				parsedPercents > 100 - varietiesToReCalculate || varietiesLen === 2;

			const editedVarieties = getEditedVarieties(varieties, itemIndex, {
				percentage: parsedPercents,
			});

			const newVarieties = requireRecalculation
				? getVarietiesWithPercentages(editedVarieties, itemIndex)
				: editedVarieties;

			const updateInput = () => setVarieties(newVarieties);

			debounced(updateInput);
			setInputVarietyPercentage(newValue);
		}
	};

	return (
		<Grid container item spacing={3} alignItems="center">
			<Grid item xs={6.5}>
				<TextField
					label={t('labels.variety')}
					value={inputVarietyName}
					onChange={handleNameChange}
					className={styles.resetLabelMargin}
				/>
			</Grid>
			<Grid item xs={2.5}>
				<TextField
					value={inputVarietyPercentage}
					disabled={isSingle}
					type="number"
					error={error}
					onChange={handlePercentageChange}
					className={styles.resetLabelMargin}
					InputProps={{
						inputProps: {
							step: 1,
							min: 1,
							max: 100,
							maxLength: 3,
						},
					}}
				/>
			</Grid>
			<Grid item xs={1} className={styles.percentageSign}>
				%
			</Grid>
			<Grid item xs={2}>
				<IconButton
					onClick={handleDeleteVariety}
					disabled={isSingle}
					size="small"
					className={MUIStyles.iconButton}
				>
					<SubtractIcon
						fontSize="small"
						color={isSingle ? 'disabled' : 'action'}
					/>
				</IconButton>
			</Grid>
		</Grid>
	);
}

export default PercentageVariety;
