import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { Trans } from 'Features/localization/components/Trans';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Box from 'UI/layout/Box';
import Page from 'UI/layout/Page';
import { FarmableColors } from 'UI/theme/Colors';

import NoAccessPageTemplate from '../../../pages/no-access/NoAccessPageTemplate';

const AuthFailedPage = () => {
	const { logout, user } = useAuth0();
	const { t } = useTranslation();

	const returnTo =
		process.env.AUTH0_LOGOUT_URI || window.location.origin + '/logout.html';

	const triggerLogout = () => {
		analyticsTrack(TrackEvents.SignedOut, { userId: user?.id });
		void logout({ logoutParams: { returnTo } });
	};

	const logOut = (
		<a
			onClick={triggerLogout}
			style={{
				textDecoration: 'underline',
				color: FarmableColors.ORANGE,
				cursor: 'pointer',
			}}
		>
			here
		</a>
	);

	return (
		<Page center>
			<NoAccessPageTemplate
				title={t('authFailedPage.title')}
				description={t('authFailedPage.message')}
			>
				<Box>
					<Trans i18nKey={'authFailedPage.logout'}>
						Click {logOut} to go back to login page.
					</Trans>
				</Box>
			</NoAccessPageTemplate>
		</Page>
	);
};

export default AuthFailedPage;
