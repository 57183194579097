import React, { useEffect, useState } from 'react';

import { useMutation, useLazyQuery, ApolloError } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Button, { TButtonProps } from 'UI/inputs/Button';
import TextInputWithTags from 'UI/inputs/TextInputWithTags';
import Box from 'UI/layout/Box';
import { validateEmail } from 'UI/utils/validateEmail';
import _uniq from 'lodash/uniq';
import _uniqueId from 'lodash/uniqueId';
import { enqueueSnackbar } from 'notistack';

import useCompanyID from 'Hooks/useCompanyID';
import asyncThrottle from 'Utils/asyncThrottle';
import throwErrorSilently from 'Utils/throwErrorSilently';

import FarmsList from './FarmsList';
import GET_LOCATIONS_BY_EMAILS, {
	TGetLocationsByEmailsSingleResult,
} from './api/getLocationsByEmails';
import INVITE_LOCATION from './api/inviteLocations';

export type TInputState = {
	id: string;
	value: string;
	valid: boolean;
	locationName?: string;
	locationId?: string;
	taskResult?: TGetLocationsByEmailsSingleResult;
};

export type TSelectProps = {
	id: string;
	name: string;
	locationId: string;
};

const THROTTLE_LIMIT = 10;

const InviteFarmsModal = (props: {
	buttonProps?: TButtonProps;
	redirectToDashboard?: boolean;
}) => {
	const { redirectToDashboard } = props;
	const { t } = useTranslation();
	const history = useHistory();
	const companyId = useCompanyID();
	const [openModal, setOpenModal] = useState(false);
	const [inputItems, setInputItems] = useState<TInputState[]>([]);
	const [isSendEnabled, setIsSendEnabled] = useState(false);
	const [inviteLocations, inviteLocationsTask] = useMutation(INVITE_LOCATION);
	const [getLocationsByEmails, { data, loading }] = useLazyQuery(
		GET_LOCATIONS_BY_EMAILS,
		{
			variables: {
				userLocationFilterInput: {
					emails: inputItems.map((email) => email.value),
				},
			},
		}
	);

	const toggleOpen = () => {
		if (openModal) {
			setInputItems([]);
		}
		setOpenModal(!openModal);
	};

	const updateSendFlag = () =>
		setIsSendEnabled(
			inputItems.length > 0 &&
				// invalid email or location is not selected
				!inputItems.find((e) => !e.locationId || !e.valid)
		);

	const onInputChange = (input: string[], indices?: number[]) => {
		if (indices?.length) {
			// if any index is given, then its a delete operation
			const newItems = inputItems.filter((_, i) => !indices.includes(i));
			setInputItems(newItems);
		} else {
			// new items are added and needs to be mapped
			const delta = input.length - inputItems.length;
			const newItems = input.slice(-delta).map((item) => ({
				id: _uniqueId(),
				value: item,
				valid: !!validateEmail(item),
			}));

			const newInputItems = inputItems.concat(newItems);
			setInputItems(newInputItems);

			if (!newInputItems.find((e) => !e.valid)) {
				void getLocationsByEmails({
					variables: {
						userLocationFilterInput: {
							emails: newInputItems.map((email) => email.value),
						},
					},
				});
			}
		}
	};

	const onLocationSelect = ({ id, name, locationId }: TSelectProps) => {
		const newInputItems = [...inputItems];
		const index = inputItems.findIndex((item) => item.id === id);

		newInputItems[index].locationName = name;
		newInputItems[index].locationId = locationId;

		setInputItems(newInputItems);
		updateSendFlag();
	};

	const sendInvitations = () => {
		const uniqLocationIds = _uniq(
			inputItems
				.map((email) => email.locationId)
				.filter((id?: string | null): id is string => !!id)
		);

		asyncThrottle(
			uniqLocationIds,
			(locationId: string) => inviteLocations({ variables: { locationId } }),
			THROTTLE_LIMIT
		)
			.then(() => {
				enqueueSnackbar(t('companyWelcomePage.inviteLocations.success'), {
					variant: 'success',
				});
			})
			.catch((error: ApolloError) => {
				enqueueSnackbar(t('companyWelcomePage.inviteLocations.failure'), {
					variant: 'error',
				});
				throwErrorSilently(error);
			});
	};

	// update inputItems with matching locations task
	useEffect(() => {
		if (openModal && data && inputItems.length) {
			setInputItems(
				inputItems.map((item) => ({
					...item,
					taskResult: data.getLocationsByManagerEmails.find(
						(taskResult) => taskResult.email === item.value
					) || { email: item.value, locations: [] },
				}))
			);
		}
	}, [data, inputItems.length]);

	// on successful invitation request
	useEffect(() => {
		if (openModal && inviteLocationsTask.data) {
			setOpenModal(false);
		}
	}, [inviteLocationsTask.data]);

	const chooseLocationsSection = data ? (
		inputItems.map(
			({ id, locationId, taskResult }) =>
				taskResult && (
					<FarmsList
						key={id}
						activeValue={locationId}
						taskSingleResult={taskResult}
						onChange={(value: string, name: string) => {
							onLocationSelect({ id, locationId: value, name });
						}}
					/>
				)
		)
	) : loading ? (
		<Box display="flex" justifyContent="center" padding="1rem">
			<LoadingSpinner width={40} height={40} />
		</Box>
	) : (
		<></>
	);

	return (
		<>
			<Button onClick={toggleOpen} {...props.buttonProps}>
				{t('companyWelcomePage.inviteFarms')}
			</Button>
			<Modal.UncontrolledModal
				toggleOpen={toggleOpen}
				open={openModal}
				onTransitionExited={() => {
					setInputItems([]);
					setIsSendEnabled(false);
					if (redirectToDashboard) {
						history.push(`/partners/${companyId}/dashboard`);
					}
				}}
			>
				<Modal.DialogTitle
					title={t('companyWelcomePage.inviteFarms')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					<Text variant="label">{t('common.to')}</Text>
					<TextInputWithTags
						emailValidation
						items={inputItems.map((e) => e.value)}
						itemsHandleControl={onInputChange}
						customMinHeight="4.875rem"
						inputPlaceholder={t('common.emailPlaceholder')}
					/>
					{chooseLocationsSection}
				</Modal.DialogContent>
				<Modal.DialogActions sx={{ width: '100%', marginTop: '1.5rem' }}>
					<Button
						variant="text"
						onClick={toggleOpen}
						sx={{
							position: 'absolute',
							left: '1rem',
						}}
					>
						{t('buttons.cancel')}
					</Button>
					<Button
						variant="outlined"
						disabled={!isSendEnabled}
						onClick={sendInvitations}
					>
						{t('buttons.send')}
					</Button>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default InviteFarmsModal;
