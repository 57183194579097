import React, { ReactElement, ReactNode } from 'react';

import {
	Select as MUISelect,
	SelectProps,
	MenuItem as MUIMenuItem,
	InputLabel as MUIInputLabel,
	FormControl as MUIFormControl,
	FormControlProps as MUIFormControlProps,
	FormHelperText as MUIFormHelperText,
	SelectChangeEvent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import uniqueId from 'lodash/uniqueId';

import Text from '../../display/Text';
import { TItem } from '../../navigation/Menu/item';
import { FarmableColors } from '../../theme/Colors';

type TCustomSelectProps = {
	options?: string[];
	helperText?: string;
	optionFormatter?: (value) => React.ReactNode;
	changeHandler?: (newValue) => void;
	small?: boolean;
	children?: ReactNode;
	hideLabel?: boolean;
	error?: boolean;
	MUIFormControlProps?: MUIFormControlProps;
} & ({ options: string[] } | { children: React.ReactNode });

type TSelectProps = SelectProps & TCustomSelectProps;

const stringToFirstUppercase = (str: string): string => {
	const formatted = str.toLowerCase().replace(/_/g, ' ');

	return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

const useStyles = makeStyles({
	selectLabel: {
		backgroundColor: FarmableColors.WHITE,
		paddingLeft: 3,
		paddingRight: 3,
	},
});

export const MenuItem = React.forwardRef<HTMLLIElement, TItem>((props, ref) => {
	const { children, key, value, ...MUIProps } = props;

	return (
		<MUIMenuItem ref={ref} key={key} value={value} {...MUIProps}>
			{children}
		</MUIMenuItem>
	);
});

const Select = (props: TSelectProps): ReactElement => {
	const {
		children,
		label,
		changeHandler,
		value,
		options,
		fullWidth,
		helperText,
		hideLabel,
		optionFormatter,
		small,
		error,
		MUIFormControlProps,
		required,
		...MUIProps
	} = props;
	const uid = uniqueId(label + '_');
	const labelId = uniqueId(label + '_label_');
	const formatFunc = optionFormatter || stringToFirstUppercase;
	const items = options?.map((option, i) => (
		<MenuItem value={option} key={'select-menu-item-' + i}>
			<Text variant="label4">{formatFunc(option)}</Text>
		</MenuItem>
	));

	const styles = useStyles();

	const handleChange = (event: SelectChangeEvent<unknown>) => {
		if (changeHandler) {
			const newValue = event.target.value as string;
			changeHandler(newValue);
		}
	};

	const selectInputProps = {
		id: uid,
		name: uid,
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;

	const menuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				maxWidth: 250,
			},
		},
	};

	const inputHelperText = helperText ? (
		<MUIFormHelperText error={error}>{helperText}</MUIFormHelperText>
	) : null;

	return (
		<MUIFormControl
			size={small ? 'small' : undefined}
			fullWidth={fullWidth !== false}
			variant="outlined"
			{...MUIFormControlProps}
		>
			{hideLabel ? null : (
				<MUIInputLabel
					error={error}
					className={styles.selectLabel}
					id={labelId}
					disabled={MUIProps.disabled}
					required={required}
				>
					{label}
				</MUIInputLabel>
			)}

			<MUISelect
				color="primary"
				onChange={handleChange}
				value={value}
				labelId={labelId}
				inputProps={selectInputProps}
				MenuProps={menuProps}
				error={error}
				sx={{
					'& .MuiTypography-root': {
						overflow: 'auto',
						width: '95%',
						textOverflow: 'ellipsis',
					},
				}}
				{...MUIProps}
			>
				{children || items}
			</MUISelect>
			{inputHelperText}
		</MUIFormControl>
	);
};

export default Select;
