import { TCost } from 'Models/sales/isCost';

import { TAggregatedSale } from '../api/getAggregatedSales';

type TDataWithMonth = TCost | TAggregatedSale;

function sortByMonth<T extends TDataWithMonth>(result: T[]): T[] {
	return result.slice(0).sort((a, b) => {
		return new Date(a.month).getTime() - new Date(b.month).getTime();
	});
}

export default sortByMonth;
