import { useState } from 'react';

type TTransactionalInput<TValue> = {
	onTransactionBegin: () => void;
	onTransactionCommit: () => void;
	onTransactionRollback: () => void;
	onTransactionChange: TTransactionChangeFunction<TValue>;
	value: TValue;
	isInTransaction: boolean;
};

export type TTransactionChangeFunction<TValue> = (
	newValue: TValue | ((previousValue: TValue) => TValue)
) => void;

function useTransactionalInput<TValue>(
	parentValue: TValue,
	onChange: (value: TValue) => void
): TTransactionalInput<TValue> {
	const [isInTransaction, setHasTransaction] = useState(false);
	const [value, setValue] = useState(parentValue);

	const onTransactionBegin = () => {
		setHasTransaction(true);
	};

	const onTransactionCommit = () => {
		setHasTransaction(false);
		onChange(value);
	};

	const onTransactionRollback = () => {
		setHasTransaction(false);
		setValue(parentValue);
	};

	const onTransactionChange: TTransactionChangeFunction<TValue> = (
		newValue
	) => {
		setValue(newValue);
		if (!isInTransaction) {
			onChange(value);
		}
	};

	return {
		onTransactionBegin,
		onTransactionCommit,
		onTransactionRollback,
		onTransactionChange,
		value: isInTransaction ? value : parentValue,
		isInTransaction,
	};
}

export default useTransactionalInput;
