import { TField } from '../fields/isField';

export default function harvestFieldsNameString({
	fields,
}: {
	fields: Pick<TField, 'name'>[];
}): string {
	if (fields.length === 0) {
		return '-';
	}

	const fieldNames = fields.map((field) => field?.name).filter(Boolean);

	return fieldNames.length > 0 ? fieldNames.join(', ') : '-';
}
