import TPageInput from 'Models/api/PageInput';

export default function mergeNestedValue(field) {
	return function (existing, incoming, { args }) {
		if (!args) {
			throw new Error('No args provided to paginated field');
		}

		// no paginated data to merge
		if (!incoming[field]) {
			return existing;
		}

		const pageInput: TPageInput = args.pageInput;

		const merged = existing ? { ...existing } : { ...incoming, [field]: [] };

		const fieldCopy = merged[field].slice();

		for (let i = 0; i < incoming[field].length; ++i) {
			fieldCopy[pageInput.offset + i] = incoming[field][i];
		}

		return { ...merged, [field]: fieldCopy };
	};
}
