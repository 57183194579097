import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import LoadingContent from '../../common/components/LoadingContent';
import GET_PARTNER_TREATMENT_LIBRARIES from '../api/getPartnerTreatmentLibraries';
import TreatmentLibrariesCard from './TreatmentLibrariesCard';

function PageBody() {
	const { data, loading, error } = useQuery(GET_PARTNER_TREATMENT_LIBRARIES);

	if (data) {
		return (
			<TreatmentLibrariesCard treatments={data.getTreatmentLibraries.content} />
		);
	}

	if (loading) {
		return <LoadingContent />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
}

export default PageBody;
