import React, { ReactElement, ReactNode, useState } from 'react';

import ControlledRadioGroup from './Controlled';

type TRadioGroupUncontrolledProps = {
	children: ReactNode;
	value: unknown;
};

const Uncontrolled = (props: TRadioGroupUncontrolledProps): ReactElement => {
	const { children, value } = props;
	const [selectedValue, setSelectedValue] = useState(value);

	return (
		<ControlledRadioGroup
			value={selectedValue}
			valueChangeHandler={setSelectedValue}
		>
			{children}
		</ControlledRadioGroup>
	);
};

export default Uncontrolled;
