import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { PartnerRoles } from 'Models/permissions/Roles';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { FarmableColors, FarmableTextColors } from 'UI/theme/Colors';

import { TMembersFilters } from '../ContentBody';
import ErrorBoundary from './../../../../../components/ErrorBoundary';

type TProps = {
	onChange: (category: string, active: boolean) => void;
	onClear?: () => void;
	filters: TMembersFilters;
};

const RolesFilter = (props: TProps) => {
	const { onChange, onClear, filters } = props;
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) =>
		setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const hasAppliedFilters = Object.values(filters).some((applied) => applied);
	const appliedFiltersAmount = Object.values(filters).reduce(
		(sum, filter) => sum + (filter ? 1 : 0),
		0
	);
	const clearAllItem =
		onClear && hasAppliedFilters
			? [
					<Divider sx={{ marginY: '0.5rem' }} key="divider" />,
					<Menu.Item
						onClick={() => {
							onClear();
							handleClose();
						}}
						key="clearAll"
					>
						<Text
							variant="body2"
							color="textSecondary"
							sx={{ textDecoration: 'underline' }}
						>
							{t('buttons.resetFilters')}
						</Text>
					</Menu.Item>,
			  ]
			: null;

	const filterItems = Object.values(PartnerRoles).map((value) => {
		return (
			<Box paddingLeft="1rem" key={value}>
				<Checkbox.Controlled
					label={t(`roles.${PartnerRoles[value]}`)}
					checked={filters[value]}
					onChange={() => {
						onChange(value, !filters[value]);
					}}
					size={'small'}
					dense
				/>
			</Box>
		);
	});

	const optionsIcon = appliedFiltersAmount ? null : <OptionsIcon />;

	const appliedFiltersAmountIcon = (
		<Box
			width="1.125rem"
			height="1.125rem"
			sx={{
				backgroundColor: FarmableColors.ORANGE,
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Text
				sx={{
					fontSize: '0.8125rem',
					color: FarmableColors.WHITE,
					fontWeight: 'bold',
				}}
			>
				{appliedFiltersAmount}
			</Text>
		</Box>
	);
	return (
		<ErrorBoundary>
			<Button
				startIcon={
					appliedFiltersAmount ? appliedFiltersAmountIcon : optionsIcon
				}
				variant="outlined"
				onClick={handleClick}
				customTextColor={FarmableTextColors.PRIMARY}
				sx={{
					paddingLeft: '0.75rem',
					paddingRight: '0.75rem',
				}}
			>
				{t('buttons.filters')}
			</Button>
			<Menu.Menu
				id="members-role-filter"
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				{filterItems}
				{clearAllItem}
			</Menu.Menu>
		</ErrorBoundary>
	);
};

export default RolesFilter;
