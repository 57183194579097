import { gql, TypedDocumentNode } from '@apollo/client';
import { TFieldVariety } from 'Models/fields/isFieldVariety';
import { TJobFieldSnapshot } from 'Models/jobs/isJobFieldSnapshot';
import { TJobTreatment } from 'Models/jobs/isJobTreatment';
import { TOriginalProduct } from 'Models/jobs/isOriginalProduct';
import { TOriginalTreatment } from 'Models/jobs/isOriginalTreatment';
import { TPartnerJob } from 'Models/partnershipCompany/PartnerJob';
import { TBasicWeather } from 'Models/weather';

export type TPartnerJobModalData = Pick<
	TPartnerJob,
	| 'id'
	| 'jobStatus'
	| 'jobType'
	| 'startDate'
	| 'createdAt'
	| 'completedDate'
	| 'jobCauses'
	| 'customJobType'
	| 'driversWithEquipmentConfig'
	| 'notes'
	| 'trackRaw'
	| 'runStatusHistory'
	| 'productsImages'
	| 'safetyChecklist'
> & {
	author: Pick<TPartnerJob['author'], 'firstName' | 'lastName'>;
	jobFields: (Pick<TJobFieldSnapshot, 'id' | 'name' | 'areaSize'> & {
		fieldVarieties: Pick<TFieldVariety, 'name' | 'category'>[] | null;
	})[];
	treatments: (Pick<TJobTreatment, 'amount' | 'unit'> & {
		originalProduct: Pick<
			TOriginalProduct,
			'name' | 'productSpec' | 'ingredients'
		>;
		originalTreatment: Pick<
			TOriginalTreatment,
			'amount' | 'unit' | 'withholdingPeriod'
		>;
	})[];
	weather?: TBasicWeather | null;
};

export type TPartnerJobModalDataResponse = {
	job: TPartnerJobModalData;
};

type TPartnerJobModalDataRequest = {
	id: string;
	locationId: string;
};

const GET_PARTNER_JOB_MODAL_DATA: TypedDocumentNode<
	TPartnerJobModalDataResponse,
	TPartnerJobModalDataRequest
> = gql`
	query getPartnerJobModalData($id: ID!, $locationId: ID!) {
		job(id: $id, locationId: $locationId) {
			id
			jobType
			jobStatus
			startDate
			completedDate
			createdAt
			driversWithEquipmentConfig {
				driver {
					firstName
					lastName
				}
				equipment {
					id
					name
					equipmentType
					rate {
						value
						unit
					}
					tankSize {
						value
						unit
					}
				}
			}
			treatments {
				amount
				unit
				originalProduct {
					name
					productSpec {
						fracNumber
						reEntryInterval
					}
					ingredients {
						name
						amount
						unit
						active
					}
				}
				originalTreatment {
					amount
					unit
					withholdingPeriod
				}
			}
			jobCauses {
				id
				name
				causeType
			}
			author {
				firstName
				lastName
			}
			fields {
				id
				name
				areaSize
				numberOfPlants
				varieties {
					identifier
					name
					category
					sowDate
				}
			}
			jobFields {
				id
				name
				fieldVarieties {
					name
					category
				}
				areaSize
			}
			customJobType {
				id
				name
			}
			runStatusHistory {
				runId
				timestamp
				status
			}
			notes {
				id
				account {
					id
					firstName
					lastName
					profileImage {
						path
					}
				}
				note
				createdAt
			}
			weather {
				temp
				windSpeed
				windDeg
				rain
			}
		}
	}
`;

export default GET_PARTNER_JOB_MODAL_DATA;
