import React, { ReactElement } from 'react';

import { InputAdornment } from '@mui/material';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { format, Locale } from 'date-fns';
import { DateRange as ReactDateRange } from 'react-date-range';
import isValidDate from 'vet/dates/isValidDate';
import isShape from 'vet/objects/isShape';

import Modal from '../../display/modal/dialog';
import CalendarIcon from '../../icons/Calendar';
import Button from '../Button';
import TextField from '../TextField';
import styles from './styles.module.scss';
import 'react-date-range/dist/styles.css';
// main css file
import 'react-date-range/dist/theme/default.css';
// theme css file
import { FarmableColors } from '../../theme/Colors';

const DEFAULT_DATE_FORMAT = 'd MMM, yyyy';

const isStart = isShape({
	startDate: isValidDate,
});

const isEnd = isShape({
	endDate: isValidDate,
});

export type TDateRange = {
	startDate: Date | null;
	endDate: Date | null;
	key?: string;
};

export type TOnChangeValueProps = {
	dates: TDateRange;
};

export type TControlledDateRangePickerProps = {
	open: boolean;
	toggleOpen: () => void;
	value: TDateRange | null;
	onChangeValue?: (newValue: TDateRange) => void;
	noBackground?: boolean;
	startDatePlaceholder?: string;
	endDatePlaceholder?: string;
	locale?: Locale;
};

function DateRangePicker(props: TControlledDateRangePickerProps): ReactElement {
	const {
		value,
		onChangeValue,
		open,
		toggleOpen,
		noBackground,
		startDatePlaceholder,
		endDatePlaceholder,
		locale,
	} = props;

	const { t } = useTranslation();

	const onChange = (ranges: TOnChangeValueProps) => {
		const range = ranges.dates;
		const newValue = { startDate: range.startDate, endDate: range.endDate };

		if (onChangeValue) {
			onChangeValue(newValue);
		}
	};

	const onClear = () => {
		if (onChangeValue) {
			onChangeValue({
				startDate: null,
				endDate: null,
			});
		}
	};

	const range = value
		? { startDate: value.startDate, endDate: value.endDate, key: 'dates' }
		: { startDate: new Date(), endDate: new Date(), key: 'dates' };

	const ranges = [range];

	const startDate = isStart(value)
		? format(value?.startDate, DEFAULT_DATE_FORMAT)
		: '';

	const endDate = isEnd(value)
		? format(value.endDate, DEFAULT_DATE_FORMAT)
		: '';

	const backdropProps = noBackground
		? {
				style: {
					backgroundColor: 'transparent',
				},
		  }
		: undefined;

	return (
		<>
			<TextField
				value={startDate}
				onClick={toggleOpen}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<CalendarIcon color="secondary" />
						</InputAdornment>
					),
					endAdornment: endDate ? (
						<span className={styles.divider}>{endDate}</span>
					) : null,
				}}
			/>
			<Modal.UncontrolledModal
				toggleOpen={toggleOpen}
				open={open}
				BackdropProps={backdropProps}
			>
				<Modal.DialogTitle
					title={t('labels.chooseDate')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent dividers noFixedWidth>
					<ReactDateRange
						ranges={ranges}
						onChange={onChange}
						fixedHeight
						rangeColors={[FarmableColors.ORANGE]}
						startDatePlaceholder={startDatePlaceholder}
						endDatePlaceholder={endDatePlaceholder}
						locale={locale}
					/>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Button variant={'text'} onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={toggleOpen}>{t('buttons.save')}</Button>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}

export default DateRangePicker;
