import { TTranslateFunction } from 'Features/localization';
import { TChartProps } from 'UI/display/Chart';
import { FarmableTextColors, FarmableColors } from 'UI/theme/Colors';
import Highcharts from 'highcharts';

import { isProfitChartSeries } from '../models/isProfitChartSeries';
import styles from '../styles.module.scss';

const getChartConfig = (t: TTranslateFunction): TChartProps['options'] => {
	const initialChartOptions: TChartProps['options'] = {
		chart: {
			type: 'column',
			style: {
				fontFamily: 'Lato',
			},
			marginTop: 40,
			marginRight: 60,
			marginLeft: 20,
			marginBottom: 110,
			spacingBottom: 0,
			height: 372,
			events: {
				load: function () {
					const zeroTick = this.yAxis[0].ticks[0];
					if (zeroTick) {
						zeroTick?.gridLine?.attr({
							'stroke-dasharray': '2, 3',
							'stroke-width': 2,
						});
					}
				},
			},
		},
		credits: {
			enabled: false,
		},
		legend: {
			align: 'left',
			useHTML: true,
			symbolPadding: 0,
			symbolWidth: 0,
			symbolHeight: 0,
			squareSymbol: false,
			labelFormatter: function () {
				if (isProfitChartSeries(this)) {
					const sum = this.options.custom.rawData.reduce(
						(sum, point) => sum + point.y,
						0
					);

					const formattedSum = Highcharts.numberFormat(sum, 0, '', ' ');

					const seriesMarker = `<span class="${styles.legendMarker}" style="color: ${this.options.color}">■ </span>`;
					const seriesType = `<span class="${styles.legendSeriesName}">
						${t(`labels.${this.options.custom.type}`)}
					</span>`;

					return `
						<div>
							<div>
								${seriesMarker}
								${seriesType}
							</div>
							<div class="${styles.legendValue}">
								${formattedSum} ${this.options?.custom?.currencyCode}
							</div>
						</div>
					`;
				}

				return '';
			},
			itemStyle: {
				fontWeight: 'normal',
			},
		},
		series: [],
		xAxis: {
			labels: {
				style: {
					fontSize: '0.75rem',
					color: FarmableTextColors.SECONDARY,
				},
			},
			max: 11,
			lineColor: FarmableColors.BLACK_12,
		},
		lang: {
			noData: t('common.charts.noData'),
		},
		title: {
			text: '',
		},

		plotOptions: {
			line: {
				lineWidth: 1,
				marker: {
					enabled: true,
					fillColor: FarmableColors.WHITE,
					symbol: 'circle',
					lineWidth: 1,
					lineColor: FarmableColors.NAVY,
				},
			},
			column: {
				stacking: 'normal',
				pointPadding: 0,
			},
		},

		yAxis: {
			title: {
				text: t('labels.value'),
				align: 'high',
				rotation: 0,
				textAlign: 'right',
				y: -20,
				x: -10,
			},
			gridLineColor: FarmableColors.BLACK_12,
			opposite: true,
			labels: {
				style: {
					fontSize: '0.75rem',
					color: FarmableTextColors.SECONDARY,
				},
			},
		},
		tooltip: {
			borderWidth: 0,
			shared: true,
			followPointer: true,
			backgroundColor: 'rgba(0,0,0,0)',
			shadow: false,
			useHTML: true,
			hideDelay: 0,
			padding: 0,
			outside: true,
		},
	};

	return initialChartOptions;
};

export default getChartConfig;
