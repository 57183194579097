import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Menu from 'UI/navigation/Menu';

const MemberDropdownView = (props: { onViewProfileClick: () => void }) => {
	const { t } = useTranslation();

	return (
		<Menu.Item onClick={props.onViewProfileClick}>
			{t('managePartnerMembers.viewProfile')}
		</Menu.Item>
	);
};

export default MemberDropdownView;
