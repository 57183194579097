import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TTimesheetsFilterInput } from 'Models/timesheet/types/TimesheetsFilterInput';

import { TTimesheetQueryResponse } from '../types';

export type TGetTimesheetsResponse = {
	getTimesheets: TTimesheetQueryResponse;
};

type TGetHoursDataInput = {
	locationId: string;
	filterBy?: Partial<TTimesheetsFilterInput>;
	pageInput?: TPageInput;
	hasPagination?: boolean;
};

const GET_HOURS_TABLE_DATA: TypedDocumentNode<
	TGetTimesheetsResponse,
	TGetHoursDataInput
> = gql`
	query getTimesheets(
		$locationId: ID!
		$filterBy: TimesheetsFilterInput
		$pageInput: PageInput!
	) {
		getTimesheets(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			timesheets {
				id
				startTime
				endTime
				team {
					id
					name
				}
				teamLead {
					id
					firstName
					lastName
				}
				teamMember {
					id
					fullName
				}
				fields {
					id
					name
				}
				workLogs {
					id
					workType
					duration
					jobId
				}
				timesheetFieldHours {
					fieldId
					duration
					isLunchTimeSubtracted
				}
			}
		}
	}
`;

export default GET_HOURS_TABLE_DATA;
