import { TTimesheet } from './isTimesheet';
import timesheetFieldsString from './timesheetFieldsString';

function timesheetFieldsNumber<TGenericTimesheet>(
	timesheet: TGenericTimesheet & Pick<TTimesheet, 'fields'>
): number | string {
	const fields = timesheet.fields;

	return fields.length === 1 ? timesheetFieldsString(timesheet) : fields.length;
}

export default timesheetFieldsNumber;
