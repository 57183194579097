import Card, { TCardProps } from './card';
import Lead, { TLeadProps } from './lead';
import Member, { TMemberProps } from './member';
import Status, { TStatusProps } from './status';

export type { TCardProps, TMemberProps, TLeadProps, TStatusProps };

export default {
	Card,
	Member,
	Lead,
	Status,
};
