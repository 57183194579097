import React, { ReactElement } from 'react';

import Route from 'Features/router/components/Route';

import PermissionValidator from '../PermissionValidator';

function FarmableRoute({
	component: Component,
	...rest
}: {
	component: React.ComponentType<any>;
	path?: string | string[];
	exact?: boolean;
}): ReactElement {
	return (
		<PermissionValidator>
			<Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
		</PermissionValidator>
	);
}

export default FarmableRoute;
