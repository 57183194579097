import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Highcharts from 'highcharts';
import { renderToString } from 'react-dom/server';

import { FarmableColors } from '../../theme/Colors';
import Chart, { TChartProps } from '../Chart';

const {
	ORANGE,
	ORANGE_13_13,
	ORANGE_13_22,
	ORANGE_13_31,
	ORANGE_13_42,
	ORANGE_13_72,
} = FarmableColors;

const DEFAULT_COLORS = [
	ORANGE_13_13,
	ORANGE_13_22,
	ORANGE_13_31,
	ORANGE_13_42,
	ORANGE,
	ORANGE_13_72,
];
const DEFAULT_HEIGHT = 248;
const DEFAULT_WIDTH = 248;
const DEFAULT_MARGIN = 4;
const DEFAULT_INNER_SIZE = 80;
const DEFAULT_BORDER_WIDTH = 0.5;
const ANIMATE_HOVER_DURATION = 200;

type TData = {
	y?: number | null;
	textElement?: ReactElement;
};

type TPieChartProps = {
	data: TData[];
	defaultTextElement?: ReactElement;
	options?: TChartProps['options'];
	width?: number;
	height?: number;
	margin?: number;
	innerSize?: number;
	colors?: string[];
};

type THighchartsPointExtended = Highcharts.Point & {
	options: TData;
};

const PieChart = (props: TPieChartProps): ReactElement => {
	const { data, options, defaultTextElement } = props;
	const { t } = useTranslation();

	const width = props.width || DEFAULT_WIDTH;
	const height = props.height || DEFAULT_HEIGHT;
	const margin = props.margin || DEFAULT_MARGIN;
	const innerSize = props.innerSize || DEFAULT_INNER_SIZE;
	const colors = props.colors || DEFAULT_COLORS;

	const radius = Math.min(width, height) / 2 - margin;
	const innerRadius = radius * (innerSize / 100);

	const hoverRadius = radius + margin - DEFAULT_BORDER_WIDTH;
	const hoverInnerRadius = innerRadius - margin + DEFAULT_BORDER_WIDTH;

	const chartOptions: TChartProps['options'] = {
		chart: {
			margin: margin,
			height: width,
			width: height,
			animation: false,
			spacingBottom: 0,
			spacingLeft: 0,
			spacingRight: 0,
			spacingTop: 0,
		},
		tooltip: {
			enabled: false,
		},
		lang: {
			noData: t('common.charts.noData'),
		},
		noData: {
			useHTML: true,
			style: {
				width: 180,
				textAlign: 'center',
			},
		},
		plotOptions: {
			pie: {
				borderWidth: DEFAULT_BORDER_WIDTH,
				borderColor: undefined,
				slicedOffset: 0,
				size: '100%',
				innerSize: `${innerSize}%`,
				dataLabels: {
					enabled: false,
				},
				animation: {
					defer: 0,
					duration: 0,
				},
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: true,
						brightness: 0,
						halo: {
							size: 0,
						},
					},
				},
				point: {
					events: {
						mouseOver(this: THighchartsPointExtended) {
							if (this.graphic) {
								this.graphic.animate(
									{
										r: hoverRadius,
										innerR: hoverInnerRadius,
									},
									{
										duration: ANIMATE_HOVER_DURATION,
									}
								);
							}

							const textElement = this.options.textElement;
							if (textElement) {
								this.series.chart.subtitle.update({
									text: renderToString(textElement),
								});
							}
						},
						mouseOut(this: THighchartsPointExtended) {
							if (this.graphic) {
								this.graphic.animate(
									{
										r: radius,
										innerR: innerRadius,
									},
									{
										duration: ANIMATE_HOVER_DURATION,
									}
								);
							}

							if (defaultTextElement) {
								this.series.chart.subtitle.update({
									text: renderToString(defaultTextElement),
								});
							}
						},
					},
				},
			},
		},
		title: {
			text: undefined,
		},
		subtitle: {
			align: 'center',
			verticalAlign: 'middle',
			useHTML: true,
			text: defaultTextElement ? renderToString(defaultTextElement) : undefined,
		},
		series: [
			{
				type: 'pie',
				data: data,
			},
		],
		colors: colors,
		credits: {
			enabled: false,
		},
		...(options ? options : {}),
	};

	return <Chart options={chartOptions} />;
};

export default PieChart;
