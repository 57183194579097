// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3um2E9f9GMJX9Vc0BXID02:hover {\n  cursor: pointer;\n  color: rgba(0,0,0,.66);\n}\n\n._1e2hvdhjRaXkq0SvVgBBKj {\n  display: flex;\n}\n\n", ""]);
// Exports
exports.locals = {
	"dropdownTitle": "_3um2E9f9GMJX9Vc0BXID02",
	"dropdownBox": "_1e2hvdhjRaXkq0SvVgBBKj"
};
module.exports = exports;
