import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductIngredient } from 'Models/products/ProductIngredient';
import { TProductIngredientInput } from 'Models/products/ProductIngredientInput';
import { ProductUnitsArray, TAllUnits } from 'Models/units';
import { toDisplayString as unitToDisplayString } from 'Models/units/transpileUnits';
import SubtractIcon from 'UI/icons/Subtract';
import IconButton from 'UI/inputs/IconButton';
import Select from 'UI/inputs/Select';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../../state';
import {
	AddTreatmentModalStatus,
	TSetProductDetailsInput,
} from '../../../../../../types';
import ActiveIngredient from './ActiveIngredient';
import Amount from './Amount';
import Name from './Name';

export type TIngredientProps = {
	ingredient:
		| TProductIngredientInput
		| Pick<
				TProductIngredient,
				| 'id'
				| 'name'
				| 'unit'
				| 'active'
				| 'amount'
				| 'registrationNumber'
				| 'groupName'
		  >;
};

const Ingredient = (props: TIngredientProps) => {
	const { ingredient } = props;
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { status, input } = state;

	if (status === AddTreatmentModalStatus.SET_PRODUCT_DETAILS) {
		const product = input.product;
		const hasIngredients = 'ingredients' in product;
		const ingredients =
			selectedProduct?.ingredients ||
			(hasIngredients && product.ingredients) ||
			[];
		const canRemove = ingredients.length > 1 || Boolean(selectedProduct);

		const onChangeUnit = (value: TAllUnits) => {
			if (
				!input.selectedGovernmentProduct &&
				'ingredients' in input.product &&
				input.product.ingredients
			) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: input.product.ingredients?.map((innerIngredient) =>
								ingredient.id === innerIngredient.id
									? {
											...innerIngredient,
											unit: value,
									  }
									: innerIngredient
							),
						},
					},
				});
			}
		};

		const onRemoveIngredient = () => {
			if (!input.selectedGovernmentProduct && ingredients) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: ingredients.filter(
								(innerIngredient) => ingredient.id !== innerIngredient.id
							),
						},
					},
				} as TSetProductDetailsInput);
			}
		};

		return (
			<Box display="flex" marginTop="0.75rem">
				<Grid container item spacing={1.5}>
					<Grid item xs={12}>
						<Name name={ingredient.name} id={ingredient.id} />
					</Grid>
					<Grid item xs={6}>
						<Amount amount={ingredient.amount} id={ingredient.id} />
					</Grid>
					<Grid item xs={6}>
						<Select
							value={ingredient.unit}
							label={t('labels.unit')}
							options={ProductUnitsArray}
							optionFormatter={unitToDisplayString}
							changeHandler={onChangeUnit}
							small
							disabled={Boolean(selectedProduct)}
						/>
					</Grid>
					<Grid item xs={12}>
						<ActiveIngredient ingredient={ingredient} />
					</Grid>
				</Grid>
				<Box marginLeft="1rem" marginTop="0.8rem">
					<IconButton
						onClick={onRemoveIngredient}
						disabled={!canRemove}
						sx={{
							width: '1.5rem',
							height: '1.5rem',
							borderWidth: 1,
							borderStyle: 'solid',
							borderColor: FarmableColors.BLACK_12,
						}}
					>
						<SubtractIcon color={canRemove ? 'action' : 'disabled'} />
					</IconButton>
				</Box>
			</Box>
		);
	}

	return null;
};

export default Ingredient;
