import React, { ReactElement } from 'react';

import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

type TPageLayoutProps = {
	children: ReactElement | ReactElement[];
	header: string | ReactElement;
	sidebar: ReactElement;
};

const PageLayout = (props: TPageLayoutProps): ReactElement => {
	const { children, header, sidebar } = props;

	return (
		<Box display="flex" overflow="auto" height="100%">
			<Box
				flex="auto"
				borderRight={`1px solid ${FarmableColors.DIVIDER}`}
				width="100%"
				overflow="auto"
			>
				<Box paddingX="1.5rem" paddingY="2.5rem">
					<Text variant="h1">{header}</Text>
					<Box paddingY="1rem">{children}</Box>
				</Box>
			</Box>
			<Box minWidth="25.5rem" maxWidth="25.5rem">
				{sidebar}
			</Box>
		</Box>
	);
};

export default PageLayout;
