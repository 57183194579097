import React from 'react';

import Text from 'UI/display/Text';

import { TTranslateFunction } from '../../features/localization';
import { TJobFieldSnapshot } from '../jobs/isJobFieldSnapshot';
import jobDriversString, {
	TJobForDriverString,
} from '../jobs/jobDriversString';
import jobTypeIngForm, { TJobForTypeIngForm } from '../jobs/jobTypeIngForm';

type TField = Pick<TJobFieldSnapshot, 'id'>;

type TJobForGetTooltipForActiveField = TJobForTypeIngForm &
	TJobForDriverString & {
		id: string;
		jobFields?: Pick<TJobFieldSnapshot, 'id'>[] | null;
	};

const getTooltipForActiveField = (
	jobs: TJobForGetTooltipForActiveField[],
	field: TField,
	t: TTranslateFunction
): JSX.Element[] | undefined => {
	const output: JSX.Element[] = [];

	jobs.forEach((job) => {
		job.jobFields?.forEach((jobField) => {
			if (jobField.id == field.id) {
				const driver = jobDriversString(job).split(' ')[0];
				const jobType = jobTypeIngForm(job, t);

				output.push(
					<Text
						key={`field-${field.id}-job-${job.id}-tooltip`}
						variant="label1"
					>
						{t('jobTooltip.message', { driver, jobType })}
					</Text>
				);
			}
		});
	});

	if (output.length) {
		return output;
	}
};

export default getTooltipForActiveField;
