import React, { Fragment, ReactElement } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import ChevronLeft from 'UI/icons/ChevronLeft';
import ChevronRight from 'UI/icons/ChevronRight';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import { makeStyles } from 'UI/utils/makeStyles';

import { FieldImportsStatus } from '../../../../models';
import { fieldImportsPageState } from '../../../../state';
import { TFieldImportsStatuses } from '../../../../types';

type TPageLayoutProps = {
	previousArrowHandler?: () => void;
	nextArrowHandler?: (() => void) | null;
	children?: JSX.Element | JSX.Element[];
};

const firstStepStatuses: TFieldImportsStatuses[] = [
	FieldImportsStatus.ADD_FIELDS_FORM,
	FieldImportsStatus.ADD_FIELDS_LOADING,
	FieldImportsStatus.ADD_FIELDS_FAILURE,
	FieldImportsStatus.ADD_FIELDS_SUCCESS,
];

const useStyles = makeStyles({
	root: {
		minWidth: '10rem',
		position: 'relative',
	},
	previousButtonStartIcon: {
		position: 'absolute',
		left: 0,
		paddingLeft: 'inherit',
	},
	nextButtonEndIcon: {
		position: 'absolute',
		right: 0,
		paddingRight: 'inherit',
	},
});

const ContentLayout = (props: TPageLayoutProps): ReactElement => {
	const { children, previousArrowHandler, nextArrowHandler } = props;
	const state = useReactiveVar(fieldImportsPageState);
	const { t } = useTranslation();

	const { root, previousButtonStartIcon, nextButtonEndIcon } = useStyles();

	const isFirstStep = firstStepStatuses.includes(state.status);
	const isLastStep = state.status === FieldImportsStatus.REVIEW_FIELDS;

	const previousButton = (
		<Button
			onClick={previousArrowHandler}
			disabled={!previousArrowHandler}
			variant="outlined"
			classes={{ root, startIcon: previousButtonStartIcon }}
			startIcon={<ChevronLeft sx={{ width: 24, height: 24 }} />}
		>
			{t('buttons.previous')}
		</Button>
	);

	const nextButton = (
		<Button
			onClick={nextArrowHandler || undefined}
			disabled={!nextArrowHandler}
			variant="outlined"
			classes={{ root, endIcon: nextButtonEndIcon }}
			endIcon={
				<ChevronRight color={nextArrowHandler ? 'action' : 'disabled'} />
			}
		>
			{isLastStep
				? t('importPages.fieldImportsPage.createFields.buttons.createFields')
				: t('buttons.next')}
		</Button>
	);

	const buttons = isFirstStep ? (
		nextButton
	) : (
		<>
			{previousButton}
			{nextButton}
		</>
	);

	return (
		<Fragment>
			{children}
			<Box
				display="flex"
				justifyContent="center"
				marginY={6}
				gap={4}
				flexWrap="wrap"
			>
				{buttons}
			</Box>
		</Fragment>
	);
};

export default ContentLayout;
