import { TypedDocumentNode, gql } from '@apollo/client';
import { TPartnerLocation } from 'Models/partnershipCompany/isPartnerLocation';

type TInviteLocationToCompanyRequest = { locationId: string };

export type TInviteLocationToCompanyResult = {
	inviteLocationToPartnershipCompany: Pick<
		TPartnerLocation,
		'id' | 'name' | 'status' | 'farmerEmail'
	>;
};

const INVITE_LOCATION_TO_COMPANY: TypedDocumentNode<
	TInviteLocationToCompanyResult,
	TInviteLocationToCompanyRequest
> = gql`
	mutation inviteLocationToCompany($locationId: ID!) {
		inviteLocationToPartnershipCompany(locationId: $locationId) {
			id
			name
			status
			farmerEmail
		}
	}
`;

export default INVITE_LOCATION_TO_COMPANY;
