import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import ISOToLocal from 'Models/dates/ISOToLocal';
import Text from 'UI/display/Text';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { FarmableTextColors } from 'UI/theme/Colors';
import _range from 'lodash/range';

import useManageHistory from 'Hooks/useManageHistory';
import usePartnerCompany from 'Hooks/usePartnerCompany';
import getSeasonParams from 'Utils/getSeasonParams';

import styles from './styles.module.scss';

// enable to plan a work for a next season
const PARTNER_PLANNER_END_YEAR = new Date().getFullYear() + 1;
// the year of the feature enabled
const PARTNER_PLANNER_START_YEAR = 2023;

export default function SeasonDropdown() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { company } = usePartnerCompany();
	const { paramsFromURL, historyPush } = useManageHistory();
	const season = getSeasonParams(paramsFromURL.filters);

	const { t } = useTranslation();

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleItemClick = (season: number) => {
		historyPush({
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				dateFrom: `${season}-01-01`,
				dateTo: `${season + 1}-01-01`,
				season: `${season}`,
			},
		});
		handleClose();
	};
	const startYear = company?.createdAt
		? ISOToLocal(company?.createdAt).getFullYear()
		: PARTNER_PLANNER_START_YEAR;

	const seasonsMenuItems = _range(startYear, PARTNER_PLANNER_END_YEAR + 1).map(
		(season) => {
			return (
				<Menu.Item key={season} onClick={() => handleItemClick(season)}>
					{`${t('labels.season')} ${season}`}
				</Menu.Item>
			);
		}
	);

	return (
		<>
			<Box className={styles.dropdownBox} onClick={handleClick}>
				<Text
					fontSize="1.75rem"
					color={FarmableTextColors.PRIMARY}
					className={styles.dropdownTitle}
				>
					{`${t('labels.season')} ${season.seasonName}`}
				</Text>
				<ArrowDropDownIcon />
			</Box>
			<Menu.Menu
				id="navbar-profile-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'season-profile-menu',
					dense: true,
				}}
			>
				{seasonsMenuItems}
			</Menu.Menu>
		</>
	);
}
