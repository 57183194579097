import React from 'react';

import ErrorIcon from 'UI/icons/Error';
import InfoIcon from 'UI/icons/Info';
import SuccessIcon from 'UI/icons/Success';
import WarningIcon from 'UI/icons/Warning';
import { SnackbarProvider as Provider } from 'notistack';

import styles from './styles.scss';

function SnackbarProvider() {
	return (
		<Provider
			classes={styles}
			iconVariant={{
				default: <InfoIcon />,
				error: <ErrorIcon />,
				warning: <WarningIcon />,
				info: <InfoIcon />,
				success: <SuccessIcon />,
			}}
		/>
	);
}

export default SnackbarProvider;
