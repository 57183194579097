import { gql, TypedDocumentNode } from '@apollo/client';
import { TUserLocation } from 'Models/partnershipCompany/isUserLocation';

type TGetLocationsByEmailsRequest = {
	userLocationFilterInput: { emails: string[] };
};

export type TGetLocationsByEmailsSingleResult = {
	email: string;
	locations: Pick<TUserLocation, 'id' | 'name'>[];
};

export type TGetLocationsByEmailsResult = {
	getLocationsByManagerEmails: TGetLocationsByEmailsSingleResult[];
};

const GET_LOCATIONS_BY_EMAILS: TypedDocumentNode<
	TGetLocationsByEmailsResult,
	TGetLocationsByEmailsRequest
> = gql`
	query getLocationsByEmails(
		$userLocationFilterInput: UserLocationFilterInput!
	) {
		getLocationsByManagerEmails(input: $userLocationFilterInput) {
			email
			locations {
				id
				name
			}
		}
	}
`;

export default GET_LOCATIONS_BY_EMAILS;
