/// <reference types="@types/intercom-web" />

declare global {
	interface Window {
		attachEvent: (name: string, callback: () => void) => void;
	}
}

type TIntercomProfile = {
	name: string;
	userHash: string;
	userId: string;
};

const VERTICAL_PADDING = 100;
const API_BASE = 'https://api-iam.intercom.io';

// The content of this function is copied from intercom dashboard - it shouldn't be modified
function init(app_id: string): void {
	const w = window;
	const ic: any = w.Intercom;
	if (typeof ic === 'function') {
		ic('reattach_activator');
		ic('update', w.intercomSettings);
	} else {
		const d = document;
		const i: any = function (...args) {
			i.c(args);
		};
		i.q = [];
		i.c = function (args) {
			i.q.push(args);
		};
		w.Intercom = i;
		const l = function () {
			const s = d.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = 'https://widget.intercom.io/widget/' + app_id;
			const x: any = d.getElementsByTagName('script')[0];
			x.parentNode.insertBefore(s, x);
		};
		if (document.readyState === 'complete') {
			l();
		} else if (w.attachEvent) {
			w.attachEvent('onload', l);
		} else {
			w.addEventListener('load', l, false);
		}
	}
}

export default function (
	app_id: string,
	profile: TIntercomProfile | null
): void {
	// clear intercom session if exists
	window.Intercom?.('shutdown');
	init(app_id);

	if (profile) {
		window.Intercom('boot', {
			api_base: API_BASE,
			app_id,
			name: profile.name,
			user_id: profile.userId,
			vertical_padding: VERTICAL_PADDING,
			user_hash: profile.userHash,
		});
	} else {
		window.Intercom('boot', {
			api_base: API_BASE,
			app_id,
			vertical_padding: VERTICAL_PADDING,
		});
	}
}
