import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getAccountName from 'Models/account/getAccountName';
import { TPartnershipCompanyUser } from 'Models/partnershipCompany/isPartnershipCompanyUser';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import Box from 'UI/layout/Box';

type TProps = {
	open: boolean;
	toggleOpen: () => void;
	member: TPartnershipCompanyUser;
};

const MemberDetailsModal = (props: TProps): ReactElement => {
	const { open, toggleOpen, member } = props;
	const { firstName, lastName, email, mobilePhone, profileImage } = member;
	const { t } = useTranslation();

	const memberTitle = getAccountName({ firstName, lastName }) || email;

	return (
		<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
			<Modal.DialogTitle
				title={t('personalInfoPage.card.title')}
				closeIconHandler={toggleOpen}
			/>
			<Modal.DialogContent>
				<Box mb="2rem" display="flex" flexDirection="row" alignItems="center">
					<Avatar src={profileImage?.path} size="xxl" alt={memberTitle} />
					<Box pl="2rem">
						<Text variant="h4">{memberTitle}</Text>
						<Text sx={{ textDecoration: 'underline' }}>
							<a href={`mailto:${email}`}>{email}</a>
						</Text>
						{mobilePhone ?? <Text>{mobilePhone}</Text>}
					</Box>
				</Box>
			</Modal.DialogContent>
		</Modal.UncontrolledModal>
	);
};

export default MemberDetailsModal;
