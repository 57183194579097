import {
	TAdvisorFieldsResponse,
	TRegularFieldsResponse,
} from '../api/getFields';

function parseData(response?: TAdvisorFieldsResponse | TRegularFieldsResponse) {
	if (response) {
		if ('getFieldsByUsersFieldCategories' in response) {
			return response.getFieldsByUsersFieldCategories;
		} else if ('getFields' in response) {
			return response.getFields.fields;
		}
	}
	return [];
}

export default parseData;
