import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import useLocation from 'Features/router/hooks/useLocation';
import Permissions from 'Models/permissions/Permissions';
import List from 'UI/display/List';
import AddIcon from 'UI/icons/Add';

import usePermission from 'Hooks/usePermission';

import { ICONS_FILL, getSettingsRoutes } from '../';

function ImportsSection(props: { locationID?: string }) {
	const { locationID } = props;
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const currentPath = location.pathname;

	const hasFieldsImportPermission = usePermission(
		Permissions.FIELD_IMPORTS_PAGE_ACCESS
	);

	if (!locationID) {
		return null;
	}

	const routes = getSettingsRoutes({ locationID });

	return hasFieldsImportPermission ? (
		<List.List
			isMenuVariant
			subheader={<List.Subheader>{t('common.sidebar.imports')}</List.Subheader>}
		>
			<List.Item disablePadding>
				<List.ItemButton
					isActive={currentPath === routes.fieldsImport}
					onClick={() => history.push(routes.fieldsImport)}
				>
					<List.ItemIcon>
						<AddIcon fill={ICONS_FILL} />
					</List.ItemIcon>
					<List.ItemText primary={t('importPages.fieldImportsPage.header')} />
				</List.ItemButton>
			</List.Item>
		</List.List>
	) : null;
}

export default ImportsSection;
