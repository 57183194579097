import React, { ReactElement } from 'react';

import MUIBox, { BoxProps } from '@mui/material/Box';

const Box = (props: BoxProps): ReactElement => {
	const { className, children, ...MUIBoxProps } = props;

	return (
		<MUIBox className={className} {...MUIBoxProps}>
			{children}
		</MUIBox>
	);
};

export default Box;
