import { gql, TypedDocumentNode } from '@apollo/client';
import { TField } from 'Models/fields/isField';

export type TGetFieldNameResponse = {
	getField: Pick<TField, 'id' | 'name'>;
};

type TGetFieldNameInput = {
	id: string;
	locationId: string;
};

const GET_FIELD_NAME_QUERY: TypedDocumentNode<
	TGetFieldNameResponse,
	TGetFieldNameInput
> = gql`
	query getFieldName($id: ID!, $locationId: ID!) {
		getField(id: $id, locationId: $locationId) {
			id
			name
		}
	}
`;

export default GET_FIELD_NAME_QUERY;
