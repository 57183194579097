import ISOToLocal from '../dates/ISOToLocal';
import classicFormat from '../dates/classicFormat';
import { TTimesheet } from './isTimesheet';

function timesheetDateString<TGenericTimesheet>(
	timesheet: TGenericTimesheet & Pick<TTimesheet, 'startTime'>
): string {
	return timesheet.startTime
		? classicFormat(ISOToLocal(timesheet.startTime))
		: '-';
}

export default timesheetDateString;
