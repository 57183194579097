import React, { ReactElement, ReactNode } from 'react';

import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import NoAccessImg from './assets/no_access.svg';

type NoAccessPageTemplateProps = {
	title?: string;
	description?: string;
	children?: ReactNode;
	headerIcon?: ReactNode;
};

const NoAccessPageTemplate = (
	props: NoAccessPageTemplateProps
): ReactElement => {
	const { title, description, children, headerIcon } = props;

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="100%"
		>
			{headerIcon || <img src={NoAccessImg} />}
			<Box paddingTop="2.625rem" textAlign="center">
				<Text variant="h1">{title}</Text>
			</Box>
			<Box paddingTop="1rem" textAlign="center">
				<Text>{description}</Text>
			</Box>
			{children}
		</Box>
	);
};

export default NoAccessPageTemplate;
