import React, { ReactElement } from 'react';

import MuiDialogActions, {
	DialogActionsProps,
} from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';

export type TDialogActions = DialogActionsProps;

const DialogActions = (props: DialogActionsProps): ReactElement => {
	const { children, ...MUIProps } = props;
	const useActionsStyles = makeStyles({
		root: {
			padding: children ? '1.5rem 1rem 1rem 1rem' : '1.5 rem 0 0 0',
		},
	});

	const { root } = useActionsStyles();

	return (
		<MuiDialogActions classes={{ root }} {...MUIProps}>
			{children}
		</MuiDialogActions>
	);
};

export default DialogActions;
