import React from 'react';

import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';

import CustomersChart from '../CustomersChart';
import RevenueChart from '../RevenueChart';
import VarietiesFilter from '../VarietiesFilter';
import VolumeByYearChart from '../VolumeByYearChart';
import VolumeChart from '../VolumeChart';

const PageBody = () => (
	<>
		<Box paddingBottom="1rem">
			<VarietiesFilter />
		</Box>
		<Grid container spacing={2}>
			<Grid item xs={8}>
				<Card>
					<VolumeChart />
				</Card>
			</Grid>
			<Grid item xs={4}>
				<Card>
					<VolumeByYearChart />
				</Card>
			</Grid>
			<Grid item xs={8}>
				<Card>
					<RevenueChart />
				</Card>
			</Grid>
			<Grid item xs={4}>
				<Card>
					<CustomersChart />
				</Card>
			</Grid>
		</Grid>
	</>
);

export default PageBody;
