import { findSupportedLanguage } from 'Features/localization/utils/findSupportedLanguage';

const contact = {
	en: 'https://calendly.com/rdfarmable/30min',
	de: 'https://calendly.com/max-farmable/demo',
	es: 'https://calendly.com/elena-farmable/30min',
};

export default (languages: readonly string[]): string => {
	const supportedLanguage = findSupportedLanguage(languages);
	const contactLink =
		supportedLanguage in contact ? contact[supportedLanguage] : contact.en;
	return contactLink;
};
