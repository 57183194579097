import proj4 from 'proj4';

function initializeProj4() {
	// in case of adding new CRS -
	// references might be taken from: https://spatialreference.org/
	proj4.defs([
		[
			'EPSG::32632',
			'+proj=utm +zone=32 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
		],
		['OGC:1.3:CRS84', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'],
	]);
}

export default initializeProj4;
