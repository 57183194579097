import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import Permissions from 'Models/permissions/Permissions';
import List from 'UI/display/List';
import SpinnerModal from 'UI/display/modal/spinner';
import CardIcon from 'UI/icons/Card';
import SettingsIcon from 'UI/icons/Settings';
import UsersIcon from 'UI/icons/Users';

import usePermission from 'Hooks/usePermission';

import { ICONS_FILL, getSettingsRoutes } from '../';
import CREATE_CUSTOMER_PORTAL from '../../../../../../features/stripe/api/createCustomerPortal';

function FarmSettingsSection(props: { locationID?: string }) {
	const { locationID } = props;
	const { t } = useTranslation();
	const history = useHistory();
	const [loadCustomerPortal, loadCustomerPortalTask] = useMutation(
		CREATE_CUSTOMER_PORTAL
	);

	const hasFarmSettingsPermission = usePermission(
		Permissions.FARM_SETTINGS_VIEW
	);

	const hasStripeRedirectPermission = usePermission(
		Permissions.STRIPE_REDIRECT
	);

	const currentPath = history.location.pathname;

	const redirectToStripe = useCallback(() => {
		if (locationID) {
			void loadCustomerPortal({
				variables: {
					locationId: locationID,
					input: { returnUrl: window.location.href },
				},
			}).then((res) => {
				if (res.data) {
					window.location.href = res.data.createCustomerPortalSession;
				}
			});
		}
	}, [loadCustomerPortal, locationID]);

	if (!locationID) {
		return null;
	}

	const routes = getSettingsRoutes({ locationID });

	const subscriptionItem = hasStripeRedirectPermission ? (
		<List.Item disablePadding>
			<List.ItemButton onClick={() => redirectToStripe()}>
				<List.ItemIcon>
					<CardIcon fill={ICONS_FILL} />
				</List.ItemIcon>
				<List.ItemText primary={t('common.sidebar.subscriptionsAndPayments')} />
			</List.ItemButton>
			<SpinnerModal open={loadCustomerPortalTask.loading} />
		</List.Item>
	) : null;

	return hasFarmSettingsPermission ? (
		<List.List
			isMenuVariant
			subheader={
				<List.Subheader>{t('common.sidebar.administration')}</List.Subheader>
			}
		>
			<List.Item disablePadding>
				<List.ItemButton
					isActive={currentPath === routes.settings}
					onClick={() => history.push(routes.settings)}
				>
					<List.ItemIcon>
						<SettingsIcon fill={ICONS_FILL} />
					</List.ItemIcon>
					<List.ItemText primary={t('common.sidebar.accountSettings')} />
				</List.ItemButton>
			</List.Item>
			<List.Item disablePadding>
				<List.ItemButton
					isActive={currentPath === routes.members}
					onClick={() => history.push(routes.members)}
				>
					<List.ItemIcon>
						<UsersIcon fill={ICONS_FILL} />
					</List.ItemIcon>
					<List.ItemText primary={t('common.sidebar.manageMembers')} />
				</List.ItemButton>
			</List.Item>
			{subscriptionItem}
		</List.List>
	) : null;
}

export default FarmSettingsSection;
