import { TFieldVarietyInput } from 'Models/fields/isFieldVarietyInput';
import uniqueId from 'lodash/uniqueId';

/**
 * Function which equally (rounding to 1) distributes percentages between all
 * passed varieties. If `activeInputIndex` is passed, then the variety
 * with this index is omitted, and percentages are distributed between
 * remaining varieties. There is also mechanism which avoid a user
 * from passing too high values when it shouldn't be possible.
 * @param {TFieldVarietyInput[]} varieties - array of varieties to recalculate percentages.
 * @param {number} [activeInputIndex] - the index of currently editing variety.
 * @returns {TFieldVarietyInput[]}
 */
export default function getVarietiesWithPercentages(
	varieties: TFieldVarietyInput[],
	activeInputIndex?: number
): TFieldVarietyInput[] {
	const MAX_PERCENTAGE = 100;
	const hasActiveInput = typeof activeInputIndex === 'number';

	const itemsToCalculate = varieties.length - (hasActiveInput ? 1 : 0);
	const maxAllowedActivePercentage = MAX_PERCENTAGE - itemsToCalculate;
	const activePercentage = Math.min(
		(hasActiveInput && varieties[activeInputIndex]?.percentage) || 0,
		maxAllowedActivePercentage
	);
	const undistributedPercents = hasActiveInput
		? MAX_PERCENTAGE - activePercentage
		: MAX_PERCENTAGE;

	const newVarieties: TFieldVarietyInput[] = [];

	const percents = Math.floor(undistributedPercents / itemsToCalculate);
	let rest = undistributedPercents % itemsToCalculate;

	varieties.forEach((variety, i) => {
		const hasRest = rest > 0;
		const uuid = uniqueId();

		if (activeInputIndex === i) {
			newVarieties.push({
				...variety,
				percentage:
					variety.percentage &&
					Math.min(variety.percentage, maxAllowedActivePercentage),
			});
			return;
		}

		newVarieties.push({
			...variety,
			_id: uuid,
			percentage: percents + (hasRest ? 1 : 0),
		});
		if (hasRest) rest--;
	});

	return newVarieties;
}
