import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { TrackEvents, analyticsTrack } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { ExportType } from 'Models/exports/ExportType';
import { TLogFormat } from 'Models/exports/Logs';
import { LogTypes } from 'Models/logs/LogType';
import Divider from 'UI/display/Divider';
import LoadingSpinnerIcon from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useManageHistory from 'Hooks//useManageHistory';
import useCurrentLocation from 'Hooks/useCurrentLocation';
import useLocationID from 'Hooks/useLocationID';
import useProfile from 'Hooks/useProfile';

import exportTimesheetsLog from '../utils/exportTimesheetsLog';

const ExportButton = () => {
	const { t } = useTranslation();
	const { profile } = useProfile();
	const locationID = useLocationID();
	const location = useCurrentLocation();
	const { paramsFromURL } = useManageHistory();
	const { getAccessTokenSilently } = useAuth0();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const tokenPromise = !window.Cypress ? getAccessTokenSilently() : null;
	const prefix = 'logsPages.hoursLogsPage.exportButton.';
	const open = Boolean(anchorEl);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const trackExport = (type: TLogFormat) => {
		if (profile) {
			analyticsTrack(TrackEvents.DataExported, {
				userId: profile.id,
				groupId: locationID,
				exportType: type,
				logType: LogTypes.Jobs,
			});
		}
	};

	const exportLogs = async (type: TLogFormat) => {
		if (location) {
			trackExport(type);
			setIsLoading(true);
			handleClose();
			const token = await tokenPromise;
			await exportTimesheetsLog(
				paramsFromURL,
				location,
				t,
				`Bearer ${token}`,
				type
			);
			setIsLoading(false);
		}
	};

	return (
		<>
			<Button
				id="export-hours-button"
				disabled={isLoading}
				variant="outlined"
				onClick={handleClick}
			>
				{isLoading ? (
					<LoadingSpinnerIcon width={24} height={24} />
				) : (
					t(`${prefix}label`)
				)}
			</Button>
			<Menu.Menu
				id="export-hours-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Menu.Item
					onClick={() => {
						void exportLogs(ExportType.XLSX);
					}}
				>
					{t(`${prefix}items.xls`)}
				</Menu.Item>
				<Divider sx={{ marginY: '0.5rem' }} />
				<Menu.Item
					onClick={() => {
						void exportLogs(ExportType.CSV);
					}}
				>
					{t(`${prefix}items.csv`)}
				</Menu.Item>
			</Menu.Menu>
		</>
	);
};

export default ExportButton;
