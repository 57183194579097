import { TTeamInputPartial } from 'Models/teams/isTeamInput';

export function duplicatedIDs(teamInput: TTeamInputPartial): any[] {
	if (teamInput.teamMemberInput) {
		const seen = new Set();
		return teamInput.teamMemberInput
			.map((member) => member.customId)
			.filter((id) => seen.size === seen.add(id).size);
	}
	return [];
}

export default function hasInputDuplicatedIDs(
	teamInput: TTeamInputPartial
): boolean {
	if (teamInput.teamMemberInput) {
		const seen = new Set();
		return teamInput.teamMemberInput
			.map((member) => member.customId)
			.some((id) => seen.size === seen.add(id).size);
	}
	return false;
}
