import useParams from 'Features/router/hooks/useParams';
import ParamTypes from 'Models/history/paramTypes';

function useFieldID(): ParamTypes['fieldID'] {
	const { fieldID } = useParams();

	return fieldID;
}

export default useFieldID;
