import React from 'react';

import Button from 'UI/inputs/Button';
import { FarmableColors } from 'UI/theme/Colors';

import styles from '../styles.module.scss';

type TButtonProps = {
	onStartLogin: () => void;
	label: string;
};

function EmailLoginButton(props: TButtonProps) {
	return (
		<Button
			variant="text"
			fullWidth
			onClick={props.onStartLogin}
			customTextColor={FarmableColors.BLACK_87}
		>
			<span className={styles.loginButtonDescription}>{props.label}</span>
		</Button>
	);
}

export default EmailLoginButton;
