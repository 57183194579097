import React, { ReactElement } from 'react';

import { TFieldVarietyInput } from 'Models/fields/isFieldVarietyInput';
import Grid from 'UI/layout/Grid';
import isNumber from 'vet/numbers/isNumber';

import PercentageVariety from './PercentageVariety';

type TPercentageVarietiesProps = {
	varieties: TFieldVarietyInput[];
	setVarieties: (varieties: TFieldVarietyInput[]) => void;
};

function shouldShowError(varieties: TFieldVarietyInput[]): boolean {
	const percentsSum = varieties.reduce<number>((acc, current) => {
		return isNumber(current.percentage) ? acc + current.percentage : acc;
	}, 0);
	const showError = percentsSum !== 100;

	return showError;
}

function PercentageVarieties(props: TPercentageVarietiesProps): ReactElement {
	const { varieties, setVarieties } = props;
	const varietiesLen = varieties.length;
	const hasSingleVariety = varietiesLen === 1;

	const showError = shouldShowError(varieties);

	return (
		<Grid container spacing={3}>
			{varieties.map((variety, index) => {
				return (
					<PercentageVariety
						key={variety._id}
						itemIndex={index}
						variety={variety}
						varieties={varieties}
						setVarieties={setVarieties}
						isSingle={hasSingleVariety}
						error={showError}
					/>
				);
			})}
		</Grid>
	);
}

export default PercentageVarieties;
