import React from 'react';

import AddJobDropdown from './AddJobDropdown';
import CategoryAndVarietyDropdown from './CategoryAndVarietyDropdown';
import styles from './styles.module.scss';

function PlannerHeader() {
	return (
		<div className={styles.plannerHeader}>
			<CategoryAndVarietyDropdown />
			<AddJobDropdown />
		</div>
	);
}

export default PlannerHeader;
