import React from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import AddIcon from 'UI/icons/Add';
import SubtractIcon from 'UI/icons/Subtract';
import Button from 'UI/inputs/Button';
import IconButton from 'UI/inputs/IconButton';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import useLocationID from 'Hooks/useLocationID';

import GET_TEAMS from '../../../api/getTeams';
import { teamInputVar } from '../../../state';
import { getTeamMemberId, removeID } from '../../../utils/getTeamMemberId';
import teamMembersByPosition from '../../../utils/teamMembersByPosition';

const useStyles = makeStyles({
	iconButton: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: FarmableColors.BLACK_12,
	},
});

function TeamMembers() {
	const { t } = useTranslation();
	const teamInput = useReactiveVar(teamInputVar);

	const locationId = useLocationID();
	const { data } = useQuery(GET_TEAMS, {
		variables: { locationId },
	});
	const teams = data?.getTeams || [];

	const styles = useStyles();

	function changeMemberIdHandler(index: number, value: string) {
		const newTeamInput = {
			...teamInput,
			teamMemberInput: teamInput?.teamMemberInput?.map(
				(member, memberIndex) => {
					return memberIndex !== index
						? member
						: {
								...member,
								customId: value,
						  };
				}
			),
		};
		teamInputVar(newTeamInput);
	}

	function changeMemberNameHandler(index: number, value: string) {
		const newTeamInput = {
			...teamInput,
			teamMemberInput: teamInput?.teamMemberInput?.map(
				(member, memberIndex) => {
					return memberIndex !== index
						? member
						: {
								...member,
								fullName: value,
						  };
				}
			),
		};
		teamInputVar(newTeamInput);
	}

	function removeTeamMemberHandler(index: number) {
		const newTeamInput = {
			...teamInput,
			teamMemberInput: teamInput?.teamMemberInput?.filter(
				(member, memberIndex) => index !== memberIndex
			),
		};
		const memberToRemove = teamInput?.teamMemberInput?.[index];
		removeID(memberToRemove?.customId);
		teamInputVar(newTeamInput);
	}

	const members = (teamInput?.teamMemberInput || [])
		.slice(0)
		.sort(teamMembersByPosition);

	function addTeamMemberHandler() {
		if (teams) {
			const derivedID = getTeamMemberId(teams);
			const newTeamInput = {
				...teamInput,
				teamMemberInput: [
					...members,
					{
						id: '',
						customId: derivedID.toString().padStart(3, '0'),
						fullName: '',
					},
				],
			};
			teamInputVar(newTeamInput);
		}
	}

	return (
		<>
			<Box pb={2}>
				<Text variant="label">{t('labels.teamMembers')}</Text>
				<Text variant="body2">{t('prompts.membersNotNeedAccount')}</Text>
				{members.map((member, index) => {
					return (
						<Grid
							key={member.id}
							container
							item
							spacing={2}
							alignItems="center"
						>
							<Grid item xs={3}>
								<TextField
									placeholder={t('labels.id')}
									value={member.customId || ''}
									onChange={(value) => changeMemberIdHandler(index, value)}
								/>
							</Grid>
							<Grid item xs={8}>
								<TextField
									placeholder={t('labels.name')}
									value={member.fullName}
									onChange={(value) => changeMemberNameHandler(index, value)}
								/>
							</Grid>
							<Grid item xs={1}>
								<IconButton
									onClick={() => removeTeamMemberHandler(index)}
									size="small"
									className={styles.iconButton}
								>
									<SubtractIcon fontSize="small" color="action" />
								</IconButton>
							</Grid>
						</Grid>
					);
				})}
			</Box>
			<Button
				variant="secondary"
				color="primary"
				startIcon={<AddIcon />}
				onClick={addTeamMemberHandler}
			>
				{t('buttons.addPerson')}
			</Button>
		</>
	);
}

export default TeamMembers;
