import React, { ReactElement, ReactEventHandler } from 'react';

import MuiSwipeableDrawer, {
	SwipeableDrawerProps,
} from '@mui/material/SwipeableDrawer';
import makeStyles from '@mui/styles/makeStyles';

export type TDrawerModal = {
	toggleOpen: ReactEventHandler;
} & Omit<SwipeableDrawerProps, 'onClose' | 'onOpen'>;

const DrawerModal = (props: TDrawerModal): ReactElement => {
	const { children, toggleOpen, ...MUIProps } = props;
	const useDrawerStyles = makeStyles({
		paper: {
			// Set the fixed width (408px) of drawer's content, as all drawers from design are having same width. Consider to add breakpoints (or remove this property) while implementing responsive design.
			width: '25.5rem',
		},
	});

	const { paper } = useDrawerStyles();

	return (
		<MuiSwipeableDrawer
			{...MUIProps}
			classes={{ paper }}
			onClose={toggleOpen}
			onOpen={toggleOpen}
		>
			{children}
		</MuiSwipeableDrawer>
	);
};

export default DrawerModal;
