import { THarvestWithTeams } from './isHarvestWithTeams';

function harvestTeamsNameString(
	harvest: Pick<THarvestWithTeams, 'teams'>,
	hasActiveTimesheetsSubscription?: boolean,
	isExport?: boolean
): string {
	if (!hasActiveTimesheetsSubscription && !isExport) {
		return 'N/A';
	}

	return (
		harvest.teams?.map((team) => team.name).join(isExport ? '\n' : ', ') || '-'
	);
}

export default harvestTeamsNameString;
