import React from 'react';

import useHistory from 'Features/router/hooks/useHistory';
import Text from 'UI/display/Text';
import ArrowBack from 'UI/icons/ArrowBack';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';

import useCompanyID from 'Hooks/useCompanyID';

import styles from '../styles.module.scss';
import LibrarySettingsDropdown from './LibrarySettingsDropdown';
import LibraryShare from './LibraryShare';

function LibraryMenu({ companyName }: { companyName: string }) {
	const history = useHistory();
	const companyID = useCompanyID();

	const onBackClick = () => {
		history.push(`/partners/${companyID}/settings/treatments`);
	};

	return (
		<Box
			marginTop="3.5rem"
			maxWidth="42.5rem"
			display="flex"
			justifyContent="space-between"
			width="100%"
			height="3.5rem"
		>
			<Box className={styles.navbarLink}>
				<IconButton
					onClick={onBackClick}
					size="small"
					className={styles.navbarIcon}
				>
					<ArrowBack />
				</IconButton>
				<Text variant="h3" className={styles.navbarBackTextLink}>
					{companyName}
				</Text>
			</Box>
			<Box display="flex">
				<LibraryShare />
				<LibrarySettingsDropdown />
			</Box>
		</Box>
	);
}

export default LibraryMenu;
