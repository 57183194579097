import {
	GovernmentProductInputSchema,
	TGovernmentProductInputPartial,
} from 'Models/products/GovernmentProductInput';
import { ProductIngredientInputSchema } from 'Models/products/ProductIngredientInput';
import {
	ProductInputSchema,
	TProductInputPartial,
} from 'Models/products/ProductInput';
import { TProductSpec } from 'Models/products/ProductSpec';
import {
	ProductSpecInputSchema,
	SpanishProductSpecInputSchema,
} from 'Models/products/ProductSpecInput';
import { ProductTreatmentInputSchema } from 'Models/products/ProductTreatmentInput';
import { z } from 'zod';

export const AddTreatmentModalStatus = {
	SELECT_PRODUCT_SOURCE: 'SELECT_PRODUCT_SOURCE',
	SELECT_GOV_TREATMENTS: 'SELECT_GOV_TREATMENTS',
	SET_PRODUCT_DETAILS: 'SET_PRODUCT_DETAILS',
	SET_CROP_CATEGORIES: 'SET_CROP_CATEGORIES',
	SELECT_CROP_CATEGORY: 'SELECT_CROP_CATEGORY',
	TREATMENT_DETAILS: 'TREATMENT_DETAILS',
	GOV_TREATMENT_DETAILS: 'GOV_TREATMENT_DETAILS',
};

export type TSelectedGovernmentProductInput = {
	selectedGovernmentProduct: boolean;
	product: TGovernmentProductInputPartial;
	productSpec?: TProductSpec;
};

export type TNewProductInput = {
	selectedGovernmentProduct: boolean;
	product: TProductInputPartial & { governmentProductId?: string; id?: string };
};

type TSelectProductInput = {
	status: typeof AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE;
	input: TNewProductInput;
	editedCategoryName: undefined;
};

export type TSetProductDetailsInput = {
	status: typeof AddTreatmentModalStatus.SET_PRODUCT_DETAILS;
	input: TNewProductInput;
	editedCategoryName: undefined;
};

type TSetCropCategoriesInput = {
	status: typeof AddTreatmentModalStatus.SET_CROP_CATEGORIES;
	input: TNewProductInput;
	editedCategoryName: undefined;
};

export type TSelectCropCategoryInput = {
	status: typeof AddTreatmentModalStatus.SELECT_CROP_CATEGORY;
	input: TNewProductInput;
	editedCategoryName: string | undefined;
};

type TSelectTreatmentsInput = {
	status: typeof AddTreatmentModalStatus.SELECT_GOV_TREATMENTS;
	input: TSelectedGovernmentProductInput;
	editedCategoryName: undefined;
};

export type TTreatmentDetailsInput = {
	status: typeof AddTreatmentModalStatus.TREATMENT_DETAILS;
	input: TNewProductInput;
	editedCategoryName: string;
};

export type TAddTreatmentsModalInput =
	| TSelectProductInput
	| TSetProductDetailsInput
	| TSetCropCategoriesInput
	| TSelectCropCategoryInput
	| TTreatmentDetailsInput
	| TSelectTreatmentsInput;

const commonInput = z.object({
	product: z
		.object({
			name: z.string().min(1),
			productSpec: ProductSpecInputSchema.optional(),
		})
		.or(
			z.object({
				governmentProductId: z.string(),
			})
		),
});

const SpanishProductDetails = z.object({
	status: z.literal(AddTreatmentModalStatus.SET_PRODUCT_DETAILS),
	input: z.object({
		product: z.object({
			name: z.string().min(1),
			productSpec: SpanishProductSpecInputSchema,
			ingredients: z.array(ProductIngredientInputSchema).optional(),
		}),
	}),
});

const AddTreatmentModalSchema = z.discriminatedUnion('status', [
	z.object({
		status: z.literal(AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE),
		input: z.object({
			product: z
				.object({
					name: z.string().min(1),
				})
				.or(
					z.object({
						governmentProductId: z.string(),
					})
				),
		}),
	}),
	z.object({
		status: z.literal(AddTreatmentModalStatus.SET_PRODUCT_DETAILS),
		input: z.object({
			product: z
				.object({
					name: z.string().min(1),
					productSpec: ProductSpecInputSchema.optional(),
					ingredients: z.array(ProductIngredientInputSchema).optional(),
				})
				.or(
					z.object({
						governmentProductId: z.string(),
					})
				),
		}),
	}),
	z.object({
		status: z.literal(AddTreatmentModalStatus.SET_CROP_CATEGORIES),
		input: z.object({
			product: ProductInputSchema.or(GovernmentProductInputSchema),
		}),
	}),
	z.object({
		status: z.literal(AddTreatmentModalStatus.SELECT_CROP_CATEGORY),
		input: commonInput,
	}),
	z.object({
		status: z.literal(AddTreatmentModalStatus.TREATMENT_DETAILS),
		input: z.object({
			product: z
				.object({
					name: z.string().min(1),
					productSpec: ProductSpecInputSchema.optional(),
					treatments: z.array(ProductTreatmentInputSchema),
				})
				.or(
					z.object({
						governmentProductId: z.string(),
						treatments: z.array(ProductTreatmentInputSchema),
					})
				),
		}),
	}),
]);

export const isAddTreatmentModalInput = (input: unknown) =>
	AddTreatmentModalSchema.safeParse(input).success;

export const isSpanishProductDetails = (input: unknown) =>
	SpanishProductDetails.safeParse(input).success;
