// consider importing locales from i18n package in future
enum Locales {
	EN = 'en',
	NO = 'no',
	RU = 'ru',
}

const DEFAULT_DECIMAL_NUMBER = 2;

function numberToDisplayString(
	num?: number | null | string,
	decimals = DEFAULT_DECIMAL_NUMBER,
	locale?: Locales
): string {
	if (typeof num === 'number') {
		const isFloat = num % 1 !== 0;
		switch (locale) {
			// consider preparing unique cases for locales which are requiring
			// internationalization handlers.
			default:
				return num.toFixed(isFloat ? decimals : 0);
		}
	}

	if (typeof num === 'string') {
		return num;
	}

	return '';
}

export default numberToDisplayString;
