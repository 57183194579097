/**
 * Get subtracting lunch duration display value.
 * @argument duration - The minimal duration for subtracting lunch in minutes.
 */
export function lunchDurationString(duration: number) {
	const hours = Math.floor(duration / 60);
	const minutes = duration % 60;

	if (hours && minutes) {
		return `${hours}h ${minutes}m`;
	} else if (hours) {
		return (duration / 60).toString() + 'h';
	} else {
		return minutes.toString() + 'm';
	}
}
