export const PlantMilestone = {
	HIBERNATION_END: 'HIBERNATION_END',
	GROWTH_START: 'GROWTH_START',
	BUDBURST: 'BUDBURST',
	LEAF_OUT: 'LEAF_OUT',
	FLOWERING: 'FLOWERING',
	FRUIT_RIPENING: 'FRUIT_RIPENING',
	LEAF_COLORATION: 'LEAF_COLORATION',
	LEAF_FALL: 'LEAF_FALL',
	HIBERNATION_START: 'HIBERNATION_START',
} as const;

export type TPlantMilestone = keyof typeof PlantMilestone;

export const PlantMilestones = Object.values(PlantMilestone);

export const isPlantMilestone = (value: string): value is TPlantMilestone =>
	Object.keys(PlantMilestone).includes(value);
