import { z } from 'zod';

import { TTeamMemberInput, TeamMemberInputSchema } from './isTeamMemberInput';

const TeamInputSchema = z.object({
	name: z.string().nonempty(),
	teamLeadId: z.string().nonempty(),
	teamMemberInput: z.array(TeamMemberInputSchema).min(1),
});

export type TTeamInput = {
	name: string;
	teamLeadId: string;
	teamMemberInput: TTeamMemberInput[];
};

export type TTeamInputPartial = Partial<{
	name: string;
	teamLeadId: string;
	teamMemberInput: {
		customId?: string;
		id: string;
		fullName: string;
		position?: number;
	}[];
}>;

export const isTeamInput = (input: unknown): input is TTeamInput =>
	TeamInputSchema.safeParse(input).success;

export default isTeamInput;
