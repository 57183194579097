import { ApolloError, useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import GET_OLDEST_HARVEST_BATCH from '../api/getOldestHarvestBatch';

const useOldestBatch = (year: number) => {
	const { paramsFromURL } = useManageHistory();
	const { data, loading, error } = useQuery(GET_OLDEST_HARVEST_BATCH, {
		variables: {
			locationId: paramsFromURL.locationID,
			filterBy: {
				dateFrom: `${year}-01-01`,
				dateTo: `${year}-12-31`,
				varieties: paramsFromURL.filters.fieldVarieties,
				onlySoldBatches: true,
			},
			pageInput: {
				limit: 1,
				offset: 0,
				sortBy: [{ direction: 'ASC', field: 'date' }],
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	return { data, loading };
};

export default useOldestBatch;
