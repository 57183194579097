import { TLocation } from '../locations/isLocation';
import convertAreaUnit from '../units/convertAreaUnit';
import roundToTwoDecimal from '../units/roundToTwoDecimal';
import { TJobFieldSnapshot } from './isJobFieldSnapshot';

type Converter = (...args) => number;

function jobArea(
	job: {
		jobFields?: Pick<TJobFieldSnapshot, 'id' | 'areaSize'>[] | null;
	},
	location?: Pick<TLocation, 'areaUnit'> | null,
	converter: Converter = convertAreaUnit,
	// List of IDs of fields where area should be calculated from.
	fieldIDs?: string[]
) {
	return roundToTwoDecimal(
		job.jobFields
			?.filter((field) => (fieldIDs ? fieldIDs.includes(field.id) : true))
			.map((field) => converter(field.areaSize, location?.areaUnit))
			.reduce((previous, current) => previous + current, 0) || 0
	);
}

export default jobArea;
