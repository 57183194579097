import isOneOf from 'vet/isOneOf';

export const SupportedLangs = {
	en: 'en',
	de: 'de',
	es: 'es',
	nb: 'nb',
	nl: 'nl',
} as const;

export type TSupportedLang = keyof typeof SupportedLangs;
const _isSupportedLang = isOneOf(...Object.values(SupportedLangs));

export const isSupportedLang: typeof _isSupportedLang = _isSupportedLang;
