import makeStyles from '@mui/styles/makeStyles';

import { FarmableColors } from '../../../theme/Colors';
import typography from '../../../theme/typography';

const useTextElementsStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	small: {
		fontSize: '14px',
		lineHeight: '20px',
		color: FarmableColors.BLACK_87,
		fontFamily: typography.fontFamily,
	},
	medium: {
		fontSize: '16px',
		lineHeight: '24px',
		color: FarmableColors.BLACK_87,
		fontWeight: 'bold',
		fontFamily: typography.fontFamily,
	},
	large: {
		fontSize: '40px',
		lineHeight: '48px',
		color: FarmableColors.BLACK_87,
		fontFamily: typography.fontFamily,
	},
});

export default useTextElementsStyles;
