import React, { Fragment, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	PartnershipLocationStatus,
	TPartnerLocation,
} from 'Models/partnershipCompany/isPartnerLocation';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors, FarmableTextColors } from 'UI/theme/Colors';

import usePartnerLocations from 'Hooks/usePartnerLocations';

import FarmListItem from './components/FarmListItem';
import StatusFilter from './components/StatusFilter';
import styles from './styles.module.scss';

const DEFAULT_FILTERS = {
	[PartnershipLocationStatus.ACTIVE]: false,
	[PartnershipLocationStatus.DEACTIVATED]: false,
	[PartnershipLocationStatus.PENDING]: false,
	[PartnershipLocationStatus.DECLINED]: false,
};

function ManageFarms() {
	const { t } = useTranslation();
	const { partnerLocations, loading } = usePartnerLocations();
	const [filters, setFilters] = useState(DEFAULT_FILTERS);
	const [searchValue, setSearchValue] = useState('');
	const hasAppliedFilters = Object.values(filters).some((applied) => applied);

	const applyFilters = (farms: TPartnerLocation[]) => {
		return farms
			.filter((item) => {
				return hasAppliedFilters ? filters[item.status] : true;
			})
			.filter((item) =>
				item.name.toUpperCase().includes(searchValue.toUpperCase())
			);
	};

	const onFilterChange = (value: string, active: boolean): void => {
		setFilters({
			...filters,
			[value]: active,
		});
	};

	const farmsList = applyFilters(partnerLocations || []).map(
		(farm, index, array) => (
			<Fragment key={farm.id}>
				<FarmListItem
					name={farm.name}
					status={farm.status}
					farmerEmail={farm.farmerEmail}
					id={farm.id}
				></FarmListItem>
				{index !== array.length - 1 ? <Divider /> : null}
			</Fragment>
		)
	);

	const noConnectedFarmsInfo = (
		<Box p="1rem 0.5rem">
			<Text variant="secondaryBody2">
				{t('partnerDashboardPage.manageFarmsSection.noFarmsInfo')}
			</Text>
		</Box>
	);

	const farmsListSection = farmsList.length ? farmsList : noConnectedFarmsInfo;

	const farmsSearchInput = (
		<FreeTextSearch
			inputPlaceholder={t('connectedFarmsList.searchFarmsPlaceholder')}
			onChange={(newValue: string): void => setSearchValue(newValue)}
			onClear={() => setSearchValue('')}
			value={searchValue}
			sx={{
				height: '2rem',
				boxShadow: 'none',
				border: `1px solid ${FarmableColors.BLACK_12}`,
				width: '13.875rem',
			}}
		/>
	);

	const tableHeaders = (
		<Box className={styles.tableHeaders}>
			<Text variant="label5" sx={{ marginRight: '1rem' }}>
				{t('partnerDashboardPage.manageFarmsSection.headers.farm')}
			</Text>
			<Grid container>
				<Grid item xs={8} />
				<Grid item xs={3}>
					<Text variant="label5">
						{t('partnerDashboardPage.manageFarmsSection.headers.status')}
					</Text>
				</Grid>
			</Grid>
		</Box>
	);

	return loading ? (
		<Box display="flex" justifyContent="center" padding="1rem">
			<LoadingSpinner width={40} height={40} />
		</Box>
	) : (
		<>
			<Box
				pb="0.5rem"
				paddingX="1rem"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Text color={FarmableTextColors.PRIMARY} variant="secondaryBody2">
					{t('partnerDashboardPage.manageFarmsSection.farmsAmount', {
						count: partnerLocations?.length || 0,
					})}
				</Text>
				<Box display="flex" flexDirection="row">
					<StatusFilter
						onChange={onFilterChange}
						onClear={() => {
							setFilters(DEFAULT_FILTERS);
						}}
						filters={filters}
					/>
					{farmsSearchInput}
				</Box>
			</Box>
			{tableHeaders}
			<Box paddingX="1rem" className={styles.farmsList}>
				{farmsListSection}
			</Box>
		</>
	);
}

export default ManageFarms;
