import React, { useEffect, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CLEARED_FILTERS, URLFiltersType } from 'Models/history/isURLFilters';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import useManageHistory from 'Hooks/useManageHistory';

import Categories from '../../../../components/filters/Categories';
import Date from '../../../../components/filters/Date';
import FieldsList from '../../../../components/filters/FieldsList';
import Season from '../../../../components/filters/Season';
import Statuses from '../../../../components/filters/Statuses';
import Treatments from '../../../../components/filters/Treatments';
import Varieties from '../../../../components/filters/Varieties';
import Drawer from '../../common/components/Drawer';
import { JOB_FILTER_KEYS } from '../constants/jobFilterKeys';
import ExportButtonValidator from './ExportButtonValidator';

const FilterModal = () => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const { historyPush, paramsFromURL } = useManageHistory();
	const paramsFilters = paramsFromURL.filters;
	const prefix = 'logsPages.filtersModal.sections.';

	const [filters, setFilters] = useState<URLFiltersType>({
		...CLEARED_FILTERS,
	});

	const toggleOpen = () => {
		setOpen((prevState) => !prevState);
	};

	useEffect(() => {
		setFilters({ ...paramsFilters });
	}, [open]);

	const onSearch = () => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFilters,
				...JOB_FILTER_KEYS.reduce(
					(acc, key) => ({ ...acc, [key]: filters[key] }),
					{} as URLFiltersType
				),
			},
			pageInput: {
				...paramsFromURL.pageInput,
				offset: 0,
			},
		};
		historyPush(newSearchParams);
		toggleOpen();
	};

	const onClear = () => {
		setFilters({ ...CLEARED_FILTERS });
	};

	return (
		<>
			<Button
				startIcon={<OptionsIcon />}
				variant="secondary"
				onClick={toggleOpen}
			>
				{t('buttons.filters')}
			</Button>
			<Drawer.Container onHideClick={toggleOpen} open={open}>
				<Drawer.Header onHideClick={toggleOpen} />
				<Drawer.Content>
					<Drawer.ContentSection title={t(`labels.season`)}>
						<Season filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection title={t(`${prefix}date`)}>
						<Date filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection title={t(`${prefix}filters`)}>
						<Grid container spacing={1}>
							<Grid item>
								<Categories filters={filters} updateFilters={setFilters} />
							</Grid>
							<Grid item>
								<Varieties
									filters={filters}
									updateFilters={setFilters}
									paramName="fieldVarieties"
								/>
							</Grid>
							<Grid item>
								<Treatments filters={filters} updateFilters={setFilters} />
							</Grid>
						</Grid>
					</Drawer.ContentSection>
					<Drawer.ContentSection title={t(`${prefix}status`)}>
						<Statuses filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection title={t(`${prefix}fields`)}>
						<FieldsList filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
				</Drawer.Content>
				<Drawer.Footer>
					<Button variant={'text'} onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={onSearch}>{t('buttons.save')}</Button>
				</Drawer.Footer>
			</Drawer.Container>
		</>
	);
};

export default FilterModal;
