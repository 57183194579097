import { TRoles } from 'Models/permissions/Roles';

type TLocationRole = {
	role: TRoles;
	locationId: string;
};

type TPartnershipCompanyRole = {
	role: TRoles;
	companyId: string;
};

export type TProfileRole = TLocationRole | TPartnershipCompanyRole;

export function isPartnershipCompanyRole(
	role: TProfileRole
): role is TPartnershipCompanyRole {
	return 'companyId' in role;
}

export function isLocationRole(role: TProfileRole): role is TLocationRole {
	return 'locationId' in role;
}

function hasPartnerCompany(userRoles: TProfileRole[]): boolean {
	const partnerRole = userRoles?.find((userRole) =>
		isPartnershipCompanyRole(userRole)
	);

	return Boolean(partnerRole);
}

export default hasPartnerCompany;
