import { gql, TypedDocumentNode } from '@apollo/client';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';

import { treatmentLibraryTreatmentFragmentFields } from './fragments';

type TUpdateLibraryTreatmentResult = {
	updateLibraryTreatment: TTreatmentLibrary;
};

type TUpdateLibraryTreatmentInput = {
	libraryId: string;
	productId: string;
	treatmentId: string;
	input: TProductTreatmentInput;
};

export const UPDATE_LIBRARY_TREATMENT: TypedDocumentNode<
	TUpdateLibraryTreatmentResult,
	TUpdateLibraryTreatmentInput
> = gql`
	mutation updateLibraryTreatment(
		$libraryId: ID!
		$productId: ID!
		$treatmentId: ID!
		$input: ProductTreatmentInput!
	) {
		updateLibraryTreatment(
			libraryId: $libraryId
			productId: $productId
			treatmentId: $treatmentId
			input: $input
		) {
			${treatmentLibraryTreatmentFragmentFields}
		}
	}
`;
