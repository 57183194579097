import { ApolloError, gql, TypedDocumentNode, useQuery } from '@apollo/client';
import { TLocations } from 'Models/locations/isLocations';

export type TGetLocationsResponse = {
	locations: Omit<TLocations, 'invitedBy'>[];
};

const GET_LOCATIONS: TypedDocumentNode<TGetLocationsResponse, null> = gql`
	query getLocations {
		locations {
			id
			name
			relation
			areaUnit
			owner {
				id
				firstName
				lastName
				profileImage {
					createdAt
					filename
					id
					path
					mimetype
				}
			}
		}
	}
`;
function useLocations() {
	const { data, loading, error } = useQuery(GET_LOCATIONS);

	if (error) {
		throw new ApolloError(error);
	}
	return { locations: data?.locations || null, loading };
}

export default useLocations;
