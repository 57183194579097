import isArrayOf from 'vet/arrays/isArrayOf';
import isOneOf from 'vet/isOneOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isNotEmpty from 'vet/strings/isNotEmpty';
import isString from 'vet/strings/isString';
import { ValidatorType } from 'vet/types';

import { isGeometry } from './isField';
import { isFieldType } from './isFieldType';
import isFieldVarietyInput, {
	isSerializedFieldVarietyInput,
} from './isFieldVarietyInput';
import { ProductionMethod } from './isProductionMethod';

const _isFieldInput = isShape({
	name: isNotEmpty,
	fieldType: isFieldType,
	geometry: isGeometry,
	averageDistanceBetweenTreesInRows: optional(isOneOf(isString, isNumber)),
	averageDistanceBetweenRows: optional(isOneOf(isString, isNumber)),
	treeSupplier: optional(isString),
	rootstock: optional(isString),
	numberOfPlants: optional(isNumber),
	plantHeight: optional(isOneOf(isString, isNumber)),
	productiveArea: optional(isOneOf(isString, isNumber)),
	varieties: isArrayOf(isFieldVarietyInput),
	productionMethod: optional(isOneOf(...Object.values(ProductionMethod))),
});

const isFieldInput: typeof _isFieldInput = _isFieldInput;

export type TFieldInput = ValidatorType<typeof isFieldInput>;

const _isSerializedFieldInput = isShape({
	name: isNotEmpty,
	fieldType: isFieldType,
	geometryInput: { polygon: isGeometry },
	averageDistanceBetweenTreesInRows: optional(isNumber),
	averageDistanceBetweenRows: optional(isNumber),
	treeSupplier: optional(isString),
	rootstock: optional(isString),
	numberOfPlants: optional(isNumber),
	plantHeight: optional(isNumber),
	productiveArea: optional(isNumber),
	varieties: isArrayOf(isSerializedFieldVarietyInput),
	productionMethod: optional(isOneOf(...Object.values(ProductionMethod))),
});

export const isSerializedFieldInput: typeof _isSerializedFieldInput =
	_isSerializedFieldInput;

export type TSerializedFieldInput = ValidatorType<
	typeof isSerializedFieldInput
>;

export default isFieldInput;
