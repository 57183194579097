import { TTimesheetTableData } from '../../pages/logs/HoursLogsPage/types';
import getAccountName from '../account/getAccountName';

function timesheetMember<TGenericTimesheet>(
	timesheet: TGenericTimesheet &
		Pick<TTimesheetTableData, 'teamMember' | 'teamLead'>
): string {
	const { teamMember, teamLead } = timesheet;

	if (!teamMember) {
		const name = getAccountName(teamLead);
		return name || '-';
	}

	return teamMember.fullName;
}

export default timesheetMember;
