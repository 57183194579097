import { gql, TypedDocumentNode } from '@apollo/client';
import { TCost } from 'Models/sales/isCost';

export type TAggregatedProductsCostResponse = {
	getAggregatedProductsCost: {
		productsCosts: TCost[];
	};
};

type TAggregatedProductsCostRequest = {
	locationId: string;
	filterBy: {
		dateFrom?: string;
		dateTo?: string;
		fieldId?: string;
	};
};

const GET_AGGR_PRODUCTS_COST_QUERY: TypedDocumentNode<
	TAggregatedProductsCostResponse,
	TAggregatedProductsCostRequest
> = gql`
	query getProfitChartAggregatedProductsCost(
		$locationId: ID!
		$filterBy: AggregatedProductsCostFilterInput
	) {
		getAggregatedProductsCost(locationId: $locationId, filterBy: $filterBy) {
			productsCosts {
				totalCost
				currency
				month
			}
		}
	}
`;

export default GET_AGGR_PRODUCTS_COST_QUERY;
