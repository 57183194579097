import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TProduct } from 'Models/products/Product';

const TYPENAME = 'Product' as const;

type TDeleteTreatmentLibraryProductResult = {
	deleteTreatmentLibraryProduct: TProduct;
};

type TDeleteTreatmentLibraryProductInput = {
	libraryId: string;
	productId: string;
};

export const DELETE_TREATMENT_LIBRARY_PRODUCT: TypedDocumentNode<
	TDeleteTreatmentLibraryProductResult,
	TDeleteTreatmentLibraryProductInput
> = gql`
	mutation deleteTreatmentLibraryProduct($libraryId: ID!, $productId: ID!) {
		deleteTreatmentLibraryProduct(
			libraryId: $libraryId
			productId: $productId
		) {
			id
		}
	}
`;

export const removeDeletedTreatmentLibraryProduct: MutationUpdaterFunction<
	TDeleteTreatmentLibraryProductResult,
	TDeleteTreatmentLibraryProductInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }, { variables }) => {
	const isDeleted = data?.deleteTreatmentLibraryProduct.id;

	if (isDeleted && variables?.productId) {
		const normalizedId = cache.identify({
			id: variables.productId,
			__typename: TYPENAME,
		});
		cache.evict({ id: normalizedId });
		cache.gc();
	}
};
