import { FarmableColors, FarmableTextColors } from './Colors';

export default {
	primary: {
		main: FarmableColors.ORANGE,
		dark: FarmableColors.ORANGE_DARK,
	},
	error: {
		main: FarmableColors.RED,
	},
	success: {
		main: FarmableColors.GREEN,
	},
	text: {
		primary: FarmableTextColors.PRIMARY,
		secondary: FarmableTextColors.SECONDARY,
		disabled: FarmableTextColors.DISABLED,
	},
	divider: FarmableColors.DIVIDER,
};
