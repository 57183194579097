import React, { Fragment } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { sortByCreatedDateDesc } from 'Models/api/PageInput';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import RecentEvent from '../../../../../components/RecentEvent';
import getListedFields from '../../utils/getListedFields';
import GET_PARTNER_RECENT_EVENTS from './api/getPartnerRecentEvents';
import styles from './styles.module.scss';

const limit = 10;

type TPartnerRecentEvents = {
	locationIds?: string[] | null;
};

const PartnerRecentEvents = (props: TPartnerRecentEvents) => {
	const { locationIds } = props;
	const { t } = useTranslation();
	const { data, loading, error, fetchMore } = useQuery(
		GET_PARTNER_RECENT_EVENTS,
		{
			variables: {
				filterBy: {
					locationIds,
				},
				pageInput: {
					limit,
					offset: 0,
					sortBy: sortByCreatedDateDesc,
				},
			},
		}
	);

	const total = data?.getPartnershipNotifications.totalElements || 0;
	const notifications = data?.getPartnershipNotifications.notifications || [];
	const showLoadMoreButton = data && notifications.length < total;

	if (error) {
		throw new ApolloError(error);
	}

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" padding="1rem">
				<LoadingSpinner width={40} height={40} />
			</Box>
		);
	}

	if (total === 0) {
		return (
			<Text variant="secondaryBody2">{t('notifications.noRecentEvents')}</Text>
		);
	}

	if (notifications) {
		const list = notifications.map((notification) => {
			const fields = getListedFields(notification);
			const fieldsString = fields.map((field) => field.name).join(', ');

			// Don't display the notification if related fields are removed
			return fields.length ? (
				<Fragment key={notification.id}>
					<Box paddingY="0.875rem">
						<RecentEvent
							account={notification.createdBy}
							action={notification.type}
							createdAt={notification.createdAt}
							fields={fieldsString}
							//todo: #3268
							//location={location?.name}
						/>
					</Box>
					<Divider />
				</Fragment>
			) : null;
		});

		return (
			<Box className={styles.eventsList}>
				{list}
				{showLoadMoreButton ? (
					<Box display="flex" justifyContent="center" padding="1rem">
						<Button
							variant="text"
							onClick={() =>
								void fetchMore({
									variables: {
										pageInput: {
											limit,
											offset: notifications.length,
											sortBy: sortByCreatedDateDesc,
										},
									},
								})
							}
						>
							{t('buttons.showMore')}
						</Button>
					</Box>
				) : null}
			</Box>
		);
	}

	return null;
};

export default PartnerRecentEvents;
