import { FarmableColors } from 'UI/theme/Colors';

import { TJob } from './isJob';

export default function (job: Pick<TJob, 'jobStatus'>): FarmableColors {
	switch (job.jobStatus) {
		case 'COMPLETED':
			return FarmableColors.GREEN;
		case 'CANCELED':
			return FarmableColors.BLACK_60;
		case 'IN_PROGRESS':
			return FarmableColors.ORANGE;
		case 'PAUSED':
			return FarmableColors.ORANGE;
		case 'NOT_STARTED':
			return FarmableColors.RED;
		case 'DRAFT':
			return FarmableColors.BLUE;
		default:
			return FarmableColors.BLACK_60;
	}
}
