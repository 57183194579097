import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import GET_GOVERNMENT_CAUSES, {
	TGovernmentCause,
} from '../api/getGovernmentCauses';

type TSelectCauseProps = {
	selectedCause?: TGovernmentCause | null;
	parentModalStateHandler: React.Dispatch<
		React.SetStateAction<TGovernmentCause | null>
	>;
};

export default function SelectCause(props: TSelectCauseProps) {
	const { selectedCause, parentModalStateHandler } = props;
	const { company } = usePartnerCompany();
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState(selectedCause?.name || '');

	const { data, loading, error } = useQuery(GET_GOVERNMENT_CAUSES, {
		variables: company?.country
			? {
					filterBy: {
						country: company?.country,
					},
			  }
			: undefined,
	});

	if (error) {
		throw new ApolloError(error);
	}

	const matchesSearchAndNonSelected = (cause: TGovernmentCause) =>
		cause.name.toUpperCase().includes(searchValue.toUpperCase()) &&
		cause.id !== selectedCause?.id;

	const filteredCauses = data?.governmentCauses.causes
		.filter(matchesSearchAndNonSelected)
		.map((cause) => (
			<List.Item
				onClick={() => {
					parentModalStateHandler(cause);
					setSearchValue(cause.name);
				}}
				key={cause.id}
				sx={{
					cursor: 'pointer',
					'&.MuiListItem-root:hover': {
						backgroundColor: FarmableColors.BLACK_4,
					},
				}}
			>
				{cause.name}
			</List.Item>
		));

	const causesList =
		!searchValue || selectedCause ? null : (
			<Box sx={{ overflow: 'auto', height: '16rem' }}>
				<List.List>
					{filteredCauses?.length ? (
						filteredCauses
					) : (
						<Text sx={{ paddingX: '1rem' }} variant="secondaryBody2">
							{t('prompts.noRegisteredCauses')}
						</Text>
					)}
				</List.List>
			</Box>
		);
	return loading ? (
		<Box
			display="flex"
			width="100%"
			justifyContent="center"
			alignItems="center"
			overflow="hidden"
		>
			<LoadingSpinner width={80} height={80} />
		</Box>
	) : (
		<>
			<Box
				display="flex"
				flexDirection="column"
				sx={{ overflow: 'hidden', width: '28rem' }}
			>
				<FreeTextSearch
					inputPlaceholder={t('causesPage.modal.existingCause.selectCause')}
					onChange={(newValue: string): void => {
						parentModalStateHandler(null);
						setSearchValue(newValue);
					}}
					onClear={() => {
						parentModalStateHandler(null);
						setSearchValue('');
					}}
					value={searchValue}
					sx={{
						height: '2.25rem',
						boxShadow: 'none',
						border: `1px solid ${FarmableColors.BLACK_12}`,
						margin: '1rem',
					}}
				/>
				{causesList}
			</Box>
		</>
	);
}
