import React, { FC, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { ReportType } from 'Models/exports/Report';
import { filterFieldsByCategory } from 'Models/fields/filterFieldsByCategory';
import getLocationVarieties from 'Models/fields/getLocationVarieties';
import { ALL as STATUSES, TJobStatuses } from 'Models/jobs/JobStatuses';
import Chip from 'UI/display/Chip';
import Text from 'UI/display/Text';
import DateRangePicker from 'UI/inputs/DateRangePicker';
import { TDateRange } from 'UI/inputs/DateRangePicker/Controlled';
import DropdownWithChips, { TOptions } from 'UI/inputs/DropdownWithChips';
import Box from 'UI/layout/Box';
import {
	endOfMonth,
	endOfYear,
	parseISO,
	startOfMonth,
	startOfYear,
	subYears,
} from 'date-fns';

import getDateFnsLocale from 'Utils/getDateFnsLocale';
import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

import { TFieldCategory } from '../../../../../components/filters/common/api/getFields';
import { ReportFormInterface } from '../../CreateReport.types';

interface CreateReportModalFormInterface {
	reportInput: ReportFormInterface;
	onChange: (value: ReportFormInterface) => void;
	filteredFields: TFieldCategory[];
	categoriesOptions: TOptions[];
	teamMembersOptions?: TOptions[];
	productInventoryOptions: TOptions[];
	teamsOptions?: TOptions[];
	qualityOptions?: TOptions[];
	reportType: ReportType;
}

export const CreateReportModalForm: FC<CreateReportModalFormInterface> = ({
	reportInput,
	onChange,
	filteredFields,
	categoriesOptions,
	teamMembersOptions = [],
	teamsOptions = [],
	qualityOptions = [],
	productInventoryOptions = [],
	reportType,
}) => {
	const { t, i18n } = useTranslation();

	const {
		dateFrom,
		dateTo,
		status,
		categories,
		fields,
		teamId,
		teamMemberIds,
		varieties,
		quality,
		products,
	} = reportInput;
	const [isDatePickerOpened, setDatePickerOpened] = useState(false);
	const handleDateChange = useCallback(
		(value: TDateRange) => {
			onChange({
				...reportInput,
				dateFrom: value.startDate
					? new Date(value?.startDate).toISOString()
					: '',
				dateTo: value.endDate ? new Date(value?.endDate).toISOString() : '',
			});
		},
		[onChange, reportInput]
	);

	const handleChangeStatus = (value: TJobStatuses[]) => {
		onChange({
			...reportInput,
			status: value,
		});
	};

	const handleChangeCategories = (value: string[]) => {
		onChange({
			...reportInput,
			fields: [],
			varieties: [],
			categories: value,
		});
	};

	const handleChangeQuality = (value: string[]) => {
		onChange({
			...reportInput,
			quality: value,
		});
	};

	const handleChangeFields = (value: string[]) => {
		onChange({
			...reportInput,
			fields: value,
		});
	};

	const handleChangeInventoryProducts = (value: string[]) => {
		onChange({
			...reportInput,
			products: value,
		});
	};

	const handleChangeTeams = (value: string[]) => {
		onChange({
			...reportInput,
			teamId: value,
			teamMemberIds: [],
		});
	};

	const handleChangeTeamMembers = (value: string[]) => {
		onChange({
			...reportInput,
			teamMemberIds: value,
		});
	};

	const handleChangeVarieties = (value: string[]) => {
		onChange({
			...reportInput,
			varieties: value,
		});
	};

	const toggleDatePicker = useCallback(() => {
		setDatePickerOpened((prevState) => !prevState);
	}, []);

	const fieldsOptions = useMemo(
		() =>
			(categories.length
				? filterFieldsByCategory(filteredFields, categories).map(
						({ name, id }) => ({
							name,
							id,
						})
				  )
				: filteredFields
			).map(({ name, id }) => ({ id, label: name })),
		[categories, filteredFields]
	);

	const productOptions = useMemo(
		() =>
			(categories.length
				? filterFieldsByCategory(filteredFields, categories).map(
						({ name, id }) => ({
							name,
							id,
						})
				  )
				: filteredFields
			).map(({ name, id }) => ({ id, label: name })),
		[categories, filteredFields]
	);

	const varietiesOptions = useMemo(
		() =>
			(categories.length
				? getLocationVarieties(
						filterFieldsByCategory(filteredFields, categories).map(
							(variety) => variety
						)
				  )
				: getLocationVarieties(filteredFields)
			).map((variety) => ({ id: variety, label: variety })),
		[categories, filteredFields]
	);

	const statusOptions = useMemo(
		() => STATUSES.map((status) => ({ id: status, label: status })),
		[]
	);

	return (
		<>
			<Text fontSize="0.875rem" fontWeight="bold">
				{t('logsPages.filtersModal.sections.date')}
			</Text>
			<Box display="flex" flexDirection="column" marginTop="0.5rem">
				<Box marginBottom="0.5rem" display="flex" gap="0.5rem">
					<Chip
						label={t('labels.thisYear')}
						onClick={() =>
							handleDateChange({
								startDate: startOfYear(new Date()),
								endDate: endOfYear(new Date()),
							})
						}
					/>
					<Chip
						label={t('labels.lastYear')}
						onClick={() =>
							handleDateChange({
								startDate: startOfYear(subYears(new Date(), 1)),
								endDate: endOfYear(subYears(new Date(), 1)),
							})
						}
					/>
					<Chip
						label={t('labels.lastMonth')}
						onClick={() =>
							handleDateChange({
								startDate: startOfMonth(new Date()),
								endDate: endOfMonth(new Date()),
							})
						}
					/>
				</Box>
				<DateRangePicker.Controlled
					value={{
						startDate: dateFrom ? parseISO(dateFrom) : null,
						endDate: dateTo ? parseISO(dateTo) : null,
					}}
					open={isDatePickerOpened}
					toggleOpen={toggleDatePicker}
					startDatePlaceholder={t('labels.dateFrom')}
					endDatePlaceholder={t('labels.dateTo')}
					onChangeValue={handleDateChange}
					noBackground
					locale={getDateFnsLocale(i18n.language)}
				/>
				{[ReportType.SPRAYING, ReportType.FERTILIZER].includes(reportType) && (
					<>
						<Box marginTop="1.5rem">
							<Text fontSize="0.875rem" lineHeight="1.25rem" fontWeight="bold">
								{t('logsPages.jobLogsPage.tableHeaders.status')}
							</Text>
							<Box marginTop="0.5rem">
								<DropdownWithChips
									disabled
									label={t('logsPages.jobLogsPage.tableHeaders.status')}
									options={statusOptions}
									selected={status}
									selectedControl={handleChangeStatus}
									translateValueFunc={stringToFirstUppercase}
								/>
							</Box>
						</Box>

						<Box marginTop="1.5rem">
							<Text fontSize="0.875rem" lineHeight="1.25rem" fontWeight="bold">
								{t('logsPages.filtersModal.sections.optional')}
							</Text>
							<Box marginTop="0.5rem">
								<DropdownWithChips
									label={t(
										'logsPages.harvestLogsPage.detailsModal.props.category'
									)}
									options={categoriesOptions}
									selected={categories}
									selectedControl={handleChangeCategories}
								/>
							</Box>
						</Box>
					</>
				)}

				{reportType === ReportType.TIMESHEET && (
					<Box marginTop="1rem">
						<Text fontSize="0.875rem" lineHeight="1.25rem" fontWeight="bold">
							{t('logsPages.filtersModal.sections.optional')}
						</Text>
						<Box marginTop="0.5rem">
							<DropdownWithChips
								label={t('logsPages.harvestLogsPage.tableHeaders.teams')}
								options={teamsOptions}
								selected={teamId}
								selectedControl={handleChangeTeams}
							/>
						</Box>
						<Box marginTop="0.5rem">
							<DropdownWithChips
								label={t('logsPages.harvestLogsPage.tableHeaders.teamMembers')}
								options={teamMembersOptions}
								selected={teamMemberIds}
								selectedControl={handleChangeTeamMembers}
							/>
						</Box>
					</Box>
				)}
				{reportType === ReportType.HARVEST && (
					<Box marginTop="1rem">
						<Text fontSize="0.875rem" lineHeight="1.25rem" fontWeight="bold">
							{t('logsPages.filtersModal.sections.optional')}
						</Text>
						<Box marginTop="0.5rem">
							<DropdownWithChips
								label={t(
									'logsPages.harvestLogsPage.detailsModal.sections.quality'
								)}
								options={qualityOptions}
								selected={quality}
								multiple={false}
								selectedControl={handleChangeQuality}
							/>
						</Box>
						<Box marginTop="0.5rem">
							<DropdownWithChips
								label={t(
									'logsPages.harvestLogsPage.detailsModal.props.category'
								)}
								options={categoriesOptions}
								selected={categories}
								selectedControl={handleChangeCategories}
							/>
						</Box>
						<Box marginTop="0.5rem">
							<DropdownWithChips
								label={t(
									'logsPages.jobLogsPage.detailsModal.sections.varieties'
								)}
								options={varietiesOptions}
								selected={varieties}
								selectedControl={handleChangeVarieties}
							/>
						</Box>
					</Box>
				)}
				{reportType === ReportType.PRODUCT_INVENTORY ? (
					<Box marginTop="1rem">
						<DropdownWithChips
							label={t(
								'logsPages.harvestLogsPage.detailsModal.sections.products'
							)}
							options={productInventoryOptions}
							selected={products}
							selectedControl={handleChangeInventoryProducts}
						/>
					</Box>
				) : (
					<Box marginTop="1rem">
						<DropdownWithChips
							label={t(
								'logsPages.harvestLogsPage.detailsModal.sections.fields'
							)}
							options={fieldsOptions}
							selected={fields}
							selectedControl={handleChangeFields}
						/>
					</Box>
				)}
			</Box>
		</>
	);
};
