import { ApolloError, useQuery } from '@apollo/client';
import { gql, TypedDocumentNode } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { TProfile } from 'Models/profile/isProfile';

export type TGetProfileResponse = {
	profile: TProfile;
};

const GET_PROFILE: TypedDocumentNode<TGetProfileResponse, null> = gql`
	query getProfile {
		profile {
			id
			email
			firstName
			lastName
			mobilePhone
			profileImage {
				path
			}
			notes
			hasEmailSubscription
			createdAt
			locale
			isReferralCodeRegistered
			intercomHash {
				intercomHashWeb
			}
			enabledCompanyType
		}
	}
`;

type TParams = {
	noError: boolean;
};

function useProfile(params?: TParams) {
	const { isAuthenticated } = useAuth0();
	const { data, loading, error } = useQuery(GET_PROFILE, {
		skip: !isAuthenticated && !window.Cypress && !window.auth0?.client,
	});

	// noError is used to avoid throwing error inside of an error boundary,
	// so we avoid infinite loop of errors
	if (error && !params?.noError) {
		throw new ApolloError(error);
	}

	return {
		profile: data?.profile || null,
		loading,
	};
}

export default useProfile;
