import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import DateField from 'UI/inputs/DateField';
import TextField from 'UI/inputs/TextField';
import { isValid, parseISO } from 'date-fns';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import getDateFnsLocale from 'Utils/getDateFnsLocale';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import { treatmentInputState } from '../../../../../state';

function AdditionalDetails() {
	const { t } = useTranslation();
	const treatmentInput = useReactiveVar(treatmentInputState);
	const { company } = usePartnerCompany();
	const isGovTreatment = !!treatmentInput?.governmentTreatmentId;

	if (!treatmentInput) {
		return null;
	}

	const { registrationNumber, licenseExpirationDate } = treatmentInput;
	const locale = getDateFnsLocale(company?.country);

	return (
		<ModalSection title={t('labels.additionalDetails')}>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
				<TextField
					sx={{
						marginBottom: '1rem',
					}}
					disabled={isGovTreatment}
					placeholder={t(
						'treatmentLibrary.cropModal.placeholder.treatmentRegistrationNumber'
					)}
					value={registrationNumber}
					onChange={(value: string) =>
						treatmentInputState({
							...treatmentInput,
							registrationNumber: value,
						})
					}
					margin="dense"
				/>
				<DateField
					value={
						licenseExpirationDate ? parseISO(licenseExpirationDate) : undefined
					}
					disabled={isGovTreatment}
					onChange={(value: Date | null) => {
						if (isValid(value)) {
							treatmentInputState({
								...treatmentInput,
								licenseExpirationDate: value?.toISOString() || undefined,
							});
						}
					}}
					label={t('treatmentLibrary.cropModal.placeholder.expirationDate')}
					format="dd.MM.yyyy"
					fullWidth
					margin="dense"
				/>
			</LocalizationProvider>
		</ModalSection>
	);
}

export default AdditionalDetails;
