import React, { ReactElement } from 'react';

import MUITab, { TabProps } from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles';

import { FarmableColors } from '../../theme/Colors';

type TTabProps = {
	label: ReactElement | string;
	className?: string;
	variant?: string;
	selectionFollowsFocus?: boolean;
	selected?: boolean;
	value?: number | string;
};

// // Those props are passed by the Tabs component (it is handled by MUI)
// // We need them for handleClick() and handleFocus() functions,
// // without which the component will not function properly.
// type TMUITabProps = {
// 	onChange?: (event: React.ChangeEvent<{ checked: boolean }>, value: any) => void,
// 	onClick?: React.EventHandler<any>,
// 	onFocus?: React.EventHandler<any>,
// 	selectionFollowsFocus?: boolean,
// 	selected?: boolean,
// 	variant?: string,
// 	value?: number | string,
// };

const Tab = (props: TTabProps & TabProps): ReactElement => {
	const { label, className, variant, ...MUITabProps } = props;

	const useStyles = makeStyles({
		root: {
			fontWeight: 'bold',
			minWidth: '0 !important',
			padding: '0 1.5rem',
		},
		rootRounded: {
			fontWeight: 'bold',
			maxWidth: 'fit-content',
			whiteSpace: 'nowrap',
			'&:hover': {
				color: FarmableColors.ORANGE,
			},
			minWidth: '0 !important',
			minHeight: '2.25rem',
			borderColor: 'transparent',
			borderWidth: '1px',
			borderTopLeftRadius: '0.5rem',
			borderTopRightRadius: '0.5rem',
			padding: '0 1.5rem',
			borderStyle: 'solid',
			fontSize: '0.875rem',
		},
		selectedRounded: {
			color: FarmableColors.ORANGE,
			borderColor: FarmableColors.DIVIDER,
			borderWidth: '1px',
			borderBottomColor: FarmableColors.WHITE,
			borderStyle: 'solid',
		},
		selected: {
			color: `${FarmableColors.ORANGE} !important`,
		},
	});

	const styles = useStyles();

	return (
		<MUITab
			label={label}
			className={className}
			classes={{
				root: variant === 'rounded' ? styles.rootRounded : styles.root,
				selected:
					variant === 'rounded' ? styles.selectedRounded : styles.selected,
			}}
			{...MUITabProps}
		/>
	);
};

export default Tab;
