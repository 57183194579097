import { TCustomersChartData } from '../api/getCustomersChartData';

type TChartData = {
	y: number;
	name: string;
};

type TMultiCurrencyChartData = {
	custom: {
		currency: string;
	};
	type: 'bar';
	data: TChartData[];
};

const getMultipleCurrencyChartData = function (
	data: TCustomersChartData[]
): TMultiCurrencyChartData[] {
	return [...data]
		.sort((a, b) => {
			return b.totalRevenue - a.totalRevenue;
		})
		.reduce<TMultiCurrencyChartData[]>((accumulatedSeries, chartData) => {
			const existingSeries = accumulatedSeries.find(
				(s) => s.custom.currency === chartData.currency
			);

			const customer = {
				name: chartData.customer.name,
				y: chartData.totalRevenue,
			};

			if (existingSeries) {
				return accumulatedSeries.map((series) => {
					if (existingSeries.custom.currency === series.custom.currency) {
						return {
							...series,
							data: [...series.data, customer],
						};
					} else {
						return series;
					}
				});
			}

			return [
				...accumulatedSeries,
				{
					custom: {
						currency: chartData.currency,
					},
					type: 'bar',
					data: [customer],
				},
			];
		}, []);
};

export default getMultipleCurrencyChartData;
