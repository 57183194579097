const DAY = 'DAY';
const NIGHT = 'NIGHT';
const ANY = 'ANY';

export const TreatmentTime = {
	DAY,
	NIGHT,
	ANY,
} as const;

export const TreatmentTimes = [DAY, NIGHT, ANY] as const;

export type TProductTreatmentTime = keyof typeof TreatmentTime;
