import _uniq from 'lodash/uniq';

import { TJob } from './isJob';

function jobCropVarieties(job: TJob) {
	const { jobFields } = job;

	if (jobFields && jobFields.length > 0) {
		return (
			_uniq(
				jobFields
					.map((field) => field.fieldVarieties?.map((variety) => variety.name))
					.flat()
			).join(', ') || '-'
		);
	} else {
		return '-';
	}
}

export default jobCropVarieties;
