import { HarvestCountingUnit } from '../units';
import roundToTwoDecimal from '../units/roundToTwoDecimal';
import { THarvest } from './types/Harvest';

function harvestWeight(
	harvest: Pick<
		THarvest,
		'weight' | 'unit' | 'countingUnit' | 'volume' | 'boxSize'
	>
): string | number {
	if (
		harvest.countingUnit === HarvestCountingUnit.BOXES &&
		harvest.volume &&
		harvest.boxSize
	) {
		return roundToTwoDecimal(harvest.volume * harvest.boxSize);
	} else if (
		harvest.countingUnit === HarvestCountingUnit.WEIGHT &&
		harvest.weight
	) {
		return harvest.weight;
	}

	return '-';
}

export default harvestWeight;
