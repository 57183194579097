import React, { ReactElement } from 'react';

import MUITab, { TabProps } from '@mui/material/Tab';

export type TItem = TabProps;

const Tab = (props: TItem): ReactElement => {
	return <MUITab {...props} disableRipple />;
};

export default Tab;
