import { gql, TypedDocumentNode } from '@apollo/client';
import { TCauseInput } from 'Models/causes/isCause';

type TCreatePartnershipCauseResponse = {
	createPartnershipCause: {
		id: string;
	};
};

type TCreatePartnershipCauseInput = {
	input: TCauseInput;
};

const CREATE_PARTNERSHIP_CAUSE: TypedDocumentNode<
	TCreatePartnershipCauseResponse,
	TCreatePartnershipCauseInput
> = gql`
	mutation createPartnershipCause($input: CauseInput!) {
		createPartnershipCause(input: $input) {
			id
		}
	}
`;

export default CREATE_PARTNERSHIP_CAUSE;
