import React from 'react';

import NavLink from 'Features/router/components/NavLink';
import Box from 'UI/layout/Box';
import { Location, LocationState } from 'history';
import { match } from 'react-router';

import styles from './styles.module.scss';

type TNavbarLink = {
	label: string;
	link: string;
	checkActiveHandler: <Params extends { [K in keyof Params]?: string }>(
		match: match<Params>,
		location: Location<LocationState>
	) => boolean;
};

const NavbarLink = (props: TNavbarLink) => {
	const { link, label, checkActiveHandler } = props;

	return (
		<NavLink
			className={styles.navbarLink}
			activeClassName={styles.navbarLinkActive}
			to={link}
			isActive={checkActiveHandler}
		>
			<Box p="1rem 1.5rem">{label}</Box>
		</NavLink>
	);
};

export default NavbarLink;
