import { HarvestCountingUnit } from '../units';
import roundToTwoDecimal from '../units/roundToTwoDecimal';
import { toDisplayString } from '../units/transpileUnits';
import { THarvest } from './types/Harvest';

function harvestWeightWithUnit(
	harvest: Pick<
		THarvest,
		'weight' | 'unit' | 'countingUnit' | 'volume' | 'boxSize'
	>
): string {
	if (!harvest.unit) {
		return '-';
	}

	const unit = toDisplayString(harvest.unit);

	if (harvest.countingUnit === HarvestCountingUnit.BOXES) {
		return harvest.volume && harvest.boxSize
			? `${roundToTwoDecimal(harvest.volume * harvest.boxSize)} ${unit}`
			: '-';
	} else if (harvest.countingUnit === HarvestCountingUnit.WEIGHT) {
		return harvest.weight && harvest.unit
			? `${harvest.weight} ${toDisplayString(harvest.unit)}`
			: '-';
	}

	return '-';
}

export default harvestWeightWithUnit;
