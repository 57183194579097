import React, { ReactElement } from 'react';

import Permissions from 'Models/permissions/Permissions';

import usePermission from 'Hooks/usePermission';

import NoAccessPage from '../../../../no-access/NoAccessPage';

type TPageValidatorProps = {
	children: ReactElement;
};

const PageAccessValidator = (props: TPageValidatorProps): ReactElement => {
	const { children } = props;

	const hasDashboardPageAccess = usePermission(
		Permissions.DASHBOARD_PAGE_ACCESS
	);

	return <>{hasDashboardPageAccess ? children : <NoAccessPage />}</>;
};

export default PageAccessValidator;
