import { ApolloError, gql, TypedDocumentNode, useQuery } from '@apollo/client';

import { TProfileRole } from 'Utils/hasPartnerCompany';

export type TProfileRolesResponse = {
	profileRoles: TProfileRole[];
};

const GET_PROFILE_ROLES: TypedDocumentNode<TProfileRolesResponse, null> = gql`
	query profileRoles {
		profileRoles {
			... on LocationRole {
				role
				locationId
			}
			... on PartnershipCompanyRole {
				role
				companyId
			}
		}
	}
`;

function useProfileRoles() {
	const { data, loading, error } = useQuery(GET_PROFILE_ROLES);

	if (error) {
		throw new ApolloError(error);
	}

	return {
		profileRoles: data?.profileRoles || [],
		loading,
	};
}

export default useProfileRoles;
