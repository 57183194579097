import React from 'react';

import { useReactiveVar } from '@apollo/client';
import useCurrentLanguage from 'Features/localization/hooks/useCurrentLanguage';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	ProductTypes,
	TProductType,
	ProductType as ProductTypeValues,
} from 'Models/products/ProductInput';
import Text from 'UI/display/Text';
import Select from 'UI/inputs/Select';
import Box from 'UI/layout/Box';
import { FarmableTextColors } from 'UI/theme/Colors';

import { addTreatmentPageState } from '../../../../../state';
import { TAddTreatmentsModalInput } from '../../../../../types';

const ProductType = () => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const currentLang = useCurrentLanguage();

	const typesTranslation = t('productTypes', {
		returnObjects: true,
		lng: currentLang,
	});

	return !state.input.selectedGovernmentProduct ? (
		<Box marginTop="0.5rem">
			<Select
				value={ProductTypeValues.OTHER}
				disabled
				label={
					<Text color={FarmableTextColors.SECONDARY}>
						{t('treatmentLibrary.addTreatmentModal.treatmentType')}
					</Text>
				}
				options={ProductTypes}
				optionFormatter={(mil) => typesTranslation[mil]}
				changeHandler={(type: TProductType) => {
					if (!state.input.selectedGovernmentProduct) {
						addTreatmentPageState({
							...state,
							input: {
								...state.input,
								product: {
									...state.input.product,
									type,
								},
							},
						} as TAddTreatmentsModalInput);
					}
				}}
				small
			/>
		</Box>
	) : null;
};

export default ProductType;
