import React from 'react';

import Link from 'Features/router/components/Link';
import { TImage } from 'Models/image/isImage';
import Avatar from 'UI/display/Avatar';
import { Item } from 'UI/navigation/Sidebar';
import { FarmablePlaceholdersColors } from 'UI/theme/Colors';

import stringToInitials from '../../../utils/stringToInitials';

const colors = Object.values(FarmablePlaceholdersColors);

type TProps = {
	id: string;
	name: string | null | undefined;
	index: number;
	baseRoute: string;
	onClick: (val: number) => void;
	active: boolean;
	defaultRoute?: string;
	image?: Pick<TImage, 'id' | 'path'> | null;
	directRoute?: string;
};

// disable should be a temp prop until we implement create company page
const SidebarItem = ({
	id,
	name,
	image,
	baseRoute,
	active,
	index,
	defaultRoute,
	directRoute,
	onClick,
}: TProps) => (
	<Item
		onClick={() => onClick(index)}
		style={{
			opacity: 1,
			pointerEvents: 'none',
		}}
		icon={
			<Link
				to={(location) => {
					if (directRoute) {
						return directRoute;
					}
					// match any subroute that comes after id, ending with a lowercase letter
					// example: farm-sales, logs/jobs, fields, dashboard, new/deeper/route/example
					const postfix: string | undefined = location.pathname.match(
						RegExp(`${baseRoute}/[a-f0-9\\-]+/([a-z/-]*[a-z]+)`)
					)?.[1];

					// override some deep routes so user won't see access-sensitive pages too often
					const enabledSubRoute = postfix?.startsWith('logs')
						? 'logs/jobs'
						: postfix?.startsWith('dashboard/fields')
						? 'dashboard'
						: postfix;

					return `${baseRoute}/${id}/${enabledSubRoute || defaultRoute || ''}`;
				}}
				style={{
					pointerEvents: 'auto',
				}}
			>
				<Avatar
					alt={name || ''}
					title={name || ''}
					src={image?.path}
					size="md"
					variant={active ? 'rounded' : 'circular'}
					color={colors[(parseInt(id, 10) || 0) % colors.length]}
				>
					{stringToInitials(name || '')}
				</Avatar>
			</Link>
		}
	/>
);

export default SidebarItem;
