import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getAccountName from 'Models/account/getAccountName';
import { TAccount } from 'Models/account/isAccount';
import Avatar from 'UI/display/Avatar';
import { FarmableTextColors } from 'UI/theme/Colors';
import { TAvatarSizes } from 'UI/theme/Sizes';
import { makeStyles } from 'UI/utils/makeStyles';
import { formatDistanceToNow } from 'date-fns';

import UTCToLocalTime from 'Utils/UTCToLocalTime';
import getDateFnsLocale from 'Utils/getDateFnsLocale';

import CommentConnector from './components/CommentConnector';

type TCommentProps = {
	note: string | ReactElement;
	account: TAccount;
	createdAt: string;
	isLast: boolean;
	isYourPost: boolean;
	avatarSize?: TAvatarSizes;
};

const Comment = (props: TCommentProps): ReactElement => {
	const { note, account, createdAt, isLast, isYourPost, avatarSize } = props;
	const { t, i18n } = useTranslation();

	const dateFnsOptions = {
		locale: getDateFnsLocale(i18n.language),
		addSuffix: true,
	};

	const avatarSrc = account?.profileImage?.path || undefined;
	const createdAtLocalTime = UTCToLocalTime(String(createdAt));
	const formattedCreatedAt = formatDistanceToNow(
		createdAtLocalTime,
		dateFnsOptions
	);

	const name = getAccountName(account);
	const nameFirstLetter = name?.charAt(0).toUpperCase();

	const useCommentStyles = makeStyles({
		commentContainer: {
			display: 'grid',
			gridTemplateColumns: 'fit-content(40px) auto',
			gridTemplateRows: 'repeat(2, auto)',
			gridColumnGap: '0.75rem',
			gridRowGap: '0px',
			marginBottom: '0.75rem',
		},
		commentAvatarContainer: {
			gridArea: '1/1/2/2',
			display: 'flex',
			alignItems: 'center',
		},
		commentTitle: {
			fontWeight: 'bold',
			color: FarmableTextColors.PRIMARY,
		},
		commentTimestamp: {
			fontSize: '0.75rem',
			color: FarmableTextColors.SECONDARY,
		},
		commentNote: {
			color: FarmableTextColors.PRIMARY,
		},
		commentHeader: {
			gridArea: '1 / 2 / 2 / 3',
		},
		commentConnectorContainer: {
			gridArea: '2 / 1 / 3 / 2',
			marginLeft: '-1px',
			marginTop: '0.5rem',
		},
		commentNoteContainer: {
			gridArea: '2 / 2 / 3 / 3',
		},
		commentYourPostAnnotation: {
			fontWeight: 'normal',
		},
	});

	const styles = useCommentStyles();

	const isYourPostString = isYourPost ? (
		<span className={styles.commentYourPostAnnotation}>
			({t('labels.you')})
		</span>
	) : null;

	return (
		<article className={styles.commentContainer}>
			<div className={styles.commentAvatarContainer}>
				<Avatar size={avatarSize} src={avatarSrc}>
					{nameFirstLetter}
				</Avatar>
			</div>
			<section className={styles.commentHeader}>
				<p className={styles.commentTitle}>
					{name} {isYourPostString}
				</p>
				<p className={styles.commentTimestamp}>{formattedCreatedAt}</p>
			</section>
			<div className={styles.commentConnectorContainer}>
				<CommentConnector hidden={isLast} />
			</div>
			<section className={styles.commentNoteContainer}>
				<p className={styles.commentNote}>{note}</p>
			</section>
		</article>
	);
};

export default Comment;
