enum FarmableColors {
	DIVIDER = 'rgb(224, 224, 224)',
	ORANGE = '#d83e14',
	ORANGE_DARK = '#be3611',
	ORANGE_50 = '#e0a08d',
	ORANGE_12 = '#f7e8e3',
	ORANGE_38 = '#f0b5a9',
	ORANGE_13_13 = '#ffccbe',
	ORANGE_13_22 = '#ffa78e',
	ORANGE_13_31 = '#fc8463',
	ORANGE_13_42 = '#f05f38',
	ORANGE_13_72 = '#ba2f09',
	ORANGE_8 = '#d83e1414',
	RED = '#e10007',
	GREEN = '#008767',
	BLUE = '#1D60C8',
	LIGHT_BLUE = '#1a6aff',
	PALE_BLUE = '#7fbef6',
	PEACH_PALE = '#ffedb4',
	BLACK_87 = '#212121',
	BLACK_60 = '#666666',
	BLACK_38 = '#9e9e9e',
	BLACK_24 = '#c2c2c2',
	BLACK_12 = '#e0e0e0',
	BLACK_4 = '#f3f3f3',
	WHITE = '#ffffff',
	GREY = '#f8f9fa',
	LIGHT_GREY = '#fafafa',
	NAVY = '#6349d6',
	SANDY_BROWN = '#f89a5a',
	ROYAL_PINK = '#eb3693',
	AZURE = '#007dee',
}

export type TFarmableColors = keyof typeof FarmableColors;

enum FarmableTextColors {
	PRIMARY = 'rgba(0, 0, 0, 0.87)',
	SECONDARY = 'rgba(0, 0, 0, 0.6)',
	DISABLED = 'rgba(0, 0, 0, 0.38)',
	GREEN = '#008767',
	ORANGE = '#d83e14',
	BLUE = '#1D60C8',
	WHITE = 'rgba(255, 255, 255, 0.87)',
	RED = '#FF3A2E',
	WHITE_NO_OPACITY = '#ffffff',
}

export type TTextColors = keyof typeof FarmableTextColors;

// Colors used for placeholder elements in the mocks.
const FarmablePlaceholdersColors = {
	RED: '#FF3A2E',
	PINK: '#E91C63',
	PURPLE: '#B440C6',
	DEEP_PURPLE: '#6349D6',
	BLUE: '#4285F4',
	GREEN: '#009688',
	LIGHT_GREEN: '#4CAE52',
	ORANGE: '#FB7500',
} as const;

const FarmableTagsColors = {
	RED: 'rgba(225, 0, 7, 0.12)',
	LIGHT_GREEN: 'rgba(0, 135, 103, 0.12)',
} as const;

export const AllPlaceholderColors = Object.values(FarmablePlaceholdersColors);

export {
	FarmableColors,
	FarmableTextColors,
	FarmablePlaceholdersColors,
	FarmableTagsColors,
};
