import { TypedDocumentNode, gql } from '@apollo/client';
import { TAccountInput } from 'Models/account/isAccountInput';

type TInvitePartnershipUserResult = {
	invitePartnershipUser: {
		id: string;
	};
};

type TInvitePartnershipUserInput = {
	input: Pick<TAccountInput, 'email'> & Partial<Omit<TAccountInput, 'email'>>;
};

const INVITE_PARTNERSHIP_USER: TypedDocumentNode<
	TInvitePartnershipUserResult,
	TInvitePartnershipUserInput
> = gql`
	mutation invitePartnershipUser($input: AccountInput!) {
		invitePartnershipUser(input: $input) {
			id
		}
	}
`;

export default INVITE_PARTNERSHIP_USER;
