import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTreatmentLibraryInput } from 'Models/treatmentLibrary/isTreatmentLibraryInput';
import Modal from 'UI/display/modal/dialog';
import AddIcon from 'UI/icons/Add';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

type TAddOrEditLibraryModalProps = {
	actionHandler: (input: TTreatmentLibraryInput, callback: () => void) => void;
	isLoading: boolean;
	isEditVersion?: boolean;
	partnerTreatmentLibrary?: TTreatmentLibraryInput;
};

function AddOrEditLibraryModal(props: TAddOrEditLibraryModalProps) {
	const { actionHandler, isEditVersion, isLoading, partnerTreatmentLibrary } =
		props;
	const [name, setName] = useState(partnerTreatmentLibrary?.name || '');
	const [open, setOpen] = useState(false);

	const { t } = useTranslation();

	const mainText = isEditVersion
		? t('buttons.editInfo')
		: t('treatmentLibrary.buttons.addLibrary');
	const actionText = isEditVersion ? t('labels.edit') : t('buttons.create');
	const onClick = () => {
		const input = {
			name,
		};
		actionHandler(input, () => {
			setName('');
			setOpen(false);
		});
	};

	return (
		<>
			{isEditVersion ? (
				<Menu.Item onClick={() => setOpen(true)}>
					{t('buttons.editInfo')}
				</Menu.Item>
			) : (
				<Button
					variant="secondary"
					onClick={() => setOpen(true)}
					color="primary"
					startIcon={<AddIcon />}
				>
					{t('treatmentLibrary.buttons.addLibrary')}
				</Button>
			)}
			<Modal.UncontrolledModal
				open={open}
				toggleOpen={() => {
					setOpen(!open);
				}}
			>
				<Modal.DialogTitle
					title={mainText}
					closeIconHandler={() => {
						setOpen(false);
					}}
				/>
				<Modal.DialogContent>
					<TextField
						label={t('treatmentLibrary.modal.libraryName')}
						value={name}
						onChange={setName}
					/>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button
							variant="text"
							onClick={() => {
								setOpen(false);
							}}
						>
							{t('buttons.cancel')}
						</Button>
						<Button
							variant="outlined"
							disabled={!name.length || isLoading}
							startIcon={isLoading ? <LoadingIcon /> : null}
							onClick={onClick}
						>
							{actionText}
						</Button>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}

export default AddOrEditLibraryModal;
