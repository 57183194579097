import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { filterFieldsByCategory } from 'Models/fields/filterFieldsByCategory';
import { URLFiltersType } from 'Models/history/isURLFilters';
import Permissions from 'Models/permissions/Permissions';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';

import CheckboxLabel from '../../fields/CheckboxLabel';
import {
	GET_FIELDS_QUERY,
	GET_FIELDS_ADVISOR_QUERY,
} from '../common/api/getFields';
import parseGetFieldsResult from '../common/utils/parseGetFieldsResult';

type TFieldsListProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const FieldsList = (props: TFieldsListProps) => {
	const { updateFilters, filters } = props;

	const locationId = useLocationID();
	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY
	);

	const { data, loading, error } = useQuery(
		hasPermissionsToRegularQuery ? GET_FIELDS_QUERY : GET_FIELDS_ADVISOR_QUERY,
		{
			variables: {
				locationId: locationId,
			},
		}
	);

	if (loading) {
		return <LoadingSpinner width={64} height={64} />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const selectedFields = filters.fieldIds || [];
		const filtersCategories = filters.categories;

		const handleChange = (fieldId: string) => {
			const isSelected = selectedFields.includes(fieldId);
			const newFields = isSelected
				? selectedFields.filter((f) => f !== fieldId)
				: [...selectedFields, fieldId];

			updateFilters({
				...filters,
				fieldIds: newFields,
			});
		};

		const fields = parseGetFieldsResult(data);
		const filteredFields = filtersCategories
			? filterFieldsByCategory(fields, filtersCategories)
			: fields;

		return (
			<Box display="flex" flexDirection="column">
				{filteredFields.map((field) => {
					const isSelected = selectedFields.includes(field.id);

					return (
						<Checkbox.Controlled
							key={field.id}
							label={<CheckboxLabel field={field} />}
							checked={isSelected}
							onChange={() => handleChange(field.id)}
							size="small"
							dense
							fullWidth
						/>
					);
				})}
			</Box>
		);
	}

	return null;
};

export default FieldsList;
