import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import { useReactToPrint } from 'react-to-print';

type TSprayLogHeaderProps = {
	printRef: React.MutableRefObject<null>;
	onAfterPrintCallback?: () => void;
};

const SprayLogHeader = (props: TSprayLogHeaderProps) => {
	const { printRef, onAfterPrintCallback } = props;
	const { t } = useTranslation();

	const handlePrint = useReactToPrint({
		content: () => printRef?.current || null,
		onAfterPrint: () => {
			if (onAfterPrintCallback) onAfterPrintCallback();
		},
	});

	return (
		<>
			<Box
				display="flex"
				width="100%"
				justifyContent="space-between"
				padding="1.5rem"
			>
				<Text variant="h1">{t('treatmentLogsExportPage.title')}</Text>
				<Button onClick={handlePrint}>
					{t('treatmentLogsExportPage.printButton')}
				</Button>
			</Box>
		</>
	);
};

export default SprayLogHeader;
