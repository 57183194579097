import React from 'react';

import Table from 'UI/display/Table';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import TableBody from './TableBody';
import TableHeaders from './TableHeaders';

const JobLogsTable = () => (
	<Table.Container style={{ overflow: 'auto', flex: 1 }}>
		<Table.Table aria-label="Jobs logs table">
			<TableHeaders />
			<ErrorBoundary withinComponent>
				<TableBody />
			</ErrorBoundary>
		</Table.Table>
	</Table.Container>
);

export default JobLogsTable;
