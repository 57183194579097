import { gql, TypedDocumentNode } from '@apollo/client';

type TDeletePartnershipCauseImageResponse = {
	deletePartnershipCauseImage: boolean;
};

type TDeletePartnershipCauseImageInput = {
	causeId: string;
	fileId: string;
};

const DELETE_PARTNERSHIP_CAUSE_IMAGE: TypedDocumentNode<
	TDeletePartnershipCauseImageResponse,
	TDeletePartnershipCauseImageInput
> = gql`
	mutation DeletePartnershipCauseImage($causeId: ID!, $fileId: ID!) {
		deletePartnershipCauseImage(causeId: $causeId, fileId: $fileId)
	}
`;

export default DELETE_PARTNERSHIP_CAUSE_IMAGE;
