import { TFieldSearchMap } from '../../../../components/fields/FieldLayers';
import { TAdvisorDashboardFieldsResponse } from '../api/getAdvisorDashboardFields';
import { TMemberDashboardFieldsResponse } from '../api/getMemberDashboardFields';

function parseResponseData(
	response?: TAdvisorDashboardFieldsResponse | TMemberDashboardFieldsResponse
): TFieldSearchMap[] {
	if (!response) {
		return [];
	}
	if ('getFields' in response) {
		return response.getFields.fields;
	}
	if ('getFieldsByUsersFieldCategories' in response) {
		return response.getFieldsByUsersFieldCategories;
	}
	return [];
}

export default parseResponseData;
