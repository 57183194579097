import { TSeasonParams } from 'Models/seasons/isSeasonParams';

import { TDataPerCurrencyMapping } from '..';
import {
	TCostsByCurrency,
	TRevenuesByCurrency,
	TProfitsByCurrency,
	TChartData,
	TData,
} from '../types';
import _findMatchingData from './findMatchingData';
import _getTotalMonths from './getTotalMonths';

// Function which fills up the data with missing months to avoid gaps on chart.
function _fulfillData(
	data: TData[],
	lastAvailableMonth: Date,
	seasonParams: TSeasonParams
) {
	const newData: TChartData[] = [];
	const [firstYear, secondYear] = seasonParams.seasonName
		.split('/')
		.map((year) => parseInt(year));

	const startingMonthIndex =
		parseInt(seasonParams.seasonCutoffDate.substring(0, 2)) - 1;
	const totalMonths = _getTotalMonths(seasonParams, lastAvailableMonth);

	let lookForMonthIndex = startingMonthIndex;
	let lookForYear = firstYear;

	for (let counter = 0; counter < totalMonths; counter++) {
		// Reset counter when the year ends, and dump the year.
		if (lookForMonthIndex === 12) {
			lookForMonthIndex = 0;
			lookForYear = secondYear;
		}

		const foundMonth = _findMatchingData(data, lookForMonthIndex, lookForYear);

		newData.push({
			x: counter,
			y: foundMonth ? foundMonth.y : 0,
		});

		lookForMonthIndex++;
	}

	return newData;
}

const findCurrency = (code) => (data: { currency: string }) =>
	data.currency === code;

function mapChartData(
	costs: TCostsByCurrency[],
	revenues: TRevenuesByCurrency[],
	profits: TProfitsByCurrency[],
	seasonParams: TSeasonParams
) {
	// Find last month in season, where the data occurs.
	const lastMonthInSeasonTimestamp = profits.reduce<number>(
		(extremeMonth, currentProfit) => {
			const months = currentProfit.profits.map((profit) => new Date(profit.x));
			const lastMonth = Math.max(...months.map(Number));

			return Math.max(lastMonth, extremeMonth);
		},
		0
	);
	const lastMonthInSeason = new Date(lastMonthInSeasonTimestamp);

	const currencyCodes = profits.map((profit) => profit.currency);

	return currencyCodes.reduce<TDataPerCurrencyMapping>(
		(chartDataPerCurrency, currencyCode) => {
			const costByCurrency = costs.find(findCurrency(currencyCode));
			const revenueByCurrency = revenues.find(findCurrency(currencyCode));
			const profitByCurrency = profits.find(findCurrency(currencyCode));

			// Fill up the data with missing months to avoid gaps on chart
			const profit = _fulfillData(
				profitByCurrency?.profits || [],
				lastMonthInSeason,
				seasonParams
			);
			const cost = _fulfillData(
				costByCurrency?.costs || [],
				lastMonthInSeason,
				seasonParams
			);
			const revenue = _fulfillData(
				revenueByCurrency?.revenues || [],
				lastMonthInSeason,
				seasonParams
			);

			return {
				...chartDataPerCurrency,
				[currencyCode]: {
					...chartDataPerCurrency[currencyCode],
					profit,
					cost,
					revenue,
				},
			};
		},
		{}
	);
}

export default mapChartData;
