import React, { useRef, useState, ReactElement, useEffect } from 'react';

import Chart, { TChartProps, TChartRef } from '../Chart';

type TCircularProgressChartProps = {
	value: number;
};

const CircularProgressChart = (
	props: TCircularProgressChartProps
): ReactElement => {
	const { value } = props;
	const chartComponent = useRef<TChartRef>(null);

	const initialConfig: TChartProps['options'] = {
		chart: {
			margin: 0,
			height: 40,
			width: 40,
		},

		title: undefined,

		credits: {
			enabled: false,
		},

		pane: [
			{
				startAngle: 0,
				endAngle: 180,
				background: [
					{
						backgroundColor: 'white',
						borderWidth: 0,
					},
				],
			},
			{
				startAngle: 180,
				endAngle: 360,
				background: [
					{
						backgroundColor: 'white',
						borderWidth: 0,
					},
				],
			},
		] as any,

		tooltip: {
			enabled: false,
		},

		plotOptions: {
			solidgauge: {
				enableMouseTracking: false,
				dataLabels: {
					enabled: true,
					format: '{y}%',
					y: -12,
					borderWidth: 0,
				},
			},
		},

		yAxis: [
			{
				min: 0,
				max: 50,
				lineWidth: 0,
				tickPositions: [],
			},
			{
				min: 50,
				max: 100,
				lineWidth: 0,
				tickPositions: [],
				pane: 1,
			},
		],

		series: [
			{
				type: 'solidgauge',
				data: [
					{
						color: {
							linearGradient: [0, 0, 0, 65] as any,
							stops: [
								[0.1, 'lightgreen'],
								[0.6, 'green'],
							],
						},
						y: 0,
						radius: '115%',
						innerRadius: '95%',
					},
				],
				animation: {
					easing: (pos) => pos,
				},
			},
			{
				type: 'solidgauge',
				data: [
					{
						color: {
							linearGradient: [0, 70, 0, 0] as any,
							stops: [
								[0.5, 'green'],
								[0.75, 'darkgreen'],
							],
						},
						y: 0,
						radius: '115%',
						innerRadius: '95%',
					},
				],
				yAxis: 1,
				animation: {
					defer: 900,
					duration: 850,
				},
			},
		],
	};

	const [chartOptions, setChartOptions] = useState(initialConfig);

	useEffect(() => {
		if (value <= 50) {
			setChartOptions({
				series: [
					{
						type: 'solidgauge',
						data: [
							{
								y: value,
							},
						],
					},
				],
			});
		} else if (value > 50) {
			setChartOptions({
				series: [
					{
						type: 'solidgauge',
						data: [
							{
								y: 50,
							},
						],
						dataLabels: {
							enabled: false,
						},
					},
					{
						type: 'solidgauge',
						data: [
							{
								y: value,
							},
						],
					},
				],
			});
		}
	}, [value]);

	return <Chart ref={chartComponent} options={chartOptions} />;
};

export default CircularProgressChart;
