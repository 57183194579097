import React, { ReactElement } from 'react';

import { TImage } from 'Models/image/isImage';
import CloseIcon from 'UI/icons/Close';

import styles from './styles.module.scss';

type TSelectedFile = {
	file: File;
	preview: string;
};

type TImagesPreview = {
	images: TImage[] | TSelectedFile[];
	onDelete?: (index: number) => void;
	imagesAlt: string;
};

const ImagesPreview = (props: TImagesPreview): ReactElement => {
	const { images, onDelete, imagesAlt } = props;

	return (
		<div className={styles.previewGallery}>
			{images.map((file, index) => (
				<div key={file.preview || file.id} className={styles.previewContainer}>
					<img
						src={file.preview || file.path || 'wrong path'}
						alt={imagesAlt || file.filename || 'farmable image'}
						className={styles.previewImage}
					/>
					{onDelete ? (
						<div
							onClick={() => onDelete(index)}
							className={styles.deletePreview}
						>
							<CloseIcon fontSize="small" />
						</div>
					) : null}
				</div>
			))}
		</div>
	);
};

export default ImagesPreview;
