import React, { useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProfile } from 'Models/profile/isProfile';
import Text from 'UI/display/Text';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import PhoneNumber from 'UI/inputs/PhoneNumber';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import CardActions from 'UI/surfaces/Card/CardActions';
import CardContent from 'UI/surfaces/Card/CardContent';

import UPDATE_ACCOUNT from '../api/updateAccount';
import ProfileAvatar from './ProfileAvatar';

type TProps = {
	profile: Pick<
		TProfile,
		'firstName' | 'lastName' | 'id' | 'profileImage' | 'email' | 'mobilePhone'
	>;
};

type TProfileCardFormInput = {
	firstName: string | undefined;
	lastName: string | undefined;
	email: TProfile['email'];
	mobilePhone: string | undefined;
};

function ProfileCard(props: TProps) {
	const { id, firstName, lastName, email, mobilePhone, profileImage } =
		props.profile;
	const { t } = useTranslation();
	const [profileInput, setProfileInput] = useState<TProfileCardFormInput>({
		firstName: firstName || undefined,
		lastName: lastName || undefined,
		email,
		mobilePhone: mobilePhone || undefined,
	});

	const [updateAccount, { loading: isSaving, error }] =
		useMutation(UPDATE_ACCOUNT);

	if (error) {
		throw new ApolloError(error);
	}

	const onFirstNameChange = (newValue?: string) => {
		const updatedProfile = {
			...profileInput,
			firstName: newValue,
		};
		setProfileInput(updatedProfile);
	};

	const onLastNameChange = (newValue?: string) => {
		const updatedProfile = {
			...profileInput,
			lastName: newValue,
		};
		setProfileInput(updatedProfile);
	};

	const onPhoneChange = (newValue?: string) => {
		const updatedProfile = {
			...profileInput,
			mobilePhone: newValue,
		};
		setProfileInput(updatedProfile);
	};

	const onSaveProfile = () => {
		void updateAccount({ variables: { input: profileInput } });
	};

	const saveButton = isSaving ? (
		<>
			<LoadingIcon />
			<Button disabled>{t('buttons.saveChanges')}</Button>
		</>
	) : (
		<Button disableElevation onClick={onSaveProfile}>
			{t('buttons.saveChanges')}
		</Button>
	);

	// todo (#3248): create reusable card components for settings cards
	return (
		<Card>
			<CardContent>
				<Box paddingBottom={2.75}>
					<Text variant="h3">{t('personalInfoPage.card.title')}</Text>
				</Box>
				<Grid
					container
					spacing={3}
					paddingTop="0.5rem"
					paddingBottom="1.5rem"
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid container item xs={9} spacing={3}>
						<Grid container item xs={12} spacing={3}>
							<Grid xs={6} item>
								<TextField
									value={profileInput.firstName || ''}
									label={t('labels.firstName')}
									onChange={(val) => onFirstNameChange(val)}
									disabled={isSaving}
								/>
							</Grid>
							<Grid xs={6} item>
								<TextField
									value={profileInput.lastName || ''}
									label={t('labels.lastName')}
									onChange={(val) => onLastNameChange(val)}
									disabled={isSaving}
								/>
							</Grid>
						</Grid>
						<Grid xs={12} item>
							<TextField
								value={profileInput.email || ''}
								label={t('labels.email')}
								// Disabled because of #1000. Enable it again,
								// when the backend is ready to change account email.
								// onChange={onEmailChange}
								disabled
							/>
						</Grid>
					</Grid>
					<Grid xs={3} item padding="auto">
						<ProfileAvatar
							account={{ id, firstName, lastName, profileImage }}
						/>
					</Grid>
				</Grid>
				<PhoneNumber
					sx={{ paddingTop: '.325rem' }}
					value={profileInput.mobilePhone || ''}
					label={t('labels.phoneNumber')}
					onChange={(val) => onPhoneChange(val)}
					disabled={isSaving}
				/>
			</CardContent>
			<CardActions
				sx={{ justifyContent: 'flex-end', padding: '0 1.5rem 1.5rem 0' }}
			>
				{saveButton}
			</CardActions>
		</Card>
	);
}

export default ProfileCard;
