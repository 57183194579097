// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3gtOXNUAY29YuhzQZlDVK {\n  padding-bottom: 1.375rem;\n}\n\n._2zRe3baoLon8DufHp3b7Cg {\n  padding: 0 1.5rem;\n  max-width: 31.25rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"noOwnerInfo": "_3gtOXNUAY29YuhzQZlDVK",
	"modalSubtitle": "_2zRe3baoLon8DufHp3b7Cg"
};
module.exports = exports;
