import React, { useRef, useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJobInputTreatment } from 'Models/jobs/JobInput';
import List from 'UI/display/List';
import Popover from 'UI/display/Popover';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import GET_TREATMENT_LIBRARIES_PRODUCTS from '../../../../../api/getCompanyTreatmentLibraryProducts';
import GET_GOVERNMENT_PRODUCTS from '../../../../../api/getGovernmentProducts';
import { useJobInputData } from '../../../../../state';
import SelectTreatmentListItem, {
	TMappedTreatment,
} from './SelectTreatmentListItem';

type TSelectTreatment = {
	treatment: TJobInputTreatment;
};

const MIN_SEARCH_LENGTH = 3;

const SelectTreatment = (props: TSelectTreatment) => {
	const { originalTreatmentId, productName } = props.treatment;
	const { company } = usePartnerCompany();
	const jobInput = useJobInputData();
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const inputRef = useRef<HTMLInputElement>();
	const skipSearch = searchValue.length < MIN_SEARCH_LENGTH;

	const {
		data: governmentProducts,
		loading: governmentProductsLoading,
		error: governmentProductsError,
	} = useQuery(GET_GOVERNMENT_PRODUCTS, {
		variables: company?.country
			? {
					filterBy: {
						country: company?.country,
						name: searchValue,
					},
			  }
			: undefined,
		skip: skipSearch || !company?.country,
	});

	const {
		data: libraryProducts,
		loading: libraryProductsLoading,
		error: libraryProductsError,
	} = useQuery(GET_TREATMENT_LIBRARIES_PRODUCTS, {
		variables: {
			filterBy: {
				productName: searchValue,
				productType:
					jobInput.jobDetails.jobType === 'FERTILIZER' ? 'FERTILIZER' : 'OTHER',
			},
		},
		skip: skipSearch,
	});

	if (governmentProductsError) {
		throw new ApolloError(governmentProductsError);
	}
	if (libraryProductsError) {
		throw new ApolloError(libraryProductsError);
	}

	const mappedLibraryTreatments = (
		libraryProducts?.getCompanyTreatmentLibraryProducts.products || []
	)
		.map((product) =>
			product.treatments.map((treatment) => ({
				productName: product.name,
				treatment,
				isCustom: true,
			}))
		)
		.flat();

	const mappedGovTreatments = (
		governmentProducts?.getGovernmentProducts.products || []
	)
		.map((product) =>
			product.treatments.map((treatment) => ({
				productName: product.name,
				treatment,
				isCustom: false,
			}))
		)
		.flat();

	const matchesSearchAndNonSelected = (product: TMappedTreatment) =>
		product.productName.toUpperCase().includes(searchValue.toUpperCase()) &&
		!jobInput.jobDetails.treatments.some(
			(treatment) =>
				treatment.originalTreatmentId === product.treatment.id ||
				treatment.productName === product.productName
		);

	const filteredTreatments = [
		...mappedLibraryTreatments,
		...mappedGovTreatments,
	]
		.filter(matchesSearchAndNonSelected)
		.map((treatment: TMappedTreatment) => (
			<SelectTreatmentListItem
				key={treatment.treatment.id}
				treatment={treatment}
				originalTreatmentId={originalTreatmentId}
				onSelectCallback={() => handleClose()}
			/>
		));

	const loadingSpinner =
		governmentProductsLoading || libraryProductsLoading ? (
			<Box
				display="flex"
				width="100%"
				justifyContent="center"
				alignItems="center"
				overflow="hidden"
			>
				<LoadingSpinner />
			</Box>
		) : null;

	const treatmentsList =
		loadingSpinner ||
		(skipSearch ? null : (
			<Box sx={{ overflow: 'auto' }}>
				<List.List>
					{filteredTreatments.length ? (
						filteredTreatments
					) : (
						<Text sx={{ paddingX: '1rem' }} variant="secondaryBody2">
							{t('prompts.noRegisteredTreatments')}
						</Text>
					)}
				</List.List>
			</Box>
		));

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<TextField
				value={productName}
				label={t('labels.treatment')}
				onClick={handleClick}
				fullWidth
			/>
			<Popover
				id="job-type-select"
				open={isMenuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				onAnimationEnd={() => inputRef.current?.focus()}
			>
				<Box
					display="flex"
					flexDirection="column"
					sx={{ overflow: 'hidden', maxHeight: '32rem', width: '28rem' }}
				>
					<FreeTextSearch
						inputPlaceholder={t('plannerPage.addJobModal.searchTreatmentInput')}
						onChange={(newValue: string): void => {
							setSearchValue(newValue);
						}}
						ref={inputRef}
						onClear={() => setSearchValue('')}
						value={searchValue}
						sx={{
							height: '2.25rem',
							boxShadow: 'none',
							border: `1px solid ${FarmableColors.BLACK_12}`,
							margin: '1rem',
						}}
					/>
					{treatmentsList}
				</Box>
			</Popover>
		</>
	);
};

export default SelectTreatment;
