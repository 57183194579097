import React, { ReactElement } from 'react';

import List from 'UI/display/List';

type DropdownUserProfileItem = {
	primaryText: string;
	secondaryText: string | null;
	avatarIcon: ReactElement;
};

const DropdownUserProfileItem = React.forwardRef(
	(props: DropdownUserProfileItem, ref) => {
		const { primaryText, secondaryText, avatarIcon } = props;

		return (
			<List.Item sx={{ minWidth: '18.75rem', padding: '0 1rem' }}>
				<List.ItemAvatar ref={ref}>{avatarIcon}</List.ItemAvatar>
				<List.ItemText
					primary={<b>{primaryText}</b>}
					primaryTypographyProps={{
						style: { fontWeight: 'bold', fontSize: '0.875rem' },
					}}
					secondary={secondaryText}
					secondaryTypographyProps={{
						style: { fontSize: '0.75rem' },
					}}
				/>
			</List.Item>
		);
	}
);

export default DropdownUserProfileItem;
