import { gql, TypedDocumentNode } from '@apollo/client';

import { TGetPaymentProductsResult } from '../types';

export type TGetStorePaymentProductsResponse = {
	getPaymentProducts: TGetPaymentProductsResult;
};

type TGetPaymentProducts = {
	locationId: string;
};

const GET_PAYMENT_PRODUCTS: TypedDocumentNode<
	TGetStorePaymentProductsResponse,
	TGetPaymentProducts
> = gql`
	query getStorePaymentProducts($locationId: ID!) {
		getPaymentProducts(locationId: $locationId) {
			id
			name
			description
			prices {
				id
				type
				currency
				unit_amount
				recurring {
					interval
					interval_count
				}
			}
			images
		}
	}
`;

export default GET_PAYMENT_PRODUCTS;
