import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';

import GET_CAUSES_DATA from './getPartnerCauses';

type TDeletePartnershipCauseResponse = {
	deletePartnershipCause: {
		id: string;
	};
};

type TDeletePartnershipCauseInput = {
	causeId: string;
};

const DELETE_PARTNERSHIP_CAUSE: TypedDocumentNode<
	TDeletePartnershipCauseResponse,
	TDeletePartnershipCauseInput
> = gql`
	mutation deletePartnershipCause($causeId: ID!) {
		deletePartnershipCause(causeId: $causeId) {
			id
		}
	}
`;

export const removeDeletedCauseFromCache: MutationUpdaterFunction<
	TDeletePartnershipCauseResponse,
	TDeletePartnershipCauseInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }) => {
	const query = { query: GET_CAUSES_DATA };
	const deletedCause = data?.deletePartnershipCause;
	const existingCauses = cache.readQuery(query)?.getPartnershipCauses.causes;
	if (existingCauses && deletedCause) {
		cache.writeQuery({
			...query,
			data: {
				getPartnershipCauses: {
					causes: [
						...existingCauses.filter((cause) => cause.id !== deletedCause.id),
					],
				},
			},
		});
	}
};

export default DELETE_PARTNERSHIP_CAUSE;
