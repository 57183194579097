// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._36RxSECh1rFmzDmF2Hyvv7 {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: .75rem;\n  margin-top: -0.25rem;\n}\n\n.DbR7NBmBs4nkTR2PwNOaj {\n  opacity: 25%;\n}\n\n._2Ol1cO62Q87zIvuTRv9BhL {\n  position: absolute;\n  margin-top: 3rem;\n  width: 100%;\n  left: 0;\n  text-align: center;\n  white-space: pre-wrap;\n}\n\n._2WyopU9-iohyKBqXduijWj {\n  padding: .4rem;\n  padding-bottom: 1rem;\n}\n\n._2WyopU9-iohyKBqXduijWj svg {\n  fill: rgba(0,0,0,.6);\n}\n\n.dpGhHKFRi_PcDglafJUss {\n  text-align: right;\n  width: 100%;\n}\n\n._2w38y4_qNOACHWVYof1vFf {\n  width: 28.125rem;\n}\n\n._2ofmZ4rF0fTlH4FLTqoa0A {\n  position: absolute !important;\n  left: 1rem;\n}\n\n._3UTmyiTjEaFpybU-5tE9S9 {\n  width: 100%;\n}\n\n", ""]);
// Exports
exports.locals = {
	"plannerHeader": "_36RxSECh1rFmzDmF2Hyvv7",
	"noDataChart": "DbR7NBmBs4nkTR2PwNOaj",
	"noDataText": "_2Ol1cO62Q87zIvuTRv9BhL",
	"paper": "_2WyopU9-iohyKBqXduijWj",
	"modalHeader": "dpGhHKFRi_PcDglafJUss",
	"modalContent": "_2w38y4_qNOACHWVYof1vFf",
	"backButton": "_2ofmZ4rF0fTlH4FLTqoa0A",
	"dialogActions": "_3UTmyiTjEaFpybU-5tE9S9"
};
module.exports = exports;
