import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Permissions from 'Models/permissions/Permissions';

import useFieldID from 'Hooks/useFieldID';
import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';

import FieldsSearchMap from '../../../../../components/SearchMap/FieldsSearchMap';
import GET_ADVISOR_MAP_FIELDS_QUERY from '../../api/getAdvisorDashboardFields';
import GET_MEMBER_MAP_FIELDS_QUERY from '../../api/getMemberDashboardFields';
import parseResponseData from '../../utils/parseFieldsResponseData';

function SearchMap() {
	const locationId = useLocationID();
	const fieldId = useFieldID();

	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELDS_QUERY
	);

	const { data, loading, error } = useQuery(
		hasPermissionsToRegularQuery
			? GET_MEMBER_MAP_FIELDS_QUERY
			: GET_ADVISOR_MAP_FIELDS_QUERY,
		{
			variables: {
				locationId,
			},
		}
	);

	if (error) {
		throw new ApolloError(error);
	}

	const parsedData = parseResponseData(data);

	return (
		<FieldsSearchMap
			key={fieldId || 'fields-search-map-component'}
			fields={parsedData}
			defaultFieldId={fieldId}
			isLoading={loading}
		/>
	);
}

export default SearchMap;
