import React, { useEffect, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CLEARED_FILTERS, URLFiltersType } from 'Models/history/isURLFilters';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';

import useManageHistory from 'Hooks/useManageHistory';

import Date from '../../../components/filters/Date';
import PartnerCategories from '../../../components/filters/PartnerCategories';
import PartnerFieldsList from '../../../components/filters/PartnerFieldsList';
import PartnerLocations from '../../../components/filters/PartnerLocations';
import PartnerVarieties from '../../../components/filters/PartnerVarieties';
import Drawer from '../common/components/Drawer';

export const PartnerSalesLogsFilter = () => {
	const [open, setOpen] = useState(false);

	const toggleOpen = () => {
		setOpen((prevState) => !prevState);
	};

	const { t } = useTranslation();
	const prefix = 'logsPages.filtersModal.sections.';
	const manageHistory = useManageHistory();

	const searchParams = manageHistory.paramsFromURL;

	const [filters, setFilters] = useState<URLFiltersType>({
		...searchParams.filters,
	});

	const onSearch = () => {
		const newSearchParams = {
			...searchParams,
			filters: {
				...searchParams.filters,
				completedDateFrom: filters.completedDateFrom,
				completedDateTo: filters.completedDateTo,
				varieties: filters.varieties,
				locations: filters.locations,
			},
			pageInput: {
				...searchParams.pageInput,
				offset: 0,
			},
		};
		manageHistory.historyPush(newSearchParams);
		toggleOpen();
	};

	const onClear = () => {
		setFilters({ ...CLEARED_FILTERS });
	};

	return (
		<>
			<Button
				startIcon={<OptionsIcon />}
				variant="secondary"
				onClick={toggleOpen}
			>
				{t('buttons.filters')}
			</Button>
			<Drawer.Container onHideClick={toggleOpen} open={open}>
				<Drawer.Header onHideClick={toggleOpen} />
				<Drawer.Content>
					<Drawer.ContentSection title={t(`${prefix}date`)}>
						<Date filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection title={t(`${prefix}filters`)}>
						<div>
							<PartnerLocations filters={filters} updateFilters={setFilters} />
							<PartnerVarieties filters={filters} updateFilters={setFilters} />
						</div>
					</Drawer.ContentSection>
				</Drawer.Content>
				<Drawer.Footer>
					<Button variant={'text'} onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={onSearch}>{t('buttons.save')}</Button>
				</Drawer.Footer>
			</Drawer.Container>
		</>
	);
};
