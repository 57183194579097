import React, { Dispatch, ReactElement, SetStateAction } from 'react';

import {
	Select as MUISelect,
	Chip as MUIChip,
	MenuItem as MUIMenuItem,
	InputLabel as MUIInputLabel,
	FormControl as MUIFormControl,
	FormHelperText as MUIFormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import uniqueId from 'lodash/uniqueId';

import CloseIcon from '../../icons/Close';

export type TOptions = {
	id: string;
	label: string;
};

interface TDropdownWithChipsProps {
	label?: string;
	options: TOptions[];
	translateValueFunc?: (value: string) => string;
	selected: string[];
	selectedControl:
		| Dispatch<SetStateAction<string[]>>
		| ((value: any[]) => void);
	className?: string;
	helperText?: string;
	sort?: boolean;
	disabled?: boolean;
	saveLabel?: boolean;
	multiple?: boolean;
}

const useStyles = makeStyles({
	selectRoot: {
		'&&': {
			padding: '0.688rem 0.813rem',
			minHeight: '1.875rem',
			height: 'auto',
		},
	},
	chipRoot: {
		fontSize: '0.875rem',
		borderRadius: '0.25rem',
		margin: '0.188rem',
		height: '1.5rem',
	},
	chipDeleteIcon: {
		'&&': {
			color: 'rgba(0, 0, 0, 0.6)',
			width: '0.9375rem',
			height: '0.9375rem',
		},
		'&&:hover': {
			color: 'rgba(0, 0, 0, 0.6)',
		},
	},
	chipLabel: {
		height: '100%',
		lineHeight: 1.71,
	},
});

//TODO rework, values and selected should be object with value and id
const DropdownWithChips = (props: TDropdownWithChipsProps): ReactElement => {
	const {
		options,
		selected,
		label,
		className,
		helperText,
		selectedControl,
		disabled,
		multiple = true,
	} = props;

	const handleChange = (value: string) => {
		if (selected.includes(value)) {
			selectedControl(
				selected.filter((selectedValue) => selectedValue !== value)
			);
		} else {
			selectedControl(multiple ? [...selected, value] : [value]);
		}
	};

	const handleDelete = (value: string) => {
		selectedControl(
			selected.filter((selectedValue) => selectedValue !== value)
		);
	};

	const style = useStyles();

	const selectRenderValue = (selected: string[]) => {
		return (
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{selected.map((value, idx) => (
					<MUIChip
						label={options.find((option) => option.id === value)?.label}
						key={value + idx}
						clickable
						disabled={disabled}
						onDelete={() => handleDelete(value)}
						deleteIcon={
							<CloseIcon onMouseDown={(event) => event.stopPropagation()} />
						}
						classes={{
							root: style.chipRoot,
							label: style.chipLabel,
							deleteIcon: style.chipDeleteIcon,
						}}
					/>
				))}
			</div>
		);
	};

	const uid = uniqueId(label + '_');

	const selectInputProps = {
		name: uid,
		id: uid,
	};

	return (
		<MUIFormControl variant="outlined" fullWidth>
			<MUIInputLabel htmlFor={uid}>{label}</MUIInputLabel>
			<MUISelect
				disabled={disabled}
				value={selected}
				label={label}
				multiple={multiple}
				inputProps={selectInputProps}
				renderValue={selectRenderValue}
				className={className}
				classes={{ select: style.selectRoot }}
			>
				{options.map((option, i) => (
					<MUIMenuItem
						value={option.id}
						key={'dropdown-menu-chip-item-' + i}
						onClick={() => handleChange(option.id)}
					>
						{option.label}
					</MUIMenuItem>
				))}
			</MUISelect>
			<MUIFormHelperText>{helperText}</MUIFormHelperText>
		</MUIFormControl>
	);
};

export default DropdownWithChips;
