import { gql, TypedDocumentNode } from '@apollo/client';

const GET_JUSTIFICATIONS: TypedDocumentNode<
	{
		getJustifications: { id: string; name: string }[];
	},
	null
> = gql`
	query getJustifications {
		getJustifications {
			id
			name
		}
	}
`;

export default GET_JUSTIFICATIONS;
