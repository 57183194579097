import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import DateField from 'UI/inputs/DateField';
import TextField from 'UI/inputs/TextField';
import { isValid, parseISO } from 'date-fns';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import useSpanishPartner from 'Hooks/useSpanishPartner';
import getDateFnsLocale from 'Utils/getDateFnsLocale';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../state';
import {
	AddTreatmentModalStatus,
	TSetProductDetailsInput,
} from '../../../../../types';
import ManufacturerName from './ManufacturerName';

const GeneralInfo = () => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { company } = usePartnerCompany();
	const locale = getDateFnsLocale(company?.country);
	const { status, input } = state;
	const { isSpanishPartner } = useSpanishPartner();

	if (status === AddTreatmentModalStatus.SET_PRODUCT_DETAILS) {
		const product = input.product;
		const hasSpec = 'productSpec' in product;
		const permitNumber =
			selectedProduct?.productSpec.registrationNumber ||
			(hasSpec && product.productSpec?.registrationNumber) ||
			'';
		const expirationDate =
			selectedProduct?.productSpec.licenseExpirationDate ||
			(hasSpec && product.productSpec?.licenseExpirationDate) ||
			'';
		const useByDate =
			selectedProduct?.productSpec.usageExpirationDate ||
			(hasSpec && product.productSpec?.usageExpirationDate) ||
			'';

		const onChangePermitNumber = (value: string) => {
			if (!input.selectedGovernmentProduct) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							productSpec: {
								...input.product.productSpec,
								registrationNumber: value,
							},
						},
					},
				} as TSetProductDetailsInput);
			}
		};

		const onChangeExpDate = (value: Date | null) => {
			if (!input.selectedGovernmentProduct && isValid(value)) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							productSpec: {
								...input.product.productSpec,
								licenseExpirationDate: value?.toISOString(),
							},
						},
					},
				} as TSetProductDetailsInput);
			}
		};

		const onChangeUseByDate = (value: Date | null) => {
			if (!input.selectedGovernmentProduct && isValid(value)) {
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							productSpec: {
								...input.product.productSpec,
								usageExpirationDate: value?.toISOString(),
							},
						},
					},
				} as TSetProductDetailsInput);
			}
		};

		return (
			<ModalSection title={t('labels.generalInfo')}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={locale}
				>
					{isSpanishPartner && !input.selectedGovernmentProduct ? null : (
						<ManufacturerName />
					)}
					<TextField
						value={permitNumber}
						label={t('labels.permitNumber')}
						onChange={onChangePermitNumber}
						helperText={t('hint.permitNumber', {
							name: 'Isourcel',
							date: '01.02.22',
						})}
						type="string"
						margin="dense"
						sx={{ marginBottom: '1rem' }}
						disabled={Boolean(selectedProduct)}
					/>
					<DateField
						value={expirationDate ? parseISO(expirationDate) : null}
						label={t('labels.expirationDate')}
						onChange={onChangeExpDate}
						helperText={t('hint.expirationDate', {
							name: 'Isourcel',
							date: '01.02.22',
						})}
						margin="dense"
						format="dd.MM.yyyy"
						fullWidth
						sx={{
							marginBottom: '1rem',
						}}
						disabled={Boolean(selectedProduct)}
					/>
					<DateField
						value={useByDate ? parseISO(useByDate) : null}
						label={t('labels.useByDate')}
						onChange={onChangeUseByDate}
						helperText={t('hint.useByDate')}
						format="dd.MM.yyyy"
						fullWidth
						margin="dense"
						disabled={Boolean(selectedProduct)}
					/>
				</LocalizationProvider>
			</ModalSection>
		);
	}

	return null;
};

export default GeneralInfo;
