import React from 'react';

import { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Grid from 'UI/layout/Grid';

import CropSummary from '../../../common/components/CropSummary';
import GET_CROP_SUMMARY_DATA from './api/getCropSummaryData';
import getSummarySectionData from './getSummarySectionData';

const CropSummarySection = () => {
	const { data, loading, error } = useQuery(GET_CROP_SUMMARY_DATA);
	const { t } = useTranslation();

	if (loading) {
		return (
			<Grid container justifyContent="center" alignItems="center">
				<LoadingSpinner width={64} height={64} />
			</Grid>
		);
	}

	if (data) {
		const summarySectionData = getSummarySectionData(
			t,
			data.getPartnershipDashboardFieldsCategories
		);

		return <CropSummary varieties={summarySectionData} />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
};

export default CropSummarySection;
