import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';

type TTreatmentLibraryPageableResponse = {
	getTreatmentLibraries: {
		totalPages: number;
		totalElements: number;
		content: TTreatmentLibrary[];
	};
};

type TGetTreatmentsRequest = {
	pageInput?: TPageInput;
};

const GET_PARTNER_TREATMENT_LIBRARIES: TypedDocumentNode<
	TTreatmentLibraryPageableResponse,
	TGetTreatmentsRequest
> = gql`
	query getPartnerTreatments {
		getTreatmentLibraries {
			totalPages
			totalElements
			content {
				id
				name
			}
		}
	}
`;

export default GET_PARTNER_TREATMENT_LIBRARIES;
