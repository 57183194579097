import React, { FC, useCallback } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductTreatment } from 'Models/products/ProductTreatment';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';
import { enqueueSnackbar } from 'notistack';

import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import { UPDATE_LIBRARY_TREATMENT } from '../../../../api';
import TreatmentDetails from '../CreateTreatmentModal/components/ModalContent/components/TreatmentDetails';
import { clearState, treatmentInputState } from '../CreateTreatmentModal/state';

interface EditProductModalProps {
	productId: string;
	treatment: TProductTreatment;
	onClose: () => void;
}
export const EditProductTreatmentModal: FC<EditProductModalProps> = ({
	productId,
	treatment,
	onClose,
}) => {
	const { t } = useTranslation();
	const treatmentInput = useReactiveVar(treatmentInputState);
	const libraryId = getTreatmentLibraryIdFromURL();

	const [updateLibraryTreatments] = useMutation(UPDATE_LIBRARY_TREATMENT);

	const handleSaveClick = useCallback(() => {
		if (treatmentInput) {
			void updateLibraryTreatments({
				variables: {
					libraryId,
					productId: productId,
					treatmentId: treatment.id,
					input: treatmentInput,
				},
			})
				.then(() => {
					enqueueSnackbar(
						t(
							'treatmentLibrary.addTreatmentModal.updateProductTreatment.success'
						),
						{
							variant: 'success',
						}
					);
					clearState();
					onClose();
				})
				.catch(() => {
					enqueueSnackbar(
						t(
							'treatmentLibrary.addTreatmentModal.updateProductTreatment.failure'
						),
						{
							variant: 'error',
						}
					);
				});
		}
	}, [
		libraryId,
		onClose,
		productId,
		t,
		treatment.id,
		treatmentInput,
		updateLibraryTreatments,
	]);

	return (
		<Modal.UncontrolledModal toggleOpen={onClose} open={true}>
			<Modal.DialogTitle
				title={t('buttons.editProductLibraryTreatment')}
				closeIconHandler={onClose}
			/>
			<Modal.DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
				<TreatmentDetails />
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Button variant="text" onClick={onClose}>
					{t('buttons.cancel')}
				</Button>
				<Button variant="outlined" onClick={handleSaveClick} disabled={false}>
					{t('buttons.save')}
				</Button>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};
