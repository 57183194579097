import { gql, TypedDocumentNode } from '@apollo/client';

export const TRANSFER_LOCATION_OWNERSHIP_MUTATION: TypedDocumentNode<{
	locationId: string;
	managerId: string;
}> = gql`
	mutation TransferLocationOwnership($locationId: ID!, $managerId: ID!) {
		transferLocationOwnership(locationId: $locationId, managerId: $managerId)
	}
`;
