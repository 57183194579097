import React, { ReactChild, ReactChildren } from 'react';

import Table from '.';

const NoDataTableContainer = (props: {
	children: ReactChild | ReactChildren;
}) => (
	<Table.Body>
		<Table.Row>
			<Table.Cell align="center" colSpan={20}>
				{props.children}
			</Table.Cell>
		</Table.Row>
	</Table.Body>
);

export default NoDataTableContainer;
