import { TTranslateFunction } from 'Features/localization';

const translateProductCategory = (category: string, t: TTranslateFunction) => {
	const categoriesEn = t('productCategories', {
		returnObjects: true,
		lng: 'en',
	});

	const categories = t('productCategories', {
		returnObjects: true,
	});

	const path = Object.keys(categoriesEn).find(
		(key) => categoriesEn[key] === category
	);

	const result = path ? categories[path] : null;
	return result || category;
};

export default translateProductCategory;
