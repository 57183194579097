import {
	PRODUCTS_COLUMN_END_INDEX,
	PRODUCTS_COLUMN_START_INDEX,
} from './constants';

export type TMerge = {
	s: {
		r: number;
		c: number;
	};
	e: {
		r: number;
		c: number;
	};
};

function getProductColumnsMerges(
	firstRow = 0,
	productColumnStart = PRODUCTS_COLUMN_START_INDEX,
	productColumnEnd = PRODUCTS_COLUMN_END_INDEX
): TMerge[] {
	return [
		{
			s: { r: firstRow, c: productColumnStart },
			e: { r: firstRow, c: productColumnEnd },
		},
	];
}

function getHeaderColumnsMerges(
	totalColumns,
	firstRow = 0,
	secondRow = 1,
	productColumnStart = PRODUCTS_COLUMN_START_INDEX,
	productColumnEnd = PRODUCTS_COLUMN_END_INDEX
): TMerge[] {
	const headerColumnsMerges: TMerge[] = [];

	// The remaining columns should be 2 rows high.
	for (let col = 0; col < totalColumns; col++) {
		// Exclude products cells.
		if (col < productColumnStart || col > productColumnEnd)
			headerColumnsMerges.push({
				s: { r: firstRow, c: col },
				e: { r: secondRow, c: col },
			});
	}

	return headerColumnsMerges;
}

export { getProductColumnsMerges, getHeaderColumnsMerges };
