// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".p0RkNUr0vDKprtgIhp2-J {\n  width: 100%;\n  padding: 1.5rem 1rem 1.5rem 1.5rem;\n  margin: 2rem 0;\n}\n\n.Jum8KdhWCccF9EmiOv0Lr {\n  margin: .25rem 0 !important;\n}\n\n._3YA4z6mqUsnh-X0f_s2UU {\n  align-items: flex-start;\n}\n\n", ""]);
// Exports
exports.locals = {
	"productCard": "p0RkNUr0vDKprtgIhp2-J",
	"productPrice": "Jum8KdhWCccF9EmiOv0Lr",
	"flexAlignStart": "_3YA4z6mqUsnh-X0f_s2UU"
};
module.exports = exports;
