import { gql, TypedDocumentNode } from '@apollo/client';
import { TTeam } from 'Models/teams/isTeam';
import { TTeamInput } from 'Models/teams/isTeamInput';

export type TCreateTeamResult = TTeam;

const CREATE_TEAM: TypedDocumentNode<
	{
		createTeam: TTeam;
	},
	{
		locationId: string;
		teamInput: TTeamInput;
	}
> = gql`
	mutation createTeam($locationId: ID!, $teamInput: TeamInput!) {
		createTeam(locationId: $locationId, input: $teamInput) {
			id
			name
			teamLead {
				id
				firstName
				lastName
				profileImage {
					id
					path
					filename
					mimetype
					createdAt
				}
			}
			hasTeamLeadUnclosedTimesheets
			teamMembers {
				id
				customId
				fullName
				position
				hasUnclosedTimesheets
			}
		}
	}
`;

export default CREATE_TEAM;
