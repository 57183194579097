import classicFormat from '../dates/classicFormat';
import { THarvestBatch } from './isHarvestBatch';

function harvestBatchDateString<TGenericHarvest>(
	harvestBatch: TGenericHarvest & Pick<THarvestBatch, 'date'>
): string {
	return classicFormat(new Date(harvestBatch.date));
}

export default harvestBatchDateString;
