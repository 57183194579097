import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import styles from './styles.module.scss';

type TSettingsCardProps = {
	children: ReactElement;
};

const SettingsCard = (props: TSettingsCardProps): ReactElement => {
	const { t } = useTranslation();
	return (
		<Card>
			<CardContent>
				<Text
					variant="h3"
					component="h2"
					className={styles.timeSettingsPageHeader}
				>
					{t('labels.settings')}
				</Text>
				{props.children}
			</CardContent>
		</Card>
	);
};

export default SettingsCard;
