import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import useLocation from 'Features/router/hooks/useLocation';
import Permissions from 'Models/permissions/Permissions';
import List from 'UI/display/List';
import TeamIcon from 'UI/icons/Team';
import TimeIcon from 'UI/icons/Time';

import usePermission from 'Hooks/usePermission';

import { ICONS_FILL, getSettingsRoutes } from '../';

function TimesheetsSection(props: { locationID?: string }) {
	const { locationID } = props;
	const location = useLocation();
	const currentPath = location.pathname;
	const { t } = useTranslation();
	const history = useHistory();

	const hasTimesheetPermission = usePermission(Permissions.TIMESHEETS_VIEW);

	const shouldShowTimesheetSection = locationID && hasTimesheetPermission;

	if (!shouldShowTimesheetSection) {
		return null;
	}

	const paths = getSettingsRoutes({ locationID });

	return (
		<List.List
			isMenuVariant
			subheader={
				<List.Subheader>{t('common.sidebar.timesheets')}</List.Subheader>
			}
		>
			<List.Item disablePadding>
				<List.ItemButton
					isActive={currentPath === paths.teams}
					onClick={() => {
						history.push(paths.teams);
					}}
				>
					<List.ItemIcon>
						<TeamIcon fill={ICONS_FILL} />
					</List.ItemIcon>
					<List.ItemText primary={t('common.sidebar.teams')} />
				</List.ItemButton>
			</List.Item>
			<List.Item disablePadding>
				<List.ItemButton
					isActive={currentPath === paths.timeSettings}
					onClick={() => {
						history.push(paths.timeSettings);
					}}
				>
					<List.ItemIcon>
						<TimeIcon fill={ICONS_FILL} />
					</List.ItemIcon>
					<List.ItemText primary={t('common.sidebar.timeSettings')} />
				</List.ItemButton>
			</List.Item>
		</List.List>
	);
}

export default TimesheetsSection;
