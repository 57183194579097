import { TFieldWeatherDaily } from '../models/isFieldWeatherDaily';
import { TMilestone } from '../models/isMilestone';

const mockFieldWeatherDaily: TFieldWeatherDaily[] = [
	{
		date: '2022-01-01',
		valueTempAvg: -5,
		valueGddAvg: 0,
	},
	{
		date: '2022-01-15',
		valueTempAvg: -7,
		valueGddAvg: 0,
	},
	{
		date: '2022-02-01',
		valueTempAvg: -10,
		valueGddAvg: 0,
	},
	{
		date: '2022-02-15',
		valueTempAvg: 0,
		valueGddAvg: 0,
	},
	{
		date: '2022-02-30',
		valueTempAvg: 5,
		valueGddAvg: 25,
	},
	{
		date: '2022-03-15',
		valueTempAvg: 2,
		valueGddAvg: 75,
	},
	{
		date: '2022-03-30',
		valueTempAvg: 15,
		valueGddAvg: 225,
	},
	{
		date: '2022-04-15',
		valueTempAvg: 12,
		valueGddAvg: 275,
	},
	{
		date: '2022-05-01',
		valueTempAvg: 17,
		valueGddAvg: 350,
	},
	{
		date: '2022-05-15',
		valueTempAvg: 20,
		valueGddAvg: 425,
	},
];

const mockFieldWeatherDailyPredictions: TFieldWeatherDaily[] = [
	{
		date: '2022-05-15',
		valueTempAvg: 20,
		valueGddAvg: 425,
	},
	{
		date: '2022-06-01',
		valueTempAvg: 22,
		valueGddAvg: 584,
	},
	{
		date: '2022-06-15',
		valueTempAvg: 19,
		valueGddAvg: 800,
	},
	{
		date: '2022-06-30',
		valueTempAvg: 25,
		valueGddAvg: 927,
	},
	{
		date: '2022-07-15',
		valueTempAvg: 27,
		valueGddAvg: 1025,
	},
	{
		date: '2022-07-30',
		valueTempAvg: 23,
		valueGddAvg: 1211,
	},
	{
		date: '2022-08-15',
		valueTempAvg: 19,
		valueGddAvg: 1399,
	},
	{
		date: '2022-08-30',
		valueTempAvg: 25,
		valueGddAvg: 1422,
	},
	{
		date: '2022-09-15',
		valueTempAvg: 22,
		valueGddAvg: 1599,
	},
	{
		date: '2022-09-30',
		valueTempAvg: 16,
		valueGddAvg: 1670,
	},
	{
		date: '2022-10-15',
		valueTempAvg: 8,
		valueGddAvg: 1725,
	},
	{
		date: '2022-10-30',
		valueTempAvg: 6,
		valueGddAvg: 1725,
	},
	{
		date: '2022-11-15',
		valueTempAvg: 3,
		valueGddAvg: 1750,
	},
	{
		date: '2022-11-30',
		valueTempAvg: -3,
		valueGddAvg: 1750,
	},
	{
		date: '2022-12-15',
		valueTempAvg: 0,
		valueGddAvg: 1750,
	},
	{
		date: '2022-12-30',
		valueTempAvg: -2,
		valueGddAvg: 1750,
	},
];

const mockMilestones: TMilestone[] = [
	{ date: '2022-04-15', name: 'Flowering', value: 275 },
	{ date: '2022-05-15', name: 'Bloom', value: 425 },
];

const mockMilestonesPredictions: TMilestone[] = [
	{ date: '2022-07-15', name: 'Crop maturity', value: 1025 },
	{ date: '2022-08-30', name: 'Harvest', value: 1422 },
	{ date: '2022-11-15', name: 'Emergence', value: 1750 },
];
export {
	mockFieldWeatherDaily,
	mockFieldWeatherDailyPredictions,
	mockMilestones,
	mockMilestonesPredictions,
};
