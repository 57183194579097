import React from 'react';

import Info from 'UI/icons/Info';

import styles from '../../../styles.module.scss';

const TooltipBase = React.forwardRef<HTMLDivElement>((props, ref) => (
	<div {...props} ref={ref} className={styles.tooltipBase}>
		<Info color="secondary" sx={{ width: 16, height: 16 }} />
	</div>
));

export default TooltipBase;
