import React, { useImperativeHandle, useState } from 'react';

import { useQuery } from '@apollo/client';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import SearchIcon from 'UI/icons/Search';
import Checkbox from 'UI/inputs/Checkbox';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';
import _xor from 'lodash/xor';

import useManageHistory from 'Hooks/useManageHistory';
import getPartnerVarietyFromURL from 'Utils/getPartnerVarietyFromURL';

import GET_PARTNERSHIP_LOCATIONS_DATA from '../../features/api/getPartnerLocations';
import LoadingContent from '../LoadingContent';
import FarmsListItem from './FarmsListItem';

type TProps = {
	onChangeCallback?: (selectedFarmsIDs: string[]) => void;
	selectedFarmsIds?: string[];
	pendingFarmsIds?: string[];
};

const FarmsMultiselectList = React.forwardRef((props: TProps, ref) => {
	const {
		onChangeCallback,
		selectedFarmsIds = [],
		pendingFarmsIds = [],
	} = props;
	const { t } = useTranslation();
	const { paramsFromURL } = useManageHistory();
	const variety = getPartnerVarietyFromURL(paramsFromURL);

	const { data, loading, error } = useQuery(GET_PARTNERSHIP_LOCATIONS_DATA, {
		variables: {
			filterBy: {
				statuses: ['ACTIVE'],
				variety: variety || undefined,
			},
		},
	});

	const [selectedFarmsIDs, setSelectedFarmsIDs] = useState<string[]>([
		...selectedFarmsIds,
		...pendingFarmsIds,
	]);
	const [searchValue, setSearchValue] = useState('');

	useImperativeHandle(ref, () => ({
		selectedFarmsIDs,
	}));

	if (loading) {
		return (
			<Box>
				<LoadingContent />
			</Box>
		);
	}

	if (data) {
		const activeFarms = data.getPartnerLocations.filter((farm) =>
			searchValue
				? farm.name.toLocaleLowerCase().includes(searchValue.trim())
				: true
		);
		const allFarmsIDs = activeFarms.map((farm) => farm.id);
		const allFarmsSelected = _xor(allFarmsIDs, selectedFarmsIDs).length === 0;
		const isIndeterminate = selectedFarmsIDs.length !== 0 && !allFarmsSelected;

		const handleClick = (selectedFarmId: string) => {
			const hasFarm = selectedFarmsIDs.includes(selectedFarmId);
			const farms = hasFarm
				? selectedFarmsIDs.filter((farmId) => farmId !== selectedFarmId)
				: [...selectedFarmsIDs, selectedFarmId];

			setSelectedFarmsIDs(farms);
			onChangeCallback?.(farms);
		};

		const handleSelectAll = (
			event: React.ChangeEvent<{ checked: boolean }>
		) => {
			const isChecked = event.target.checked;
			const farms = !isChecked ? [] : allFarmsIDs;

			setSelectedFarmsIDs(farms);
			onChangeCallback?.(farms);
		};

		const items = activeFarms.map((farm) => {
			const isActive = selectedFarmsIDs.includes(farm.id);
			const isPending = pendingFarmsIds?.includes(farm.id);

			return (
				<FarmsListItem
					name={farm.name}
					id={farm.id}
					key={farm.id}
					isActive={isActive}
					isPending={isPending}
					onClick={handleClick}
				/>
			);
		});

		const allFarmsLabel = isIndeterminate
			? `${t('labels.farms')} (${selectedFarmsIDs.length})`
			: t('labels.allFarms');

		return (
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					maxHeight: '20.25rem',
				}}
			>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Checkbox.Controlled
							size="medium"
							label={<Text variant="label5">{allFarmsLabel}</Text>}
							onChange={handleSelectAll}
							checked={allFarmsSelected}
							indeterminate={isIndeterminate}
							sx={{ marginRight: '0.5rem' }}
						/>
					</Grid>
					<Grid item>
						<TextField
							sx={{
								width: '12rem',
								'& input': { padding: '0.5rem' },
								'& .MuiInputBase-adornedStart': { paddingLeft: '0.5rem' },
							}}
							placeholder={t('connectedFarmsList.searchFarmsPlaceholder')}
							onChange={(val: string) => setSearchValue(val)}
							value={searchValue}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon fill={FarmableColors.BLACK_60} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Divider sx={{ marginY: '0.5rem' }} />
				{items}
			</Box>
		);
	}

	if (error) {
		return <Text>{t('common.fallback.noData')}</Text>;
	}

	return null;
});

export default FarmsMultiselectList;
