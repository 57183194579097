import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function DeleteIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				d="M5.5 18.571c0 1.336.9 2.429 2 2.429h9c1.1 0 2-1.093 2-2.429V7h-13v11.571zM16.5 7v10.7c0 .715-.45 1.3-1 1.3h-7c-.55 0-1-.585-1-1.3V7h9zm-2.75 2a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm-3.5 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm5.25-4-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 5H5c-.55 0-1 .45-1 1s.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1h-3.5z"
				fillRule="nonzero"
			/>
		</SvgIcon>
	);
}

export default DeleteIcon;
