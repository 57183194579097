import area from '@turf/area';
import { polygon, Position } from '@turf/helpers';
import _flatten from 'lodash/flatten';

import { AreaUnits } from '../units';
import convertAreaUnit from '../units/convertAreaUnit';
import { toDisplayString } from '../units/transpileUnits';
import { TCoordinatePoint } from './isCoordinatePoint';
import { TFieldInput } from './isFieldInput';

const DEFAULT_AREA_UNIT = AreaUnits.HECTARE;

function _pointToPosition({ x, y }: TCoordinatePoint): Position {
	return [x, y];
}

function fieldInputAreaWithUnit(
	fieldInput: TFieldInput,
	areaUnit?: AreaUnits | null
): string {
	const positionsArray = _flatten(fieldInput.geometry).map(_pointToPosition);
	const feature = polygon([positionsArray]);
	const areaSize = area(feature);

	if (!areaUnit) {
		return `${convertAreaUnit(areaSize, DEFAULT_AREA_UNIT)} ${toDisplayString(
			DEFAULT_AREA_UNIT
		)}`;
	}

	return `${convertAreaUnit(areaSize, areaUnit)} ${toDisplayString(areaUnit)}`;
}

export default fieldInputAreaWithUnit;
