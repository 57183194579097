import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	TOrderStatus,
	AllOrderStatuses,
} from 'Models/history/isURLOrderStatus';

import ConfirmedIcon from '../../icons/Confirmed';
import FailedIcon from '../../icons/Failed';
import Card from '../../surfaces/Card';
import Text from '../Text';

const useGetContent = (status: TOrderStatus) => {
	const { t } = useTranslation();

	if (status === AllOrderStatuses.SUCCESS) {
		return {
			icon: <ConfirmedIcon />,
			title: t('paymentCard.successTitle'),
			subtitle: t('paymentCard.successSubtitle'),
		};
	}
	if (status === AllOrderStatuses.CANCELED) {
		return {
			icon: <FailedIcon />,
			title: t('paymentCard.canceledTitle'),
			subtitle: null,
		};
	}
	if (status === AllOrderStatuses.REJECTED) {
		return {
			icon: <FailedIcon />,
			title: t('paymentCard.rejectedTitle'),
			subtitle: null,
		};
	}
};

const PaymentCard: React.FC<{ status: TOrderStatus }> = ({ status }) => {
	const content = useGetContent(status);
	const useStyles = makeStyles({
		root: {
			margin: 'auto',
			textAlign: 'center',
			border: 0,
		},
		confirmationSection: {
			marginTop: '3rem',
			marginBottom: '3rem',
		},
		confirmationText: {
			marginTop: '1.5rem',
		},
	});

	const styles = useStyles();

	return (
		<Card className={styles.root}>
			<section className={styles.confirmationSection}>
				{content?.icon}
				<Text variant="h2" className={styles.confirmationText}>
					{content?.title}
				</Text>
				<Text>{content?.subtitle}</Text>
			</section>
		</Card>
	);
};

export default PaymentCard;
