import React from 'react';

import LogsTableHeaderValues from 'Models/jobs/jobLogsTableData';
import Table from 'UI/display/Table';

import { TPartnerTimesheetTableData } from '../../../api/getPartnerHoursTableData';
import getPartnerHoursRowData from '../../../utils/getPartnerHoursRowData';

type THoursLogsTableRowProps = {
	timesheet: TPartnerTimesheetTableData;
};

const TableRow = (props: THoursLogsTableRowProps) => {
	const { timesheet } = props;

	return (
		<Table.Row hover>
			{getPartnerHoursRowData(timesheet).map((cell, index) => (
				<Table.Cell key={`cell-${LogsTableHeaderValues[index] || index}`}>
					{cell}
				</Table.Cell>
			))}
		</Table.Row>
	);
};

export default TableRow;
