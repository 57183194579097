import { gql, TypedDocumentNode } from '@apollo/client';
import { TCost } from 'Models/sales/isCost';

export type TAggregatedHoursCostResponse = {
	getAggregatedWorkingHoursCost: {
		workLogsCosts: TCost[];
	};
};

type TAggregatedHoursCostRequest = {
	locationId: string;
	filterBy: {
		dateFrom?: string;
		dateTo?: string;
		fieldId?: string;
	};
};

const GET_AGGR_HOURS_COST_QUERY: TypedDocumentNode<
	TAggregatedHoursCostResponse,
	TAggregatedHoursCostRequest
> = gql`
	query getProfitChartAggregatedHoursCost(
		$locationId: ID!
		$filterBy: AggregatedWorkLogsFilterInput
	) {
		getAggregatedWorkingHoursCost(
			locationId: $locationId
			filterBy: $filterBy
		) {
			workLogsCosts {
				totalCost
				currency
				month
			}
		}
	}
`;

export default GET_AGGR_HOURS_COST_QUERY;
