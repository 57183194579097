import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';
import { validateEmail } from 'UI/utils/validateEmail';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import FarmsMultiselectList from '../../../../../../components/FarmsMultiselectList';
import EDIT_MEMBER_ACCESS_TO_LOCATIONS from '../../../api/EditMemberAccessToLocations';
import INVITE_PARTNERSHIP_USER from '../../../api/InvitePartnershipUser';
import GraphQLError from './GraphQLError';

type TInviteMemberModal = {
	isModalOpen: boolean;
	parentModalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
	modalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteMemberModal = (props: TInviteMemberModal) => {
	const { modalStateHandler, parentModalStateHandler, isModalOpen } = props;
	const [showError, setShowError] = useState<boolean>(false);
	const [locationIds, setLocationIds] = useState<string[]>([]);

	const [email, setEmail] = useState('');

	const { t } = useTranslation();
	const [invitePartnershipUser, invitePartnershipUserResult] = useMutation(
		INVITE_PARTNERSHIP_USER
	);

	const [editMemberAccessToLocation, editMemberAccessToLocationResult] =
		useMutation(EDIT_MEMBER_ACCESS_TO_LOCATIONS);

	const isValidEmail = Boolean(validateEmail(email));
	const isInviting =
		invitePartnershipUserResult.loading ||
		editMemberAccessToLocationResult.loading;
	const canSendInvitation =
		isValidEmail && email.length && locationIds.length && !isInviting;

	useEffect(() => {
		if (isModalOpen && editMemberAccessToLocationResult.data) {
			setEmail('');
			setLocationIds([]);
			modalStateHandler(false);
			closeModal();
		}
	}, [editMemberAccessToLocationResult.data, isModalOpen]);

	const backToParentModal = () => {
		modalStateHandler(false);
		parentModalStateHandler(true);
	};

	const closeModal = () => {
		modalStateHandler(false);
		setEmail('');
		setLocationIds([]);
	};

	const handleEmailChange = (newValue: string) => {
		if (showError && validateEmail(newValue)) setShowError(false);
		setEmail(newValue);
	};

	const handleFarmsChange = (farms: string[]) => {
		setLocationIds(farms);
	};

	const handleSendInvitation = () => {
		void invitePartnershipUser({
			variables: {
				input: { email },
			},
			onCompleted: (data) => {
				void editMemberAccessToLocation({
					variables: {
						memberId: data.invitePartnershipUser.id,
						locationIds,
					},
					refetchQueries: ['getCompanyInvitedUsersOnManageMembersPage'],
				});
			},
		});
	};

	useSnackbarNotifications({
		mutationResult: editMemberAccessToLocationResult,
		messageSuccess: t('managePartnerMembers.inviteMember.success'),
		messageFailure: t('managePartnerMembers.inviteMember.failure'),
	});

	return (
		<>
			<Modal.UncontrolledModal toggleOpen={closeModal} open={isModalOpen}>
				<Modal.DialogTitle
					title={t('managePartnerMembers.inviteMembers')}
					closeIconHandler={closeModal}
				/>
				<Modal.DialogContent
					sx={{ borderBottom: `1px solid ${FarmableColors.BLACK_12}` }}
				>
					<Box paddingBottom="0.5rem">
						<GraphQLError task={invitePartnershipUserResult} />
						<Text variant="label">{t('common.to')}</Text>
						<TextField
							value={email}
							onChange={handleEmailChange}
							onBlur={() => setShowError(!isValidEmail)}
							error={showError}
							helperText={showError ? t('errors.incorrectEmail.message') : ''}
							required
							placeholder={t('common.emailPlaceholder')}
						/>
					</Box>
					<FarmsMultiselectList onChangeCallback={handleFarmsChange} />
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant={'text'} onClick={backToParentModal}>
							{t('buttons.back')}
						</Button>
						<Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
							<Button
								variant={'outlined'}
								disabled={!canSendInvitation}
								onClick={handleSendInvitation}
								startIcon={isInviting ? <LoadingIcon fontSize="small" /> : null}
							>
								{t('buttons.sendInvitation')}
							</Button>
						</Box>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default InviteMemberModal;
