import { parseISO } from 'date-fns';

import { TRunStatusLog, RunStatus } from './isJobRunStatusLog';

const dateFromApiFormat = (date: string): Date => parseISO(`${date}Z`);

export type TJobRunningPeriod = [number, number];

export const getRunningPeriods = (
	runStatusHistory: TRunStatusLog[]
): TJobRunningPeriod[] => {
	const periods = runStatusHistory
		.map((item) => ({
			...item,
			timestamp: dateFromApiFormat(item.timestamp).getTime(),
		}))
		.sort((a, b) => a.timestamp - b.timestamp)
		.reduce<{
			step: 'start' | 'stop';
			value: number | null;
			result: TJobRunningPeriod[];
		}>(
			(acc, item) => {
				if (acc.step === 'start' && item.status === RunStatus.RUNNING) {
					return {
						...acc,
						step: 'stop',
						value: item.timestamp,
					};
				}
				if (
					acc.step === 'stop' &&
					acc.value &&
					(item.status === RunStatus.PAUSED ||
						item.status === RunStatus.COMPLETED)
				) {
					return {
						step: 'start',
						value: null,
						result: [...acc.result, [acc.value, item.timestamp]],
					};
				}
				return acc;
			},
			{
				step: 'start',
				value: null,
				result: [],
			}
		);

	if (periods.step === 'stop' && periods.value) {
		return [...periods.result, [periods.value, Date.now()]];
	}

	return periods.result;
};
