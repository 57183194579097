export enum PartnerSalesLogsTableEnum {
	delivery = 'delivery',
	farmName = 'farmName',
	farmOwner = 'farmOwner',
	harvestDate = 'harvestDate',
	fields = 'fields',
	category = 'category',
	variety = 'variety',
	volume = 'volume',
	quality = 'quality',
}

export const PartnerSalesLogsTableBodyValues = Object.values(
	PartnerSalesLogsTableEnum
);
