import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import LoadingContent from '../../common/components/LoadingContent';
import GET_PARTNER_CAUSES from '../api/getPartnerCauses';
import CausesCard from './CausesCard';

function PageBody() {
	const { data, loading, error } = useQuery(GET_PARTNER_CAUSES);
	if (data) {
		return <CausesCard causes={data.getPartnershipCauses.causes} />;
	}

	if (loading) {
		return <LoadingContent />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
}

export default PageBody;
