import React, { useEffect, useState, ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import { TPartnerLocations } from 'Models/partnershipCompany/isPartnerLocation';
import getSharedLocationsBBox from 'Models/sharedLocations/getSharedLocationsBBox';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Box from 'UI/layout/Box';
import Map from 'UI/maps/Map';
import { refetchMaxZoom } from 'UI/maps/Map/events';
import GMapLayer from 'UI/maps/layers/GMapLayer';
import { point, bounds as leafletBounds, Map as TMap } from 'leaflet';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import OverlayLoader from '../../components/OverlayLoader';
import { fetchMaxZoom } from '../../features/google/initGoogleMapsAPILoader';
import NoSharedLocationsText from './components/NoSharedLocationsText';
import SharedLocationsMarker from './components/SharedLocationsMarker';
import hideOverlappingTooltips from './utils/hideOverlappingTooltips';

type TSearchMapComponent = {
	sharedLocations: TPartnerLocations;
	isLoading?: boolean;
	hideSearchInput?: boolean;
	disableFarmClick?: boolean;
	onLocationMarkerClick?: (locationId: string) => void;
};

const FarmsSearchMap = (props: TSearchMapComponent): ReactElement => {
	const { sharedLocations, isLoading, hideSearchInput, onLocationMarkerClick } =
		props;
	const [bounds, setBounds] = useState(Map.DEFAULT_BOUNDS);
	const { t } = useTranslation();
	const [filteredLocations, setFilteredLocations] =
		useState<TPartnerLocations | null>(null);
	const [searchValue, setSearchValue] = useState('');
	const [map, setMap] = useState<TMap | null>(null);
	const { company } = usePartnerCompany();
	const history = useHistory();

	const onChange = (newValue: string) => {
		if (sharedLocations) {
			const filteredValue = sharedLocations.filter((location) =>
				location.name?.toUpperCase().includes(newValue.toUpperCase())
			);
			setFilteredLocations(
				filteredValue.length ? filteredValue : sharedLocations
			);
		}

		setSearchValue(newValue);
	};

	const onClear = () => {
		setSearchValue('');
		setFilteredLocations(null);
	};

	const defaultOnClick = (locationId: string) => {
		const newURL = `/partners/${company?.id}/dashboard/partner-locations/${locationId}`;

		history.push(newURL);
	};

	useEffect(() => {
		if (sharedLocations?.length) {
			const zoomOn = filteredLocations || sharedLocations;

			const boundingBox = getSharedLocationsBBox(zoomOn);

			if (boundingBox && map) {
				const boundsCenter = leafletBounds(
					point(boundingBox[0]),
					point(boundingBox[1])
				).getCenter();

				const coordinates = { lng: boundsCenter.x, lat: boundsCenter.y };

				fetchMaxZoom(coordinates, (response) => {
					// Sometimes Google Maps does not provide accurate zoom
					// so we need to decrease it for safety reasons by 1.
					map.options.maxZoom = response.zoom - 1;
					setBounds(boundingBox);
				});
			}
		} else {
			setBounds(Map.DEFAULT_BOUNDS);
		}
	}, [sharedLocations, filteredLocations, map]);

	const getMapContent = () => {
		if (isLoading) {
			return <OverlayLoader />;
		}

		if (!sharedLocations.length) {
			return <NoSharedLocationsText />;
		} else {
			return (
				<>
					{hideSearchInput ? null : (
						<Box
							maxWidth="25rem"
							position="relative"
							margin="0.5rem"
							zIndex={9999}
						>
							<FreeTextSearch
								inputPlaceholder={t('searchMap.freeTextSearchPlaceholder')}
								onChange={onChange}
								onClear={onClear}
								value={searchValue}
							/>
						</Box>
					)}
					<SharedLocationsMarker
						sharedLocations={sharedLocations}
						showLocationsName
						onLocationMarkerClick={onLocationMarkerClick || defaultOnClick}
					/>
				</>
			);
		}
	};

	return (
		<Box data-testid="search-map" height="27.125rem" margin="0.5rem">
			<Map.Controlled
				bounds={bounds}
				boundsOptions={{ padding: [50, 50] }}
				maxBoundsViscosity={1}
				onMapZoomEnd={hideOverlappingTooltips}
				onMapReflow={hideOverlappingTooltips}
				onDragEnd={refetchMaxZoom(fetchMaxZoom)}
				whenCreated={(map) => setMap(map)}
			>
				<>
					{getMapContent()}
					<GMapLayer />
				</>
			</Map.Controlled>
		</Box>
	);
};

export default FarmsSearchMap;
