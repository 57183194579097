import { intervalToDuration } from 'date-fns';

import { TTimesheet } from './isTimesheet';
import timesheetDurationMs from './timesheetDurationMs';

function timesheetDurationString(
	timesheet: Pick<TTimesheet, 'endTime' | 'timesheetFieldHours'>
): string {
	const durationInMs = timesheetDurationMs(timesheet);

	if (!durationInMs) {
		return '-';
	}

	const durationObject = intervalToDuration({
		start: new Date(0),
		end: new Date(durationInMs),
	});

	return `${durationObject.hours}h ${durationObject.minutes}m`;
}

export default timesheetDurationString;
