import z from 'zod';

export const TimesheetSettingsInputSchema = z.object({
	subtractLunchTime: z.boolean(),
	minDurationForSubtractingLunch: z.number().positive().optional(),
	lunchTimeDuration: z.number().positive().optional(),
	pricePerHour: z.number().positive().optional(),
	currency: z.string().optional(),
});

export type TTimesheetSettingsInput = {
	subtractLunchTime: boolean;
	minDurationForSubtractingLunch?: number | null;
	lunchTimeDuration?: number | null;
	pricePerHour?: number | null;
	currency?: string | null;
};

export const isTimesheetSettingsInput = (
	input: unknown
): input is TTimesheetSettingsInput =>
	TimesheetSettingsInputSchema.safeParse(input).success;
