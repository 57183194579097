// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2XaAIztAzJ9EU8LFeL6186 {\n  background-color: #fafafa;\n  border-radius: .2rem;\n  box-shadow: 0 1px 5px 0 rgba(0,0,0,.16),0 3px 1px -2px rgba(0,0,0,.08),0 2px 2px 0 rgba(0,0,0,.12);\n  padding: .75rem;\n}\n\n._2LTbAacbVL_YIHkk5gKv_m {\n  margin-left: 2.5rem;\n}\n\n._2k9PsjJ3Yh9RODyJTkQ5UK {\n  margin-left: -2.5rem;\n  text-align: end;\n}\n\n._1r6aq9GNS7RgDnOF8yx0bw {\n  display: flex;\n  align-items: center;\n  height: 1.5rem;\n}\n\n._3Lu_WQ_O6nDCuidjGik1ns {\n  width: .75rem;\n  height: .25rem;\n  margin: .5rem .375rem .5rem 0;\n  border-radius: 2px;\n}\n\n._2ny-zOFRxdYXzN633tZdO6 {\n  font-size: 1.2rem;\n  padding-bottom: .1rem;\n  padding-right: .2rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"tooltipContainer": "_2XaAIztAzJ9EU8LFeL6186",
	"firstXAxisLabel": "_2LTbAacbVL_YIHkk5gKv_m",
	"lastXAxisLabel": "_2k9PsjJ3Yh9RODyJTkQ5UK",
	"legendItem": "_1r6aq9GNS7RgDnOF8yx0bw",
	"lineMarker": "_3Lu_WQ_O6nDCuidjGik1ns",
	"circleMarker": "_2ny-zOFRxdYXzN633tZdO6"
};
module.exports = exports;
