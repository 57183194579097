import useMonthsTranslation from 'Features/localization/hooks/useMonthsTranslation';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TChartProps } from 'UI/display/Chart';
import { FarmableColors } from 'UI/theme/Colors';
import classnames from 'classnames';
import { format } from 'date-fns';
import Highcharts from 'highcharts';
import isNumber from 'vet/numbers/isNumber';

import initialChartConfig, { ExtendedAxis } from '../initialChartConfig';
import { TFieldWeatherDaily } from '../models/isFieldWeatherDaily';
import { TMilestone } from '../models/isMilestone';
import { isTemperatureChartSeries } from '../models/isTemperatureChartSeries';
import styles from '../styles.module.scss';

type _TPoint = {
	x: number | undefined;
	y: number | undefined;
};

type _TParsedWeatherData = {
	temperature: _TPoint[];
	gdd: _TPoint[];
};

function _parseWeatherData(data: TFieldWeatherDaily[]): _TParsedWeatherData {
	return data.reduce(
		(acc: _TParsedWeatherData, curr) => {
			return {
				temperature: [
					...acc.temperature,
					{
						x: new Date(curr.date).getTime(),
						y: isNumber(curr.valueTempAvg) ? curr.valueTempAvg : undefined,
					},
				],
				gdd: [
					...acc.gdd,
					{
						x: new Date(curr.date).getTime(),
						y: isNumber(curr.valueGddAvg) ? curr.valueGddAvg : undefined,
					},
				],
			};
		},
		{ temperature: [], gdd: [] }
	);
}

function _parseMilestoneData(data: TMilestone[]) {
	return data.map((milestone) => ({
		name: milestone.name,
		x: new Date(milestone.date).getTime(),
		y: milestone.value,
	}));
}

export type TChartOptionsData =
	| {
			showPredictions: true;
			fieldWeather: TFieldWeatherDaily[];
			fieldWeatherPredictions: TFieldWeatherDaily[];
			milestones: TMilestone[];
			milestonesPredictions: TMilestone[];
	  }
	| {
			showPredictions: false;
			fieldWeather: TFieldWeatherDaily[];
			milestones: TMilestone[];
	  };

const TEMPERATURE_PREDICTIONS_SERIES_INDEX = 5;

function useChartOptions(data: TChartOptionsData): TChartProps['options'] {
	const { t } = useTranslation();
	const monthsTranslation = useMonthsTranslation('short');
	const { fieldWeather, milestones, showPredictions } = data;

	const parsedWeatherData = _parseWeatherData(fieldWeather);
	const parsedMilestoneData = _parseMilestoneData(milestones);

	const parsedWeatherPredictionsData =
		showPredictions && _parseWeatherData(data.fieldWeatherPredictions);
	const parsedMilestonesPredictionsData =
		showPredictions && _parseMilestoneData(data.milestonesPredictions);

	function drawPredictionsSeparator(this: Highcharts.Chart): void {
		const temperaturePredictionsSeries = this.series.find(
			(series) => series.index === TEMPERATURE_PREDICTIONS_SERIES_INDEX
		);
		const hasPredictions =
			showPredictions && temperaturePredictionsSeries?.visible;

		if (hasPredictions) {
			const xAxis = this.xAxis[0];
			const firstPredictionsPoint = temperaturePredictionsSeries.points[0];

			xAxis.addPlotLine({
				id: 'predictionsSeparator',
				width: 2,
				value: firstPredictionsPoint.x,
				color: FarmableColors.BLACK_12,
				dashStyle: 'ShortDot',
			});
		}
	}

	return {
		...initialChartConfig,
		chart: {
			...initialChartConfig.chart,
			events: {
				load: drawPredictionsSeparator,
			},
		},
		tooltip: {
			...initialChartConfig.tooltip,
			formatter(this: Highcharts.TooltipFormatterContextObject) {
				const date = new Date(this.point.x);
				const dateFormatted = `${format(date, 'dd')} ${
					monthsTranslation[date.getMonth()]
				}`;
				return `
				<div class="${styles.tooltipContainer}">
					${t('common.gddChart.plantMilestones')} <br/> 
					${dateFormatted} <b>${this.point.name}</b>
				</div>
			`;
			},
		},
		xAxis: {
			...initialChartConfig.xAxis,
			labels: {
				useHTML: true,
				style: {
					fontSize: '0.8125rem',
				},
				formatter() {
					const date = new Date(this.value);
					const firstRow = `${format(date, 'dd')} ${
						monthsTranslation[date.getMonth()]
					}`;
					const secondRow = format(date, 'yyyy');

					const year = `<div>${secondRow}</div>`;
					const className = classnames(
						this.isFirst ? styles.firstXAxisLabel : null,
						this.isLast ? styles.lastXAxisLabel : null
					);

					return `<div class="${className}"><div>${firstRow}</div>${
						this.isFirst || this.isLast ? year : ''
					}</div>`;
				},
			},
			tickPositioner(this: ExtendedAxis) {
				const firstDayOfYear = Date.UTC(new Date().getFullYear(), 0, 1);
				const lastDayOfYear = Date.UTC(new Date().getFullYear(), 11, 31);

				if (showPredictions) {
					const temperaturePredictionsSeries = this.series.find(
						(series) => series.index === TEMPERATURE_PREDICTIONS_SERIES_INDEX
					);

					if (isTemperatureChartSeries(temperaturePredictionsSeries)) {
						const firstPredictionsPoint =
							temperaturePredictionsSeries.options.data[0];

						if (firstPredictionsPoint) {
							return [firstDayOfYear, firstPredictionsPoint.x, lastDayOfYear];
						}
					}
				}

				return [firstDayOfYear, lastDayOfYear];
			},
		},
		series: [
			{
				id: 'dailyAvgTemperature',
				name: t('common.gddChart.dailyAvgTemperature'),
				type: 'line',
				color: FarmableColors.LIGHT_BLUE,
				index: 4,
				legendIndex: 2,
				yAxis: 0,
				data: parsedWeatherData.temperature,
			},
			{
				// predictions
				visible: showPredictions,
				name: t('common.gddChart.dailyAvgTemperature'),
				type: 'spline',
				color: FarmableColors.PALE_BLUE,
				showInLegend: false,
				index: TEMPERATURE_PREDICTIONS_SERIES_INDEX,
				yAxis: 0,
				data: parsedWeatherPredictionsData
					? parsedWeatherPredictionsData.temperature
					: undefined,
			},
			{
				id: 'gdd',
				name: t('common.gddChart.gdd'),
				type: 'line',
				color: FarmableColors.ORANGE,
				index: 0,
				legendIndex: 0,
				yAxis: 1,
				data: parsedWeatherData.gdd,
			},
			{
				// predictions
				visible: showPredictions,
				name: t('common.gddChart.gdd'),
				type: 'line',
				color: FarmableColors.ORANGE_13_22,
				showInLegend: false,
				index: 1,
				yAxis: 1,
				data: parsedWeatherPredictionsData
					? parsedWeatherPredictionsData.gdd
					: undefined,
			},
			{
				id: 'plantMilestones',
				name: t('common.gddChart.plantMilestones'),
				type: 'scatter',
				color: FarmableColors.ORANGE,
				index: 3,
				legendIndex: 1,
				yAxis: 1,
				data: parsedMilestoneData,
			},
			{
				// predictions
				visible: showPredictions,
				name: t('common.gddChart.plantMilestones'),
				type: 'scatter',
				color: FarmableColors.ORANGE_13_22,
				marker: {
					lineColor: FarmableColors.ORANGE_13_22,
				},
				showInLegend: false,
				index: 2,
				yAxis: 1,
				data: parsedMilestonesPredictionsData || undefined,
			},
		],
	};
}

export default useChartOptions;
