import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Breakpoints from 'UI/theme/breakpoints';
import useBreakpoint from 'UI/utils/useBreakpoint';

import useLocationID from 'Hooks/useLocationID';

import Template from '../../../../../components/DashboardPageTemplate';
import CropSummary from '../CropSummary';
import SearchMap from '../SearchMap';
import Breadcrumbs from './../../../common/components/Breadcrumbs';
import PartnerRecentEvents from './../../../common/components/PartnerRecentEvents';

export default function PageBody() {
	const { t } = useTranslation();
	const locationId = useLocationID();
	const isTablet = useBreakpoint(Breakpoints.TABLET);

	return (
		<Template>
			<Template.Navigation>
				<Breadcrumbs />
			</Template.Navigation>
			<Template.Board>
				<Template.Card
					size={isTablet ? 6 : 4}
					title={t('farmDashboardPage.summarySection.title')}
					noHeaderBorder
					noPadding
				>
					<CropSummary />
				</Template.Card>
				<Template.Card size={isTablet ? 6 : 8} noPadding noHeaderBorder>
					<SearchMap />
				</Template.Card>
				<Template.Card
					size={isTablet ? 6 : 4}
					title={t('farmDashboardPage.recentEvents.title')}
					noPadding
				>
					<PartnerRecentEvents locationIds={[locationId]} />
				</Template.Card>
			</Template.Board>
		</Template>
	);
}
