export type TDirection = 'ASC' | 'DESC';

type TPageInput = {
	limit: number;
	offset: number;
	sortBy: { direction: TDirection; field: string }[];
};

export const sortByCreatedDateDesc: TPageInput['sortBy'] = [
	{
		direction: 'DESC',
		field: 'createdAt',
	},
];

export const sortByCreatedDateAsc: TPageInput['sortBy'] = [
	{
		direction: 'ASC',
		field: 'createdAt',
	},
];

export default TPageInput;
