import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';

import {
	treatmentLibraryProductFragmentFields,
	treatmentLibraryTreatmentFragmentFields,
} from './fragments';

type TCreateLibraryTreatmentResult = {
	createLibraryTreatment: TTreatmentLibrary;
};

type TCreateLibraryTreatmentInput = {
	libraryId: string;
	productId: string;
	input: TProductTreatmentInput;
};

export const CREATE_LIBRARY_TREATMENT: TypedDocumentNode<
	TCreateLibraryTreatmentResult,
	TCreateLibraryTreatmentInput
> = gql`
	mutation createLibraryTreatment(
		$libraryId: ID!
		$productId: ID!
		$input: ProductTreatmentInput!
	) {
		createLibraryTreatment(
			libraryId: $libraryId
			productId: $productId
			input: $input
		) {
			${treatmentLibraryTreatmentFragmentFields}
		}
	}
`;

export const addLibraryTreatmentInCache: MutationUpdaterFunction<
	TCreateLibraryTreatmentResult,
	TCreateLibraryTreatmentInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }, { variables }) => {
	const productCacheId = cache.identify({
		__typename: 'Product',
		id: variables?.productId,
	});

	const fragment = gql`
		fragment NewTreatment on Product {
			${treatmentLibraryProductFragmentFields}
		}
	`;

	cache.modify({
		id: productCacheId,
		fields: {
			treatments(existingTreatments = []) {
				const newTreatmentRef = cache.writeFragment({
					data: data?.createLibraryTreatment,
					fragment,
				});

				return [...existingTreatments, newTreatmentRef];
			},
		},
	});
};
