import { Map as TMap } from 'leaflet';

import { TFetchMaxZoom } from '../../../features/google/initGoogleMapsAPILoader';

// Refetch & reset maxZoom while navigating the map
export const refetchMaxZoom = (fetchMaxZoom: TFetchMaxZoom) => (map: TMap) => {
	fetchMaxZoom(map.getCenter(), (response) => {
		// Sometimes Google Maps does not provide accurate zoom
		// so we need to decrease it for safety reasons by 1.
		map.options.maxZoom = response.zoom - 1;
	});
};
