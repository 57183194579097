import React from 'react';

import { QueryHookOptions, useQuery } from '@apollo/client';
import { TTranslateFunction } from 'Features/localization';
import TPageInput from 'Models/api/PageInput';
import Tooltip from 'UI/display/Tooltip';
import { FarmableColors } from 'UI/theme/Colors';
import { format } from 'date-fns';

import translateProductCategory from 'Utils/translateProductCategory';

import { GET_PARTNER_SALES_TABLE_DATA } from './PartnerSalesLogs.queries';
import { SalesLogApiType } from './PartnerSalesLogs.types';

interface GetPartnerSalesDataResponse {
	totalPages: number;
	totalElements: number;
	getPartnerSales: {
		content: SalesLogApiType[];
	};
}

interface GetPartnerSalesDataVariables {
	filterBy: {
		saleFilters: {
			dateFrom: string;
			dateTo: string;
			customerId: string;
			varieties: string[];
		};
		locationIds: string[];
	};
	pageInput: TPageInput;
}

export const useGetPartnerSalesData = (
	options: QueryHookOptions<
		GetPartnerSalesDataResponse,
		GetPartnerSalesDataVariables
	>
) =>
	useQuery<GetPartnerSalesDataResponse, GetPartnerSalesDataVariables>(
		GET_PARTNER_SALES_TABLE_DATA,
		options
	);

const DEFAULT_FORMAT = 'dd.MM.yyyy';

export const useGetPartnerSalesRowData = (
	data: SalesLogApiType[] | undefined,
	t: TTranslateFunction
) => {
	if (!data) {
		return [];
	}

	const FieldsTooltip = (value: string[]) => (
		<Tooltip
			arrow
			placement="top"
			title={value.join(', ')}
			disableHoverListener={value.length <= 1}
			arrowColor={FarmableColors.LIGHT_GREY}
		>
			<div>
				<span>
					{`${value[0]}`}
					{value.length > 1 ? (
						<span
							style={{
								marginLeft: '4px',
								background: FarmableColors.BLACK_4,
								fontWeight: 'bold',
								borderRadius: '50%',
								padding: '4px',
							}}
						>
							+{value.length}
						</span>
					) : null}
				</span>
			</div>
		</Tooltip>
	);

	return data.map(({ sale, location }) =>
		Object.values({
			delivery: format(new Date(sale.date), DEFAULT_FORMAT),
			farmName: location.name,
			farmOwner: `${location.farmOwner?.firstName} ${location.farmOwner?.lastName}`,
			harvestDate:
				sale.batches[0].date === sale.batches[sale.batches.length - 1].date
					? format(new Date(sale.batches[0].date), DEFAULT_FORMAT)
					: `${format(
							new Date(sale.batches[0].date),
							DEFAULT_FORMAT
					  )} - ${format(
							new Date(sale.batches[sale.batches.length - 1].date),
							DEFAULT_FORMAT
					  )}`,
			fields: FieldsTooltip(
				sale.batches.map(({ field }) => field.name) as string[]
			),
			category: sale.batches[0].field.varieties?.[0].category
				? translateProductCategory(
						sale.batches[0].field.varieties?.[0].category,
						t
				  )
				: '',
			variety: sale.variety,
			volume:
				sale?.boxSize && sale?.boxVolume
					? `${(sale?.boxSize * sale?.boxVolume).toFixed()} kg`
					: `${sale.volume} kg`,
			quality: sale.quality,
		})
	);
};
