import React, { useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductIngredientInput } from 'Models/products/ProductIngredientInput';
import { TProductInput } from 'Models/products/ProductInput';
import TextField from 'UI/inputs/TextField';

import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../../state';

type TProps = {
	name: TProductIngredientInput['name'];
	id: TProductIngredientInput['id'];
};

function Name(props: TProps) {
	const { name, id } = props;
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { status, input } = state;
	const [showError, setShowError] = useState(false);
	const [innerName, setInnerName] = useState(name);

	if (status === 'SET_PRODUCT_DETAILS') {
		const handleChange = (value: string) => {
			if (showError && value.length > 0) setShowError(false);

			setInnerName(value);
		};

		const handleBlur = () => {
			if (
				!input.selectedGovernmentProduct &&
				'ingredients' in input.product &&
				input.product.ingredients
			) {
				setShowError(innerName.length < 1);

				const newIngredients = input.product.ingredients?.map(
					(innerIngredient) =>
						id === innerIngredient.id
							? {
									...innerIngredient,
									name: innerName,
							  }
							: innerIngredient
				);

				setInnerName(innerName);
				addTreatmentPageState({
					...state,
					input: {
						...input,
						product: {
							...input.product,
							ingredients: newIngredients,
						} as TProductInput,
					},
				});
			}
		};

		return (
			<TextField
				value={innerName}
				fullWidth
				onChange={handleChange}
				onBlur={handleBlur}
				margin="dense"
				label={t('labels.name')}
				disabled={Boolean(selectedProduct)}
				helperText={showError ? t('helpers.noEmptyString') : ''}
				error={showError}
				required
			/>
		);
	}
	return null;
}

export default Name;
