import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Permissions from 'Models/permissions/Permissions';
import Divider from 'UI/display/Divider';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import PestControl from 'UI/icons/PestControl';
import ProductsIcon from 'UI/icons/Products';
import SettingsIcon from 'UI/icons/Settings';
import UsersIcon from 'UI/icons/Users';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu/menu';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import usePermission from 'Hooks/usePermission';
import getFeatureFlag from 'Utils/getFeatureFlag';

import DropdownPageItem from './DropdownPageItem';
import styles from './DropdownPageItem.module.scss';
import SettingsMenuHeader from './SettingsMenuHeader';

const PartnerSettingsDropdown = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { company } = usePartnerCompany();
	const { t } = useTranslation();

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const hasPermissionsToSeeDropdown = usePermission(
		Permissions.NAVBAR_SETTINGS_DROPDOWN
	);

	const hasPartnerOwnerPermission = usePermission(
		Permissions.PARTNER_PORTAL_OWNER
	);

	const hasEnabledPartnerTreatments = getFeatureFlag('PARTNER_TREATMENTS');

	const hasAccessToCauses =
		company?.country !== 'ES' || process.env.NODE_ENV !== 'production';

	return company && hasPermissionsToSeeDropdown ? (
		<>
			<Box className={styles.farmNameBox} onClick={handleClick}>
				<p className={styles.farmName}>{company.name}</p>
				<ArrowDropDownIcon />
			</Box>
			<Menu
				id="navbar-partner-name-menu"
				anchorEl={anchorEl}
				transitionDuration={0}
				sx={{ left: '1.5rem', top: '-0.25rem' }}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'navbar-farm-name-menu',
					dense: true,
				}}
			>
				<SettingsMenuHeader name={company.name} address={company.address} />
				<Divider key="partner-settings-divider" sx={{ marginY: '0.5rem' }} />
				{hasPartnerOwnerPermission ? (
					<DropdownPageItem
						link={`/partners/${company.id}/settings/info`}
						icon={SettingsIcon}
						key="partner-settings-link"
						label={t(`common.sidebar.partnerInfo`)}
						onClick={handleClose}
					/>
				) : null}
				<DropdownPageItem
					link={`/partners/${company.id}/settings/members`}
					icon={UsersIcon}
					key="manage-partner-members-link"
					label={t(`common.sidebar.manageMembers`)}
					onClick={handleClose}
				/>
				<Divider sx={{ marginY: '0.5rem' }} />
				{hasEnabledPartnerTreatments ? (
					<DropdownPageItem
						key="treatments-link"
						link={`/partners/${company.id}/settings/treatments`}
						icon={ProductsIcon}
						label={t(`common.sidebar.treatments`)}
						onClick={handleClose}
					/>
				) : null}
				{hasAccessToCauses ? (
					<DropdownPageItem
						key="causes-link"
						link={`/partners/${company.id}/settings/causes`}
						icon={PestControl}
						label={t(`common.sidebar.causes`)}
						onClick={handleClose}
					/>
				) : null}
			</Menu>
		</>
	) : null;
};

export default PartnerSettingsDropdown;
