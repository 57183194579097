import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';
import { TTreatmentLibraryInput } from 'Models/treatmentLibrary/isTreatmentLibraryInput';

import GET_PARTNER_TREATMENT_LIBRARIES from './getPartnerTreatmentLibraries';

type TCreateTreatmentLibraryResult = {
	createTreatmentLibrary: TTreatmentLibrary;
};

type TCreateTreatmentLibraryInput = {
	input: TTreatmentLibraryInput;
};

const CREATE_TREATMENT_LIBRARY: TypedDocumentNode<
	TCreateTreatmentLibraryResult,
	TCreateTreatmentLibraryInput
> = gql`
	mutation createTreatmentLibrary($input: TreatmentLibraryInput!) {
		createTreatmentLibrary(input: $input) {
			id
			name
		}
	}
`;

export const addCreatedTreatmentLibraryInCache: MutationUpdaterFunction<
	TCreateTreatmentLibraryResult,
	TCreateTreatmentLibraryInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }) => {
	const query = { query: GET_PARTNER_TREATMENT_LIBRARIES };
	const existingData = cache.readQuery(query)?.getTreatmentLibraries;
	if (existingData && data) {
		cache.writeQuery({
			...query,
			data: {
				getTreatmentLibraries: {
					...existingData,
					content: [...existingData.content, data.createTreatmentLibrary],
				},
			},
		});
	}
};

export default CREATE_TREATMENT_LIBRARY;
