import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getAccountName from 'Models/account/getAccountName';
import { TPartnershipCompanyUser } from 'Models/partnershipCompany/isPartnershipCompanyUser';
import Roles from 'Models/permissions/Roles';
import translateRoles from 'Models/roles/translateRoles';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import useProfile from 'Hooks/useProfile';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import DEACTIVATE_PARTNERSHIP_USER from '../../api/DeactivatePartnershipUser';
import ChangeFarmsAccessModal from './components/ChangeFarmsAccessModal';
import ChangeToAdminAccessModal from './components/ChangeToAdminAccessModal';
import MemberDetailsModal from './components/MemberDetailsModal';
import MemberDropdown from './components/MemberDropdown';
import MemberTooltip from './components/MemberTooltip';

export type TMemberItemProps = {
	member: TPartnershipCompanyUser;
};

const MemberItem = (props: TMemberItemProps) => {
	const {
		firstName,
		lastName,
		email,
		role,
		id,
		profileImage,
		availableLocations,
	} = props.member;
	const { t } = useTranslation();
	const translatedRole = translateRoles(role, t);
	const { profile } = useProfile();
	const isYou = profile?.id === id ? ` (${t('labels.you')})` : '';
	const [isProfileDetailsModalOpen, setIsProfileDetailsModalOpen] =
		useState(false);
	const [isChangeFarmsAccessModalOpen, setIsChangeFarmsAccessModalOpen] =
		useState(false);
	const [isAdminAccessModalOpen, setIsAdminAccessModalOpen] = useState(false);

	const memberTooltip =
		role === Roles.PA_MEMBER ? (
			<MemberTooltip
				sharedLocations={availableLocations.map((location) => location.name)}
			/>
		) : null;

	const [deactivatePartnershipUser, deactivatePartnershipUserResult] =
		useMutation(DEACTIVATE_PARTNERSHIP_USER);

	useSnackbarNotifications({
		mutationResult: deactivatePartnershipUserResult,
		messageSuccess: t('managePartnerMembers.deactivateUser.success'),
		messageFailure: t('managePartnerMembers.deactivateUser.failure'),
	});

	const onDeactivateAccountClick = () => {
		void deactivatePartnershipUser({
			variables: { partnershipUserId: id },
			refetchQueries: ['getCompanyInvitedUsersOnManageMembersPage'],
		});
	};

	const memberItemTitle = getAccountName({ firstName, lastName }) || email;

	return (
		<>
			<Box display="flex" padding="1rem 0" justifyContent="space-between">
				<Box display="flex">
					<Avatar alt={memberItemTitle} src={profileImage?.path} />
					<Box display="flex" flexDirection="column" marginLeft="1rem">
						<Text
							sx={{
								lineHeight: 1.25,
								cursor: 'pointer',
								'&:hover': {
									textDecoration: 'underline',
								},
							}}
							onClick={() => {
								setIsProfileDetailsModalOpen(true);
							}}
						>
							{memberItemTitle} {isYou}
						</Text>
						<Box display="flex">
							<Text variant="body2" sx={{ lineHeight: 1.43 }}>
								{translatedRole}
							</Text>
							{memberTooltip}
							{firstName || lastName ? (
								<Text variant="secondaryBody2" sx={{ lineHeight: 1.43 }}>
									&nbsp; &mdash; {email}
								</Text>
							) : null}
						</Box>
					</Box>
				</Box>
				<MemberDropdown
					role={role}
					id={id}
					displayInfoModal={() => {
						setIsProfileDetailsModalOpen(true);
					}}
					displayChangeToAdminAccessModal={() => {
						setIsAdminAccessModalOpen(true);
					}}
					deactivatePartnershipUser={onDeactivateAccountClick}
					displayFarmsAccessModal={() => {
						setIsChangeFarmsAccessModalOpen(true);
					}}
				/>
			</Box>
			<ChangeFarmsAccessModal
				open={isChangeFarmsAccessModalOpen}
				toggleOpen={() => {
					setIsChangeFarmsAccessModalOpen(!isChangeFarmsAccessModalOpen);
				}}
				member={props.member}
			/>
			<MemberDetailsModal
				open={isProfileDetailsModalOpen}
				toggleOpen={() => {
					setIsProfileDetailsModalOpen(!isProfileDetailsModalOpen);
				}}
				member={props.member}
			/>
			<ChangeToAdminAccessModal
				open={isAdminAccessModalOpen}
				toggleOpen={() => {
					setIsAdminAccessModalOpen(!isAdminAccessModalOpen);
				}}
				member={props.member}
			/>
		</>
	);
};

export default MemberItem;
