import React, { ReactElement } from 'react';

import Modal from 'UI/display/modal/dialog';

type TProps = {
	modalTitle: string;
	open: boolean;
	toggleOpen: () => void;
	modalButtons: ReactElement;
	children: ReactElement;
};

const ManageFieldTemplate = (props: TProps): ReactElement => {
	const { children, toggleOpen, modalTitle, open, modalButtons } = props;

	return (
		<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
			<Modal.DialogTitle title={modalTitle} closeIconHandler={toggleOpen} />
			<Modal.DialogContent>{children}</Modal.DialogContent>
			<Modal.DialogActions>{modalButtons}</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default ManageFieldTemplate;
