import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function FailedIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 88 88" sx={{ width: 64, height: 64 }} {...props}>
			<defs>
				<linearGradient
					id="rgdizw5xib"
					x1="83.809%"
					x2="14.268%"
					y1="84.577%"
					y2="13.954%"
				>
					<stop offset="0%" stopColor="#FFF" stopOpacity="0" />
					<stop offset="100%" stopOpacity=".5" />
				</linearGradient>
				<linearGradient
					id="jx6pdj1b1d"
					x1="14.286%"
					x2="83.792%"
					y1="13.954%"
					y2="84.577%"
				>
					<stop offset="0%" stopColor="#FFF" stopOpacity="0" />
					<stop offset="100%" stopOpacity=".5" />
				</linearGradient>
				<path
					id="e6l67kzvpa"
					d="M40.248 45.254l4.95-4.95c.781-.78.781-2.047 0-2.828L23.922 16.2l-7.737 7.82L37.42 45.253c.78.781 2.047.781 2.828 0z"
				/>
				<path
					id="gm41n3o3xc"
					d="M30.516 22.794l-6.933-6.932L9.136 1.414c-.781-.781-2.048-.781-2.829 0l-4.95 4.95c-.78.78-.78 2.047 0 2.828L15.815 23.65l6.971 6.97 7.73-7.826z"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g>
					<path
						fill={props.fill || '#E10007'}
						d="M0 44c0 24.307 19.7 44 44 44s44-19.693 44-44C88 19.706 68.3 0 44 0S0 19.706 0 44z"
						transform="translate(-756 -184) translate(461 120) translate(295 64)"
					/>
					<g>
						<g transform="translate(-756 -184) translate(461 120) translate(295 64) translate(20.813 20.313)">
							<use fill="#FFF" xlinkHref="#e6l67kzvpa" />
							<use fill="url(#rgdizw5xib)" xlinkHref="#e6l67kzvpa" />
						</g>
						<g transform="translate(-756 -184) translate(461 120) translate(295 64) translate(20.813 20.313)">
							<use fill="#FFF" xlinkHref="#gm41n3o3xc" />
							<use fill="url(#jx6pdj1b1d)" xlinkHref="#gm41n3o3xc" />
						</g>
						<path
							fill="#FFF"
							d="M45.198 6.364l-4.95-4.95c-.78-.781-2.047-.781-2.828 0L1.357 37.476c-.78.781-.78 2.048 0 2.829l4.95 4.95c.781.78 2.048.78 2.829 0L45.198 9.191c.781-.781.781-2.047 0-2.828z"
							transform="translate(-756 -184) translate(461 120) translate(295 64) translate(20.813 20.313)"
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
}

export default FailedIcon;
