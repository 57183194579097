import React from 'react';

import useHistory from 'Features/router/hooks/useHistory';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';
import Text from 'UI/display/Text';
import ChevronRightIcon from 'UI/icons/ChevronRight';
import Box from 'UI/layout/Box';

import useCompanyID from 'Hooks/useCompanyID';

import styles from './styles.module.scss';

type TProps = {
	treatment: TTreatmentLibrary;
};

function LibraryListItem(props: TProps) {
	const { treatment } = props;
	const history = useHistory();
	const companyID = useCompanyID();

	const onLibraryItemClick = () => {
		history.push(`/partners/${companyID}/settings/treatments/${treatment.id}`);
	};

	return (
		<Box className={styles.treatment} onClick={onLibraryItemClick}>
			<Text>{treatment.name}</Text>
			<ChevronRightIcon />
		</Box>
	);
}

export default LibraryListItem;
