import React, { ReactElement, useEffect, useRef } from 'react';

import { polygon, Polygon as TPolygon } from 'leaflet';
import { Polygon as LeafletPolygon, PolygonProps } from 'react-leaflet';

export type TPositions = PolygonProps['positions'];

type TProps = {
	positions: TPositions;
	onClick?: () => void;
	color?: string;
	fillColor?: string;
	opacity?: number;
	fillOpacity?: number;
	tooltipContent?: ReactElement[] | null;
	interactive?: boolean;
};

const Polygon = (props: TProps): ReactElement => {
	const {
		color,
		fillColor,
		positions,
		tooltipContent,
		onClick,
		opacity,
		fillOpacity,
		interactive,
	} = props;
	const polygon = useRef<TPolygon>(null);

	useEffect(() => {
		polygon.current?.setStyle({ color, fillColor, opacity, fillOpacity });
	}, [color, fillColor, opacity, fillOpacity]);

	return (
		<LeafletPolygon
			positions={positions}
			eventHandlers={{
				click: () => {
					onClick ? onClick() : null;
				},
			}}
			ref={polygon}
			interactive={interactive !== false}
		>
			{tooltipContent}
		</LeafletPolygon>
	);
};

export { polygon };

export default Polygon;
