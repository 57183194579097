import { gql, TypedDocumentNode } from '@apollo/client';
import { TPartnershipCompany } from 'Models/partnershipCompany/isPartnershipCompany';
import { TPartnershipCompanyInput } from 'Models/partnershipCompany/isPartnershipCompanyInput';

const CREATE_COMPANY_MUTATION: TypedDocumentNode<
	{
		createPartnershipCompany: TPartnershipCompany;
	},
	{ input: TPartnershipCompanyInput }
> = gql`
	mutation createPartnerCompany($input: PartnershipCompanyInput!) {
		createPartnershipCompany(input: $input) {
			id
			name
			country
			city
			address
			zip
			email
			mobilePhone
		}
	}
`;

export default CREATE_COMPANY_MUTATION;
