import React, { useState, useEffect } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCustomer } from 'Models/customers/isCustomer';
import {
	isCustomerInput,
	TCustomerInput,
} from 'Models/customers/isCustomerInput';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

import UploadImage, { TSelectedFile } from '../../../../components/UploadImage';

type TCustomerModalContainerProps = {
	isModalOpen: boolean;
	onCloseModal: () => void;
	onSaveHandler: (customerInput: TCustomerInput, image?: TSelectedFile) => void;
	isMutationLoading?: boolean;
	customer?: TCustomer;
};

export default function CustomerModalContainer(
	props: TCustomerModalContainerProps
) {
	const { t } = useTranslation();
	const {
		isModalOpen,
		isMutationLoading,
		onCloseModal,
		onSaveHandler,
		customer,
	} = props;

	const [isInputError, setInputError] = useState(false);
	const [name, setName] = useState<undefined | string>(undefined);
	const [address, setAddress] = useState<undefined | string>(undefined);
	const [city, setCity] = useState<undefined | string>(undefined);
	const [zip, setZip] = useState<undefined | string>(undefined);
	const [selectedImages, setSelectedImages] = useState<TSelectedFile[]>([]);

	useEffect(() => {
		if (customer) {
			const { name, address, city, zip } = customer;
			setName(name);
			setAddress(address || '');
			setCity(city || '');
			setZip(zip || '');
		} else {
			setName(undefined);
			setAddress(undefined);
			setCity(undefined);
			setZip(undefined);
		}
		setSelectedImages([]);
	}, [customer, isModalOpen]);

	const onClose = () => {
		onCloseModal();
	};

	const onSave = () => {
		const input = {
			name,
			address,
			city,
			zip,
		};
		if (isCustomerInput(input)) {
			setInputError(false);
			onSaveHandler(input, selectedImages[0]);
		} else {
			setInputError(true);
		}
	};

	const uploadImageButton = (
		<UploadImage
			selectedImages={selectedImages}
			selectedImagesControl={setSelectedImages}
			maxAmount={1}
			ButtonProps={{
				sx: { '&:hover': { backgroundColor: 'transparent' } },
			}}
		>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Avatar
					src={customer?.image?.path}
					size="xl"
					color={FarmableColors.BLACK_38}
					alt={name || undefined}
				/>
				<Text
					variant="body2"
					noWrap
					sx={{
						textDecoration: 'underline',
						cursor: 'pointer',
					}}
					paddingTop="0.5rem"
				>
					{t('labels.updateImage')}
				</Text>
			</Box>
		</UploadImage>
	);

	return (
		<Modal.UncontrolledModal
			open={isModalOpen}
			toggleOpen={isMutationLoading ? undefined : onClose}
		>
			<Modal.DialogTitle
				title={t('customersPage.addCustomer.title')}
				closeIcon={!isMutationLoading}
				closeIconHandler={isMutationLoading ? undefined : onClose}
			/>
			<Modal.DialogContent>
				<Grid container spacing={2} paddingTop="0.5rem" paddingBottom="1.5rem">
					<Grid container item xs={12} spacing={2}>
						<Grid xs={8} container item spacing={2}>
							<Grid item xs={12}>
								<TextField
									size="small"
									margin="dense"
									disabled={isMutationLoading}
									value={name}
									label={t('labels.name')}
									onChange={setName}
									helperText={
										isInputError && !name
											? `${t('errors.requiredField.message')}`
											: undefined
									}
									error={isInputError && !name}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="small"
									margin="dense"
									disabled={isMutationLoading}
									value={address}
									label={t('labels.address')}
									onChange={setAddress}
								/>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="flex-start"
								height="100%"
								alignItems="center"
							>
								{uploadImageButton}
							</Box>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={2}>
						<Grid item xs={8}>
							<TextField
								size="small"
								margin="dense"
								disabled={isMutationLoading}
								value={city}
								label={t('labels.city')}
								onChange={setCity}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								size="small"
								margin="dense"
								disabled={isMutationLoading}
								value={zip}
								label={t('labels.zip')}
								onChange={setZip}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Box display="flex" justifyContent="space-between" width="100%">
					<Button
						variant="text"
						disabled={isMutationLoading}
						onClick={onCloseModal}
					>
						{t('buttons.cancel')}
					</Button>
					<Button
						variant="outlined"
						disabled={isMutationLoading}
						onClick={onSave}
						startIcon={isMutationLoading ? <LoadingIcon /> : undefined}
					>
						{t('buttons.save')}
					</Button>
				</Box>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
}
