import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ErrorBoundary from '../../../components/ErrorBoundary';
import ProfilePageTemplate from '../common/components/ProfilePageTemplate';
import PageContent from './components/PageContent';

function ManageMembersPage() {
	const { t } = useTranslation();
	return (
		<ProfilePageTemplate
			header={t('managePartnerMembers.header')}
			subheader={t('managePartnerMembers.subheader')}
		>
			<ErrorBoundary>
				<PageContent />
			</ErrorBoundary>
		</ProfilePageTemplate>
	);
}

export default ManageMembersPage;
