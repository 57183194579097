import { TTranslateFunction } from 'Features/localization';
import { TChartProps } from 'UI/display/Chart';
import { FarmableColors, FarmableTextColors } from 'UI/theme/Colors';

const initialChartOptions = (t: TTranslateFunction): TChartProps['options'] => {
	return {
		chart: {
			spacing: [0, 0, 0, 0],
			marginTop: 55,
			marginBottom: 55,
			style: {
				fontFamily: 'Lato',
			},
		},
		credits: {
			enabled: false,
		},
		legend: {
			align: 'left',
			padding: 1,
		},
		plotOptions: {
			series: {
				marker: {
					enabled: false,
					symbol: 'circle',
				},
			},
		},
		title: {
			text: t('farmSalesPage.revenueChart.title'),
			align: 'left',
		},
		xAxis: {
			type: 'datetime',
			crosshair: {
				color: FarmableColors.BLACK_24,
				label: {
					enabled: true,
					format: '{value:%d.%m.%y}',
					shape: 'rect',
					borderRadius: 6,
					borderWidth: 1,
					borderColor: FarmableColors.BLACK_12,
					backgroundColor: '#fff',
					style: {
						color: FarmableColors.BLACK_87,
						fontSize: '0.75rem',
					},
					padding: 2,
				},
			},
			labels: {
				y: 24,
				style: {
					fontSize: '0.75rem',
					color: FarmableTextColors.SECONDARY,
				},
			},
			tickLength: 0,
			lineColor: FarmableColors.BLACK_12,
		},
		yAxis: {
			opposite: true,
			title: {
				text: null,
			},
			labels: {
				style: {
					fontSize: '0.75rem',
					color: FarmableTextColors.SECONDARY,
				},
				x: 8,
				align: 'left',
				reserveSpace: true,
			},
		},
	};
};

export default initialChartOptions;
