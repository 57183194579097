import React, { ReactNode, ReactElement } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import L, { LatLngExpression } from 'leaflet';
import ReactDOMServer from 'react-dom/server';

import { IconSizes } from '../../theme/Sizes';
import Marker from '../Marker';

type TProps = {
	position: LatLngExpression;
	markerIcon?: ReactElement;
	size?: keyof typeof IconSizes;
	onClick?: () => void;
	children?: ReactNode;
};

const CustomMarker = (props: TProps) => {
	const { position, markerIcon, size, onClick, children } = props;
	const dimension = IconSizes[size || 'xs'];

	const useStyles = makeStyles({
		markerIcon: {
			background: 'none',
			border: 'none',
		},
	});

	const styles = useStyles();

	const icon = markerIcon
		? L.divIcon({
				iconSize: [dimension, dimension],
				iconAnchor: [dimension / 2, dimension / 2],
				html: ReactDOMServer.renderToString(markerIcon),
				className: styles.markerIcon,
		  })
		: undefined;

	return (
		<Marker position={position} icon={icon} onClick={onClick}>
			{children}
		</Marker>
	);
};

export default CustomMarker;
