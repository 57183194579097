import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCause } from 'Models/causes/isCause';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { FarmableColors } from 'UI/theme/Colors';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import DELETE_CAUSE_MUTATION, {
	removeDeletedCauseFromCache,
} from '../api/deletePartnershipCause';

type TProps = {
	cause: Pick<TCause, 'id'>;
};

const DeleteCauseModal = (props: TProps) => {
	const { cause } = props;

	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	const toggleOpen = () => {
		setOpen(!open);
	};

	const [deleteCause, mutationResult] = useMutation(DELETE_CAUSE_MUTATION, {
		update: removeDeletedCauseFromCache,
	});

	useSnackbarNotifications({
		mutationResult,
		messageSuccess: t('info.deletePartnerCause.success'),
		messageFailure: t('info.deletePartnerCause.failure'),
	});

	const deleteTeam = () => {
		void deleteCause({ variables: { causeId: cause.id } });
	};

	return (
		<>
			<Menu.Item sx={{ color: FarmableColors.RED }} onClick={toggleOpen}>
				{t('buttons.delete')}
			</Menu.Item>
			<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
				<Modal.DialogTitle
					title={t('partnerCausesPage.deleteCauseModal.title')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					<Text>{t('partnerCausesPage.deleteCauseModal.message')}</Text>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant="text" onClick={toggleOpen}>
							{t('buttons.back')}
						</Button>
						<Button
							variant="outlined"
							onClick={deleteTeam}
							disabled={mutationResult.loading}
							startIcon={mutationResult.loading ? <LoadingIcon /> : null}
						>
							{t('buttons.delete')}
						</Button>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default DeleteCauseModal;
