import React, { ReactElement } from 'react';

import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

const LoadingContent = (): ReactElement => {
	return (
		<Card>
			<CardContent>
				<Box display="flex" alignItems="center" justifyContent="center">
					<LoadingSpinner />
				</Box>
			</CardContent>
		</Card>
	);
};

export default LoadingContent;
