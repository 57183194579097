import React from 'react';

import openConsentManager from 'Features/analytics/utils/openConsentManager';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProfile } from 'Models/profile/isProfile';
import Avatar from 'UI/display/Avatar';
import Divider from 'UI/display/Divider';
import PersonalInfoIcon from 'UI/icons/IDCard';
import SettingsIcon from 'UI/icons/Settings';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu/menu';
import { AvatarSizes } from 'UI/theme/Sizes';

import DropdownPageItem from '../DropdownPageItem';
import DropdownSignOutItem from './components/DropdownSignOutItem';
import DropdownUserProfileItem from './components/DropdownUserProfileItem';
import styles from './styles.module.scss';

type TUserProfileDropdown = {
	profile: Pick<TProfile, 'firstName' | 'profileImage' | 'lastName'> | null;
	signOutCallback: () => void;
	profileDescription: string | null;
};

const ProfileDropdown: React.FC<TUserProfileDropdown> = (props) => {
	const { profile: p, profileDescription, signOutCallback } = props;
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	if (!p) return <></>;

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handlePrivacyPreferencesClick = () => {
		openConsentManager();
		handleClose();
	};

	const renderAvatarIcon = (size: keyof typeof AvatarSizes) => (
		<Avatar size={size} alt="Profile avatar" src={p.profileImage?.path}>
			{p.firstName ? p.firstName.charAt(0) : ''}
		</Avatar>
	);

	const personalInfoLink = (
		<DropdownPageItem
			link={`/profile-settings`}
			icon={PersonalInfoIcon}
			label={t(`common.sidebar.personalInfo`)}
			onClick={handleClose}
		/>
	);

	const privacyPreferencesLink = (
		<DropdownPageItem
			onClick={handlePrivacyPreferencesClick}
			icon={SettingsIcon}
			label={t(`common.sidebar.privacyPreferences`)}
		/>
	);

	const userProfileInfo = `${p.firstName || ''} ${p.lastName || ''}`;

	return (
		<>
			<Box className={styles.navbarLink}>
				<Box className={styles.profileButton} onClick={handleClick}>
					{renderAvatarIcon('sm')}
				</Box>
			</Box>
			<Menu
				id="navbar-profile-menu"
				anchorEl={anchorEl}
				transitionDuration={0}
				open={open}
				sx={{ left: '0.5rem', top: '-0.25rem' }}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'navbar-profile-menu',
					dense: true,
				}}
			>
				<DropdownUserProfileItem
					avatarIcon={renderAvatarIcon('md')}
					primaryText={userProfileInfo}
					secondaryText={profileDescription}
				/>
				<Divider sx={{ marginY: '0.5rem' }} />
				{personalInfoLink}
				{privacyPreferencesLink}
				<Divider sx={{ marginY: '0.5rem' }} />
				<DropdownSignOutItem signOutCallback={signOutCallback} />
			</Menu>
		</>
	);
};

export default ProfileDropdown;
