import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJobInputTreatment } from 'Models/jobs/JobInput';
import { ProductUnitsArray } from 'Models/units';
import { toDisplayString as unitToDisplayString } from 'Models/units/transpileUnits';
import Select from 'UI/inputs/Select';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../../../../state';

type TTreatmentUnit = {
	treatment: Pick<TJobInputTreatment, 'unit' | 'originalTreatmentId'>;
	disabled: boolean;
};

const TreatmentUnit = (props: TTreatmentUnit) => {
	const { t } = useTranslation();
	const { unit, originalTreatmentId } = props.treatment;

	const jobInput = useJobInputData();
	const status = useAddJobModalStatus();

	const handleTreatmentUnit = (value) => {
		const treatments = jobInput.jobDetails.treatments.map((treatment) => {
			if (treatment.originalTreatmentId === originalTreatmentId) {
				return {
					...treatment,
					unit: value,
				};
			}

			return treatment;
		});

		jobInput.jobDetails.treatments = treatments;
		addJobModalState({
			status,
			jobInputData: { ...jobInput },
		});
	};

	return (
		<Select
			value={unit}
			label={t('labels.unit')}
			options={ProductUnitsArray}
			changeHandler={handleTreatmentUnit}
			optionFormatter={unitToDisplayString}
			disabled={props.disabled}
		/>
	);
};

export default TreatmentUnit;
