import React, { ReactElement } from 'react';

import { ApolloError } from '@apollo/client';
import getErrorType, { errorTypes } from 'Models/error/getErrorType';
import CriticalErrorFallback from 'UI/fallbacks/CriticalErrorFallback';
import ErrorFallback from 'UI/fallbacks/ErrorFallback';

type FallbackComponentProps = {
	error: Error | ApolloError;
	eventId: string;
	withLogout?: boolean;
	fullHeight?: boolean;
	withinComponent?: boolean;
};

const FallbackComponent = (props: FallbackComponentProps): ReactElement => {
	const { error, fullHeight, withLogout } = props;
	const type = getErrorType(error);

	if (error instanceof ApolloError) {
		if (error.networkError && 'statusCode' in error.networkError) {
			if (type === errorTypes.AUTH_ERROR) {
				return (
					<CriticalErrorFallback
						type={type}
						message={error.message}
						{...props}
					/>
				);
			}
			return (
				<ErrorFallback
					statusCode={error.networkError.statusCode}
					message={error.message}
					type={type}
					{...props}
				/>
			);
		}
		return <ErrorFallback message={error.message} type={type} {...props} />;
	} else if (error instanceof Error) {
		if (fullHeight || withLogout) {
			return (
				<CriticalErrorFallback type={type} message={error.message} {...props} />
			);
		}

		return <ErrorFallback type={type} message={error.message} {...props} />;
	}

	return <ErrorFallback type={errorTypes.UNEXPECTED_ERROR} {...props} />;
};

export default FallbackComponent;
