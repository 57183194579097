import { TRoles } from 'Models/permissions/Roles';

import hasPartnerCompany, {
	isLocationRole,
	isPartnershipCompanyRole,
} from '../utils/hasPartnerCompany';
import useLocationID from './useLocationID';
import useProfileRoles from './useProfileRoles';

function useProfileRole(locationID?: string): TRoles[] {
	const { profileRoles } = useProfileRoles();
	const locationIdFromURL = useLocationID();
	const locationId = locationID || locationIdFromURL;

	if (!locationId && hasPartnerCompany(profileRoles)) {
		return profileRoles
			.filter((profileRole) => isPartnershipCompanyRole(profileRole))
			.map((profileRole) => profileRole.role);
	}

	return profileRoles
		.filter(
			(profileRole) =>
				isLocationRole(profileRole) && profileRole.locationId === locationId
		)
		.map((profileRole) => profileRole.role);
}

export default useProfileRole;
