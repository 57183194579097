import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductInputPartial } from 'Models/products/ProductInput';
import Select from 'UI/inputs/Select';
import Box from 'UI/layout/Box';

import { addTreatmentPageState } from '../../../../../../state';
import { TAddTreatmentsModalInput } from '../../../../../../types';
import GET_FERTILIZER_MATERIALS from './api/getFertilizerMaterials';

function ProductFertilizerMaterial() {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const { input } = state;

	const { data, error, loading } = useQuery(GET_FERTILIZER_MATERIALS);

	if (error) {
		throw new ApolloError(error);
	}

	if (!input.selectedGovernmentProduct) {
		const fertilizerMaterials = data?.getFertilizerMaterials;

		const onChangeHandler = (value: string) => {
			addTreatmentPageState({
				...state,
				input: {
					...input,
					product: {
						...input.product,
						productSpec: {
							...input.product.productSpec,
							fertilizerMaterialId: fertilizerMaterials?.find(
								(fertilizerMaterial) => fertilizerMaterial.name === value
							)?.id,
						},
					},
				},
			} as TAddTreatmentsModalInput);
		};

		return (
			<Box marginTop="0.5rem">
				<Select
					value={
						fertilizerMaterials?.find(
							(fertilizerMaterial) =>
								fertilizerMaterial.id ===
								(input.product as TProductInputPartial).productSpec
									?.fertilizerMaterialId
						)?.name || ''
					}
					label={t('treatmentLibrary.addTreatmentModal.fertilizerMaterial')}
					options={
						fertilizerMaterials
							?.map((fertilizerMaterial) => fertilizerMaterial.name)
							.sort() || []
					}
					disabled={loading || !fertilizerMaterials}
					changeHandler={onChangeHandler}
					small
					required
				/>
			</Box>
		);
	}
	return null;
}

export default ProductFertilizerMaterial;
