import isOneOf from 'vet/isOneOf';
import { ExtendedValidator } from 'vet/types';

export const LogTypes = {
	Jobs: 'Jobs',
	Hours: 'Hours',
	Harvest: 'Harvest',
	InventoryMovement: 'InventoryMovement',
	CurrentInventory: 'CurrentInventory',
	Sales: 'Sales',
} as const;

export type TLogType = keyof typeof LogTypes;

const _isLogType = isOneOf(
	...Object.values(LogTypes)
) as ExtendedValidator<TLogType>;

export const isLogType: typeof _isLogType = _isLogType;
