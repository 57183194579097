import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import InvMovementLogsTableHeaderValues from 'Models/inventory/inventoryMovementTableHeaders';
import Table from 'UI/display/Table';

const LogsTableHeader = () => {
	const { t } = useTranslation();

	return (
		<Table.Head>
			<Table.Row>
				{InvMovementLogsTableHeaderValues.map((header) => (
					<Table.Cell key={header}>
						{t(`logsPages.currInventoryLogsPage.tableHeaders.${header}`)}
					</Table.Cell>
				))}
			</Table.Row>
		</Table.Head>
	);
};

export default LogsTableHeader;
