import React, { memo } from 'react';

import UIKitChart from 'UI/display/Chart';

import useChartOptions, { TChartOptionsData } from './hooks/useChartOptions';
import { TFieldWeatherDaily } from './models/isFieldWeatherDaily';
import { TMilestone } from './models/isMilestone';

type TTemperatureChartProps = {
	fieldWeather: TFieldWeatherDaily[];
	fieldWeatherPredictions?: TFieldWeatherDaily[];
	milestones: TMilestone[];
	milestonesPredictions?: TMilestone[];
};

function TemperatureChart(props: TTemperatureChartProps) {
	const {
		fieldWeather,
		fieldWeatherPredictions,
		milestones,
		milestonesPredictions,
	} = props;

	const data: TChartOptionsData =
		fieldWeatherPredictions && milestonesPredictions
			? {
					showPredictions: true,
					fieldWeather,
					fieldWeatherPredictions,
					milestones,
					milestonesPredictions,
			  }
			: { showPredictions: false, fieldWeather, milestones };

	const chartOptions = useChartOptions(data);

	return <UIKitChart options={chartOptions} />;
}

export default memo(TemperatureChart);
