import z from 'zod';

const CustomerInputForm = z.object({
	name: z.string().nonempty(),
	address: z.string().optional().nullable(),
	city: z.string().optional().nullable(),
	zip: z.string().optional().nullable(),
});

export const isCustomerInput = (input: unknown): input is TCustomerInput =>
	CustomerInputForm.safeParse(input).success;

export type TCustomerInput = {
	name: string;
	address?: string | null;
	city?: string | null;
	zip?: string | null;
	imageName?: string | null;
};
