import React from 'react';

import TableSortLabel from '@mui/material/TableSortLabel';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TDirection } from 'Models/api/PageInput';
import PartnerLogsTableHeaderValues from 'Models/jobs/partnerJobLogsTableData';
import { TSortInput } from 'Models/request/isSortInput';
import Table from 'UI/display/Table';

import useManageHistory from 'Hooks/useManageHistory';

type TTableOrder = 'asc' | 'desc';

const SORTABLE_COLUMNS = ['completedDate', 'jobStatus'];

const parseOrder = (order: TDirection) => order.toLowerCase() as TTableOrder;

const LogsTableHeader = () => {
	const { t } = useTranslation();
	const { paramsFromURL: searchParams, historyPush } = useManageHistory();

	const pageInput = searchParams.pageInput;

	const sortHandler = (property, currentSortBy: TSortInput[]) => {
		const { field: orderBy, direction: order } = currentSortBy.filter(
			(sort) => sort.field === property
		)[0];

		const direction = orderBy === property && order === 'ASC' ? 'DESC' : 'ASC';

		const sortBy: TSortInput[] = currentSortBy.map((sortBy) => {
			return sortBy.field === property ? { field: orderBy, direction } : sortBy;
		});

		historyPush({
			...searchParams,
			pageInput: { ...pageInput, offset: 0, sortBy },
		});
	};

	const headers = PartnerLogsTableHeaderValues.map(({ id }) => {
		const headerLabel = t(`logsPages.jobLogsPage.tableHeaders.${id}`);

		if (SORTABLE_COLUMNS.includes(id)) {
			const { field, direction } = pageInput.sortBy.filter(
				(sort) => sort.field === id
			)[0];

			return (
				<Table.Cell
					key={id}
					sortDirection={field === id ? parseOrder(direction) : undefined}
				>
					<TableSortLabel
						active={field === id}
						direction={field === id ? parseOrder(direction) : 'asc'}
						onClick={() => sortHandler(id, pageInput.sortBy)}
					>
						{headerLabel}
					</TableSortLabel>
				</Table.Cell>
			);
		}

		return <Table.Cell key={id}>{headerLabel}</Table.Cell>;
	});

	return (
		<Table.Head>
			<Table.Row>{headers}</Table.Row>
		</Table.Head>
	);
};

export default LogsTableHeader;
