import React from 'react';

import Box from 'UI/layout/Box';

import LogsPageHeader from '../../../components/logs/LogsPageHeader';
import LogsPageLayout from '../../../components/logs/LogsPageLayout';
import { CreateReportButton } from '../CreateReport';
import Filters from '../common/components/Filters';
import TableTabs from '../common/components/TableTabs';
import ExportButtonValidator from './components/ExportButtonValidator';
import FilterModal from './components/FilterModal';
import JobDetailsModal from './components/JobDetailsModal';
import JobLogsTable from './components/JobLogsTable';
import Pagination from './components/Pagination';

const JobLogsPage = () => {
	return (
		<LogsPageLayout>
			<LogsPageHeader>
				<CreateReportButton />
			</LogsPageHeader>
			<TableTabs>
				<Box
					padding="0.5rem 1rem 1rem 1rem"
					display="flex"
					justifyContent="space-between"
				>
					<Box display="flex" gap="0.5rem">
						<FilterModal />
						<Filters />
					</Box>
					<ExportButtonValidator />
				</Box>
				<JobLogsTable />
				<Pagination />
			</TableTabs>
			<JobDetailsModal />
		</LogsPageLayout>
	);
};

export default JobLogsPage;
