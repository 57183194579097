import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import useLocation from 'Features/router/hooks/useLocation';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';
import List from 'UI/display/List';
import UsersIcon from 'UI/icons/Users';

import usePermission from 'Hooks/usePermission';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import { ICONS_FILL, getSettingsRoutes } from '../';

function SalesManagementSection(props: { locationID?: string }) {
	const { locationID } = props;
	const location = useLocation();
	const currentPath = location.pathname;
	const { t } = useTranslation();
	const history = useHistory();

	const hasSalesManagementPermission = usePermission(
		Permissions.FARM_SALES_PAGE_ACCESS
	);

	const hasValidSalesSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.SALE
	);

	const shouldShowSalesManagementSection =
		locationID && hasSalesManagementPermission && hasValidSalesSubscription;

	if (!shouldShowSalesManagementSection) {
		return null;
	}

	const paths = getSettingsRoutes({ locationID });

	return (
		<List.List
			isMenuVariant
			subheader={
				<List.Subheader>{t('common.sidebar.sales_management')}</List.Subheader>
			}
		>
			<List.Item disablePadding>
				<List.ItemButton
					isActive={currentPath === paths.customers}
					onClick={() => {
						history.push(paths.customers);
					}}
				>
					<List.ItemIcon>
						<UsersIcon fill={ICONS_FILL} />
					</List.ItemIcon>
					<List.ItemText primary={t('common.sidebar.customers')} />
				</List.ItemButton>
			</List.Item>
		</List.List>
	);
}

export default SalesManagementSection;
