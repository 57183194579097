import { THarvestBatch } from './isHarvestBatch';

function harvestBatchPercentageStorageVolume<TGenericHarvestBatch>(
	harvestBatch: TGenericHarvestBatch &
		Pick<THarvestBatch, 'totalVolume' | 'soldVolume' | 'wasteVolume'>
): number {
	const { totalVolume, soldVolume, wasteVolume } = harvestBatch;

	return Math.round(
		(100 * (totalVolume - soldVolume - wasteVolume)) / totalVolume
	);
}

export default harvestBatchPercentageStorageVolume;
