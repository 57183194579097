import React, { ReactElement } from 'react';

import MuiCell, { TableCellProps } from '@mui/material/TableCell';

import truncateString from 'Utils/truncateString';

const Cell = (props: TableCellProps): ReactElement => {
	const { children, ...MUIProps } = props;

	return (
		<MuiCell {...MUIProps}>
			{typeof children === 'string' ? truncateString(children, 34) : children}
		</MuiCell>
	);
};

export default Cell;
