const CURR_INVENTORY_LOGS_TABLE_HEADERS = [
	'productName',
	'purchaseDate',
	'amount',
	'unit',
	'totalValue',
] as const;

const CurrInventoryLogsTableHeaderValues = [
	...CURR_INVENTORY_LOGS_TABLE_HEADERS,
];

export default CurrInventoryLogsTableHeaderValues;
