import { gql, TypedDocumentNode } from '@apollo/client';
import { TAggregatedSales } from 'Models/sales/isAggregatedSales';
import { TAggregatedSalesGroupOption } from 'Models/sales/isAggregatedSalesGroupOption';

export type TRevenueData = Pick<
	TAggregatedSales,
	'totalRevenue' | 'currency' | 'date'
>;

type TRevenueChartDataResponse = {
	getAggregatedSales: TRevenueData[];
};

type TRevenueChartDataRequest = {
	locationId: string;
	query: {
		filterBy: {
			dateFrom: string;
			dateTo: string;
			varieties?: string[];
		};
		groupBy: TAggregatedSalesGroupOption;
	};
};
const GET_REVENUE_CHART_DATA: TypedDocumentNode<
	TRevenueChartDataResponse,
	TRevenueChartDataRequest
> = gql`
	query getAggregatedSales($locationId: ID!, $query: AggregatedSalesQuery) {
		getAggregatedSales(locationId: $locationId, query: $query) {
			totalRevenue
			currency
			date
		}
	}
`;

export default GET_REVENUE_CHART_DATA;
