import React, { ReactChild } from 'react';

import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import FarmableNavbar from '../FarmableNavbar';
import FarmsSidebar from '../FarmsSidebar';

type TProps = {
	children: ReactChild | ReactChild[];
};

const AppNavigation = (props: TProps) => (
	<Box display="flex">
		<Box borderRight={`1px solid ${FarmableColors.BLACK_12}`}>
			<FarmsSidebar />
		</Box>
		<Box display="flex" flexDirection="column" width="100%" height="100vh">
			<FarmableNavbar />
			{props.children}
		</Box>
	</Box>
);

export default AppNavigation;
