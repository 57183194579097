import format from 'date-fns/format';

import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const DEFAULT_FORMAT = 'yyyy-MM-dd HH:mm';

const jobStartedFullDateString = (
	job: Pick<TJob, 'startDate' | 'completedDate'>,
	dateFormat?: string
): string => {
	if (!job.startDate) {
		return '-';
	}
	const startDate = toLocalDate(job.startDate);

	if (!job.completedDate) {
		return format(startDate, dateFormat || DEFAULT_FORMAT);
	}

	const completedDate = toLocalDate(job.completedDate);

	const startDateInMs = Number(format(startDate, 'T'));
	const completedDateInMs = Number(format(completedDate, 'T'));
	const hasPositiveDatesDifference = completedDateInMs - startDateInMs >= 0;

	return hasPositiveDatesDifference
		? format(startDate, dateFormat || DEFAULT_FORMAT)
		: '-';
};

export default jobStartedFullDateString;
