import { gql, TypedDocumentNode } from '@apollo/client';

import { TPlannerJobDetailsResponse } from '../types';

type TGetPlannerJobDetailsInput = {
	plannerJobId: string;
};

const GET_PLANNER_JOB_DETAILS: TypedDocumentNode<
	{
		getPartnerPlannerJob: TPlannerJobDetailsResponse;
	},
	TGetPlannerJobDetailsInput
> = gql`
	query getPartnerPlannerJob($plannerJobId: ID!) {
		getPartnerPlannerJob(plannerJobId: $plannerJobId) {
			id
			variety {
				name
				category
			}
			jobDetails {
				jobType
				causes {
					id
				}
				treatments {
					id
					amount
					unit
					originalTreatmentId
					originalProduct {
						id
						name
					}
				}
				justifications {
					id
				}
			}
			dueDate
			comment
			sharedLocations {
				id
				name
			}
		}
	}
`;

export default GET_PLANNER_JOB_DETAILS;
