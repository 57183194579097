import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import NoLogsStatement from '../../common/components/NoLogsStatement';
import GET_PARTNER_HARVESTS_TABLE_DATA from '../api/getPartnerHarvestsTableData';
import HarvestLogsTableRow from './TableRow';

const TableBody = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const { data, loading, error } = useQuery(GET_PARTNER_HARVESTS_TABLE_DATA, {
		variables: {
			filterBy: {
				harvestFilters: {
					dateFrom: searchParams.filters.dateFrom,
					dateTo: searchParams.filters.dateTo,
					fieldCategories: searchParams.filters.fieldCategories,
					fieldVarieties: searchParams.filters.fieldVarieties,
					fieldIds: searchParams.filters.fieldIds,
				},
				locationIds: searchParams.filters.locations,
			},
			pageInput: searchParams.pageInput,
			hasPagination: true,
		},
	});

	if (loading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (!data?.getPartnerHarvests.harvests?.length) {
		return (
			<NoDataTableContainer>
				<NoLogsStatement logsType="Harvest" />
			</NoDataTableContainer>
		);
	}

	return (
		<Table.Body>
			{data.getPartnerHarvests.harvests.map((element) => (
				<HarvestLogsTableRow
					key={element.harvest.id}
					harvest={{ ...element.harvest, location: element.location }}
				/>
			))}
		</Table.Body>
	);
};

export default TableBody;
