import { VolumeUnits } from './index';
import roundToTwoDecimal from './roundToTwoDecimal';

const ONE_GALLON_IN_LITER = 3.785;
const ONE_MILLILITER_IN_LITER = 0.001;

function convertVolume(
	volume: number,
	from: VolumeUnits = VolumeUnits.LITER,
	to: VolumeUnits = VolumeUnits.GALLON,
	formatter = roundToTwoDecimal
): number {
	if (from === VolumeUnits.LITER && to === VolumeUnits.GALLON) {
		return formatter(volume / ONE_GALLON_IN_LITER);
	} else if (from === VolumeUnits.GALLON && to === VolumeUnits.LITER) {
		return formatter(volume * ONE_GALLON_IN_LITER);
	} else if (from === VolumeUnits.MILLILITER && to === VolumeUnits.LITER) {
		return formatter(volume * ONE_MILLILITER_IN_LITER);
	} else if (from === VolumeUnits.LITER && to === VolumeUnits.MILLILITER) {
		return formatter(volume / ONE_MILLILITER_IN_LITER);
	} else if (from === VolumeUnits.GALLON && to === VolumeUnits.MILLILITER) {
		const volumeInLiters = convertVolume(
			volume,
			VolumeUnits.GALLON,
			VolumeUnits.LITER
		);

		return convertVolume(
			volumeInLiters,
			VolumeUnits.LITER,
			VolumeUnits.MILLILITER
		);
	} else if (from === to) {
		return formatter(volume);
	}

	return formatter(volume * ONE_GALLON_IN_LITER);
}

export default convertVolume;
