import React from 'react';

import { URLFiltersType } from 'Models/history/isURLFilters';

import useManageHistory from 'Hooks/useManageHistory';

import Varieties from '../../../../components/filters/Varieties';

const VarietiesFilter = () => {
	const { paramsFromURL, historyPush } = useManageHistory();

	const onChange = (filters: URLFiltersType) => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				fieldVarieties: filters.fieldVarieties,
			},
		};

		historyPush(newSearchParams);
	};

	return paramsFromURL.filters ? (
		<Varieties
			filters={paramsFromURL.filters}
			updateFilters={onChange}
			paramName="fieldVarieties"
		/>
	) : null;
};

export default VarietiesFilter;
