import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import TextField from 'UI/inputs/TextField';

import getDebounce from 'Utils/getDebounce';

const debounced = getDebounce();

type TFieldNameProps = {
	name: string;
	setName: (name: string) => void;
};

const FieldName = (props: TFieldNameProps): ReactElement => {
	const { name, setName } = props;
	const [inputName, setInputName] = useState<string>(name);
	const { t } = useTranslation();

	function changeInputHandler(value) {
		const updateInput = () => setName(value);

		setInputName(value);
		debounced(updateInput);
	}

	const showError = !name.length;

	return (
		<TextField
			value={inputName}
			label={t('labels.fieldName')}
			onChange={changeInputHandler}
			helperText={
				showError ? `${t('errors.requiredField.message')}` : undefined
			}
			error={showError}
		/>
	);
};

export default FieldName;
