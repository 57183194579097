import z from 'zod';

import { ProductUnits, TConsumptionUnits, TProductUnits } from '../units';
import { SPRAYING, FERTILIZER, FERTIGATE } from './JobTypes';

export const SUPPORTED_JOB_TYPES = [SPRAYING, FERTILIZER] as const;

export type TSupportedJobs = typeof SUPPORTED_JOB_TYPES[number];

// validators are to be used only for user inputs,
// so we want TS first approach and add zod only where necessary
export const JobInputTreatmentSchema = z.object({
	amount: z.number().positive(),
	unit: z.nativeEnum(ProductUnits),
	originalTreatmentId: z.string(),
	// this property is used only on our end, and does not match
	// API's schema. We need to somehow display the product name
	// within the input.
	productName: z.string().optional(),
	id: z.string().optional(),
});

export type TJobTreatmentInput = {
	amount: number;
	consumptionAmount?: number;
	consumptionUnit?: TConsumptionUnits;
	originalTreatmentId: string;
	unit: TProductUnits;
};

export type TJobDetailsInput = {
	jobType: TSupportedJobs;
	causeIds?: string[] | null;
	treatments?: TJobTreatmentInput[] | null;
	justificationsIds?: string[] | null;
};

export type TJobInputTreatment = z.infer<typeof JobInputTreatmentSchema>;

export const SprayingJobInputSchema = z.object({
	jobType: z.literal(SPRAYING),
	treatments: JobInputTreatmentSchema.array().min(1),
	causeIds: z.string().array().min(1),
	justificationIds: z.string().array(),
});

export const SprayingSpanishJobInputSchema = z.object({
	jobType: z.literal(SPRAYING),
	treatments: JobInputTreatmentSchema.array().min(1),
	causeIds: z.string().array().min(1),
	justificationIds: z.string().array().min(1),
});

export const FertilizeOrFertigateJobInputSchema = z.object({
	jobType: z.literal(FERTIGATE).or(z.literal(FERTILIZER)),
	treatments: JobInputTreatmentSchema.array().min(1),
});

export const JobInputSchema = z.object({
	jobType: z.enum(SUPPORTED_JOB_TYPES),
	treatments: JobInputTreatmentSchema.array().min(1),
	causeIds: z.string().array(),
	justificationIds: z.string().array(),
});

export type TJobInput = z.infer<typeof JobInputSchema>;

export const isAmountInput = (amount: unknown) =>
	JobInputTreatmentSchema.shape.amount.safeParse(amount).success;
