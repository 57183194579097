import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TPartnerJobFilterInput } from 'Models/jobs/types/JobFilterInput';

import { TPartnerExportPDFResponse } from '../types';

type TGetPartnerJobsExportDataInput = {
	filterBy?: Partial<TPartnerJobFilterInput>;
	pageInput?: Partial<TPageInput>;
};

const GET_PARTNER_JOBS_EXPORT_DATA: TypedDocumentNode<
	{
		getPartnerJobs: TPartnerExportPDFResponse;
	},
	TGetPartnerJobsExportDataInput
> = gql`
	query getPartnerJobsExportData(
		$filterBy: PartnerJobFilterInput
		$pageInput: PageInput
	) {
		getPartnerJobs(filterBy: $filterBy, pageInput: $pageInput) {
			totalElements
			jobs {
				location {
					id
					name
					areaUnit
					address
					city
					country
					zip
				}
				job {
					id
					startDate
					completedDate
					jobType
					jobCauses {
						id
						name
					}
					treatments {
						id
						amount
						unit
						originalProduct {
							id
							name
							productSpec {
								fracNumber
								reEntryInterval
							}
							ingredients {
								name
								amount
								unit
								active
							}
						}
						originalTreatment {
							withholdingPeriod
							amount
							unit
						}
					}
					customJobType {
						id
						name
					}
					weather {
						temp
						windSpeed
						windDeg
						rain
					}
					driversWithEquipmentConfig {
						driver {
							id
							firstName
							lastName
						}
						equipment {
							id
							name
							equipmentType
							rate {
								value
								unit
							}
							tankSize {
								value
								unit
							}
						}
					}
					jobFields {
						id
						name
						areaSize
						plantHeight
						productiveArea
						varieties {
							name
							category
							percentage
						}
						fieldVarieties {
							name
							category
							percentage
						}
					}
				}
			}
		}
	}
`;

export default GET_PARTNER_JOBS_EXPORT_DATA;
