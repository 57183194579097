import { useMediaQuery } from '@mui/material';

import theme from '../theme';
import Breakpoints, { TBreakpoint } from '../theme/breakpoints';

const useBreakpoint = (breakpoint?: TBreakpoint) => {
	// returns boolean value: true if window width is below given value
	const matches = useMediaQuery(
		theme.breakpoints.down(breakpoint || Breakpoints.MOBILE)
	);

	return matches;
};
export default useBreakpoint;
