import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { THarvest } from 'Models/harvest/types/Harvest';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import useProfile from 'Hooks/useProfile';

import Comment from '../../../../../components/Comment';
import DetailModalSection from '../../../../../components/ModalSection';

type CommentsProps = {
	harvest: Pick<THarvest, 'author' | 'comment' | 'date'>;
};

const CommentSection = (props: CommentsProps): ReactElement | null => {
	const { harvest } = props;
	const { t } = useTranslation();
	const title = t(`logsPages.harvestLogsPage.detailsModal.sections.comment`);
	const { profile } = useProfile();
	const isYourHarvest = harvest.author.id === profile?.id;

	return harvest.comment?.length ? (
		<DetailModalSection title={title}>
			<Grid container spacing={1}>
				<Grid item>
					<Comment
						note={<Box mt="0.5rem">{harvest.comment}</Box>}
						account={harvest.author}
						avatarSize="sm"
						createdAt={harvest.date}
						isLast={true}
						isYourPost={isYourHarvest}
					></Comment>
				</Grid>
			</Grid>
		</DetailModalSection>
	) : null;
};

export default CommentSection;
