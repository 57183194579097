import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { SPRAYING } from 'Models/jobs/JobTypes';
import Chip from 'UI/display/Chip';
import Text from 'UI/display/Text';
import Tooltip from 'UI/display/Tooltip';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';
import _pull from 'lodash/pull';

import GET_JUSTIFICATIONS from '../../../api/getJustifications';
import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../../state';
import TooltipBase from './TooltipBase';

const AddSpainJustifications = () => {
	const { t } = useTranslation();
	const jobInput = useJobInputData();
	const status = useAddJobModalStatus();
	const { data, error } = useQuery(GET_JUSTIFICATIONS);

	if (error) {
		throw new ApolloError(error);
	}

	if (jobInput.jobDetails.jobType !== SPRAYING || !data) {
		return null;
	}

	const currentJustifications = jobInput.jobDetails.justificationIds || [];

	const tooltipContent = (
		<Box padding="0.25rem">
			<Text variant="body2">{t('plannerPage.justification.helperText')}</Text>
		</Box>
	);

	const justificationClickHandler = (justificationId: string) => {
		const justificationIds = currentJustifications.includes(justificationId)
			? _pull(currentJustifications, justificationId)
			: [...currentJustifications, justificationId];

		if ('justificationIds' in jobInput.jobDetails) {
			jobInput.jobDetails.justificationIds = justificationIds;
		}
		addJobModalState({
			status,
			jobInputData: { ...jobInput },
		});
	};

	return (
		<>
			<Box display="flex" marginTop="1.5rem" marginBottom="0.75rem">
				<Text variant="label">{t('labels.justification')}</Text>
				<Tooltip
					arrow
					placement="top"
					title={tooltipContent}
					arrowColor={FarmableColors.LIGHT_GREY}
					backgroundColor={FarmableColors.LIGHT_GREY}
					boxShadow
				>
					<TooltipBase />
				</Tooltip>
			</Box>
			<Box display="flex" flexWrap="wrap">
				{data.getJustifications.map((justification) => {
					const { id, name } = justification;
					const active = currentJustifications.includes(id);

					return (
						<Chip
							chipColor={active ? FarmableColors.ORANGE : undefined}
							key={id}
							label={name}
							onClick={() => justificationClickHandler(id)}
							sx={{
								marginTop: '0.5rem',
								marginRight: '0.5rem',
							}}
						/>
					);
				})}
			</Box>
		</>
	);
};

export default AddSpainJustifications;
