import { gql, TypedDocumentNode } from '@apollo/client';

import { TTeamsTableQueryResponse } from '../types';

export type TGetTeamsPageableResponse = {
	getTeamsPageable: TTeamsTableQueryResponse;
};

type TGetTeamsPageableInput = {
	locationID: string;
};

const GET_TEAMS_FOR_HARVEST_TABLE_DATA: TypedDocumentNode<
	TGetTeamsPageableResponse,
	TGetTeamsPageableInput
> = gql`
	query getTeamsPageable($locationID: ID!) {
		getTeamsPageable(locationId: $locationID) {
			teams {
				id
				name
			}
		}
	}
`;

export default GET_TEAMS_FOR_HARVEST_TABLE_DATA;
