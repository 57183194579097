import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Table from 'UI/display/Table';

import { PartnerSalesLogsTableBodyValues } from './PartnerSalesLogs.constants';

export const PartnerSalesLogsTableHeader = () => {
	const { t } = useTranslation();

	const headers = PartnerSalesLogsTableBodyValues.map((header) => {
		const headerLabel = t(`logsPages.jobLogsPage.tableHeaders.${header}`);

		return <Table.Cell key={header}>{headerLabel}</Table.Cell>;
	});

	return (
		<Table.Head>
			<Table.Row>{headers}</Table.Row>
		</Table.Head>
	);
};
