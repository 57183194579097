import { TAdvisorDashboardFieldsResponse } from '../../../../common/api/getAdvisorDashboardFields';
import { TGetDashboardFieldResponse } from '../api/getDashboardFieldData';

function parseResponseData(
	response: TAdvisorDashboardFieldsResponse | TGetDashboardFieldResponse,
	fieldId?: string
) {
	if (!fieldId) {
		return null;
	}
	if ('getField' in response) {
		return response.getField;
	}
	if ('getFieldsByUsersFieldCategories' in response) {
		return response.getFieldsByUsersFieldCategories.find(
			(field) => field.id === fieldId
		);
	}
	return null;
}

export default parseResponseData;
