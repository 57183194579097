import React, { ReactElement } from 'react';

import { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { getFieldName } from 'Models/fields/getFieldName';
import Permissions from 'Models/permissions/Permissions';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Grid from 'UI/layout/Grid';
import CardHeader from 'UI/surfaces/Card/CardHeader';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useFieldID from 'Hooks/useFieldID';
import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';

import GET_ADVISOR_DASHBOARD_FIELDS_QUERY from '../../../common/api/getAdvisorDashboardFields';
import CropSummary from '../../../common/components/CropSummary';
import GET_DASHBOARD_FIELD_QUERY from './api/getDashboardFieldData';
import getSummarySectionData from './utils/getSummarySectionData';
import parseResponseData from './utils/parseResponseData';

const _defaultCardHeaderPadding = '1.25rem 1.5rem';

const _cardHeaderStyles = {
	p: _defaultCardHeaderPadding,
	'& .MuiCardHeader-title': {
		fontSize: '1.25rem',
	},
};

const CropSummarySection = (): ReactElement | null => {
	const locationId = useLocationID();
	const location = useCurrentLocation();
	const fieldID = useFieldID();
	const { t } = useTranslation();

	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY
	);
	const { data, loading, error } = useQuery(
		hasPermissionsToRegularQuery
			? GET_DASHBOARD_FIELD_QUERY
			: GET_ADVISOR_DASHBOARD_FIELDS_QUERY,
		{
			variables: {
				id: fieldID || '',
				locationId,
			},
		}
	);

	if (data && location) {
		const fieldData = parseResponseData(data, fieldID);
		const summarySectionData = getSummarySectionData(
			fieldData,
			location.areaUnit,
			t
		);

		const fieldName = getFieldName(fieldData);

		return (
			<>
				<CardHeader title={fieldName} sx={_cardHeaderStyles} />
				<CropSummary varieties={summarySectionData} />
			</>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (loading || !location) {
		return (
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				sx={{ p: _defaultCardHeaderPadding }}
			>
				<LoadingSpinner width={64} height={64} />
			</Grid>
		);
	}

	return null;
};

export default CropSummarySection;
