import React, { ReactElement } from 'react';

import LoadingSpinner from '../../../icons/LoadingSpinner';
import Modal from '../dialog';

export type TSpinnerModal = {
	open: boolean;
};

const SpinnerModal = (props: TSpinnerModal): ReactElement => {
	return (
		<Modal.UncontrolledModal
			open={props.open}
			PaperProps={{
				style: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<LoadingSpinner />
		</Modal.UncontrolledModal>
	);
};

export default SpinnerModal;
