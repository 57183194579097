import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { URLFiltersType } from 'Models/history/isURLFilters';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';

import { GET_PARTNER_TEAMS_QUERY } from './api/getPartnerTeams';
import styles from './styles.module.scss';

type TPartnerTeamsProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const PartnerTeams = (props: TPartnerTeamsProps) => {
	const { updateFilters, filters } = props;

	const { data, loading, error } = useQuery(GET_PARTNER_TEAMS_QUERY);

	if (loading) {
		return <LoadingSpinner width={64} height={64} />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const selectedTeams = filters.teamId || [];

		const handleChange = (id: string) => {
			const isSelected = selectedTeams.includes(id);
			const newSelectedTeams = isSelected
				? selectedTeams.filter((i) => i !== id)
				: [...selectedTeams, id];

			updateFilters({
				...filters,
				teamId: newSelectedTeams,
			});
		};

		return (
			<Box display="flex" flexDirection="column">
				{data.getPartnerTeams.map((partnerTeam) => {
					const { location, team } = partnerTeam;
					const isSelected = selectedTeams.includes(team.id);

					const label = (
						<Box className={styles.labelContainer}>
							<Text variant="label" className={styles.caption}>
								{team.name}
							</Text>
							<Text variant="secondaryBody2" className={styles.farmName}>
								{`(${location.name || location.id})`}
							</Text>
						</Box>
					);

					return (
						<Checkbox.Controlled
							key={team.id}
							label={label}
							checked={isSelected}
							onChange={() => handleChange(team.id)}
							size="small"
							dense
							fullWidth
						/>
					);
				})}
			</Box>
		);
	}

	return null;
};

export default PartnerTeams;
