import numberToDisplayString from 'Utils/numberToDisplayString';

import { KILOGRAM, POUND } from '../units';
import { toDisplayString } from '../units/transpileUnits';
import { THarvestBatch } from './isHarvestBatch';

function harvestBatchWasteVolume<TGenericHarvest>(
	harvestBatch: TGenericHarvest & Pick<THarvestBatch, 'wasteVolume'>,
	isImperial?: boolean | null
): string {
	return `${numberToDisplayString(harvestBatch.wasteVolume)} ${toDisplayString(
		isImperial ? POUND : KILOGRAM
	)}`;
}

export default harvestBatchWasteVolume;
