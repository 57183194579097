import React from 'react';

import Redirect from 'Features/router/components/Redirect';
import Route from 'Features/router/components/Route';
import Switch from 'Features/router/components/Switch';
import Box from 'UI/layout/Box';

import getFeatureFlag from 'Utils/getFeatureFlag';

import FarmableRoute from '../../components/FarmableRoute';
import FarmSalesPage from '../../pages/FarmSalesPage';
import LandingPage from '../../pages/LandingPage';
import SelectFarmPage from '../../pages/SelectFarmPage';
import StorePage from '../../pages/StorePage';
import FarmDashboardPage from '../../pages/dashboard/FarmDashboardPage';
import FieldDashboardPage from '../../pages/dashboard/FieldDashboardPage';
import PartnerDashboardPage from '../../pages/dashboard/PartnerDashboardPage';
import PartnerFarmDashboardPage from '../../pages/dashboard/PartnerFarmDashboardPage';
import HarvestExportPage from '../../pages/exports/location/harvest';
import HoursExportPage from '../../pages/exports/location/hours';
import PartnerHarvestExportPage from '../../pages/exports/partner/harvest';
import PartnerHoursExportPage from '../../pages/exports/partner/hours';
import PartnerJobsExportPage from '../../pages/exports/partner/jobs';
import CurrentInventoryLogsPage from '../../pages/logs/CurrentInventoryLogsPage';
import EmployeesLogsPage from '../../pages/logs/EmployeesLogsPage';
import HarvestLogsPage from '../../pages/logs/HarvestLogsPage';
import HoursLogsPage from '../../pages/logs/HoursLogsPage';
import InventoryMovementLogsPage from '../../pages/logs/InventoryMovementLogsPage';
import JobLogsPage from '../../pages/logs/JobLogsPage';
import PartnerHarvestLogsPage from '../../pages/logs/PartnerHarvestLogsPage';
import PartnerHoursLogsPage from '../../pages/logs/PartnerHoursLogsPage';
import PartnerJobLogsPage from '../../pages/logs/PartnerJobsLogsPage';
import { PartnerHarvestSalesPage } from '../../pages/logs/PartnerSalesLogsPage';
import PartnerTableTabs from '../../pages/logs/common/components/PartnerTableTabs';
import NoFarmsAccessPage from '../../pages/no-access/NoFarmsAccessPage';
import CompanyWelcomePage from '../../pages/partnershipCompany/CompanyWelcomePage';
import CreateCompanyPage from '../../pages/partnershipCompany/CreateCompanyPage';
import PlannerPage from '../../pages/planner/PlannerPage';
import CustomersPage from '../../pages/profile/CustomersPage';
import FarmSettingsPage from '../../pages/profile/FarmSettingsPage';
import ManageMembersPage from '../../pages/profile/ManageMembersPage';
import FarmMembersPage from '../../pages/profile/MembersPage';
import PartnerCausesPage from '../../pages/profile/PartnerCausesPage';
import PartnerInfoPage from '../../pages/profile/PartnerInfoPage';
import PartnerTreatmentLibraryPage from '../../pages/profile/PartnerTreatmentLibraryPage';
import PartnerTreatmentsPage from '../../pages/profile/PartnerTreatmentsPage';
import PersonalInfoPage from '../../pages/profile/PersonalInfoPage';
import TeamsPage from '../../pages/profile/TeamsPage';
import TimeSettingsPage from '../../pages/profile/TimeSettingsPage';
import FieldImportsPage from '../../pages/profile/imports/FieldImportsPage';
import LogsPageHeader from '../logs/LogsPageHeader';
import LogsPageLayout from '../logs/LogsPageLayout';

export default function FarmableRouter() {
	const hasEnabledPartnerEmployeeLogs = getFeatureFlag('PARTNER_LOGS_EMPLOYEE');

	const hasEnabledPartnerTreatments = getFeatureFlag('PARTNER_TREATMENTS');

	const DummyPartnerLogsPage = () => (
		<LogsPageLayout>
			<LogsPageHeader>export button</LogsPageHeader>
			<PartnerTableTabs>dummy</PartnerTableTabs>
		</LogsPageLayout>
	);

	return (
		<Switch>
			<Route exact path="/" component={LandingPage} />
			<Route exact path="/no-access" component={NoFarmsAccessPage} />
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/jobs"
				component={JobLogsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/hours"
				component={HoursLogsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/harvest"
				component={HarvestLogsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/employees"
				component={EmployeesLogsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/movement"
				component={InventoryMovementLogsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/current"
				component={CurrentInventoryLogsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/harvest"
				component={HarvestLogsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/hours/export/:type"
				component={HoursExportPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/logs/harvest/export/:type"
				component={HarvestExportPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/store"
				component={StorePage}
			/>
			<Route exact path="/profile-settings" component={PersonalInfoPage} />
			<FarmableRoute
				exact
				path="/locations/:locationID/settings/members"
				component={FarmMembersPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/settings/info"
				component={FarmSettingsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/settings/time-settings"
				component={TimeSettingsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/settings/teams"
				component={TeamsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/settings/customers"
				component={CustomersPage}
			/>

			<FarmableRoute
				exact
				path="/locations/:locationID/settings/import/fields"
				component={FieldImportsPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/farm-sales"
				component={FarmSalesPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/dashboard/fields/:fieldID/"
				component={FieldDashboardPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/dashboard"
				component={PartnerDashboardPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/dashboard/partner-locations/:locationID"
				component={PartnerFarmDashboardPage}
			/>
			<FarmableRoute
				exact
				path="/locations/:locationID/dashboard"
				component={FarmDashboardPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/settings/info"
				component={PartnerInfoPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/settings/members"
				component={ManageMembersPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/settings/causes"
				component={PartnerCausesPage}
			/>
			{hasEnabledPartnerTreatments && (
				<FarmableRoute
					exact
					path="/partners/:companyID/settings/treatments"
					component={PartnerTreatmentsPage}
				/>
			)}
			{hasEnabledPartnerTreatments && (
				<FarmableRoute
					exact
					path="/partners/:companyID/settings/treatments/:libraryID"
					component={(props) => (
						<Box width="100%" height="100vh" overflow="auto">
							<PartnerTreatmentLibraryPage {...props} />
						</Box>
					)}
				/>
			)}
			<FarmableRoute
				exact
				path="/partners/:companyID/welcome"
				component={CompanyWelcomePage}
			/>
			<FarmableRoute
				exact
				path="/create-company"
				component={CreateCompanyPage}
			/>
			<Route exact path="/campaign/select" component={SelectFarmPage} />
			<FarmableRoute
				exact
				path="/mobile/:locationID/store"
				component={(props) => (
					<Box
						display="flex"
						flexDirection="column"
						width="100%"
						height="100vh"
						overflow="auto"
					>
						<StorePage {...props} />
					</Box>
				)}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/logs/jobs"
				component={PartnerJobLogsPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/logs/jobs/export/:type"
				component={PartnerJobsExportPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/logs/harvest"
				component={PartnerHarvestLogsPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/logs/sales"
				component={PartnerHarvestSalesPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/logs/harvest/export/:type"
				component={PartnerHarvestExportPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/logs/hours"
				component={PartnerHoursLogsPage}
			/>
			<FarmableRoute
				exact
				path="/partners/:companyID/logs/hours/export/:type"
				component={PartnerHoursExportPage}
			/>
			{hasEnabledPartnerEmployeeLogs && (
				<FarmableRoute
					exact
					path="/partners/:companyID/logs/employees"
					component={DummyPartnerLogsPage}
				/>
			)}
			<FarmableRoute
				exact
				path="/partners/:companyID/planner"
				component={PlannerPage}
			/>
			<Route path="/*">
				<Redirect to="/" />
			</Route>
		</Switch>
	);
}
