import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ErrorBoundary from '../../../components/ErrorBoundary';
import ProfilePageTemplate from '../common/components/ProfilePageTemplate';
import PageBody from './components/PageBody';

function PartnerTreatmentsPage() {
	const { t } = useTranslation();

	return (
		<ProfilePageTemplate
			header={t('treatmentLibrary.title')}
			subheader={t('treatmentLibrary.subtitle')}
		>
			<ErrorBoundary>
				<PageBody />
			</ErrorBoundary>
		</ProfilePageTemplate>
	);
}

export default PartnerTreatmentsPage;
