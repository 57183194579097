import React, { ReactElement, ReactNode } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TBasicWeather, isBasicWeather } from 'Models/weather';
import getCompassDirection from 'Models/weather/getCompassDirection';
import getPrecipitationString from 'Models/weather/getPrecipitationString';
import getTemperature from 'Models/weather/getTemperature';
import getWindSpeedString from 'Models/weather/getWindSpeedString';
import ArrowUpwardIcon from 'UI/icons/ArrowUpward';
import PrecipitationIcon from 'UI/icons/Precipitation';
import TemperatureIcon from 'UI/icons/Temperature';
import WindIcon from 'UI/icons/Wind';
import Grid from 'UI/layout/Grid';

import useImperialUnits from 'Hooks/useImperialUnits';

import DetailModalSection from '../../../../../components/ModalSection';
import styles from '../styles.module.scss';

type TWeatherProps = {
	job: {
		weather?: TBasicWeather | null;
	};
};

type TWeatherTileProps = {
	icon: ReactElement;
	measurement?: ReactNode;
	subtitle: string;
};

function WeatherTile(props: TWeatherTileProps): ReactElement {
	const { icon, measurement, subtitle } = props;

	return (
		<Grid container className={styles.weatherTile}>
			<Grid item>{icon}</Grid>
			<Grid container item direction="column" md>
				<Grid item className={styles.measurement}>
					{measurement}
				</Grid>
				<Grid item className={styles.subtitle}>
					{subtitle}
				</Grid>
			</Grid>
		</Grid>
	);
}

function Weather(props: TWeatherProps): ReactElement | null {
	const { t } = useTranslation();
	const isImperial = useImperialUnits();

	const prefix = 'logsPages.jobLogsPage.detailsModal.';

	const weather = isBasicWeather(props.job.weather) ? props.job.weather : null;

	const { temp, windSpeed, windDeg, rain } = weather || {};

	const temperature = temp ? getTemperature(temp, isImperial) : null;
	const windCompassDirection =
		typeof windDeg === 'number' ? getCompassDirection(windDeg) : null;
	const windArrowDirection = typeof windDeg === 'number' ? windDeg - 180 : null;

	const windArrow = windArrowDirection ? (
		<ArrowUpwardIcon
			style={{ transform: `rotate(${windArrowDirection}deg)` }}
			width={18}
			height={18}
		/>
	) : null;

	const tempMeasurement = temperature ? (
		<span style={{ color: temperature.tempColor }}>
			{temperature.tempValue}
		</span>
	) : (
		t('labels.notApplicable')
	);

	const windMeasurement = (
		<span>
			{getWindSpeedString(windSpeed)} {windArrow}
		</span>
	);
	const rainMeasurement = <span>{getPrecipitationString(rain)}</span>;

	const windSubtitle = windCompassDirection
		? `${t(`${prefix}props.wind`)} (${windCompassDirection})`
		: t(`${prefix}props.wind`);

	return (
		<DetailModalSection title={t(`${prefix}sections.weather`)}>
			<div className={styles.weather}>
				<WeatherTile
					icon={<TemperatureIcon />}
					measurement={tempMeasurement}
					subtitle={t(`${prefix}props.temperature`)}
				/>
				<WeatherTile
					icon={<WindIcon />}
					measurement={windMeasurement}
					subtitle={windSubtitle}
				/>
				<WeatherTile
					icon={<PrecipitationIcon />}
					measurement={rainMeasurement}
					subtitle={t(`${prefix}props.precipitation`)}
				/>
			</div>
		</DetailModalSection>
	);
}

export default Weather;
