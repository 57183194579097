import { z } from 'zod';

export const TeamMemberInputSchema = z.object({
	id: z.string().optional(),
	fullName: z.string().nonempty(),
	customId: z.string().optional(),
	position: z.number().optional(),
});

export type TTeamMemberInput = {
	fullName: string;
	id?: string | undefined;
	customId?: string | undefined;
	position?: number | undefined;
};

export const isTeamMemberInput = (input: unknown): input is TTeamMemberInput =>
	TeamMemberInputSchema.safeParse(input).success;
