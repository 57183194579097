import React, { ReactElement, ReactNode, useState, MouseEvent } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTeam } from 'Models/teams/isTeam';
import Divider from 'UI/display/Divider';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import MoreIcon from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Menu from 'UI/navigation/Menu';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';
import CardHeader from 'UI/surfaces/Card/CardHeader';
import { makeStyles } from 'UI/utils/makeStyles';

import DeleteTeamModal from '../DeleteTeamModal';

export type TCardProps = {
	team: TTeam;
	onEdit: () => void;
	children: ReactNode;
};

const useStyles = makeStyles({
	contentRoot: {
		padding: 0,
		'&:last-child': {
			paddingBottom: '1rem',
		},
	},
	listRoot: {
		width: '100%',
		padding: 0,
	},
	cardHeaderRoot: {
		padding: '1.25rem 1.5rem',
	},
	dividerRoot: {
		margin: '0.5rem 0',
	},
});

function TeamCard(props: TCardProps): ReactElement {
	const { team, onEdit, children } = props;
	const { listRoot, contentRoot, cardHeaderRoot, dividerRoot } = useStyles();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation();

	const open = Boolean(anchorEl);

	function handleClick(event: MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	function handleEdit() {
		onEdit();
		handleClose();
	}

	const settings = (
		<>
			<IconButton aria-label="Settings" onClick={handleClick} size="medium">
				<MoreIcon />
			</IconButton>
			<Menu.Menu
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<Menu.Item onClick={handleEdit}>
					<Text variant="body2">{t('buttons.editTeam')}</Text>
				</Menu.Item>
				<Divider className={dividerRoot} />
				<DeleteTeamModal teamId={team.id} />
			</Menu.Menu>
		</>
	);

	return (
		<Card>
			<CardHeader
				title={<Text variant="h4">{team.name}</Text>}
				action={settings}
				className={cardHeaderRoot}
			/>
			<CardContent className={contentRoot}>
				<List.List className={listRoot}>{children}</List.List>
			</CardContent>
		</Card>
	);
}

export default TeamCard;
