import { TSalesVolumeUnits, SalesVolumeUnits } from '.';
import roundToTwoDecimal from './roundToTwoDecimal';

const METRIC_MULTIPLIER = 1000;
const ONE_KILOGRAM_IN_POUND = 2.20462;

function convertWeight(
	weight: number,
	from: TSalesVolumeUnits = SalesVolumeUnits.KILOGRAM,
	to: TSalesVolumeUnits = SalesVolumeUnits.TON,
	formatter = roundToTwoDecimal
): number {
	if (from === SalesVolumeUnits.KILOGRAM && to === SalesVolumeUnits.TON) {
		return formatter(weight / METRIC_MULTIPLIER);
	} else if (
		from === SalesVolumeUnits.KILOGRAM &&
		to === SalesVolumeUnits.THOUSANDS_OF_POUNDS
	) {
		const pounds = weight * ONE_KILOGRAM_IN_POUND;
		return formatter(pounds / 1000);
	} else if (
		from === SalesVolumeUnits.TON &&
		to === SalesVolumeUnits.KILOGRAM
	) {
		return formatter(weight * METRIC_MULTIPLIER);
	} else if (
		from === SalesVolumeUnits.TON &&
		to === SalesVolumeUnits.THOUSANDS_OF_POUNDS
	) {
		const kilograms = convertWeight(
			weight,
			SalesVolumeUnits.TON,
			SalesVolumeUnits.KILOGRAM
		);

		return convertWeight(
			kilograms,
			SalesVolumeUnits.KILOGRAM,
			SalesVolumeUnits.THOUSANDS_OF_POUNDS
		);
	} else if (
		from === SalesVolumeUnits.THOUSANDS_OF_POUNDS &&
		to === SalesVolumeUnits.KILOGRAM
	) {
		const pounds = weight * 1000;
		return formatter(pounds / ONE_KILOGRAM_IN_POUND);
	} else if (
		from === SalesVolumeUnits.THOUSANDS_OF_POUNDS &&
		to === SalesVolumeUnits.TON
	) {
		const kilograms = convertWeight(
			weight,
			SalesVolumeUnits.THOUSANDS_OF_POUNDS,
			SalesVolumeUnits.KILOGRAM
		);

		return convertWeight(
			kilograms,
			SalesVolumeUnits.KILOGRAM,
			SalesVolumeUnits.TON
		);
	} else if (from === to) {
		return formatter(weight);
	}

	return convertWeight(weight, from, to);
}

export default convertWeight;
