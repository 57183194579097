import WithSymbol from '../units/WithSymbol';
import { TLocation } from './isLocation';

export default function (location: Pick<TLocation, 'areaUnit'>): string {
	if (location.areaUnit) {
		return WithSymbol(location.areaUnit);
	}

	return '-';
}
