import React, { Fragment, ReactElement } from 'react';

import { TTeamMember } from 'Models/timesheet/isTeamMember';
import Avatar from 'UI/display/Avatar';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import Status from './status';

export type TMemberProps = {
	teamMember: TTeamMember;
};

const useStyles = makeStyles({
	itemRoot: {
		padding: '0.5rem 1.5rem',
		'&:active': {
			background: FarmableColors.BLACK_4,
			borderRadius: '0.5em',
		},
	},
	textItemRoot: {
		margin: 0,
	},
	textItemPrimary: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& > p': {
			lineHeight: 1.25,
		},
	},
	textItemSecondary: {
		lineHeight: 1.43,
	},
});

function Member(props: TMemberProps): ReactElement {
	const {
		teamMember: { fullName, customId, hasUnclosedTimesheets },
	} = props;
	const { itemRoot, textItemRoot, textItemPrimary, textItemSecondary } =
		useStyles();

	const avatar = fullName
		.split(' ')
		.map((segment) => segment.charAt(0))
		.join('');
	const primary = (
		<Fragment>
			<Text>{fullName}</Text>
			<Status clockedIn={hasUnclosedTimesheets} />
		</Fragment>
	);

	return (
		<List.Item className={itemRoot}>
			<List.ItemAvatar>
				<Avatar>{avatar}</Avatar>
			</List.ItemAvatar>
			<List.ItemText
				primary={primary}
				secondary={customId}
				classes={{
					root: textItemRoot,
					primary: textItemPrimary,
					secondary: textItemSecondary,
				}}
			/>
		</List.Item>
	);
}

export default Member;
