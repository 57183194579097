import React from 'react';

import LogsTableHeaderValues from 'Models/jobs/jobLogsTableData';
import Table from 'UI/display/Table';

import { TTimesheetTableData } from '../../../types';
import getHoursLogsRowData from '../../../utils/getHoursLogsRowData';

type THoursLogsTableRowProps = {
	timesheet: TTimesheetTableData;
};

const TableRow = (props: THoursLogsTableRowProps) => {
	const { timesheet } = props;

	return (
		<Table.Row hover>
			{getHoursLogsRowData(timesheet).map((cell, index) => (
				<Table.Cell key={`cell-${LogsTableHeaderValues[index] || index}`}>
					{cell}
				</Table.Cell>
			))}
		</Table.Row>
	);
};

export default TableRow;
