import URI from 'urijs';

export default function defaultRedirectURI() {
	const url = URI(window.location.href);

	const origin = url.origin();
	const destinationPath = url.path();
	const params = encodeURIComponent(url.query());

	if (destinationPath) {
		const defaultURI = `${origin}/auth-callback?destination=${destinationPath}`;

		return params ? defaultURI + '?' + params : defaultURI;
	}

	return origin + '/auth-callback';
}
