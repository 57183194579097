import React, { ReactNode } from 'react';

import Box from 'UI/layout/Box';

type TNavigationProps = {
	children: ReactNode;
};

const Navigation = (props: TNavigationProps) => {
	return <Box paddingTop="1.5rem">{props.children}</Box>;
};

export default Navigation;
