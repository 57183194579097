import { THoursExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import teamName from 'Models/teams/teamName';
import timesheetCommentString from 'Models/timesheet/timesheetCommentString';
import timesheetDuration from 'Models/timesheet/timesheetDuration';
import timesheetEmployeeId from 'Models/timesheet/timesheetEmployeeId';
import timesheetEndTimeString from 'Models/timesheet/timesheetEndTimeString';
import timesheetFieldsString from 'Models/timesheet/timesheetFieldsString';
import timesheetMember from 'Models/timesheet/timesheetMember';
import timesheetStandardDateString from 'Models/timesheet/timesheetStandardDateString';
import timesheetStartTimeString from 'Models/timesheet/timesheetStartTimeString';
import timesheetTaskString from 'Models/timesheet/timesheetTaskString';

import { TTimesheetExportData } from '../types';

function getHoursLogsRowDataForExport(
	timesheet: TTimesheetExportData,
	dictionary: THoursExportHeadersDictionary
) {
	const {
		date,
		start,
		stop,
		duration,
		team,
		who,
		employeeId,
		task,
		fields,
		comments,
	} = dictionary;

	return {
		[date]: timesheetStandardDateString(timesheet),
		[start]: timesheetStartTimeString(timesheet),
		[stop]: timesheetEndTimeString(timesheet),
		[duration]: timesheetDuration(timesheet),
		[team]: teamName(timesheet.team),
		[who]: timesheetMember(timesheet),
		[employeeId]: timesheetEmployeeId(timesheet),
		[task]: timesheetTaskString(timesheet),
		[fields]: timesheetFieldsString(timesheet),
		[comments]: timesheetCommentString(timesheet),
	};
}

export default getHoursLogsRowDataForExport;
