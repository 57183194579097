// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3KhtYQeszTaMcLkovb7Byh {\n  height: 100%;\n  font-weight: bold;\n  font-size: .875rem;\n  color: rgba(0,0,0,.6);\n}\n\n._3KhtYQeszTaMcLkovb7Byh:hover {\n  background-color: rgba(0,0,0,.03);\n  cursor: pointer;\n}\n\n._2TVN-wGP2kZ1XhXNPCBmYH {\n  color: rgba(0,0,0,.87) !important;\n  position: relative;\n}\n\n._2TVN-wGP2kZ1XhXNPCBmYH:before {\n  content: \"\";\n  position: absolute;\n  height: 3px;\n  width: calc(100% - 3rem);\n  bottom: -1px;\n  left: 1.5rem;\n  background-color: #d83e14;\n}\n\n", ""]);
// Exports
exports.locals = {
	"navbarLink": "_3KhtYQeszTaMcLkovb7Byh",
	"navbarLinkActive": "_2TVN-wGP2kZ1XhXNPCBmYH"
};
module.exports = exports;
