// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1P8qO7D757GDqiTladswrm {\n  padding-bottom: 2rem;\n}\n\n._2uKq4MGhVgz3j_cvVkr1-O {\n  padding-top: 1rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"timeSettingsPageHeader": "_1P8qO7D757GDqiTladswrm",
	"lunchTimeInputsWrapper": "_2uKq4MGhVgz3j_cvVkr1-O"
};
module.exports = exports;
