import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TVolumeByYearChartData } from 'Models/harvestBatch/isVolumeByYearChartData';
import Chart, { TChartRef } from 'UI/display/Chart';
import { FarmableTextColors } from 'UI/theme/Colors';

import useImperialUnits from 'Hooks/useImperialUnits';
import useManageHistory from 'Hooks/useManageHistory';

import GET_VOLUME_BY_SEASON_CHART_DATA from './api/getVolumeBySeasonChartData';
import chartConfig from './chartConfig';

// where 24 is a point width and 16 is a padding - from mockup
const CATEGORY_WIDTH = 40;
// where 56 is a title height and 24 is a margin bottom - from mockup
const RESERVED_SPACE = 80;

type TChartData = {
	y: number;
	name: string;
};

const getChartData = function (data: TVolumeByYearChartData[]): TChartData[] {
	return data.map((d) => ({
		y: d.totalVolume,
		name: d.season,
	}));
};

const VolumeByYearChart = (): ReactElement => {
	const chartComponent = useRef<TChartRef>(null);
	const { t } = useTranslation();
	const isImperial = useImperialUnits();

	const [chartOptions, setChartOptions] = useState(chartConfig(isImperial, t));

	const { paramsFromURL } = useManageHistory();
	const { data, loading, error } = useQuery(GET_VOLUME_BY_SEASON_CHART_DATA, {
		variables: {
			locationId: paramsFromURL.locationID,
			query: {
				filterBy: {
					varieties: paramsFromURL.filters.fieldVarieties,
				},
				groupBy: 'SEASON',
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	const chart = chartComponent?.current?.chart;

	useEffect(() => {
		if (chart) {
			if (data) {
				const chartData = getChartData(data.getAggregatedHarvestBatches);
				const noData = !chartData.length;

				chart.hideLoading();
				chart.reflow();

				setChartOptions({
					series: [
						{
							color: FarmableTextColors.ORANGE,
							type: 'bar',
							data: chartData,
						},
					],
					chart: {
						scrollablePlotArea: noData
							? undefined
							: {
									minHeight: chartData.length * CATEGORY_WIDTH + RESERVED_SPACE,
									opacity: 1,
							  },
					},
					xAxis: {
						visible: !noData,
					},
				});
			} else if (loading) {
				chart.hideNoData();
				chart.showLoading();

				setChartOptions({
					series: [],
				});
			}
		}
	}, [chart, data, loading]);

	return (
		<Chart
			options={chartOptions}
			ref={chartComponent}
			containerProps={{
				style: {
					padding: '1.5rem',
					height: '22rem',
				},
			}}
		/>
	);
};

export default VolumeByYearChart;
