import React from 'react';

import ErrorBoundary from '../../../components/ErrorBoundary';
import PageAccessValidator from '../common/components/PageAccessValidator';
import PageBody from './components/PageBody';

function FieldDashboardPage() {
	return (
		<ErrorBoundary>
			<PageAccessValidator>
				<PageBody />
			</PageAccessValidator>
		</ErrorBoundary>
	);
}

export default FieldDashboardPage;
