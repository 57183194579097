import React, { ReactElement } from 'react';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MUIRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

type TRadioGroupControlledProps = {
	valueChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	label?: string;
} & RadioGroupProps;

const RadioGroup = (props: TRadioGroupControlledProps): ReactElement => {
	const { className, valueChangeHandler, label, value, children, ...MUIProps } =
		props;

	function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
		if (valueChangeHandler) {
			valueChangeHandler(event);
		}
	}

	return (
		<FormControl>
			<FormLabel>{label}</FormLabel>
			<MUIRadioGroup
				className={className}
				onChange={handleChange}
				value={value}
				{...MUIProps}
			>
				{children}
			</MUIRadioGroup>
		</FormControl>
	);
};

export default RadioGroup;
