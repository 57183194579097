import teamName from 'Models/teams/teamName';
// import { TTimesheetSettings } from 'Packages/models/timesheet/isTimesheetSettings';
import timesheetDateString from 'Models/timesheet/timesheetDateString';
import timesheetDurationString from 'Models/timesheet/timesheetDurationString';
import timesheetEndTimeString from 'Models/timesheet/timesheetEndTimeString';
import timesheetFieldsNumber from 'Models/timesheet/timesheetFieldsNumber';
import timesheetMember from 'Models/timesheet/timesheetMember';
import timesheetStartTimeString from 'Models/timesheet/timesheetStartTimeString';
import timesheetTaskString from 'Models/timesheet/timesheetTaskString';

import { TPartnerTimesheetTableData } from '../api/getPartnerHoursTableData';

// TODO: fix the time duration calculations
function getPartnerHoursLogsRowData(
	timesheet: TPartnerTimesheetTableData
	// timesheetSetting?: TTimesheetSettings | null
) {
	return Object.values({
		date: timesheetDateString(timesheet),
		location: timesheet.location.name,
		start: timesheetStartTimeString(timesheet),
		stop: timesheetEndTimeString(timesheet),
		// timesheetSetting is passing here as a second parameter in calc
		duration: timesheetDurationString(timesheet),
		team: teamName(timesheet.team),
		who: timesheetMember(timesheet),
		task: timesheetTaskString(timesheet),
		fields: timesheetFieldsNumber(timesheet),
	});
}

export default getPartnerHoursLogsRowData;
