import React, { ReactElement } from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { TSvgIconProps } from '..';

function NoDataIcon(props: TSvgIconProps): ReactElement {
	return (
		<SvgIcon viewBox="0 0 128 128" {...props}>
			<g fill="none" fillRule="evenodd">
				<path
					d="M0 0H128V128H0z"
					transform="translate(-204 -225) translate(64 56) translate(24 169) translate(116)"
				/>
				<g
					fillRule="nonzero"
					transform="translate(-204 -225) translate(64 56) translate(24 169) translate(116) translate(7 8)"
				>
					<path
						fill="#FFD4C8"
						d="M72.331 25.012h-30.7c-1.457.002-2.638 1.182-2.64 2.639v68.233l-.351.107-7.532 2.307c-.743.226-1.53-.192-1.757-.935L6.948 24.187c-.227-.743.192-1.53.935-1.758l11.605-3.553L53.134 8.577 64.74 5.023c.357-.11.742-.073 1.072.102.33.175.576.474.686.83l5.726 18.705.107.352z"
					/>
					<path
						fill="#601400"
						d="M79.03 24.66L72.13 2.117c-.232-.759-.756-1.394-1.456-1.766-.7-.372-1.52-.45-2.279-.218L52.077 5.127l-33.644 10.3-16.317 4.997C.537 20.909-.35 22.58.13 24.159l23.588 77.043c.386 1.257 1.546 2.115 2.861 2.116.296 0 .591-.044.874-.131l11.186-3.424.352-.11v-.367l-.352.108-11.29 3.456c-1.393.425-2.867-.358-3.295-1.75L.47 24.055c-.206-.669-.137-1.392.192-2.01.328-.619.889-1.08 1.559-1.285l16.317-4.996 33.644-10.3L68.498.47c.251-.077.513-.116.776-.116 1.158.002 2.18.759 2.519 1.866l6.87 22.441.109.352h.366l-.107-.352z"
					/>
					<path
						fill="#D83E14"
						d="M21.583 22.487c-.696 0-1.31-.455-1.515-1.12l-2.266-7.402c-.123-.401-.082-.835.115-1.206.197-.37.534-.648.935-.771l30.953-9.476c.836-.255 1.72.215 1.977 1.05l2.266 7.402c.255.836-.215 1.72-1.05 1.977l-30.953 9.476c-.15.046-.305.07-.462.07z"
					/>
					<circle cx="33.453" cy="4.39" r="3.519" fill="#D83E14" />
					<circle cx="33.453" cy="4.39" r="2.228" fill="#FFF" />
					<path
						fill="#FFD4C8"
						d="M106.02 102.42H46.555c-.825-.001-1.494-.67-1.495-1.496v-71.25c.001-.825.67-1.494 1.495-1.495h59.463c.826 0 1.495.67 1.496 1.495v71.25c-.001.826-.67 1.494-1.496 1.495z"
					/>
					<path
						fill="#601400"
						d="M78.663 24.66H41.63c-1.65.003-2.988 1.34-2.99 2.99v71.744l.352-.108V27.651c.001-1.457 1.182-2.637 2.638-2.639h37.142l-.11-.352zm32.282 0H41.63c-1.65.003-2.988 1.34-2.99 2.99v80.575c.002 1.65 1.34 2.988 2.99 2.99h69.315c1.651-.002 2.989-1.34 2.991-2.99V27.651c-.002-1.65-1.34-2.988-2.99-2.99zm2.64 83.565c-.003 1.457-1.183 2.637-2.64 2.639H41.63c-1.456-.002-2.637-1.182-2.638-2.639V27.651c.001-1.457 1.182-2.637 2.638-2.639h69.315c1.457.002 2.637 1.182 2.64 2.639v80.574z"
					/>
					<path
						fill="#D83E14"
						d="M92.473 32.4h-32.37c-.874 0-1.583-.708-1.584-1.582v-7.741c.001-.874.71-1.582 1.584-1.583h32.37c.874 0 1.582.709 1.583 1.583v7.74c0 .875-.709 1.583-1.583 1.584z"
					/>
					<circle cx="76.288" cy="18.503" r="3.519" fill="#D83E14" />
					<circle cx="76.288" cy="18.503" r="2.143" fill="#FFF" />
				</g>
			</g>
		</SvgIcon>
	);
}

export default NoDataIcon;
