import React, { ReactElement, ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import _Masonry, { MasonryProps } from 'react-masonry-css';

const DEFAULT_BREAKPOINT_COLS = 2;

const useStyles = makeStyles({
	masonryGrid: {
		marginTop: '1.5rem',
		display: 'flex',
		marginLeft: '-1.5rem',
		width: 'auto',
	},
	masonryGridColumn: {
		paddingLeft: '1.5rem',
		backgroundClip: 'padding-box',
		'& > div': {
			marginBottom: '1.5rem',
		},
	},
});

type TMasonryProps = {
	children: ReactNode;
} & Partial<MasonryProps>;

const Masonry = (props: TMasonryProps): ReactElement => {
	const { breakpointCols, className, columnClassName, children } = props;

	const styles = useStyles();

	return (
		<_Masonry
			breakpointCols={breakpointCols || DEFAULT_BREAKPOINT_COLS}
			className={className || styles.masonryGrid}
			columnClassName={columnClassName || styles.masonryGridColumn}
		>
			{children}
		</_Masonry>
	);
};

export default Masonry;
