import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import CardActions from 'UI/surfaces/Card/CardActions';
import CardContent from 'UI/surfaces/Card/CardContent';
import { FarmableColors } from 'UI/theme/Colors';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import containsNumber from 'Utils/containsNumber';

import UploadImage, { TSelectedFile } from '../../../../components/UploadImage';
import LoadingContent from '../../common/components/LoadingContent';
import UPDATE_PARTNER_COMPANY_MUTATION from '../api/updatePartnerCompany';
import UPLOAD_PARTNER_IMAGE_MUTATION from '../api/uploadPartnerCompanyImage';

function PartnerDetailsCard() {
	const { t } = useTranslation();
	const { company } = usePartnerCompany();

	// input state
	const [isInputError, setInputError] = useState(false);
	const [name, setName] = useState(company?.name);
	const [address, setAddress] = useState(company?.address);
	const [zip, setZip] = useState(company?.zip);
	const [city, setCity] = useState(company?.city);
	const [selectedImages, setSelectedImages] = useState<TSelectedFile[]>([]);

	const [updateInfo, updateInfoTask] = useMutation(
		UPDATE_PARTNER_COMPANY_MUTATION
	);
	const [uploadImage, uploadImageTask] = useMutation(
		UPLOAD_PARTNER_IMAGE_MUTATION
	);

	const onSaveProfile = () => {
		if (name && !containsNumber(city)) {
			const input = {
				name,
				city,
				zip,
				address,
			};

			void updateInfo({
				variables: {
					input: {
						...input,
						address: input.address || undefined,
						city: input.city || undefined,
						zip: input.zip || undefined,
					},
				},
				refetchQueries: ['partnershipCompany'],
			});

			if (selectedImages.length) {
				void uploadImage({
					variables: { file: selectedImages[0].file },
					refetchQueries: ['partnershipCompany'],
				}).then(() => setSelectedImages([]));
			}

			setInputError(false);
		} else {
			setInputError(true);
		}
	};

	const isSaving = updateInfoTask.loading || uploadImageTask.loading;
	useSnackbarNotifications({
		mutationResult: updateInfoTask,
		messageFailure: t('errors.updatePartnerInfoFailed'),
		messageSuccess: t('errors.updatePartnerInfoSuccess'),
	});

	useSnackbarNotifications({
		mutationResult: uploadImageTask,
		messageFailure: t('errors.updatePartnerImageFailed'),
		messageSuccess: t('errors.updatePartnerImageSuccess'),
	});

	useEffect(() => {
		if (company) {
			setName(company.name);
			setAddress(company.address);
			setZip(company.zip);
			setCity(company.city);
			setZip(company.zip);
		}
	}, [company]);

	if (!company) {
		return <LoadingContent />;
	}

	const saveButton = isSaving ? (
		<>
			<LoadingIcon />
			<Button disabled>{t('buttons.saveChanges')}</Button>
		</>
	) : (
		<Button disableElevation onClick={onSaveProfile}>
			{t('buttons.saveChanges')}
		</Button>
	);

	const uploadImageButton = (
		<UploadImage
			selectedImages={selectedImages}
			selectedImagesControl={setSelectedImages}
			maxAmount={1}
			ButtonProps={{
				sx: { '&:hover': { backgroundColor: 'transparent' } },
			}}
		>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Avatar
					src={company.image?.path}
					size="xxl"
					color={FarmableColors.BLACK_38}
					alt={name || undefined}
				/>
				<Text
					variant="body2"
					noWrap
					sx={{
						textDecoration: 'underline',
						cursor: 'pointer',
					}}
					paddingTop="0.5rem"
				>
					{t('labels.updateImage')}
				</Text>
			</Box>
		</UploadImage>
	);

	const nameInput = (
		<TextField
			name="company name"
			required
			disabled={isSaving}
			value={name}
			label={t('labels.name')}
			onChange={setName}
			helperText={
				isInputError && !name
					? `${t('errors.requiredField.message')}`
					: undefined
			}
			error={isInputError && !name}
		/>
	);

	const countryInput = (
		<TextField
			name="country"
			disabled
			value={company.country}
			label={t('labels.country')}
		/>
	);

	const addressInput = (
		<TextField
			name="address"
			disabled={isSaving}
			value={address || undefined}
			label={t('labels.address')}
			onChange={setAddress}
		/>
	);

	const zipInput = (
		<TextField
			name="zip"
			disabled={isSaving}
			value={zip || undefined}
			label={t('labels.zip')}
			onChange={setZip}
		/>
	);

	const cityInput = (
		<TextField
			name="city"
			disabled={isSaving}
			value={city || undefined}
			label={t('labels.city')}
			onChange={setCity}
			helperText={
				isInputError && city && containsNumber(city)
					? `${t('errors.inputContainsNumber')}`
					: undefined
			}
			error={isInputError && !!city && containsNumber(city)}
		/>
	);

	return (
		<Card>
			<CardContent>
				<Box paddingBottom={2.75}>
					<Text variant="h3">{t('partnerInfoPage.card.title')}</Text>
				</Box>
				<Grid container spacing={3} paddingTop="0.5rem" paddingBottom="1.5rem">
					<Grid container item xs={12} spacing={3}>
						<Grid xs={8} container item spacing={3}>
							<Grid item xs={12}>
								{nameInput}
							</Grid>
							<Grid item xs={12}>
								{countryInput}
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="flex-start"
								height="100%"
								alignItems="center"
							>
								{uploadImageButton}
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{addressInput}
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={4}>
							{zipInput}
						</Grid>
						<Grid item xs={8}>
							{cityInput}
						</Grid>
					</Grid>
				</Grid>
				<CardActions
					sx={{ justifyContent: 'flex-end', paddingBottom: '.5rem' }}
				>
					{saveButton}
				</CardActions>
			</CardContent>
		</Card>
	);
}

export default PartnerDetailsCard;
