import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function GreenHouseIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				fillRule="nonzero"
				d="M17.5 2c2.363 0 4.375 1.497 4.494 3.51L22 5.7V6h-2v-.3c0-.874-1.07-1.7-2.5-1.7-1.367 0-2.406.756-2.494 1.586L15 5.7V6h-2v-.3C13 3.592 15.063 2 17.5 2zm0 5.333c2.363 0 4.375 1.497 4.494 3.51l.006.19v.3h-2v-.3c0-.874-1.07-1.7-2.5-1.7-1.367 0-2.406.756-2.494 1.587l-.006.113v.3h-2v-.3c0-2.108 2.063-3.7 4.5-3.7zm0 5.334c2.363 0 4.375 1.496 4.494 3.51l.006.19v.3h-2v-.3c0-.874-1.07-1.7-2.5-1.7-1.367 0-2.406.756-2.494 1.586l-.006.114v.3h-2v-.3c0-2.109 2.063-3.7 4.5-3.7zm0 5.333c2.363 0 4.375 1.497 4.494 3.51l.006.19v.3h-2v-.3c0-.874-1.07-1.7-2.5-1.7-1.367 0-2.406.756-2.494 1.586L15 21.7v.3h-2v-.3c0-2.108 2.063-3.7 4.5-3.7zM6.5 2c2.363 0 4.375 1.497 4.494 3.51L11 5.7V6H9v-.3C9 4.826 7.93 4 6.5 4c-1.367 0-2.406.756-2.494 1.586L4 5.7V6H2v-.3C2 3.592 4.063 2 6.5 2zm0 5.333c2.363 0 4.375 1.497 4.494 3.51l.006.19v.3H9v-.3c0-.874-1.07-1.7-2.5-1.7-1.367 0-2.406.756-2.494 1.587L4 11.033v.3H2v-.3c0-2.108 2.063-3.7 4.5-3.7zm0 5.334c2.363 0 4.375 1.496 4.494 3.51l.006.19v.3H9v-.3c0-.874-1.07-1.7-2.5-1.7-1.367 0-2.406.756-2.494 1.586L4 16.367v.3H2v-.3c0-2.109 2.063-3.7 4.5-3.7zM6.5 18c2.363 0 4.375 1.497 4.494 3.51l.006.19v.3H9v-.3c0-.874-1.07-1.7-2.5-1.7-1.367 0-2.406.756-2.494 1.586L4 21.7v.3H2v-.3C2 19.592 4.063 18 6.5 18z"
				transform="translate(-148 -216) translate(16 116) translate(0 88) translate(132 12)"
			/>
		</SvgIcon>
	);
}

export default GreenHouseIcon;
