import { gql, TypedDocumentNode } from '@apollo/client';
import { TPartnershipCompany } from 'Models/partnershipCompany/isPartnershipCompany';

const UPLOAD_COMPANY_IMAGE_MUTATION: TypedDocumentNode<
	{
		uploadPartnershipCompanyImage: Pick<TPartnershipCompany, 'id' | 'image'>;
	},
	{ file: File }
> = gql`
	mutation uploadCompanyImage($file: Upload!) {
		uploadPartnershipCompanyImage(file: $file) {
			id
			image
		}
	}
`;

export default UPLOAD_COMPANY_IMAGE_MUTATION;
