import isNumber from 'vet/numbers/isNumber';

import { EquipmentType } from '../equipments/isEquipment';
import { TJobTreatment } from '../jobs/isJobTreatment';
import {
	GRAM,
	KILOGRAM,
	TON,
	POUND,
	OUNCE,
	GRAM_PER_SQUARE_METER,
	LITER_PER_SQUARE_METER,
	GRAM_PER_1000_SQUARE_METER,
	LITER_PER_1000_SQUARE_METER,
	MILLILITER_PER_1000_SQUARE_METER,
	GRAM_PER_HECTARE,
	KILOGRAM_PER_HECTARE,
	TON_PER_HECTARE,
	LITER_PER_HECTARE,
	MILLILITER_PER_HECTARE,
	POUND_PER_ACRE,
	GALLON_PER_ACRE,
	OUNCE_PER_ACRE,
	GRAM_PER_100_LITER,
	MILLILITER_PER_100_LITER,
	GRAM_PER_LITER_WATER,
	LITER_PER_LITER_WATER,
	GRAM_PER_PLANT,
	OUNCE_PER_PLANT,
	MILLILITER_PER_PLANT,
	FLUID_OUNCE_PER_PLANT,
	LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT,
	VolumeUnits,
	TAllUnits,
	KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT,
	LITER_PER_1000_LITER_WATER,
	KILOGRAM_PER_1000_LITER_WATER,
	UNKNOWN,
} from '../units';

const SQUARE_METER_IN_ACRES = 0.000247105381;

const convertAttributeValue = (value: string): number => {
	const attrValue = typeof value === 'string' ? Number(value) : 0;

	return isNaN(attrValue) ? 0 : attrValue;
};

const squareMetersToAcres = (value: number): number => {
	return value * SQUARE_METER_IN_ACRES;
};

export type TProductConsumption = {
	name: string;
	unit: TAllUnits;
	amount: number;
};

export default (
	treatment: Pick<TJobTreatment, 'amount' | 'unit' | 'originalProduct'>,
	areaSize: number,
	equipment?: EquipmentType | null,
	numberOfPlants?: number | null,
	plantHeight?: number | null
): TProductConsumption => {
	const { originalProduct, unit, amount } = treatment;
	const name = originalProduct?.name;
	const equipmentRateConfig = equipment && equipment.rate;

	if (!name) {
		return {
			name: '-',
			amount: 0,
			unit: GRAM,
		};
	}

	const notApplicable = {
		name,
		amount: 0,
	};

	switch (unit) {
		case GRAM_PER_SQUARE_METER:
			return {
				name,
				unit: GRAM,
				amount: amount * areaSize,
			};
		case LITER_PER_SQUARE_METER:
			return {
				name,
				unit: VolumeUnits.LITER,
				amount: amount * areaSize,
			};
		case GRAM_PER_1000_SQUARE_METER:
			return {
				name,
				unit: GRAM,
				amount: (amount * areaSize) / 1000,
			};
		case LITER_PER_1000_SQUARE_METER:
			return {
				name,
				unit: VolumeUnits.LITER,
				amount: (amount * areaSize) / 1000,
			};
		case MILLILITER_PER_1000_SQUARE_METER:
			return {
				name,
				unit: VolumeUnits.MILLILITER,
				amount: (amount * areaSize) / 1000,
			};

		case LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT:
			return {
				name,
				unit: VolumeUnits.LITER,
				amount: (amount * areaSize) / 10000,
			};
		case GRAM_PER_HECTARE:
			return {
				name,
				unit: GRAM,
				amount: (amount * areaSize) / 10000,
			};

		case GRAM_PER_PLANT:
			if (!isNumber(numberOfPlants)) {
				return { ...notApplicable, unit: GRAM_PER_PLANT };
			}

			return {
				name,
				unit: GRAM_PER_PLANT,
				amount: (amount * numberOfPlants) / areaSize,
			};

		case MILLILITER_PER_PLANT:
			if (!isNumber(numberOfPlants)) {
				return { ...notApplicable, unit: MILLILITER_PER_PLANT };
			}

			return {
				name,
				unit: MILLILITER_PER_PLANT,
				amount: (amount * numberOfPlants) / areaSize,
			};

		case OUNCE_PER_PLANT:
			if (!isNumber(numberOfPlants)) {
				return { ...notApplicable, unit: OUNCE_PER_PLANT };
			}

			return {
				name,
				unit: OUNCE_PER_PLANT,
				amount: (amount * numberOfPlants) / areaSize,
			};

		case FLUID_OUNCE_PER_PLANT:
			if (!isNumber(numberOfPlants)) {
				return { ...notApplicable, unit: FLUID_OUNCE_PER_PLANT };
			}

			return {
				name,
				unit: FLUID_OUNCE_PER_PLANT,
				amount: (amount * numberOfPlants) / areaSize,
			};

		case KILOGRAM_PER_HECTARE:
			return {
				name,
				unit: KILOGRAM,
				amount: (amount * areaSize) / 10000,
			};
		case KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT: {
			// plant height is given in centimeters
			const heightInMeters = (plantHeight || 100) / 100;
			return {
				name,
				unit: KILOGRAM,
				amount: (amount * areaSize * heightInMeters) / 10000,
			};
		}
		case TON_PER_HECTARE:
			return {
				name,
				unit: TON,
				amount: (amount * areaSize) / 10000,
			};
		case POUND_PER_ACRE:
			return {
				name,
				unit: POUND,
				amount: amount * squareMetersToAcres(areaSize),
			};
		case GALLON_PER_ACRE:
			return {
				name,
				unit: VolumeUnits.GALLON,
				amount: amount * squareMetersToAcres(areaSize),
			};
		case OUNCE_PER_ACRE:
			return {
				name,
				unit: OUNCE,
				amount: amount * squareMetersToAcres(areaSize),
			};
		case LITER_PER_HECTARE:
			return {
				name,
				unit: VolumeUnits.LITER,
				amount: (amount * areaSize) / 10000,
			};
		case MILLILITER_PER_HECTARE:
			return {
				name,
				unit: VolumeUnits.MILLILITER,
				amount: (amount * areaSize) / 10000,
			};
		case GRAM_PER_100_LITER:
			if (equipmentRateConfig) {
				const litersPerField =
					(convertAttributeValue(equipmentRateConfig.value) * areaSize) / 10000;

				return {
					name,
					unit: GRAM,
					amount: (amount * litersPerField) / 100,
				};
			} else {
				return {
					...notApplicable,
					unit: GRAM,
				};
			}
		case GRAM_PER_LITER_WATER:
			if (equipmentRateConfig) {
				const litersPerField =
					(convertAttributeValue(equipmentRateConfig.value) * areaSize) / 10000;

				return {
					name,
					unit: GRAM,
					amount: amount * litersPerField,
				};
			} else {
				return {
					...notApplicable,
					unit: GRAM,
				};
			}
		case MILLILITER_PER_100_LITER:
			if (equipmentRateConfig) {
				const litersPerField =
					(convertAttributeValue(equipmentRateConfig.value) * areaSize) / 10000;

				return {
					name,
					unit: VolumeUnits.MILLILITER,
					amount: (amount * litersPerField) / 100,
				};
			} else {
				return {
					...notApplicable,
					unit: VolumeUnits.MILLILITER,
				};
			}
		case LITER_PER_LITER_WATER:
			if (equipmentRateConfig) {
				const litersPerField =
					(convertAttributeValue(equipmentRateConfig.value) * areaSize) / 10000;

				return {
					name,
					unit: VolumeUnits.LITER,
					amount: amount * litersPerField,
				};
			} else {
				return {
					...notApplicable,
					unit: VolumeUnits.LITER,
				};
			}
		case LITER_PER_1000_LITER_WATER:
			if (equipmentRateConfig) {
				const litersPerField =
					(convertAttributeValue(equipmentRateConfig.value) * areaSize) / 10000;

				return {
					name,
					unit: VolumeUnits.LITER,
					amount: (amount * litersPerField) / 1000,
				};
			} else {
				return {
					...notApplicable,
					unit: VolumeUnits.LITER,
				};
			}
		case KILOGRAM_PER_1000_LITER_WATER:
			if (equipmentRateConfig) {
				const litersPerField =
					(convertAttributeValue(equipmentRateConfig.value) * areaSize) / 10000;

				return {
					name,
					unit: KILOGRAM,
					amount: (amount * litersPerField) / 1000,
				};
			} else {
				return {
					...notApplicable,
					unit: VolumeUnits.LITER,
				};
			}
		default:
			// eslint-disable-next-line
			const exhaustiveCheck: never = unit;
			return {
				...notApplicable,
				unit: UNKNOWN,
			};
	}
};
