import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import useCurrentLocation from 'Hooks/useCurrentLocation';

import ErrorBoundary from '../../../components/ErrorBoundary';
import ProfilePageAccessValidator from '../common/components/ProfilePageAccessValidator';
import PageBody from './components/PageBody';

function FarmMembersPage() {
	const location = useCurrentLocation();
	const { t } = useTranslation();

	return (
		<ProfilePageAccessValidator
			header={t('membersPage.title')}
			subheader={t('membersPage.subheader.manageAccess', {
				locationName: location?.name || '',
			})}
		>
			<ErrorBoundary>
				<PageBody />
			</ErrorBoundary>
		</ProfilePageAccessValidator>
	);
}

export default FarmMembersPage;
