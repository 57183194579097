import React, { memo, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { PartnerRoles } from 'Models/permissions/Roles';
import Divider from 'UI/display/Divider';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

import MembersList from '../MembersList';
import RolesFilter from '../RolesFilter';

const DEFAULT_FILTERS = {
	[PartnerRoles.PA_OWNER]: false,
	[PartnerRoles.PA_ADMIN]: false,
	[PartnerRoles.PA_MEMBER]: false,
};

export type TMembersFilters = typeof DEFAULT_FILTERS;

const ContentBody = memo(() => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const [filters, setFilters] = useState(DEFAULT_FILTERS);

	const onFilterChange = (value: string, active: boolean): void => {
		setFilters({
			...filters,
			[value]: active,
		});
	};

	return (
		<Grid>
			<Grid container spacing={2} m="-0.5rem 0 1.5rem 0.5rem" pr="3rem">
				<Grid item xs={10}>
					<FreeTextSearch
						inputPlaceholder={t(
							'managePartnerMembers.searchMembersPlaceholder'
						)}
						onChange={(newValue: string): void => setSearchValue(newValue)}
						onClear={() => setSearchValue('')}
						value={searchValue}
						sx={{
							height: '2.25rem',
							boxShadow: 'none',
							border: `1px solid ${FarmableColors.BLACK_12}`,
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<RolesFilter
						onChange={onFilterChange}
						onClear={() => {
							setFilters(DEFAULT_FILTERS);
						}}
						filters={filters}
					/>
				</Grid>
			</Grid>
			<Divider />
			<MembersList filters={filters} search={searchValue} />
		</Grid>
	);
});

export default ContentBody;
