import { TChartProps } from 'UI/display/Chart';
import { FarmableColors } from 'UI/theme/Colors';
import Highcharts, { AxisLabelsFormatterContextObject } from 'highcharts';

import styles from './styles.module.scss';

export interface ExtendedAxis extends Highcharts.Axis {
	dataMax?: number;
}

const yAxisCommonOptions = {
	title: {
		text: '',
	},
	tickLength: 30,
	tickWidth: 1,
	tickColor: FarmableColors.BLACK_12,
	labels: {
		style: {
			fontSize: '0.8125rem',
		},
		y: 16,
		useHTML: true,
		formatter: function (this: AxisLabelsFormatterContextObject) {
			return this.isFirst
				? `<div style="margin-top: -1.2rem">${this.value}</div>`
				: this.value.toString();
		},
	},
};

const initialChartConfig: TChartProps['options'] = {
	chart: {
		type: 'line',
		spacingRight: 0,
		height: 368,
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	plotOptions: {
		series: {
			events: {
				legendItemClick() {
					return false;
				},
			},
			states: {
				inactive: {
					enabled: false,
				},
			},
		},
		line: {
			marker: {
				enabled: false,
			},
			states: {
				hover: {
					enabled: false,
				},
			},
			enableMouseTracking: false,
		},
		spline: {
			marker: {
				enabled: false,
			},
			states: {
				hover: {
					enabled: false,
				},
			},
			enableMouseTracking: false,
		},
		scatter: {
			marker: {
				enabled: true,
				fillColor: FarmableColors.WHITE,
				symbol: 'circle',
				lineWidth: 2,
				lineColor: FarmableColors.ORANGE,
			},
		},
	},
	legend: {
		align: 'left',
		itemStyle: {
			fontWeight: 'normal',
		},
		symbolPadding: 0,
		symbolWidth: 0,
		symbolHeight: 0,
		squareSymbol: false,
		useHTML: true,
		labelFormatter: function () {
			switch (this.options.id) {
				case 'gdd':
				case 'dailyAvgTemperature':
					return `<div class="${styles.legendItem}"><div class="${styles.lineMarker}" style="background-color:${this.options.color}"></div><div>${this.name}</div></div>`;
				case 'plantMilestones':
					return `<div class="${styles.legendItem}"><div class="${styles.circleMarker}" style="color:${this.options.color}">●</div><div>${this.name}<div/><div/>`;
			}

			return this.name;
		},
	},
	tooltip: {
		borderWidth: 0,
		shared: true,
		followPointer: true,
		backgroundColor: 'transparent',
		shadow: false,
		useHTML: true,
		hideDelay: 0,
		padding: 0,
	},
	xAxis: {
		type: 'datetime',
		tickLength: 4,
		max: Date.UTC(new Date().getFullYear(), 11, 31),
	},
	yAxis: [
		{
			...yAxisCommonOptions,
			labels: {
				x: -30,
				align: 'left',
				...yAxisCommonOptions.labels,
			},
		},
		{
			...yAxisCommonOptions,
			labels: {
				x: 30,
				align: 'right',
				...yAxisCommonOptions.labels,
			},
			opposite: true,
		},
	],
};

export default initialChartConfig;
