import React, { ReactElement, useState } from 'react';

import { TTranslateFunction } from 'Features/localization';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLParamsType } from 'Models/history/isURLParams';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

import useManageHistory from 'Hooks/useManageHistory';

const getSoldFilterStatus = (
	searchInput: URLParamsType,
	t: TTranslateFunction
) => {
	switch (searchInput.filters.onlySoldBatches) {
		case true:
			return t('labels.sold');
		case false:
			return t('labels.notSold');
		case undefined:
			return t('labels.all');
	}
};

const SoldFilter = (): ReactElement => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { paramsFromURL, historyPush } = useManageHistory();
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const setFilter = (arr?: boolean) => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				onlySoldBatches: arr,
			},
		};

		setAnchorEl(null);
		historyPush(newSearchParams);
	};

	return (
		<Box>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{getSoldFilterStatus(paramsFromURL, t)}
			</Button>
			<Menu.Menu
				id="sales-page-sold-filter-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={() => setAnchorEl(null)}
			>
				<Menu.Item onClick={() => setFilter(undefined)}>
					{t('labels.all')}
				</Menu.Item>
				<Menu.Item onClick={() => setFilter(true)}>
					{t('labels.sold')}
				</Menu.Item>
				<Menu.Item onClick={() => setFilter(false)}>
					{t('labels.notSold')}
				</Menu.Item>
			</Menu.Menu>
		</Box>
	);
};

export default SoldFilter;
