import React, { ReactElement } from 'react';

import MUIDivider, { DividerProps } from '@mui/material/Divider';

type TDividerProps = DividerProps & {
	component?: keyof HTMLElementTagNameMap;
};

const Divider = (props: TDividerProps): ReactElement => {
	return <MUIDivider flexItem={true} {...props} />;
};

export default Divider;
