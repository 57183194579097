import _rangeRight from 'lodash/rangeRight';

import getSeasonPerDay from './getSeasonPerDay';
import { TSeasonParams } from './isSeasonParams';

const getAllAvailableSeasons = (
	seasonCutoffDate: string,
	from: Date,
	to?: Date
): TSeasonParams[] => {
	const firstDay = new Date(from);
	const lastDay = to || new Date();

	// Reset the time of given dates
	firstDay.setHours(0, 0, 0, 0);
	lastDay.setHours(0, 0, 0, 0);

	const fromDateYear = firstDay.getFullYear();
	const oldestYearCutoffDate = new Date(`${seasonCutoffDate}-${fromDateYear}`);

	// We use data sampling approach to check how many seasons are included in a
	// given time range.
	const availableSampleDates = _rangeRight(
		fromDateYear,
		lastDay.getFullYear() + 1
	).reduce<Date[]>((dates, current) => {
		const sampleDate = new Date(lastDay);
		sampleDate.setFullYear(current);

		// We should stop sampling when sample data is earlier than the
		// oldest cutoff date, and the oldest cutoff date is also earlier
		// than the "from" date.
		const shouldOmitSample =
			sampleDate < oldestYearCutoffDate && oldestYearCutoffDate <= firstDay;

		return shouldOmitSample ? dates : [...dates, sampleDate];
	}, []);

	// We need to additionally put the "from" date to avoid lack
	// of the oldest possible season.
	const shouldAddFromDateToSamples =
		firstDay < oldestYearCutoffDate &&
		oldestYearCutoffDate <
			availableSampleDates[availableSampleDates.length - 1];

	if (shouldAddFromDateToSamples) {
		availableSampleDates.push(firstDay);
	}

	return availableSampleDates.map((day) =>
		getSeasonPerDay(seasonCutoffDate, day)
	);
};

export default getAllAvailableSeasons;
