import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTimesheetSettings } from 'Models/timesheet/isTimesheetSettings';
import Text from 'UI/display/Text';
import Select from 'UI/inputs/Select';
import TextField from 'UI/inputs/TextField';
import Grid from 'UI/layout/Grid';
import Menu from 'UI/navigation/Menu';
import CurrencyCodes from 'currency-codes';

import isPositiveNumber from '../../../../utils/isPositiveNumber';

const CURRENCIES = CurrencyCodes.codes();

function getCurrencySelectItem(value: string) {
	return (
		<Menu.Item key={'select-item-' + value} value={value}>
			<Text variant="body2">{value}</Text>
		</Menu.Item>
	);
}

export type THoursCostInputs = Pick<
	TTimesheetSettings,
	'currency' | 'pricePerHour'
>;

type THoursCostInputsProps = {
	hoursCostInputs: THoursCostInputs;
	setHoursCostInputs: React.Dispatch<React.SetStateAction<THoursCostInputs>>;
};

const HoursCostInputs = (props: THoursCostInputsProps) => {
	const { hoursCostInputs, setHoursCostInputs } = props;
	const { t } = useTranslation();

	const currenciesItems = CURRENCIES.map(getCurrencySelectItem);

	function handlePricePerHourChange(newValue: string) {
		if (isPositiveNumber(newValue)) {
			setHoursCostInputs({
				...hoursCostInputs,
				pricePerHour: parseInt(newValue),
			});
		}
	}

	function handleCurrencyChange(newValue: string) {
		setHoursCostInputs({
			...hoursCostInputs,
			currency: newValue,
		});
	}

	return (
		<Grid container spacing={2} paddingTop="2rem">
			<Grid item xs={6}>
				<TextField
					value={hoursCostInputs.pricePerHour?.toString() || '0'}
					label={t('labels.pricePerHour')}
					type="number"
					onChange={handlePricePerHourChange}
					inputProps={{ min: 0 }}
				/>
			</Grid>

			<Grid item xs={6}>
				<Select
					label={t('labels.currency')}
					value={hoursCostInputs.currency}
					changeHandler={handleCurrencyChange}
				>
					{currenciesItems}
				</Select>
			</Grid>
		</Grid>
	);
};

export default HoursCostInputs;
