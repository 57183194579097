import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { THarvestFilterInput } from 'Models/harvest/types/HarvestFilterInput';

import { THarvestTableQueryResponse } from '../types';

export type TGetHarvestTableDataResponse = {
	getHarvest: THarvestTableQueryResponse;
};

type TGetHarvestInput = {
	locationID: string;
	pageInput?: TPageInput;
	filterBy?: Partial<THarvestFilterInput>;
};

const GET_HARVEST_TABLE_DATA: TypedDocumentNode<
	TGetHarvestTableDataResponse,
	TGetHarvestInput
> = gql`
	query getHarvest(
		$locationID: ID!
		$filterBy: HarvestFilterInput
		$pageInput: PageInput!
	) {
		getHarvest(
			locationId: $locationID
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			harvest {
				id
				date
				fields {
					id
					name
					areaSize
				}
				category
				variety
				countingUnit
				volume
				unit
				weight
				boxSize
				quality
				author {
					id
					firstName
					lastName
					profileImage {
						id
						path
						filename
					}
				}
				teamMembers {
					id
					fullName
				}
				teamLeads {
					id
					firstName
					lastName
				}
				teamIds
				images {
					id
					path
					filename
				}
			}
		}
	}
`;

export default GET_HARVEST_TABLE_DATA;
