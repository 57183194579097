// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3DV3erTDXhGBJx62Uv0FwO {\n  font-size: .75rem;\n  margin-right: .5rem;\n}\n\n.g5bX84pyJfvd-sqVALHOY {\n  padding: 0;\n  margin-top: 1rem;\n  margin-bottom: 2.5rem;\n}\n\n.g5bX84pyJfvd-sqVALHOY>*:not(:last-child) {\n  margin-bottom: .5rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"exportNotification": "_3DV3erTDXhGBJx62Uv0FwO",
	"header": "g5bX84pyJfvd-sqVALHOY"
};
module.exports = exports;
