import getOldestItem from './getOldestItem';

type TObjectWithDate = {
	date?: string | null;
};

const getLastSaleDate = (
	currentYear?: TObjectWithDate[],
	previousYear?: TObjectWithDate[]
) => {
	const currentYearLastData = currentYear?.[currentYear.length - 1];
	const previousYearLastData = previousYear?.[previousYear.length - 1];

	return getOldestItem(currentYearLastData, previousYearLastData);
};

export default getLastSaleDate;
