import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SVG = require('./icon.react.svg').default;

const ASPECT = 160.0 / 32.0;

const HEIGHTS = {
	sm: 18,
	md: 24,
	lg: 30,
};

const WIDTHS = {
	sm: HEIGHTS['sm'] * ASPECT,
	md: HEIGHTS['md'] * ASPECT,
	lg: HEIGHTS['lg'] * ASPECT,
};

type TProps = {
	size?: 'sm' | 'md' | 'lg';
	width?: number;
	height?: number;
};

const Icon = (props: TProps) => {
	const { size = 'md', ...rest } = props;

	if (size) {
		rest.width = WIDTHS[size];
		rest.height = HEIGHTS[size];
	}

	return <SVG {...rest} />;
};

export default Icon;
