import { URLFiltersType } from 'Models/history/isURLFilters';
import { TJob } from 'Models/jobs/isJob';
import calculateProductConsumption, {
	TProductConsumption,
} from 'Models/products/calculateProductConsumption';
import normalizeProductConsumption from 'Models/products/normalizeProductConsumption';
import productComparator from 'Models/products/productComparator';
import { TAllUnits } from 'Models/units';

import getFieldsFromLocationJobs from './getFieldsFromLocationJobs';

export type TProductsConsumption = {
	products: TProductConsumption[];
	unit: TAllUnits;
};

type TGetProductConsumptionFromJob = Pick<
	TJob,
	'treatments' | 'jobFields' | 'driversWithEquipmentConfig'
>;

function getProductConsumptionFromJobs(
	jobs: TGetProductConsumptionFromJob[],
	filters?: URLFiltersType
) {
	const allProducts = jobs
		.map((job) => {
			const treatmentFieldTuple = job.treatments
				.map((treatment) =>
					getFieldsFromLocationJobs([job])
						.filter((field) =>
							filters && filters.fieldIds?.length
								? filters.fieldIds.includes(field.id)
								: true
						)
						.map((field) => ({
							treatment: treatment,
							field: field,
						}))
				)
				.flat();

			const jobProducts = treatmentFieldTuple.map((jobTreatment) => {
				const equipment = job.driversWithEquipmentConfig[0]?.equipment;
				const areaSize = jobTreatment.field.areaSize || 0;
				const plantHeight = jobTreatment.field.plantHeight || 0;
				const numberOfPlants = jobTreatment.field.numberOfPlants || 0;

				return calculateProductConsumption(
					jobTreatment.treatment,
					areaSize,
					equipment,
					numberOfPlants,
					plantHeight
				);
			});
			return jobProducts;
		})
		.flat();

	const normalizedProducts = allProducts
		.reduce((products: TProductConsumption[], product: TProductConsumption) => {
			const reducedProduct = products.find(
				(p) => p.name === product.name && p.unit === product.unit
			);
			if (reducedProduct) {
				reducedProduct.amount += product.amount;
			} else {
				products.push(product);
			}
			return products;
		}, [])
		.map((product) => normalizeProductConsumption(product));

	const productsSortedByUnit = normalizedProducts.reduce(
		(products: TProductsConsumption[], product: TProductConsumption) => {
			const unitProduct = products.find((p) => p.unit === product.unit);
			if (unitProduct) {
				const productExist = unitProduct.products.find(
					(p) => p.name === product.name
				);
				if (productExist) {
					productExist.amount += product.amount;
				} else {
					unitProduct.products.push(product);
				}
			} else {
				products.push({
					unit: product.unit,
					products: [product],
				});
			}
			return products;
		},
		[]
	);

	productsSortedByUnit.forEach(
		(sortedProduct) =>
			(sortedProduct.products = sortedProduct.products.sort(productComparator))
	);

	return productsSortedByUnit;
}

export default getProductConsumptionFromJobs;
