import React, { ReactElement } from 'react';

import Text from 'UI/display/Text';
import ConfirmedIcon from 'UI/icons/Confirmed';
import Box from 'UI/layout/Box';

type TSuccessMessageProps = {
	message?: string;
	children?: ReactElement;
};

const SuccessMessage = (props: TSuccessMessageProps) => {
	const { children, message } = props;
	return (
		<Box padding={2} display="flex" flexDirection="column" alignItems="center">
			<ConfirmedIcon />
			{message ? (
				<Box padding={2} textAlign="center">
					<Text variant="h2">{message}</Text>
				</Box>
			) : null}
			{children}
		</Box>
	);
};

export default SuccessMessage;
