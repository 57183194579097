import { TJob } from 'Models/jobs/isJob';
import { TJobFieldSnapshot } from 'Models/jobs/isJobFieldSnapshot';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';

const isJobField = (
	item: TJobFieldSnapshot | null | undefined
): item is TJobFieldSnapshot => {
	return !!item;
};

const getFieldsFromLocationJobs = (jobs: Pick<TJob, 'jobFields'>[]) =>
	uniqBy(
		flatMap(jobs, (job) => job.jobFields),
		'id'
	).filter(isJobField);

export default getFieldsFromLocationJobs;
