import { gql, TypedDocumentNode } from '@apollo/client';
import { TTeam } from 'Models/teams/isTeam';

export type TGetTeamsResponse = {
	getTeams: Pick<TTeam, 'id' | 'name' | 'teamMembers'>[];
};

type TGetTeamsInput = {
	locationId: string;
};

export const GET_TEAMS_QUERY: TypedDocumentNode<
	TGetTeamsResponse,
	TGetTeamsInput
> = gql`
	query getTeams($locationId: ID!) {
		getTeams(locationId: $locationId) {
			id
			name
			teamMembers {
				id
				fullName
			}
		}
	}
`;
