import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';
import TextField from 'UI/inputs/TextField';

import getDebounce from 'Utils/getDebounce';
import isPositiveNumber from 'Utils/isPositiveNumber';
import numberToDisplayString from 'Utils/numberToDisplayString';

const debounced = getDebounce();

type TDistanceBetweenRowsProps = {
	distance: TFieldInput['averageDistanceBetweenRows'];
	setDistance: (distance: number) => void;
};

function DistanceBetweenRows(props: TDistanceBetweenRowsProps): ReactElement {
	const { distance, setDistance } = props;
	const [inputValue, setInputValue] = useState(() =>
		numberToDisplayString(distance)
	);
	const { t } = useTranslation();
	const showError = Boolean(inputValue && !isPositiveNumber(inputValue));

	function changeDistanceHandler(value) {
		const sanitizedDistance = numberToDisplayString(value);
		const updateInput = () => setDistance(value);
		debounced(updateInput);
		setInputValue(sanitizedDistance);
	}

	return (
		<TextField
			value={inputValue}
			label={t('labels.distanceBetweenRows')}
			type={'number'}
			onChange={changeDistanceHandler}
			inputProps={{ min: 0, step: 0.1 }}
			error={showError}
			helperText={
				showError ? t('helpers.positiveValuesOnly') : t('helpers.inCentimeters')
			}
		/>
	);
}

export default DistanceBetweenRows;
