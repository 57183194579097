import Roles from './Roles';

export type TPermissionsTable = typeof Permissions;

const BASIC_ACCESS = [Roles.ADMIN, Roles.FARMER, Roles.MANAGER];
const ADMIN_ACCESS = [Roles.ADMIN, Roles.FARMER];

const Permissions = {
	// single role permissions to enable checking exact roles
	// in usePermission hook
	[Roles.ADMIN]: [Roles.ADMIN],
	[Roles.FARMER]: [Roles.FARMER],
	[Roles.MANAGER]: [Roles.MANAGER],
	[Roles.ADVISOR]: [Roles.ADVISOR],
	[Roles.DRIVER]: [Roles.DRIVER],
	[Roles.PA_OWNER]: [Roles.PA_OWNER],
	[Roles.PA_ADMIN]: [Roles.PA_ADMIN],
	[Roles.PA_MEMBER]: [Roles.PA_MEMBER],
	NAVBAR_SETTINGS_DROPDOWN: [
		...BASIC_ACCESS,
		Roles.PA_ADMIN,
		Roles.PA_MEMBER,
		Roles.PA_OWNER,
	],

	FARM_SETTINGS_VIEW: BASIC_ACCESS,

	DELETE_FARM: ADMIN_ACCESS,

	ADD_FARM_PHOTO: ADMIN_ACCESS,

	JOB_LOGS_EXPORT: [...BASIC_ACCESS, Roles.ADVISOR],
	JOB_LOGS_PURCHASE: ADMIN_ACCESS,
	JOB_LOGS_VIEW: BASIC_ACCESS,

	TEAMS_MANAGE: [...BASIC_ACCESS, Roles.DRIVER],
	TEAMS_VIEW: [...BASIC_ACCESS, Roles.DRIVER],

	TIMESHEETS_EXPORT: BASIC_ACCESS,
	TIMESHEETS_PURCHASE: ADMIN_ACCESS,
	TIMESHEETS_VIEW: BASIC_ACCESS,

	HARVEST_VIEW: BASIC_ACCESS,
	HARVEST_EXPORT: BASIC_ACCESS,

	INVENTORY_VIEW: BASIC_ACCESS,

	STRIPE_REDIRECT: ADMIN_ACCESS,

	WEB_PORTAL_ACCESS: [...BASIC_ACCESS, Roles.ADVISOR],

	SHARE_NOTE_WITH_ADVISOR: BASIC_ACCESS,

	PROFILE_PAGE_ACCESS: BASIC_ACCESS,

	PRODUCT_AND_CAUSES_ACCESS: BASIC_ACCESS,

	HOURS_LOGS_VIEW: BASIC_ACCESS,
	FETCH_LOCATION_SUBSCRIPTIONS: BASIC_ACCESS,
	STORE_PAGE_VIEW: ADMIN_ACCESS,
	BECOME_TEAM_LEAD: [Roles.FARMER, Roles.MANAGER, Roles.DRIVER],

	FIELD_IMPORTS_PAGE_ACCESS: BASIC_ACCESS,

	GET_REGULAR_FIELDS_QUERY: BASIC_ACCESS,
	GET_REGULAR_FIELD_CATEGORIES_QUERY: BASIC_ACCESS,
	FARM_SALES_PAGE_ACCESS: BASIC_ACCESS,

	DASHBOARD_PAGE_ACCESS: [...BASIC_ACCESS, Roles.ADVISOR],
	DASHBOARD_PAGE_PROFIT_CHART_ACCESS: BASIC_ACCESS,

	PARTNER_PORTAL_ACCESS: [Roles.PA_ADMIN, Roles.PA_MEMBER, Roles.PA_OWNER],
	PARTNER_PORTAL_OWNER: [Roles.PA_OWNER],
	PARTNER_PORTAL_ADMIN: [Roles.PA_ADMIN, Roles.PA_OWNER],

	PARTNER_PORTAL_INVITE_MEMBER: [Roles.PA_ADMIN, Roles.PA_OWNER],
};

export default Permissions;
