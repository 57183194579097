import { TTeamMemberInput } from 'Models/teams/isTeamMemberInput';
import { TTeamMember } from 'Models/timesheet/isTeamMember';

function populateMembersPositions(teamMembers: TTeamMember[]): TTeamMember[];
function populateMembersPositions(
	teamMembers: TTeamMemberInput[]
): TTeamMemberInput[];
function populateMembersPositions(
	teamMembers: TTeamMember[] | TTeamMemberInput[]
) {
	return teamMembers.map((member, index) => ({
		...member,
		position: index,
	}));
}

export default populateMembersPositions;
