import { ApolloError, useQuery } from '@apollo/client';
import { gql, TypedDocumentNode } from '@apollo/client';
import { TLocation } from 'Models/locations/isLocation';

import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';

export type TGetCurrentLocationResponse = {
	location: TLocation;
};

type TGetCurrentLocationInput = {
	locationId: string;
};

const GET_LOCATION_DATA: TypedDocumentNode<
	TGetCurrentLocationResponse,
	TGetCurrentLocationInput
> = gql`
	query getCurrentLocation($locationId: ID!) {
		location(id: $locationId) {
			id
			name
			orgNumber
			businessName
			address
			city
			zip
			country
			areaUnit
			seasonCutoffDate
			createdAt
			type
		}
	}
`;

function useCurrentLocation(): TGetCurrentLocationResponse['location'] | null {
	const locationId = getLocationIdFromUrl();

	const { data, error } = useQuery(GET_LOCATION_DATA, {
		skip: !locationId,
		variables: { locationId },
	});

	if (error) {
		throw new ApolloError(error);
	}

	return data?.location || null;
}

export default useCurrentLocation;
