export type StateMember<TStatus extends string> = {
	status: TStatus;
};

export function assertStateStatus<TStatus extends string>(
	state: { status: string },
	...expectedStatuses: TStatus[]
): asserts state is StateMember<TStatus> {
	if (!expectedStatuses.includes(state.status as TStatus)) {
		throw new Error(
			`Invalid state ${state.status} (expected one of: ${expectedStatuses})`
		);
	}
}
