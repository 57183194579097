import { TCause } from 'Models/causes/isCause';
import { z } from 'zod';

import { TProductUnits, isProductUnit } from '../units';
import { TPlantMilestone, isPlantMilestone } from './PlantMilestone';

type TDateTime = string;

const naturalNumber = z.number().min(0);

export const ProductTreatmentInputSchema = z.object({
	category: z.string(),
	amount: naturalNumber,
	unit: z.string().refine((value) => isProductUnit(value)),
	waterMin: naturalNumber.optional(),
	waterMax: naturalNumber.optional(),
	waterUnit: z.string().refine(isProductUnit).optional(),
	causes: z.array(z.string()).optional(),
	withholdingPeriod: naturalNumber.optional(),
	maxApplicationsVegetation: naturalNumber.optional(),
	maxApplicationsCategory: naturalNumber.optional(),
	maxApplicationsCause: naturalNumber.optional(),
	applicationInterval: naturalNumber.optional(),
	maxVolume: naturalNumber.optional(),
	plantMilestones: z.array(z.string().refine(isPlantMilestone)).optional(),
	registrationNumber: z.string().optional(),
	licenseExpirationDate: z.string().datetime().optional(),
	areaOfApplication: z.string().optional(),
	cultivationType: z.string().optional(),
	regulations: z.array(z.string()).optional(),
});

export type TProductTreatmentInput = {
	governmentTreatmentId: string | null;
	category: string;
	amount: number;
	unit: TProductUnits;
	waterMin?: number;
	waterMax?: number;
	waterUnit?: TProductUnits;
	causeIds?: string[];
	withholdingPeriod?: number;
	maxApplicationsVegetation?: number;
	maxApplicationsCategory?: number;
	maxApplicationsCause?: number;
	applicationInterval?: number;
	maxVolume?: number;
	plantMilestones?: TPlantMilestone[];
	registrationNumber?: string;
	licenseExpirationDate?: TDateTime;
	areaOfApplication?: string;
	cultivationType?: string;
	regulations?: string[];
	fieldsOfUse?: string[];
};
