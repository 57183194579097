import React, { ReactElement } from 'react';

import Box from 'UI/layout/Box';
import Container from 'UI/layout/Container';

import Board from './components/Board';
import Card from './components/Card';
import Navigation from './components/Navigation';
import styles from './styles.module.scss';

type TDashboardPageTemplate = {
	children: ReactElement | ReactElement[];
};

const DashboardPageTemplate = (props: TDashboardPageTemplate) => {
	return (
		<Container maxWidth={false} className={styles.dashRoot}>
			<Box className={styles.dashContent}>{props.children}</Box>
		</Container>
	);
};

type _TWrapper = typeof DashboardPageTemplate & {
	Board: typeof Board;
	Card: typeof Card;
	Navigation: typeof Navigation;
};

const Wrapper = DashboardPageTemplate as _TWrapper;
Wrapper.Board = Board;
Wrapper.Card = Card;
Wrapper.Navigation = Navigation;

export default Wrapper;
