import React, { ReactElement } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { FarmableColors } from '../../theme/Colors';
import './styles.scss';

function LoadingSpinnerIcon(props: SvgIconProps): ReactElement {
	const { width = 120, height = 120, ...MUISvgIconProps } = props;

	const styles = {
		width,
		height,
		animation: 'rotate 2s linear infinite',
		'& circle': {
			strokeDasharray: '1, 200',
			strokeDashoffset: 0,
			animation: 'dash 1.5s ease-in-out infinite',
			strokeLinecap: 'round',
			strokeWidth: 3,
			stroke: FarmableColors.ORANGE,
		},
	};

	return (
		<SvgIcon viewBox="25 25 50 50" sx={styles} {...MUISvgIconProps}>
			<circle cx="50" cy="50" r="20" fill="none" />
		</SvgIcon>
	);
}

export default LoadingSpinnerIcon;
