import { TTranslateFunction } from 'Features/localization';
import { FarmableTextColors } from 'UI/theme/Colors';
import { CurrencyCodeRecord } from 'currency-codes';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';

function getTooltipPoint(
	point: TooltipFormatterContextObject,
	t: TTranslateFunction
) {
	const name = point.series.options.custom?.isCurrentYear
		? t('common.currentYear')
		: t('common.previousYear');

	if (point.y) {
		const amount = Highcharts.numberFormat(point.y, 0, '', ' ');
		const currency = point.series.options.custom?.currency;

		return `<div style="display: flex">
			<span style="width: 0.75rem" style='color: ${point.color}'>•</span>
			<span>${name}: <b>${amount}</b> ${currency}</span>
		</div>`;
	}
}

type TTooltipPoints =
	| [TooltipFormatterContextObject]
	| [TooltipFormatterContextObject, TooltipFormatterContextObject];

type TPointsGroupedByCurrency = {
	code: CurrencyCodeRecord['code'];
	points: TTooltipPoints;
};

function getTooltipDifference(
	points: [TooltipFormatterContextObject, TooltipFormatterContextObject],
	currency: CurrencyCodeRecord['code'],
	t: TTranslateFunction
) {
	if (points[0].y && points[1].y) {
		const difference = points[0].y - points[1].y;
		const formattedDifference = Highcharts.numberFormat(difference, 0, '', ' ');
		const color =
			difference >= 0 ? FarmableTextColors.GREEN : FarmableTextColors.RED;

		return `<div style="display: flex">
			<span style="width: 0.75rem">${t('common.difference')}: 
				<span style='color: ${color}'>
					<b>${formattedDifference}</b> ${currency}
				</span>
			</span>
		</div>`;
	}
}

function parseTooltipPoints(
	pointsByCurrencies: TPointsGroupedByCurrency,
	t: TTranslateFunction
) {
	const { code, points } = pointsByCurrencies;
	const parsedPoints = points.map((point) => getTooltipPoint(point, t));

	if (points.length === 2) {
		return {
			code,
			points: [...parsedPoints, getTooltipDifference(points, code, t)],
		};
	}

	return { code, points: parsedPoints };
}

export default parseTooltipPoints;
