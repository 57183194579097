import { addDays } from 'date-fns';
import format from 'date-fns/format';

import { TJob } from './isJob';
import { TJobTreatment } from './isJobTreatment';
import jobReEntryInterval from './jobReEntryInterval';

function jobEarliestReEntryToField(
	job: Pick<TJob, 'completedDate'> & {
		treatments: Pick<TJobTreatment, 'amount' | 'unit' | 'originalProduct'>[];
	}
): string {
	const reEntryInterval = jobReEntryInterval(job);
	return job.completedDate && reEntryInterval
		? format(addDays(new Date(job.completedDate), reEntryInterval), 'dd.MM.yy')
		: '-';
}

export default jobEarliestReEntryToField;
