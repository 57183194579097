import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ProfilePageTemplate from '../common/components/ProfilePageTemplate';
import TeamsPageBody from './components/TeamsPageBody';

function TeamsPage(): ReactElement {
	const { t } = useTranslation();
	return (
		<ProfilePageTemplate
			header={t('teamsPage.header')}
			subheader={t('teamsPage.subheader')}
		>
			<TeamsPageBody />
		</ProfilePageTemplate>
	);
}

export default TeamsPage;
