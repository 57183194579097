import { TPlannerJobInput } from 'Models/planner/PartnerPlannerJobInput';

function parseCreatePlannerJobInput(input: TPlannerJobInput) {
	return {
		...input,
		jobDetails: {
			jobType: input.jobDetails.jobType,
			treatments: input.jobDetails.treatments.map((treatment) => ({
				amount: treatment.amount,
				unit: treatment.unit,
				originalTreatmentId: treatment.originalTreatmentId,
			})),
			causeIds: input.jobDetails.causeIds.length
				? input.jobDetails.causeIds
				: null,
			justificationIds: input.jobDetails.justificationIds.length
				? input.jobDetails.justificationIds
				: null,
		},
	};
}
export default parseCreatePlannerJobInput;
