import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJob } from 'Models/jobs/isJob';
import {
	SafetyChecklistItemNames,
	TSafetyChecklistItemName,
} from 'Models/jobs/isSafetyChecklistItem';
import jobAuthorNameString from 'Models/jobs/jobAuthorNameString';
import Text from 'UI/display/Text';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';

import DetailModalSection from '../../../../../components/ModalSection';

type TSafeSprayingProps = {
	job: Pick<TJob, 'safetyChecklist'> & {
		author: Pick<TJob['author'], 'firstName' | 'lastName'>;
	};
};

const SafeSpraying = (props: TSafeSprayingProps) => {
	const { job } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.jobLogsPage.detailsModal.';
	const author = jobAuthorNameString(job);
	if (!job.safetyChecklist?.length) {
		return null;
	}
	function getCheckboxMessage(name: TSafetyChecklistItemName): string | null {
		if (name === SafetyChecklistItemNames.PRODUCT_LABELS_READ) {
			return t(`${prefix}props.productLabels`, {
				name: author,
			});
		} else if (
			name === SafetyChecklistItemNames.PERSONAL_PROTECTION_EQUIPMENT_USED
		) {
			return t(`${prefix}props.ppe`, {
				name: author,
			});
		}
		return null;
	}

	const checklist = job.safetyChecklist.map((item) => {
		const message = getCheckboxMessage(item.name);

		return message ? (
			<Box key={item.name}>
				<Checkbox.Controlled
					dense
					disabled
					label={<Text color="textPrimary">{message}</Text>}
					checked={item.checked}
				/>
			</Box>
		) : null;
	});

	return (
		<DetailModalSection title={t(`${prefix}sections.safeSpraying`)}>
			{checklist}
		</DetailModalSection>
	);
};

export default SafeSpraying;
