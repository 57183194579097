import React from 'react';

import { useQuery, ApolloError } from '@apollo/client';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';

import useLocationID from 'Hooks/useLocationID';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import LoadingContent from '../../common/components/LoadingContent';
import NoSubscription from '../../common/components/NoSubscription';
import GET_TIMESHEET_SETTINGS_DATA from '../api/getTimesheetSettingsData';
import LunchTimeSettings from './LunchTimeSettings';
import SettingsCard from './SettingsCard';

const TimeSettingsPageBody = () => {
	const locationId = useLocationID();
	const hasTimesheetsSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.TIMESHEET
	);
	const { data, loading, error } = useQuery(GET_TIMESHEET_SETTINGS_DATA, {
		variables: {
			locationId,
		},
	});

	if (loading || hasTimesheetsSubscription === null) {
		return <LoadingContent />;
	}

	if (!hasTimesheetsSubscription) {
		return <NoSubscription />;
	}

	if (data) {
		const timesheetSettings = data.getTimesheetSettings;

		return (
			<SettingsCard>
				<ErrorBoundary>
					<LunchTimeSettings timesheetSettings={timesheetSettings} />
				</ErrorBoundary>
			</SettingsCard>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
};

export default TimeSettingsPageBody;
