import React, { ReactNode } from 'react';

import Text from 'UI/display/Text';

type TFieldLogHeaderSubtitlesProps = {
	children: ReactNode | ReactNode[];
};

const FieldLogHeaderSubtitles = (props: TFieldLogHeaderSubtitlesProps) => {
	const { children } = props;

	return children ? (
		<Text variant="body2">
			{React.Children.map(children, (child, index) => {
				if (child && children[index + 1]) {
					return <>{child} - </>;
				} else {
					return child;
				}
			})}
		</Text>
	) : null;
};

export default FieldLogHeaderSubtitles;
