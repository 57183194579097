import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import AppleIcon from 'UI/icons/Apple';
import FacebookIcon from 'UI/icons/Facebook';
import GoogleIcon from 'UI/icons/Google';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import ErrorBoundary from '../../components/ErrorBoundary';
import IntercomInit from '../../components/IntercomInit';
import Logo from '../../components/icons/misc/LogoBig';
import useAuth0Error from '../../hooks/useAuth0Error';
import EmailLoginButton from './components/EmailLoginButton';
import IconLoginButton from './components/IconLoginButton';
import SignUpButton from './components/SignUpButton';
import styles from './styles.module.scss';
import getLoginParams, {
	AuthorizeType,
	Provider,
} from './utils/getLoginParams';

const LoginPage = () => {
	const { t } = useTranslation();
	useAuth0Error();
	const { loginWithRedirect } = useAuth0();

	const authenticatedLoginButton = (
		<Grid item>
			<EmailLoginButton
				label={t('loginPage.buttons.logIn')}
				onStartLogin={() => void loginWithRedirect(getLoginParams())}
			/>
		</Grid>
	);

	const authenticatedSignUp = (
		<Grid item>
			<SignUpButton
				label={t('loginPage.buttons.signUp')}
				onStartLogin={() =>
					void loginWithRedirect(
						getLoginParams({ mode: AuthorizeType.SIGN_UP })
					)
				}
			/>
		</Grid>
	);

	const authenticatedAppleButton = (
		<Grid item>
			<IconLoginButton
				label={t('loginPage.buttons.authWithApple')}
				onStartLogin={() =>
					void loginWithRedirect(getLoginParams({ connection: Provider.APPLE }))
				}
			>
				<AppleIcon className={styles.buttonIcon} />
			</IconLoginButton>
		</Grid>
	);

	const authenticatedGoogleButton = (
		<Grid item>
			<IconLoginButton
				label={t('loginPage.buttons.authWithGoogle')}
				onStartLogin={() =>
					void loginWithRedirect(
						getLoginParams({ connection: Provider.GOOGLE })
					)
				}
			>
				<GoogleIcon className={styles.buttonIcon} />
			</IconLoginButton>
		</Grid>
	);

	const authenticatedFacebookButton = (
		<Grid item>
			<IconLoginButton
				label={t('loginPage.buttons.authWithFacebook')}
				onStartLogin={() =>
					void loginWithRedirect(
						getLoginParams({ connection: Provider.FACEBOOK })
					)
				}
			>
				<FacebookIcon className={styles.buttonIcon} />
			</IconLoginButton>
		</Grid>
	);

	const loginButton = (
		<>
			<Grid container direction="column" spacing={2}>
				{authenticatedSignUp}
				{authenticatedFacebookButton}
				{authenticatedGoogleButton}
				{authenticatedAppleButton}
				{authenticatedLoginButton}
			</Grid>
		</>
	);

	return (
		<Box className={styles.splashBackground}>
			<Box className={styles.loginBar}>
				<IntercomInit.Guest />
				<Box className={styles.content}>
					<div className={styles.scrollContainer}>
						<Logo className={styles.logo} />
						<Text component={'h2'} className={styles.descriptionText}>
							{t('loginPage.description')}
						</Text>
						{loginButton}
					</div>
				</Box>
			</Box>
		</Box>
	);
};

export default () => (
	<ErrorBoundary withLogout>
		<LoginPage />
	</ErrorBoundary>
);
