import { TTimesheet } from './isTimesheet';

function timesheetFieldsString<TGenericTimesheet>(
	timesheet: TGenericTimesheet & Pick<TTimesheet, 'fields'>
): string {
	const { fields } = timesheet;

	if (fields.length === 0) {
		return '-';
	}

	return fields.map((field) => field.name).join(', ');
}

export default timesheetFieldsString;
