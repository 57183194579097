import { TTranslateFunction } from 'Features/localization';
import getFieldsGeometryFromUploadedFiles from 'Models/fields/getFieldsGeometryFromUploadedFiles';
import { TField } from 'Models/fields/isField';
import { EMPTY_FIELD_INPUT } from 'Models/fields/mocks/mockFieldInput';
import {
	isGeoJSONField,
	TGeoJSONField,
} from 'Models/geoJSONFiles/isGeoJSONField';
import EMPTY_VARIETY from 'Models/jobs/mocks/mockEmptyVariety';
import _uniqueId from 'lodash/uniqueId';

type TParsedFieldsArea = Pick<TField, 'geometry' | 'name'>[];

const populateInitialFieldOptions = (fields: TParsedFieldsArea) =>
	fields.map((field) => ({
		...EMPTY_FIELD_INPUT,
		name: field.name,
		varieties: [
			{
				...EMPTY_VARIETY,
				_id: _uniqueId(),
				percentage: 100,
			},
		],
		geometry: field.geometry,
	}));

type TGeojsonUploadFailed = {
	errorMessage: string;
};
type TUploadedGeoJSONFile = TGeoJSONField | TGeojsonUploadFailed;

const getUploadedFiles = async (files: FileList, t: TTranslateFunction) =>
	await Promise.all(
		Array.from(files).map(
			(file) =>
				new Promise<TUploadedGeoJSONFile>((resolve, reject) => {
					const fileReader = new FileReader();

					fileReader.readAsText(file);
					fileReader.onload = () => {
						if (typeof fileReader.result === 'string') {
							try {
								const geoJSONResult = JSON.parse(fileReader.result);
								if (isGeoJSONField(geoJSONResult)) {
									resolve(geoJSONResult);
								} else {
									resolve({
										errorMessage: t('uploadGeoJSON.wrongFileFormat', {
											fileName: file.name,
										}),
									});
								}
							} catch (error) {
								resolve({
									errorMessage: t('uploadGeoJSON.parseErrorMessage', {
										fileName: file.name,
									}),
								});
							}
						}
					};
					fileReader.onerror = () => {
						reject(fileReader.error);
					};
				})
		)
	);

export default async function LoadFieldsFromFiles(
	files: FileList,
	t: TTranslateFunction
) {
	const uploadedFiles = await getUploadedFiles(files, t);
	const errors = uploadedFiles
		.filter((file): file is TGeojsonUploadFailed => 'errorMessage' in file)
		.map((r) => r.errorMessage);

	if (errors.length) {
		throw new Error(errors.join(' & '));
	} else {
		const validUploadedFiles = uploadedFiles.filter(
			(file): file is TGeoJSONField => 'features' in file
		);
		const parsedFieldsArea = getFieldsGeometryFromUploadedFiles(
			validUploadedFiles,
			t
		);

		// get rid of a duplicate fields - #2042
		const deduplicatedFieldsArea = Array.from(
			new Set(parsedFieldsArea.map((field) => JSON.stringify(field)))
		).map((field) => JSON.parse(field)) as TParsedFieldsArea;

		return populateInitialFieldOptions(deduplicatedFieldsArea);
	}
}
