import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import standardFormat from 'Models/dates/standardFormat';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import DatePicker from 'UI/inputs/DatePicker';
import TextArea from 'UI/inputs/TextArea';
import Box from 'UI/layout/Box';

import useManageHistory from 'Hooks/useManageHistory';
import getSeasonParams from 'Utils/getSeasonParams';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../state';

const DateAndCommentStep = () => {
	const { t } = useTranslation();
	const jobInput = useJobInputData();
	const status = useAddJobModalStatus();
	const { paramsFromURL } = useManageHistory();
	const season = getSeasonParams(paramsFromURL.filters);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleDateInputClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCommentInput = (comment: string): void => {
		addJobModalState({
			status,
			jobInputData: { ...jobInput, comment },
		});
	};

	const handleDateInput = (date: Date | null): void => {
		if (date) {
			addJobModalState({
				status,
				jobInputData: { ...jobInput, dueDate: standardFormat(date) },
			});
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			<Box mb="2rem">
				<Text fontSize="0.875rem" fontWeight="bold">
					{t('common.date')}
				</Text>
				<DatePicker.Controlled
					inputProps={{
						fullWidth: true,
						sx: {
							height: '2.75rem',
							'& .MuiOutlinedInput-root': {
								borderRadius: '0.375rem',
								'&.Mui-focused fieldset': {
									borderWidth: '3px',
								},
							},
						},
						onClick: handleDateInputClick,
					}}
					disablePast
					minDate={new Date(season.from)}
					maxDate={new Date(season.to)}
					views={['month', 'day']}
					popperProps={{
						placement: 'bottom-start',
						anchorEl: anchorEl,
					}}
					value={jobInput.dueDate ? new Date(jobInput.dueDate) : null}
					onChange={handleDateInput}
					format="dd.MM.yyyy"
					dense
				/>
			</Box>
			<Divider />
			<Box mt="1rem">
				<Text fontSize="0.875rem" fontWeight="bold">
					{t('common.comment')}
				</Text>
				<TextArea
					onChange={handleCommentInput}
					rows={3}
					placeholder={t('common.addOptionalComment')}
					value={jobInput.comment || ''}
					inputProps={{
						sx: {
							borderRadius: '0.375rem',
							p: 0,
							'&&.Mui-focused > fieldset': { borderWidth: '3px' },
						},
					}}
				/>
			</Box>
		</Box>
	);
};

export default DateAndCommentStep;
