const FIRST_ROW_INDEX = 0;
const SECOND_ROW_INDEX = 1;
const HEADER_HEIGHT = 2;
const TEAMS_COLUMN_START_INDEX = 8;
const TEAMS_COLUMN_END_INDEX = 9;
const COLUMNS_IN_REPORT = 12;

export {
	FIRST_ROW_INDEX,
	SECOND_ROW_INDEX,
	HEADER_HEIGHT,
	TEAMS_COLUMN_START_INDEX,
	TEAMS_COLUMN_END_INDEX,
	COLUMNS_IN_REPORT,
};
