import { TData } from '../types';

function findMatchingData(data: TData[], monthIndex: number, year: number) {
	return data.find((data) => {
		const parsedDate = new Date(data.x);
		const monthIndexFromDate = parsedDate.getMonth();
		const yearFromDate = parsedDate.getFullYear();

		return monthIndexFromDate === monthIndex && yearFromDate === year;
	});
}

export default findMatchingData;
