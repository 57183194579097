import DialogActions from './actions';
import DialogModal from './body';
import DialogContent from './content';
import DialogTitle from './title';
import UncontrolledModal from './uncontrolled';

const Export = {
	DialogModal,
	DialogTitle,
	DialogContent,
	DialogActions,
	UncontrolledModal,
};

export default Export;
