import isArray from 'vet/arrays/isArray';
import isOneOf from 'vet/isOneOf';

//
// Internal note
//
// The job treatment is assign as a jobType on the backend
//

const SPRAYING = 'SPRAYING';
const FERTILIZER = 'FERTILIZER';
const FERTIGATE = 'FERTIGATE';
const CUSTOM = 'CUSTOM';
const SCOUTING = 'SCOUTING';
const MULTI_LOCATION = 'MULTI_LOCATION';
const HARVEST = 'HARVEST';
const SOW_PLANT = 'SOW_PLANT';

const JobTypes = {
	SPRAYING,
	FERTILIZER,
	FERTIGATE,
	CUSTOM,
	SCOUTING,
	MULTI_LOCATION,
	HARVEST,
	SOW_PLANT,
} as const;

const ALL = Object.values(JobTypes).filter(
	(value): value is TJobType => typeof value === 'string'
);

export type TJobType = keyof typeof JobTypes;

const isJobType = isOneOf(...Object.values(JobTypes));

const isJobTypeArray = (array: string[] | undefined): array is TJobType[] => {
	if (isArray(array)) {
		return array.every((element) => isJobType(element));
	} else {
		return false;
	}
};

export default {
	SPRAYING,
	FERTILIZER,
	FERTIGATE,
	CUSTOM,
	SCOUTING,
	MULTI_LOCATION,
	HARVEST,
	SOW_PLANT,
	ALL,
	JobTypes,
};

export {
	SPRAYING,
	FERTILIZER,
	FERTIGATE,
	CUSTOM,
	SCOUTING,
	HARVEST,
	SOW_PLANT,
	ALL,
	JobTypes,
	isJobType,
	isJobTypeArray,
};
