import { TTranslateFunction } from 'Features/localization';
import { THarvestExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import { ExportType, TExportType } from 'Models/exports/ExportType';
import { THarvestLogsPage } from 'Models/harvest/isHarvestWithTeams';

import { getHarvestRowExportData, getHarvestExportHeaders } from './exportData';

function getExportData(
	harvests: THarvestLogsPage[],
	fileType: TExportType,
	dictionary: THarvestExportHeadersDictionary,
	t: TTranslateFunction,
	hasTimesheetsSubscription?: boolean
) {
	const headers = getHarvestExportHeaders(dictionary);
	const isCSV = fileType === ExportType.CSV;

	const exportData = harvests.map((harvest) =>
		getHarvestRowExportData(harvest, dictionary, t, hasTimesheetsSubscription)
	);

	return {
		// Headers made for merged rows.
		// Without the timesheets subscription we are not merging any rows,
		// so the result key's will become headers.
		exportData:
			hasTimesheetsSubscription && !isCSV
				? [headers, ...exportData]
				: exportData,
	};
}

export default getExportData;
