import { gql, TypedDocumentNode } from '@apollo/client';

import { TCreatePlannerJobTreatmentInput } from '../types';

const CREATE_PLANNER_JOB_TREATMENT: TypedDocumentNode<
	{
		createPlannerJobTreatment: { id: string };
	},
	TCreatePlannerJobTreatmentInput
> = gql`
	mutation createPlannerJobTreatment(
		$plannerJobId: ID!
		$input: JobTreatmentInput!
	) {
		createPlannerJobTreatment(plannerJobId: $plannerJobId, input: $input) {
			id
		}
	}
`;

export default CREATE_PLANNER_JOB_TREATMENT;
