import React from 'react';

import { ExportType } from 'Models/exports/ExportType';

import useExportType from 'Hooks/useExportType';

import ExportToFile from './components/ExportToFile';

function HoursExportPage() {
	const type = useExportType();

	if (type === ExportType.CSV || type === ExportType.XLSX) {
		return <ExportToFile type={type} />;
	}

	return null;
}

export default HoursExportPage;
