import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import InviteFarmsModal from '../../../../components/InviteFarmsModal';

type TPageContent = {
	companyName: string;
};

const PageBody = (props: TPageContent) => {
	const { companyName } = props;
	const { t } = useTranslation();
	return (
		<Box
			height="100%"
			alignItems="center"
			display="flex"
			flexDirection="column"
			justifyContent="center"
		>
			<Text variant="h1" sx={{ fontWeight: 'bold' }} marginBottom="1rem">
				{t('companyWelcomePage.welcome', {
					companyName: companyName,
				})}
			</Text>
			<Text marginBottom="1.5rem">
				{t('companyWelcomePage.inviteInformation')}
			</Text>
			<InviteFarmsModal redirectToDashboard />
		</Box>
	);
};

export default PageBody;
