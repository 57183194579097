import React from 'react';

import { useQuery } from '@apollo/client';
import { Chip as MUIChip } from '@mui/material';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { SPRAYING } from 'Models/jobs/JobTypes';
import Text from 'UI/display/Text';
import Tooltip from 'UI/display/Tooltip';
import CloseIcon from 'UI/icons/Close';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import GET_GOVERNMENT_CAUSES from '../../../api/getGovernmentCauses';
import GET_PARTNERSHIP_CAUSES from '../../../api/getPartnershipCauses';
import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../../state';
import AddCausesDropdown from './AddCausesDropdown';
import TooltipBase from './TooltipBase';

const AddCauses = () => {
	const { company } = usePartnerCompany();
	const { data: partnerData } = useQuery(GET_PARTNERSHIP_CAUSES);
	const { data: govData } = useQuery(GET_GOVERNMENT_CAUSES, {
		skip: !company?.country,
		variables: company?.country
			? {
					filterBy: {
						country: company?.country,
					},
			  }
			: undefined,
	});

	const { t } = useTranslation();
	const jobInput = useJobInputData();
	const status = useAddJobModalStatus();

	if (jobInput.jobDetails.jobType !== SPRAYING || (!govData && !partnerData)) {
		return null;
	}

	const causeIds = jobInput.jobDetails.causeIds || [];

	const handleDelete = (causeId: string) => {
		const newCauseIds = causeIds.filter(
			(appliedCause) => appliedCause !== causeId
		);

		if ('causeIds' in jobInput.jobDetails) {
			jobInput.jobDetails.causeIds = newCauseIds;
		}
		addJobModalState({
			status,
			jobInputData: { ...jobInput },
		});
	};

	const tooltipContent = (
		<Box padding="0.25rem">
			<Text variant="body2">{t('plannerPage.causes.helperText')}</Text>
		</Box>
	);

	const allCauses = [
		...(partnerData?.getPartnershipCauses.causes || []),
		...(govData?.governmentCauses.causes || []),
	];

	const appliedCauses = causeIds.length ? (
		<Box display="flex" flexWrap="wrap">
			{allCauses
				.filter((cause) => causeIds.includes(cause.id))
				.map((cause) => (
					<MUIChip
						label={cause.name}
						key={cause.id}
						onDelete={() => handleDelete(cause.id)}
						deleteIcon={
							<CloseIcon
								width={16}
								height={16}
								fill={FarmableColors.WHITE}
								onMouseDown={(event) => event.stopPropagation()}
							/>
						}
						sx={{
							marginTop: '0.5rem',
							marginRight: '0.5rem',
							color: FarmableColors.WHITE,
							backgroundColor: FarmableColors.ORANGE,
						}}
					/>
				))}
		</Box>
	) : null;

	return (
		<>
			<Box display="flex">
				<Text variant="label">{t('labels.cause')}</Text>
				<Tooltip
					arrow
					placement="top"
					title={tooltipContent}
					arrowColor={FarmableColors.LIGHT_GREY}
					backgroundColor={FarmableColors.LIGHT_GREY}
					boxShadow
				>
					<TooltipBase />
				</Tooltip>
			</Box>
			{appliedCauses}
			<Box marginTop="0.75rem" marginBottom="1.5rem">
				<AddCausesDropdown causes={allCauses} />
			</Box>
		</>
	);
};

export default AddCauses;
