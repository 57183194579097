import { ApolloError } from '@apollo/client';

export const errorTypes = {
	NETWORK_ERROR: 'NETWORK_ERROR',
	SERVER_ERROR: 'SERVER_ERROR',
	FORBIDDEN: 'FORBIDDEN',
	UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
	AUTH_ERROR: 'AUTH_ERROR',
} as const;

export type TErrorType = keyof typeof errorTypes;

const getErrorType = (error: Error | ApolloError): TErrorType => {
	if (error instanceof ApolloError) {
		if (error.networkError) {
			if (
				'statusCode' in error.networkError &&
				error.networkError.statusCode === 401
			) {
				return errorTypes.AUTH_ERROR;
			}
			if (error.message.includes('Forbidden')) {
				return errorTypes.FORBIDDEN;
			}
			return errorTypes.NETWORK_ERROR;
		}
		if (error.protocolErrors || error.graphQLErrors) {
			return errorTypes.SERVER_ERROR;
		}
	}

	if (error.message.includes('unauthorized')) {
		return errorTypes.AUTH_ERROR;
	}

	return errorTypes.UNEXPECTED_ERROR;
};

export default getErrorType;
