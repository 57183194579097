import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getDateRepresentationFromString from 'Models/dates/getDateRepresentationFromString';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import ErrorBoundary from '../../../components/ErrorBoundary';
import NoLogsStatement from '../common/components/NoLogsStatement';
import { PartnerSalesLogsTableBodyValues } from './PartnerSalesLogs.constants';
import {
	useGetPartnerSalesData,
	useGetPartnerSalesRowData,
} from './PartnerSalesLogs.hooks';
import { PartnerSalesLogsTableHeader } from './PartnerSalesLogsTableHeader';

export const PartnerSalesLogsTable = () => {
	const manageHistory = useManageHistory();
	const { t } = useTranslation();
	const searchParams = manageHistory.paramsFromURL;

	const { data, loading } = useGetPartnerSalesData({
		variables: {
			filterBy: {
				saleFilters: {
					dateFrom: getDateRepresentationFromString(
						searchParams.filters.completedDateFrom!
					),
					dateTo: getDateRepresentationFromString(
						searchParams.filters.completedDateTo!
					),
					customerId: searchParams.filters.customerId!,
					varieties: searchParams.filters.varieties!,
				},
				locationIds: searchParams.filters.locations!,
			},
			pageInput: searchParams.pageInput,
		},
		fetchPolicy: 'network-only',
	});
	const tableData = useGetPartnerSalesRowData(data?.getPartnerSales.content, t);

	if (loading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	return (
		<Table.Container style={{ overflow: 'auto', flex: 1 }}>
			<Table.Table stickyHeader aria-label="Partner Sales logs table">
				<PartnerSalesLogsTableHeader />
				<ErrorBoundary withinComponent>
					{tableData?.length ? (
						<Table.Body>
							{tableData?.map((row, idx) => (
								<Table.Row hover key={idx}>
									{row.map((cell, index) => (
										<Table.Cell
											key={`cell-${
												PartnerSalesLogsTableBodyValues[index] || index
											}-${index}`}
										>
											{cell}
										</Table.Cell>
									))}
								</Table.Row>
							))}
						</Table.Body>
					) : (
						<NoDataTableContainer>
							<NoLogsStatement logsType="Sales" />
						</NoDataTableContainer>
					)}
				</ErrorBoundary>
			</Table.Table>
		</Table.Container>
	);
};
