import React, { ReactElement, useState, MouseEvent } from 'react';

import customerAddressToDisplayString from 'Models/customers/customerAddressToDisplayString';
import { TCustomer } from 'Models/customers/isCustomer';
import Avatar from 'UI/display/Avatar';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import MoreIcon from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { FarmableTextColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import DeleteCustomerModal from './DeleteCustomerModal';
import UpdateCustomerModal from './UpdateCustomerModal';

type TCustomerItemProps = {
	customer: TCustomer;
};

const useStyles = makeStyles({
	dividerRoot: {
		margin: '0.5rem 0',
	},
});

const CustomerItem = (props: TCustomerItemProps): ReactElement => {
	const { customer } = props;
	const { dividerRoot } = useStyles();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const menuOpen = Boolean(anchorEl);

	function handleMenuClick(event: MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleMenuClose() {
		setAnchorEl(null);
	}

	const settings = (
		<>
			<IconButton aria-label="Settings" onClick={handleMenuClick} size="medium">
				<MoreIcon fill={FarmableTextColors.SECONDARY} />
			</IconButton>
			<Menu.Menu
				anchorEl={anchorEl}
				keepMounted
				open={menuOpen}
				onClose={handleMenuClose}
			>
				<div onClick={() => handleMenuClose()}>
					<UpdateCustomerModal customer={customer} />
				</div>
				<Divider className={dividerRoot} />
				<div onClick={() => handleMenuClose()}>
					<DeleteCustomerModal customer={customer} />
				</div>
			</Menu.Menu>
		</>
	);

	return (
		<>
			<Box
				marginY="0.5rem"
				marginLeft="1.5rem"
				marginRight="1rem"
				display="flex"
				justifyContent="space-between"
			>
				<Box display="flex">
					<Avatar alt={customer.name} src={customer.image?.path} />
					<Box display="flex" flexDirection="column" marginLeft="1rem">
						<Text>{customer.name}</Text>
						<Text variant="secondaryBody2">
							{customerAddressToDisplayString(customer)}
						</Text>
					</Box>
				</Box>
				{settings}
			</Box>
			<Divider sx={{ margin: '0 1.5rem' }} />
		</>
	);
};

export default CustomerItem;
