import { TModule } from 'Models/stripe/isStripeModule';

import throwErrorSilently from 'Utils/throwErrorSilently';

import { getConfigValue } from '../../../Firebase';

type TStripeModule = Record<string, TModule>;

export default function getStripeModules() {
	//temporary workaround for issue within cypress tests #3554
	try {
		const stripeModules = getConfigValue('STRIPE_MODULES').asString();
		const parsedStripeModules = JSON.parse(stripeModules) as TStripeModule;

		return parsedStripeModules;
	} catch (e) {
		if (e instanceof Error) {
			throwErrorSilently(e);
		}
		return {};
	}
}
