import React, { FC, useCallback, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductTreatment } from 'Models/products/ProductTreatment';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import MoreIcon from 'UI/icons/More';
import Button from 'UI/inputs/Button';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { FarmableColors } from 'UI/theme/Colors';

interface ProductMenuTreatmentButtonProps {
	treatment: TProductTreatment;
	isMenuOpened: boolean;
	isOneTreatmentAdded: boolean;
	toggleMenu: (id: string) => void;
	onDeleteTreatment: (treatmentId: string) => void;
	onEditTreatment: (treatment: TProductTreatment) => void;
}

export const ProductTreatmentMenuButton: FC<ProductMenuTreatmentButtonProps> =
	({
		treatment,
		isMenuOpened = false,
		isOneTreatmentAdded = false,
		toggleMenu,
		onDeleteTreatment,
		onEditTreatment,
	}) => {
		const { t } = useTranslation();
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
		const [isDeleteTreatmentModalOpened, setDeleteTreatmentModalOpened] =
			useState(false);
		const isGovTreatment = !!treatment.governmentTreatmentId;
		const shouldShowDelete = !isGovTreatment && !isOneTreatmentAdded;

		const handleClick = useCallback(
			(event: React.MouseEvent<HTMLElement>) => {
				event.preventDefault();
				event.stopPropagation();
				toggleMenu(treatment.id);
				setAnchorEl(event.currentTarget);
			},
			[toggleMenu, treatment.id]
		);

		const handleClose = useCallback(
			(event: React.MouseEvent<HTMLElement>) => {
				event.preventDefault();
				event.stopPropagation();
				toggleMenu(treatment.id);
				setAnchorEl(null);
			},
			[toggleMenu, treatment.id]
		);

		const handleToggleDeleteModal = useCallback(
			(event?: React.SyntheticEvent<Element, Event>) => {
				event?.preventDefault();
				event?.stopPropagation();
				setDeleteTreatmentModalOpened((prevState) => !prevState);
			},
			[]
		);

		const handleEditProductClick = useCallback(
			(event: React.MouseEvent<HTMLElement>) => {
				event.preventDefault();
				event.stopPropagation();
				onEditTreatment(treatment);
			},
			[onEditTreatment, treatment]
		);

		return (
			<>
				<IconButton size="medium" sx={{ margin: 'auto' }} onClick={handleClick}>
					<MoreIcon />
				</IconButton>
				<Menu.Menu
					id="library-settings-menu"
					open={isMenuOpened}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						horizontal: 'right',
						vertical: 'bottom',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<Menu.Item onClick={handleEditProductClick}>
						{t('buttons.editProductLibraryTreatment')}
					</Menu.Item>
					{shouldShowDelete && <Divider />}
					{shouldShowDelete && (
						<Menu.Item
							sx={{ color: FarmableColors.RED }}
							onClick={handleToggleDeleteModal}
						>
							{t('buttons.deleteProductLibraryTreatment')}
						</Menu.Item>
					)}
				</Menu.Menu>
				<Modal.UncontrolledModal
					open={isDeleteTreatmentModalOpened}
					toggleOpen={handleToggleDeleteModal}
				>
					<Modal.DialogTitle
						title={t('treatmentLibrary.deleteTreatmentModal.title')}
						closeIconHandler={handleToggleDeleteModal}
					/>
					<Modal.DialogContent>
						<Text>{t('treatmentLibrary.deleteTreatmentModal.message')}</Text>
					</Modal.DialogContent>
					<Modal.DialogActions>
						<Box display="flex" justifyContent="space-between" width="100%">
							<Button variant="text" onClick={handleToggleDeleteModal}>
								{t('buttons.back')}
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									onDeleteTreatment(treatment.id);
									toggleMenu(treatment.id);
									setDeleteTreatmentModalOpened(false);
								}}
							>
								{t('buttons.delete')}
							</Button>
						</Box>
					</Modal.DialogActions>
				</Modal.UncontrolledModal>
			</>
		);
	};
