import _uniq from 'lodash/uniq';

import { TFieldVariety } from '../fields/isFieldVariety';

function getJobFieldVarieties(field: {
	fieldVarieties?: Pick<TFieldVariety, 'name' | 'category'>[] | null;
}): string {
	const { fieldVarieties } = field;
	return fieldVarieties
		? _uniq(fieldVarieties.map((variety) => variety.name)).join(', ')
		: '-';
}

export default getJobFieldVarieties;
