import {
	TReportFormat,
	TReportParams,
	TReportType,
} from 'Models/exports/Report';

import downloadBlob from './downloadBlob';

async function generateReport({
	request,
	reportName,
	locationID,
	reportType,
	reportFormat,
	accessToken,
}: {
	request: TReportParams;
	reportName: string;
	locationID: string;
	reportType: TReportType;
	reportFormat: TReportFormat;
	accessToken: string;
}) {
	const response = await fetch(
		`${process.env.API_HOST}/location/${locationID}/report/${reportType}/${reportFormat}`,
		{
			method: 'POST',
			headers: {
				authorization: accessToken,
				'content-type': 'application/json',
			},
			body: JSON.stringify(request),
		}
	);

	if (!response.ok) {
		throw new Error(`${response.status} ${response.statusText}`);
	}

	const blob = await response.blob();
	downloadBlob(blob, reportName);
}

export default generateReport;
