import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';

import usePermission from 'Hooks/usePermission';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';

import NavbarLink from '../../NavbarLink';

const LocationNavigationItems = () => {
	const locationId = getLocationIdFromUrl();
	const { t } = useTranslation();

	const hasStoreViewPermission = usePermission(Permissions.STORE_PAGE_VIEW);

	const hasNavigationPermission = usePermission(Permissions.WEB_PORTAL_ACCESS);

	const hasFarmSalesPagePermission = usePermission(
		Permissions.FARM_SALES_PAGE_ACCESS
	);
	const hasDashboardPagePermission = usePermission(
		Permissions.DASHBOARD_PAGE_ACCESS
	);

	const hasValidSalesSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.SALE
	);

	const logsLink = (
		<NavbarLink
			label={t('common.navbar.logs')}
			link={`/locations/${locationId}/logs/jobs`}
			checkActiveHandler={(_, location) => location.pathname.includes('logs')}
		/>
	);

	const farmSalesLink =
		hasFarmSalesPagePermission && hasValidSalesSubscription ? (
			<NavbarLink
				label={t('common.navbar.farmSales')}
				link={`/locations/${locationId}/farm-sales`}
				checkActiveHandler={(_, location) =>
					location.pathname.includes('farm-sales')
				}
			/>
		) : null;
	const storeLink = hasStoreViewPermission ? (
		<NavbarLink
			label={t('common.navbar.store')}
			link={`/locations/${locationId}/store`}
			checkActiveHandler={(_, location) => location.pathname.includes('store')}
		/>
	) : null;

	const dashboardLink = hasDashboardPagePermission ? (
		<NavbarLink
			label={t('common.navbar.dashboard')}
			link={`/locations/${locationId}/dashboard`}
			checkActiveHandler={(_, location) =>
				location.pathname.includes('dashboard')
			}
		/>
	) : null;

	return hasNavigationPermission ? (
		<>
			{dashboardLink}
			{logsLink}
			{farmSalesLink}
			{storeLink}
		</>
	) : null;
};

export default LocationNavigationItems;
