import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Permissions from 'Models/permissions/Permissions';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import usePermission from 'Hooks/usePermission';

import DeleteModal from './DeleteModal';
import { TransferOwnershipModal } from './TransferOwnershipModal';

const SettingsForm = (): ReactElement => {
	const hasDeleteFarmPermission = usePermission(Permissions.DELETE_FARM);
	const { t } = useTranslation();

	const validNotification = hasDeleteFarmPermission ? (
		t('farmSettingsPage.settings.deleteWarning')
	) : (
		<p>{t('farmSettingsPage.settings.delete.invalidRoleWarning')}</p>
	);

	return (
		<Card sx={{ marginTop: '1.5rem' }}>
			<CardContent>
				<Text variant="h3" marginBottom="1rem">
					{t('farmSettingsPage.settings.title')}
				</Text>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Text>{t('buttons.transferOwnership')}</Text>
						<Text variant="secondaryBody2">
							{t('farmSettingsPage.transferOwnership.description')}
						</Text>
					</Grid>
					<Grid item textAlign="right">
						<TransferOwnershipModal />
					</Grid>
				</Grid>
				<Divider sx={{ marginY: '1rem' }} />
				<Grid container justifyContent="space-between">
					<Grid item>
						<Text>{t('buttons.deleteFarm')}</Text>
						<Text variant="secondaryBody2">{validNotification}</Text>
					</Grid>
					<Grid item textAlign="right">
						<DeleteModal />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default SettingsForm;
