import { TypedDocumentNode, gql } from '@apollo/client';

type TDeactivatePartnerLocationRequest = {
	locationId: string;
};

type TDeactivatePartnerLocationResponse = {
	deactivatePartnerLocation: boolean;
};

const DEACTIVATE_PARTNER_LOCATION: TypedDocumentNode<
	TDeactivatePartnerLocationResponse,
	TDeactivatePartnerLocationRequest
> = gql`
	mutation deactivatePartnerLocation($locationId: ID!) {
		deactivatePartnerLocation(locationId: $locationId)
	}
`;

export default DEACTIVATE_PARTNER_LOCATION;
