import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import DropdownContent from '../DropdownContent';
import GET_PARTNER_FIELDS from '../common/api/getPartnerFields';
import getCategoriesFromFields from './utils/getCategoriesFromFields';

type TPartnerCategoriesProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const PartnerCategories = (props: TPartnerCategoriesProps) => {
	const { updateFilters, filters } = props;
	const { data, loading, error } = useQuery(GET_PARTNER_FIELDS);
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const appliedCategories = filters.fieldCategories || [];

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const dropdownContentData = getCategoriesFromFields(
		t,
		data?.getPartnerFields
	);

	if (error) {
		throw new ApolloError(error);
	}

	const onItemClick = (category: string, active: boolean) => {
		const newCategories = active
			? appliedCategories.filter(
					(appliedCategory) => appliedCategory !== category
			  )
			: [...appliedCategories, category];

		updateFilters({
			...filters,
			fieldCategories: newCategories,
		});
	};

	const onClear = () => {
		updateFilters({
			...filters,
			fieldCategories: undefined,
		});
	};

	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
				sx={{ marginRight: '0.5rem' }}
			>
				{t('buttons.category')}
				{appliedCategories?.length ? `(${appliedCategories?.length})` : ''}
			</Button>
			<Menu.Menu
				id="category-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={loading}
					dropdownItems={dropdownContentData}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={appliedCategories}
				/>
			</Menu.Menu>
		</>
	);
};

export default PartnerCategories;
