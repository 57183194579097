import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import {
	TMovementType,
	ALL as MOVEMENT_TYPES,
} from 'Models/inventory/ProductInventoryLog';
import Chip from 'UI/display/Chip';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

type TStatusesProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

export default function MovementTypes(props: TStatusesProps) {
	const { t } = useTranslation();
	const { updateFilters, filters } = props;
	const movementTypes = filters?.movementTypes || [];

	const onChipClick = (movementType: TMovementType) => {
		const active = movementTypes.includes(movementType);
		const newMovementType = active
			? movementTypes.filter((s) => s !== movementType)
			: [...movementTypes, movementType];

		updateFilters({
			...filters,
			movementTypes: newMovementType,
		});
	};

	return (
		<Grid container spacing={1}>
			{MOVEMENT_TYPES.map((movementType: TMovementType, i) => {
				const active = movementTypes.includes(movementType);

				return (
					<Grid item key={`${movementType}-${i}`}>
						<Chip
							chipColor={active ? FarmableColors.ORANGE : undefined}
							label={t(`movementType.${movementType}`)}
							onClick={() => onChipClick(movementType)}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
}
