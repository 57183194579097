import { Alpha2Code } from 'i18n-iso-countries';

import { TImage } from '../image/isImage';

export enum PartnershipCompanyType {
	ADVISOR = 'ADVISOR',
	MANAGER = 'MANAGER',
}

export type TPartnershipCompany = {
	id: string;
	name: string;
	type: PartnershipCompanyType.ADVISOR | PartnershipCompanyType.MANAGER;
	country: Alpha2Code;
	city: string | null;
	address: string | null;
	zip: string | null;
	email: string | null;
	mobilePhone: string | null;
	image: TImage | null;
	createdAt: string;
};
