import { isBasicWeather } from '../weather';
import getPrecipitationString from '../weather/getPrecipitationString';
import getTemperature from '../weather/getTemperature';
import getWindSpeedString from '../weather/getWindSpeedString';
import { TJob } from './isJob';

function jobWeather(job: TJob, isImperial: boolean): string {
	const weather = isBasicWeather(job.weather) ? job.weather : null;

	const { temp, windSpeed, rain } = weather || {};

	const temperature = temp ? getTemperature(temp, isImperial) : null;
	const windSpeedString = getWindSpeedString(windSpeed);

	const precipitationString = getPrecipitationString(rain);
	const temperatureValue = temperature ? temperature.tempValue : 'N/A';

	return `${temperatureValue} - ${windSpeedString} - ${precipitationString}`;
}

export default jobWeather;
