import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TMember } from 'Models/profile/isMember';
import translateRoles from 'Models/roles/translateRoles';
import Avatar from 'UI/display/Avatar';
import Divider from 'UI/display/Divider';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import MoreIcon from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Menu from 'UI/navigation/Menu';

import styles from './styles.module.scss';

const Member = (props: TMember & { isYou: boolean }) => {
	const { firstName, lastName, email } = props.account;
	const { t } = useTranslation();
	const role = translateRoles(props.accountRole, t);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const memberDropdown = (
		<>
			<IconButton size="medium" onClick={handleClick}>
				<MoreIcon className={styles.noPointerEvents} />
			</IconButton>
			<Menu.Menu
				id="member-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Menu.Item>{t('membersPage.member.editInfo')}</Menu.Item>
				<Divider sx={{ marginY: '0.5rem' }} />
				<Menu.Item>{t('membersPage.member.changeToManager')}</Menu.Item>
				<Menu.Item>{t('membersPage.member.changeToAdvisor')}</Menu.Item>
				<Divider sx={{ marginY: '0.5rem' }} />
				<Menu.Item className="has-text-danger">
					{t('membersPage.member.deactivate')}
				</Menu.Item>
			</Menu.Menu>
		</>
	);

	return (
		<>
			<List.Item
				sx={{ padding: '0.5rem 0' }}
				// not implemented yet
				// secondaryAction={memberDropdown}
			>
				<List.ItemAvatar>
					<Avatar
						alt={props.account.firstName || undefined}
						src={props.account.profileImage?.path}
					/>
				</List.ItemAvatar>
				<List.ItemText
					primary={
						firstName &&
						lastName && (
							<Text>
								{firstName} {lastName} {props.isYou}
							</Text>
						)
					}
					secondary={
						<>
							<Text variant="body2" component="span">
								{role}
							</Text>
							<Text variant="secondaryBody2" component="span">
								&mdash; {email}
							</Text>
						</>
					}
				/>
			</List.Item>
			<Divider
				sx={{
					'&:last-child': {
						display: 'none',
					},
				}}
			/>
		</>
	);
};

export default Member;
