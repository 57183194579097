import getEndOfTheDayISO from 'Models/dates/getEndOfTheDayISO';
import getStartOfTheDayISO from 'Models/dates/getStartOfTheDayISO';
import { URLFiltersType } from 'Models/history/isURLFilters';
import { TOrderStatus } from 'Models/history/isURLOrderStatus';
import { URLParamsType } from 'Models/history/isURLParams';
import QS from 'qs';
import URI from 'urijs';

import locationIDFromURL from './locationIDFromURL';
import pageInputFromQuery from './pageInputFromQuery';

const filtersFromQuery = (query: any) => {
	const {
		sts,
		jt,
		cjt,
		cat,
		cdf,
		cdt,
		tf,
		tt,
		fc,
		fi,
		ti,
		fv,
		df,
		dt,
		cid,
		osb,
		vs,
		sn,
		ls,
		pvc,
		pvn,
		mt,
		s,
		tm,
		tl,
	} = query;

	const jobStatuses = sts;
	const jobTypes = jt;
	const customJobTypeIds = cjt;
	const categories = cat;
	const completedDateFrom = getStartOfTheDayISO(cdf);
	const completedDateTo = getEndOfTheDayISO(cdt);
	const timeFrom = getStartOfTheDayISO(tf);
	const timeTo = getEndOfTheDayISO(tt);
	const teamId = ti;
	const fieldCategories = fc;
	const fieldIds = fi;
	const fieldVarieties = fv;
	const dateFrom = df;
	const dateTo = dt;
	const customerId = cid;
	const onlySoldBatches = osb ? JSON.parse(osb) : undefined;
	const varieties = vs;
	const season = sn;
	const locations = ls;
	const partnerVarietyCategory = pvc;
	const partnerVarietyName = pvn;
	const movementTypes = mt;
	const search = s;
	const teamMemberIds = tm;
	const teamLeadIds = tl;

	const params: URLFiltersType = {
		jobStatuses,
		jobTypes,
		customJobTypeIds,
		categories,
		completedDateFrom,
		completedDateTo,
		timeFrom,
		timeTo,
		teamId,
		fieldCategories,
		fieldIds,
		fieldVarieties,
		dateFrom,
		dateTo,
		customerId,
		onlySoldBatches,
		varieties,
		season,
		locations,
		partnerVarietyCategory,
		partnerVarietyName,
		movementTypes,
		search,
		teamMemberIds,
		teamLeadIds,
	};

	return params;
};

function orderStatusFromQuery(query: any): TOrderStatus {
	const { os } = query;
	return os;
}

function isDemoReportFromQuery(query: any): boolean {
	const { dr } = query;
	return dr;
}

const paramsFromURL = (url: string): URLParamsType => {
	const parsed = URI(url);
	// The default array limit is 20 -
	// we need to change it to get the proper filters format from the URL #1781
	const query = QS.parse(parsed.query(), { arrayLimit: 2000 });

	return {
		locationID: locationIDFromURL(url),
		filters: filtersFromQuery(query),
		pageInput: pageInputFromQuery(query),
		orderStatus: orderStatusFromQuery(query),
		demoReport: isDemoReportFromQuery(query),
	};
};

export default paramsFromURL;
