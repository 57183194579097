import React, { ReactElement, useRef, FormEvent } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import { fieldImportsPageState } from '../../../../state';
import loadFieldsFromFiles from '../../../../utils/loadFieldsFromFiles';
import styles from './styles.module.scss';

const UploadGeoJSON = (): ReactElement => {
	const { t } = useTranslation();
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleChange = (event: FormEvent<HTMLInputElement>) => {
		const target = event.currentTarget;
		if (target.files?.length) {
			loadFieldsFromFiles(target.files, t)
				.then((fields) => {
					fieldImportsPageState({
						status: 'ADD_FIELDS_SUCCESS',
						fieldsInput: fields.map((field) => {
							return {
								...field,
								name: field.name || '',
							};
						}),
					});
				})
				.catch((e: unknown) => {
					fieldImportsPageState({
						status: 'ADD_FIELDS_FAILURE',
						error: e,
					});
				});
		}
	};

	function handleClick(): void {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	}

	return (
		<Box textAlign="center">
			<section>
				<input
					accept=".geojson"
					multiple
					type="file"
					onChange={handleChange}
					ref={fileInputRef}
					className={styles.fileInput}
				/>
				<Button onClick={handleClick}>
					{t('importPages.fieldImportsPage.uploadFiles')}
				</Button>
			</section>
		</Box>
	);
};

export default UploadGeoJSON;
