import formatISO from 'date-fns/formatISO';

import { TFieldVarietyInput } from '../../fields/isFieldVarietyInput';

const EMPTY_VARIETY: TFieldVarietyInput = {
	_id: '',
	name: '',
	category: '',
	percentage: null,
	identifier: undefined,
	rows: undefined,
	width: undefined,
	length: undefined,
	sowDate: formatISO(new Date()),
};

export default EMPTY_VARIETY;
