import React from 'react';

import TemperatureChart from '../../../common/components/TemperatureChart';
import {
	// mockMilestonesPredictions,
	mockMilestones,
	mockFieldWeatherDaily,
	// mockFieldWeatherDailyPredictions,
} from '../../../common/components/TemperatureChart/mocks/mockChartData';

const TemperatureChartSection = () => {
	return (
		<TemperatureChart
			fieldWeather={mockFieldWeatherDaily}
			milestones={mockMilestones}
			// Uncomment to enable predictions.
			// fieldWeatherPredictions={mockFieldWeatherDailyPredictions}
			// milestonesPredictions={mockMilestonesPredictions}
		/>
	);
};

export default TemperatureChartSection;
