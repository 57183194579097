import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ProfilePageTemplate from '../../common/components/ProfilePageTemplate';
import ImportsPageValidator from './components/ImportsPageValidator';
import PageBody from './components/PageBody';
import PageLayout from './components/PageLayout';

function FieldImportsPage() {
	const { t } = useTranslation();

	return (
		<ImportsPageValidator>
			<ProfilePageTemplate
				header={t('importPages.fieldImportsPage.header')}
				subheader={t('importPages.fieldImportsPage.subheader')}
			>
				<PageLayout>
					<PageBody />
				</PageLayout>
			</ProfilePageTemplate>
		</ImportsPageValidator>
	);
}

export default FieldImportsPage;
