import React from 'react';

import TableSortLabel from '@mui/material/TableSortLabel';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import TPageInput, { TDirection } from 'Models/api/PageInput';
import HarvestLogsTableHeaderValues from 'Models/harvest/harvestLogsTableData';
import Table from 'UI/display/Table';

import useManageHistory from 'Hooks/useManageHistory';

type TTableOrder = 'asc' | 'desc';

const SORTABLE_COLUMNS = ['date'];

const parseOrder = (order: TDirection) => order.toLowerCase() as TTableOrder;

const TableHeaders = () => {
	const { t } = useTranslation();
	const { paramsFromURL: searchParams, historyPush } = useManageHistory();

	const pageInput = searchParams.pageInput;

	const orderBy = pageInput.sortBy[0].field;
	const order = pageInput.sortBy[0].direction;

	const sortHandler = (property) => {
		const direction = orderBy === property && order === 'ASC' ? 'DESC' : 'ASC';
		const sortBy: TPageInput['sortBy'] = [
			{ field: orderBy, direction: direction },
		];

		historyPush({
			...searchParams,
			pageInput: { ...pageInput, offset: 0, sortBy },
		});
	};

	const headers = HarvestLogsTableHeaderValues.map((header) => {
		const headerLabel = t(`logsPages.harvestLogsPage.tableHeaders.${header}`);

		const content = SORTABLE_COLUMNS.includes(header) ? (
			<TableSortLabel
				active={orderBy === header}
				direction={orderBy === header ? parseOrder(order) : 'asc'}
				onClick={() => sortHandler(header)}
			>
				{headerLabel}
			</TableSortLabel>
		) : (
			headerLabel
		);

		return (
			<Table.Cell
				key={header}
				sortDirection={orderBy === header ? parseOrder(order) : undefined}
			>
				{content}
			</Table.Cell>
		);
	});

	return (
		<Table.Head>
			<Table.Row>{headers}</Table.Row>
		</Table.Head>
	);
};

export default TableHeaders;
