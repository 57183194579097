import React from 'react';

import { TEmployeesLog } from 'Models/employees/EmployeesLog';
import EmployeesLogsTableHeaderValues from 'Models/employees/employeesTableHeaders';
import Table from 'UI/display/Table';

import getEmployeesLogsRowData from '../../../utils/getEmployeesLogsRowData';

type TEmployeesLogsTableRowProps = {
	log: TEmployeesLog;
};

const TableRow = (props: TEmployeesLogsTableRowProps) => {
	const { log } = props;

	return (
		<Table.Row hover>
			{getEmployeesLogsRowData(log).map((cell, index) => (
				<Table.Cell key={`cell-${EmployeesLogsTableHeaderValues[index]}`}>
					{cell}
				</Table.Cell>
			))}
		</Table.Row>
	);
};

export default TableRow;
