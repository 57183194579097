import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

import { toDisplayString } from './transpileUnits';

export default function (unit: string): string {
	if (unit) {
		return `${stringToFirstUppercase(unit)} (${toDisplayString(unit)})`;
	}

	return '-';
}
