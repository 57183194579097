import { TTranslateFunction } from '../../features/localization';
import { isRole, TRoles } from './isRole';

function translateRoles(
	roles: TRoles[] | string,
	translateFunction: TTranslateFunction
) {
	if (Array.isArray(roles) && roles.length) {
		return roles
			.map((role: TRoles) => translateFunction(`roles.${role}`))
			.join(', ');
	} else if (isRole(roles)) {
		return translateFunction(`roles.${roles}`);
	} else if (typeof roles === 'string') {
		return roles
			.split(', ')
			.map((role) => (isRole(role) ? translateFunction(`roles.${role}`) : role))
			.join(', ');
	}

	return '';
}

export default translateRoles;
