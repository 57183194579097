import React, { ReactElement } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import translateRoles from 'Models/roles/translateRoles';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useProfile from 'Hooks/useProfile';
import useProfileRole from 'Hooks/useProfileRole';

import ProfileDropdown from './ProfileDropdown';

const UserProfileDropdown = (): ReactElement => {
	const { t } = useTranslation();
	const { profile } = useProfile();
	const location = useCurrentLocation();
	const { logout } = useAuth0();
	const role = useProfileRole(location?.id);

	const locationName = location?.name || '';
	const formattedRoles = translateRoles(role, t);

	const rolesString =
		role.length && location
			? `${formattedRoles} ${
					locationName ? t('common.navbar.at') : ''
			  } ${locationName}`
			: '';
	const returnTo =
		process.env.AUTH0_LOGOUT_URI || window.location.origin + '/logout.html';

	const triggerLogout = () => {
		analyticsTrack(TrackEvents.SignedOut, { userId: profile?.id });
		void logout({ logoutParams: { returnTo } });
	};

	return (
		<ProfileDropdown
			profile={profile}
			profileDescription={rolesString}
			signOutCallback={triggerLogout}
		/>
	);
};

export default UserProfileDropdown;
