import { InMemoryCacheConfig } from '@apollo/client';

import mergePaginatedNestedValue from './utils/mergePaginatedNestedValue';
import readPaginatedNestedValue from './utils/readPaginatedNestedValue';

const cacheConfig: InMemoryCacheConfig = {
	typePolicies: {
		PaymentProduct: {
			keyFields: ['id', 'prices'],
		},
		Query: {
			fields: {
				getPartnershipNotifications: {
					merge(existing, incoming) {
						return existing
							? {
									...existing,
									notifications: existing.notifications.concat(
										incoming.notifications
									),
							  }
							: incoming;
					},
					keyArgs: ['filterBy'],
				},
				getNotifications: {
					merge(existing, incoming) {
						return existing
							? {
									...existing,
									notifications: existing.notifications.concat(
										incoming.notifications
									),
							  }
							: incoming;
					},
					keyArgs: ['filterBy', 'locationId'],
				},
				getPartnerJobs: {
					read: readPaginatedNestedValue('jobs'),
					merge: mergePaginatedNestedValue('jobs'),
					keyArgs: ['filterBy', 'pageInput', ['sortBy']],
				},
				getPartnerTimesheets: {
					read: readPaginatedNestedValue('timesheets'),
					merge: mergePaginatedNestedValue('timesheets'),
					keyArgs: ['filterBy'],
				},
				getPartnerHarvests: {
					read: readPaginatedNestedValue('harvests'),
					merge: mergePaginatedNestedValue('harvests'),
					keyArgs: ['filterBy'],
				},
				getTimesheets: {
					read: readPaginatedNestedValue('timesheets'),
					merge: mergePaginatedNestedValue('timesheets'),
					keyArgs: ['filterBy'],
				},
				getHarvest: {
					read: readPaginatedNestedValue('harvest'),
					merge: mergePaginatedNestedValue('harvest'),
					keyArgs: ['filterBy'],
				},
				getTeams: {
					merge(existing, incoming) {
						return incoming;
					},
				},
			},
		},
	},
};

export default cacheConfig;
