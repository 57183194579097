import React, { ReactElement } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import MUISwitch from '@mui/material/Switch';
import { SwitchProps } from '@mui/material/Switch/Switch';
import makeStyles from '@mui/styles/makeStyles';

export type TSwitchControlledProps = {
	checkedChangeHandler: (value: boolean) => void;
	label?: string;
} & SwitchProps;

const useStyles = makeStyles({
	root: {
		// Reduce padding added in the private MUI class
		marginRight: '-9px',
	},
});

const Controlled = (props: TSwitchControlledProps): ReactElement => {
	const {
		className,
		checked,
		checkedChangeHandler,
		disabled,
		label,
		classes,
		...MUIProps
	} = props;
	const styles = useStyles();

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		checkedChangeHandler(event.target.checked);
	}

	return label ? (
		<FormControlLabel
			label={label}
			disabled={disabled}
			control={
				<MUISwitch
					className={className}
					classes={classes || { root: styles.root }}
					checked={checked}
					onChange={handleChange}
					color={'primary'}
					{...MUIProps}
				/>
			}
		/>
	) : (
		<MUISwitch
			className={className}
			classes={{ root: styles.root }}
			checked={checked}
			onChange={handleChange}
			color={'primary'}
		/>
	);
};

export default Controlled;
