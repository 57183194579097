import { gql, TypedDocumentNode } from '@apollo/client';

export type TVolumeChartData = {
	totalVolume: number;
	date?: string | null;
};

type TGetVolumechartDataResponse = {
	getAggregatedHarvestBatches: TVolumeChartData[];
};

type TGetVolumechartDataRequest = {
	locationId: string;
	query: {
		filterBy?: {
			dateFrom: string;
			dateTo: string;
			varieties?: string[];
		};
		groupBy?: 'SEASON' | 'DATE';
	};
};

const GET_VOLUME_CHART_DATA: TypedDocumentNode<
	TGetVolumechartDataResponse,
	TGetVolumechartDataRequest
> = gql`
	query getVolumeChartData(
		$locationId: ID!
		$query: AggregatedHarvestBatchQuery
	) {
		getAggregatedHarvestBatches(locationId: $locationId, query: $query) {
			totalVolume
			date
		}
	}
`;

export default GET_VOLUME_CHART_DATA;
