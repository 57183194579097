import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import {
	TMovementType,
	TProductInventoryLog,
} from 'Models/inventory/ProductInventoryLog';

export type TInventoryMovementTableData = Pick<
	TProductInventoryLog,
	| 'date'
	| 'movementType'
	| 'product'
	| 'amountChange'
	| 'unit'
	| 'valueChange'
	| 'currency'
>;

const GET_INV_MOVEMENT_TABLE_DATA: TypedDocumentNode<
	{
		getProductInventoryLog: {
			totalPages: number;
			totalElements: number;
			log: TInventoryMovementTableData[];
		};
	},
	{
		locationId: string;
		pageInput: TPageInput;
		hasPagination: boolean;
		filterBy: {
			productName?: string;
			dateFrom?: Date;
			dateTo?: Date;
			movementTypes?: TMovementType[];
		};
	}
> = gql`
	query getProductInventoryTableData(
		$locationId: ID!
		$filterBy: ProductInventoryLogFilterInput
		$pageInput: PageInput!
	) {
		getProductInventoryLog(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			log {
				date
				movementType
				product {
					id
					name
				}
				unit
				amountChange
				valueChange
				currency
			}
		}
	}
`;

export default GET_INV_MOVEMENT_TABLE_DATA;
