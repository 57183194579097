import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import SuccessMessage from '../../../SuccessMessage';

type TCreateFieldsSuccessProps = {
	fieldsAmount: number;
};

function CreateFieldsSuccess(props: TCreateFieldsSuccessProps) {
	const { fieldsAmount } = props;

	const { t } = useTranslation();

	return (
		<SuccessMessage
			message={t('importPages.fieldImportsPage.createFields.successMessage', {
				count: fieldsAmount,
			})}
		/>
	);
}

export default CreateFieldsSuccess;
