import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import Modal from 'UI/display/modal/dialog';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';

import NavigatorButton from '../../../../../components/NavigatorButton';
import InviteAdmin from './components/InviteAdminModal';
import InviteMemberModal from './components/InviteMemberModal';

const InviteModal = () => {
	const [isModalOpen, setOpenModal] = useState(false);
	const [isInviteAdminModalOpen, setOpenInviteAdminModal] = useState(false);
	const [isInviteMembersModalOpen, setOpenInviteMembersModal] = useState(false);

	const { t } = useTranslation();

	const toggleOpen = () => {
		setOpenModal(!isModalOpen);
	};

	const openInviteAdminModal = () => {
		setOpenModal(false);
		setOpenInviteAdminModal(true);
	};

	const openInviteMembersModal = () => {
		setOpenModal(false);
		setOpenInviteMembersModal(true);
	};

	return (
		<>
			<InviteAdmin
				parentModalStateHandler={setOpenModal}
				modalStateHandler={setOpenInviteAdminModal}
				isModalOpen={isInviteAdminModalOpen}
			/>
			<InviteMemberModal
				parentModalStateHandler={setOpenModal}
				modalStateHandler={setOpenInviteMembersModal}
				isModalOpen={isInviteMembersModalOpen}
			/>
			<Button
				variant="secondary"
				color="primary"
				startIcon={<AddIcon />}
				onClick={toggleOpen}
			>
				{t('managePartnerMembers.inviteButton')}
			</Button>
			<Modal.UncontrolledModal toggleOpen={toggleOpen} open={isModalOpen}>
				<Modal.DialogTitle
					title={t('managePartnerMembers.inviteButton')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent noPadding sx={{ marginBottom: '1.5rem' }}>
					<NavigatorButton
						mainText={t('labels.admin')}
						helperText={t('managePartnerMembers.adminSubheader')}
						onClick={openInviteAdminModal}
					/>
					<Divider />
					<NavigatorButton
						mainText={t('labels.member')}
						helperText={t('managePartnerMembers.memberSubheader')}
						onClick={openInviteMembersModal}
					/>
				</Modal.DialogContent>
			</Modal.UncontrolledModal>
		</>
	);
};

export default InviteModal;
