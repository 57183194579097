import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import NoLogsStatement from '../../common/components/NoLogsStatement';
import GET_PARTNER_JOBS_TABLE_DATA from '../api/getPartnerJobsTableData';
import JobLogsTableRow from './TableRow';

const TableBody = () => {
	const manageHistory = useManageHistory();

	const searchParams = manageHistory.paramsFromURL;

	const { data, loading, error } = useQuery(GET_PARTNER_JOBS_TABLE_DATA, {
		variables: {
			filterBy: {
				jobFilters: {
					jobStatuses: searchParams.filters.jobStatuses,
					jobTypes: searchParams.filters.jobTypes,
					completedDateFrom: searchParams.filters.completedDateFrom,
					completedDateTo: searchParams.filters.completedDateTo,
					fieldCategories: searchParams.filters.fieldCategories,
					fieldIds: searchParams.filters.fieldIds,
				},
				locationIds: searchParams.filters.locations,
			},
			pageInput: searchParams.pageInput,
			hasPagination: true,
		},
	});

	if (loading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (!data?.getPartnerJobs.jobs?.length) {
		return (
			<NoDataTableContainer>
				<NoLogsStatement logsType="Jobs" />
			</NoDataTableContainer>
		);
	}

	return (
		<Table.Body>
			{data.getPartnerJobs.jobs.map((job) => (
				<JobLogsTableRow
					key={job.job.id}
					job={{ ...job.job, location: job.location }}
				/>
			))}
		</Table.Body>
	);
};

export default TableBody;
