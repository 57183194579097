import React, { FC, useRef, useState } from 'react';

import { ApolloError, useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { ProductType, TProductInput } from 'Models/products/ProductInput';
import Divider from 'UI/display/Divider';
import List from 'UI/display/List';
import Popover from 'UI/display/Popover';
import Text from 'UI/display/Text';
import CloseIcon from 'UI/icons/Close';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import IconButton from 'UI/inputs/IconButton';
import InputAdornment from 'UI/inputs/InputAdornment';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';
import { Alpha2Code } from 'i18n-iso-countries';

import useSpanishPartner from 'Hooks/useSpanishPartner';

import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../state';
import { TAddTreatmentsModalInput } from '../../../types';
import GET_GOVERNMENT_PRODUCTS from './api/getGovernmentProducts';

const MIN_SEARCH_LENGTH = 3;

type SelectProductInputProps = {
	country: Alpha2Code;
};

const SelectProductInput: FC<SelectProductInputProps> = ({ country }) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const inputRef = useRef<HTMLInputElement>();
	const skipSearch = searchValue.length < MIN_SEARCH_LENGTH;
	// Spanish users can create only fertilizer type custom treatments, we need to disable it as it's not finished, source: Alex
	const { isSpanishPartner } = useSpanishPartner();

	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const treatmentInput = state.input;

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) =>
		setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const handleClear = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.stopPropagation();
		addTreatmentPageState({
			...state,
			input: {
				selectedGovernmentProduct: false,
				product: {},
			},
		});
		selectedGovernmentProduct(null);
	};

	const {
		data: governmentProducts,
		loading,
		error,
	} = useQuery(GET_GOVERNMENT_PRODUCTS, {
		variables: {
			filterBy: {
				country,
				name: searchValue,
			},
		},
		skip: skipSearch,
	});

	if (error) {
		throw new ApolloError(error);
	}

	const loadingSpinner = loading ? (
		<Box
			display="flex"
			width="100%"
			justifyContent="center"
			alignItems="center"
			overflow="hidden"
		>
			<LoadingSpinner />
		</Box>
	) : null;

	const products = governmentProducts?.getGovernmentProducts.products.map(
		(product) => (
			<List.Item
				key={product.id}
				onClick={() => {
					addTreatmentPageState({
						...state,
						input: {
							selectedGovernmentProduct: true,
							product: {
								...state.input.product,
								governmentProductId: product.id,
							},
						},
					} as TAddTreatmentsModalInput);
					selectedGovernmentProduct(product);
					handleClose();
				}}
				sx={{
					cursor: 'pointer',
					'&.MuiListItem-root:hover': {
						backgroundColor: FarmableColors.BLACK_4,
					},
				}}
			>
				<Box display="flex" flexDirection="column">
					<Text>{product.name}</Text>
				</Box>
			</List.Item>
		)
	);

	const productsList =
		loadingSpinner ||
		(skipSearch ? null : (
			<Box sx={{ overflow: 'auto' }}>
				<List.List>
					{governmentProducts?.getGovernmentProducts.products.length ? (
						products
					) : (
						<Text sx={{ paddingX: '1rem' }} variant="secondaryBody2">
							{t('prompts.noRegisteredProducts')}
						</Text>
					)}
				</List.List>
			</Box>
		));

	const productName =
		'name' in treatmentInput.product ? treatmentInput.product.name : undefined;

	return (
		<>
			<TextField
				value={selectedProduct?.name || ''}
				placeholder={t('labels.selectProduct')}
				onClick={handleClick}
				fullWidth
				disabled={Boolean(!selectedProduct && productName)}
				InputProps={{
					endAdornment: selectedProduct ? (
						<InputAdornment position="start">
							<IconButton onClick={handleClear} edge="end">
								<CloseIcon />
							</IconButton>
						</InputAdornment>
					) : null,
				}}
			/>
			<Popover
				id="product-select"
				open={isMenuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				onAnimationEnd={() => inputRef.current?.focus()}
			>
				<Box
					display="flex"
					flexDirection="column"
					sx={{ overflow: 'hidden', maxHeight: '32rem', width: '28rem' }}
				>
					<FreeTextSearch
						inputPlaceholder={t(
							'treatmentLibrary.addTreatmentModal.searchProductInput.placeholder'
						)}
						onChange={(newValue: string): void => {
							setSearchValue(newValue);
						}}
						ref={inputRef}
						onClear={() => setSearchValue('')}
						value={searchValue}
						sx={{
							height: '2.25rem',
							boxShadow: 'none',
							border: `1px solid ${FarmableColors.BLACK_12}`,
							margin: '1rem',
						}}
					/>
					{productsList}
				</Box>
			</Popover>
			{isSpanishPartner ? null : (
				<>
					<Divider
						sx={{
							padding: '1rem 0',
						}}
					>
						<Text color={FarmableColors.BLACK_60}>{t('labels.or')}</Text>
					</Divider>
					<TextField
						onChange={(name) => {
							addTreatmentPageState({
								...state,
								input: {
									selectedGovernmentProduct: false,
									product: {
										...state.input.product,
										name,
										type: ProductType.FERTILIZER,
									} as TProductInput,
								},
							});
						}}
						value={productName}
						placeholder={t('labels.productName')}
						disabled={Boolean(selectedProduct)}
					/>
				</>
			)}
		</>
	);
};

export default SelectProductInput;
