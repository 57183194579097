import React, { ReactElement, ReactNode } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import useLocation from 'Features/router/hooks/useLocation';
import { PartnershipCompanyType } from 'Models/partnershipCompany/isPartnershipCompany';
import Tabs from 'UI/navigation/Tabs';
import Tab from 'UI/navigation/Tabs/Tab';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import getFeatureFlag from 'Utils/getFeatureFlag';

type TProps = {
	children: ReactNode;
};

const useStyles = makeStyles({
	container: {
		flex: 1,
		border: `1px solid ${FarmableColors.DIVIDER}`,
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderTop: 0,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},
});

const PartnerTableTabs = (props: TProps): ReactElement => {
	const { children } = props;
	const { t } = useTranslation();
	const styles = useStyles();
	const history = useHistory();
	const { company } = usePartnerCompany();
	const isManagerCompany = company?.type === PartnershipCompanyType.MANAGER;

	const location = useLocation();
	const currentPath = location.pathname;

	const managerTabs = isManagerCompany
		? [
				<Tab
					key="hours-tab"
					label={t('logsPages.tabs.hours')}
					value="hours"
					variant="rounded"
				/>,
				<Tab
					key="harvest-tab"
					label={t('logsPages.tabs.harvest')}
					value="harvest"
					variant="rounded"
				/>,
				// getFeatureFlag('PARTNER_LOGS_EMPLOYEE') ? (
				// 	<Tab
				// 		key="employees-tab"
				// 		label={t('logsPages.tabs.employees')}
				// 		value="employees"
				// 		variant="rounded"
				// 	/>
				// ) : null,
		  ]
		: [
				<Tab
					key="sales-tab"
					label={t('logsPages.tabs.sales')}
					value="sales"
					variant="rounded"
				/>,
		  ];

	return (
		<>
			<Tabs
				value={currentPath.split('/').pop() || ''}
				firstValue="jobs"
				variant="rounded"
				disableIndicator
				valueChangeHandler={(_, value) => {
					history.push(`/partners/${company?.id}/logs/${value}`);
				}}
			>
				<Tab
					key="jobs-tab"
					label={t('logsPages.tabs.jobs')}
					value="jobs"
					variant="rounded"
				/>
				{...managerTabs}
			</Tabs>
			<div className={styles.container}>{children}</div>
		</>
	);
};

export default PartnerTableTabs;
