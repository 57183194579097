// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3V5xaZjV40bnci_8YaoyBW {\n  margin-bottom: -0.75rem !important;\n}\n\n._36h5V7BsQ4sXBvM06Con-j {\n  padding-bottom: 0 !important;\n}\n\n._2IDFDiGiTIqYWMod_7N-jv {\n  font-weight: bold;\n}\n\n._3T-76ZlbGOrkfaqlYGkEZA {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n}\n\n._2ggdKfaUhXLqyz6XcCBY5M {\n  font-size: .875rem;\n  color: red;\n}\n\n.v_z9_xhFcv76yVnh7Bj75 {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.kQDafjiLlXLfbYV89epN->*:not(:last-child) {\n  padding-bottom: 1.5rem;\n}\n\n.XV38VsZTgPAH_fHt9aemP {\n  margin-top: 0 !important;\n}\n\n._21HkyUBX3M_wL8N22MsqI6 {\n  width: 28.125rem;\n}\n\n._2yvDD8KCm3bv8Zc0galVFU {\n  padding-left: .5rem !important;\n}\n\n", ""]);
// Exports
exports.locals = {
	"fixColumnMargin": "_3V5xaZjV40bnci_8YaoyBW",
	"noBottomPaddingColumn": "_36h5V7BsQ4sXBvM06Con-j",
	"modalButton": "_2IDFDiGiTIqYWMod_7N-jv",
	"saveButton": "_3T-76ZlbGOrkfaqlYGkEZA",
	"errorMsg": "_2ggdKfaUhXLqyz6XcCBY5M",
	"dialogActions": "v_z9_xhFcv76yVnh7Bj75",
	"fieldInputForms": "kQDafjiLlXLfbYV89epN-",
	"resetLabelMargin": "XV38VsZTgPAH_fHt9aemP",
	"deleteModalContent": "_21HkyUBX3M_wL8N22MsqI6",
	"percentageSign": "_2yvDD8KCm3bv8Zc0galVFU"
};
module.exports = exports;
