import React from 'react';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import LoadingContent from '../../../components/LoadingContent';
import PageBody from './components/PageBody';

const CompanyWelcomePage = () => {
	const { company, loading } = usePartnerCompany();

	if (company) {
		return <PageBody companyName={company.name} />;
	}

	if (loading) {
		return <LoadingContent />;
	}

	return null;
};

export default CompanyWelcomePage;
