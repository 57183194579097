// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3w_ngYyql5fpdCJBThIjGp {\n  width: 28.125rem;\n}\n\n.iSvJkIYfHNWnjmH5H8Ver {\n  position: absolute !important;\n  left: 1rem;\n}\n\n._1IgmUxtThpW4cG4WqvGfnM {\n  width: 100%;\n}\n\n.vJDVPmVrdCr3FGOisp8dp {\n  margin: auto;\n}\n\n", ""]);
// Exports
exports.locals = {
	"modalContent": "_3w_ngYyql5fpdCJBThIjGp",
	"backButton": "iSvJkIYfHNWnjmH5H8Ver",
	"dialogActions": "_1IgmUxtThpW4cG4WqvGfnM",
	"autoMargin": "vJDVPmVrdCr3FGOisp8dp"
};
module.exports = exports;
