import { TTranslateFunction } from 'Features/localization';
import { TChartProps } from 'UI/display/Chart';
import { FarmableTextColors } from 'UI/theme/Colors';

const getChartConfig = (
	t: TTranslateFunction,
	language: string
): TChartProps['options'] => {
	const initialChartOptions: TChartProps['options'] = {
		chart: {
			type: 'bar',
			marginRight: 40,
			marginBottom: 35,
			style: {
				fontFamily: 'Lato',
			},
		},
		legend: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		series: [],
		plotOptions: {
			bar: {
				maxPointWidth: 24,
			},
		},
		title: {
			align: 'left',
			text: t('farmSalesPage.customersChart.title'),
		},
		xAxis: {
			type: 'category',
			labels: {
				style: {
					fontSize: '0.875rem',
					color: FarmableTextColors.PRIMARY,
				},
			},
		},
		tooltip: {
			pointFormatter() {
				if (
					typeof this.y === 'number' &&
					this.series.options.custom?.currency
				) {
					return `${this.y.toLocaleString(language)} ${
						this.series.options.custom.currency
					}`;
				} else {
					return t('common.charts.noData');
				}
			},
			followPointer: true,
			useHTML: true,
		},
		lang: {
			noData: t('common.charts.noData'),
		},
		yAxis: {
			title: {
				text: null,
			},
			labels: {
				style: {
					fontSize: '0.75rem',
					color: FarmableTextColors.SECONDARY,
				},
			},
		},
	};

	return initialChartOptions;
};

export default getChartConfig;
