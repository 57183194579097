import React, { ReactElement } from 'react';

import useCurrentLocation from 'Hooks/useCurrentLocation';

import LoadingContent from '../../common/components/LoadingContent';
import DetailsForm from './DetailsForm';
import SettingsForm from './SettingsForm';

const PageBody = (): ReactElement => {
	const location = useCurrentLocation();

	return location ? (
		<>
			<DetailsForm />
			<SettingsForm />
		</>
	) : (
		<LoadingContent />
	);
};

export default PageBody;
