import React, { useState } from 'react';

import ControlledMap from './component';
import { Props } from './types';

const UncontrolledMap = (props: Props) => {
	const [viewport, setViewport] = useState(props.viewport);

	const onViewportChanged = setViewport as any;

	return React.createElement(
		ControlledMap,
		{ ...props, viewport, onViewportChanged }
		// props.children,
	);
};

UncontrolledMap.defaultProps = ControlledMap.defaultProps;

export default UncontrolledMap;
