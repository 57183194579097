import React, { useEffect, useState } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import {
	DELETE_TREATMENT_LIBRARY_PRODUCT,
	removeDeletedTreatmentLibraryProduct,
} from '../../../api';
import { selectedProductId } from '../state';
import styles from './styles.module.scss';

function DeleteProductModal() {
	const { t } = useTranslation();
	const productId = useReactiveVar(selectedProductId);
	const [deleteTreatmentProduct, mutationResult] = useMutation(
		DELETE_TREATMENT_LIBRARY_PRODUCT,
		{
			update: removeDeletedTreatmentLibraryProduct,
		}
	);
	const libraryId = getTreatmentLibraryIdFromURL();
	const { data, loading: isDeleting, error } = mutationResult;

	const toggleHandler = () => selectedProductId(null);
	const onDelete = () => {
		if (productId) {
			void deleteTreatmentProduct({ variables: { productId, libraryId } });
		}
	};

	useSnackbarNotifications({
		mutationResult,
		messageSuccess: t(
			'treatmentLibrary.addTreatmentModal.deleteProductTreatment.success'
		),
		messageFailure: t(
			'treatmentLibrary.addTreatmentModal.deleteProductTreatment.failure'
		),
	});

	useEffect(() => {
		if (data || error) {
			selectedProductId(null);
		}
	}, [error, data]);

	return (
		<Modal.UncontrolledModal
			toggleOpen={toggleHandler}
			open={Boolean(productId)}
		>
			<Modal.DialogTitle
				title={t('treatmentLibrary.deleteTreatmentModal.title')}
				closeIconHandler={toggleHandler}
			/>
			<Modal.DialogContent>
				<p className={styles.modalContent}>
					{t('treatmentLibrary.deleteTreatmentModal.message')}
				</p>
			</Modal.DialogContent>
			<Modal.DialogActions className={styles.dialogActions}>
				<Button
					variant="text"
					onClick={toggleHandler}
					className={styles.backButton}
				>
					{t('buttons.back')}
				</Button>
				<Button
					startIcon={isDeleting ? <LoadingIcon /> : null}
					variant="outlined"
					disabled={isDeleting}
					onClick={onDelete}
				>
					{t('buttons.delete')}
				</Button>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
}

export default DeleteProductModal;
