import React, { ReactElement, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import Permissions from 'Models/permissions/Permissions';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import LockIcon from 'UI/icons/Lock';
import Button from 'UI/inputs/Button';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import DELETE_LOCATION_MUTATION from '../api/deleteLocation';
import styles from './styles.module.scss';

const DeleteModal = (): ReactElement => {
	const { t } = useTranslation();
	const locationId = useLocationID();
	const [open, setOpen] = useState(false);
	const history = useHistory();

	const [deleteFarm, deleteTask] = useMutation(DELETE_LOCATION_MUTATION, {
		refetchQueries: ['getLocations'],
	});

	const hasDeleteFarmPermission = usePermission(Permissions.DELETE_FARM);

	useSnackbarNotifications({
		mutationResult: deleteTask,
		messageSuccess: t('info.deleteFarmSuccess'),
		messageFailure: t('errors.deleteFarmFailed'),
	});

	useEffect(() => {
		if (deleteTask.data) {
			history.push('/');
		}
	}, [deleteTask.data]);

	const toggleOpen = () => {
		setOpen(!open);
	};

	const deleteFarmButton = (
		<Button
			startIcon={deleteTask.loading ? <LoadingIcon /> : null}
			variant="outlined"
			disabled={deleteTask.loading}
			onClick={() => void deleteFarm({ variables: { locationId } })}
		>
			{t('buttons.delete')}
		</Button>
	);

	const openDeleteModalButton = (
		<Button
			variant="outlined"
			onClick={toggleOpen}
			disabled={!hasDeleteFarmPermission}
			startIcon={
				!hasDeleteFarmPermission ? (
					<LockIcon fill="rgba(0, 0, 0, 0.26)" />
				) : null
			}
		>
			{t('buttons.deleteFarm')}
		</Button>
	);

	return (
		<>
			{openDeleteModalButton}
			<Modal.UncontrolledModal toggleOpen={toggleOpen} open={open}>
				<Modal.DialogTitle
					title={t('farmSettingsPage.deleteModal.title')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					<p className={styles.modalContent}>
						{t('farmSettingsPage.deleteModal.message')}
					</p>
				</Modal.DialogContent>
				<Modal.DialogActions className={styles.dialogActions}>
					<Button
						variant="text"
						onClick={toggleOpen}
						className={styles.backButton}
					>
						{t('buttons.back')}
					</Button>
					{deleteFarmButton}
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default DeleteModal;
