import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function WindIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 32 32" fontSize="large" {...props}>
			<path
				d="M13.59 7.59A2 2 0 1 1 15 11H6m10.59 11.41A2 2 0 1 0 18 19H6m15.73-8.27A2.5 2.5 0 1 1 23.5 15H6"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeOpacity=".87"
				strokeWidth="2"
				fill="none"
				stroke="black"
			/>
		</SvgIcon>
	);
}

export default WindIcon;
