import React, { Fragment, ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';

import CreateTeamModal from './CreateTeamModal';
import styles from './styles.module.scss';

const TeamsControlCard = (): ReactElement => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	const toggleOpen = () => setOpen(!open);

	return (
		<Fragment>
			<CreateTeamModal open={open} toggleOpen={toggleOpen} />
			<Card className={styles.cardContent}>
				<Grid
					container
					justifyContent="space-between"
					className={styles.textHeader}
				>
					<Text variant="h3">{t('teamsPage.addTeamModal.title')}</Text>
					<Button
						variant="secondary"
						color="primary"
						startIcon={<AddIcon />}
						onClick={toggleOpen}
					>
						{t('buttons.createTeam')}
					</Button>
				</Grid>
				<Text variant="body1" color="textSecondary">
					{t('teamsPage.addTeamModal.description')}
				</Text>
			</Card>
		</Fragment>
	);
};

export default TeamsControlCard;
