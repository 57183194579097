import React, { ReactElement } from 'react';

import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton';

const IconButton = (props: IconButtonProps): ReactElement => {
	const { children, className, onClick, ...MUIProps } = props;

	return (
		<MUIIconButton className={className} onClick={onClick} {...MUIProps}>
			{children}
		</MUIIconButton>
	);
};

export default IconButton;
