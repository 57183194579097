import React from 'react';

import Button from 'UI/inputs/Button';

import styles from '../styles.module.scss';

type TButtonProps = {
	onStartLogin: () => void;
	label: string;
};

function SignUpButton(props: TButtonProps) {
	return (
		<Button disableElevation fullWidth onClick={props.onStartLogin}>
			<span className={styles.loginButtonDescription}>{props.label}</span>
		</Button>
	);
}

export default SignUpButton;
