import React, { ReactElement, ReactNode } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Link from '@mui/material/Link';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import AppStoreIcon from 'UI/icons/AppStore';
import GooglePlayIcon from 'UI/icons/GooglePlay';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import useProfile from 'Hooks/useProfile';

import NoAccessPageTemplate from '../NoAccessPageTemplate';

type TProps = {
	headerIcon?: ReactNode;
};

function NoFarmsAccessPage(props: TProps): ReactElement {
	const { profile } = useProfile();
	const { t } = useTranslation();
	const { logout } = useAuth0();
	const { headerIcon } = props;

	const appStoreLink = (
		<Box paddingX="0.5rem">
			<Link href={process.env.APP_STORE_LINK}>
				<AppStoreIcon sx={{ width: 120, height: 40 }} />
			</Link>
		</Box>
	);

	const googlePlayLink = (
		<Box paddingX="0.5rem">
			<Link href={process.env.GOOGLE_PLAY_LINK}>
				<GooglePlayIcon sx={{ width: 135, height: 40 }} />
			</Link>
		</Box>
	);
	const returnTo =
		process.env.AUTH0_LOGOUT_URI || window.location.origin + '/logout.html';

	const triggerLogout = () => {
		analyticsTrack(TrackEvents.SignedOut, { userId: profile?.id });
		void logout({ logoutParams: { returnTo } });
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="100vh"
		>
			<NoAccessPageTemplate
				title={t('noAccess.noFarmsAccessPage.title')}
				description={t('noAccess.noFarmsAccessPage.description')}
				headerIcon={headerIcon}
			>
				<Box paddingY="1.5rem" display="flex">
					{googlePlayLink}
					{appStoreLink}
				</Box>
				<Button variant="outlined" onClick={triggerLogout}>
					{t('buttons.signOut')}
				</Button>
			</NoAccessPageTemplate>
		</Box>
	);
}

export default NoFarmsAccessPage;
