import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ErrorBoundary from '../../components/ErrorBoundary';
import PageBody from './components/PageBody';
import PageLayout from './components/PageLayout';
import PageValidator from './components/PageValidator';
import Sidebar from './components/Sidebar';

function FarmSalesPage(): ReactElement {
	const { t } = useTranslation();

	return (
		<ErrorBoundary>
			<PageValidator>
				<PageLayout header={t('farmSalesPage.header')} sidebar={<Sidebar />}>
					<PageBody />
				</PageLayout>
			</PageValidator>
		</ErrorBoundary>
	);
}

export default FarmSalesPage;
