import { TypedDocumentNode, gql } from '@apollo/client';
import { TAccountInput } from 'Models/account/isAccountInput';

type TInvitePartnershipAdminInput = {
	input: Pick<TAccountInput, 'email'> & Partial<Omit<TAccountInput, 'email'>>;
};

type TInvitePartnershipUserInput = {
	invitePartnershipUser: {
		id: string;
	};
};

const INVITE_PARTNERSHIP_ADMIN: TypedDocumentNode<
	TInvitePartnershipUserInput,
	TInvitePartnershipAdminInput
> = gql`
	mutation invitePartnershipAdmin($input: AccountInput!) {
		invitePartnershipAdmin(input: $input) {
			id
		}
	}
`;

export default INVITE_PARTNERSHIP_ADMIN;
