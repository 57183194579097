import isAllOf from 'vet/isAllOf';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

import isImage from '../image/isImage';

const _isAccount = isShape({
	id: optional(isString),
	firstName: optional(isString),
	lastName: optional(isString),
	profileImage: optional(isImage),
});

const _isAccountWithId = isAllOf(
	_isAccount,
	isShape({
		id: isString,
	})
);

// https://github.com/microsoft/TypeScript/issues/34596
const isAccount: typeof _isAccount = _isAccount;
export const isAccountWithId: typeof _isAccountWithId = _isAccountWithId;

export default isAccount;

export type TAccount = ValidatorType<typeof isAccount>;
export type TAccountWithId = ValidatorType<typeof isAccountWithId>;
