import React, { ReactElement } from 'react';

import Permissions from 'Models/permissions/Permissions';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Page from 'UI/layout/Page';

import useLocationSubscriptions from 'Hooks/useLocationSubscriptions';
import useLocations from 'Hooks/useLocations';
import usePermission from 'Hooks/usePermission';
import useProfile from 'Hooks/useProfile';
import useProfileRoles from 'Hooks/useProfileRoles';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';
import hasPartnerCompany from 'Utils/hasPartnerCompany';

import NoPermissionPage from '../../pages/no-access/NoPermissionPage';

type PermissionValidatorProps = {
	children: ReactElement | ReactElement[];
};

const PermissionValidator = (props: PermissionValidatorProps): ReactElement => {
	const { children } = props;
	const { profile, loading: isProfileLoading } = useProfile();
	const { profileRoles, loading: isProfileRolesLoading } = useProfileRoles();
	const { loading: isLocationSubscriptionsLoading } =
		useLocationSubscriptions();
	const { locations, loading: isLoadingLocations } = useLocations();

	const currentLocationID = getLocationIdFromUrl();
	const canAccessWebPortal = usePermission(Permissions.WEB_PORTAL_ACCESS);
	const canAccessPartnerPortal =
		!currentLocationID &&
		(profile?.enabledCompanyType || hasPartnerCompany(profileRoles));

	// Need to check whether locations are loaded before
	// checking the role and accessing web portal
	if (
		isLoadingLocations ||
		isProfileLoading ||
		isProfileRolesLoading ||
		isLocationSubscriptionsLoading
	) {
		return (
			<Page noMinHeight center>
				<LoadingSpinner />
			</Page>
		);
	} else if (
		locations !== null &&
		(canAccessWebPortal || canAccessPartnerPortal)
	) {
		return <>{children}</>;
	} else {
		return <NoPermissionPage />;
	}
};

export default PermissionValidator;
