import { gql, TypedDocumentNode } from '@apollo/client';
import { TAggregatedSalesGroupOption } from 'Models/sales/isAggregatedSalesGroupOption';
import { TCustomer } from 'Models/sales/isCustomer';

export type TCustomersChartData = {
	totalRevenue: number;
	currency: string;
	customer: Pick<TCustomer, 'id' | 'name'>;
};
type TGetCustomersChartDataResponse = {
	getAggregatedSales: TCustomersChartData[];
};

type TGetCustomersChartDataRequest = {
	locationId: string;
	query: {
		filterBy?: {
			dateFrom?: string;
			dateTo?: string;
			customerIds?: string[];
			varieties?: string[];
		};
		groupBy?: TAggregatedSalesGroupOption;
	};
};

const GET_CUSTOMERS_CHART_DATA: TypedDocumentNode<
	TGetCustomersChartDataResponse,
	TGetCustomersChartDataRequest
> = gql`
	query getCustomersChartData($locationId: ID!, $query: AggregatedSalesQuery) {
		getAggregatedSales(locationId: $locationId, query: $query) {
			totalRevenue
			currency
			customer {
				id
				name
			}
		}
	}
`;

export default GET_CUSTOMERS_CHART_DATA;
