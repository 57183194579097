import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import useParams from 'Features/router/hooks/useParams';

import LoadingContent from '../../common/components/LoadingContent';
import GET_MEMBERS_PAGE_DATA from '../api/getMembers';
import FarmMembersCard from './FarmMembersCard';

const PageBody = () => {
	const { locationID } = useParams();

	const { data, loading, error } = useQuery(GET_MEMBERS_PAGE_DATA, {
		variables: { locationId: locationID },
	});

	if (data) {
		return <FarmMembersCard members={data.members} />;
	}

	if (loading) {
		return <LoadingContent />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
};

export default PageBody;
