import { TProductSpec } from 'Models/products/ProductSpec';
import { z } from 'zod';

import {
	ProductTreatmentInputSchema,
	TGovernmentProductTreatmentInput,
} from './ProductTreatmentInput';

export const GovernmentProductInputSchema = z.object({
	governmentProductId: z.string().min(1),
	treatments: z.array(ProductTreatmentInputSchema).min(1),
});

export type TGovernmentProductInput = {
	treatments: TGovernmentProductTreatmentInput[];
	productSpec?: TProductSpec;
};

export const isGovernmentProductInput = (
	input: unknown
): input is TGovernmentProductInput =>
	GovernmentProductInputSchema.safeParse(input).success;

export type TGovernmentProductInputPartial = Partial<TGovernmentProductInput>;
