import React from 'react';

import { TTeam } from 'Models/teams/isTeam';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { editingTeamIdVar, teamInputVar } from '../state';
import teamMembersByPosition from '../utils/teamMembersByPosition';
import TeamCard from './TeamCard';

type TDraggableTeamCardProps = {
	team: TTeam;
	toggleOpen: () => void;
};

const TeamMember = ({ teamMember, index }) => {
	return (
		<Draggable draggableId={teamMember.id} index={index}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<TeamCard.Member teamMember={teamMember} />
				</div>
			)}
		</Draggable>
	);
};

function DraggableTeamCard(props: TDraggableTeamCardProps) {
	const { team, toggleOpen } = props;

	const members = team.teamMembers
		.slice(0)
		.sort(teamMembersByPosition)
		.map((teamMember, index) => (
			<TeamMember
				teamMember={teamMember}
				index={index}
				key={`teammate-${teamMember.id}`}
			/>
		));

	const handleEditTeam = (team: TTeam) => {
		editingTeamIdVar(team.id);
		teamInputVar({
			name: team.name,
			teamLeadId: team.teamLead.id,
			teamMemberInput: team.teamMembers.map((member) => ({
				id: member.id,
				fullName: member.fullName,
				customId: member.customId || undefined,
				position:
					typeof member.position === 'number' ? member.position : undefined,
			})),
		});
		toggleOpen();
	};

	return (
		<TeamCard.Card onEdit={() => handleEditTeam(team)} team={team}>
			{team.teamLead ? (
				<TeamCard.Lead
					profile={team.teamLead}
					clockedIn={team.hasTeamLeadUnclosedTimesheets}
				/>
			) : null}
			<Droppable droppableId={team.id}>
				{(provided) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						style={{ minHeight: '5px' }}
					>
						{members}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</TeamCard.Card>
	);
}

export default DraggableTeamCard;
