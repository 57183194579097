import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJob } from 'Models/jobs/isJob';
import jobAccountString from 'Models/jobs/jobAuthorNameString';
import jobDateCompletedTimeString from 'Models/jobs/jobDateCompletedTimeString';
import jobDateStartTimeString from 'Models/jobs/jobDateStartTimeString';
import jobDriverEquipmentString from 'Models/jobs/jobDriverEquipmentString';
import jobDriversString from 'Models/jobs/jobDriversString';
import jobDurationTimeString from 'Models/jobs/jobDurationTimeString';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';

import getDateFnsLocale from 'Utils/getDateFnsLocale';
import { keys } from 'Utils/objectTyped';

import DetailModalSection from '../../../../../components/ModalSection';

type TStatsProps = {
	job: Pick<
		TJob,
		| 'startDate'
		| 'completedDate'
		| 'driversWithEquipmentConfig'
		| 'runStatusHistory'
	> & {
		author: Pick<TJob['author'], 'firstName' | 'lastName'>;
	};
};

const Stats = (props: TStatsProps): ReactElement | null => {
	const { job } = props;
	const { t, i18n } = useTranslation();
	const prefix = 'logsPages.jobLogsPage.detailsModal.props.';
	const title = t(`logsPages.jobLogsPage.detailsModal.sections.stats`);

	const fields = {
		plannedBy: jobAccountString(job),
		operator: jobDriversString(job),
		equipment: jobDriverEquipmentString(job),
		startTime: jobDateStartTimeString(job),
		endTime: jobDateCompletedTimeString(job),
		duration: jobDurationTimeString(job, getDateFnsLocale(i18n.language)),
	};

	const details = keys<typeof fields>(fields).map((name, i) => (
		<Text key={i}>{t(`${prefix}${name}`)}: </Text>
	));

	return (
		<DetailModalSection title={title}>
			<Grid container spacing={1}>
				<Grid item>{details}</Grid>
				<Grid item>
					{Object.values(fields).map((value, i) => (
						<Text key={i}>{value}</Text>
					))}
				</Grid>
			</Grid>
		</DetailModalSection>
	);
};

export default Stats;
