import React, { ReactElement, useState, ReactNode } from 'react';

import { AccordionProps } from '@mui/material';

import Accordion from '.';

export type TAccordionUncontrolled = {
	children: NonNullable<ReactNode>;
} & AccordionProps;

const Uncontrolled = (props: TAccordionUncontrolled): ReactElement => {
	const [expanded, setExpanded] = useState(true);

	const toggleExpanding = () => {
		setExpanded(!expanded);
	};
	return (
		<Accordion.Controlled
			{...props}
			expanded={expanded}
			onChange={toggleExpanding}
		>
			{props.children}
		</Accordion.Controlled>
	);
};

export default Uncontrolled;
