import { TrackEvents } from 'Features/analytics';
import { analyticsTrack } from 'Features/analytics';

import { TAuthUserObject } from '../types';
import { getAuthUserLoginCount } from '../utils/getAuthUserLoginCount';
import { getAuthUserMetaData } from '../utils/getAuthUserMetaData';
import { getAuthenticationSource } from '../utils/getAuthenticationSource';

export default function addAnalytics(userAuthObject: TAuthUserObject) {
	const loginCount = getAuthUserLoginCount(userAuthObject);
	const authenticationSource = getAuthenticationSource(userAuthObject);
	const userMetaData = getAuthUserMetaData(userAuthObject);

	if (authenticationSource && loginCount && userMetaData) {
		if (loginCount > 1) {
			analyticsTrack(TrackEvents.SignedIn, {
				userId: String(userMetaData.accountId),
				authSource: authenticationSource,
			});
		} else {
			analyticsTrack(TrackEvents.SignedUp, {
				userId: String(userMetaData.accountId),
				authSource: authenticationSource,
			});
		}
	}
}
