import React, { ReactElement, useMemo, useState } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Permissions from 'Models/permissions/Permissions';
import Roles from 'Models/permissions/Roles';
import { TMember } from 'Models/profile/isMember';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Loading from 'UI/icons/Loading';
import LockIcon from 'UI/icons/Lock';
import Button from 'UI/inputs/Button';
import Select from 'UI/inputs/Select';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';
import throwErrorSilently from 'Utils/throwErrorSilently';

import GET_LOCATION_MEMBERS from '../../TeamsPage/api/getLocationMembers';
import { TRANSFER_LOCATION_OWNERSHIP_MUTATION } from '../api/transferLocationOwnership';
import styles from './styles.module.scss';

export const TransferOwnershipModal = (): ReactElement => {
	const { t } = useTranslation();
	const locationId = useLocationID();
	const { logout } = useAuth0();
	const [open, setOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState<TMember | null>(null);
	const { data, loading: membersLoading } = useQuery(GET_LOCATION_MEMBERS, {
		variables: { locationId: locationId },
	});

	const [transferOwnership, { loading }] = useMutation(
		TRANSFER_LOCATION_OWNERSHIP_MUTATION
	);

	const options = useMemo(
		() =>
			data?.members.filter(({ accountRole }) => accountRole === Roles.MANAGER),
		[data]
	);
	const hasTransferOwnershipPermission = usePermission(Permissions.DELETE_FARM);

	const toggleOpen = () => {
		setOpen(!open);
	};
	const returnTo =
		process.env.AUTH0_LOGOUT_URI || window.location.origin + '/logout.html';

	const transferOwnershipButton = (
		<Button
			startIcon={loading ? <LoadingIcon /> : null}
			variant="outlined"
			disabled={loading || !selectedUser}
			onClick={() =>
				void transferOwnership({
					variables: { locationId, managerId: selectedUser?.account.id },
				})
					.then(() => logout({ logoutParams: { returnTo } }))
					.catch((error: ApolloError) => throwErrorSilently(error))
			}
		>
			{t('buttons.transferOwnership')}
		</Button>
	);

	const openTransferOwnershipButton = (
		<Button
			variant="outlined"
			onClick={toggleOpen}
			disabled={!hasTransferOwnershipPermission}
			startIcon={
				!hasTransferOwnershipPermission ? (
					<LockIcon fill="rgba(0, 0, 0, 0.26)" />
				) : null
			}
		>
			{t('buttons.transferOwnership')}
		</Button>
	);

	return (
		<>
			{openTransferOwnershipButton}
			<Modal.UncontrolledModal toggleOpen={toggleOpen} open={open}>
				<Modal.DialogTitle
					title={t('buttons.transferOwnership')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					{membersLoading ? (
						<Loading />
					) : (
						<>
							<p
								className={styles.modalContent}
								style={{ marginBottom: '1rem' }}
							>
								{t(
									options?.length
										? 'farmSettingsPage.transferOwnership.modal.description'
										: 'farmSettingsPage.transferOwnership.modal.noManagers'
								)}
							</p>
							{options?.length ? (
								<Select
									fullWidth
									label={t('farmSettingsPage.transferOwnership.selectMember')}
									value={selectedUser}
									options={options as never as string[]}
									optionFormatter={({ account }) =>
										`${account.firstName || ''} ${account.lastName || ''}`
									}
									changeHandler={setSelectedUser}
								/>
							) : null}
						</>
					)}
				</Modal.DialogContent>
				<Modal.DialogActions className={styles.dialogActions}>
					<Button
						variant="text"
						onClick={toggleOpen}
						className={styles.backButton}
					>
						{t('buttons.back')}
					</Button>
					{transferOwnershipButton}
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};
