import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import Modal from 'UI/display/modal/dialog';
import AddIcon from 'UI/icons/Add';
import Edit from 'UI/icons/Edit';
import LibraryAddCheck from 'UI/icons/LibraryAddCheck';
import Button from 'UI/inputs/Button';
import { FarmableColors } from 'UI/theme/Colors';

import NavigatorButton from '../../../../components/NavigatorButton';
import AddExistingCauseModal from './AddExistingCauseModal';
import AddNewCauseModal from './AddNewCauseModal';

const SelectCauseTypeModal = () => {
	const [isModalOpen, setOpenModal] = useState(false);
	const [isAddNewCauseModalOpen, setIsAddNewCauseModalOpen] = useState(false);
	const [isAddExistingCauseModalOpen, setIsExistingNewCauseModalOpen] =
		useState(false);

	const { t } = useTranslation();

	const toggleOpen = () => {
		setOpenModal(!isModalOpen);
	};

	const openAddNewCauseModal = () => {
		setOpenModal(false);
		setIsAddNewCauseModalOpen(true);
	};

	const openAddExistingCauseModal = () => {
		setOpenModal(false);
		setIsExistingNewCauseModalOpen(true);
	};

	return (
		<>
			<AddNewCauseModal
				parentModalStateHandler={setOpenModal}
				modalStateHandler={setIsAddNewCauseModalOpen}
				isModalOpen={isAddNewCauseModalOpen}
			/>
			<AddExistingCauseModal
				parentModalStateHandler={setOpenModal}
				modalStateHandler={setIsExistingNewCauseModalOpen}
				isModalOpen={isAddExistingCauseModalOpen}
			/>
			<Button
				variant="secondary"
				color="primary"
				startIcon={<AddIcon />}
				onClick={toggleOpen}
			>
				{t('causesPage.modal.title')}
			</Button>
			<Modal.UncontrolledModal toggleOpen={toggleOpen} open={isModalOpen}>
				<Modal.DialogTitle
					title={t('causesPage.modal.title')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent noPadding sx={{ marginBottom: '1.5rem' }}>
					<NavigatorButton
						mainText={t('causesPage.modal.addNewCause.title')}
						helperText={t('causesPage.modal.addNewCause.description')}
						icon={<Edit htmlColor={FarmableColors.BLACK_60} />}
						onClick={openAddNewCauseModal}
					/>
					<Divider />
					<NavigatorButton
						mainText={t('causesPage.modal.existingCauseList.title')}
						helperText={t('causesPage.modal.existingCauseList.description')}
						icon={<LibraryAddCheck htmlColor={FarmableColors.BLACK_60} />}
						onClick={openAddExistingCauseModal}
					/>
				</Modal.DialogContent>
			</Modal.UncontrolledModal>
		</>
	);
};

export default SelectCauseTypeModal;
