import { TTranslateFunction } from 'Features/localization';
import getEndOfTheDayISO from 'Models/dates/getEndOfTheDayISO';
import getStartOfTheDayISO from 'Models/dates/getStartOfTheDayISO';
import { TLogFormat, TLogType } from 'Models/exports/Logs';
import { URLParamsType } from 'Models/history/isURLParams';
import { TLocation } from 'Models/locations/isLocation';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';

import generateLogs from 'Utils/generateLogs';
import throwErrorSilently from 'Utils/throwErrorSilently';
import truncateString from 'Utils/truncateString';

const MAX_REPORT_NAME_LENGTH = 40;

async function exportHarvestLog(
	paramsFromURL: URLParamsType,
	location: Pick<TLocation, 'id' | 'name'>,
	t: TTranslateFunction,
	accessToken: string,
	logFormat: TLogFormat
) {
	const paramsFilters = paramsFromURL.filters;
	const reportDate = format(new Date(), 'yyyy-MM-dd');
	const reportName = truncateString(
		`${location?.name} - ${reportDate}`,
		MAX_REPORT_NAME_LENGTH
	);

	const dateFrom = getStartOfTheDayISO(paramsFilters.dateFrom);
	const dateTo = getEndOfTheDayISO(paramsFilters.dateTo);

	const request = {
		dateFrom: dateFrom || new Date(2015).toISOString(),
		dateTo: dateTo || new Date(Date.now()).toISOString(),
		jobType: paramsFilters.jobTypes || [],
		status: paramsFilters.jobStatuses || [],
		categories: paramsFilters.categories || [],
		varieties: paramsFilters.varieties || [],
		fields: paramsFilters.fieldIds || [],
		memberIds: paramsFilters.teamMemberIds || [],
	};

	enqueueSnackbar(t('logsPages.reports.generationStarted'), {
		variant: 'info',
	});

	try {
		await generateLogs({
			request,
			reportName,
			locationID: location.id,
			logType: 'HARVEST',
			logFormat,
			accessToken,
		});
	} catch (error) {
		enqueueSnackbar(t('logsPages.reports.generationFailed'), {
			variant: 'error',
		});
		if (error instanceof Error) {
			throwErrorSilently(error);
		}
	}
}

export default exportHarvestLog;
