import React, { ReactElement, ReactChild } from 'react';

import Card from 'UI/surfaces/Card';
import CardHeader from 'UI/surfaces/Card/CardHeader';
import { makeStyles } from 'UI/utils/makeStyles';

type TStylesArgs = {
	flipAvatar: boolean;
};

const useStyles = makeStyles({
	root: {
		boxShadow: 'none',
		border: 'none',
	},
	avatar: {
		marginRight: ({ flipAvatar }: TStylesArgs) => (flipAvatar ? 0 : undefined),
		marginLeft: '0.625rem',
	},
	content: {
		flex: 1,
		minWidth: 0,
	},
	header: {
		flexDirection: ({ flipAvatar }: TStylesArgs) =>
			flipAvatar ? 'row-reverse' : undefined,
		alignItems: 'flex-start',
		padding: 0,
	},
});

type TEventProps = {
	avatar: ReactElement;
	avatarOrientation?: 'left' | 'right';
	subheader: ReactChild;
	title: ReactChild;
};

const Event = (props: TEventProps) => {
	const avatarOrientation = props.avatarOrientation;
	const { avatar, subheader, title } = props;
	const styles = useStyles({ flipAvatar: avatarOrientation === 'right' });

	return (
		<Card className={styles.root} raised={false}>
			<CardHeader
				className={styles.header}
				classes={{
					avatar: styles.avatar,
					content: styles.content,
				}}
				avatar={avatar}
				title={title}
				subheader={subheader}
			/>
		</Card>
	);
};

export default Event;
