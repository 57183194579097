import { TTranslateFunction } from 'Features/localization';
import {
	parseRevenueChartData,
	TParsedRevenueChartData,
} from 'Models/sales/parseRevenueChartData';
import { FarmableColors } from 'UI/theme/Colors';

import { TRevenueData } from '../../../api/getRevenueChartData';
import { TSortDataPerCurrencyResult } from './sortDataPerCurrency';

function accumulateData(data: TParsedRevenueChartData[]) {
	const accumulatedData: TParsedRevenueChartData[] = [];
	for (let i = 0, sum = 0; i < data.length; i++) {
		sum += data[i].point.y;

		accumulatedData.push({
			year: data[i].year,
			point: {
				x: data[i].point.x,
				y: sum,
			},
		});
	}

	return accumulatedData;
}

const getData = (data: TRevenueData[]) =>
	accumulateData(
		data
			.map((d) =>
				parseRevenueChartData({
					x: d.date || '',
					y: d.totalRevenue,
				})
			)
			.sort((data1, data2) => data1.point.x - data2.point.x)
	).map((data) => data.point);

function createSeriesOptions(
	currentYear: TSortDataPerCurrencyResult,
	previousYear: TSortDataPerCurrencyResult,
	t: TTranslateFunction
) {
	const series: Highcharts.SeriesLineOptions[] = [];

	for (const currency in currentYear) {
		const currentYearPoints = getData(currentYear[currency]);

		series.push({
			name: currency,
			type: 'line',
			color: FarmableColors.ORANGE,
			data: currentYearPoints?.length ? currentYearPoints : [],
			custom: {
				isCurrentYear: true,
				currency,
			},
			marker: {
				// make sure that a single-point series is visible
				enabled: currentYearPoints?.length === 1,
			},
			zIndex: 1,
		});
	}

	for (const currency in previousYear) {
		const previousYearPoints = getData(previousYear[currency]);

		if (!currentYear[currency]) {
			series.push({
				name: currency,
				color: FarmableColors.ORANGE,
				type: 'line',
				data: [],
			});
		}

		series.push({
			name: `${currency} (${t('common.previousYear')})`,
			type: 'line',
			data: previousYearPoints?.length ? previousYearPoints : [],
			color: FarmableColors.ORANGE_38,
			marker: {
				// make sure that a single-point series is visible
				enabled: previousYearPoints?.length === 1,
			},
			custom: {
				isCurrentYear: false,
				currency,
			},
		});
	}

	return series;
}

export default createSeriesOptions;
