import React, { ReactElement, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import JobTypes, { ALL as JOBTYPES, isJobType } from 'Models/jobs/JobTypes';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

import useLocationID from 'Hooks/useLocationID';

import GET_CUSTOM_JOB_TYPES from './api/getCustomJobTypes';

//
// We need to remember that treatments are called a jobTypes on the backend
//

type TTreatmentsProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const Treatments = (props: TTreatmentsProps): ReactElement => {
	const { updateFilters, filters } = props;
	const locationId = useLocationID();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const { data } = useQuery(GET_CUSTOM_JOB_TYPES, {
		skip: !locationId,
		variables: { locationId },
	});

	const filterJobTypes = [
		...(filters?.jobTypes || []),
		...(filters?.customJobTypeIds || []),
	];
	const hasAppliedFilters = filterJobTypes.length;
	const filtersCount = hasAppliedFilters ? `(${filterJobTypes?.length})` : '';

	const onItemClick = (item: string, active: boolean) => {
		const newJobTypes = active
			? filterJobTypes?.filter((type) => type !== item)
			: [...filterJobTypes, item];

		updateFilters({
			...filters,
			jobTypes: newJobTypes.filter(isJobType),
			customJobTypeIds: newJobTypes.filter((t) => !isJobType(t)),
		});
	};

	const onClear = () => {
		updateFilters({
			...filters,
			jobTypes: undefined,
		});
	};

	const clearAllItem = hasAppliedFilters
		? [
				<Divider sx={{ marginY: '0.5rem' }} key="divider" />,
				<Menu.Item onClick={onClear} key="clearAll">
					<Text variant="body2" color="textSecondary">
						{t('buttons.clearAll')}
					</Text>
				</Menu.Item>,
		  ]
		: null;

	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
				sx={{ marginRight: '0.5rem' }}
			>
				{t('buttons.jobType')} {filtersCount}
			</Button>
			<Menu.Menu
				id="treatments-filter-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				{JOBTYPES.map((jobType, i) => {
					if (jobType === JobTypes.CUSTOM) return null;
					const active = filterJobTypes?.includes(jobType);
					return (
						<Box pl={1.375} key={`${jobType}-${i}`}>
							<Checkbox.Controlled
								label={t(`common.jobs.types.${jobType}`)}
								checked={active}
								onChange={() => onItemClick(jobType, active)}
								size="small"
								dense
							/>
						</Box>
					);
				})}
				{data?.customJobTypes.map((customJobType, i) => {
					const active = filterJobTypes?.includes(customJobType.id);
					return (
						<Box pl={1.375} key={`${customJobType.id}-${i}`}>
							<Checkbox.Controlled
								label={customJobType.name}
								checked={active}
								onChange={() => onItemClick(customJobType.id, active)}
								size="small"
								dense
							/>
						</Box>
					);
				})}
				{clearAllItem}
			</Menu.Menu>
		</>
	);
};

export default Treatments;
