import { gql, TypedDocumentNode } from '@apollo/client';
import { TTreatmentLibraryLocationStatus } from 'Models/treatmentLibrary/isTreatmentLibraryLocation';

type TTreatmentLibraryLocationsResponse = {
	getTreatmentLibraryLocations: {
		location: {
			id: string;
		};
		status: TTreatmentLibraryLocationStatus;
	}[];
};

type TGetTreatmentLibraryLocationsRequest = {
	libraryId: string;
};

const GET_TREATMENT_LIBRARY_LOCATIONS: TypedDocumentNode<
	TTreatmentLibraryLocationsResponse,
	TGetTreatmentLibraryLocationsRequest
> = gql`
	query getTreatmentLibraryLocations($libraryId: ID!) {
		getTreatmentLibraryLocations(libraryId: $libraryId) {
			location {
				id
			}
			status
		}
	}
`;

export default GET_TREATMENT_LIBRARY_LOCATIONS;
