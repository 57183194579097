import React, { ReactElement, ReactNode, ReactEventHandler } from 'react';

import Modal from '.';

export type TUncontrolledDrawer = {
	children?: ReactNode;
	open: boolean;
	toggleOpen: ReactEventHandler;
};

const UncontrolledDrawer = (props: TUncontrolledDrawer): ReactElement => {
	return (
		<Modal.DrawerModal
			{...props}
			open={props.open}
			toggleOpen={props.toggleOpen}
		>
			{props.children}
		</Modal.DrawerModal>
	);
};

export default UncontrolledDrawer;
