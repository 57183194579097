import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import NoAccessPageTemplate from '../../../../no-access/NoAccessPageTemplate';

const NoAccessPage = (): ReactElement => {
	const { t } = useTranslation();

	return (
		<NoAccessPageTemplate
			title={t('noAccess.noAccess.harvestExportPage.title')}
			description={t('noAccess.noAccess.harvestExportPage.description')}
		/>
	);
};

export default NoAccessPage;
