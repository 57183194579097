import React, { FC, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { TProduct } from 'Models/products/Product';
import { TProductTreatment } from 'Models/products/ProductTreatment';
import Divider from 'UI/display/Divider';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';
import { makeStyles } from 'UI/utils/makeStyles';
import { useTranslation } from 'react-i18next';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import ModalSection from '../../../../../../../../../../components/ModalSection';
import {
	govTreatmentsInputState,
	selectedGovernmentProduct,
} from '../../../state';
import { GET_GOVERNMENT_PRODUCT_TREATMENTS } from './api/getGovermentTreatmens';

const useStyles = makeStyles(() => ({
	listItem: {
		paddingTop: '0',
		paddingBottom: '0',
		paddingRight: '0',
	},
	divider: {
		marginTop: '1.5rem',
		marginBottom: '1rem',
	},
	listTitle: {
		paddingBottom: '1rem',
	},
}));

interface SelectGovTreatmentsProps {
	product?: TProduct;
}

export const SelectGovTreatments: FC<SelectGovTreatmentsProps> = ({
	product,
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const selectedProduct = useReactiveVar(selectedGovernmentProduct) || product;
	const selectedTreatments = useReactiveVar(govTreatmentsInputState) || [];
	const { company } = usePartnerCompany();

	const { data: govTreatments, loading } = useQuery(
		GET_GOVERNMENT_PRODUCT_TREATMENTS,
		{
			skip: !selectedProduct?.governmentProductSpec?.country,
			variables: {
				filterBy: {
					country: company?.country as string,
					productName: selectedProduct?.name,
					productRegistrationNumber:
						selectedProduct?.productSpec.registrationNumber,
				},
			},
		}
	);

	useEffect(() => {
		govTreatmentsInputState([]);
		if (product) {
			govTreatmentsInputState(product.treatments);
		}
	}, [product]);

	const handleTreatmentClick = (treatment: TProductTreatment) => {
		if (selectedTreatments.some(({ id }) => id === treatment.id)) {
			govTreatmentsInputState(
				selectedTreatments.filter(({ id }) => id !== treatment.id)
			);
		} else {
			govTreatmentsInputState([...selectedTreatments, treatment]);
		}
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			overflow="auto"
			maxHeight="35.25rem"
		>
			<Text>{t('treatmentLibrary.addModal.description')}</Text>
			<Divider className={classes.divider} />
			<ModalSection noDivider>
				{loading ? (
					<Box textAlign="center" width="100%" height="100%">
						<LoadingSpinner />
					</Box>
				) : (
					<List.List>
						<Text
							fontWeight="bold"
							variant="body2"
							className={classes.listTitle}
						>
							{t('labels.category')}
						</Text>
						{govTreatments?.getGovernmentTreatments?.treatments.map(
							(treatment, idx) => {
								const unitText = t(
									`apiConstants.productUnits.${treatment.unit}`
								);

								return (
									<List.Item className={classes.listItem} key={idx}>
										<Checkbox.Controlled
											onClick={() => handleTreatmentClick(treatment)}
											checked={selectedTreatments.some(
												({ governmentTreatmentId }) =>
													governmentTreatmentId ===
													treatment.governmentTreatmentId
											)}
										/>
										<List.ItemText
											sx={{ fontSize: 14 }}
											primary={`${treatment.category} - ${treatment.amount} ${unitText}`}
											secondary={
												treatment.treatmentCauses?.length
													? treatment.treatmentCauses.map(({ name }) => name)
													: undefined
											}
										/>
									</List.Item>
								);
							}
						)}
					</List.List>
				)}
			</ModalSection>
		</Box>
	);
};
