import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldVarietyInput } from 'Models/fields/isFieldVarietyInput';
import { METER } from 'Models/units';
import SubtractIcon from 'UI/icons/Subtract';
import IconButton from 'UI/inputs/IconButton';
import TextField from 'UI/inputs/TextField';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import getDebounce from 'Utils/getDebounce';
import isPositiveNumber from 'Utils/isPositiveNumber';
import numberToDisplayString from 'Utils/numberToDisplayString';

import styles from '../../styles.module.scss';
import { getEditedVarieties, removeVariety } from '../utils';

const debounced = getDebounce();

type TGreenHouseVarietyProps = {
	variety: TFieldVarietyInput;
	isSingle: boolean;
	varieties: TFieldVarietyInput[];
	itemIndex: number;
	setVarieties: (varieties: TFieldVarietyInput[]) => void;
};

const useStyles = makeStyles({
	iconButton: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: FarmableColors.BLACK_12,
	},
});

function GreenHouseVariety(props: TGreenHouseVarietyProps): ReactElement {
	const { variety, isSingle, itemIndex, varieties, setVarieties } = props;
	const { t } = useTranslation();
	const MUIStyles = useStyles();

	const [inputName, setInputName] = useState(variety.name);
	const [inputId, setInputId] = useState(
		numberToDisplayString(variety.identifier)
	);
	const [inputWidth, setInputWidth] = useState(
		numberToDisplayString(variety.width)
	);
	const [inputLength, setInputLength] = useState(
		numberToDisplayString(variety.length)
	);

	const handleNameChange = (name) => {
		const newVarieties = getEditedVarieties(varieties, itemIndex, { name });
		const updateInput = () => setVarieties(newVarieties);

		debounced(updateInput);
		setInputName(name);
	};

	const handleDeleteVariety = () =>
		removeVariety(varieties, setVarieties, itemIndex);

	const handleIDChange = (identifier) => {
		const newVarieties = getEditedVarieties(varieties, itemIndex, {
			identifier,
		});
		const updateInput = () => setVarieties(newVarieties);

		debounced(updateInput);
		setInputId(identifier);
	};

	const handleWidthChange = (width) => {
		const newVarieties = getEditedVarieties(varieties, itemIndex, { width });
		const updateInput = () => setVarieties(newVarieties);
		const sanitizedInputWidth = numberToDisplayString(width);

		debounced(updateInput);
		setInputWidth(sanitizedInputWidth);
	};

	const handleLengthChange = (length) => {
		const newVarieties = getEditedVarieties(varieties, itemIndex, { length });
		const updateInput = () => setVarieties(newVarieties);
		const sanitizedInputLength = numberToDisplayString(length);

		debounced(updateInput);
		setInputLength(sanitizedInputLength);
	};

	return (
		<>
			<Grid container item spacing={3} alignItems="center">
				<Grid item xs={3}>
					<TextField
						label={t('labels.id')}
						value={inputId}
						type="number"
						onChange={handleIDChange}
						className={styles.resetLabelMargin}
						InputProps={{
							inputProps: {
								step: 1,
								min: 1,
							},
						}}
					/>
				</Grid>
				<Grid item xs={7}>
					<TextField
						label={t('labels.variety')}
						value={inputName}
						onChange={handleNameChange}
						className={styles.resetLabelMargin}
					/>
				</Grid>
				<Grid item xs={2}>
					<IconButton
						onClick={handleDeleteVariety}
						disabled={isSingle}
						size="small"
						className={MUIStyles.iconButton}
					>
						<SubtractIcon
							fontSize="small"
							color={isSingle ? 'disabled' : 'action'}
						/>
					</IconButton>
				</Grid>
			</Grid>
			<Grid container item spacing={3}>
				<Grid item xs={5}>
					<TextField
						label={t('labels.width', { context: METER })}
						value={inputWidth}
						type="number"
						onChange={handleWidthChange}
						className={styles.resetLabelMargin}
						InputProps={{
							inputProps: {
								step: 1,
								min: 0,
							},
						}}
						error={Boolean(inputWidth && !isPositiveNumber(inputWidth))}
						helperText={
							inputWidth && !isPositiveNumber(inputWidth)
								? t('helpers.positiveValuesOnly')
								: undefined
						}
					/>
				</Grid>
				<Grid item xs={5}>
					<TextField
						label={t('labels.length', { context: METER })}
						value={inputLength}
						type="number"
						onChange={handleLengthChange}
						className={styles.resetLabelMargin}
						InputProps={{
							inputProps: {
								step: 1,
								min: 0,
							},
						}}
						error={Boolean(inputLength && !isPositiveNumber(inputLength))}
						helperText={
							inputLength && !isPositiveNumber(inputLength)
								? t('helpers.positiveValuesOnly')
								: undefined
						}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default GreenHouseVariety;
