import numberToDisplayString from 'Utils/numberToDisplayString';

import { KILOGRAM, POUND } from '../units';
import { toDisplayString } from '../units/transpileUnits';
import { THarvestBatch } from './isHarvestBatch';

function harvestBatchSoldVolume<TGenericHarvest>(
	harvestBatch: TGenericHarvest & Pick<THarvestBatch, 'soldVolume'>,
	isImperial?: boolean | null
): string {
	return `${numberToDisplayString(harvestBatch.soldVolume)} ${toDisplayString(
		isImperial ? POUND : KILOGRAM
	)}`;
}

export default harvestBatchSoldVolume;
