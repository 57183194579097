import { TMerge } from '../../../common/utils/handleExport';
import {
	COLUMNS_IN_REPORT,
	FIRST_ROW_INDEX,
	SECOND_ROW_INDEX,
	TEAMS_COLUMN_END_INDEX,
	TEAMS_COLUMN_START_INDEX,
} from './constants';

function getTeamsColumnsMerges(
	firstRow = FIRST_ROW_INDEX,
	teamsColumnStart = TEAMS_COLUMN_START_INDEX,
	teamsColumnEnd = TEAMS_COLUMN_END_INDEX
): TMerge[] {
	return [
		{
			s: { r: firstRow, c: teamsColumnStart },
			e: { r: firstRow, c: teamsColumnEnd },
		},
	];
}

function getTeamsHeaderColumnsMerges(
	firstRow = FIRST_ROW_INDEX,
	secondRow = SECOND_ROW_INDEX,
	teamsColumnStart = TEAMS_COLUMN_START_INDEX,
	teamsColumnEnd = TEAMS_COLUMN_END_INDEX,
	totalColumns = COLUMNS_IN_REPORT
): TMerge[] {
	const headerColumnsMerges: TMerge[] = [];

	// The remaining columns should be 2 rows high.
	for (let columnIndex = 0; columnIndex < totalColumns; columnIndex++) {
		// Exclude products cells.
		if (columnIndex < teamsColumnStart || columnIndex > teamsColumnEnd)
			headerColumnsMerges.push({
				s: { r: firstRow, c: columnIndex },
				e: { r: secondRow, c: columnIndex },
			});
	}

	return headerColumnsMerges;
}

export { getTeamsColumnsMerges, getTeamsHeaderColumnsMerges };
export type { TMerge };
