import { init, BrowserTracing } from '@sentry/react';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

import pkg from '../../package.json';

export default function (): void {
	init({
		release: pkg.version,
		dsn: process.env.SENTRY_DSN_ADDRESS,
		environment: process.env.NODE_ENV,
		integrations: [
			new BrowserTracing({
				tracePropagationTargets: [
					'localhost',
					process.env.API_HOST || '',
					/^\//,
				],
				traceFetch: false,
			}),
		],
		normalizeDepth: 7,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.25,
		// By default, Sentry attaches all fetch events as breadcrumbs.
		// Since the apollo-link-sentry package adds new, detailed logs,
		// they would show up duplicated in Sentry.
		// The following solves this issue
		beforeBreadcrumb: excludeGraphQLFetch,
	});
}
