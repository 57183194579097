import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import isFieldInput, { TFieldInput } from 'Models/fields/isFieldInput';
import { FieldTypes } from 'Models/fields/isFieldType';
import { TFieldVarietyInput } from 'Models/fields/isFieldVarietyInput';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import DeleteFieldModal from './DeleteFieldModal';
import styles from './styles.module.scss';

type TModalButtonsProps = {
	fieldInput: TFieldInput;
	onCancel: () => void;
	onSave: () => void;
	onDelete: () => void;
};

type TSaveButtonProps = {
	onSave: () => void;
	disabled: boolean;
};

function SaveButton(props: TSaveButtonProps) {
	const { disabled, onSave } = props;
	const { t } = useTranslation();

	const saveButton = (
		<Button variant="outlined" onClick={onSave} disabled={disabled}>
			{t('buttons.save')}
		</Button>
	);

	return saveButton;
}

function accumulatePercents(
	sum: number,
	currentVariety: TFieldVarietyInput
): number {
	if (currentVariety.percentage) {
		return sum + currentVariety.percentage;
	}
	return sum;
}

function ModalButtons(props: TModalButtonsProps): ReactElement {
	const { fieldInput, onCancel, onSave, onDelete } = props;
	const { t } = useTranslation();

	// Disable when varieties don't have accumulated 100 percents;
	const hasPercentageVariety =
		fieldInput.fieldType === FieldTypes.ROWS ||
		fieldInput.fieldType === FieldTypes.FIELD;

	const hasDistributedPercents =
		fieldInput.varieties.reduce(accumulatePercents, 0) === 100;

	const hasValidFieldInput = isFieldInput(fieldInput);
	const disableSaving =
		!hasValidFieldInput || (hasPercentageVariety && !hasDistributedPercents);

	return (
		<div className={styles.dialogActions}>
			<Box display="flex">
				<Button variant="text" onClick={onCancel}>
					{t('buttons.cancel')}
				</Button>
				<Box paddingLeft="0.5rem">
					<DeleteFieldModal onDelete={onDelete} />
				</Box>
			</Box>
			<SaveButton disabled={disableSaving} onSave={onSave} />
		</div>
	);
}

export default ModalButtons;
