import { TTranslateFunction } from 'Features/localization';
import { SPRAYING, FERTILIZER, FERTIGATE } from 'Models/jobs/JobTypes';
import { TChartProps } from 'UI/display/Chart';
import { FarmableColors } from 'UI/theme/Colors';
import { PointClickEventObject } from 'highcharts';

export type TSeason = {
	start: number;
	end: number;
	plotLine: number;
};

export const jobTypeColorMap = {
	FERTIGATE: FarmableColors.SANDY_BROWN,
	FERTILIZER: FarmableColors.ROYAL_PINK,
	SPRAYING: FarmableColors.AZURE,
};

const getChartConfig = (
	season: TSeason,
	t: TTranslateFunction,
	onPointClick: (event: PointClickEventObject) => void
) => {
	// Order is based on the mockup
	const chartRawData = [
		{
			id: FERTIGATE,
			type: 'scatter' as const,
			name: t('common.jobs.types.FERTIGATE'),
			data: [],
			color: jobTypeColorMap.FERTIGATE,
		},
		{
			id: FERTILIZER,
			type: 'scatter' as const,
			name: t('common.jobs.types.FERTILIZER'),
			data: [],
			color: jobTypeColorMap.FERTILIZER,
		},
		{
			id: SPRAYING,
			type: 'scatter' as const,
			name: t('common.jobs.types.SPRAYING'),
			data: [],
			color: jobTypeColorMap.SPRAYING,
		},
	];

	const categories = chartRawData.map((chartData) => chartData.id).reverse();
	const showPlotLine = season.plotLine > season.start;
	const initialChartOptions: TChartProps['options'] = {
		chart: {
			style: {
				fontFamily: 'Lato',
			},
			height: 188,
			spacingTop: 2,
			spacingLeft: 0,
			spacingRight: 0,
		},
		// replaced by the custom no data implementation
		noData: {
			style: {
				display: 'none',
			},
		},
		yAxis: {
			title: undefined,
			categories: categories,
			min: 0,
			max: categories.length - 1,
			labels: {
				enabled: false,
			},
		},
		xAxis: {
			type: 'datetime',
			min: season.start,
			max: season.end,
			tickLength: 0,
			lineColor: '#e6e6e6',
			dateTimeLabelFormats: {
				month: {
					main: '%d %b',
				},
				day: {
					main: '%e %b',
				},
			},
			labels: {
				style: {
					fontSize: '0.75rem',
				},
			},
			tickPositions: showPlotLine
				? [season.start, season.plotLine, season.end]
				: [season.start, season.end],
			plotLines: showPlotLine
				? [
						{
							color: FarmableColors.BLACK_38,
							width: 1,
							value: season.plotLine,
							dashStyle: 'Dash',
						},
				  ]
				: undefined,
		},
		title: undefined,
		tooltip: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		legend: {
			align: 'left',
			margin: 16,
			padding: -2,
			itemStyle: {
				fontWeight: 'normal',
			},
		},
		plotOptions: {
			scatter: {
				marker: {
					symbol: 'circle',
					radius: 6,
					lineWidth: 1,
					lineColor: FarmableColors.BLACK_12,
				},
			},
			series: {
				point: {
					events: { click: onPointClick },
				},
			},
		},
		series: chartRawData,
	};

	return { initialChartOptions, chartRawData, categories };
};

export default getChartConfig;
