import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';

import { TAuthUserObject } from '../types';

function getAuthUserMetaData(user: TAuthUserObject) {
	const keys = Object.keys(user);
	const userMetaDataKey = keys.find((key) => key.includes('user_metadata'));

	const isUserMetaData = isShape({
		accountId: isNumber,
	});

	if (userMetaDataKey) {
		const userMetaData = user[userMetaDataKey];

		return isUserMetaData(userMetaData) ? userMetaData : null;
	}

	return null;
}

export { getAuthUserMetaData };
