import React, { ReactElement, ReactNode } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import styles from './styles.module.scss';

type TLogsPageHeaderProps = {
	children?: ReactNode;
};

const LogsPageHeader = (props: TLogsPageHeaderProps): ReactElement => {
	const { children } = props;
	const { t } = useTranslation();

	return (
		<div className={styles.header}>
			<Box justifyContent="space-between" display="flex" flexDirection="row">
				<Text variant="h1">{t('logsPages.title')}</Text>
				{children}
			</Box>
			<Text variant="secondaryBody1">{t('logsPages.description')}</Text>
		</div>
	);
};

export default LogsPageHeader;
