import React, { Fragment, useMemo, useEffect, useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { fetchMaxZoom } from 'Features/google/initGoogleMapsAPILoader';
import fieldsBoundingBox from 'Models/fields/fieldsBoundingBox';
import { TFieldInput } from 'Models/fields/isFieldInput';
import isFieldInput from 'Models/fields/isFieldInput';
import Box from 'UI/layout/Box';
import Map from 'UI/maps/Map';
import { refetchMaxZoom } from 'UI/maps/Map/events';
import GMapLayer from 'UI/maps/layers/GMapLayer';
import { LatLngBounds, LatLngBoundsLiteral } from 'leaflet';

import useLocationID from 'Hooks/useLocationID';

import OverlayLoader from '../../../../../../../../components/OverlayLoader';
import FieldPolygon, {
	FieldPolygonStatus,
} from '../../../../../../../../components/fields/FieldPolygon';
import { GET_FIELDS_GEOMETRY_QUERY } from '../../../../api/getFieldsGeometryData';
import AddFieldModal from '../FieldModal/AddFieldModal';
import ImportedFieldsMarker from './ImportedFieldsMarker';
import styles from './styles.module.scss';

type TPreviewContent = {
	fieldsInput: TFieldInput[];
};

function PreviewContent(props: TPreviewContent) {
	const { fieldsInput } = props;

	const locationId = useLocationID();
	const [bounds, setBounds] = useState<LatLngBoundsLiteral | LatLngBounds>(
		Map.DEFAULT_BOUNDS
	);
	const editFieldsInput = fieldsInput;

	const { data, loading, error } = useQuery(GET_FIELDS_GEOMETRY_QUERY, {
		variables: { locationId },
	});

	useEffect(() => {
		const boundingBox = fieldsBoundingBox(editFieldsInput);
		setBounds(boundingBox);
	}, [editFieldsInput, data]);

	const mapPreview = useMemo(() => {
		const uploadedFieldsLayers = editFieldsInput.map((field, i) => {
			const isFieldValid = isFieldInput(field);
			const status = isFieldValid
				? FieldPolygonStatus.COMPLETED
				: FieldPolygonStatus.FOCUSED;

			return (
				<Fragment key={field.name + '-uploadedFields-' + i}>
					<AddFieldModal fieldInputIndex={i}>
						<FieldPolygon field={field} status={status} />
					</AddFieldModal>
					<ImportedFieldsMarker field={field} isFieldValid={isFieldValid} />
				</Fragment>
			);
		});

		const fieldsLayers = loading ? (
			<OverlayLoader />
		) : (
			data?.getFields.fields.map((field, i) => {
				return (
					<FieldPolygon
						field={field}
						key={field.name + '-fieldsLayer-' + i}
						status={FieldPolygonStatus.INACTIVE}
					/>
				);
			})
		);

		return (
			<Box className={styles.mapContainer}>
				<Map.Controlled
					bounds={bounds}
					boundsOptions={{ padding: [50, 50] }}
					maxBoundsViscosity={1}
					roundedCorners
					onDragEnd={() => refetchMaxZoom(fetchMaxZoom)}
				>
					<>
						{uploadedFieldsLayers}
						{fieldsLayers}
						<GMapLayer />
					</>
				</Map.Controlled>
			</Box>
		);
	}, [editFieldsInput, loading, data?.getFields.fields, bounds]);

	if (error) {
		throw new ApolloError(error);
	} else {
		return mapPreview;
	}
}

export default PreviewContent;
