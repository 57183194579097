import React from 'react';

import getFieldVarieties from 'Models/fields/getFieldVarieties';
import { TField } from 'Models/fields/isField';
import { TFieldVariety } from 'Models/fields/isFieldVariety';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import styles from './styles.module.scss';

type TProps = {
	field: Pick<TField, 'name' | 'id'> & {
		varieties: Pick<TFieldVariety, 'name'>[];
	};
};

export default function CheckboxLabel(props: TProps) {
	const { field } = props;
	const varieties = getFieldVarieties(field);

	const label = (
		<Box className={styles.labelContainer}>
			<Text variant="label" className={styles.caption}>
				{field.name || field.id}
			</Text>
			<Text variant="secondaryBody2" className={styles.varietiesRoot}>
				{`(${varieties})`}
			</Text>
		</Box>
	);

	return label;
}
