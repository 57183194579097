import { FarmableTextColors } from './Colors';

export default {
	fontFamily:
		'Lato, Avenir, Adobe Heiti Std, Segoe UI, Trebuchet MS, sans‑serif',
	button: {
		textTransform: 'none',
	},
	h1: {
		fontSize: '2.125rem',
		lineHeight: '2.5rem',
		color: FarmableTextColors.PRIMARY,
		fontWeight: 'normal',
	},
	h2: {
		fontSize: '1.75rem',
		lineHeight: '2.25rem',
		color: FarmableTextColors.PRIMARY,
		fontWeight: 'normal',
	},
	h3: {
		fontSize: '1.375rem',
		lineHeight: '1.75rem',
		color: FarmableTextColors.PRIMARY,
		fontWeight: 'normal',
	},
	h4: {
		fontSize: '1.25rem',
		lineHeight: '1.5rem',
		color: FarmableTextColors.PRIMARY,
		fontWeight: 'normal',
	},
	body1: {
		fontSize: '1rem',
		lineHeight: '1.5rem',
		color: FarmableTextColors.PRIMARY,
		fontWeight: 'normal',
	},
	body2: {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		color: FarmableTextColors.PRIMARY,
		fontWeight: 'normal',
	},
	overline: {
		fontSize: '0.6875rem',
		lineHeight: '1.25rem',
		color: FarmableTextColors.SECONDARY,
		fontWeight: 'normal',
		letterSpacing: '0.8px',
		textTransform: 'uppercase',
	},
} as const;
