import {
	TJobLogExportParams,
	TLogFormat,
	THarvestLogExportParams,
	TTimesheetLogExportParams,
} from 'Models/exports/Logs';

import downloadBlob from './downloadBlob';

type TProps =
	| {
			request: TJobLogExportParams;
			reportName: string;
			locationID: string;
			logType: 'JOB';
			logFormat: TLogFormat;
			accessToken: string;
	  }
	| {
			request: THarvestLogExportParams;
			reportName: string;
			locationID: string;
			logType: 'HARVEST';
			logFormat: TLogFormat;
			accessToken: string;
	  }
	| {
			request: TTimesheetLogExportParams;
			reportName: string;
			locationID: string;
			logType: 'TIMESHEET';
			logFormat: TLogFormat;
			accessToken: string;
	  };

async function generateLogs({
	request,
	reportName,
	locationID,
	logType,
	logFormat,
	accessToken,
}: TProps): Promise<void> {
	const response = await fetch(
		`${process.env.API_HOST}/location/${locationID}/logs/${logType}/${logFormat}`,
		{
			method: 'POST',
			headers: {
				authorization: accessToken,
				'content-type': 'application/json',
			},
			body: JSON.stringify(request),
		}
	);

	if (!response.ok) {
		throw new Error(`${response.status} ${response.statusText}`);
	}

	const blob = await response.blob();
	downloadBlob(blob, reportName);
}

export default generateLogs;
