import { TSeasonParams } from 'Models/seasons/isSeasonParams';

const MONTHS_IN_A_YEAR = 12;

function getTotalMonths(
	seasonParams: TSeasonParams,
	lastAvailableMonthDate: Date
) {
	const [firstYear] = seasonParams.seasonName
		.split('/')
		.map((year) => parseInt(year));

	const hasCustomSeasonCutoffDate = seasonParams.seasonCutoffDate !== '01-01';
	const startingMonthIndex =
		parseInt(seasonParams.seasonCutoffDate.substring(0, 2)) - 1;
	const lastMonthInTheFirstYear =
		lastAvailableMonthDate.getFullYear() == firstYear;
	const lastMonthIndex = lastAvailableMonthDate.getMonth();

	const totalMonths =
		lastMonthIndex === startingMonthIndex
			? 1
			: lastMonthIndex - startingMonthIndex + 1;

	if (hasCustomSeasonCutoffDate) {
		return lastMonthInTheFirstYear
			? totalMonths
			: MONTHS_IN_A_YEAR - startingMonthIndex + (lastMonthIndex + 1);
	}

	return totalMonths;
}

export default getTotalMonths;
