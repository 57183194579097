import { gql, TypedDocumentNode } from '@apollo/client';
import { TCause } from 'Models/causes/isCause';
import { TGovernmentCauseFilterInput } from 'Models/causes/types/GovernmentCauseFilterInput';

type TGetGovernmentCausesInput = {
	filterBy: TGovernmentCauseFilterInput;
};

const GET_GOVERNMENT_CAUSES: TypedDocumentNode<
	{
		governmentCauses: { causes: TCause[] };
	},
	TGetGovernmentCausesInput
> = gql`
	query getGovernmentCauses($filterBy: GovernmentCauseFilterInput!) {
		governmentCauses(filterBy: $filterBy) {
			causes {
				id
				name
				createdAt
				discriminator
				causeType
				description
				spanishSpec {
					scientificName
					siexCode
					eppoCode
				}
				causeImages {
					id
					path
					filename
					mimetype
					createdAt
				}
			}
		}
	}
`;

export default GET_GOVERNMENT_CAUSES;
