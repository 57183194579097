import React from 'react';

import Text from 'UI/display/Text';
import ChevronRight from 'UI/icons/ChevronRight';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import { FarmableTextColors } from 'UI/theme/Colors';

type TNavigator = {
	mainText: string;
	helperText: string;
	icon?: React.ReactNode;
	onClick: () => void;
};

const NavigatorButton = (props: TNavigator) => {
	const { mainText, helperText, onClick, icon } = props;

	return (
		<Button
			onClick={onClick}
			endIcon={<ChevronRight fill={FarmableTextColors.SECONDARY} />}
			variant="secondary"
			sx={{
				padding: '1.25rem 1.5rem',
				width: '100%',
				justifyContent: 'space-between',
				borderRadius: 0,
				borderWidth: 0,
				'&:hover': {
					borderWidth: 0,
				},
			}}
		>
			<Box display="flex" alignItems="center">
				{icon}
				<Box
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
					pl="1rem"
				>
					<Text variant="body1" sx={{ cursor: 'pointer' }}>
						{mainText}
					</Text>
					<Text
						variant="body2"
						color="textSecondary"
						sx={{ cursor: 'pointer' }}
						textAlign="start"
					>
						{helperText}
					</Text>
				</Box>
			</Box>
		</Button>
	);
};

export default NavigatorButton;
