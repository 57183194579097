import { TypedDocumentNode, gql } from '@apollo/client';
import { TPartnershipCompanyUser } from 'Models/partnershipCompany/isPartnershipCompanyUser';

export const TYPE = 'partnershipCompany/GetCompanyInvitedUsers' as const;

const GET_COMPANY_INVITED_USERS: TypedDocumentNode<
	{
		getCompanyInvitedUsers: TPartnershipCompanyUser[];
	},
	null
> = gql`
	query getCompanyInvitedUsersOnManageMembersPage {
		getCompanyInvitedUsers {
			id
			firstName
			lastName
			email
			role
			profileImage {
				id
				path
				filename
				mimetype
				createdAt
			}
			availableLocations {
				id
				name
			}
		}
	}
`;

export default GET_COMPANY_INVITED_USERS;
