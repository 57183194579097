import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

import { TWorkLog } from './isWorkLog';

function timesheetTaskString<TGenericTimesheet>(
	timesheet: TGenericTimesheet & {
		workLogs?: Omit<TWorkLog, 'duration'>[] | null;
	}
): string {
	const { workLogs } = timesheet;

	if (!workLogs || workLogs.length === 0) {
		return '-';
	}

	return (
		workLogs
			.map((workLog) =>
				workLog.workType ? stringToFirstUppercase(workLog.workType) : null
			)
			.filter(Boolean)
			.join(', ') || '-'
	);
}

export default timesheetTaskString;
