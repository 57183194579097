import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TRecurringInterval } from 'Models/stripe/isPaymentProduct';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

type TProductPriceProps = {
	price: string;
	currency: string;
	interval: TRecurringInterval;
	isPurchased?: boolean;
};

const ProductPrice = (props: TProductPriceProps) => {
	const { price, currency, interval, isPurchased } = props;

	const { t } = useTranslation();

	return isPurchased ? (
		<Box marginY={1}>
			<Text variant="label2" overrideMUIColor="GREEN">
				{t('labels.active')}
			</Text>
		</Box>
	) : (
		<Box marginY={1}>
			<Text display="inline" variant={'label2'} overrideMUIColor="GREEN">
				{price} {currency}{' '}
			</Text>
			<Text display="inline" variant="body2" overrideMUIColor="GREEN">
				{t('labels.per')} {t(`intervals.${interval}`)}
			</Text>
		</Box>
	);
};

export default ProductPrice;
