import z from 'zod';

import {
	FertilizeOrFertigateJobInputSchema,
	JobInputSchema,
	SprayingJobInputSchema,
	SprayingSpanishJobInputSchema,
	TJobDetailsInput,
} from '../jobs/JobInput';
import JobTypes from '../jobs/JobTypes';
import { PartnerVarietySchema, TPartnerVariety } from './PartnerVariety';

const PlannerJobInputSchema = z.object({
	jobDetails: JobInputSchema,
	variety: PartnerVarietySchema,
	dueDate: z.string(),
	comment: z.string().optional().nullable(),
});

const PlannerSprayingJobInputSchema = z.object({
	jobDetails: SprayingJobInputSchema,
	variety: PartnerVarietySchema,
	dueDate: z.string(),
	comment: z.string().optional().nullable(),
});

const PlannerSprayingSpanishJobInputSchema = z.object({
	jobDetails: SprayingSpanishJobInputSchema,
	variety: PartnerVarietySchema,
	dueDate: z.string(),
	comment: z.string().optional().nullable(),
});

const PlannerFertilizeOrFertigateJobInputSchema = z.object({
	jobDetails: FertilizeOrFertigateJobInputSchema,
	variety: PartnerVarietySchema,
	dueDate: z.string(),
	comment: z.string().optional().nullable(),
});

type TPlannerSprayingJobInput = z.infer<typeof PlannerSprayingJobInputSchema>;
type TPlannerSprayingSpanishJobInput = z.infer<
	typeof PlannerSprayingSpanishJobInputSchema
>;
type TPlannerFertilizeOrFertigateJobInput = z.infer<
	typeof PlannerFertilizeOrFertigateJobInputSchema
>;

export const isPlannerJobInput = (input: unknown): input is TPlannerJobInput =>
	PlannerJobInputSchema.safeParse(input).success;

const isPlannerSprayingJobInput = (
	input: unknown
): input is TPlannerSprayingJobInput =>
	PlannerSprayingJobInputSchema.safeParse(input).success;

const isPlannerSprayingSpanishJobInput = (
	input: unknown
): input is TPlannerSprayingSpanishJobInput =>
	PlannerSprayingSpanishJobInputSchema.safeParse(input).success;

const isPlannerFertilizeOrFertigateJobInput = (
	input: unknown
): input is TPlannerFertilizeOrFertigateJobInput =>
	PlannerFertilizeOrFertigateJobInputSchema.safeParse(input).success;

// validator
export function isPartnerPlannerJobInput(
	jobInput: unknown,
	isSpanishPartner: boolean
) {
	const isPlannerJobInput = (input: unknown): input is TPlannerJobInput =>
		PlannerJobInputSchema.safeParse(input).success;
	// Validate with type guard to check the input in more general terms
	if (isPlannerJobInput(jobInput)) {
		if (jobInput.jobDetails.jobType === JobTypes.SPRAYING) {
			return isSpanishPartner
				? isPlannerSprayingSpanishJobInput(jobInput)
				: isPlannerSprayingJobInput(jobInput);
		}

		if (
			jobInput.jobDetails.jobType === JobTypes.FERTIGATE ||
			jobInput.jobDetails.jobType === JobTypes.FERTILIZER
		) {
			return isPlannerFertilizeOrFertigateJobInput(jobInput);
		}
	}

	return false;
}
// our inner main type
export type TPlannerJobInput = z.infer<typeof PlannerJobInputSchema>;

export type TPartnerPlannerJobInput = {
	variety: TPartnerVariety;
	jobDetails: TJobDetailsInput;
	dueDate: string;
	comment?: string | null;
};
