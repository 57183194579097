import useParams from 'Features/router/hooks/useParams';
import { ExportType, TExportType } from 'Models/exports/ExportType';

const useExportType = (): TExportType | undefined => {
	const { type } = useParams();

	return ExportType[type.toUpperCase()];
};

export default useExportType;
