import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ProfilePageTemplate from '../common/components/ProfilePageTemplate';
import TimeSettingsPageBody from './components/TimeSettingsPageBody';

function TimeSettingsPage(): ReactElement {
	const { t } = useTranslation();
	return (
		<ProfilePageTemplate
			header={t('timeSettingsPage.header')}
			subheader={t('timeSettingsPage.subheader')}
		>
			<TimeSettingsPageBody />
		</ProfilePageTemplate>
	);
}

export default TimeSettingsPage;
