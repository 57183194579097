import React, { Fragment, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import fieldInputAreaWithUnit from 'Models/fields/fieldInputAreaWithUnit';
import fieldInputCategory from 'Models/fields/fieldInputCategory';
import { TFieldInput } from 'Models/fields/isFieldInput';
import isLocations from 'Models/locations/isLocations';
import Table from 'UI/display/Table';

import useLocationID from 'Hooks/useLocationID';
import useLocations from 'Hooks/useLocations';
import { assertStateStatus } from 'Utils/assertStateStatus';
import translateProductCategory from 'Utils/translateProductCategory';

import { FieldImportsStatus } from '../../../../../../models';
import { fieldImportsPageState } from '../../../../../../state';
import FieldDetailsModal from '../../../FieldDetailsModal';

type TReviewTableRowProps = {
	fieldInput: TFieldInput;
};

const ReviewTableRow = (props: TReviewTableRowProps) => {
	const state = useReactiveVar(fieldImportsPageState);
	const { fieldInput } = props;
	const locationID = useLocationID();
	const { locations } = useLocations();
	const { t } = useTranslation();
	const location = locations?.find((location) => location.id === locationID);
	const [isModalOpen, setIsModalOpen] = useState(false);

	isLocations.assert(location);
	assertStateStatus(state, FieldImportsStatus.REVIEW_FIELDS);

	function translate(value: string): string {
		return translateProductCategory(value, t);
	}

	function toggleModal() {
		setIsModalOpen((prevState) => !prevState);
	}

	return (
		<Fragment>
			<Table.Row hover sx={{ cursor: 'pointer' }} onClick={toggleModal}>
				<Table.Cell>{fieldInput.name}</Table.Cell>
				<Table.Cell>{t(`fieldType.${fieldInput.fieldType}`)}</Table.Cell>
				<Table.Cell>{fieldInputCategory(fieldInput, translate)}</Table.Cell>
				<Table.Cell>
					{fieldInputAreaWithUnit(fieldInput, location.areaUnit)}
				</Table.Cell>
			</Table.Row>
			<FieldDetailsModal
				open={isModalOpen}
				field={fieldInput}
				toggleOpen={toggleModal}
			/>
		</Fragment>
	);
};

export default ReviewTableRow;
