import React, { ReactElement, useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Select, { MenuItem } from 'UI/inputs/Select';

import styles from './styles.module.scss';

type TCategoryProps = {
	category: string;
	onChangeCategory?: (category: string) => void;
};

const Category = (props: TCategoryProps): ReactElement => {
	const { category, onChangeCategory } = props;
	const [selected, setSelected] = useState(category);
	const { t } = useTranslation();

	useEffect(() => setSelected(category), [category]);

	function handleOnChange(category: string) {
		setSelected(category);
		onChangeCategory?.(category);
	}

	const categoriesEn = t('productCategories', {
		returnObjects: true,
		lng: 'en',
	});

	const categories = t('productCategories', {
		returnObjects: true,
	});

	const categoriesList = Object.values<string>(categoriesEn).sort();

	const translate = useCallback(
		(value: string): string => {
			const path = Object.keys(categoriesEn).find(
				(key) => categoriesEn[key] === value
			);

			const result = path ? categories[path] : null;
			return result || value;
		},
		[categories, categoriesEn]
	);

	const options = categoriesList.map((category) => {
		return (
			<MenuItem key={category} value={category}>
				<Text variant="label4">{translate(category)}</Text>
			</MenuItem>
		);
	});

	return (
		<Select
			value={selected || ''}
			changeHandler={handleOnChange}
			label={t('labels.category')}
			MUIFormControlProps={{
				className: styles.resetLabelMargin,
			}}
		>
			{options}
		</Select>
	);
};

export default Category;
