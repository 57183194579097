import React, { ReactElement } from 'react';

import formatForDisplay from 'Models/dates/formatForDisplay';
import getDateRepresentationFromString from 'Models/dates/getDateRepresentationFromString';
import parseISO from 'date-fns/parseISO';

import FilterTag from '../pages/logs/common/components/FilterTag';

const getDateFilterTag = (
	dateFnsOptions: { locale: Locale },
	dateFrom?: string,
	dateTo?: string,
	season?: string
): ReactElement | null => {
	if (season) {
		return null;
	}

	if (dateFrom && dateTo) {
		const hasDayRange =
			getDateRepresentationFromString(dateFrom) ===
			getDateRepresentationFromString(dateTo);
		const parsedDateFrom = formatForDisplay(parseISO(dateFrom), dateFnsOptions);
		const parsedDateTo = formatForDisplay(parseISO(dateTo), dateFnsOptions);

		return (
			<FilterTag
				customLabel={
					hasDayRange ? parsedDateFrom : `${parsedDateFrom} - ${parsedDateTo}`
				}
			/>
		);
	}

	return null;
};

export default getDateFilterTag;
