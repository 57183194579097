export type TAuthUserObject = Record<string, unknown> & { sub?: string };

export const Provider = {
	GOOGLE: 'google-oauth2',
	FACEBOOK: 'facebook',
	APPLE: 'apple',
} as const;

type TProviderKeys = keyof typeof Provider;
export type TProvider = typeof Provider[TProviderKeys];
