import { gql, TypedDocumentNode } from '@apollo/client';

import { TTeamsTableQueryResponse } from '../types';

type TGetTeamsForHarvestInput = {
	locationID: string;
};

const GET_TEAMS_FOR_HARVEST_TABLE_DATA: TypedDocumentNode<
	{
		getTeamsPageable: TTeamsTableQueryResponse;
	},
	TGetTeamsForHarvestInput
> = gql`
	query getTeamsPageable($locationID: ID!) {
		getTeamsPageable(locationId: $locationID) {
			teams {
				id
				name
			}
		}
	}
`;

export default GET_TEAMS_FOR_HARVEST_TABLE_DATA;
