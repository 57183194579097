import paramsFromURL from '../../utils/paramsFromURL';
import paramsToURL from '../../utils/paramsToURL';
import { TOrderStatus } from './isURLOrderStatus';

function getOrderStatusURL(status: TOrderStatus): string {
	const currentURL = window.location.href;
	const searchParams = paramsFromURL(currentURL);
	const newSearchParams = {
		...searchParams,
		orderStatus: status,
	};

	return paramsToURL(currentURL, newSearchParams);
}

export default getOrderStatusURL;
