import React, { useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { THarvestExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import { TExportType } from 'Models/exports/ExportType';
import { LogTypes } from 'Models/logs/LogType';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import useManageHistory from 'Hooks/useManageHistory';
import usePartnerCompany from 'Hooks/usePartnerCompany';
import useProfile from 'Hooks/useProfile';

import ExportLoadingSpinner from '../../../common/components/ExportLoadingSpinner';
import ExportPageLayout from '../../../common/components/ExportPageLayout';
import handleExport from '../../../common/utils/handlePartnerExport';
import GET_PARTNER_HARVEST_FILE_EXPORT_DATA from '../api/getPartnerHarvestFileExportData';
import getExportData from '../utils/getExportData';

type TProps = { type: Exclude<TExportType, 'PDF'> };

const LIMIT = 30;

function ExportToFile(props: TProps) {
	const { profile } = useProfile();
	const { company } = usePartnerCompany();
	const { t } = useTranslation();
	const { paramsFromURL: searchParams } = useManageHistory();

	const filterBy = {
		harvestFilters: {
			dateFrom: searchParams.filters.dateFrom,
			dateTo: searchParams.filters.dateTo,
			fieldCategories: searchParams.filters.fieldCategories,
		},
		locationIds: searchParams.filters.locations,
	};

	const { data, loading, error, fetchMore } = useQuery(
		GET_PARTNER_HARVEST_FILE_EXPORT_DATA,
		{
			variables: {
				filterBy,
				pageInput: {
					offset: 0,
					limit: LIMIT,
					sortBy: [{ direction: 'DESC', field: 'date' }],
				},
			},
		}
	);

	const total = data?.getPartnerHarvests.totalElements || 0;
	const rawHarvests = data?.getPartnerHarvests.harvests;

	useEffect(() => {
		if (rawHarvests && rawHarvests.length < total) {
			void fetchMore({
				variables: { pageInput: { offset: rawHarvests.length, limit: LIMIT } },
			});
		}
	}, [rawHarvests, fetchMore, total]);

	const dictionary: THarvestExportHeadersDictionary = t(
		'exportPages.headers.harvest',
		{
			returnObjects: true,
		}
	);

	if (data && total === 0) {
		return (
			<Box padding="1.5rem">
				<Text>{t('labels.noLogsFound')}</Text>
			</Box>
		);
	}

	if (loading || (rawHarvests && rawHarvests.length < total)) {
		return <ExportLoadingSpinner />;
	}

	if (rawHarvests) {
		const harvests = rawHarvests.map((harvest) => ({
			...harvest.harvest,
			location: harvest.location,
		}));

		const exportData = getExportData(harvests, dictionary, t);

		return (
			<ExportPageLayout
				title={t('exportPages.readyTitle', { context: props.type })}
				downloadTrigger={() => {
					handleExport({
						exportData,
						fileType: props.type,
						logType: LogTypes.Harvest,
						fileName: company?.name || 'Harvest report',
					});
					if (profile && company) {
						analyticsTrack(TrackEvents.DataDownloaded, {
							userId: profile.id,
							groupId: company.id,
							exportType: props.type,
							logType: LogTypes.Harvest,
						});
					}
				}}
			/>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
}

export default ExportToFile;
