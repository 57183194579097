import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { KILOGRAM, POUND } from 'Models/units';
import { toDisplayString } from 'Models/units/transpileUnits';
import Chart, { TChartRef } from 'UI/display/Chart';
import { FarmableColors } from 'UI/theme/Colors';

import useImperialUnits from 'Hooks/useImperialUnits';
import replaceYearToCurrent from 'Utils/replaceYearToCurrent';

import useOldestBatch from '../../hooks/useOldestBatch';
import getLastSaleDate from '../../utils/getLastSaleDate';
import getOldestItem from '../../utils/getOldestItem';
import { TVolumeChartData } from './api/getVolumeChartData';
import { initialChartOptions } from './chartConfig';
import useVolumeChartData from './hooks/useVolumeChartData';

function isRecordWithDate(
	value: TVolumeChartData
): value is { totalVolume: number; date: string } {
	return Boolean(value.date);
}

function parseChartData(chartData: TVolumeChartData[]) {
	return chartData
		.filter(isRecordWithDate)
		.map((record) => [
			new Date(replaceYearToCurrent(record.date)).getTime(),
			record.totalVolume,
		])
		.sort((a, b) => a[0] - b[0]);
}

const VolumeChart = () => {
	const { t } = useTranslation();
	const chartComponent = useRef<TChartRef>(null);
	const isImperial = useImperialUnits();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
		...initialChartOptions,
		title: {
			...initialChartOptions.title,
			text: t('farmSalesPage.volumeChart.title'),
		},
	});

	const currentYear = new Date().getFullYear();

	const currentYearVolume = useVolumeChartData(currentYear);
	const previousYearVolume = useVolumeChartData(currentYear - 1);

	const currentYearOldestHarvestBatch = useOldestBatch(currentYear);
	const previousYearOldestHarvestBatch = useOldestBatch(currentYear - 1);

	const unit = toDisplayString(isImperial ? POUND : KILOGRAM);

	useEffect(() => {
		const chart = chartComponent?.current?.chart;

		if (chart) {
			if (
				currentYearVolume.data &&
				previousYearVolume.data &&
				currentYearOldestHarvestBatch.data &&
				previousYearOldestHarvestBatch.data
			) {
				const currentYearData =
					currentYearVolume.data.getAggregatedHarvestBatches;
				const previousYearData =
					previousYearVolume.data.getAggregatedHarvestBatches;

				const noData = !currentYearData.length && !previousYearData.length;

				if (noData) {
					chart.showNoData();
				}

				chart.hideLoading();
				chart.reflow();

				setChartOptions({
					tooltip: {
						formatter() {
							return t('farmSalesPage.volumeChart.tooltipContent', {
								value: this.y,
								unit,
							});
						},
					},
					series: [
						{
							name: t('common.previousYear'),
							color: FarmableColors.ORANGE_50,
							type: 'column',
							data: parseChartData(previousYearData),
							legendIndex: 2,
						},
						{
							name: t('farmSalesPage.volumeChart.legend.currentYear', {
								unit,
							}),
							color: FarmableColors.ORANGE,
							type: 'column',
							data: parseChartData(currentYearData),
						},
					],
					xAxis: {
						visible: !noData,
						min: getOldestItem(
							currentYearOldestHarvestBatch.data.getHarvestBatches
								.harvestBatches[0],
							previousYearOldestHarvestBatch.data.getHarvestBatches
								.harvestBatches[0]
						),
						max: getLastSaleDate(currentYearData, previousYearData),
					},
					legend: {
						enabled: !noData,
					},
					lang: {
						noData: t('common.charts.noData'),
					},
				});
			} else if (currentYearVolume.loading || previousYearVolume.loading) {
				chart.hideNoData();
				chart.showLoading();

				setChartOptions({
					xAxis: {
						visible: false,
					},
					series: [],
				});
			}
		}
	}, [
		// loading props are excluded as with them we reach rerender max depth
		currentYearOldestHarvestBatch.data,
		currentYearVolume.data,
		previousYearOldestHarvestBatch.data,
		previousYearVolume.data,
		t,
		unit,
	]);

	return (
		<Chart
			options={chartOptions}
			ref={chartComponent}
			containerProps={{
				style: {
					padding: '1.5rem',
					height: '22rem',
				},
			}}
		/>
	);
};

export default VolumeChart;
