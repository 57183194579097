import React, { Fragment, ReactElement } from 'react';

import Box from 'UI/layout/Box';
import Container from 'UI/layout/Container';

type TPageLayoutProps = {
	children: ReactElement | ReactElement[];
};

const PageLayout = (props: TPageLayoutProps): ReactElement => {
	const { children } = props;

	return (
		<Fragment>
			<Box overflow="auto" flex="auto" display="flex">
				<Container maxWidth={'sm'}>
					<Box padding={5} height="100%">
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							height="100%"
						>
							<Box
								margin={3}
								width={'100%'}
								display="flex"
								flexDirection="column"
								height="100%"
							>
								{children}
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Fragment>
	);
};

export default PageLayout;
