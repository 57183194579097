import { TJobTreatment } from './isJobTreatment';

type TJobTreatmentWithholdingPeriod = TJobTreatment & {
	originalTreatment: {
		withholdingPeriod: number;
	};
};

function isJobTreatmentWithholdingPeriod(
	treatment: Pick<TJobTreatment, 'amount' | 'unit' | 'originalTreatment'>
): treatment is TJobTreatmentWithholdingPeriod {
	return typeof treatment.originalTreatment?.withholdingPeriod === 'number';
}

function jobWithholdingPeriod(job: {
	treatments: Pick<TJobTreatment, 'amount' | 'unit' | 'originalTreatment'>[];
}): number | null {
	if (!job.treatments.length) {
		return null;
	}

	const withholdingPeriodsArray = job.treatments
		.filter(isJobTreatmentWithholdingPeriod)
		.map((treatment) => treatment.originalTreatment.withholdingPeriod);

	return withholdingPeriodsArray.length
		? Math.max(...withholdingPeriodsArray)
		: null;
}

export default jobWithholdingPeriod;
