import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { THarvest } from 'Models/harvest/types/Harvest';
import Grid from 'UI/layout/Grid';

import ImagesPreview from '../../../../../components/ImagesPreview';
import DetailModalSection from '../../../../../components/ModalSection';

type InformationProps = {
	harvest: Pick<THarvest, 'images'>;
};

const Photos = (props: InformationProps) => {
	const { harvest } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.harvestLogsPage.detailsModal.';
	const title = t(`${prefix}sections.photos`);

	return (
		<DetailModalSection title={title}>
			<Grid container spacing={1}>
				<Grid item>
					{harvest.images?.length ? (
						<ImagesPreview
							images={harvest.images}
							imagesAlt={t(`${prefix}attachedPhotosAlt`)}
						/>
					) : (
						t('labels.noPhotos')
					)}
				</Grid>
			</Grid>
		</DetailModalSection>
	);
};

export default Photos;
