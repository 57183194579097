import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { FarmableColors, FarmablePlaceholdersColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

export type TStatusProps = {
	clockedIn?: boolean | null;
};

const statusStyles = {
	width: '0.5rem',
	height: '0.5rem',
	borderRadius: '50%',
	margin: 6,
};

const useStyles = makeStyles({
	green: {
		...statusStyles,
		backgroundColor: FarmablePlaceholdersColors.GREEN,
	},
	red: {
		...statusStyles,
		backgroundColor: FarmableColors.RED,
	},
});

function Status(props: TStatusProps): ReactElement {
	const { clockedIn } = props;
	const { green, red } = useStyles();
	const status = clockedIn ? green : red;
	const { t } = useTranslation();

	return (
		<div
			title={clockedIn ? t('labels.clockedIn') : t('labels.unavailable')}
			className={status}
		></div>
	);
}

export default Status;
