import React, { ReactNode } from 'react';

import Box from 'UI/layout/Box';

import styles from './styles.module.scss';

type TLogsPageLayoutProps = {
	children: ReactNode;
};

const LogsPageLayout = (props: TLogsPageLayoutProps) => {
	const { children } = props;
	return (
		<div className={styles.logsScroller}>
			<Box className={styles.logsContainer}>{children}</Box>
		</div>
	);
};

export default LogsPageLayout;
