import { gql, TypedDocumentNode } from '@apollo/client';

type TGetCustomerPortalResult = {
	createCustomerPortalSession: string;
};
type TGetCustomerPortalRequest = {
	locationId: string;
	input: { returnUrl: string };
};
const CREATE_CUSTOMER_PORTAL: TypedDocumentNode<
	TGetCustomerPortalResult,
	TGetCustomerPortalRequest
> = gql`
	mutation createCustomerPortal(
		$locationId: ID!
		$input: CustomerPortalParams!
	) {
		createCustomerPortalSession(locationId: $locationId, input: $input)
	}
`;

export default CREATE_CUSTOMER_PORTAL;
