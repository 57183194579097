import isOneOf from 'vet/isOneOf';
import { ValidatorType } from 'vet/types';

export const AggregatedSalesGroupOption = {
	DATE: 'DATE',
	CUSTOMER: 'CUSTOMER',
	MONTH: 'MONTH',
} as const;

const _isAggregatedSalesGroupOption = isOneOf(
	AggregatedSalesGroupOption.DATE,
	AggregatedSalesGroupOption.CUSTOMER,
	AggregatedSalesGroupOption.MONTH
);

// https://github.com/microsoft/TypeScript/issues/34596
const isAggregatedSalesGroupOption: typeof _isAggregatedSalesGroupOption =
	_isAggregatedSalesGroupOption;

export type TAggregatedSalesGroupOption = ValidatorType<
	typeof isAggregatedSalesGroupOption
>;

export default isAggregatedSalesGroupOption;
