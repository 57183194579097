import { gql, TypedDocumentNode } from '@apollo/client';
import { TTreatmentLibrary } from 'Models/treatmentLibrary/isTreatmentLibrary';
import { TTreatmentLibraryInput } from 'Models/treatmentLibrary/isTreatmentLibraryInput';

type TUpdateTreatmentLibraryResult = {
	updateTreatmentLibrary: TTreatmentLibrary;
};

type TUpdateTreatmentLibraryInput = {
	id: string;
	input: TTreatmentLibraryInput;
};

export const UPDATE_TREATMENT_LIBRARY: TypedDocumentNode<
	TUpdateTreatmentLibraryResult,
	TUpdateTreatmentLibraryInput
> = gql`
	mutation updateTreatmentLibrary($id: ID!, $input: TreatmentLibraryInput!) {
		updateTreatmentLibrary(id: $id, input: $input) {
			id
			name
		}
	}
`;
