import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function EnterpriseFarmIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 112 112" {...props}>
			<g fill="none" fillRule="evenodd">
				<path
					d="M91 39a4 4 0 0 1 4 4v42a4 4 0 0 1-4 4H70V39h21zm-2 36H76v6h13v-6zm0-14H76v6h13v-6zm0-14H76v6h13v-6zM40 53v36H21a4 4 0 0 1-4-4V57a4 4 0 0 1 4-4h19zm-6 22H23v6h11v-6zm0-14H23v6h11v-6z"
					fillOpacity=".12"
					fill="#D83E14"
				/>
				<path
					d="M44 25h22a4 4 0 0 1 4 4v10h21a4 4 0 0 1 4 4v42a4 4 0 0 1-4 4H21a4 4 0 0 1-4-4V57a4 4 0 0 1 4-4h19V29a4 4 0 0 1 4-4zm32 50h13v6H76v-6zm0-14h13v6H76v-6zm0-14h13v6H76v-6zm-6-8v49M46 75h18v6H46zm0-14h18v6H46zm0-14h18v6H46zm0-14h18v6H46zm-6 17v38M23 75h11v6H23v-6zm0-14h11v6H23v-6z"
					stroke="#D83E14"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</SvgIcon>
	);
}

export default EnterpriseFarmIcon;
