import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import FarmsSearchMap from '../../../../../components/SearchMap/FarmsSearchMap';
import GET_PARTNER_LOCATIONS from './api/getPartnerLocations';

const SearchMap = () => {
	const { data, loading, error } = useQuery(GET_PARTNER_LOCATIONS, {
		variables: {
			filterBy: {
				statuses: ['ACTIVE'],
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	return (
		<FarmsSearchMap
			sharedLocations={data?.getPartnerLocations || []}
			key="partner-farms-search-map-component"
			isLoading={loading}
		/>
	);
};

export default SearchMap;
