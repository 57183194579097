import { format } from 'date-fns';

import toLocalDate from '../dates/toLocalDate';
import { getRunningPeriods } from './getRunningPeriods';
import { TJob as TFullJob } from './isJob';

function jobDurationTimeInMs(
	job: Pick<
		TFullJob,
		'runStatusHistory' | 'startDate' | 'completedDate' | 'trackRaw'
	>
): number | null {
	const history = job.runStatusHistory;

	let durationTimeInMs;
	let runningPeriods;
	if (history) {
		runningPeriods = getRunningPeriods(history);
	}

	if (runningPeriods?.length) {
		durationTimeInMs = runningPeriods.reduce(
			(sum, period) => sum + (period[1] - period[0]),
			0
		);
	} else if (job.startDate && job.completedDate) {
		// logic for tasks created before running periods were introduced
		const startDateInMs = Number(format(toLocalDate(job.startDate), 'T'));
		const completedDateInMs = Number(
			format(toLocalDate(job.completedDate), 'T')
		);
		durationTimeInMs = completedDateInMs - startDateInMs;
	}

	return isNaN(durationTimeInMs)
		? null
		: durationTimeInMs > 0
		? durationTimeInMs
		: 0;
}

export default jobDurationTimeInMs;
