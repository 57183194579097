import z from 'zod';

import { CoordinatePointSchema } from '../fields/isCoordinatePoint';

export const PartnershipLocationStatus = {
	PENDING: 'PENDING',
	ACTIVE: 'ACTIVE',
	DEACTIVATED: 'DEACTIVATED',
	DECLINED: 'DECLINED',
} as const;

export type TPartnershipLocationStatus = keyof typeof PartnershipLocationStatus;

export const PartnerLocationSchema = z.object({
	id: z.string(),
	name: z.string(),
	status: z.nativeEnum(PartnershipLocationStatus),
	farmerEmail: z.string().email(),
	farmOwner: z
		.object({
			firstName: z.string(),
			lastName: z.string(),
			email: z.string(),
		})
		.optional(),
	address: z.string().optional(),
	city: z.string().optional(),
	zip: z.string().optional(),
	country: z.string().optional(),
	coordinate: CoordinatePointSchema.optional(),
});

export type TPartnerLocation = z.infer<typeof PartnerLocationSchema>;

export type TPartnerLocations = TPartnerLocation[];
