// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3XcNFDheYONymnhr9wt22a {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  min-height: 100vh;\n}\n\n._2hMObQ7F6wX-NM5KiQM35A {\n  justify-content: center;\n  align-items: center;\n}\n\n._2klU9VvSHFbj_wOS3edwf4 {\n  min-height: unset;\n}\n\n", ""]);
// Exports
exports.locals = {
	"base": "_3XcNFDheYONymnhr9wt22a",
	"center": "_2hMObQ7F6wX-NM5KiQM35A",
	"noMinHeight": "_2klU9VvSHFbj_wOS3edwf4"
};
module.exports = exports;
