import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';

import styles from './styles.module.scss';

type TDeleteFieldModal = {
	onDelete: () => void;
};

const DeleteFieldModal = (props: TDeleteFieldModal): ReactElement => {
	const [open, setOpen] = useState(false);
	const { onDelete } = props;

	const { t } = useTranslation();

	const toggleOpen = () => {
		setOpen(!open);
	};

	const deleteFarm = () => {
		onDelete();
		toggleOpen();
	};

	const deleteFarmButton = (
		<Button onClick={deleteFarm} variant="outlined">
			{t('buttons.delete')}
		</Button>
	);

	const openDeleteModalButton = (
		<Button onClick={toggleOpen}>{t('buttons.deleteField')}</Button>
	);

	return (
		<>
			{openDeleteModalButton}
			<Modal.UncontrolledModal toggleOpen={toggleOpen} open={open}>
				<Modal.DialogTitle
					title={t('buttons.deleteField')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					<p className={styles.deleteModalContent}>
						{t('importPages.fieldImportsPage.deleteFieldModal.deleteMessage')}
					</p>
				</Modal.DialogContent>
				<Modal.DialogActions className={styles.dialogActions}>
					<Button variant={'text'} onClick={toggleOpen}>
						{t('buttons.back')}
					</Button>
					{deleteFarmButton}
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default DeleteFieldModal;
