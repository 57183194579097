import { TFieldInput } from '../isFieldInput';
import { FieldTypes } from '../isFieldType';

export const EMPTY_FIELD_INPUT: TFieldInput = {
	fieldType: FieldTypes.ROWS,
	name: '',
	geometry: [[]],
	averageDistanceBetweenTreesInRows: undefined,
	averageDistanceBetweenRows: undefined,
	treeSupplier: undefined,
	rootstock: undefined,
	numberOfPlants: undefined,
	plantHeight: undefined,
	productiveArea: undefined,
	productionMethod: undefined,
	varieties: [],
};

const mockFieldInput: TFieldInput = {
	name: 'New field 1',
	fieldType: FieldTypes.ROWS,
	averageDistanceBetweenRows: null,
	treeSupplier: null,
	rootstock: null,
	numberOfPlants: null,
	plantHeight: null,
	productiveArea: null,
	geometry: [
		[
			{
				x: 19.968179762363434,
				y: 50.050552132479744,
			},
			{
				x: 19.969193637371063,
				y: 50.05191443440344,
			},
			{
				x: 19.96981054544449,
				y: 50.05181626728978,
			},
			{
				x: 19.971079230308533,
				y: 50.051721544445854,
			},
			{
				x: 19.971076548099518,
				y: 50.05097064401284,
			},
			{
				x: 19.96896866708994,
				y: 50.050663219230145,
			},
			{
				x: 19.968179762363434,
				y: 50.050552132479744,
			},
		],
	],
	productionMethod: undefined,
	averageDistanceBetweenTreesInRows: 0.9,
	varieties: [
		{
			_id: '1',
			name: 'Koksa',
			percentage: 0,
			category: 'Apples',
			identifier: null,
			rows: 10,
			length: null,
			width: null,
			sowDate: '2018-01-31T22:00:01Z',
		},
		{
			_id: '2',
			name: 'Józefinka',
			percentage: 0,
			category: 'Pears',
			identifier: null,
			rows: 5,
			length: null,
			width: null,
			sowDate: '2018-01-31T22:00:01Z',
		},
	],
};

export default mockFieldInput;
