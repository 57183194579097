import React, { ReactElement } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import ErrorBoundary from '../../components/ErrorBoundary';
import { getAuthUserMetaData } from '../../features/auth0/utils/getAuthUserMetaData';
import AuthFailedPage from './components/AuthFailedPage';
import PageBody from './components/PageBody';

function LandingPage(): ReactElement {
	const { user } = useAuth0();
	const metadata = user ? getAuthUserMetaData(user) : null;

	const hasUserMetadata = Boolean(metadata?.accountId);

	return hasUserMetadata ? (
		<ErrorBoundary>
			<PageBody />
		</ErrorBoundary>
	) : (
		<AuthFailedPage />
	);
}

export default LandingPage;
