import { gql, TypedDocumentNode } from '@apollo/client';

type TShareTreatmentLibraryResult = {
	shareTreatmentLibraryWithLocation: boolean;
};

type TShareTreatmentLibraryInput = {
	libraryId: string;
	locationId: string;
};

const SHARE_TREATMENT_LIBRARY: TypedDocumentNode<
	TShareTreatmentLibraryResult,
	TShareTreatmentLibraryInput
> = gql`
	mutation shareTreatmentLibraryWithLocation(
		$libraryId: ID!
		$locationId: ID!
	) {
		shareTreatmentLibraryWithLocation(
			libraryId: $libraryId
			locationId: $locationId
		)
	}
`;

export default SHARE_TREATMENT_LIBRARY;
