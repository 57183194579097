import { TFieldInput, TSerializedFieldInput } from 'Models/fields/isFieldInput';
import {
	TFieldVarietyInput,
	TSanitizedFieldVarietyInput,
} from 'Models/fields/isFieldVarietyInput';

import isString from '../../../../../../../../../utils/isString';

function getSerializedVarietyInput(
	varietyInput: TFieldVarietyInput
): TSanitizedFieldVarietyInput {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { width, length, _id, ...rest } = varietyInput;

	return {
		width: isString(width) ? parseFloat(width) : width,
		length: isString(length) ? parseFloat(length) : length,
		...rest,
	};
}

function getSerializedFieldInput(
	fieldInput: TFieldInput
): TSerializedFieldInput {
	const {
		averageDistanceBetweenTreesInRows,
		averageDistanceBetweenRows,
		plantHeight,
		productiveArea,
		varieties,
		geometry,
		...rest
	} = fieldInput;

	return {
		averageDistanceBetweenTreesInRows: isString(
			averageDistanceBetweenTreesInRows
		)
			? parseFloat(averageDistanceBetweenTreesInRows)
			: averageDistanceBetweenTreesInRows,
		averageDistanceBetweenRows: isString(averageDistanceBetweenRows)
			? parseFloat(averageDistanceBetweenRows)
			: averageDistanceBetweenRows,
		productiveArea: isString(productiveArea)
			? parseFloat(productiveArea)
			: productiveArea,
		plantHeight: isString(plantHeight) ? parseFloat(plantHeight) : plantHeight,
		varieties: varieties.map(getSerializedVarietyInput),
		geometryInput: { polygon: geometry },
		...rest,
	};
}

export default getSerializedFieldInput;
