import React, { ReactElement } from 'react';

import { TField } from 'Models/fields/isField';
import CheckMarker from 'UI/maps/CheckMarker';
import ExclamationMarker from 'UI/maps/ExclamationMarker';
import { polygon } from 'UI/maps/Polygon';
import { FarmableColors } from 'UI/theme/Colors';
import _flatten from 'lodash/flatten';

type TProps = {
	field: Pick<TField, 'geometry'>;
	isFieldValid: boolean;
};

function ImportedFieldsMarker(props: TProps): ReactElement | null {
	const { field, isFieldValid } = props;
	const positions = (_flatten(field.geometry) || []).map<[number, number]>(
		(pos) => [pos.y, pos.x]
	);

	const center = polygon(positions).getBounds().getCenter();

	return isFieldValid ? (
		<CheckMarker
			noPointerEvents
			position={center}
			fill={FarmableColors.BLACK_4}
		/>
	) : (
		<ExclamationMarker
			noPointerEvents
			position={center}
			fill={FarmableColors.BLACK_24}
		/>
	);
}

export default ImportedFieldsMarker;
