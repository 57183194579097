import isNull from 'vet/isNull';
import isOneOf from 'vet/isOneOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

export const varietySchema = {
	name: isString,
	category: isString,
	percentage: optional(isNumber),
	sowDate: isOneOf(isString, isNull),
};

const _isVariety = isShape(varietySchema);
const isVariety: typeof _isVariety = _isVariety;

export type TVariety = ValidatorType<typeof isVariety>;
export default isVariety;
