import React, { FC, useCallback, useState } from 'react';

import { styled } from '@mui/material';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	ReportBreakdown,
	ReportFormat,
	ReportType,
	TReportFormat,
} from 'Models/exports/Report';
import Divider from 'UI/display/Divider';
import Button from 'UI/inputs/Button';
import Select from 'UI/inputs/Select';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import {
	CREATE_REPORT_TAB_STATE,
	CreateReportInputSchema,
	ReportFormInterface,
	TCreateReportType,
} from '../CreateReport.types';

interface CreateReportModalControlsInterface {
	reportInput: ReportFormInterface;
	onChange: (input: ReportFormInterface) => void;
	onCancel: () => void;
	onSubmit: ({
		reportInput,
		reportFormat,
	}: {
		reportInput: ReportFormInterface;
		reportFormat: TReportFormat;
	}) => void;
	onNextClick: () => void;
	onBackClick: () => void;
	tabState: CREATE_REPORT_TAB_STATE;
	reportType: TCreateReportType;
}

const CustomSelectWrapper = styled('div')({
	width: '100%',
	'& .MuiFormControl-root': {
		marginTop: 0,
	},
});

export const CreateReportModalControls: FC<CreateReportModalControlsInterface> =
	({
		reportInput,
		onCancel,
		onChange,
		onSubmit,
		onNextClick,
		onBackClick,
		tabState,
		reportType,
	}) => {
		const { t } = useTranslation();
		const [reportFormat, setReportFormat] = useState(reportInput.format);
		const isValid = CreateReportInputSchema.safeParse(reportInput).success;
		const handleSubmit = useCallback(() => {
			onSubmit({ reportInput, reportFormat });
		}, [onSubmit, reportFormat, reportInput]);

		const handleChangeBreakdown = useCallback(
			(value) => {
				onChange({ ...reportInput, breakdown: value });
			},
			[onChange, reportInput]
		);

		return (
			<>
				{tabState === CREATE_REPORT_TAB_STATE.form ? (
					<Grid container justifyContent="space-between" marginTop="5rem">
						<Grid item>
							<Button variant="outlined" onClick={onCancel} disabled={!isValid}>
								{t('buttons.cancel')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								onClick={onNextClick}
								disabled={!isValid}
							>
								{t('buttons.next')}
							</Button>
						</Grid>
					</Grid>
				) : (
					<Box flex={1} marginTop="10.375rem">
						<Divider sx={{ marginX: '-1rem', marginBottom: '1.5rem' }} />
						<Box
							display="flex"
							gap="0.75rem"
							justifyContent="space-between"
							alignItems="center"
						>
							{[ReportType.SPRAYING, ReportType.FERTILIZER].includes(
								reportType
							) && (
								<CustomSelectWrapper>
									<Select
										fullWidth
										label={t('logsPages.createReport.summary.breakdown')}
										value={reportInput.breakdown}
										options={Object.values(ReportBreakdown)}
										changeHandler={handleChangeBreakdown}
									/>
								</CustomSelectWrapper>
							)}
							<Select
								fullWidth
								label={t('logsPages.createReport.summary.downloadAs')}
								value={reportFormat}
								disabled={[
									ReportType.HARVEST,
									ReportType.PRODUCT_INVENTORY,
								].includes(reportType)}
								options={Object.values(ReportFormat)}
								optionFormatter={(value) => value}
								changeHandler={setReportFormat}
							/>
						</Box>
						<Grid container justifyContent="space-between" marginTop="1.5rem">
							<Grid item>
								<Button variant="outlined" onClick={onBackClick}>
									{t('buttons.back')}
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="outlined"
									onClick={handleSubmit}
									disabled={!isValid}
								>
									{t('exportPages.downloadButton')}
								</Button>
							</Grid>
						</Grid>
					</Box>
				)}
			</>
		);
	};
