import numberToDisplayString from 'Utils/numberToDisplayString';

import { KILOGRAM, POUND } from '../units';
import { toDisplayString } from '../units/transpileUnits';
import { THarvestBatch } from './isHarvestBatch';

function harvestBatchTotalVolume<TGenericHarvest>(
	harvestBatch: TGenericHarvest & Pick<THarvestBatch, 'totalVolume'>,
	isImperial?: boolean | null
): string {
	if (isImperial) {
		return `${numberToDisplayString(
			harvestBatch.totalVolume
		)} ${toDisplayString(POUND)}`;
	}
	return `${numberToDisplayString(harvestBatch.totalVolume)} ${toDisplayString(
		KILOGRAM
	)}`;
}

export default harvestBatchTotalVolume;
