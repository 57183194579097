import React, { ReactElement, ReactNode } from 'react';

import { CardProps } from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';

import Card from '.';
import bannerBackground from '../../assets/bannerBackground.svg';
import Text from '../../display/Text';
import { FarmableColors } from '../../theme/Colors';

type BannerProps = {
	header: string;
	message: string;
	contactInfo?: ReactNode;
} & CardProps;

const useStyles = makeStyles({
	root: {
		width: '100%',
		padding: '22px 0 24px 0',
		textAlign: 'center',
		backgroundColor: FarmableColors.LIGHT_BLUE,
		color: FarmableColors.WHITE,
		border: 'none',
		borderRadius: '5px',
		backgroundImage: `url(${bannerBackground})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	marginTop: {
		marginTop: '4px',
	},
	text: {
		fontSize: '1rem',
		lineHeight: '1.5rem',
	},
});

const Banner = (props: BannerProps): ReactElement => {
	const { header, message, contactInfo, ...MUIProps } = props;
	const { root, text, marginTop } = useStyles();

	return (
		<Card className={root} {...MUIProps}>
			<Text variant="h2" overrideMUIColor="INHERIT">
				{header}
			</Text>
			<Text
				variant="body2"
				overrideMUIColor="INHERIT"
				className={`${text} ${marginTop}`}
			>
				{message}
			</Text>
			<Text variant="body2" overrideMUIColor="INHERIT" className={text}>
				{contactInfo}
			</Text>
		</Card>
	);
};

export default Banner;
