import React, { useState } from 'react';

import { useMutation, useApolloClient } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTreatmentLibraryInput } from 'Models/treatmentLibrary/isTreatmentLibraryInput';
import Divider from 'UI/display/Divider';
import MoreIcon from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Menu from 'UI/navigation/Menu';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import AddOrEditLibraryModal from '../../../../common/components/TreatmentLibrary/AddOrEditLibraryModal';
import { UPDATE_TREATMENT_LIBRARY } from '../../../api';
import { GET_PARTNER_TREATMENT_LIBRARY } from '../../../api/getTreatmentLibrary';
import DeleteLibraryModal from './DeleteLibraryModal';

function LibrarySettingsDropdown() {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const treatmentLibraryId = getTreatmentLibraryIdFromURL();
	const client = useApolloClient();
	const isOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const partnerTreatmentLibrary = client.readQuery({
		query: GET_PARTNER_TREATMENT_LIBRARY,
		variables: {
			libraryId: treatmentLibraryId,
		},
	});

	const [updateTreatmentLibrary, updateTreatmentLibraryResult] = useMutation(
		UPDATE_TREATMENT_LIBRARY
	);

	useSnackbarNotifications({
		mutationResult: updateTreatmentLibraryResult,
		messageSuccess: t('treatmentLibrary.addLibraryModal.createLibrary.success'),
		messageFailure: t('treatmentLibrary.addLibraryModal.createLibrary.failure'),
	});

	const updateTreatmentLibraryAction = (
		input: TTreatmentLibraryInput,
		callback: () => void
	) => {
		void updateTreatmentLibrary({
			variables: {
				id: treatmentLibraryId,
				input: input,
			},
			onCompleted() {
				handleClose();
				callback();
			},
		});
	};

	return (
		<>
			<IconButton size="medium" sx={{ margin: 'auto' }} onClick={handleClick}>
				<MoreIcon />
			</IconButton>
			<Menu.Menu
				id="library-settings-menu"
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<AddOrEditLibraryModal
					isEditVersion
					actionHandler={updateTreatmentLibraryAction}
					isLoading={updateTreatmentLibraryResult.loading}
					partnerTreatmentLibrary={partnerTreatmentLibrary?.getTreatmentLibrary}
				/>
				<Divider />
				<DeleteLibraryModal />
			</Menu.Menu>
		</>
	);
}

export default LibrarySettingsDropdown;
