import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';
import { validateEmail } from 'UI/utils/validateEmail';

import INVITE_PARTNERSHIP_ADMIN from '../../../api/InvitePartnershipAdmin';
import GraphQLError from './GraphQLError';

type TInviteAdminModal = {
	isModalOpen: boolean;
	parentModalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
	modalStateHandler: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteAdminModal = (props: TInviteAdminModal) => {
	const { modalStateHandler, parentModalStateHandler, isModalOpen } = props;
	const [email, setEmail] = useState('');
	const [showError, setShowError] = useState<boolean>(false);

	const { t } = useTranslation();
	const [invitePartnershipAdmin, invitePartnershipAdminResult] = useMutation(
		INVITE_PARTNERSHIP_ADMIN
	);

	const isValidEmail = Boolean(validateEmail(email));
	const isInviting = invitePartnershipAdminResult.loading;
	const canSendInvitation = isValidEmail && email.length && !isInviting;

	useEffect(() => {
		if (isModalOpen && invitePartnershipAdminResult.data) {
			setEmail('');
			modalStateHandler(false);
			closeModal();
		}
	}, [isModalOpen, invitePartnershipAdminResult.data]);

	const backToParentModal = () => {
		modalStateHandler(false);
		parentModalStateHandler(true);
	};

	const closeModal = () => {
		modalStateHandler(false);
		setEmail('');
	};

	const handleSendInvitation = () => {
		void invitePartnershipAdmin({
			variables: { input: { email } },
			refetchQueries: ['getCompanyInvitedUsersOnManageMembersPage'],
		});
	};

	const handleEmailChange = (newValue) => {
		if (showError && validateEmail(newValue)) setShowError(false);
		setEmail(newValue);
	};

	return (
		<>
			<Modal.UncontrolledModal toggleOpen={closeModal} open={isModalOpen}>
				<Modal.DialogTitle
					title={t('managePartnerMembers.inviteAdmins')}
					closeIconHandler={closeModal}
				/>
				<Modal.DialogContent
					sx={{ borderBottom: `1px solid ${FarmableColors.BLACK_12}` }}
				>
					<Box paddingBottom="0.5rem">
						<GraphQLError task={invitePartnershipAdminResult} />
						<Text variant="label">{t('common.to')}</Text>
						<TextField
							value={email}
							onChange={handleEmailChange}
							onBlur={() => setShowError(!isValidEmail)}
							error={showError}
							helperText={showError ? t('errors.incorrectEmail.message') : ''}
							required
							placeholder={t('common.emailPlaceholder')}
						/>
					</Box>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant={'text'} onClick={backToParentModal}>
							{t('buttons.back')}
						</Button>
						<Box display="flex" flexDirection="row" alignItems="center">
							<Button
								variant={'outlined'}
								disabled={!canSendInvitation}
								onClick={handleSendInvitation}
								startIcon={
									isInviting ? (
										<LoadingIcon sx={{ width: 20, height: 20 }} />
									) : null
								}
							>
								{t('buttons.sendInvitation')}
							</Button>
						</Box>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default InviteAdminModal;
