import { gql, TypedDocumentNode } from '@apollo/client';
import { TCustomer } from 'Models/customers/isCustomer';
import { TCustomerInput } from 'Models/customers/isCustomerInput';

const EDIT_CUSTOMER_MUTATION: TypedDocumentNode<
	{
		createCustomer: Pick<TCustomer, 'id'>;
	},
	{
		locationId: string;
		customerId: string;
		input: TCustomerInput;
	}
> = gql`
	mutation EditCustomer(
		$locationId: ID!
		$customerId: ID!
		$input: CustomerInput!
	) {
		editCustomer(
			locationId: $locationId
			customerId: $customerId
			input: $input
		) {
			id
		}
	}
`;

export default EDIT_CUSTOMER_MUTATION;
