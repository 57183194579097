import React from 'react';

import { useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_EMPLOYEES_TABLE_DATA from '../api/getEmployeesTableData';

const Pagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const { data, loading } = useQuery(GET_EMPLOYEES_TABLE_DATA, {
		variables: {
			filterBy: {
				dateFrom: searchParams.filters.dateFrom,
				dateTo: searchParams.filters.dateTo,
				employeeName: searchParams.filters.search,
			},
			pageInput: searchParams.pageInput,
			locationId: searchParams.locationID,
			hasPagination: true,
		},
	});

	return (
		<TablePagination
			count={data?.getEmployees.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
