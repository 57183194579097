import React, { ReactElement } from 'react';

import MUICollapse, { CollapseProps } from '@mui/material/Collapse';

type TControlledCollapseProps = Record<string, unknown> & CollapseProps;

const Controlled = (props: TControlledCollapseProps): ReactElement => {
	return <MUICollapse {...props}>{props.children}</MUICollapse>;
};

export default Controlled;
