import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { filterFieldsByCategory } from 'Models/fields/filterFieldsByCategory';
import { URLFiltersType } from 'Models/history/isURLFilters';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';

import CheckboxLabel from '../../fields/CheckboxLabel';
import GET_PARTNER_FIELDS_QUERY from '../common/api/getPartnerFields';

type TPartnerFieldsListProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

function PartnerFieldsList(props: TPartnerFieldsListProps) {
	const { updateFilters, filters } = props;
	const { fieldIds: selectedFields = [], fieldCategories, locations } = filters;

	const { data, loading, error } = useQuery(GET_PARTNER_FIELDS_QUERY);

	if (loading) {
		return <LoadingSpinner width={64} height={64} />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (!data) return null;

	const fields = data.getPartnerFields.fields.map((field) => ({
		...field.field,
		location: field.location,
	}));

	const filteredFieldsByCategories = filterFieldsByCategory(
		fields,
		fieldCategories
	);

	const filteredFields = locations?.length
		? filteredFieldsByCategories.filter((field) =>
				locations.includes(field.location.id)
		  )
		: filteredFieldsByCategories;

	const filterSelectedFieldIds = selectedFields.filter(
		(id) => !filteredFields.some((field) => field.id === id)
	);

	//update already selected fields after changing location or category
	if (filterSelectedFieldIds.length) {
		const filteredFields = selectedFields.filter(
			(fieldId) => !filterSelectedFieldIds.includes(fieldId)
		);
		updateFilters({
			...filters,
			fieldIds: filteredFields,
		});
	}

	const handleChange = (fieldId: string) => {
		const isSelected = selectedFields.includes(fieldId);
		const newFields = isSelected
			? selectedFields.filter((f) => f !== fieldId)
			: [...selectedFields, fieldId];

		updateFilters({
			...filters,
			fieldIds: newFields,
		});
	};

	return (
		<Box display="flex" flexDirection="column">
			{filteredFields.map((field) => {
				const isSelected = selectedFields.includes(field.id);

				return (
					<Checkbox.Controlled
						key={field.id}
						label={<CheckboxLabel field={field} />}
						checked={isSelected}
						onChange={() => handleChange(field.id)}
						size="small"
						dense
						fullWidth
					/>
				);
			})}
		</Box>
	);
}

export default PartnerFieldsList;
