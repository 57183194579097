import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Button from 'UI/inputs/Button';

import { addTreatmentPageState, treatmentInputState } from '../state';

type TPreviousButtonProps = {
	closeModalHandler: () => void;
};

function PreviousButton(props: TPreviousButtonProps) {
	const { closeModalHandler } = props;
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const status = state.status;

	const onPreviousButtonClick = () => {
		switch (state.status) {
			case 'SET_PRODUCT_DETAILS': {
				addTreatmentPageState({
					...state,
					status: 'SELECT_PRODUCT',
					editedCategoryName: undefined,
				});
				break;
			}
			case 'SET_CROP_CATEGORIES': {
				addTreatmentPageState({
					...state,
					status: 'SET_PRODUCT_DETAILS',
				});
				break;
			}
			case 'SELECT_CROP_CATEGORY': {
				addTreatmentPageState({
					...state,
					status: 'SET_CROP_CATEGORIES',
					editedCategoryName: undefined,
				});
				treatmentInputState(undefined);
				break;
			}
			case 'TREATMENT_DETAILS': {
				const treatmentExists = !!state.input.product.treatments?.find(
					(treatment) => treatment.category === state.editedCategoryName
				);
				if (treatmentExists) {
					addTreatmentPageState({
						...state,
						status: 'SET_CROP_CATEGORIES',
						editedCategoryName: undefined,
					});
					treatmentInputState(undefined);
				} else {
					addTreatmentPageState({
						...state,
						status: 'SELECT_CROP_CATEGORY',
					});
				}
				break;
			}

			default:
				closeModalHandler();
		}
	};
	return (
		<Button variant="text" onClick={onPreviousButtonClick}>
			{status === 'SELECT_PRODUCT' ? t('buttons.cancel') : t('buttons.back')}
		</Button>
	);
}

export default PreviousButton;
