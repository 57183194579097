import React, { ReactElement } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import AuthFailedPage from '../../../pages/LandingPage/components/AuthFailedPage';
import LoginPage from '../../../pages/LoginPage';
import SplashPage from '../../../pages/SplashPage';

type TProps = {
	children: ReactElement;
};

function RequireAuth(props: TProps) {
	const { isLoading, isAuthenticated, error } = useAuth0();

	if (error) {
		return <AuthFailedPage />;
	}

	if (isLoading) {
		return <SplashPage />;
	}

	if (window.Cypress && window.auth0?.client) {
		return props.children;
	}

	if (!isAuthenticated) {
		return <LoginPage />;
	}

	return props.children;
}

export default RequireAuth;
