import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TProduct } from 'Models/products/Product';
import { TGovernmentProductFilterInput } from 'Models/products/types/GovernmentProductFilterInput';

import { treatmentLibraryProductFragmentFields } from '../../../../../../../../api';

type TGetGovernmentProductsResponse = {
	getGovernmentProducts: {
		products: TProduct[];
	};
};

type TGetGovernmentProductsRequest = {
	filterBy: TGovernmentProductFilterInput;
	pageInput?: TPageInput;
};

const GET_GOVERNMENT_PRODUCTS: TypedDocumentNode<
	TGetGovernmentProductsResponse,
	TGetGovernmentProductsRequest
> = gql`
	query getGovernmentProducts(
		$filterBy: GovernmentProductFilterInput!
		$pageInput: PageInput
	) {
		getGovernmentProducts(filterBy: $filterBy, pageInput: $pageInput) {
			products {
				${treatmentLibraryProductFragmentFields}
			}
		}
	}
`;

export default GET_GOVERNMENT_PRODUCTS;
