import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { THarvestFilterInput } from 'Models/harvest/types/HarvestFilterInput';
import { TTimesheetsFilterInput } from 'Models/timesheet/types/TimesheetsFilterInput';

import { THarvestTableQueryResponse } from '../HarvestLogsPage/types';

export type TGetTimesheetsResponse = {
	getTimesheets: {
		totalPages: number;
		totalElements: number;
	};
};

type TGetHoursDataInput = {
	locationId: string;
	filterBy?: Partial<TTimesheetsFilterInput>;
	pageInput?: TPageInput;
	hasPagination?: boolean;
};

export const GET_HOURS_TOTAL_ELEMENTS: TypedDocumentNode<
	TGetTimesheetsResponse,
	TGetHoursDataInput
> = gql`
	query getTimesheets(
		$locationId: ID!
		$filterBy: TimesheetsFilterInput
		$pageInput: PageInput!
	) {
		getTimesheets(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
		}
	}
`;

export type TGetHarvestTableDataResponse = {
	getHarvest: Pick<THarvestTableQueryResponse, 'totalElements' | 'totalPages'>;
};

type TGetHarvestInput = {
	locationID: string;
	pageInput?: TPageInput;
	filterBy?: Partial<THarvestFilterInput>;
};

export const GET_TOTAL_HARVEST_ELEMENTS: TypedDocumentNode<
	TGetHarvestTableDataResponse,
	TGetHarvestInput
> = gql`
	query getHarvest(
		$locationID: ID!
		$filterBy: HarvestFilterInput
		$pageInput: PageInput!
	) {
		getHarvest(
			locationId: $locationID
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
		}
	}
`;
