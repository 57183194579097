import countries, { Alpha2Code } from 'i18n-iso-countries';
import z from 'zod';

export type TPartnershipCompanyInput = {
	name: string;
	country: Alpha2Code;
	city?: string;
	zip?: string;
	address?: string;
	email?: string;
	mobilePhone?: string;
};

export const isAlpha2Code = (value: string): value is Alpha2Code =>
	Object.keys(countries.getAlpha2Codes()).includes(value);

const PartnershipCompanyInputSchema = z.object({
	name: z.string().min(1),
	country: z.string().refine((value) => isAlpha2Code(value), {
		message: 'Invalid country code',
	}),
	city: z.string().optional(),
	zip: z.string().optional(),
	address: z.string().optional(),
	email: z.string().optional(),
	mobilePhone: z.string().optional(),
});

export function isPartnershipCompanyInput(input: unknown) {
	return PartnershipCompanyInputSchema.safeParse(input).success;
}
