import React, { useState } from 'react';

import {
	TextField as MUITextField,
	OutlinedInputProps,
	TextFieldProps,
} from '@mui/material';

export type TNumericFieldProps = {
	value?: string;
	fullWidth?: boolean;
	onValueParsed?: (newValue: number) => void;
	inputValidator?: (amount: number) => boolean;
	icon?: Partial<OutlinedInputProps>;
	errorHelperText?: string;
	maxDecimals?: number;
} & Omit<TextFieldProps, 'onChange' | 'onBlur' | 'type' | 'error'>;

const NumericField = (props: TNumericFieldProps): JSX.Element => {
	const {
		placeholder,
		label,
		margin,
		onValueParsed,
		value = '',
		fullWidth,
		helperText,
		disabled,
		icon,
		inputValidator,
		errorHelperText = '',
		maxDecimals = 2,
		...MUIProps
	} = props;

	const [amountInput, setAmountInput] = useState<string>(value);
	const [showError, setShowError] = useState(false);

	const handleChange = (value: string) => {
		const parsedAmount = parseFloat(value) || 0;
		const isFloat = parsedAmount % 1 !== 0;
		const decimalsLength = isFloat
			? parsedAmount.toString().split('.')[1].length
			: 0;

		if (showError && inputValidator && inputValidator(parsedAmount)) {
			setShowError(false);
		}
		if (isFloat && decimalsLength > maxDecimals) {
			return;
		}

		setAmountInput(value);
	};

	const handleBlur = () => {
		const parsedAmount = parseFloat(amountInput);
		setAmountInput(parsedAmount.toString());
		if (inputValidator) {
			setShowError(!inputValidator(parsedAmount));
		}
		onValueParsed?.(parsedAmount);
	};

	const onChangeEvent = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValue = event.target.value;
		handleChange(newValue);
	};

	return (
		<MUITextField
			fullWidth={fullWidth !== false}
			label={label}
			onChange={onChangeEvent}
			placeholder={placeholder}
			value={amountInput}
			variant="outlined"
			helperText={showError ? errorHelperText : helperText}
			type="number"
			disabled={disabled}
			InputProps={icon}
			error={showError}
			margin={margin}
			onBlur={handleBlur}
			{...MUIProps}
		/>
	);
};

export default NumericField;
