import React, { useState, ReactElement } from 'react';

import getAllAvailableSeasons from 'Models/seasons/getAllAvailableSeasons';
import { TSeasonParams } from 'Models/seasons/isSeasonParams';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

type TProps = {
	seasonParams: TSeasonParams;
	onClickItem: (number) => void;
	locationCreatedAt: Date;
};

const SeasonFilter = (props: TProps): ReactElement | null => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const { seasonParams, locationCreatedAt, onClickItem } = props;
	const currentYear = new Date().getFullYear();
	const selectedSeason = seasonParams.seasonName || currentYear;

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setIsOpen(true);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setIsOpen(false);
		setAnchorEl(null);
	};

	const handleItemClick = (season) => {
		handleClose();
		onClickItem(season);
	};

	const seasonsMenuItems = getAllAvailableSeasons(
		seasonParams.seasonCutoffDate,
		locationCreatedAt
	).map((season) => {
		return (
			<Menu.Item
				key={season.seasonName}
				onClick={() => handleItemClick(season)}
			>
				{season.seasonName}
			</Menu.Item>
		);
	});

	return (
		<Box>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{selectedSeason}
			</Button>
			<Menu.Menu
				id="dashboard-page-season-filter-menu"
				anchorEl={anchorEl}
				keepMounted
				open={isOpen}
				onClose={handleClose}
				narrow={true}
			>
				{seasonsMenuItems}
			</Menu.Menu>
		</Box>
	);
};

export default SeasonFilter;
