import { gql, TypedDocumentNode } from '@apollo/client';

import { TGetMembersResponse } from '../types';

type TGetMembersPageDataInput = {
	locationId: string;
};

const GET_MEMBERS_PAGE_DATA: TypedDocumentNode<
	{
		members: TGetMembersResponse;
	},
	TGetMembersPageDataInput
> = gql`
	query getMembers($locationId: ID!) {
		members(locationId: $locationId) {
			account {
				id
				email
				firstName
				lastName
				profileImage {
					id
					path
					filename
					mimetype
					createdAt
				}
			}
			accountRole
		}
	}
`;

export default GET_MEMBERS_PAGE_DATA;
