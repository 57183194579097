import React from 'react';

import ThemeProvider from 'UI/theme/ThemeProvider';
import { render } from 'react-dom';

import AppContainer from './AppContainer';
import initSentry from './sentry';

render(
	<ThemeProvider>
		<AppContainer />
	</ThemeProvider>,
	document.getElementById('app')
);
initSentry();
