import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import harvestBatchDateString from 'Models/harvestBatch/harvestBatchDateString';
import harvestBatchFieldName from 'Models/harvestBatch/harvestBatchFieldName';
import harvestBatchPercentageStorageVolume from 'Models/harvestBatch/harvestBatchPercentageStorageVolume';
import harvestBatchSoldVolume from 'Models/harvestBatch/harvestBatchSoldVolume';
import harvestBatchStorageVolume from 'Models/harvestBatch/harvestBatchStorageVolume';
import harvestBatchTotalVolume from 'Models/harvestBatch/harvestBatchTotalVolume';
import harvestBatchVariety from 'Models/harvestBatch/harvestBatchVariety';
import harvestBatchWasteVolume from 'Models/harvestBatch/harvestBatchWasteVolume';
import { THarvestBatch } from 'Models/harvestBatch/isHarvestBatch';
import CircularProgressChart from 'UI/display/CircularProgressChart';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import Tooltip from 'UI/display/Tooltip';
import Box from 'UI/layout/Box';

import useImperialUnits from 'Hooks/useImperialUnits';

import styles from './styles.module.scss';

type TBatchItemProps = {
	harvestBatch: THarvestBatch;
	clickHandler?: () => void;
};

const BatchItem = (props: TBatchItemProps): ReactElement => {
	const { harvestBatch, clickHandler } = props;
	const isImperial = useImperialUnits();
	const { t } = useTranslation();

	const tooltipContent = (
		<Box display="flex">
			<Box display="flex" flexDirection="column" marginRight="0.5rem">
				<Text variant="secondaryBody2" overrideMUIColor="WHITE">
					{t('farmSalesPage.soldVolume')}
				</Text>
				<Text variant="secondaryBody2" overrideMUIColor="WHITE">
					{t('farmSalesPage.storageVolume')}
				</Text>
				<Text variant="secondaryBody2" overrideMUIColor="WHITE">
					{t('farmSalesPage.wasteVolume')}
				</Text>
			</Box>
			<Box textAlign="end" display="flex" flexDirection="column">
				<Text variant="label" overrideMUIColor="WHITE">
					{harvestBatchSoldVolume(harvestBatch, isImperial)}
				</Text>
				<Text variant="label" overrideMUIColor="WHITE">
					{harvestBatchStorageVolume(harvestBatch, isImperial)}
				</Text>
				<Text variant="label" overrideMUIColor="WHITE">
					{harvestBatchWasteVolume(harvestBatch, isImperial)}
				</Text>
			</Box>
		</Box>
	);

	return (
		<>
			<Box
				paddingY="0.75rem"
				display="flex"
				justifyContent="space-between"
				width="100%"
				paddingLeft="1.5rem"
				paddingRight="1rem"
				onClick={clickHandler}
			>
				<Box display="flex" flexDirection="column">
					<Text>
						{`
							${harvestBatchDateString(harvestBatch)} - 
							${harvestBatchVariety(harvestBatch)} - 
							${harvestBatchTotalVolume(harvestBatch, isImperial)}
							`}
					</Text>
					<Text>{harvestBatchFieldName(harvestBatch)}</Text>
				</Box>
				<Tooltip arrow placement="left" title={tooltipContent}>
					<div style={{ alignSelf: 'center' }}>
						<Box display="flex" alignItems="center">
							<CircularProgressChart
								value={harvestBatchPercentageStorageVolume(harvestBatch)}
							/>
						</Box>
					</div>
				</Tooltip>
			</Box>
			<Divider />
		</>
	);
};

export default BatchItem;
