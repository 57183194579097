import React, { useMemo, useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useLocationID from 'Hooks/useLocationID';

import GET_LOCATION_MEMBERS from '../../../pages/profile/TeamsPage/api/getLocationMembers';
import DropdownContent, { DROPDOWN_ITEM_DIVIDER } from '../DropdownContent';
import { GET_TEAMS_MEMBERS_QUERY } from './api/getTeamMembers';

type TTeamMembersProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
	includeLocationMembers?: boolean;
};

function TeamMembers(props: TTeamMembersProps) {
	const { filters, updateFilters, includeLocationMembers = false } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();
	const locationId = useLocationID();
	const { data, loading, error } = useQuery(GET_TEAMS_MEMBERS_QUERY, {
		variables: {
			locationId: locationId,
			filterBy: {
				teamIds: filters.teamId,
			},
		},
	});
	const { data: locationMembersData } = useQuery(GET_LOCATION_MEMBERS, {
		variables: { locationId: locationId },
		skip: !includeLocationMembers,
	});

	if (error) {
		throw new ApolloError(error);
	}

	const open = Boolean(anchorEl);
	const teamMembers = data?.getTeamMembersPageable.teamMembers || [];
	const locationMembers =
		locationMembersData?.members.map(
			({ account }) => `${account.firstName || ''} ${account.lastName || ''}`
		) || [];
	const dropdownContentData = [
		...teamMembers.map((teamMember) => teamMember.fullName),
		DROPDOWN_ITEM_DIVIDER,
		...locationMembers,
	].filter((elem) =>
		includeLocationMembers ? true : elem !== DROPDOWN_ITEM_DIVIDER
	);

	const appliedMemberIds = filters.teamMemberIds || [];
	const locationMemberIds = filters.teamLeadIds || [];

	const appliedTeamMembers = useMemo(
		() =>
			teamMembers
				.filter((teamMember) => appliedMemberIds.includes(teamMember.id))
				.map((teamMember) => teamMember.fullName),
		[appliedMemberIds, teamMembers]
	);

	const appliedLocationMembers =
		locationMembersData?.members
			.filter(({ account }) => locationMemberIds.includes(account.id))
			.map(
				({ account }) => `${account.firstName || ''} ${account.lastName || ''}`
			) || [];

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const onItemClick = (teamMemberName: string, active: boolean) => {
		const teamMemberId = teamMembers?.find(
			(teamMember) => teamMember.fullName === teamMemberName
		)?.id;

		const locationMemberId = locationMembersData?.members.find(
			({ account }) =>
				`${account.firstName || ''} ${account.lastName || ''}` ===
				teamMemberName
		)?.account.id;

		if (teamMemberId) {
			const newMemberIds = active
				? appliedMemberIds.filter(
						(appliedMemberId) => appliedMemberId !== teamMemberId
				  )
				: [...appliedMemberIds, teamMemberId];

			updateFilters({
				...filters,
				teamMemberIds: newMemberIds,
			});
		} else if (locationMemberId) {
			const newLocationMemberIds = active
				? locationMemberIds.filter(
						(appliedLocationMemberId) =>
							appliedLocationMemberId !== locationMemberId
				  )
				: [...locationMemberIds, locationMemberId];

			updateFilters({
				...filters,
				teamLeadIds: newLocationMemberIds,
			});
		}
	};

	const onClear = () => {
		updateFilters({
			...filters,
			teamMemberIds: undefined,
			teamLeadIds: undefined,
		});
	};

	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{t('labels.teamMembers')}
				{appliedMemberIds?.length ? ` (${appliedMemberIds?.length})` : ''}
			</Button>
			<Menu.Menu
				id="category-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={loading}
					dropdownItems={dropdownContentData}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={[...appliedTeamMembers, ...appliedLocationMembers]}
				/>
			</Menu.Menu>
		</>
	);
}

export default TeamMembers;
