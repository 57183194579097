import React, { ReactElement } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import MUIRadio, { RadioProps } from '@mui/material/Radio';
import Text from 'UI/display/Text';

type TCheckboxControlledProps = {
	value: unknown;
	className?: string;
	checked?: boolean;
	valueChangeHandler?: React.Dispatch<React.SetStateAction<string>>;
	disableRipple?: boolean;
	disabled?: boolean;
	label?: string;
	id?: string;
	size?: RadioProps['size'];
};

const Controlled = (props: TCheckboxControlledProps): ReactElement => {
	const {
		className,
		checked,
		valueChangeHandler,
		disabled,
		label,
		value,
		id,
		disableRipple,
		size,
	} = props;

	function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
		if (valueChangeHandler) {
			valueChangeHandler(event.target.value);
		}
	}

	return (
		<FormControlLabel
			label={<Text variant="body2">{label}</Text>}
			disabled={disabled}
			value={value}
			control={
				<MUIRadio
					id={id}
					className={className}
					checked={checked}
					onChange={handleChange}
					color={'primary'}
					value={value}
					disableRipple={disableRipple}
					size={size}
					sx={{ mr: '0.125rem' }}
				/>
			}
		/>
	);
};

export default Controlled;
