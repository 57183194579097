import { useEffect } from 'react';

import startIntercom from 'Features/intercom/startIntercom';

import useProfile from 'Hooks/useProfile';

const APP_ID = process.env.INTERCOM_APP_ID;

function Guest() {
	if (APP_ID) {
		startIntercom(APP_ID, null);
	}

	return null;
}

function User() {
	const { profile } = useProfile();

	useEffect(() => {
		const intercomProfile = profile
			? {
					name: `${profile.firstName} ${profile.lastName}`,
					userId: profile.id,
					userHash: profile.intercomHash.intercomHashWeb,
			  }
			: null;

		if (APP_ID && intercomProfile) {
			startIntercom(APP_ID, intercomProfile);
		}
	}, [
		profile?.id,
		profile?.firstName,
		profile?.lastName,
		profile?.intercomHash.intercomHashWeb,
	]);

	return null;
}

export default {
	Guest,
	User,
};
