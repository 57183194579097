import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import GET_PARTNERSHIP_LOCATIONS from '../../features/api/getPartnerLocations';
import DropdownContent from './DropdownContent';

type TPartnerLocationsProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const PartnerLocations = (props: TPartnerLocationsProps) => {
	const { filters, updateFilters } = props;
	const { data, loading, error } = useQuery(GET_PARTNERSHIP_LOCATIONS, {
		variables: {
			filterBy: {
				statuses: ['ACTIVE'],
			},
		},
	});
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const appliedLocations = filters.locations || [];

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const dropdownLocationData = data?.getPartnerLocations.map(
		(partnerLocation) => partnerLocation.name
	);
	const dropdownAppliedLocations = data?.getPartnerLocations
		.filter((partnerLocation) => appliedLocations.includes(partnerLocation.id))
		.map((location) => location.name);

	if (error) {
		throw new ApolloError(error);
	}

	const onItemClick = (location, active) => {
		const chosenLocation = data?.getPartnerLocations.find(
			(partnerLocation) => partnerLocation.name === location
		);
		if (chosenLocation) {
			const newLocations = active
				? appliedLocations.filter(
						(appliedLocation) => appliedLocation !== chosenLocation?.id
				  )
				: [...appliedLocations, chosenLocation?.id];

			updateFilters({
				...filters,
				locations: newLocations,
			});
		}
	};

	const onClear = () => {
		updateFilters({
			...filters,
			locations: undefined,
		});
	};

	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
				sx={{ marginRight: '0.5rem' }}
			>
				{t('buttons.locations')}
				{appliedLocations?.length ? `(${appliedLocations?.length})` : ''}
			</Button>
			<Menu.Menu
				id="category-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={loading}
					dropdownItems={dropdownLocationData}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={dropdownAppliedLocations}
				/>
			</Menu.Menu>
		</>
	);
};

export default PartnerLocations;
