import React, { ReactElement, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';
import TextField from 'UI/inputs/TextField';

import getDebounce from 'Utils/getDebounce';
import isPositiveNumber from 'Utils/isPositiveNumber';
import numberToDisplayString from 'Utils/numberToDisplayString';

const debounced = getDebounce();

type TProductiveAreaProps = {
	area: TFieldInput['productiveArea'];
	setArea: (area: number) => void;
};

function ProductiveArea(props: TProductiveAreaProps): ReactElement {
	const { area, setArea } = props;
	const [inputValue, setInputValue] = useState(() =>
		numberToDisplayString(area)
	);
	const { t } = useTranslation();
	const showError = Boolean(inputValue && !isPositiveNumber(inputValue));

	function changeAreaHandler(value) {
		const sanitizedArea = numberToDisplayString(value);
		setInputValue(sanitizedArea);
		const updateInput = () => setArea(value);
		debounced(updateInput);
	}

	return (
		<TextField
			value={inputValue}
			label={t('labels.productiveArea')}
			type={'number'}
			onChange={changeAreaHandler}
			helperText={
				showError ? t('helpers.positiveValuesOnly') : t('helpers.inHectares')
			}
			inputProps={{ min: 0, step: 0.1 }}
			error={showError}
		/>
	);
}

export default ProductiveArea;
