import React from 'react';

import { useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_PARTNER_HOURS_TABLE_DATA from '../api/getPartnerHoursTableData';

const Pagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();
	const pageInput = searchParams.pageInput;

	const { data, loading } = useQuery(GET_PARTNER_HOURS_TABLE_DATA, {
		variables: {
			filterBy: {
				timesheetFilters: {
					timeFrom: searchParams.filters.timeFrom,
					timeTo: searchParams.filters.timeTo,
					categories: searchParams.filters.categories,
				},
				locationIds: searchParams.filters.locations,
			},
			pageInput,
			hasPagination: true,
		},
	});

	return (
		<TablePagination
			count={data?.getPartnerTimesheets.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
