import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const jobInitialDateEnd = (jobs: Pick<TJob, 'completedDate'>[]) => {
	const latestTimeInMs = Math.max(
		...jobs.map((job) =>
			job.completedDate ? toLocalDate(job.completedDate).getTime() : 0
		)
	);

	return latestTimeInMs > 0 ? new Date(latestTimeInMs) : null;
};

export default jobInitialDateEnd;
