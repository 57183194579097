import React from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import jobDateCompletedString from 'Models/jobs/jobDateCompletedString';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';

import getErrorMessage from 'Utils/getErrorMessage';

import Causes from '../../../common/components/DetailModal/Causes';
import Comments from '../../../common/components/DetailModal/Comments';
import Fields from '../../../common/components/DetailModal/Fields';
import Information from '../../../common/components/DetailModal/Information';
import Photos from '../../../common/components/DetailModal/Photos';
import ProductDetails from '../../../common/components/DetailModal/ProductDetails';
import SafeSpraying from '../../../common/components/DetailModal/SafeSpraying';
import Stats from '../../../common/components/DetailModal/Stats';
import Weather from '../../../common/components/DetailModal/Weather';
import GET_PARTNER_JOB_MODAL_DATA from '../../api/getPartnerJobModalData';
import { detailsModalData, isDetailsModalOpen } from '../../state';

const JobDetailsModal = () => {
	const { t } = useTranslation();
	const isOpen = useReactiveVar(isDetailsModalOpen);
	const tableData = useReactiveVar(detailsModalData);
	const { data, loading, error } = useQuery(GET_PARTNER_JOB_MODAL_DATA, {
		skip: !tableData?.id || !tableData?.location.id,
		variables: {
			id: tableData?.id || '',
			locationId: tableData?.location.id || '',
		},
	});
	const jobData = data?.job;

	function onClose() {
		isDetailsModalOpen(false);
	}

	return loading ? null : (
		<Modal.UncontrolledModal open={isOpen} toggleOpen={onClose}>
			<Modal.DialogTitle
				title={jobData ? jobDateCompletedString(jobData) : '-'}
				closeIconHandler={onClose}
			/>
			<Modal.DialogContent>
				{error && getErrorMessage(error)}
				{jobData && (
					<>
						<Information job={{ ...jobData, location: tableData?.location }} />
						<Stats job={jobData} />
						<Weather job={jobData} />
						<Fields job={jobData} />
						<Causes job={jobData} />
						<ProductDetails job={jobData} />
						<Photos job={jobData} />
						<SafeSpraying job={jobData} />
						<Comments job={jobData} />
					</>
				)}
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Button variant="outlined" onClick={onClose}>
					{t('buttons.close')}
				</Button>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default JobDetailsModal;
