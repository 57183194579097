import { gql, TypedDocumentNode } from '@apollo/client';
import { TTimesheetSettingsInput } from 'Models/timesheet/isTimesheetSettingsInput';

const EDIT_TIMESHEET_SETTINGS_MUTATION: TypedDocumentNode<
	{ editTimesheetSettings: TTimesheetSettingsInput },
	{ locationId: string; input: TTimesheetSettingsInput }
> = gql`
	mutation EditTimesheetSettings(
		$locationId: ID!
		$input: TimesheetSettingsInput!
	) {
		editTimesheetSettings(locationId: $locationId, input: $input) {
			subtractLunchTime
			minDurationForSubtractingLunch
			lunchTimeDuration
			pricePerHour
			currency
		}
	}
`;

export default EDIT_TIMESHEET_SETTINGS_MUTATION;
