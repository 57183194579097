import { ReactNode } from 'react';

const HARVEST_LOGS_TABLE_HEADERS = [
	'date',
	'field',
	'category',
	'variety',
	'boxes',
	'weight',
	'responsible',
	'teams',
	'teamMembers',
] as const;

const HarvestLogsTableHeaderValues = [...HARVEST_LOGS_TABLE_HEADERS];

export type THarvestLogsTableHeader = typeof HARVEST_LOGS_TABLE_HEADERS[number];

export default HarvestLogsTableHeaderValues;

export type THarvestLogsTableData = {
	[key in typeof HARVEST_LOGS_TABLE_HEADERS[number]]: ReactNode;
};
