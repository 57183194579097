// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".N1WhG3VixJbl5RVWVlZAj {\n  height: 100%;\n  display: flex;\n}\n\n._1WMSfLKn-_rZIaNuVFmJLw {\n  margin: auto .625rem auto auto;\n  color: rgba(0,0,0,.6);\n}\n\n._3mFl-BhNWIzq5UTxpIQUzq {\n  margin: auto 1rem auto auto;\n}\n\n._1qN1QK60mgVWV9KdlVcuyG {\n  max-width: 42.5rem;\n  margin: 0 .5rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"navbarLink": "N1WhG3VixJbl5RVWVlZAj",
	"navbarIcon": "_1WMSfLKn-_rZIaNuVFmJLw",
	"navbarBackTextLink": "_3mFl-BhNWIzq5UTxpIQUzq",
	"libraryCard": "_1qN1QK60mgVWV9KdlVcuyG"
};
module.exports = exports;
