import { URLParamsType } from 'Models/history/isURLParams';
import { useHistory } from 'react-router-dom';

import getSearchParamsFromURL from '../utils/getSearchParamsFromURL';
import paramsFromURL from '../utils/paramsFromURL';
import searchParamsToURL from '../utils/paramsToURL';

const useManageHistory = () => {
	const history = useHistory();
	const currentURL: string =
		history.location.pathname + history.location.search;

	const historyPush = (params: URLParamsType) => {
		const searchURL = searchParamsToURL(currentURL, params);
		const searchParams = getSearchParamsFromURL(searchURL);

		return history.push(searchParams);
	};

	return {
		currentLocation: history.location,
		historyPush: historyPush,
		paramsFromURL: paramsFromURL(currentURL),
	};
};

export default useManageHistory;
