import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTimesheetSettings } from 'Models/timesheet/isTimesheetSettings';
import { isTimesheetSettingsInput } from 'Models/timesheet/isTimesheetSettingsInput';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import Switch from 'UI/inputs/Switch';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import isEqual from 'lodash/isEqual';

import useLocationID from 'Hooks/useLocationID';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import removeTypename from 'Utils/removeTypename';

import EDIT_TIMESHEET_SETTINGS_MUTATION from '../api/editTimesheetSettingsMutation';
import GET_TIMESHEET_SETTINGS_DATA from '../api/getTimesheetSettingsData';
import HoursCostInputs, { THoursCostInputs } from './HoursCostInputs';
import LunchTimeSettingsInputs, {
	TLunchTimeSettingsInputs,
} from './LunchTimeSettingsInputs';

type TLunchTimeSettingsProps = {
	timesheetSettings: TTimesheetSettings;
};

const LunchTimeSettings = (props: TLunchTimeSettingsProps) => {
	const { timesheetSettings } = props;
	const locationId = useLocationID();
	const { t } = useTranslation();

	const [subtractLunchTime, setSubtractLunchTime] = useState(
		timesheetSettings.subtractLunchTime
	);
	const [hoursCostInputs, setHoursCostInputs] = useState<THoursCostInputs>({
		pricePerHour: timesheetSettings.pricePerHour,
		currency: timesheetSettings.currency,
	});
	const [lunchTimeSettingsInputs, setLunchTimeSettingsInputs] =
		useState<TLunchTimeSettingsInputs>({
			minDurationForSubtractingLunch:
				timesheetSettings.minDurationForSubtractingLunch,
			lunchTimeDuration: timesheetSettings.lunchTimeDuration,
		});

	const [updateTimesheetSettings, updateTimesheetSettingsTask] = useMutation(
		EDIT_TIMESHEET_SETTINGS_MUTATION,
		{
			refetchQueries: [GET_TIMESHEET_SETTINGS_DATA],
		}
	);
	const input = {
		...hoursCostInputs,
		...lunchTimeSettingsInputs,
		subtractLunchTime,
	};

	const hasChangedInput = !isEqual(input, removeTypename(timesheetSettings));
	const isValid = isTimesheetSettingsInput(input);

	const isSaveButtonDisabled =
		!(hasChangedInput && isValid) || updateTimesheetSettingsTask.loading;

	useSnackbarNotifications({
		mutationResult: updateTimesheetSettingsTask,
		messageSuccess: t('timeSettingsPage.settingsUpdateSuccess'),
		messageFailure: t('timeSettingsPage.errors.save'),
	});

	function handleSave() {
		if (input) {
			void updateTimesheetSettings({
				variables: {
					locationId,
					input,
				},
			});
		}
	}

	return (
		<>
			<Box mb="1.5rem">
				<Text mb="0.5rem">{t('labels.estimatedAverageCost')}</Text>
				<Text variant="secondaryBody1">
					{t('prompts.estimatedAverageCost')}
				</Text>
				<HoursCostInputs
					hoursCostInputs={hoursCostInputs}
					setHoursCostInputs={setHoursCostInputs}
				/>
			</Box>

			<Divider />

			<Box mt="1.5rem">
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item>
						<Text>{t('labels.lunchTime')}</Text>
					</Grid>
					<Grid item>
						<Switch.Controlled
							checked={subtractLunchTime}
							checkedChangeHandler={setSubtractLunchTime}
						/>
					</Grid>
				</Grid>
				<Text variant="secondaryBody1">{t('prompts.lunchTime')}</Text>
				{subtractLunchTime ? (
					<LunchTimeSettingsInputs
						lunchTimeSettingsInputs={lunchTimeSettingsInputs}
						setLunchTimeSettingsInputs={setLunchTimeSettingsInputs}
					/>
				) : null}
			</Box>
			<Box
				display="flex"
				justifyContent="flex-end"
				alignItems="center"
				pt="1.75rem"
				pb="0.875rem"
			>
				<>
					{updateTimesheetSettingsTask.loading ? <LoadingIcon /> : null}
					<Button
						disableElevation
						disabled={isSaveButtonDisabled}
						onClick={handleSave}
					>
						{t('buttons.saveChanges')}
					</Button>
				</>
			</Box>
		</>
	);
};

export default LunchTimeSettings;
