import React from 'react';

import { useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_CURRENT_INV_TABLE_DATA from '../api/getCurrentInventoryTableData';

const Pagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const { data, loading } = useQuery(GET_CURRENT_INV_TABLE_DATA, {
		variables: {
			filterBy: {
				productName: searchParams.filters.search,
			},
			pageInput: searchParams.pageInput,
			locationId: searchParams.locationID,
			hasPagination: true,
		},
	});

	return (
		<TablePagination
			count={data?.getProductInventory.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
