import { gql, TypedDocumentNode } from '@apollo/client';
import { TCustomer } from 'Models/customers/isCustomer';

const GET_CUSTOMERS_DATA: TypedDocumentNode<
	{
		getCustomers: TCustomer[];
	},
	{ locationId: string }
> = gql`
	query GetCustomers($locationId: ID!) {
		getCustomers(locationId: $locationId) {
			id
			name
			image {
				id
				path
				filename
				mimetype
				createdAt
			}
			archived
			hasSales
			country
			city
			zip
			address
		}
	}
`;

export default GET_CUSTOMERS_DATA;
