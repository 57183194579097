import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useParams from 'Features/router/hooks/useParams';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import { GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS } from '../../../api/getTreatmentLibraryProducts';
import styles from '../styles.module.scss';
import AddTreatmentModal from './CreateTreatmentModal';
import DeleteProductModal from './DeleteProductModal';
import { ProductListItem } from './ProductListItem';

function TreatmentsCard() {
	const { t } = useTranslation();
	const { libraryID } = useParams();
	const [selectedProduct, setSelectedProduct] = useState('');
	const { data, loading, error } = useQuery(
		GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS,
		{ variables: { libraryId: libraryID }, skip: !libraryID }
	);

	const handleToggleAccordionClick = (id: string) => {
		if (id === selectedProduct) {
			setSelectedProduct('');
		} else {
			setSelectedProduct(id);
		}
	};

	const treatmentsCount = t('treatmentsLibraryPage.card.productWithCount', {
		count: data?.getTreatmentLibraryProducts.products?.length || 0,
	});

	if (error) {
		throw new ApolloError(error);
	}

	const content = loading ? (
		<Box display="flex" justifyContent="center" padding="1rem">
			<LoadingSpinner width={40} height={40} />
		</Box>
	) : (
		data?.getTreatmentLibraryProducts?.products?.map((product) => (
			<ProductListItem
				key={product.id}
				product={product}
				isProductSelected={selectedProduct === product.id}
				handleToggleAccordionClick={handleToggleAccordionClick}
			/>
		))
	);

	return (
		<Card className={styles.libraryCard}>
			<CardContent className={styles.libraryCardContent}>
				<Box
					display="flex"
					justifyContent="space-between"
					className={styles.libraryCardHeader}
				>
					<Text variant="h3">{treatmentsCount}</Text>
					<AddTreatmentModal />
					<DeleteProductModal />
				</Box>
				{content}
			</CardContent>
		</Card>
	);
}

export default TreatmentsCard;
