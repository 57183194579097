import { TTranslateFunction } from 'Features/localization';

import { TJob } from './isJob';
import jobCategories from './jobCategories';

export default function jobCategoriesString(
	job: Pick<TJob, 'jobFields'>,
	t: TTranslateFunction
) {
	return jobCategories(job, t).join(', ') || '-';
}
