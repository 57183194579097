import React from 'react';

import { URLFiltersType } from 'Models/history/isURLFilters';

import useManageHistory from 'Hooks/useManageHistory';

import Varieties from '../../../../../components/filters/Varieties';

const VarietiesFilter = () => {
	const { paramsFromURL, historyPush } = useManageHistory();

	const onChange = (filters: URLFiltersType) => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				varieties: filters.varieties,
			},
		};

		historyPush(newSearchParams);
	};

	return paramsFromURL.filters ? (
		<Varieties filters={paramsFromURL.filters} updateFilters={onChange} />
	) : null;
};

export default VarietiesFilter;
