import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ConfirmedIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 88 88" sx={{ width: 64, height: 64 }} {...props}>
			<defs>
				<linearGradient
					id="jnmared1tb"
					x1="14.169%"
					x2="83.903%"
					y1="13.954%"
					y2="84.577%"
				>
					<stop offset="0%" stopColor="#FFF" stopOpacity="0" />
					<stop offset="100%" stopOpacity=".5" />
				</linearGradient>
				<path
					id="dwdbzou5ba"
					d="M5.723 19.586l-4.95 4.95c-.78.78-.78 2.047 0 2.828L17.39 43.981c.781.781 2.048.781 2.829 0l4.95-4.95c.78-.78.78-2.047 0-2.828L8.55 19.586c-.78-.781-2.047-.781-2.828 0z"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g>
					<path
						fill="#008767"
						d="M0 44c0 24.307 19.7 44 44 44s44-19.693 44-44C88 19.706 68.3 0 44 0S0 19.706 0 44z"
						transform="translate(-756 -184) translate(461 120) translate(295 64)"
					/>
					<g>
						<g transform="translate(-756 -184) translate(461 120) translate(295 64) translate(15 21)">
							<use fill="#FFF" xlinkHref="#dwdbzou5ba" />
							<use fill="url(#jnmared1tb)" xlinkHref="#dwdbzou5ba" />
						</g>
						<path
							fill="#FFF"
							d="M56.722 5.536l-4.95-4.95c-.78-.781-2.047-.781-2.828 0L11.467 38.062l6.364 6.364c.782.781 2.048.781 2.829 0L56.722 8.364c.781-.781.781-2.047 0-2.828z"
							transform="translate(-756 -184) translate(461 120) translate(295 64) translate(15 21)"
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
}

export default ConfirmedIcon;
