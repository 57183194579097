import { gql, TypedDocumentNode } from '@apollo/client';
import { TCustomer } from 'Models/customers/isCustomer';

const DELETE_CUSTOMER_MUTATION: TypedDocumentNode<
	{
		createCustomer: Pick<TCustomer, 'id'>;
	},
	{
		locationId: string;
		customerId: string;
	}
> = gql`
	mutation DeleteCustomer($locationId: ID!, $customerId: ID!) {
		deleteCustomer(locationId: $locationId, customerId: $customerId) {
			id
		}
	}
`;

export default DELETE_CUSTOMER_MUTATION;
