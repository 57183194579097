import React, { useState, useEffect } from 'react';

import {
	Chip as MUIChip,
	Autocomplete as MUIAutocomplete,
	TextField as MUITextField,
} from '@mui/material';
import { useTranslation } from 'Features/localization/hooks/useTranslation';

import Text from '../../display/Text';
import Close from '../../icons/Close';
import ErrorIcon from '../../icons/Error';
import Box from '../../layout/Box';
import { FarmableColors, FarmableTagsColors } from '../../theme/Colors';
import { validateEmail } from '../../utils/validateEmail';

type TTextInputWIthTagsProps = {
	emailValidation?: boolean;
	customMinHeight?: string;
	items: string[];
	itemsHandleControl: (input: string[], indexArr?: number[]) => void;
	inputPlaceholder?: string;
	multiple?: boolean;
};

// implemented for copy & paste reasons - accepted formats are:
// name@email.com name@email.com name@email.com;
// name@email.com,name@email.com,name@email.com;
// name@email.com, name@email.com, name@email.com;
const parseInput = (inputValue) =>
	inputValue.split(/[ ,]+/).filter((input: string) => !!input);

const getChipColor = (item: string) =>
	validateEmail(item) ? FarmableTagsColors.LIGHT_GREEN : FarmableTagsColors.RED;

const TextInputWithTags = (props: TTextInputWIthTagsProps) => {
	const {
		emailValidation,
		customMinHeight,
		itemsHandleControl,
		items,
		inputPlaceholder,
		multiple = true,
	} = props;
	const [inputValue, setInputValue] = useState('');

	const invalidItemsIndices = items
		.map((item, index) => !validateEmail(item) && index)
		.filter((i): i is number => typeof i === 'number');

	useEffect(() => {
		setInputValue('');
	}, [items]);

	const { t } = useTranslation();

	const removeItems = (wrongItemsIdx: number[]) => {
		const newSelectedItem = [...items].filter(
			(_, index) => !wrongItemsIdx.includes(index)
		);
		itemsHandleControl(newSelectedItem, wrongItemsIdx);
	};

	const getErrorMessage = () => {
		if (invalidItemsIndices.length) {
			return (
				<>
					<ErrorIcon
						sx={{
							width: '1rem',
							height: '1rem',
							marginBottom: '-3px',
							marginLeft: '-0.75rem',
							fill: FarmableColors.RED,
						}}
					/>
					<Text marginLeft="0.25rem" variant="body3" component="span">
						{t('companyWelcomePage.errorsAmount', {
							amount: invalidItemsIndices.length,
						})}
						&nbsp;
					</Text>
					<Text
						variant="body3"
						sx={{
							textDecoration: 'underline',
							cursor: 'pointer',
						}}
						onClick={() => removeItems(invalidItemsIndices)}
						component="span"
					>
						{t('companyWelcomePage.removeErrors')}
					</Text>
				</>
			);
		}
	};

	const renderTags = items.map((item, index) => {
		return (
			<MUIChip
				key={item + index}
				label={item}
				icon={
					emailValidation && !validateEmail(item) ? (
						<ErrorIcon fontSize="small" color="error" />
					) : undefined
				}
				onDelete={() => {
					items.splice(index, 1);
					itemsHandleControl(items, [index]);
				}}
				deleteIcon={<Close fontSize="small" color="secondary" />}
				sx={{
					marginRight: '0.5rem',
					backgroundColor: emailValidation ? getChipColor(item) : undefined,
					border:
						emailValidation && !validateEmail(item)
							? `dashed 1px ${FarmableColors.RED}`
							: undefined,
					borderRadius: '0.25rem',
					marginBottom: 'auto',
					marginTop: '0.25rem',
				}}
			/>
		);
	});

	return (
		<MUIAutocomplete
			multiple={multiple}
			id="tags-filled"
			options={[]}
			freeSolo
			disableClearable
			renderTags={() => (
				<Box maxHeight="11.25rem" sx={{ overflowY: 'auto' }}>
					{renderTags}
				</Box>
			)}
			value={items}
			inputValue={inputValue}
			renderInput={(params) => (
				<MUITextField
					{...params}
					variant="outlined"
					placeholder={inputPlaceholder || undefined}
					helperText={emailValidation ? getErrorMessage() : null}
					onChange={(e) => setInputValue(e.target.value)}
					onKeyDown={(e: any) => {
						if (
							e.key == ' ' ||
							e.code == 'Space' ||
							e.keyCode == 32 ||
							e.key == 'Enter' ||
							e.keyCode == 13
						) {
							e.preventDefault();
							if (e.target.value.length > 0) {
								const inputValue = parseInput(e.target.value);
								itemsHandleControl([...items, ...inputValue]);
							}
						}
					}}
					sx={{
						'& .MuiInputBase-root': {
							minHeight: customMinHeight ? customMinHeight : undefined,
							paddingTop: '0.313rem',
							alignItems: 'flex-start',
						},
						'& .MuiInputBase-input': {
							marginBottom: 'auto',
							marginTop: '0.125rem',
						},
					}}
				/>
			)}
		/>
	);
};

export default TextInputWithTags;
