import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function FacebookIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				fill="#1877F2"
				fillRule="nonzero"
				d="M24 12.073C24 5.406 18.627 0 12 0S0 5.406 0 12.073C0 18.1 4.388 23.094 10.125 24v-8.435H7.078v-3.492h3.047v-2.66c0-3.025 1.792-4.696 4.533-4.696 1.312 0 2.686.235 2.686.235v2.971H15.83c-1.491 0-1.956.93-1.956 1.886v2.264h3.328l-.532 3.49h-2.796V24C19.612 23.094 24 18.1 24 12.073"
				transform="translate(-96 -514) translate(80 444) translate(16 70)"
			/>
		</SvgIcon>
	);
}

export default FacebookIcon;
