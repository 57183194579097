// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1d0prZKmFm5rUBPz8YqjBl {\n  height: 100%;\n  overflow: auto;\n  padding-bottom: 1rem;\n}\n\n._2DhCX_6wsofxef8585cmqD {\n  margin: auto;\n  max-width: 70rem;\n  width: 100%;\n}\n\n", ""]);
// Exports
exports.locals = {
	"dashRoot": "_1d0prZKmFm5rUBPz8YqjBl",
	"dashContent": "_2DhCX_6wsofxef8585cmqD"
};
module.exports = exports;
