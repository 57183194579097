import isOneOf from 'vet/isOneOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

const _isImage = isShape({
	id: optional(isOneOf(isString)),
	path: optional(isString),
	filename: optional(isString),
	mimetype: optional(isString),
	createdAt: optional(isString),
});

// https://github.com/microsoft/TypeScript/issues/34596
const isImage: typeof _isImage = _isImage;

export type TImage = ValidatorType<typeof isImage>;

export default isImage;
