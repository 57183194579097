import { gql, TypedDocumentNode } from '@apollo/client';
import { TPaymentProduct } from 'Models/stripe/isPaymentProduct';

export type TGetPaymentProductsResult = {
	getPaymentProducts: TPaymentProduct[];
};

type TGetPaymentProductsInput = {
	locationId: string;
};

const GET_MODAL_PAYMENT_PRODUCTS: TypedDocumentNode<
	TGetPaymentProductsResult,
	TGetPaymentProductsInput
> = gql`
	query getPaymentProducts($locationId: ID!) {
		getPaymentProducts(locationId: $locationId) {
			id
			name
			description
			prices {
				id
				type
				currency
				unit_amount
				recurring {
					interval
					interval_count
				}
			}
			images
		}
	}
`;

export default GET_MODAL_PAYMENT_PRODUCTS;
