import { TTranslateFunction } from 'Features/localization';
import { AreaUnits } from 'Models/units';

import sumOtherVarieties from '../../../common/components/CropSummary/utils/sumOtherVarieties';
import { TCropCategoryData, TCropSummaryCategory } from './types';

const MAX_VAR_NUMBER = 5;

function getSummarySectionData(
	t: TTranslateFunction,
	data: TCropCategoryData[]
): TCropSummaryCategory[] {
	const otherCategoryName = t('productCategories.other');

	const sortedVarieties = data
		.slice()
		.sort((a, b) => b.areaSize - a.areaSize)
		.map((variety: TCropCategoryData) => ({
			...variety,
			areaUnit: AreaUnits.HECTARE,
		}));

	if (sortedVarieties.length > MAX_VAR_NUMBER) {
		const otherVarieties = sumOtherVarieties({
			varieties: sortedVarieties.slice(MAX_VAR_NUMBER),
			areaUnit: AreaUnits.HECTARE,
			category: otherCategoryName,
		});

		const deleteCount = sortedVarieties.length - MAX_VAR_NUMBER;

		sortedVarieties.splice(MAX_VAR_NUMBER, deleteCount, otherVarieties);
	}

	return sortedVarieties;
}

export default getSummarySectionData;
