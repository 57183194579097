import { TSeasonParams } from './isSeasonParams';

function getSeasonPerDay(seasonCutoffDate: string, day: Date): TSeasonParams {
	const year = day.getFullYear();
	const cutoffDateForYear = new Date(`${seasonCutoffDate}-${year}`);
	const hasDefaultSeasonCutoffDate = seasonCutoffDate === '01-01';

	// Season starts and ends same as a year
	if (hasDefaultSeasonCutoffDate) {
		return {
			from: `${year}-01-01`,
			to: `${year + 1}-01-01`,
			seasonName: `${year}`,
			seasonCutoffDate: seasonCutoffDate,
		};
	}

	// When a given day exceeds the cutoff date, return the new season
	if (day > cutoffDateForYear) {
		return {
			from: `${year}-${seasonCutoffDate}`,
			to: `${year + 1}-${seasonCutoffDate}`,
			seasonName: `${year}/${year + 1}`,
			seasonCutoffDate: seasonCutoffDate,
		};
	}

	return {
		from: `${year - 1}-${seasonCutoffDate}`,
		to: `${year}-${seasonCutoffDate}`,
		seasonName: `${year - 1}/${year}`,
		seasonCutoffDate: seasonCutoffDate,
	};
}

export default getSeasonPerDay;
