import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

const _isLocationID = isString;

// https://github.com/microsoft/TypeScript/issues/34596
const isLocationID: typeof _isLocationID = _isLocationID;

export type LocationIDType = ValidatorType<typeof isLocationID>;

export default isLocationID;
