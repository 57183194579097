import { gql, TypedDocumentNode } from '@apollo/client';

export type TGetCompanyAccessesDataResponse = {
	getCompanyDataAccesses: {
		fields: boolean;
		jobs: boolean;
		harvest: boolean;
		sales: boolean;
	};
};

export const GET_COMPANY_ACCESSES_DATA: TypedDocumentNode<TGetCompanyAccessesDataResponse> = gql`
	query GetCompanyDataAccesses {
		getCompanyDataAccesses {
			locationId
			fields
			jobs
			harvest
			sales
		}
	}
`;
