import { ApolloError, useQuery } from '@apollo/client';

import hasPartnerCompany from 'Utils/hasPartnerCompany';

import GET_PARTNERSHIP_LOCATIONS_DATA, {
	TPartnerLocationsResponse,
} from '../features/api/getPartnerLocations';
import useProfileRoles from './useProfileRoles';

function usePartnerLocations(): {
	partnerLocations: TPartnerLocationsResponse['getPartnerLocations'] | null;
	loading: boolean;
} {
	const { profileRoles } = useProfileRoles();
	const { data, error, loading } = useQuery(GET_PARTNERSHIP_LOCATIONS_DATA, {
		skip: !hasPartnerCompany(profileRoles),
	});

	if (error) {
		throw new ApolloError(error);
	}

	return { partnerLocations: data?.getPartnerLocations || null, loading };
}

export default usePartnerLocations;
