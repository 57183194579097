import React from 'react';

import { TPartnerLocations } from 'Models/partnershipCompany/isPartnerLocation';
import hasSharedLocationCoordinate from 'Models/sharedLocations/hasSharedLocationCoordinate';
import Text from 'UI/display/Text';
import Pin from 'UI/icons/Pin';
import CustomMarker from 'UI/maps/CustomMarker';
import Tooltip from 'UI/maps/Tooltip';

type TSharedLocationsMarkerProps = {
	sharedLocations: TPartnerLocations;
	showLocationsName?: boolean;
	onLocationMarkerClick?: (locationId: string) => void;
};

const SharedLocationsMarker = (props: TSharedLocationsMarkerProps) => {
	const { sharedLocations, showLocationsName, onLocationMarkerClick } = props;

	return (
		<>
			{sharedLocations
				? sharedLocations
						.filter(hasSharedLocationCoordinate)
						.map((sharedLocation) => {
							const { x, y } = sharedLocation.coordinate;
							const onLocationClick = () =>
								onLocationMarkerClick
									? onLocationMarkerClick(sharedLocation.id)
									: null;

							return (
								<CustomMarker
									key={sharedLocation.id}
									position={{ lat: y, lng: x }}
									markerIcon={<Pin />}
									size="sm"
									onClick={onLocationClick}
								>
									{showLocationsName ? (
										<Tooltip
											key={`farm-name-tooltip-${sharedLocation.id}`}
											direction="left"
											variant="noBackground"
											permanent
											offset={[-10, 0]}
										>
											<Text
												overrideMUIColor="WHITE_NO_OPACITY"
												sx={{ textShadow: '2px 2px #000000' }}
											>
												{sharedLocation.name}
											</Text>
										</Tooltip>
									) : null}
								</CustomMarker>
							);
						})
				: null}
		</>
	);
};

export default SharedLocationsMarker;
