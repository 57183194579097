import { TTranslateFunction } from 'Features/localization';
import { THarvestExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';

import { TPartnerHarvest } from '../types';
import getHarvestRowExportData, { THarvestExportData } from './exportData';

type TExportData = {
	exportData: THarvestExportData[];
};

export type TExportTeamsInfo = {
	teams: string;
	members?: string;
};

function getExportData(
	harvests: TPartnerHarvest[],
	dictionary: THarvestExportHeadersDictionary,
	t: TTranslateFunction
): TExportData {
	const exportData = harvests.map((harvest) =>
		getHarvestRowExportData(harvest, dictionary, t)
	);

	return {
		exportData,
	};
}

export default getExportData;
