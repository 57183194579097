import React, { Fragment } from 'react';

import { useReactiveVar } from '@apollo/client/react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';

import ModalSection from '../../../../../../../../../../components/ModalSection';
import { addTreatmentPageState } from '../../../state';
import {
	AddTreatmentModalStatus,
	TAddTreatmentsModalInput,
} from '../../../types';
import CropDropdown from './CropDropdown';

function CropCategories() {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);

	const goToAddCrop = () => {
		addTreatmentPageState({
			...state,
			status: AddTreatmentModalStatus.SELECT_CROP_CATEGORY,
			input: {
				...state.input,
			},
		} as TAddTreatmentsModalInput);
	};

	return (
		<>
			<Text>{t('treatmentLibrary.addModal.description')}</Text>
			<Divider sx={{ margin: '1.5rem 0' }} />
			<ModalSection title={t('labels.category')} noDivider>
				<List.List>
					{(state.input.product.treatments || []).map((treatment, idx) => (
						<Fragment key={idx}>
							<List.Item
								sx={{ padding: '0.75rem 0' }}
								secondaryAction={<CropDropdown treatment={treatment} />}
							>
								<List.ItemText primary={treatment.category} />
							</List.Item>
							<Divider />
						</Fragment>
					))}
				</List.List>
				<Button
					variant="secondary"
					onClick={goToAddCrop}
					startIcon={<AddIcon />}
					sx={{ marginTop: '0.5rem' }}
				>
					{t('labels.addCrop')}
				</Button>
			</ModalSection>
		</>
	);
}

export default CropCategories;
