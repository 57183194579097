import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function RowsIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				d="M21 3v9c0 2.3-.88 4.61-2.64 6.36A8.95 8.95 0 0 1 12 21c-2.12 0-4.07-.74-5.62-1.97L4.41 21 3 19.59l1.97-1.98A8.963 8.963 0 0 1 3 12c0-5.14 4.2-9 9-9h9zm-2 2h-7c-1.84 0-3.58.71-4.9 2.01C5.74 8.32 5 10.1 5 12c0 1.57.52 3.021 1.396 4.19l4.19-4.19L12 13.414l-4.19 4.19A6.964 6.964 0 0 0 12 19c1.87 0 3.63-.73 4.95-2.05A6.96 6.96 0 0 0 19 12V5z"
				fillRule="nonzero"
			/>
		</SvgIcon>
	);
}

export default RowsIcon;
