const MOBILE = 768 as const;
const TABLET = 998 as const;
const DESKTOP = 1118 as const;
const WIDESCREEN = 1462 as const;

const Breakpoints = {
	MOBILE,
	TABLET,
	DESKTOP,
	WIDESCREEN,
};

export type TBreakpoint = typeof ALL[number];

export const ALL = [MOBILE, TABLET, DESKTOP, WIDESCREEN];

export const breakpointsValues = {
	values: {
		xs: 0,
		sm: MOBILE,
		md: TABLET,
		lg: DESKTOP,
		xl: WIDESCREEN,
	},
};

export default Breakpoints;
