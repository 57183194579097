import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Stats from 'UI/display/Stats';
import { format } from 'date-fns';

import useImperialUnits from 'Hooks/useImperialUnits';
import useManageHistory from 'Hooks/useManageHistory';

import GET_SALES_STATS from './api/getSalesStats';
import getParsedSalesStats from './utils/getParsedSalesStats';

const SalesStats = () => {
	const { t } = useTranslation();
	const { paramsFromURL } = useManageHistory();

	const { data, loading, error } = useQuery(GET_SALES_STATS, {
		variables: {
			locationId: paramsFromURL.locationID,
			query: {
				filterBy: {
					dateFrom: paramsFromURL.filters.dateFrom
						? format(new Date(paramsFromURL.filters.dateFrom), 'yyyy-MM-dd')
						: undefined,
					dateTo: paramsFromURL.filters?.dateTo
						? format(new Date(paramsFromURL.filters?.dateTo), 'yyyy-MM-dd')
						: undefined,
					varieties: paramsFromURL.filters?.varieties,
				},
			},
		},
	});
	const isImperial = useImperialUnits();

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const { harvestedAmount, storageAmount, soldAmount } = getParsedSalesStats(
			data.getAggregatedHarvestBatches,
			Boolean(isImperial)
		);

		return (
			<Stats.Section>
				<Stats.Item
					value={harvestedAmount}
					description={t('farmSalesPage.salesStats.harvested')}
				/>
				<Stats.Item
					value={storageAmount}
					description={t('farmSalesPage.salesStats.storage')}
				/>
				<Stats.Item
					value={soldAmount}
					description={t('farmSalesPage.salesStats.sold')}
				/>
			</Stats.Section>
		);
	}

	if (loading) {
		return (
			<Stats.Section>
				<Stats.Item description={t('farmSalesPage.salesStats.harvested')} />
				<Stats.Item description={t('farmSalesPage.salesStats.storage')} />
				<Stats.Item description={t('farmSalesPage.salesStats.sold')} />
			</Stats.Section>
		);
	}

	return null;
};

export default SalesStats;
