import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { THarvestBatch } from 'Models/harvestBatch/isHarvestBatch';

type TGetOldestHarvestBatchResponse = {
	getHarvestBatches: {
		harvestBatches: Pick<THarvestBatch, 'id' | 'date'>[];
	};
};
type TGetOldestHarvestBatchRequest = {
	locationId: string;
	filterBy?: {
		dateFrom?: string;
		dateTo?: string;
		varieties?: string[];
		onlySoldBatches?: boolean;
		customerId?: string;
	};
	pageInput: TPageInput;
};

const GET_OLDEST_HARVEST_BATCH: TypedDocumentNode<
	TGetOldestHarvestBatchResponse,
	TGetOldestHarvestBatchRequest
> = gql`
	query getOldestHarvestBatch(
		$locationId: ID!
		$filterBy: HarvestBatchFilterInput
		$pageInput: PageInput!
	) {
		getHarvestBatches(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			harvestBatches {
				id
				date
			}
		}
	}
`;

export default GET_OLDEST_HARVEST_BATCH;
