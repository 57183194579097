import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import useLocationID from 'Hooks/useLocationID';

import FieldsSearchMap from '../../../../../components/SearchMap/FieldsSearchMap';
import GET_PARTNER_FIELDS_SEARCH_MAP_DATA from './api/getPartnerFieldsSearchMapData';

const SearchMap = () => {
	const locationID = useLocationID();
	const { data, loading, error } = useQuery(
		GET_PARTNER_FIELDS_SEARCH_MAP_DATA,
		{
			skip: !locationID,
			variables: {
				filterBy: {
					locationIds: [locationID],
				},
			},
		}
	);

	if (error) {
		throw new ApolloError(error);
	}

	return (
		<FieldsSearchMap
			key="partner-fields-search-map-component"
			fields={data?.getPartnerFields.fields.map((field) => field.field) || []}
			isLoading={loading}
			disableFieldClick
		/>
	);
};

export default SearchMap;
