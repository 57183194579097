import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import TextSearchInput from '../../../../components/filters/TextSearchInput';

const Filters = () => {
	const { t } = useTranslation();

	return (
		<TextSearchInput
			placeholder={t('logsPages.currInventoryLogsPage.searchPlaceholder')}
		/>
	);
};

export default Filters;
