import React from 'react';

import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';

import usePermission from 'Hooks/usePermission';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import PageAccessValidator from '../../../../components/PageAccessValidator';
import NoSubscriptionPage from '../../../no-access/NoSubscriptionPage';
import ExportToFile from './components/ExportToFile';

function HoursExportPage() {
	const hasValidSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.TIMESHEET
	);
	const hasTimesheetExportPermission = usePermission(
		Permissions.TIMESHEETS_EXPORT
	);
	const canPurchase = usePermission(Permissions.TIMESHEETS_PURCHASE);

	const hasPageAccess = Boolean(
		hasTimesheetExportPermission && hasValidSubscription
	);
	return (
		<PageAccessValidator
			displayContent={hasPageAccess}
			fallback={<NoSubscriptionPage canPurchase={canPurchase} />}
		>
			<ErrorBoundary>
				<ExportToFile />
			</ErrorBoundary>
		</PageAccessValidator>
	);
}

export default HoursExportPage;
