import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function RowsIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				fillRule="nonzero"
				d="M20 2c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1zm-4 0c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1zm-4 0c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1zM8 2c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1zM4 2c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1z"
				transform="translate(-148 -160) translate(16 116) translate(0 32) translate(132 12)"
			/>
		</SvgIcon>
	);
}

export default RowsIcon;
