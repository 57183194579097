import React from 'react';

import Avatar from 'UI/display/Avatar';
import List from 'UI/display/List';

type TProps = {
	name: string;
	address?: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SettingsMenuHeader(props: TProps, ref) {
	const { name, address } = props;

	const avatarIcon = (
		<Avatar alt="Profile avatar">{name.charAt(0) || '?'}</Avatar>
	);

	return (
		<List.Item sx={{ padding: '0 1 rem', minWidth: '18.75rem' }}>
			<List.ItemAvatar>{avatarIcon}</List.ItemAvatar>
			<List.ItemText
				primary={name}
				primaryTypographyProps={{
					style: { fontWeight: 'bold', fontSize: '0.875rem' },
				}}
				secondary={address}
				secondaryTypographyProps={{
					style: { fontSize: '0.75rem' },
				}}
			/>
		</List.Item>
	);
}

export default React.forwardRef(SettingsMenuHeader);
