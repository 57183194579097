import { format } from 'date-fns';

import { TVariety } from './isVariety';

const DEFAULT_FORMAT = 'dd/MM/yyyy';

type TVarietyWithSowDate = { sowDate: string };

function varietiesBySowDate(a: TVarietyWithSowDate, b: TVarietyWithSowDate) {
	return Date.parse(a.sowDate) - Date.parse(b.sowDate);
}

function isVarietyWithSowDate(
	variety: Pick<TVariety, 'sowDate'>
): variety is TVarietyWithSowDate {
	return variety.sowDate !== null;
}

const getVarietiesSowDate = (
	varieties?: Pick<TVariety, 'sowDate'>[] | null
): string => {
	if (varieties && varieties.length > 1) {
		const sortedByDate = varieties
			.filter(isVarietyWithSowDate)
			.sort(varietiesBySowDate);

		const firstVarietyDateString = format(
			Date.parse(sortedByDate[0].sowDate),
			DEFAULT_FORMAT
		);
		const lastVarietyDateString = format(
			Date.parse(sortedByDate[sortedByDate.length - 1].sowDate),
			DEFAULT_FORMAT
		);

		return `${firstVarietyDateString} - ${lastVarietyDateString}`;
	} else if (
		varieties &&
		varieties.length === 1 &&
		isVarietyWithSowDate(varieties[0])
	) {
		return format(Date.parse(varieties[0]?.sowDate), DEFAULT_FORMAT);
	}

	return '-';
};

export default getVarietiesSowDate;
