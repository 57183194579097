import { TTranslateFunction } from 'Features/localization';
import { TJobExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import { ExportType, TExportType } from 'Models/exports/ExportType';
import { TPartnerHarvest } from 'Models/harvest/isPartnerHarvest';
import { TPartnerJob } from 'Models/jobs/isPartnerJob';
import _flatten from 'lodash/flatten';

import {
	PRODUCTS_COLUMN_END_INDEX,
	PRODUCTS_COLUMN_START_INDEX,
} from './constants';
import { TMerge } from './jobMerges';
import {
	getHeaders,
	getRowData,
	THeaderColumn,
	TValuesColumn,
} from './partnerColumnValues';

type TExportData = {
	exportData: (THeaderColumn | TValuesColumn)[];
	additionalProductsMerges: TMerge[];
	columnsCount: number;
};

type TExportDataOptions = {
	jobs: TPartnerJob[];
	fileType: TExportType;
	dictionary: TJobExportHeadersDictionary;
	harvests?: TPartnerHarvest[];
	maxNotesLength?: number;
	t: TTranslateFunction;
};

const HEADER_HEIGHT = 2;

function getExportData(options: TExportDataOptions): TExportData {
	const { jobs, harvests, fileType, dictionary, maxNotesLength, t } = options;

	const isCSV = fileType === ExportType.CSV;
	const columnHeader = getHeaders(dictionary, false, maxNotesLength);
	const columnsCount = Object.keys(columnHeader).length;
	const additionalProductsMerges: TMerge[] = [];
	let addedRowsCount = 0;

	const exportData = jobs.map((job, jobIndex) => {
		const fieldHarvests = harvests?.filter((harvest) =>
			harvest.fields.some((field) =>
				job.jobFields?.some((job) => job.id === field.id)
			)
		);

		// If job contains more than 1 product, add additional rows for each product
		if (job.treatments.length > 1) {
			const rowIndex = jobIndex + HEADER_HEIGHT + addedRowsCount;
			for (let colIndex = 0; colIndex < columnsCount; colIndex++) {
				// Exclude products cells.
				if (
					colIndex < PRODUCTS_COLUMN_START_INDEX ||
					colIndex > PRODUCTS_COLUMN_END_INDEX
				) {
					// Merge rows of products that belong to the same job.
					additionalProductsMerges.push({
						s: {
							r: rowIndex,
							c: colIndex,
						},
						e: {
							r: rowIndex + job.treatments.length - 1,
							c: colIndex,
						},
					});
				}
			}

			addedRowsCount += job.treatments.length - 1;
		}

		return job.treatments.length
			? job.treatments.map((treatment) =>
					getRowData({
						job,
						dictionary,
						treatment,
						harvests: fieldHarvests,
						isImperial: false,
						t,
					})
			  )
			: getRowData({
					job,
					dictionary,
					treatment: undefined,
					harvests: fieldHarvests,
					isImperial: false,
					t,
			  });
	});

	const result = _flatten<TValuesColumn>(exportData);

	// CSV reports don't have merged headers
	return {
		exportData: isCSV ? result : [columnHeader, ...result],
		additionalProductsMerges,
		columnsCount,
	};
}

export default getExportData;
export type { TExportData };
