import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TGovernmentProductInput } from 'Models/products/GovernmentProductInput';
import { TProduct } from 'Models/products/Product';

import { treatmentLibraryProductFragmentFields } from './fragments/productFragment';
import { GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS } from './getTreatmentLibraryProducts';

type TCreateTreatmentLibraryGovernmentProductResult = {
	createTreatmentLibraryGovernmentProduct: TProduct;
};

type TCreateTreatmentLibraryGovernmentProductInput = {
	libraryId: string;
	input: TGovernmentProductInput;
};

export const CREATE_TREATMENT_LIBRARY_GOVERNMENT_PRODUCT: TypedDocumentNode<
	TCreateTreatmentLibraryGovernmentProductResult,
	TCreateTreatmentLibraryGovernmentProductInput
> = gql`
	mutation createTreatmentLibraryGovernmentProduct(
		$libraryId: ID!
		$input: GovernmentProductInput!
	) {
		createTreatmentLibraryGovernmentProduct(
			libraryId: $libraryId
			input: $input
		) {
			${treatmentLibraryProductFragmentFields}
		}
	}
`;

export const addCreatedTreatmentLibraryGovernmentProductInCache: MutationUpdaterFunction<
	TCreateTreatmentLibraryGovernmentProductResult,
	TCreateTreatmentLibraryGovernmentProductInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }, options) => {
	const libraryId = options.variables?.libraryId;
	const query = {
		query: GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS,
		variables: { libraryId: libraryId || '' },
	};

	const existingData = cache.readQuery(query)?.getTreatmentLibraryProducts;

	if (existingData && data) {
		cache.writeQuery({
			...query,
			data: {
				getTreatmentLibraryProducts: {
					...existingData,
					products: [
						...existingData.products,
						data.createTreatmentLibraryGovernmentProduct,
					],
				},
			},
		});
	}
};
