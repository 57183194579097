import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Tooltip from 'UI/display/Tooltip';
import Box from 'UI/layout/Box';

type TMembertooltip = {
	sharedLocations: string[];
};

const MemberTooltip = (props: TMembertooltip) => {
	const { sharedLocations } = props;
	const { t } = useTranslation();

	if (sharedLocations.length) {
		const tooltipContent = (
			<Box maxWidth="13.5rem">{sharedLocations.join(', ')}</Box>
		);

		return (
			<Tooltip title={tooltipContent} arrow placement="top">
				<div>
					<Text variant="body2" sx={{ lineHeight: 1.43 }}>
						:&nbsp;
						{t('managePartnerMembers.farmAmount', {
							count: sharedLocations.length,
						})}
					</Text>
				</div>
			</Tooltip>
		);
	}

	return null;
};

export default MemberTooltip;
