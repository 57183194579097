import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { Trans } from 'Features/localization/components/Trans';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TErrorType } from 'Models/error/getErrorType';
import Text from 'UI/display/Text';
import { FarmableColors } from 'UI/theme/Colors';

import useProfile from 'Hooks/useProfile';

import FallbackPageTemplate from './FallbackPageTemplate';

type TProps = {
	message: string;
	type: TErrorType;
	eventId: string;
	withLogout?: boolean;
	fullHeight?: boolean;
};

const CriticalErrorFallback = (props: TProps) => {
	const { withLogout } = props;
	const { profile } = useProfile({ noError: true });
	const { t } = useTranslation();
	const { logout } = useAuth0();

	const returnTo =
		process.env.AUTH0_LOGOUT_URI || window.location.origin + '/logout.html';

	const triggerLogout = () => {
		analyticsTrack(TrackEvents.SignedOut, { userId: profile?.id });
		void logout({ logoutParams: { returnTo } });
	};

	const logOut = (
		<a
			onClick={triggerLogout}
			style={{
				textDecoration: 'underline',
				color: FarmableColors.ORANGE,
				cursor: 'pointer',
			}}
		>
			log out
		</a>
	);
	const tryAgain = withLogout ? (
		<Trans i18nKey={'common.fallbackComponent.logout'}>
			Please {logOut} and try again
		</Trans>
	) : (
		t('common.fallbackComponent.refreshSite')
	);

	return (
		<FallbackPageTemplate {...props}>
			<Text variant="h1" sx={{ fontSize: '3rem', lineHeight: '1.2' }}>
				{t('common.oops')}
			</Text>
			<Text variant="h2">{tryAgain}</Text>
		</FallbackPageTemplate>
	);
};

export default CriticalErrorFallback;
