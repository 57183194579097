export enum ERROR_STATUSES {
	ROLE_NOT_FOUND = 'ROLE_NOT_FOUND',
	ROLE_NOT_SUPPORTED = 'ROLE_NOT_SUPPORTED',
	FIELD_NOT_FOUND = 'FIELD_NOT_FOUND',
	FIELD_IS_REFERENCED_JOB = 'FIELD_IS_REFERENCED_JOB',
	EQUIPMENT_NOT_FOUND = 'EQUIPMENT_NOT_FOUND',
	EQUIPMENT_USED_IN_JOB_NOT_FOUND = 'EQUIPMENT_USED_IN_JOB_NOT_FOUND',
	EQUIPMENT_CONFIGURATION_NOT_FOUND = 'EQUIPMENT_CONFIGURATION_NOT_FOUND',
	EQUIPMENT_CONFIGURATION_WAS_DELETED = 'EQUIPMENT_CONFIGURATION_WAS_DELETED',
	LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND',
	JOB_NOT_FOUND = 'JOB_NOT_FOUND',
	JOB_NOT_FOUND_BY_NOTE_ID = 'JOB_NOT_FOUND_BY_NOTE_ID',
	JOB_NOT_STARTED = 'JOB_NOT_STARTED',
	JOB_FOR_DRIVER_NOT_FOUND = 'JOB_FOR_DRIVER_NOT_FOUND',
	JOB_FIELD_STATE_NOT_FOUND = 'JOB_FIELD_STATE_NOT_FOUND',
	JOB_ALREADY_STARTED = 'JOB_ALREADY_STARTED',
	JOB_NOT_COMPLETED = 'JOB_NOT_COMPLETED',
	JOB_WAS_STARTED = 'JOB_WAS_STARTED',
	HARVEST_JOB_SHOULD_BE_IN_PROGRESS = 'HARVEST_JOB_SHOULD_BE_IN_PROGRESS',
	FIELD_HAS_ACTIVE_JOBS = 'FIELD_HAS_ACTIVE_JOBS',
	INVALID_FIELDS_PASSED_TO_JOB = 'INVALID_FIELDS_PASSED_TO_JOB',
	USER_WITH_EMAIL_NOT_FOUND = 'USER_WITH_EMAIL_NOT_FOUND',
	DRIVERS_NOT_FOUND_FOR_LOCATION = 'DRIVERS_NOT_FOUND_FOR_LOCATION',
	ACCOUNTS_NOT_FOUND_FOR_LOCATION = 'ACCOUNTS_NOT_FOUND_FOR_LOCATION',
	DRIVER_NOT_FOUND_FOR_LOCATION = 'DRIVER_NOT_FOUND_FOR_LOCATION',
	DRIVER_FOR_JOB_NOT_FOUND = 'DRIVER_FOR_JOB_NOT_FOUND',
	USER_ALREADY_INVITED = 'USER_ALREADY_INVITED',
	USER_NOT_FOUND = 'USER_NOT_FOUND',
	RUN_NOT_FOUND = 'RUN_NOT_FOUND',
	NO_ACTIVE_RUNS_FOUND = 'NO_ACTIVE_RUNS_FOUND',
	NOT_ABLE_TO_UPDATE_AUTH0_USER = 'NOT_ABLE_TO_UPDATE_AUTH0_USER',
	UNABLE_TO_CREATE_AUTH0_USER = 'UNABLE_TO_CREATE_AUTH0_USER',
	NOT_ABLE_TO_REACH_AUTH0 = 'NOT_ABLE_TO_REACH_AUTH0',
	INVALID_OVERRIDDEN_IDS_PASSED = 'INVALID_OVERRIDDEN_IDS_PASSED',
	CONFIG_NOT_CONTAINS_DEFAULT_EQUIPMENT_CONFIG = 'CONFIG_NOT_CONTAINS_DEFAULT_EQUIPMENT_CONFIG',
	AUTH0_EXCEPTION = 'AUTH0_EXCEPTION',
	INVALID_GEOMETRY = 'INVALID_GEOMETRY',
	GEOMETRY_EXCEPTION = 'GEOMETRY_EXCEPTION',
	CALCULATOR_EXCEPTION = 'CALCULATOR_EXCEPTION',
	NOT_SUPPORTED = 'NOT_SUPPORTED',
	RUN_LOG_EXCEPTION = 'RUN_LOG_EXCEPTION',
	COMPLETED_METERS_IS_GREATER_THAN_OVERALL_JOB = 'COMPLETED_METERS_IS_GREATER_THAN_OVERALL_JOB',
	INVALID_IMAGE_FORMAT = 'INVALID_IMAGE_FORMAT',
	IMAGE_TOO_LARGE = 'IMAGE_TOO_LARGE',
	IMAGE_READ_ERROR = 'IMAGE_READ_ERROR',
	UPLOAD_ERROR = 'UPLOAD_ERROR',
	IMAGE_NOT_FOUND = 'IMAGE_NOT_FOUND',
	IMAGE_ALREADY_ASSIGNED_TO_ANOTHER_HARVEST = 'IMAGE_ALREADY_ASSIGNED_TO_ANOTHER_HARVEST',
	FAILED_TO_DELETE_USER_PROFILE_IMAGE = 'FAILED_TO_DELETE_USER_PROFILE_IMAGE',
	PRODUCT_UNIT_NOT_SUPPORTED = 'PRODUCT_UNIT_NOT_SUPPORTED',
	PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
	CUSTOM_JOB_NOT_FOUND = 'CUSTOM_JOB_NOT_FOUND',
	CAUSE_NOT_FOUND = 'CAUSE_NOT_FOUND',
	INVALID_INPUT = 'INVALID_INPUT',
	FIELD_NOTE_NOT_FOUND = 'FIELD_NOTE_NOT_FOUND',
	LABEL_NOT_FOUND = 'LABEL_NOT_FOUND',
	FIELD_NOTE_COMMENT_NOT_FOUND = 'FIELD_NOTE_COMMENT_NOT_FOUND',
	UNSUPPORTED_JOBS_HISTORY_VIEW = 'UNSUPPORTED_JOBS_HISTORY_VIEW',
	HARVEST_NOT_FOUND = 'HARVEST_NOT_FOUND',
	HARVEST_DATE_NOT_DEFINED = 'HARVEST_DATE_NOT_DEFINED',
	HARVEST_TARGET_NOT_FOUND = 'HARVEST_TARGET_NOT_FOUND',
	ONLY_KG_UNIT_FOR_HARVEST_TARGET_SUPPORTED = 'ONLY_KG_UNIT_FOR_HARVEST_TARGET_SUPPORTED',
	ONLY_OWNER_CAN_DELETE_LOCATION = 'ONLY_OWNER_CAN_DELETE_LOCATION',
	BOX_NOT_FOUND = 'BOX_NOT_FOUND',
	EQUIPMENT_USED_IN_ACTIVE_JOB = 'EQUIPMENT_USED_IN_ACTIVE_JOB',
	STRIPE_EVENT_DESERIALIZATION_FAILURE = 'STRIPE_EVENT_DESERIALIZATION_FAILURE',
	STRIPE_INVALID_CHECKOUT_SESSION_MODE = 'STRIPE_INVALID_CHECKOUT_SESSION_MODE',
	STRIPE_INVALID_CHECKOUT_URL = 'STRIPE_INVALID_CHECKOUT_URL',
	STRIPE_INVALID_CUSTOMER_PORTAL_URL = 'STRIPE_INVALID_CUSTOMER_PORTAL_URL',
	STRIPE_CUSTOMER_NOT_FOUND = 'STRIPE_CUSTOMER_NOT_FOUND',
	NOTIFICATION_FOR_SUCH_ROLE_NOT_SUPPORTED = 'NOTIFICATION_FOR_SUCH_ROLE_NOT_SUPPORTED',
	INVALID_ACCOUNT_ID = 'INVALID_ACCOUNT_ID',
	UPSTREAM_SERVICE_FAILURE = 'UPSTREAM_SERVICE_FAILURE',
	INVALID_SEASON_CUTOFF_DATE_FORMAT = 'INVALID_SEASON_CUTOFF_DATE_FORMAT',
	SEASON_CUTOFF_DATE_MONTH_INVALID = 'SEASON_CUTOFF_DATE_MONTH_INVALID',
	SEASON_CUTOFF_DATE_DAY_INVALID = 'SEASON_CUTOFF_DATE_DAY_INVALID',
	ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
	MONITORING_NOT_FOUND = 'MONITORING_NOT_FOUND',
	REPORT_WRITE_ERROR = 'REPORT_WRITE_ERROR',
	JOB_REPORT_NOT_FOUND = 'JOB_REPORT_NOT_FOUND',
	USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
	SERVER_ERROR = 'SERVER_ERROR',
	REFERRAL_CODE_NOT_FOUND = 'REFERRAL_CODE_NOT_FOUND',
	REFERRAL_INFO_NOT_FOUND = 'REFERRAL_INFO_NOT_FOUND',
	INVALID_CATEGORIES_PASSED = 'INVALID_CATEGORIES_PASSED',
	USER_DEVICE_NOT_FOUND = 'USER_DEVICE_NOT_FOUND',
	NO_UNIQUE_ELEMENT = 'NO_UNIQUE_ELEMENT',
	NO_ELEMENTS_FOUND = 'NO_ELEMENTS_FOUND',
	NOTIFICATION_TYPE_NOT_SUPPORTED = 'NOTIFICATION_TYPE_NOT_SUPPORTED',
	JOB_POINT_NOT_FOUND = 'JOB_POINT_NOT_FOUND',
	INVALID_FIELD_VARIETY = 'INVALID_FIELD_VARIETY',
	INVALID_DATETIME_RANGE_PASSED = 'INVALID_DATETIME_RANGE_PASSED',
	INVALID_CONFIG_PASSED = 'INVALID_CONFIG_PASSED',
	INVALID_TEAM_ID_PASSED = 'INVALID_TEAM_ID_PASSED',
	INVALID_TEAM_MEMBER_ID_PASSED = 'INVALID_TEAM_MEMBER_ID_PASSED',
	INVALID_FIELD_IDS_PASSED = 'INVALID_FIELD_IDS_PASSED',
	TEAM_NOT_FOUND = 'TEAM_NOT_FOUND',
	CHANGING_EMAIL_IS_NOT_ALLOWED = 'CHANGING_EMAIL_IS_NOT_ALLOWED',
	INVALID_TS_CLOCK_IN_CONFIGURATION_PASSED = 'INVALID_TS_CLOCK_IN_CONFIGURATION_PASSED',
	INVALID_TS_CLOCK_OUT_CONFIGURATION_PASSED = 'INVALID_TS_CLOCK_OUT_CONFIGURATION_PASSED',
	USER_CANNOT_BE_CLOCKED_IN_TWICE = 'USER_CANNOT_BE_CLOCKED_IN_TWICE',
	INVALID_TS_SUBMIT_CONFIGURATION_PASSED = 'INVALID_TS_SUBMIT_CONFIGURATION_PASSED',
	TIMESHEET_NOT_FOUND = 'TIMESHEET_NOT_FOUND',
	TEAM_MEMBER_NOT_FOUND = 'TEAM_MEMBER_NOT_FOUND',
	TIMESHEET_CANNT_BE_NULL = 'TIMESHEET_CANNT_BE_NULL',
	TIMESHEET_ID_SHOULD_BE_NULL = 'TIMESHEET_ID_SHOULD_BE_NULL',
	WORK_LOG_DOESNT_EXIST = 'WORK_LOG_DOESNT_EXIST',
	TIMESHEET_SETTINGS_NOT_FOUND = 'TIMESHEET_SETTINGS_NOT_FOUND',
	TIMESHEET_SETTINGS_ALREADY_EXISTS = 'TIMESHEET_SETTINGS_ALREADY_EXISTS',
	TEAM_HAS_ACTIVE_TIMESHEETS = 'TEAM_HAS_ACTIVE_TIMESHEETS',
	CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
	HARVEST_BATCH_NOT_FOUND = 'HARVEST_BATCH_NOT_FOUND',
	HARVEST_COULD_NOT_BE_UPDATED = 'HARVEST_COULD_NOT_BE_UPDATED',
	HARVEST_COULD_NOT_BE_DELETED = 'HARVEST_COULD_NOT_BE_DELETED',
	SALE_NOT_FOUND = 'SALE_NOT_FOUND',
	BATCHES_FOR_MANUAL_ALLOCATION_CANNOT_BE_NULL = 'BATCHES_FOR_MANUAL_ALLOCATION_CANNOT_BE_NULL',
	INVALID_BATCH_ID_PASSED = 'INVALID_BATCH_ID_PASSED',
	INVALID_VARIETY_PASSED_FOR_MANUAL_BATCHES = 'INVALID_VARIETY_PASSED_FOR_MANUAL_BATCHES',
	INVALID_SALE_VOLUME_PASSED = 'INVALID_SALE_VOLUME_PASSED',
	INVALID_WASTE_VOLUME_PASSED = 'INVALID_WASTE_VOLUME_PASSED',
	IMAGE_ALREADY_ASSIGNED_TO_ANOTHER_SALE = 'IMAGE_ALREADY_ASSIGNED_TO_ANOTHER_SALE',
	MILESTONE_DOESNT_EXIST = 'MILESTONE_DOESNT_EXIST',
	FIELD_TIMELINE_DOESNT_EXIST = 'FIELD_TIMELINE_DOESNT_EXIST',
	INVALID_MILESTONE_ACTUAL_DATE = 'INVALID_MILESTONE_ACTUAL_DATE',
	INVALID_EMAIL_PASSED = 'INVALID_EMAIL_PASSED',
	FORBIDDEN = 'FORBIDDEN',
	NO_PARTNERSHIP_COMPANY_REGISTERED = 'NO_PARTNERSHIP_COMPANY_REGISTERED',
	NO_ACTIVE_PARTNERSHIP_COMPANY_FOUND = 'NO_ACTIVE_PARTNERSHIP_COMPANY_FOUND',
	PARTNERSHIP_COMPANY_ALREADY_REGISTERED = 'PARTNERSHIP_COMPANY_ALREADY_REGISTERED',
	SORT_NOT_SUPPORTED = 'SORT_NOT_SUPPORTED',
	PARTNERSHIP_USER_IS_ALREADY_INVITED = 'PARTNERSHIP_USER_IS_ALREADY_INVITED',
}
