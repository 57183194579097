import React, { useState } from 'react';

import { TSeasonParams } from 'Models/seasons/isSeasonParams';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Grid from 'UI/layout/Grid';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useManageHistory from 'Hooks/useManageHistory';
import getSeasonParams from 'Utils/getSeasonParams';

import ProfitChartComponent from '../../../../../components/ProfitChart';
import ProfitChartHeader from '../../../../../components/ProfitChart/components/Header';
import SeasonFilter from '../../../../../components/ProfitChart/components/SeasonFilter';
import useProfitChartData from './hooks/useProfitChartData';

function ProfitChart() {
	const location = useCurrentLocation();
	const { paramsFromURL, historyPush } = useManageHistory();
	const [seasonParams, setSeasonParams] = useState<TSeasonParams>();

	const { loading, data } = useProfitChartData();

	if (!seasonParams) {
		// read season params from url query first on load
		setSeasonParams(
			getSeasonParams(paramsFromURL.filters, location?.seasonCutoffDate)
		);
	}

	const setSearchParams = (params: TSeasonParams) => {
		const newParams = {
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				dateFrom: params?.from,
				dateTo: params?.to,
				season: params?.seasonName,
			},
		};

		historyPush(newParams);
	};

	if (data && location && seasonParams) {
		// The first season is the year of when the farm has been created.
		const locationCreatedAt = new Date(location.createdAt || Date.now());

		const onClickItem = (seasonParams: TSeasonParams) => {
			setSeasonParams(seasonParams);
			setSearchParams(seasonParams);
		};

		const seasonFilter = (
			<SeasonFilter
				seasonParams={seasonParams}
				locationCreatedAt={locationCreatedAt}
				onClickItem={onClickItem}
			/>
		);

		return (
			<>
				<ProfitChartHeader seasonFilter={seasonFilter} />
				<ProfitChartComponent
					seasonParams={seasonParams}
					// Disabled until it's implemented
					displayProductsWithoutCostInfo={false}
					data={data}
				/>
			</>
		);
	}

	if (loading) {
		return (
			<>
				<ProfitChartHeader />
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<LoadingSpinner width={64} height={64} />
				</Grid>
			</>
		);
	}

	return null;
}

export default ProfitChart;
