import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import { TPartnerCause } from '../types';
import CauseItem from './CauseItem';
import SelectCauseTypeModal from './SelectCauseTypeModal';
import styles from './styles.module.scss';

type TCausesCardProps = {
	causes: TPartnerCause[];
};

const CausesCard = (props: TCausesCardProps): ReactElement => {
	const { causes } = props;
	const { t } = useTranslation();
	const causesCount = t('causesPage.card.causeWithCount', {
		count: causes.length,
	});

	const causesList = causes.map((cause) => (
		<CauseItem key={cause.id} cause={cause} />
	));

	return (
		<Card>
			<CardContent>
				<Box display="flex" justifyContent="space-between" mb="1.25rem">
					<Text variant="h3">{causesCount}</Text>
					<Text variant="h3">
						<SelectCauseTypeModal />
					</Text>
				</Box>
				<div className={styles.causesSection}>{causesList}</div>
			</CardContent>
		</Card>
	);
};

export default CausesCard;
