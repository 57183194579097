import React, { useState } from 'react';

import { useReactiveVar } from '@apollo/client/react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import Divider from 'UI/display/Divider';
import MoreIcon from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Menu from 'UI/navigation/Menu';
import { FarmableColors } from 'UI/theme/Colors';

import { addTreatmentPageState } from '../../../state';
import {
	AddTreatmentModalStatus,
	TAddTreatmentsModalInput,
	TTreatmentDetailsInput,
} from '../../../types';

type TCropDropdownProps = {
	treatment: TProductTreatmentInput;
};

const CropDropdown = (props: TCropDropdownProps) => {
	const { t } = useTranslation();
	const { treatment } = props;
	const state = useReactiveVar(addTreatmentPageState);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onEditInfoClick = () => {
		addTreatmentPageState({
			...state,
			status: AddTreatmentModalStatus.TREATMENT_DETAILS,
			editedCategoryName: treatment.category,
		} as TTreatmentDetailsInput);
		handleClose();
	};

	const onDeleteClick = () => {
		const existingTreatments = state.input.product.treatments || [];

		addTreatmentPageState({
			...state,
			input: {
				...state.input,
				selectedGovernmentProduct: false,
				product: {
					...state.input.product,
					treatments: existingTreatments.filter(
						(existingTreatment) =>
							existingTreatment.category !== treatment.category
					),
				},
			},
		} as TAddTreatmentsModalInput);
		handleClose();
	};

	return (
		<>
			<IconButton onClick={handleClick}>
				<MoreIcon />
			</IconButton>
			<Menu.Menu
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				narrow
			>
				<Menu.Item onClick={onEditInfoClick}>{t('labels.edit')}</Menu.Item>
				<Divider sx={{ marginY: '0.5rem' }} />
				<Menu.Item onClick={onDeleteClick} sx={{ color: FarmableColors.RED }}>
					{t('buttons.delete')}
				</Menu.Item>
			</Menu.Menu>
		</>
	);
};

export default CropDropdown;
