import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCustomer } from 'Models/customers/isCustomer';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import useLocationID from '../../../../hooks/useLocationID';
import DELETE_CUSTOMER_MUTATION from '../api/deleteCustomerMutation';
import GET_CUSTOMERS_DATA from '../api/getCustomersData';

type TProps = {
	customer: Pick<TCustomer, 'id'>;
};

const DeleteCustomerModal = (props: TProps) => {
	const { customer } = props;
	const locationId = useLocationID();

	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	const toggleOpen = () => {
		setOpen(!open);
	};

	const [deleteCustomer, mutationResult] = useMutation(
		DELETE_CUSTOMER_MUTATION,
		{
			refetchQueries: [GET_CUSTOMERS_DATA],
		}
	);

	useSnackbarNotifications({
		mutationResult,
		messageSuccess: t('customersPage.deleteCustomerSuccess'),
		messageFailure: t('customersPage.deleteCustomerFail'),
	});

	const deleteTeam = () => {
		void deleteCustomer({ variables: { locationId, customerId: customer.id } });
	};

	return (
		<>
			<Menu.Item onClick={toggleOpen}>
				<Text color="error" variant="body2">
					{t('customersPage.deleteCustomerModal.title')}
				</Text>
			</Menu.Item>
			<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
				<Modal.DialogTitle
					title={t('customersPage.deleteCustomerModal.title')}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent>
					<Text>{t('customersPage.deleteCustomerModal.message')}</Text>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant={'text'} onClick={toggleOpen}>
							{t('buttons.back')}
						</Button>
						<Button
							variant="outlined"
							onClick={deleteTeam}
							disabled={mutationResult.loading}
							startIcon={mutationResult.loading ? <LoadingIcon /> : null}
						>
							{t('buttons.delete')}
						</Button>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default DeleteCustomerModal;
