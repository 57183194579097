import { toDisplayString } from '../units/transpileUnits';
import { TJobTreatment } from './isJobTreatment';
import { TOriginalProduct } from './isOriginalProduct';

export default function (job: {
	treatments: (Pick<TJobTreatment, 'amount' | 'unit'> & {
		originalProduct?: Pick<TOriginalProduct, 'name'> | null;
	})[];
}) {
	return job.treatments.length
		? job.treatments
				.filter((treatment) => treatment.originalProduct?.name)
				.map(
					(treatment) =>
						treatment.originalProduct?.name +
						' · ' +
						treatment.amount +
						' ' +
						toDisplayString(treatment.unit)
				)
		: ['-'];
}
