import { TJob } from 'Models/jobs/isJob';

function getMaxLengthNotes(jobs: Pick<TJob, 'notes'>[]) {
	let maxNotesLength = 0;
	for (const job of jobs) {
		if (job.notes && job.notes.length > maxNotesLength) {
			maxNotesLength = job.notes.length;
		}
	}
	return maxNotesLength;
}

export default getMaxLengthNotes;
