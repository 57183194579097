import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { URLFiltersType } from 'Models/history/isURLFilters';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';

import useLocationID from 'Hooks/useLocationID';

import { GET_TEAMS_QUERY } from './api/getTeams';

type TTeamsProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const Teams = (props: TTeamsProps) => {
	const { updateFilters, filters } = props;
	const locationId = useLocationID();

	const { data, loading, error } = useQuery(GET_TEAMS_QUERY, {
		variables: {
			locationId: locationId,
		},
	});

	if (loading) {
		return <LoadingSpinner width={64} height={64} />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const selectedTeams = filters.teamId || [];

		const handleChange = (id: string) => {
			const isSelected = selectedTeams.includes(id);
			const newSelectedTeams = isSelected
				? selectedTeams.filter((i) => i !== id)
				: [...selectedTeams, id];

			updateFilters({
				...filters,
				teamId: newSelectedTeams,
			});
		};

		return (
			<Box display="flex" flexDirection="column">
				{data.getTeams.map((team) => {
					const isSelected = selectedTeams.includes(team.id);

					return (
						<Checkbox.Controlled
							key={team.id}
							label={team.name || team.id}
							checked={isSelected}
							onChange={() => handleChange(team.id)}
							size="small"
							dense
							fullWidth
						/>
					);
				})}
			</Box>
		);
	}

	return null;
};

export default Teams;
