import React from 'react';

import { TextField as MUITextField, TextFieldProps } from '@mui/material';

type TTextFieldProps = {
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	value?: string;
	fullWidth?: boolean;
	onChange?: (newValue: string, value?: string) => void;
	helperText?: string;
	type?: string;
} & TextFieldProps;

const TextField = (props: TTextFieldProps): JSX.Element => {
	const {
		placeholder,
		label,
		onChange,
		value,
		fullWidth,
		helperText,
		type,
		disabled,
		...otherProps
	} = props;

	const onChangeEvent = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValue = event.target.value;
		if (onChange) {
			onChange(newValue, value);
		}
	};

	return (
		<MUITextField
			fullWidth={fullWidth !== false}
			color="primary"
			label={label}
			onChange={onChangeEvent}
			placeholder={placeholder}
			value={value}
			variant="outlined"
			helperText={helperText}
			type={type}
			disabled={disabled}
			{...otherProps}
		/>
	);
};

export default TextField;
