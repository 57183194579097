import React, { ReactElement } from 'react';

import { TTranslateFunction } from 'Features/localization';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import fieldsArea from 'Models/fields/fieldsArea';
import { AreaUnits } from 'Models/units';
import convertAreaUnit from 'Models/units/convertAreaUnit';
import roundToTwoDecimal from 'Models/units/roundToTwoDecimal';
import { toDisplayString } from 'Models/units/transpileUnits';
import PieChart from 'UI/display/PieChart';
import useTextElementStyles from 'UI/display/PieChart/hooks/useTextElementStyles';
import Box from 'UI/layout/Box';
import _uniq from 'lodash/uniq';

import translateProductCategory from 'Utils/translateProductCategory';

import { TCropSummaryVariety } from '../../../../../FieldDashboardPage/components/CropSummary/utils/getSummarySectionData';
import { TCropSummaryCategory } from '../../../../utils/getSummarySectionData';

type TChartTextElement = [string, string, string];

type TStyledTooltipProps = {
	text: TChartTextElement | null;
};

function StyledTooltip(props: TStyledTooltipProps) {
	const { root, large, medium, small } = useTextElementStyles();
	const { text } = props;

	if (text) {
		return (
			<span className={root}>
				<span className={large}>{text[0]}</span>
				<span className={medium}>{text[1]}</span>
				<span className={small}>{text[2]}</span>
			</span>
		);
	}

	return null;
}

function getAreaText(
	areaSize: number,
	areaUnit?: AreaUnits,
	convert?: boolean
) {
	const areaUnitString = areaUnit ? toDisplayString(areaUnit) : '';
	const fieldArea = convert
		? convertAreaUnit(areaSize, areaUnit)
		: roundToTwoDecimal(areaSize);

	return `${fieldArea}\u00A0${areaUnitString}`;
}

function getFieldsText(translateFunction: TTranslateFunction, fields?: number) {
	const fieldsText = Number(fields)
		? `${fields} ${translateFunction('labels.field', {
				count: fields,
		  }).toLocaleLowerCase()}`
		: '';
	return fieldsText;
}

function getTextElements(
	item: TCropSummaryVariety | TCropSummaryCategory,
	translateFunction: TTranslateFunction
): TChartTextElement {
	const { areaUnit, areaSize, category } = item;
	const fieldsCount = 'fieldIds' in item ? item.fieldIds.length : undefined;

	return [
		getAreaText(areaSize, areaUnit, true),
		translateProductCategory(category, translateFunction),
		getFieldsText(translateFunction, fieldsCount),
	];
}

function getTotalTextElements(
	varieties: (TCropSummaryVariety | TCropSummaryCategory)[],
	translateFunction: TTranslateFunction
): TChartTextElement {
	const summary = varieties.reduce(
		(totals, variety: TCropSummaryVariety | TCropSummaryCategory) => {
			const { areaSize, areaUnit } = variety;
			const fieldArea = fieldsArea({
				areaSize,
				location: { areaUnit },
			});

			const newTotals = {
				area: totals.area + fieldArea,
				unit: areaUnit || totals.unit,
				fields: _uniq([
					...totals.fields,
					...('fieldIds' in variety ? variety.fieldIds : []),
				]),
			};

			return newTotals;
		},
		{
			area: 0,
			unit: '' as AreaUnits,
			fields: [] as string[],
		}
	);

	return [
		getAreaText(summary.area, summary.unit),
		translateFunction('labels.total'),
		getFieldsText(translateFunction, summary.fields?.length),
	];
}

type TVariety = TCropSummaryCategory | TCropSummaryVariety;

type TChartProps = {
	varieties: TCropSummaryCategory[] | TCropSummaryVariety[];
};

export default function Chart({ varieties }: TChartProps): ReactElement {
	const { t } = useTranslation();
	const chartData = varieties.map((variety: TVariety) => {
		const varietyText = <StyledTooltip text={getTextElements(variety, t)} />;

		return {
			y: variety.areaSize,
			textElement: varietyText,
		};
	});

	const tooltipText = chartData.length
		? getTotalTextElements(varieties, t)
		: null;

	const totalData = <StyledTooltip text={tooltipText} />;

	return (
		<Box display="flex" justifyContent="center" paddingBottom="2.25rem">
			<PieChart
				data={chartData}
				defaultTextElement={totalData}
				innerSize={80}
			/>
		</Box>
	);
}
