import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import isJobStatus from 'Models/jobs/isJobStatus';
import translateJobStatus from 'Models/jobs/translateJobStatus';

import useCompanyID from 'Hooks/useCompanyID';
import useManageHistory from 'Hooks/useManageHistory';
import getDateFilterTag from 'Utils/getDateFilterTag';
import getDateFnsLocale from 'Utils/getDateFnsLocale';

import GET_CUSTOM_JOB_TYPES from '../../../../components/filters/Treatments/api/getCustomJobTypes';
import FilterTag from './FilterTag';
import LocationFilterTag from './LocationFilterTag';

const Filters = () => {
	const { t, i18n } = useTranslation();
	const isPartner = Boolean(useCompanyID());
	const manageHistory = useManageHistory();

	const {
		categories,
		completedDateFrom,
		completedDateTo,
		dateFrom,
		dateTo,
		fieldCategories,
		fieldIds,
		fieldVarieties,
		jobTypes,
		customJobTypeIds,
		locations,
		teamId,
		timeFrom,
		timeTo,
		varieties,
		season,
		jobStatuses,
		movementTypes,
		teamMemberIds,
	} = manageHistory.paramsFromURL.filters;

	const { data } = useQuery(GET_CUSTOM_JOB_TYPES, {
		skip: isPartner || !customJobTypeIds || customJobTypeIds.length > 1,
		variables: { locationId: manageHistory.paramsFromURL.locationID },
	});

	const translatedStatuses = jobStatuses
		?.filter(isJobStatus)
		.map((status) => translateJobStatus(status, t, isPartner));

	const dateFnsOptions = {
		locale: getDateFnsLocale(i18n.language),
	};

	const date = getDateFilterTag(
		dateFnsOptions,
		completedDateFrom || dateFrom || timeFrom,
		completedDateTo || dateTo || timeTo,
		season
	);

	const jobTypesValue = jobTypes?.length
		? [...jobTypes, ...(customJobTypeIds || [])]
		: customJobTypeIds?.map(
				(cus) => data?.customJobTypes.find((e) => e.id === cus)?.name || cus
		  ) || [];

	const MultiChoiceFilterTag = (props: {
		values?: string[];
		labelName:
			| 'status'
			| 'field'
			| 'variety'
			| 'category'
			| 'treatment'
			| 'team'
			| 'movementType'
			| 'teamMembers';
	}) => {
		const { values, labelName } = props;
		return values?.length ? (
			<FilterTag
				name={t(`labels.${labelName}`, {
					count: values.length,
				})}
				value={values.length == 1 ? values[0] : values.length}
			/>
		) : null;
	};

	return (
		<>
			{season ? <FilterTag name={t('labels.season')} value={season} /> : null}
			{date}
			<MultiChoiceFilterTag
				values={categories || fieldCategories}
				labelName="category"
			/>
			<MultiChoiceFilterTag
				values={varieties || fieldVarieties}
				labelName="variety"
			/>
			{locations ? (
				<LocationFilterTag
					locations={locations}
					label={t('labels.location', {
						count: locations.length,
					})}
				/>
			) : null}
			<MultiChoiceFilterTag values={jobTypesValue} labelName="treatment" />
			<MultiChoiceFilterTag values={teamId} labelName="team" />
			<MultiChoiceFilterTag values={translatedStatuses} labelName="status" />
			<MultiChoiceFilterTag values={fieldIds} labelName="field" />
			<MultiChoiceFilterTag values={movementTypes} labelName="movementType" />
			<MultiChoiceFilterTag values={teamMemberIds} labelName="teamMembers" />
		</>
	);
};

export default Filters;
