import isNotEmpty from 'vet/strings/isNotEmpty';

import { TCustomer } from './isCustomer';

const customerAddressToDisplayString = (customer: TCustomer) => {
	return [customer.address, customer.city, customer.zip, customer.country]
		.filter(isNotEmpty)
		.join(' ');
};

export default customerAddressToDisplayString;
