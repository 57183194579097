import debounce from 'lodash/debounce';

const DEFAULT_DEBOUNCE_TIME = 150;

// Copy of DebouncedFunc from lodash
interface DebouncedFunc<T extends (...args: any[]) => any> {
	(...args: Parameters<T>): ReturnType<T> | undefined;
	cancel(): void;
	flush(): ReturnType<T> | undefined;
}

export default function getDebounce(
	debounceTime?: number
): DebouncedFunc<(callback: () => void) => void> {
	return debounce(
		(callback: () => void) => callback(),
		debounceTime || DEFAULT_DEBOUNCE_TIME
	);
}
