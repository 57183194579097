import React from 'react';

import Divider from 'UI/display/Divider';
import Box from 'UI/layout/Box';

import BatchList from './BatchList';
import Header from './Header';
import SalesStats from './SalesStats';
import SeasonFilter from './SeasonFilter';
import SoldFilter from './SoldFilter';
import VarietiesFilter from './VarietiesFilter';

const Sidebar = () => {
	return (
		<Box height="100%" display="flex" flexDirection="column">
			<Header />
			<Divider />
			<SalesStats />
			<Divider />
			<Box paddingY="0.75rem" paddingX="1rem" display="flex">
				<SeasonFilter />
				<VarietiesFilter />
				<SoldFilter />
			</Box>
			<Divider />
			<BatchList />
		</Box>
	);
};

export default Sidebar;
