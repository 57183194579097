import useSegmentAnalytics from './hooks/useSegmentAnalytics';
import isIdentifyTraits, {
	TIdentifyTraits,
} from './models/identify/isIdentifyTraits';
import TrackEvents from './models/track/TrackEvents';
import { TTrackProperties } from './models/track/TrackProperties';
import DataEvent from './models/track/data/DataEvent';

/// <reference types="@types/segment-analytics" />
declare global {
	interface Window {
		analytics?: SegmentAnalytics.AnalyticsJS;
	}
}

function analyticsTrack(
	name: TrackEvents.DataExported | TrackEvents.DataDownloaded,
	properties: Pick<
		TTrackProperties,
		'userId' | 'groupId' | 'exportType' | 'logType'
	>
): void;
function analyticsTrack(
	name:
		| TrackEvents.ProductModified
		| TrackEvents.AccountModified
		| TrackEvents.TeamDeleted,
	properties: Pick<TTrackProperties, 'userId' | 'groupId'>
): void;
function analyticsTrack(
	name: TrackEvents.ProductViewed | TrackEvents.CheckoutStarted,
	properties: Pick<
		TTrackProperties,
		'userId' | 'groupId' | 'farmName' | 'moduleName'
	>
): void;
function analyticsTrack(
	name: TrackEvents.SignedIn | TrackEvents.SignedUp,
	properties: Pick<TTrackProperties, 'userId' | 'authSource'>
): void;
function analyticsTrack(
	name: TrackEvents.SignedOut | TrackEvents.SeeDemoReportClicked,
	properties: Pick<TTrackProperties, 'userId'>
): void;
function analyticsTrack(
	name: TrackEvents.NoteCreated,
	properties: Pick<
		TTrackProperties,
		'userId' | 'groupId' | 'photoAdd' | 'locationAdd'
	>
): void;
function analyticsTrack(
	name: TrackEvents.TeamCreated | TrackEvents.TeamModified,
	properties: Pick<TTrackProperties, 'userId' | 'groupId' | 'teamSize'>
): void;
function analyticsTrack(
	name: TrackEvents[number],
	properties?: Partial<TTrackProperties>
): void {
	if (window.analytics && process.env.SEGMENT_ID) {
		window.analytics.track(name, properties);
	}
}

function analyticsIdentify(userId: string, traits?: TIdentifyTraits): void {
	if (window.analytics && process.env.SEGMENT_ID) {
		window.analytics.identify(userId, traits);
	}
}

function analyticsPage(category?: string, name?: string): void {
	if (window.analytics && process.env.SEGMENT_ID) {
		const { analytics } = window;
		analytics.page(category, name);
	}
}

export {
	isIdentifyTraits,
	DataEvent,
	TrackEvents,
	analyticsTrack,
	analyticsIdentify,
	analyticsPage,
	useSegmentAnalytics,
};
