import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function EditIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				d="M12.76 18h6a1 1 0 0 1 0 2h-6a1 1 0 1 1 0-2zm1.897-13.082a3.132 3.132 0 1 1 4.43 4.43l-9.609 9.61a1 1 0 0 1-.511.274l-3.77.753a1 1 0 0 1-1.177-1.177l.755-3.77a1 1 0 0 1 .273-.51zM13.382 9.02l-6.705 6.707-.401 2.002 2.002-.4 6.705-6.707-1.601-1.602zm4.29-2.688a1.132 1.132 0 0 0-1.6 0l-1.276 1.274 1.602 1.602 1.275-1.275c.379-.379.433-.96.162-1.397l-.074-.106z"
				fillRule="nonzero"
			/>
		</SvgIcon>
	);
}

export default EditIcon;
