import React, { ReactElement, useRef } from 'react';

import useMonthsTranslation from 'Features/localization/hooks/useMonthsTranslation';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TSeasonParams } from 'Models/seasons/isSeasonParams';
import Chart, { TChartRef } from 'UI/display/Chart';
import { FarmableColors } from 'UI/theme/Colors';
import { CurrencyCodeRecord } from 'currency-codes';
import Highcharts, { SeriesOptionsType } from 'highcharts';

import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

import getChartConfig from './components/ChartConfig';
import styles from './styles.module.scss';
import { TChartData } from './types';
import getYearsForTooltip from './utils/getYearsForTooltip';

export type TCurrencyData = {
	revenue: TChartData[];
	cost: TChartData[];
	profit: TChartData[];
};

export type TDataPerCurrencyMapping = Record<
	CurrencyCodeRecord['code'],
	TCurrencyData
>;

type TProfitChartProps = {
	data: TDataPerCurrencyMapping;
	displayProductsWithoutCostInfo: boolean;
	seasonParams: TSeasonParams;
};

const GROUP_PADDING = 0.1;

function createSeriesOptions(
	data: TDataPerCurrencyMapping
): SeriesOptionsType[] {
	const series: SeriesOptionsType[] = [];
	Object.keys(data).forEach((currencyCode, i, currencies) => {
		const { revenue, cost, profit } = data[currencyCode];

		const negativeCost = cost.map((point) => {
			return {
				...point,
				y: -point.y,
			};
		});

		let sum = 0;
		const accumulatedProfit = profit.map((profitPoint) => {
			sum += profitPoint.y;
			return {
				...profitPoint,
				y: sum,
			};
		});

		series?.push(
			{
				type: 'column',
				name: `Revenue (${currencyCode})`,
				data: revenue,
				color: FarmableColors.LIGHT_BLUE,
				stack: currencyCode,
				custom: {
					currencyCode,
					type: 'revenue',
					rawData: revenue,
				},
			},
			{
				type: 'column',
				name: `Cost (${currencyCode})`,
				data: negativeCost,
				color: FarmableColors.ORANGE_13_42,
				stack: currencyCode,
				custom: {
					currencyCode,
					type: 'cost',
					rawData: cost,
				},
			},
			{
				type: 'line',
				name: `Profit (${currencyCode})`,
				data: accumulatedProfit,
				color: FarmableColors.NAVY,
				zIndex: 1,
				stack: currencyCode,
				custom: {
					currencyCode,
					type: 'profit',
					rawData: profit,
				},
				pointPlacement:
					-0.5 +
					GROUP_PADDING +
					((1 / currencies.length) * (i + 1) - 0.5 / currencies.length) *
						(1 - GROUP_PADDING * 2),
			}
		);
	});
	return series;
}

function createTooltipItem(
	seriesName: string,
	markerColor: string,
	value: number,
	currency: string
): string {
	const marker = `<span style="color: ${markerColor}">■</span>`;

	return `<div class="${styles.tooltipSeriesItem}">
	<div>${marker} ${seriesName}:</div><div>${Highcharts.numberFormat(
		value,
		0,
		'',
		' '
	)} ${currency}</div>
	</div>`;
}

const ProfitChart = (props: TProfitChartProps): ReactElement => {
	const chartComponent = useRef<TChartRef>(null);

	const { t } = useTranslation();

	const options = getChartConfig(t);

	const { data, displayProductsWithoutCostInfo, seasonParams } = props;

	const monthsTranslation = useMonthsTranslation(
		'short',
		seasonParams?.seasonCutoffDate
	);

	const yearsForTooltip = getYearsForTooltip(seasonParams);
	const profitTranslation = t('labels.profit');
	const revenueTranslation = t('labels.revenue');
	const costTranslation = t('labels.cost');

	options.xAxis = {
		...options.xAxis,
		categories: monthsTranslation.map(stringToFirstUppercase),
	};

	options.plotOptions = {
		...options.plotOptions,
		column: {
			...options.plotOptions?.column,
			groupPadding: GROUP_PADDING,
		},
	};

	if (displayProductsWithoutCostInfo) {
		options.subtitle = {
			text: `<div class="${styles.subtitle}">
				${t('farmDashboardPage.profitChart.productsWithMissingCostInfo')}
			</div>`,
			verticalAlign: 'bottom',
			align: 'right',
			useHTML: true,
			y: -35,
			x: -40,
		};
	}

	options.tooltip = {
		...options.tooltip,
		formatter: function () {
			const pointsByCurrency = {};
			const currenciesHTML: string[] = [];

			this.points?.forEach((point) => {
				const currency = point.series.options.custom?.currencyCode;
				const type = point.series.options.custom?.type;
				if (currency) {
					pointsByCurrency[currency] = {
						...pointsByCurrency[currency],
					};
					pointsByCurrency[currency][type] = point;
				}
			});

			for (const currency in pointsByCurrency) {
				const currencyHeader = `<div class="${styles.tooltipCurrencyHeader}"><b>${currency}:</b></div>`;

				const { profit, revenue, cost } = pointsByCurrency[currency];

				const year = yearsForTooltip[cost.point.index];

				const profitRange = `- ${profit.x.toUpperCase()}`;

				const profitHeader = `<div class="${styles.tooltipHeader}">
				${monthsTranslation[0].toUpperCase()} ${
					profit.point.x ? profitRange : '' // don't display range only for January
				} ${year}
				</div>`;

				const costAndRevenueHeader = `<div class="${styles.tooltipHeader}">
				${profit.x.toUpperCase()} ${year}
				</div>`;

				const profitItem = createTooltipItem(
					profitTranslation,
					profit.color,
					profit.y,
					currency
				);

				const revenueItem = createTooltipItem(
					revenueTranslation,
					revenue.color,
					revenue.y,
					currency
				);

				const costItem = createTooltipItem(
					costTranslation,
					cost.color,
					-cost.y,
					currency
				);

				currenciesHTML.push(`
					<div class="${styles.tooltipCurrencySection}">
						${currencyHeader}
						<div class="${styles.tooltipSection}">
							${profitHeader}
							${profitItem}
						</div>
						<div class="${styles.tooltipSection}">
							${costAndRevenueHeader}
							${revenueItem}
							${costItem}
						</div>
					</div>
				`);
			}

			return `<div class="${styles.tooltipContainer}">${currenciesHTML.join(
				''
			)}</div>`;
		},
	};

	options.series = createSeriesOptions(data);

	return <Chart options={options} ref={chartComponent} />;
};

export default ProfitChart;
