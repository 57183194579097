import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Roles from 'Models/permissions/Roles';
import AddIcon from 'UI/icons/Add';
import Breakpoints from 'UI/theme/breakpoints';
import useBreakpoint from 'UI/utils/useBreakpoint';

import useProfileRoles from 'Hooks/useProfileRoles';
import getFeatureFlag from 'Utils/getFeatureFlag';
import { isPartnershipCompanyRole } from 'Utils/hasPartnerCompany';

import Template from '../../../../../components/DashboardPageTemplate';
import InviteFarmsModal from '../../../../../components/InviteFarmsModal';
import CropSummary from '../CropSummary';
import ManageFarms from '../ManageFarms';
import SearchMap from '../SearchMap';
import TemperatureChartSection from '../TemperatureChartSection';
import Breadcrumbs from './../../../common/components/Breadcrumbs';
import PartnerRecentEvents from './../../../common/components/PartnerRecentEvents';

const PageBody = () => {
	const { t } = useTranslation();
	const { profileRoles } = useProfileRoles();
	const isTablet = useBreakpoint(Breakpoints.TABLET);

	const hasEnabledGDDChart = getFeatureFlag('DASHBOARD_GDD_CHART');

	const userPartnerRole = profileRoles.find((role) =>
		isPartnershipCompanyRole(role)
	)?.role;
	const hasEditPermission =
		userPartnerRole && userPartnerRole !== Roles.PA_MEMBER;

	const getGDDChart = function (width: 4 | 8 | 12): ReactElement {
		return (
			<Template.Card
				size={width}
				title={t('farmDashboardPage.temperatureChart.title')}
			>
				<TemperatureChartSection />
			</Template.Card>
		);
	};

	return (
		<Template>
			<Template.Navigation>
				<Breadcrumbs />
			</Template.Navigation>
			<Template.Board>
				<Template.Card
					size={8}
					title={t('partnerDashboardPage.manageFarmsSection.title')}
					action={
						hasEditPermission ? (
							<InviteFarmsModal
								buttonProps={{
									variant: 'secondary',
									color: 'primary',
									startIcon: <AddIcon />,
								}}
							/>
						) : undefined
					}
					noHeaderBorder
					noHeaderHeight
					noPadding
				>
					<ManageFarms />
				</Template.Card>
				<Template.Card size={4} noPadding noHeaderBorder>
					<SearchMap />
				</Template.Card>
				<Template.Card
					size={isTablet ? 6 : 4}
					title={t('farmDashboardPage.summarySection.title')}
					noHeaderBorder
					noPadding
				>
					<CropSummary />
				</Template.Card>
				<Template.Card
					size={isTablet ? 6 : 4}
					title={t('farmDashboardPage.recentEvents.title')}
					noPadding
				>
					<PartnerRecentEvents />
				</Template.Card>
				{hasEnabledGDDChart ? getGDDChart(isTablet ? 12 : 8) : null}
			</Template.Board>
		</Template>
	);
};

export default PageBody;
