import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ErrorBoundary from '../../../components/ErrorBoundary';
import ProfilePageAccessValidator from '../common/components/ProfilePageAccessValidator';
import PageBody from './components/PageBody';

function FarmSettingsPage(): ReactElement {
	const { t } = useTranslation();

	return (
		<ProfilePageAccessValidator
			header={t('farmSettingsPage.title')}
			subheader={t('farmSettingsPage.description')}
		>
			<ErrorBoundary>
				<PageBody />
			</ErrorBoundary>
		</ProfilePageAccessValidator>
	);
}

export default FarmSettingsPage;
