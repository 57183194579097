import React, { useEffect } from 'react';

import { setTag } from '@sentry/react';
import { useSegmentAnalytics } from 'Features/analytics';
import { ConsentManager } from 'Features/analytics/components/ConsentManager';
import { initGoogleAPILoader } from 'Features/google/initGoogleMapsAPILoader';
import { i18n } from 'Features/localization';
import useLocation from 'Features/router/hooks/useLocation';
import { highchartsInstance, noDataPlugin } from 'UI/display/Chart';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsSolidGauge from 'highcharts/modules/solid-gauge';

import useProfile from 'Hooks/useProfile';
import initializeProj4 from 'Utils/initializeProj4';

import ErrorBoundary from './components/ErrorBoundary';
import FarmableRouter from './components/FarmableRouter';
import IntercomInit from './components/IntercomInit';
import withDisabledResponsiveness from './components/withDisabledResponsiveness';
import SnackbarProvider from './features/snackbars';

highchartsMore(highchartsInstance);
highchartsSolidGauge(highchartsInstance);
noDataPlugin(highchartsInstance);

// initialize localization
// eslint-disable-next-line
const translations = i18n;

const Router = withDisabledResponsiveness(FarmableRouter);

const App = () => {
	const location = useLocation();
	const { profile } = useProfile();

	useEffect(() => {
		initializeProj4();
		initGoogleAPILoader();
	}, []);

	if (profile) {
		setTag('user.email', profile.email);
	}

	useSegmentAnalytics(location.pathname);

	return (
		<ErrorBoundary fullHeight withLogout>
			<IntercomInit.User />
			<Router />
			<ConsentManager />
			<SnackbarProvider />
		</ErrorBoundary>
	);
};

export default App;
