import { TGovernmentProductInputPartial } from 'Models/products/GovernmentProductInput';
import { isProductInput, TProductInput } from 'Models/products/ProductInput';

export const sanitizeProductInput = (
	input:
		| {
				ingredients?: TProductInput['ingredients'];
		  }
		| TGovernmentProductInputPartial
) => {
	if (isProductInput(input)) {
		return {
			...input,
			ingredients: input.ingredients?.map((ingredient) => {
				if (ingredient && 'id' in ingredient) {
					const { id, ...rest } = ingredient;
					return rest;
				}
				return ingredient;
			}),
		};
	}
	return input;
};
