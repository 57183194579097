import { alpha } from '@mui/material';

import { FarmableColors, FarmableTextColors } from './Colors';
import { IconSizes } from './Sizes';

export default {
	MuiPaper: {
		styleOverrides: {
			root: { borderRadius: '0.5rem' },
		},
	},
	MuiFormControl: {
		styleOverrides: {
			root: {
				// Additional margin to avoid cropping outline label by parent element.
				'&:first-of-type': {
					marginTop: '0.25rem',
				},
			},
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				marginTop: '0.125rem',
				marginLeft: '1rem',
				lineHeight: 1.33,
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				'.MuiFormControl-marginDense &:not(.MuiInputLabel-shrink)': {
					top: -5,
					transform: 'translate(0.875rem, 0.875rem) scale(1)',
				},
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
					borderColor: FarmableColors.BLACK_12,
				},
			},
			input: {
				'.MuiFormControl-marginDense &': {
					padding: '0.7rem 1rem',
				},
				'&.MuiInputBase-inputSizeSmall': {
					padding: '0.4rem 0.875rem',
				},
				padding: '1rem',
				height: '1.25rem',
			},
			notchedOutline: {
				borderColor: FarmableColors.BLACK_12,
				'&:hover': {
					borderColor: FarmableColors.BLACK_38,
				},
				'&:focus': {
					borderColor: FarmableColors.BLACK_38,
				},
			},
		},
	},
	MuiTypography: {
		styleOverrides: {
			gutterBottom: {
				marginBottom: '0.5rem',
			},
		},
	},
	MuiSelect: {
		styleOverrides: {
			select: {
				fontSize: '0.875rem',
				color: FarmableTextColors.PRIMARY,
				minHeight: '1.25rem',
				height: 0,
				'&.MuiInputBase-inputSizeSmall': {
					padding: '0.7rem 1rem',
				},
			},
			selectMenu: {
				fontSize: '0.875rem',
				color: FarmableTextColors.PRIMARY,
				height: '1.25rem',
			},
		},
	},
	MuiCheckbox: {
		styleOverrides: {
			root: {
				transition: 'background-color 250ms, color 250ms',
				'&:hover': {
					backgroundColor: alpha('#000', 0.04),
				},
				'&.Mui-checked:hover': {
					backgroundColor: alpha(FarmableColors.ORANGE, 0.08),
				},
				'&$disabled': {
					color: FarmableTextColors.DISABLED,
				},
				color: FarmableTextColors.SECONDARY,
			},
		},
	},
	MuiListItemIcon: {
		styleOverrides: {
			root: {
				minWidth: '2.5rem',
			},
		},
	},
	MuiMenu: {
		styleOverrides: {
			root: {
				'& .MuiPopover-paper': { minWidth: '12rem', borderRadius: '6px' },
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: { fontSize: '0.875rem' },
			dense: {
				padding: '0.125rem 1.5rem',
			},
		},
	},
	MuiButton: {
		styleOverrides: {
			root: {
				fontWeight: 'bold',
				boxShadow: 'none',
				borderRadius: '6px',
				borderColor: FarmableColors.BLACK_12,
				'&:hover': {
					borderColor: FarmableColors.BLACK_12,
				},
				'&:focus': {
					borderColor: FarmableColors.ORANGE,
				},
				'&:active': {
					borderColor: FarmableColors.ORANGE,
				},
			},
			containedPrimary: {
				'&:hover': {
					boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px ${FarmableColors.BLACK_12}, 0 1px 1px 0 rgba(0, 0, 0, 0.14)`,
				},
			},
			outlinedSecondary: {
				fontWeight: 'normal',
				borderRadius: '2rem',
				background: FarmableColors.WHITE,
				color: FarmableColors.BLACK_87,
				padding: '3px 12px',
				'&:hover': {
					borderColor: FarmableColors.BLACK_12,
					background: FarmableColors.BLACK_4,
				},
				'&:focus': {
					borderColor: FarmableColors.BLACK_12,
					background: FarmableColors.BLACK_12,
				},
				'&:active': {
					borderColor: FarmableColors.BLACK_12,
					background: FarmableColors.WHITE,
					boxShadow: `0 1px 3px 1px ${FarmableColors.BLACK_12}`,
				},
			},
			startIcon: {
				color: FarmableColors.ORANGE,
				'.Mui-disabled & svg': {
					color: FarmableColors.BLACK_38,
				},
			},
			endIcon: {
				color: FarmableColors.BLACK_87,
				'*:nth-of-type(1)': {
					margin: '0 -0.2rem 0 -0.4rem',
					fontSize: '1.5rem',
				},
				'.Mui-disabled & svg': {
					color: FarmableColors.BLACK_38,
				},
			},
		},
	},
	MuiBreadcrumbs: {
		styleOverrides: {
			root: {
				fontSize: '1.75rem',
				'&:active': {
					color: 'rgba(0, 0, 0, 0.87)',
				},
			},
		},
	},
	MuiDialog: {
		styleOverrides: {
			paper: {
				borderRadius: '0.5rem',
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				padding: '0 1rem',
				height: '3.25rem',
				minWidth: '4rem',
				borderBottom: `1px solid ${FarmableColors.BLACK_12}`,
			},
			head: {
				fontWeight: 'bold',
				lineHeight: '18px',
				fontSize: '0.875rem',
			},
		},
	},
	MuiTablePagination: {
		defaultProps: {
			showFirstButton: true,
			showLastButton: true,
		},
		styleOverrides: {
			root: {
				borderBottom: 'none',
			},
			select: {
				borderRadius: '0.25rem',
				border: `solid 1px ${FarmableColors.BLACK_12}`,
			},
			selectIcon: { width: '2rem' },
		},
	},
	MuiSvgIcon: {
		styleOverrides: {
			colorPrimary: { color: FarmableColors.BLACK_87 },
			colorSecondary: { color: FarmableColors.BLACK_60 },
			colorAction: { color: FarmableColors.ORANGE },
			colorSuccess: { color: FarmableColors.GREEN },
			colorError: { color: FarmableColors.RED },
			fontSizeLarge: { width: IconSizes.sm, height: IconSizes.sm },
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				'&.Mui-disabled': {
					opacity: 1,
				},
			},
		},
	},
};
