import { HarvestCountingUnit } from '../units';
import roundToTwoDecimal from '../units/roundToTwoDecimal';
import { THarvest } from './types/Harvest';

function harvestVolume(
	harvest: Pick<THarvest, 'countingUnit' | 'volume'>
): string | number {
	if (harvest.countingUnit === HarvestCountingUnit.BOXES && harvest.volume) {
		return roundToTwoDecimal(harvest.volume);
	}

	return 'N/A';
}

export default harvestVolume;
