import { formatISO } from 'date-fns';

function getDateRepresentationFromString(date?: null): undefined;
function getDateRepresentationFromString(date: Date | string): string;
function getDateRepresentationFromString(date?: Date): string | undefined;
function getDateRepresentationFromString(date?: string): string | undefined;
function getDateRepresentationFromString(
	date?: Date | null
): string | undefined;
function getDateRepresentationFromString(date?: Date | string | null) {
	return date
		? formatISO(new Date(date), { representation: 'date' })
		: undefined;
}

export default getDateRepresentationFromString;
