import React from 'react';

import { TileLayer } from 'react-leaflet';

const GMapLayer = () => (
	<TileLayer
		url="//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
		subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
		maxZoom={18}
		maxNativeZoom={20}
	/>
);

export default GMapLayer;
