import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import NoLogsStatement from '../../../../common/components/NoLogsStatement';
import GET_PARTNER_HOURS_TABLE_DATA from '../../../api/getPartnerHoursTableData';
import TableRow from './TableRow';

const TableBody = () => {
	const manageHistory = useManageHistory();
	const { filters, pageInput } = manageHistory.paramsFromURL;

	const { data, loading, error } = useQuery(GET_PARTNER_HOURS_TABLE_DATA, {
		variables: {
			filterBy: {
				timesheetFilters: {
					teamId: filters.teamId,
					timeFrom: filters.timeFrom,
					timeTo: filters.timeTo,
					categories: filters.categories,
					varieties: filters.varieties,
				},
				locationIds: filters.locations,
			},
			pageInput: pageInput,
			hasPagination: true,
		},
	});

	if (loading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (!data?.getPartnerTimesheets.timesheets?.length) {
		return (
			<NoDataTableContainer>
				<NoLogsStatement logsType="Hours" />
			</NoDataTableContainer>
		);
	}

	return (
		<Table.Body>
			{data.getPartnerTimesheets.timesheets.map((timesheet) => (
				<TableRow
					key={timesheet.timesheet.id}
					timesheet={{ ...timesheet.timesheet, location: timesheet.location }}
				/>
			))}
		</Table.Body>
	);
};

export default TableBody;
