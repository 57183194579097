import { gql, TypedDocumentNode } from '@apollo/client';

const GET_FERTILIZER_MATERIALS: TypedDocumentNode<
	{
		getFertilizerMaterials: { id: string; name: string }[];
	},
	null
> = gql`
	query getFertilizerMaterials {
		getFertilizerMaterials {
			id
			name
		}
	}
`;

export default GET_FERTILIZER_MATERIALS;
