import { Locale } from 'date-fns';
import { enGB as en, de, es, nb, nl } from 'date-fns/locale';

// map locales from date-fns to i18next
const locales: Record<string, Locale> = {
	en,
	de,
	es,
	nb,
	nl,
};

function getDateFnsLocale(locale?: string): Locale {
	if (locale?.includes('-')) {
		const lang = locale.split('-')[0];
		return locales[lang] || locales.en;
	}
	return locale ? locales[locale] : locales.en;
}

export default getDateFnsLocale;
