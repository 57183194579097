import React, { ReactElement, useState } from 'react';

import TextField from 'UI/inputs/TextField';

import getDebounce from 'Utils/getDebounce';
import numberToDisplayString from 'Utils/numberToDisplayString';

const debounced = getDebounce();

type TTotalPlantsProps = {
	numberOfPlants: number | null | undefined;
	setNumberOfPlants: (area: number) => void;
};

function TotalPlants(props: TTotalPlantsProps): ReactElement {
	const { numberOfPlants, setNumberOfPlants } = props;
	const [inputValue, setInputValue] = useState(() =>
		numberToDisplayString(numberOfPlants)
	);

	function changeTotalHandler(value) {
		const isPositiveInteger = /^[1-9]\d*$/.test(value);

		if (isPositiveInteger) {
			const sanitizedNumberOfPlants = numberToDisplayString(value);
			setInputValue(sanitizedNumberOfPlants);
			const updateInput = () => setNumberOfPlants(parseInt(value));
			debounced(updateInput);
		}
	}

	return (
		<TextField
			label={'Total number of plants'}
			value={inputValue}
			type="number"
			onChange={changeTotalHandler}
			InputProps={{
				inputProps: {
					step: 1,
					min: 1,
				},
			}}
		/>
	);
}

export default TotalPlants;
