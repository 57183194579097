import { gql, TypedDocumentNode } from '@apollo/client';

type TTreatmentLibraryResponse = {
	getTreatmentLibrary: {
		id: string;
		name: string;
	};
};

type TGetTreatmentLibraryRequest = {
	libraryId: string;
};

export const GET_PARTNER_TREATMENT_LIBRARY: TypedDocumentNode<
	TTreatmentLibraryResponse,
	TGetTreatmentLibraryRequest
> = gql`
	query getTreatmentLibrary($libraryId: ID!) {
		getTreatmentLibrary(id: $libraryId) {
			id
			name
		}
	}
`;
