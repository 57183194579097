import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function IDCardIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				d="M22 3H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zm0 16H2V5h20v14zm-7-4h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2zm0-4h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2zm0-4h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2zm-7 5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4 3.59c0-1.5-1.97-2.58-4-2.58-2.03 0-4 1.08-4 2.58V16h8v-.41z"
				fillRule="nonzero"
			/>
		</SvgIcon>
	);
}

export default IDCardIcon;
