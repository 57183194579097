// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._8y5QX6S8Cb81CJxF-p_XN {\n  display: flex;\n  flex-direction: column;\n}\n\n._8y5QX6S8Cb81CJxF-p_XN>*:not(:last-child) {\n  margin-bottom: .25rem;\n  white-space: pre;\n}\n\n._1RIOwUG0lbDw2C_1xLr_c0 {\n  position: relative;\n}\n\n._1RIOwUG0lbDw2C_1xLr_c0::before,\n._1RIOwUG0lbDw2C_1xLr_c0::after {\n  content: \"\";\n  position: absolute;\n  display: block;\n  width: 24px;\n  height: 100%;\n  top: 0;\n}\n\n._1RIOwUG0lbDw2C_1xLr_c0::after {\n  right: 0;\n  background-image: linear-gradient(to left, #fff 0%, transparent 100%);\n}\n\n._1RIOwUG0lbDw2C_1xLr_c0::before {\n  left: 0;\n  background-image: linear-gradient(to right, #fff 0%, transparent 100%);\n}\n\n._1X-hqW2n0ljTCAabvx6EmY {\n  display: flex;\n}\n\n._1X-hqW2n0ljTCAabvx6EmY>._3TKLAUEdH0JZ5ZlkvtatR0 {\n  margin: 0 auto;\n  display: flex;\n  padding: 0 1.5rem;\n  overflow-x: auto;\n}\n\n._1X-hqW2n0ljTCAabvx6EmY>._3TKLAUEdH0JZ5ZlkvtatR0>.UyIpZQiyASXdywEcVjep1 {\n  display: flex;\n  border-radius: .5rem;\n  border: solid 1px rgba(0,0,0,.12);\n  padding: .75rem 1rem .75rem .75rem;\n}\n\n._1X-hqW2n0ljTCAabvx6EmY>._3TKLAUEdH0JZ5ZlkvtatR0>.UyIpZQiyASXdywEcVjep1:not(:last-child) {\n  margin-right: .375rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"descriptionSection": "_8y5QX6S8Cb81CJxF-p_XN",
	"overlay": "_1RIOwUG0lbDw2C_1xLr_c0",
	"list": "_1X-hqW2n0ljTCAabvx6EmY",
	"innerContainer": "_3TKLAUEdH0JZ5ZlkvtatR0",
	"card": "UyIpZQiyASXdywEcVjep1"
};
module.exports = exports;
