import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getLocationVarieties from 'Models/fields/getLocationVarieties';
import { URLFiltersType } from 'Models/history/isURLFilters';
import Permissions from 'Models/permissions/Permissions';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';
import _flatten from 'lodash/flatten';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';
import useProfileRole from 'Hooks/useProfileRole';

import DropdownContent from '../DropdownContent';
import {
	GET_FIELDS_QUERY,
	GET_FIELDS_ADVISOR_QUERY,
} from '../common/api/getFields';
import parseGetFieldsResult from '../common/utils/parseGetFieldsResult';

type TVarietiesProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
	paramName?: 'varieties' | 'fieldVarieties';
};

const Varieties = (props: TVarietiesProps) => {
	const { filters, updateFilters, paramName = 'varieties' } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();
	const roles = useProfileRole();
	const locationId = useLocationID();
	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY,
		roles
	);

	const getFieldsResult = useQuery(
		hasPermissionsToRegularQuery ? GET_FIELDS_QUERY : GET_FIELDS_ADVISOR_QUERY,
		{
			variables: {
				locationId,
			},
		}
	);

	const open = Boolean(anchorEl);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	if (getFieldsResult.loading) {
		return null;
	}

	const dropdownContent = getLocationVarieties(
		parseGetFieldsResult(getFieldsResult.data)
	);
	const appliedVarieties = filters[paramName] || [];
	const appliedVarietiesAmount = appliedVarieties.length
		? `(${appliedVarieties.length})`
		: '';

	if (getFieldsResult.error) {
		throw new ApolloError(getFieldsResult.error);
	}

	const onItemClick = (variety: string, active: boolean) => {
		const newVarieties: string[] = active
			? appliedVarieties.filter((fieldVariety) => fieldVariety !== variety)
			: [...appliedVarieties, variety];

		updateFilters({
			...filters,
			[paramName]: newVarieties,
		});
	};

	const onClear = () => {
		updateFilters({
			...filters,
			[paramName]: undefined,
		});
	};
	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{t('labels.variety', { count: 1 })} {appliedVarietiesAmount}
			</Button>
			<Menu.Menu
				id="varieties-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={getFieldsResult.loading}
					dropdownItems={dropdownContent}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={appliedVarieties}
				/>
			</Menu.Menu>
		</>
	);
};

export default Varieties;
