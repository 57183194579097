import { gql, TypedDocumentNode } from '@apollo/client';
import { TCause, TCauseType } from 'Models/causes/isCause';

type TGetPartnershipCausesResponse = {
	getPartnershipCauses: { causes: TCause[] };
};

type TGetPartnershipCausesInput = {
	filterBy: {
		name?: string;
		type?: TCauseType;
	};
};

const GET_PARTNERSHIP_CAUSES: TypedDocumentNode<
	TGetPartnershipCausesResponse,
	TGetPartnershipCausesInput
> = gql`
	query getPartnershipCauses(
		$filterBy: CauseFilterInput
		$pageInput: PageInput
	) {
		getPartnershipCauses(filterBy: $filterBy, pageInput: $pageInput) {
			causes {
				id
				name
				createdAt
				discriminator
				causeType
				description
				spanishSpec {
					scientificName
					siexCode
					eppoCode
				}
				causeImages {
					id
					path
					filename
					mimetype
					createdAt
				}
			}
		}
	}
`;

export default GET_PARTNERSHIP_CAUSES;
