import isString from 'vet/strings/isString';

import { TFieldInput } from './isFieldInput';

function fieldInputCategory(
	fieldInput: TFieldInput,
	translateFn?: (value: string) => string
): string {
	const categories = fieldInput?.varieties
		?.map((variety) => variety.category)
		.filter((category): category is string => isString(category));

	if (categories && categories.length > 0) {
		const translatedCategories = categories.map((category) =>
			translateFn ? translateFn(category) : category
		);

		return translatedCategories.join(', ');
	}

	return '-';
}

export default fieldInputCategory;
