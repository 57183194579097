import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import NoAccessPageTemplate from '../NoAccessPageTemplate';

const NoPermissionPage = (): ReactElement => {
	const { t } = useTranslation();

	return (
		<NoAccessPageTemplate
			title={t('noAccess.noPermissionPage.title')}
			description={t('noAccess.noPermissionPage.description')}
		/>
	);
};

export default NoPermissionPage;
