import React, { Fragment, ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

export type TLoadingStatus = 'failed' | 'success' | 'loading';

type TLoadingSectionProps = {
	status: TLoadingStatus;
	displayButton: boolean;
	failureMessage?: string;
	onClick: () => void;
};

function LoadingSection(props: TLoadingSectionProps): ReactElement | null {
	const { status, displayButton, failureMessage, onClick } = props;
	let section;
	const { t } = useTranslation();
	const showMoreMessage = t('buttons.showMore');

	switch (status) {
		case 'success':
			section = displayButton ? (
				<Button variant="text" onClick={onClick}>
					{showMoreMessage}
				</Button>
			) : null;
			break;
		case 'failed':
			section = (
				<Fragment>
					{failureMessage || (
						<Text variant="body2" color="textSecondary">
							{failureMessage}
						</Text>
					)}

					<Button variant="text" onClick={onClick}>
						{showMoreMessage}
					</Button>
				</Fragment>
			);
			break;
		default:
			section = <LoadingSpinner width={64} height={64} />;
	}

	return (
		section && (
			<Box display="flex" justifyContent="center" padding="1rem">
				{section}
			</Box>
		)
	);
}

export default LoadingSection;
