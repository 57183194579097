import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TEmployeesLog } from 'Models/employees/EmployeesLog';

export type TEmployeeTableData = Omit<TEmployeesLog, 'teamMember'>;

const GET_EMPLOYEES_TABLE_DATA: TypedDocumentNode<
	{
		getEmployees: {
			totalPages: number;
			totalElements: number;
			employees: TEmployeeTableData[];
		};
	},
	{
		locationId: string;
		pageInput: TPageInput;
		hasPagination: boolean;
		filterBy: {
			dateFrom?: string;
			dateTo?: string;
			employeeName?: string;
		};
	}
> = gql`
	query getEmployeesTableData(
		$locationId: ID!
		$filterBy: EmployeesFilterInput
		$pageInput: PageInput!
	) {
		getEmployees(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			employees {
				id
				name
				email
				workHours
			}
		}
	}
`;

export default GET_EMPLOYEES_TABLE_DATA;
