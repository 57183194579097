import React, { ReactElement, ReactEventHandler } from 'react';

import MuiDialog, { DialogProps } from '@mui/material/Dialog';

export type TDialogModal = {
	open: boolean;
	toggleOpen?: ReactEventHandler;
} & DialogProps;

const DialogModal = (props: TDialogModal): ReactElement => {
	const { children, open, toggleOpen, ...MUIProps } = props;

	return (
		<MuiDialog open={open} onClose={toggleOpen} {...MUIProps}>
			{children}
		</MuiDialog>
	);
};

export default DialogModal;
