import isBoolean from 'vet/booleans/isBoolean';
import isOneOf from 'vet/isOneOf';
import isShape from 'vet/objects/isShape';
import ValidatorType from 'vet/types/ValidatorType';

export const SafetyChecklistItemNames = {
	PRODUCT_LABELS_READ: 'PRODUCT_LABELS_READ',
	PERSONAL_PROTECTION_EQUIPMENT_USED: 'PERSONAL_PROTECTION_EQUIPMENT_USED',
} as const;

const _isSafetyChecklistItemName = isOneOf<
	['PRODUCT_LABELS_READ', 'PERSONAL_PROTECTION_EQUIPMENT_USED']
>(
	SafetyChecklistItemNames.PRODUCT_LABELS_READ,
	SafetyChecklistItemNames.PERSONAL_PROTECTION_EQUIPMENT_USED
);
const isSafetyChecklistItemName: typeof _isSafetyChecklistItemName =
	_isSafetyChecklistItemName;
export type TSafetyChecklistItemName = ValidatorType<
	typeof isSafetyChecklistItemName
>;

const _isSafetyChecklistItem = isShape({
	name: _isSafetyChecklistItemName,
	checked: isBoolean,
});

const isSafetyChecklistItem: typeof _isSafetyChecklistItem =
	_isSafetyChecklistItem;
export type TSafetyChecklistItem = ValidatorType<typeof isSafetyChecklistItem>;

export default isSafetyChecklistItem;
