import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import TextField from 'UI/inputs/TextField';

import useSpanishPartner from 'Hooks/useSpanishPartner';
import numberToDisplayString from 'Utils/numberToDisplayString';

import { addTreatmentPageState } from '../../../../../state';
import { TAddTreatmentsModalInput } from '../../../../../types';
import ManufacturerName from './ManufacturerName';
import ProductFertilizerMaterial from './ProductFertilizerMaterial';

function SpanishCustomFields() {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const { isSpanishPartner } = useSpanishPartner();
	const { input } = state;

	if (!input.selectedGovernmentProduct && isSpanishPartner) {
		const onChangeTraderRegistrationNumber = (value: string) => {
			addTreatmentPageState({
				...state,
				input: {
					...input,
					product: {
						...input.product,
						productSpec: {
							...input.product.productSpec,
							traderRegistrationNumber: value,
						},
					},
				},
			} as TAddTreatmentsModalInput);
		};

		const onChangeOrganicCarbon = (value: string) => {
			addTreatmentPageState({
				...state,
				input: {
					...input,
					product: {
						...input.product,
						productSpec: {
							...input.product.productSpec,
							organicCarbon: Number(value),
						},
					},
				},
			} as TAddTreatmentsModalInput);
		};

		return (
			<>
				<TextField
					value={input.product.productSpec?.traderRegistrationNumber || ''}
					label={t(
						'treatmentLibrary.addTreatmentModal.traderRegistrationNumber'
					)}
					onChange={onChangeTraderRegistrationNumber}
					type="string"
					margin="dense"
					sx={{
						marginTop: '1rem',
					}}
					required
				/>
				<ProductFertilizerMaterial />
				<TextField
					value={numberToDisplayString(
						input.product.productSpec?.organicCarbon
					)}
					label={t('treatmentLibrary.addTreatmentModal.organicCarbon')}
					onChange={onChangeOrganicCarbon}
					type="number"
					margin="dense"
					sx={{
						marginTop: '1rem',
					}}
					InputProps={{ inputProps: { min: 0 } }}
					required
				/>
				<ManufacturerName />
			</>
		);
	}

	return null;
}

export default SpanishCustomFields;
