import { gql, TypedDocumentNode } from '@apollo/client';

import { TResult } from '../types';
import { TPartnershipNotificationInput } from '../types';

const GET_PARTNER_RECENT_EVENTS: TypedDocumentNode<
	{
		getPartnershipNotifications: TResult;
	},
	TPartnershipNotificationInput
> = gql`
	query getPartnershipNotifications(
		$filterBy: PartnershipNotificationFilterInput
		$pageInput: PageInput!
	) {
		getPartnershipNotifications(filterBy: $filterBy, pageInput: $pageInput) {
			totalElements
			notifications {
				id
				type
				createdAt
				createdBy {
					id
					firstName
					lastName
					profileImage {
						id
						path
						filename
						mimetype
						createdAt
					}
				}
				entity {
					... on Job {
						id
						jobFields: fields {
							id
							name
						}
					}
					... on Note {
						id
					}
					... on FieldNote {
						id
						fieldNoteField: field {
							id
							name
						}
					}
					... on FieldNoteComment {
						id
					}
					... on Harvest {
						id
						harvestFields: fields {
							id
							name
						}
					}
					... on Timesheet {
						id
						timesheetFields: fields {
							id
							name
						}
					}
				}
				parentEntity {
					... on Job {
						id
						jobFields: fields {
							id
							name
						}
					}
					... on FieldNote {
						id
						fieldNoteField: field {
							id
							name
						}
					}
				}
			}
		}
	}
`;

export default GET_PARTNER_RECENT_EVENTS;
