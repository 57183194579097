import { GraphQLErrors } from '@apollo/client/errors';

import { TFailedTask } from '../../utils/checkRequestStatus';
import { ERROR_STATUSES } from './errorTypes';

type TGraphQLErrorStatus = keyof typeof ERROR_STATUSES;

type TResultWithErrors = Omit<TFailedTask, 'error'> & {
	errors: GraphQLErrors;
};

export const hasGraphQLErrors = (
	result: unknown
): result is TResultWithErrors => {
	return (
		typeof result === 'object' &&
		result !== null &&
		'errors' in result &&
		Array.isArray(result['errors'])
	);
};

export const hasNestedGraphQLErrors = (
	result: unknown
): result is TFailedTask & {
	error: TFailedTask['error'] & { graphQLErrors: GraphQLErrors };
} => {
	return (
		typeof result === 'object' &&
		result !== null &&
		'error' in result &&
		typeof result['error'] === 'object' &&
		result['error'] !== null &&
		'graphQLErrors' in result['error']
	);
};

export const getGraphQLErrorMessage = (
	errors: GraphQLErrors | readonly Error[]
): string => errors.map((error) => error.message).join('\n');

export const getGraphQLErrorStatuses = (
	errors: GraphQLErrors
): TGraphQLErrorStatus[] => {
	return errors
		.map((error) => error.extensions?.status)
		.filter((status): status is TGraphQLErrorStatus => status !== undefined);
};

/**
 * Function checks if response includes graphQL errors and throws an error with merged err message
 * this helps to map 'errors' to more common 'error'
 * @param {unknown} [result] - result of a graphQL request
 * @param {string?} [requestId] - string value used for identifying error source
 */
export const catchGraphQlErrors = (result: unknown, requestId?: string) => {
	if (hasGraphQLErrors(result)) {
		const type = requestId ? `(${requestId}) ` : '';
		throw new Error(`${type}${getGraphQLErrorMessage(result.errors)}`);
	}
};

export default { hasGraphQLErrors, getGraphQLErrorMessage };
