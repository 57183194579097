import React, { ReactElement, Fragment } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { getDistanceBetweenRows } from 'Models/fields/getDistanceBetweenRows';
import { getDistanceBetweenTreesInRows } from 'Models/fields/getDistanceBetweenTreeInRows';
import { getFieldName } from 'Models/fields/getFieldName';
import { getFieldNumberOfPlants } from 'Models/fields/getFieldNumberOfPlants';
import { getFieldPlantHeight } from 'Models/fields/getFieldPlantHeight';
import { getFieldProductiveArea } from 'Models/fields/getFieldProductiveArea';
import { getFieldRootstock } from 'Models/fields/getFieldRootstock';
import { getFieldTreeSupplier } from 'Models/fields/getFieldTreeSupplier';
import { TFieldInput } from 'Models/fields/isFieldInput';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import { keys } from '../../../../../../../../utils/objectTyped';

type TProps = {
	field: TFieldInput;
	open: boolean;
	toggleOpen: () => void;
};

const FieldDetailsModal = (props: TProps): ReactElement => {
	const { field, open, toggleOpen } = props;
	const { t } = useTranslation();

	const fieldInformation = {
		fieldName: getFieldName(field),
		fieldType: t(`fieldType.${field.fieldType}`),
		distanceBetweenPlantsInRows: getDistanceBetweenTreesInRows(field),
		distanceBetweenRows: getDistanceBetweenRows(field),
		supplier: getFieldTreeSupplier(field),
		rootstock: getFieldRootstock(field),
		numberOfPlants: getFieldNumberOfPlants(field),
		plantHeight: getFieldPlantHeight(field),
		productiveArea: getFieldProductiveArea(field),
	};

	const fieldVarieties = field.varieties.map((variety, i) => (
		<Fragment key={i}>
			<Grid item>
				<Text>{t('labels.name')}:</Text>
				<Text>{t('labels.percentage')}:</Text>
			</Grid>
			<Grid item>
				<Text>{variety.name}</Text>
				<Text>{variety.percentage ? `${variety.percentage}` : '-'}</Text>
			</Grid>
			<Box marginBottom={2} />
		</Fragment>
	));

	const fieldDetails = (
		<>
			<Grid container spacing={1}>
				<Grid item>
					{keys<typeof fieldInformation>(fieldInformation).map((name, i) => (
						<Text key={i}>{t(`labels.${name}`)}: </Text>
					))}
				</Grid>
				<Grid item>
					{Object.values(fieldInformation).map((value, i) => (
						<Text key={i}>{value}</Text>
					))}
				</Grid>
			</Grid>
			<Box paddingTop={2} paddingBottom={2}>
				<Divider variant="fullWidth" />
			</Box>
		</>
	);

	const varietiesDetails = (
		<>
			<Text variant="label" component="p" gutterBottom>
				{t('labels.variety_plural')}
			</Text>
			<Grid container spacing={1}>
				{fieldVarieties}
			</Grid>
		</>
	);

	return (
		<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
			<Modal.DialogTitle
				title={t('fieldDetailsPage.title')}
				closeIconHandler={toggleOpen}
			/>
			<Modal.DialogContent>
				{fieldDetails}
				{varietiesDetails}
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Button variant={'outlined'} onClick={toggleOpen}>
					{t('buttons.close')}
				</Button>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default FieldDetailsModal;
