import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import TextField from 'UI/inputs/TextField';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../state';
import { TNewProductInput } from '../../../../../types';

const ProductName = () => {
	const { t } = useTranslation();
	const { input } = useReactiveVar(addTreatmentPageState);
	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);

	const newName = 'name' in input.product ? input.product.name : '';

	const handleNameChange = (name: string) => {
		addTreatmentPageState({
			...state,
			input: {
				...state.input,
				product: {
					...state.input.product,
					name: name,
				},
			} as TNewProductInput,
		});
	};

	return (
		<ModalSection title={t('labels.name')} noDivider>
			<TextField
				value={selectedProduct?.name || newName}
				type="string"
				margin="dense"
				onChange={handleNameChange}
				disabled={input.selectedGovernmentProduct}
			/>
		</ModalSection>
	);
};

export default ProductName;
