import React, { ReactElement } from 'react';

import MUIList, { ListProps } from '@mui/material/List';

import { FarmableTextColors } from '../../theme/Colors';

export type TList = ListProps & {
	isMenuVariant?: boolean;
};

const List = (props: TList): ReactElement => {
	const { children, isMenuVariant, ...MUIProps } = props;

	if (isMenuVariant) {
		MUIProps.sx = {
			'& .MuiListSubheader-root': {
				color: FarmableTextColors.SECONDARY,
				textTransform: 'uppercase',
				fontSize: '0.688rem',
				letterSpacing: '0.05rem',
				marginLeft: '0.5rem',
				lineHeight: '2.5rem',
				position: 'inherit',
			},
			'& .MuiButtonBase-root': {
				padding: '0.25rem 1.5rem',
				borderRadius: '0 1.375rem 1.375rem 0',
			},
			'& .MuiListItemText-root': {
				paddingLeft: '0.5rem',
			},
			...MUIProps.sx,
		};
	}

	return <MUIList {...MUIProps}>{children}</MUIList>;
};

export default List;
