import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { FieldImportsStatus } from '../../models';
import { fieldImportsPageState } from '../../state';
import AddFieldsFailure from './components/AddFieldsFailure';
import AddFieldsForm from './components/AddFieldsForm';
import AddFieldsLoading from './components/AddFieldsLoading';
import AddFieldsSuccess from './components/AddFieldsSuccess';
import CreateFieldsFailure from './components/CreateFieldsFailure';
import CreateFieldsLoading from './components/CreateFieldsLoading';
import CreateFieldsSuccess from './components/CreateFieldsSuccess';
import ImportedFieldsPreview from './components/ImportedFieldsPreview';
import ReviewFields from './components/ReviewFields';

const FieldImportsContent = () => {
	const state = useReactiveVar(fieldImportsPageState);
	const status = state.status;

	switch (status) {
		case FieldImportsStatus.ADD_FIELDS_FORM:
			return <AddFieldsForm />;
		case FieldImportsStatus.ADD_FIELDS_SUCCESS:
			return <AddFieldsSuccess fieldsInput={state.fieldsInput} />;
		case FieldImportsStatus.ADD_FIELDS_FAILURE:
			return <AddFieldsFailure error={state.error} />;
		case FieldImportsStatus.ADD_FIELDS_LOADING:
			return <AddFieldsLoading />;
		case FieldImportsStatus.EDIT_FIELDS:
			return <ImportedFieldsPreview fieldsInput={state.fieldsInput} />;
		case FieldImportsStatus.REVIEW_FIELDS:
			return <ReviewFields fieldsInput={state.fieldsInput} />;
		case FieldImportsStatus.CREATE_FIELDS_SUCCESS:
			return <CreateFieldsSuccess fieldsAmount={state.fieldsAmount} />;
		case FieldImportsStatus.CREATE_FIELDS_FAILURE:
			return (
				<CreateFieldsFailure
					fieldsInput={state.fieldsInput}
					error={state.error}
				/>
			);
		case FieldImportsStatus.CREATE_FIELDS_LOADING:
			return <CreateFieldsLoading fieldsAmount={state.fieldsAmount} />;
		default:
			return null;
	}
};

export default FieldImportsContent;
