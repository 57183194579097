import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ShareIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				xmlns="http://www.w3.org/2000/svg"
				d="M19 11a1 1 0 0 1 1 1v7a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-7a1 1 0 0 1 2 0v7a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-7a1 1 0 0 1 1-1zm-7-8 .033.002h.026l.026.003.032.002.031.006.028.003.024.006.03.004.035.012.027.005.019.008.026.007.033.015.034.012.018.01.018.007.043.024.028.014.009.007.016.009.058.042.019.012.003.004.01.006.081.073 4 4a1 1 0 1 1-1.414 1.414L13 6.415V16a1 1 0 0 1-.883.993L12 17a1 1 0 0 1-1-1V6.414L8.707 8.707a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414l4-4 .082-.073.008-.006.022-.016.059-.042.015-.009.01-.007.027-.014.044-.024.017-.007.02-.01.032-.012.034-.015.025-.007.02-.008.026-.005.037-.012.028-.004.025-.006.027-.003.032-.006.03-.002.028-.003h.026L12 3z"
			/>
		</SvgIcon>
	);
}

export default ShareIcon;
