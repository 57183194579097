import { z } from 'zod';

import { TAllUnits, isUnit } from '../units';

export const ProductIngredientInputSchema = z.object({
	name: z.string().min(1),
	active: z.boolean(),
	amount: z.number().positive(),
	unit: z.string().refine(isUnit),
	organicAmount: z.number().optional(),
	organicUnit: z.string().refine(isUnit).optional(),
	registrationNumber: z.string().optional(),
	groupName: z.string().optional(),
});

export type TProductIngredientInput = {
	id?: string;
	name: string;
	active: boolean;
	amount: number;
	unit: TAllUnits;
	organicAmount?: number | null;
	organicUnit?: TAllUnits | null;
	registrationNumber?: string | null;
	groupName?: string | null;
};

export const isAmountInput = (amount: unknown) =>
	ProductIngredientInputSchema.shape.amount.safeParse(amount).success;

export type TSanitizedProductIngredientInput = Omit<
	TProductIngredientInput,
	'id'
>;
