import isArrayOf from 'vet/arrays/isArrayOf';
import isOneOf from 'vet/isOneOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import isString from 'vet/strings/isString';
import { ValidatorType } from 'vet/types';

const _isProfitChartPoint = isShape({
	x: isNumber,
	y: isNumber,
});

export type TProfitChartPoint = ValidatorType<typeof _isProfitChartPoint>;

const _isProfitChartSeriesOptions = isShape({
	custom: isShape({
		type: isOneOf<['revenue', 'cost', 'profit']>('revenue', 'cost', 'profit'),
		currencyCode: isString,
		rawData: isArrayOf(_isProfitChartPoint),
	}),
});

const _isProfitChartSeries = isShape({
	options: _isProfitChartSeriesOptions,
});

export type TProfitChartSeries = ValidatorType<typeof _isProfitChartSeries>;

export const isProfitChartSeries: typeof _isProfitChartSeries =
	_isProfitChartSeries;
