import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Roles, { TRoles } from 'Models/permissions/Roles';
import Divider from 'UI/display/Divider';
import Menu from 'UI/navigation/Menu';

import useProfile from 'Hooks/useProfile';

type TAdminDropdown = {
	memberItemRole: TRoles;
	memberItemId: string;
	onEditInfoClick: () => void;
	onViewProfileClick?: () => void;
	onDeactivateAccountClick?: () => void;
	onChangeToAdminAccessClick: () => void;
	onChangeFarmsAccessClick: () => void;
};

const AdminDropdownView = (props: TAdminDropdown) => {
	const { t } = useTranslation();
	const {
		memberItemRole,
		memberItemId,
		onEditInfoClick,
		onViewProfileClick,
		onDeactivateAccountClick,
		onChangeToAdminAccessClick,
		onChangeFarmsAccessClick,
	} = props;

	const { profile } = useProfile();
	const isYou = profile?.id === memberItemId;

	if (isYou) {
		return (
			<Menu.Item onClick={onEditInfoClick}>
				{t('managePartnerMembers.editInfo')}
			</Menu.Item>
		);
	}

	if (memberItemRole === Roles.PA_MEMBER) {
		return (
			<div>
				<Menu.Item onClick={onChangeFarmsAccessClick}>
					{t('managePartnerMembers.changeAccess')}
				</Menu.Item>
				<Menu.Item onClick={onChangeToAdminAccessClick}>
					{t('managePartnerMembers.changeToAdmin')}
				</Menu.Item>
				<Divider sx={{ marginY: '0.5rem' }} />
				<Menu.Item
					className="has-text-danger"
					onClick={onDeactivateAccountClick}
				>
					{t('managePartnerMembers.deactivateAccount')}
				</Menu.Item>
			</div>
		);
	}

	return (
		<Menu.Item onClick={onViewProfileClick}>
			{t('managePartnerMembers.viewProfile')}
		</Menu.Item>
	);
};

export default AdminDropdownView;
