import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJob } from 'Models/jobs/isJob';
import { TJobTreatment } from 'Models/jobs/isJobTreatment';
import { TPartnerJob } from 'Models/jobs/isPartnerJob';
import jobDateCompletedString from 'Models/jobs/jobDateCompletedString';
import jobEarliestHarvestPermitted from 'Models/jobs/jobEarliestHarvestPermitted';
import jobEarliestReEntryToField from 'Models/jobs/jobEarliestReEntryInField';
import jobStatusColor from 'Models/jobs/jobStatusColor';
import jobTreatment from 'Models/jobs/jobTreatment';
import Chip from 'UI/display/Chip';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';
import { TFunction } from 'i18next';

import { keys } from 'Utils/objectTyped';

import DetailModalSection from '../../../../../components/ModalSection';

type InformationProps = {
	job: Pick<
		TJob,
		'jobStatus' | 'jobType' | 'customJobType' | 'completedDate'
	> & {
		location?: TPartnerJob['location'] | undefined;
		treatments: Pick<TJobTreatment, 'amount' | 'unit'>[];
	};
};

type TInformationFields = {
	status: ReactElement;
	treatment: string;
	date: string;
	earliestHarvestPermitted: string;
	earliestReEntryToField: string;
};

type TInformationFieldsWithLocation = TInformationFields & {
	location: string;
};

const getSectionFields = function (
	job: InformationProps['job'],
	t: TFunction
): TInformationFields | TInformationFieldsWithLocation {
	const fields = {
		status: (
			<Chip
				chipColor={jobStatusColor(job)}
				label={t(`common.jobs.statuses.${job.jobStatus}`).toUpperCase()}
				dense
			/>
		),
		treatment: jobTreatment(job, t),
		date: jobDateCompletedString(job),
		earliestHarvestPermitted: jobEarliestHarvestPermitted(job),
		earliestReEntryToField: jobEarliestReEntryToField(job),
	};

	if ('location' in job) {
		return {
			location: job.location?.name || '-',
			...fields,
		};
	}

	return fields;
};

const Information = (props: InformationProps) => {
	const { job } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.jobLogsPage.detailsModal.';
	const fields = getSectionFields(job, t);

	const details = keys<typeof fields>(fields).map((name) => (
		<Text key={name}>{t(`${prefix}props.${name}`)}: </Text>
	));

	return (
		<DetailModalSection title={t(`${prefix}sections.information`)}>
			<Grid container spacing={1}>
				<Grid item>{details}</Grid>
				<Grid item>
					{Object.values(fields).map((value, i) => (
						<Text key={i}>{value}</Text>
					))}
				</Grid>
			</Grid>
		</DetailModalSection>
	);
};

export default Information;
