import { TTimesheet } from './isTimesheet';

function timesheetEmployeeId<TGenericTimesheet>(
	timesheet: TGenericTimesheet &
		Pick<TTimesheet, 'teamMember'> & {
			teamLead: Omit<TTimesheet['teamLead'], 'profileImage'>;
		}
): string {
	const { teamMember, teamLead } = timesheet;

	return teamMember?.customId || teamMember?.id || teamLead.id || '-';
}

export default timesheetEmployeeId;
