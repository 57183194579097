import { THarvest } from './types/Harvest';

function harvestCrewString(harvest: {
	author: Pick<THarvest['author'], 'firstName' | 'lastName'>;
}): string {
	const author = harvest.author;
	const firstName = author.firstName ? author.firstName : null;
	const lastName = author.lastName ? author.lastName : null;

	if (firstName && lastName) {
		return firstName + ' ' + lastName;
	} else if (firstName) {
		return firstName;
	} else if (lastName) {
		return lastName;
	}
	return '-';
}

export default harvestCrewString;
