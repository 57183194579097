import { makeVar } from '@apollo/client';
import { Loader } from '@googlemaps/js-api-loader';
import { LatLngLiteral } from 'leaflet';

export const googleAPIVar = makeVar<typeof google | null>(null);

const apiKey = process.env.GOOGLE_MAPS_API_KEY;

export function initGoogleAPILoader() {
	if (!googleAPIVar() && apiKey) {
		void new Loader({ apiKey }).load().then((google) => {
			googleAPIVar(google);
		});
	}
}

export function fetchMaxZoom(
	centerPoint: LatLngLiteral,
	callback: (response: google.maps.MaxZoomResult) => unknown
) {
	const maxZoomService = googleAPIVar()
		? new google.maps.MaxZoomService()
		: null;

	void maxZoomService?.getMaxZoomAtLatLng(centerPoint)?.then(callback);
}

export type TFetchMaxZoom = typeof fetchMaxZoom;
