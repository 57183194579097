import React, { ReactElement } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { TrackEvents } from 'Features/analytics';
import { analyticsTrack } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTeamInput, isTeamInput } from 'Models/teams/isTeamInput';

import useLocationID from 'Hooks/useLocationID';
import useProfile from 'Hooks/useProfile';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import ErrorModal from '../../../../components/ErrorBoundary/ErrorModal';
import CREATE_TEAM from '../api/createTeam';
import GET_TEAMS from '../api/getTeams';
import { teamInputVar } from '../state';
import ManageTeamTemplate from './ManageTeamTemplate';

type TProps = {
	open: boolean;
	toggleOpen: () => void;
};

const TEAM_LEAD_COUNT = 1;

const CreateTeamModal = (props: TProps): ReactElement => {
	const { toggleOpen } = props;
	const teamInput = useReactiveVar(teamInputVar);
	const locationId = useLocationID();
	const { profile } = useProfile();
	const { t } = useTranslation();

	const [createTeam, createTeamTask] = useMutation(CREATE_TEAM, {
		onCompleted: handleCompleted,
		update(cache, { data }) {
			const query = {
				query: GET_TEAMS,
				variables: { locationId },
			};
			const newTeam = data?.createTeam;
			const existingTeams = cache.readQuery(query)?.getTeams;

			if (existingTeams && newTeam) {
				cache.writeQuery({
					...query,
					data: {
						getTeams: [...existingTeams, newTeam],
					},
				});
			}
		},
	});

	useSnackbarNotifications({
		mutationResult: createTeamTask,
		messageFailure: t('errors.createTeamError'),
		messageSuccess: t('info.createTeamSuccess'),
	});

	function handleCompleted() {
		toggleOpen();
		teamInputVar({});
	}

	const saveTeam = (input: TTeamInput) => {
		if (profile && locationId) {
			analyticsTrack(TrackEvents.TeamCreated, {
				userId: profile.id,
				groupId: locationId,
				teamSize: input.teamMemberInput
					? (input.teamMemberInput.length + TEAM_LEAD_COUNT).toString()
					: TEAM_LEAD_COUNT.toString(),
			});
		}

		if (isTeamInput(teamInput)) {
			void createTeam({ variables: { locationId, teamInput } });
		}
	};

	return (
		<ErrorBoundary fallback={ErrorModal}>
			<ManageTeamTemplate
				modalTitle={t('teamsPage.createTeamModal.title')}
				isLoading={createTeamTask.loading}
				saveTeam={saveTeam}
				{...props}
			/>
		</ErrorBoundary>
	);
};

export default CreateTeamModal;
