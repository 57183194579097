import React, { ReactElement } from 'react';

import { TField } from 'Models/fields/isField';
import { polygon } from 'UI/maps/Polygon';
import WarningMarker from 'UI/maps/WarningMarker';
import { isAfter } from 'date-fns';
import _flatten from 'lodash/flatten';

type TProps = {
	field: Pick<TField, 'geometry' | 'reentryAllowedDate' | 'harvestAllowedDate'>;
};

function SafeSprayingMarker(props: TProps): ReactElement | null {
	const { reentryAllowedDate, harvestAllowedDate } = props.field;
	const canEnter = reentryAllowedDate
		? isAfter(new Date(), Date.parse(reentryAllowedDate))
		: true;
	const canHarvest = harvestAllowedDate
		? isAfter(new Date(), Date.parse(harvestAllowedDate))
		: true;

	const positions = _flatten(props.field.geometry || [[]]).map<
		[number, number]
	>((pos) => [pos.y, pos.x]);

	const center = polygon(positions).getBounds().getCenter();
	const parsedReEntryAllowedDate = reentryAllowedDate
		? new Date(reentryAllowedDate)
		: null;
	const parsedHarvestAllowedDate = harvestAllowedDate
		? new Date(harvestAllowedDate)
		: null;

	const marker =
		!canEnter || !canHarvest ? (
			<WarningMarker
				position={center}
				reEntryAllowedDate={parsedReEntryAllowedDate}
				harvestAllowedDate={parsedHarvestAllowedDate}
			/>
		) : null;

	return marker;
}

export default SafeSprayingMarker;
