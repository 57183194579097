import React from 'react';

import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';

import useLocationSubscriptions from 'Hooks/useLocationSubscriptions';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import LoadingContent from '../../common/components/LoadingContent';
import NoSubscription from '../../common/components/NoSubscription';
import AddTeamCard from './AddTeamCard';
import TeamsBoard from './TeamsBoard';

const TeamsPageBody = () => {
	const { loading } = useLocationSubscriptions();
	const hasTimesheetsSubscription = Boolean(
		useSubscriptionsFeatureFlag(SubscriptionType.TIMESHEET)
	);

	if (loading) {
		return <LoadingContent />;
	}

	if (!hasTimesheetsSubscription) {
		return <NoSubscription />;
	}

	return (
		<>
			<AddTeamCard />
			<TeamsBoard />
		</>
	);
};

export default TeamsPageBody;
