import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import { GRAM_PER_SQUARE_METER } from 'Models/units';

import { addTreatmentPageState, treatmentInputState } from '../../../../state';
import AdditionalDetails from './components/AdditionalDetails';
import ApplicationRequirements from './components/ApplicationRequirements';
import Causes from './components/Causes';
import ApplicationRate from './components/Details';
import RelevantDevelopmentStage from './components/RelevantDevelopmentStage';
import WaterMixRate from './components/WaterMixRate';

function TreatmentDetails() {
	const state = useReactiveVar(addTreatmentPageState);
	const treatmentInput = useReactiveVar(treatmentInputState);
	const treatments = state.input.product.treatments || [];
	const createdTreatment = treatments.find(
		(treatment) => treatment.category === state.editedCategoryName
	);

	useEffect(() => {
		if (state.status !== 'TREATMENT_DETAILS') {
			treatmentInputState(undefined);
		} else {
			treatmentInputState(
				treatmentInput ||
					createdTreatment ||
					({
						category: state.editedCategoryName,
						unit: GRAM_PER_SQUARE_METER,
						amount: 0,
					} as TProductTreatmentInput)
			);
		}
	}, [
		state.status,
		state.editedCategoryName,
		createdTreatment,
		treatmentInput,
	]);

	return (
		<>
			<ApplicationRate />
			<Causes />
			<RelevantDevelopmentStage />
			<ApplicationRequirements />
			<AdditionalDetails />
			<WaterMixRate />
		</>
	);
}

export default TreatmentDetails;
