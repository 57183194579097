import { TLocations } from '../locations/isLocations';
import convertAreaUnit from '../units/convertAreaUnit';
import { TField } from './isField';

type TFieldWithArea = Pick<TField, 'areaSize'> & {
	location?: Pick<TLocations, 'areaUnit'>;
};

export default function fieldsArea(field: TFieldWithArea): number {
	const location = field.location;
	return field.areaSize && location?.areaUnit
		? convertAreaUnit(field.areaSize, location.areaUnit)
		: 0;
}
