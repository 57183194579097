import React from 'react';

import { useQuery } from '@apollo/client';
import getDateRepresentationFromString from 'Models/dates/getDateRepresentationFromString';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../common/components/Pagination';
import { GET_PARTNER_SALES_TABLE_DATA } from './PartnerSalesLogs.queries';

export const PartnerSalesLogsPagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const { data, loading } = useQuery(GET_PARTNER_SALES_TABLE_DATA, {
		variables: {
			filterBy: {
				saleFilters: {
					dateFrom: getDateRepresentationFromString(
						searchParams.filters.completedDateFrom!
					),
					dateTo: getDateRepresentationFromString(
						searchParams.filters.completedDateTo!
					),
					customerId: searchParams.filters.customerId!,
					varieties: searchParams.filters.varieties!,
				},
				locationIds: searchParams.filters.locations!,
			},
			pageInput: searchParams.pageInput,
		},
	});

	return (
		<TablePagination
			count={data?.getPartnerSales.totalElements || 0}
			loading={loading}
		/>
	);
};
