// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1VRu7i-ofkaxIn8mSknh7f {\n  width: 100%;\n  padding: 1.5rem;\n  max-width: 82rem;\n  margin: 0 auto;\n}\n\n._3pbLMJmu76LNGhXM75WcnX {\n  display: flex;\n  flex-direction: column;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  height: 100vh;\n}\n\n", ""]);
// Exports
exports.locals = {
	"logsContainer": "_1VRu7i-ofkaxIn8mSknh7f",
	"logsScroller": "_3pbLMJmu76LNGhXM75WcnX"
};
module.exports = exports;
