import { gql, TypedDocumentNode } from '@apollo/client';

import { TEditPartnerPlannerInput } from '../types';

const EDIT_PLANNER_JOB_MUTATION: TypedDocumentNode<
	{
		editPartnerPlannerJob: { id: string };
	},
	TEditPartnerPlannerInput
> = gql`
	mutation editPartnerPlannerJob(
		$plannerJobId: ID!
		$input: PartnerPlannerJobInput!
	) {
		editPartnerPlannerJob(plannerJobId: $plannerJobId, input: $input) {
			id
		}
	}
`;

export default EDIT_PLANNER_JOB_MUTATION;
