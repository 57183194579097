import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TProductTreatment } from 'Models/products/ProductTreatment';

import { treatmentLibraryTreatmentFragmentFields } from '../../../../../../../../api';

export interface GetGovernmentProductTreatmentsVariables {
	pageInput?: TPageInput;
	filterBy: {
		country: string;
		category?: string;
		productName?: string;
		productRegistrationNumber?: string | null;
	};
}

export interface GetGovernmentProductTreatmentsResponse {
	getGovernmentTreatments: {
		totalPages: number;
		totalElements: number;
		treatments: TProductTreatment[];
	};
}

export const GET_GOVERNMENT_PRODUCT_TREATMENTS: TypedDocumentNode<
	GetGovernmentProductTreatmentsResponse,
	GetGovernmentProductTreatmentsVariables
> = gql`
	query GetGovernmentTreatments(
		$pageInput: PageInput
		$filterBy: GovernmentTreatmentFilterInput!
	) {
		getGovernmentTreatments(pageInput: $pageInput, filterBy: $filterBy) {
			totalPages
			totalElements
			treatments {
				${treatmentLibraryTreatmentFragmentFields}
			}
		}
	}
`;
