import { TPlannerJobInput } from 'Models/planner/PartnerPlannerJobInput';

import removeTypename from 'Utils/removeTypename';

// we need to parse the input data for mutation - remove typename & productName
export default function parseEditPlannerJobInput(jobInput: TPlannerJobInput) {
	return {
		...jobInput,
		variety: removeTypename(jobInput.variety),
		jobDetails: {
			...jobInput.jobDetails,
			treatments: jobInput.jobDetails.treatments.map((treatment) => ({
				amount: treatment.amount,
				unit: treatment.unit,
				originalTreatmentId: treatment.originalTreatmentId,
			})),
		},
	};
}
