import { HarvestCountingUnit } from '../units';
import { toDisplayString } from '../units/transpileUnits';
import { THarvest } from './types/Harvest';

function harvestWeightUnit<TGenericHarvest>(
	harvest: TGenericHarvest &
		Pick<THarvest, 'weight' | 'unit' | 'countingUnit' | 'volume' | 'boxSize'>
): string {
	if (!harvest.unit) {
		return '-';
	}

	const unit = toDisplayString(harvest.unit);

	if (
		harvest.countingUnit === HarvestCountingUnit.BOXES &&
		harvest.volume &&
		harvest.boxSize
	) {
		return `${unit}`;
	} else if (
		harvest.countingUnit === HarvestCountingUnit.WEIGHT &&
		harvest.weight &&
		harvest.unit
	) {
		return `${toDisplayString(harvest.unit)}`;
	}

	return '-';
}

export default harvestWeightUnit;
