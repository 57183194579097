import toCurrencyDisplay from 'Features/localization/utils/toCurrencyDisplay';
import classicFormat from 'Models/dates/classicFormat';
import { TProductInventory } from 'Models/inventory/ProductsInventory';
import roundToTwoDecimal from 'Models/units/roundToTwoDecimal';
import { toDisplayString } from 'Models/units/transpileUnits';

function getCurrentInvLogsRowData(
	product: TProductInventory,
	locale: Locale['code']
) {
	return Object.values({
		productName: product.product.name,
		purchaseDate: product.lastPurchase
			? classicFormat(new Date(product.lastPurchase))
			: '-',
		amount: roundToTwoDecimal(product.stockAmount),
		unit: toDisplayString(product.stockUnit),
		totalValue: toCurrencyDisplay(product.totalValue, locale, product.currency),
	});
}

export default getCurrentInvLogsRowData;
