import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { SelectTreatmentsSourceModal } from '../../../SelectTreatmentsSourceModal';
import { addTreatmentPageState } from '../../state';
import { AddTreatmentModalStatus } from '../../types';
import CropCategories from './components/CropCategories';
import { ProductDetails } from './components/ProductDetails';
import SelectCropCategory from './components/SelectCropCategory';
import { SelectGovTreatments } from './components/SelectGovTretments';
import TreatmentDetails from './components/TreatmentDetails';

function ModalContent() {
	const { status } = useReactiveVar(addTreatmentPageState);

	switch (status) {
		case AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE:
			return <SelectTreatmentsSourceModal />;
		case AddTreatmentModalStatus.SELECT_GOV_TREATMENTS:
			return <SelectGovTreatments />;
		case AddTreatmentModalStatus.SET_PRODUCT_DETAILS:
			return <ProductDetails />;
		case AddTreatmentModalStatus.SET_CROP_CATEGORIES:
			return <CropCategories />;
		case AddTreatmentModalStatus.SELECT_CROP_CATEGORY:
			return <SelectCropCategory />;
		case AddTreatmentModalStatus.TREATMENT_DETAILS:
		case AddTreatmentModalStatus.GOV_TREATMENT_DETAILS:
			return <TreatmentDetails />;
		default:
			return null;
	}
}

export default ModalContent;
