import { TypedDocumentNode, gql } from '@apollo/client';
import { PartnerRoles } from 'Models/permissions/Roles';

const EDIT_PARTNERSHIP_USER_ROLE: TypedDocumentNode<
	{
		editPartnershipUserRole: boolean;
	},
	{
		partnershipUserId: string;
		partnershipRole: PartnerRoles;
	}
> = gql`
	mutation editPartnershipUserRole(
		$partnershipUserId: ID!
		$partnershipRole: PartnershipRole!
	) {
		editPartnershipUserRole(
			partnershipUserId: $partnershipUserId
			partnershipRole: $partnershipRole
		)
	}
`;

export default EDIT_PARTNERSHIP_USER_ROLE;
