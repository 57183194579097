import React, { ReactElement } from 'react';

import MuiDialogContent, {
	DialogContentProps,
} from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';

export type TDialogContent = {
	noFixedWidth?: boolean;
	noPadding?: boolean;
} & DialogContentProps;

const DialogContent = (props: TDialogContent): ReactElement => {
	const { children, noPadding, noFixedWidth, ...MUIProps } = props;
	const useContentStyles = makeStyles({
		root: {
			// Set the fixed width of modal's content, as all modals from design are having same width. Consider to add breakpoints (or remove this property) while implementing responsive design.
			width: noFixedWidth ? 'auto' : '31.25rem',
			padding: noPadding ? 0 : '0 1.5rem',
		},
	});

	const { root } = useContentStyles();

	return (
		<MuiDialogContent classes={{ root }} {...MUIProps}>
			{children}
		</MuiDialogContent>
	);
};

export default DialogContent;
