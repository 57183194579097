import React, { useState, useEffect } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CLEARED_FILTERS, URLFiltersType } from 'Models/history/isURLFilters';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import Date from '../../../../components/filters/Date';
import PartnerCategories from '../../../../components/filters/PartnerCategories';
import PartnerLocations from '../../../../components/filters/PartnerLocations';
import PartnerTeams from '../../../../components/filters/PartnerTeams';
import PartnerVarieties from '../../../../components/filters/PartnerVarieties';
import useManageHistory from '../../../../hooks/useManageHistory';
import Drawer from '../../common/components/Drawer';

const FilterModal = () => {
	const [open, setOpen] = useState(false);

	function toggleOpen() {
		setOpen((prevState) => !prevState);
	}

	const { t } = useTranslation();
	const manageHistory = useManageHistory();

	const searchParams = manageHistory.paramsFromURL;
	const paramsFilters = searchParams.filters;

	const [filters, setFilters] = useState<URLFiltersType>({
		...CLEARED_FILTERS,
	});

	useEffect(() => {
		setFilters({
			...paramsFilters,
			teamId: paramsFilters.teamId,
			timeFrom: paramsFilters.timeFrom,
			timeTo: paramsFilters.timeTo,
			categories: paramsFilters.categories,
			locations: paramsFilters.locations,
			varieties: paramsFilters.varieties,
		});
	}, [open]);

	const onSave = () => {
		const newSearchParams = {
			...searchParams,
			filters: {
				...searchParams.filters,
				teamId: filters.teamId,
				timeFrom: filters.timeFrom,
				timeTo: filters.timeTo,
				categories: filters.categories,
				locations: filters.locations,
				varieties: filters.varieties,
			},
		};

		manageHistory.historyPush(newSearchParams);
		toggleOpen();
	};

	const onClear = () => {
		setFilters({ ...CLEARED_FILTERS });
	};

	return (
		<>
			<Button
				startIcon={<OptionsIcon />}
				variant="secondary"
				onClick={toggleOpen}
			>
				{t('buttons.filters')}
			</Button>
			<Drawer.Container onHideClick={toggleOpen} open={open}>
				<Drawer.Header onHideClick={toggleOpen} />
				<Drawer.Content>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.date')}
					>
						<Date filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.filters')}
					>
						<Box>
							<PartnerCategories filters={filters} updateFilters={setFilters} />
							<PartnerLocations filters={filters} updateFilters={setFilters} />
							<PartnerVarieties filters={filters} updateFilters={setFilters} />
							{/* 
						// TODO: add missing filters when backend will be ready
						<Fields /> 
						*/}
						</Box>
					</Drawer.ContentSection>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.partnerTeams')}
					>
						<PartnerTeams filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
				</Drawer.Content>
				<Drawer.Footer>
					<Button variant="text" onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={onSave}>{t('buttons.save')}</Button>
				</Drawer.Footer>
			</Drawer.Container>
		</>
	);
};

export default FilterModal;
