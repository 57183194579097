import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import fieldsArea from 'Models/fields/fieldsArea';
import { AreaUnits } from 'Models/units';
import { toDisplayString } from 'Models/units/transpileUnits';
import Text from 'UI/display/Text';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

import translateProductCategory from 'Utils/translateProductCategory';

import { TCropSummaryVariety } from '../../../../../FieldDashboardPage/components/CropSummary/utils/getSummarySectionData';
import { TCropSummaryCategory } from '../../../../utils/getSummarySectionData';
import styles from './styles.module.scss';

const DEFAULT_COLORS = [
	FarmableColors.ORANGE_13_13,
	FarmableColors.ORANGE_13_22,
	FarmableColors.ORANGE_13_31,
	FarmableColors.ORANGE_13_42,
	FarmableColors.ORANGE,
	FarmableColors.ORANGE_13_72,
];

const useStyles = makeStyles({
	indicator: {
		width: '0.75rem',
		height: '0.75rem',
		borderRadius: '50%',
		border: 'solid 1px rgba(0, 0, 0, 0.2)',
		margin: '0.125rem 0.5rem 0 0',
		backgroundColor: (theme: { color: string }) => theme.color,
	},
});

type TListItemProps = {
	category: string;
	areaSize: number;
	areaUnit: AreaUnits;
	fieldIds?: string[];
	color: string;
};

function ListItem(props: TListItemProps) {
	const { category, areaSize, areaUnit, fieldIds, color } = props;
	const { indicator } = useStyles({ color });
	const { t } = useTranslation();

	const areaUnitString = areaUnit ? toDisplayString(areaUnit) : '';
	const fieldArea = fieldsArea({
		areaSize: areaSize,
		location: { areaUnit: areaUnit },
	});
	const areaString = `${fieldArea}\u00A0${areaUnitString}`;
	const fieldsText = `${fieldIds?.length}\u00A0${t('labels.field', {
		count: fieldIds?.length,
	}).toLocaleLowerCase()}`;

	const translatedCategory = translateProductCategory(category, t);

	return (
		<div className={styles.card}>
			<div className={indicator}></div>
			<div className={styles.descriptionSection}>
				<Text variant="label5">{translatedCategory}</Text>
				<Text variant="label6">{areaString}</Text>
				{fieldIds?.length ? <Text variant="label6">{fieldsText}</Text> : null}
			</div>
		</div>
	);
}

type TVarietiesListProps = {
	varieties: TCropSummaryCategory[] | TCropSummaryVariety[];
};

export default function VarietiesList(props: TVarietiesListProps) {
	const items = props.varieties.map(
		(variety: TCropSummaryCategory | TCropSummaryVariety, index: number) => {
			return (
				<ListItem
					{...variety}
					key={variety.category}
					color={DEFAULT_COLORS[index]}
				/>
			);
		}
	);

	return (
		<div className={styles.overlay}>
			<div className={styles.list}>
				<div className={styles.innerContainer}>{items}</div>
			</div>
		</div>
	);
}
