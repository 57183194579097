import React from 'react';

import ErrorBoundary from '../../../components/ErrorBoundary';
import PageBody from './components/PageBody';

function PartnerDashboardPage() {
	return (
		<ErrorBoundary>
			<PageBody />
		</ErrorBoundary>
	);
}

export default PartnerDashboardPage;
