import React from 'react';

import { Trans } from 'Features/localization/components/Trans';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Banner from 'UI/surfaces/Card/Banner';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

const useStyles = makeStyles({
	mail: {
		color: FarmableColors.WHITE,
		fontWeight: 'bold',
		'&:hover': {
			color: FarmableColors.WHITE,
		},
	},
});

const NoCreditCardMessage = () => {
	const cardStyles = useStyles();
	const { t } = useTranslation();

	return (
		<Banner
			header={t('storePage.noCreditCard.header')}
			message={t('storePage.noCreditCard.message')}
			contactInfo={
				<Trans i18nKey={'storePage.noCreditCard.contactInfo'}>
					Contact us at
					<a href="mailto:support@farmable.tech" className={cardStyles.mail}>
						support@farmable.tech
					</a>
					for more information.
				</Trans>
			}
		/>
	);
};

export default NoCreditCardMessage;
