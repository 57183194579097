import React, { useState } from 'react';

import Controlled from './Controlled';

function Uncontrolled() {
	const [date, setDate] = useState<Date | null>(new Date());

	return <Controlled value={date} onChange={setDate} />;
}

export default Uncontrolled;
