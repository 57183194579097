import { TTranslateFunction } from 'Features/localization';

import translateProductCategory from 'Utils/translateProductCategory';

import { THarvest } from './types/Harvest';

function harvestCategoryString(
	harvest: Pick<THarvest, 'date' | 'category'>,
	t: TTranslateFunction
): string {
	return translateProductCategory(harvest.category, t) || '-';
}

export default harvestCategoryString;
