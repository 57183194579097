import React, { ReactElement } from 'react';

import { FarmableColors } from 'UI/theme/Colors';

import useCompanyID from 'Hooks/useCompanyID';
import useCurrentLocation from 'Hooks/useCurrentLocation';
import useLocationID from 'Hooks/useLocationID';

import FarmSettingsSection from './components/FarmSettingsSection';
import ImportsSection from './components/ImportsSection';
import LibrarySection from './components/LibrarySection';
import PartnerSettingsSection from './components/PartnerSettingsSection';
import SalesManagementSection from './components/SalesManagementSection';
import TimesheetsSection from './components/TimesheetsSection';

type TLocationRoutes = {
	settings: string;
	members: string;
	teams: string;
	timeSettings: string;
	fieldsImport: string;
	customers: string;
};

type TCompanyRoutes = {
	partnerInfo: string;
	manageMembers: string;
	partnerCauses: string;
	partnerTreatments: string;
};

function getSettingsRoutes(routeIDs: { locationID: string }): TLocationRoutes;
function getSettingsRoutes(routeIDs: { companyID: string }): TCompanyRoutes;
function getSettingsRoutes(routeIDs: {
	locationID?: string;
	companyID?: string;
}): TLocationRoutes | TCompanyRoutes {
	const { locationID, companyID } = routeIDs;

	if (locationID) {
		return {
			settings: `/locations/${locationID}/settings/info` as const,
			members: `/locations/${locationID}/settings/members` as const,
			teams: `/locations/${locationID}/settings/teams` as const,
			timeSettings: `/locations/${locationID}/settings/time-settings` as const,
			customers: `/locations/${locationID}/settings/customers` as const,
			fieldsImport: `/locations/${locationID}/settings/import/fields` as const,
		};
	}

	return {
		partnerInfo: `/partners/${companyID}/settings/info` as const,
		manageMembers: `/partners/${companyID}/settings/members` as const,
		partnerCauses: `/partners/${companyID}/settings/causes` as const,
		partnerTreatments: `/partners/${companyID}/settings/treatments` as const,
	};
}

export const ICONS_FILL = FarmableColors.BLACK_60;

const SettingsList = (): ReactElement => {
	const locationID = useLocationID();
	const companyID = useCompanyID();
	const location = useCurrentLocation();
	const isSIEX = location && location.type === 'SIEX';

	return (
		<>
			<PartnerSettingsSection companyID={companyID} />
			<LibrarySection companyID={companyID} />
			<FarmSettingsSection locationID={locationID} />
			<TimesheetsSection locationID={locationID} />
			<SalesManagementSection locationID={locationID} />
			{!isSIEX ? <ImportsSection locationID={locationID} /> : null}
		</>
	);
};

export { getSettingsRoutes };

export default SettingsList;
