import format from 'date-fns/format';

import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const jobDateStartTimeString = (job: Pick<TJob, 'startDate'>) => {
	if (!job.startDate) {
		return '-';
	}

	return `${format(toLocalDate(job.startDate), 'HH:mm')}`;
};

export default jobDateStartTimeString;
