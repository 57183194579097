import React, { ReactElement } from 'react';

import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

import { FarmableColors } from '../../theme/Colors';

export type TTooltipProps = {
	backgroundColor?: string;
	arrowColor?: string;
	boxShadow?: boolean;
} & TooltipProps;

type TTooltipStylesProps = {
	arrowColor?: string;
	backgroundColor?: string;
	boxShadow?: boolean;
};

const useStyles = makeStyles({
	arrow: {
		color: (args: TTooltipStylesProps) =>
			args.arrowColor || FarmableColors.BLACK_87,
		'&:before': {
			boxShadow: (args: TTooltipStylesProps) =>
				args.boxShadow
					? '0 1px 5px 0 rgb(0 0 0 / 16%), 0 3px 1px -2px rgb(0 0 0 / 8%), 0 2px 2px 0 rgb(0 0 0 / 12%)'
					: 'none',
		},
	},
	tooltip: {
		backgroundColor: (args: TTooltipStylesProps) =>
			args.backgroundColor || FarmableColors.BLACK_87,
		boxShadow: (args: TTooltipStylesProps) =>
			args.boxShadow
				? '0 1px 5px 0 rgb(0 0 0 / 16%), 0 3px 1px -2px rgb(0 0 0 / 8%), 0 2px 2px 0 rgb(0 0 0 / 12%)'
				: 'none',
	},
});

const Tooltip = (props: TTooltipProps): ReactElement => {
	const { backgroundColor, arrowColor, boxShadow, ...defaultProps } = props;

	const classes = useStyles({
		arrowColor: arrowColor,
		backgroundColor: backgroundColor,
		boxShadow: boxShadow,
	});

	return <MUITooltip {...defaultProps} classes={classes} />;
};

export default Tooltip;
