import React from 'react';

import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';

import { TPartnerCause } from '../types';
import CauseDropdown from './CauseDropdown';
import styles from './styles.module.scss';

type TProps = {
	cause: TPartnerCause;
};

function CauseItem(props: TProps) {
	const { cause } = props;

	return (
		<Grid container className={styles.cause}>
			<Grid item sm={6}>
				<Text variant="label">{cause.name}</Text>
			</Grid>
			<Grid item sm={5}>
				<Text variant="secondaryBody2" textTransform="capitalize">
					{cause.causeType.toLowerCase()}
				</Text>
			</Grid>
			<Grid item sm={1}>
				<CauseDropdown cause={cause} />
			</Grid>
		</Grid>
	);
}

export default CauseItem;
