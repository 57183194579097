import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Chart, { TChartRef } from 'UI/display/Chart';

import { TRevenueData } from '../../api/getRevenueChartData';
import useOldestBatch from '../../hooks/useOldestBatch';
import getTheLastSaleDate from '../../utils/getLastSaleDate';
import getTheOldestItem from '../../utils/getOldestItem';
import initialChartOptions from './ChartConfig';
import useRevenueChartData from './hooks/useRevenueChartData';
import createSeriesOptions from './utils/createSeriesOptions';
import sortDataPerCurrency from './utils/sortDataPerCurrency';
import tooltipFormatter from './utils/tooltipFormatter';

const sortData = (result: TRevenueData[]) =>
	[...result].sort((saleA, saleB) => {
		return saleA.date && saleB.date
			? new Date(saleA.date).getTime() - new Date(saleB.date).getTime()
			: 0;
	});

const RevenueChart = () => {
	const { t } = useTranslation();
	const [chartOptions, setChartOptions] = useState(initialChartOptions(t));
	const chartComponent = useRef<TChartRef>(null);
	const currentYear = new Date().getFullYear();

	const currentYearRevenue = useRevenueChartData(currentYear);
	const previousYearRevenue = useRevenueChartData(currentYear - 1);

	const currentYearOldestHarvestBatch = useOldestBatch(currentYear);
	const previousYearOldestHarvestBatch = useOldestBatch(currentYear - 1);

	const formatter = useCallback(tooltipFormatter(t), [t]);

	useEffect(() => {
		const chart = chartComponent?.current?.chart;

		if (chart) {
			if (
				previousYearRevenue.data &&
				currentYearRevenue.data &&
				previousYearOldestHarvestBatch.data &&
				currentYearOldestHarvestBatch.data
			) {
				const noData =
					!currentYearRevenue.data.getAggregatedSales.length &&
					!previousYearRevenue.data.getAggregatedSales.length;

				if (noData) {
					chart.showNoData();
				}

				const sortedCurrent = sortData(
					currentYearRevenue.data.getAggregatedSales
				);
				const sortedPrevious = sortData(
					previousYearRevenue.data.getAggregatedSales
				);

				const series = createSeriesOptions(
					sortDataPerCurrency(sortedCurrent || []),
					sortDataPerCurrency(sortedPrevious || []),
					t
				);

				chart.hideLoading();
				chart.reflow();

				setChartOptions({
					xAxis: {
						min: getTheOldestItem(
							currentYearOldestHarvestBatch.data.getHarvestBatches
								.harvestBatches[0],
							previousYearOldestHarvestBatch.data.getHarvestBatches
								.harvestBatches[0]
						),
						max: getTheLastSaleDate(sortedCurrent, sortedPrevious),
						visible: !noData,
					},
					series,
					tooltip: {
						shared: true,
						useHTML: true,
						formatter,
					},
				});
			} else if (currentYearRevenue.loading || previousYearRevenue.loading) {
				chart.hideNoData();
				chart.showLoading();

				setChartOptions({
					xAxis: {
						visible: false,
					},
					series: [],
				});
			}
		}
	}, [
		// loading props are excluded as with them we reach rerender max depth
		currentYearOldestHarvestBatch.data,
		currentYearRevenue.data,
		formatter,
		previousYearOldestHarvestBatch.data,
		previousYearRevenue.data,
		t,
	]);

	return (
		<Chart
			options={chartOptions}
			ref={chartComponent}
			containerProps={{
				style: {
					padding: '1.5rem',
					height: '22rem',
				},
			}}
		/>
	);
};

export default RevenueChart;
