import React, { ReactElement } from 'react';

import { ConsentManager as SegmentConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';

import { Trans } from '../../localization/components/Trans';
import { useTranslation } from '../../localization/hooks/useTranslation';
import styles from './styles.module.scss';

const ConsentManager = (): ReactElement | null => {
	const { t } = useTranslation();

	const bannerContent = (
		<span>
			<Trans i18nKey={'consentManager.bannerContent'}>
				We use cookies (and other similar technologies) to collect data to
				improve your experience on our site. By using our website, you’re
				agreeing to the collection of data as described in our
				<a href="https://www.farmable.tech/privacy" target="_blank">
					Website Data Collection Policy
				</a>
				.
			</Trans>
		</span>
	);

	if (process.env.SEGMENT_ID) {
		return (
			<div className={styles.consentManagerWrapper}>
				<SegmentConsentManager
					writeKey={process.env.SEGMENT_ID}
					// inEU package comes with no type declarations.
					shouldRequireConsent={inEU as () => Promise<boolean> | boolean}
					bannerContent={bannerContent}
					bannerSubContent={t('consentManager.bannerSubContent')}
					preferencesDialogTitle={t('consentManager.preferencesDialogTitle')}
					preferencesDialogContent={t(
						'consentManager.preferencesDialogContent'
					)}
					cancelDialogTitle={t('consentManager.cancelDialogTitle')}
					cancelDialogContent={t('consentManager.cancelDialogContent')}
					bannerBackgroundColor={'#1d60c8'}
				/>
			</div>
		);
	}

	return null;
};

export { ConsentManager };
