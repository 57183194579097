import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import FailedIcon from 'UI/icons/Failed';
import Box from 'UI/layout/Box';

import getErrorMessage from 'Utils/getErrorMessage';

import ContentLayout from '../ContentLayout';
import UploadGeoJSON from '../UploadGeoJSON';

type TAddFieldsFailure = {
	error: unknown;
};

const AddFieldsFailure = (props: TAddFieldsFailure) => {
	const { error } = props;
	const { t } = useTranslation();

	const title = (
		<Text variant="h3" component="h2">
			{t('importPages.fieldImportsPage.uploadFilesStep.title')}
		</Text>
	);

	const description = (
		<Text variant="body1" color="textSecondary">
			{t('importPages.fieldImportsPage.uploadFilesStep.description')}
		</Text>
	);

	return (
		<ContentLayout>
			<Box
				padding={2}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				{title}
				<Box paddingTop={2} paddingBottom={4}>
					{description}
				</Box>
				<FailedIcon />
				<Box padding={2} textAlign="center">
					<Text variant="h2">{t('labels.error')}</Text>
					<Text>{getErrorMessage(error)}</Text>
				</Box>
				<UploadGeoJSON />
			</Box>
		</ContentLayout>
	);
};

export default AddFieldsFailure;
