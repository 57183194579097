import React, { useState } from 'react';

import Accordion from 'UI/display/Accordion';
import Text from 'UI/display/Text';
import ExpandMore from 'UI/icons/ExpandMore';
import { FarmableColors } from 'UI/theme/Colors';
import { useTranslation } from 'react-i18next';

const OptionsAccordion = (props) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const handleChange = () => {
		setExpanded(!expanded);
	};

	return (
		<Accordion.Controlled
			sx={{
				'&::before': {
					display: 'none',
				},
			}}
			expanded={expanded}
			onChange={handleChange}
			elevation={0}
			disableGutters
		>
			<Accordion.Header
				sx={{
					color: FarmableColors.ORANGE,
					padding: 0,
					width: 'fit-content',
					'& .Mui-expanded .MuiSvgIcon-root': {
						transform: 'rotate(180deg)',
					},
				}}
				aria-controls="details-collapsible-content"
			>
				<Text color="primary" variant="label">
					{t('buttons.optionalDetails')}
				</Text>
				<ExpandMore />
			</Accordion.Header>
			<Accordion.Content
				sx={{
					padding: 0,
				}}
			>
				{props.children}
			</Accordion.Content>
		</Accordion.Controlled>
	);
};

export default OptionsAccordion;
