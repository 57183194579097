import { intervalToDuration } from 'date-fns';

import { TTimesheet } from './isTimesheet';
import timesheetDurationMs from './timesheetDurationMs';

function timesheetDuration(
	timesheet: Pick<TTimesheet, 'endTime' | 'timesheetFieldHours'>
): string | number {
	const durationInMs = timesheetDurationMs(timesheet);

	if (!durationInMs) {
		return '-';
	}

	const durationObject = intervalToDuration({
		start: new Date(0),
		end: new Date(durationInMs),
	});

	const duration =
		(durationObject.hours || 0) +
		(durationObject.minutes
			? Math.round((durationObject.minutes / 60) * 100) / 100
			: 0);

	return duration === 0 ? '-' : duration;
}

export default timesheetDuration;
