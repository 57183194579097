import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Paper, { PaperProps } from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';

import Close from '../../icons/Close';
import Search from '../../icons/Search';
import { FarmableColors } from '../../theme/Colors';
import IconButton from '../IconButton';

export type TFreeTextSearchProps = {
	inputPlaceholder?: string;
	value?: string;
	error?: boolean;
	onChange?: (newValue: string, value?: string) => void;
	onClear?: () => void;
} & Omit<PaperProps, 'onChange'> &
	Omit<InputBaseProps, 'onChange'>;

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
});

const FreeTextSearch = forwardRef(
	(props: TFreeTextSearchProps, ref): JSX.Element => {
		const {
			inputPlaceholder,
			onChange,
			onClear,
			value,
			error,
			sx,
			...MUIProps
		} = props;

		const innerRef = React.useRef<Node>();
		useImperativeHandle(ref, () => innerRef.current);

		const styles = useStyles();
		const onChangeEvent = (
			event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
		) => {
			const newValue = event.target.value;
			if (onChange) {
				onChange(newValue, value);
			}
		};

		useEffect(() => {
			const onKeyDown = (event: KeyboardEvent): void => {
				if (event.key === 'Enter') {
					event.preventDefault();
				}
			};

			// type assertion needed as we use KeyboardEvent
			// which has more props than event defined in EventListener
			innerRef.current?.addEventListener('keydown', onKeyDown as EventListener);

			return () =>
				innerRef.current?.removeEventListener(
					'keydown',
					onKeyDown as EventListener
				);
		}, []);

		return (
			<Paper component="form" classes={{ root: styles.root }} sx={sx}>
				<IconButton disabled size="large">
					<Search fill={FarmableColors.BLACK_60} />
				</IconButton>
				<InputBase
					inputRef={innerRef}
					fullWidth={true}
					onChange={onChangeEvent}
					value={value}
					placeholder={inputPlaceholder || 'Search...'}
					error={error}
					{...MUIProps}
				/>
				{onClear ? (
					<IconButton onClick={onClear} size="small">
						<Close fill={FarmableColors.BLACK_60} />
					</IconButton>
				) : null}
			</Paper>
		);
	}
);

export default FreeTextSearch;
