import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import styles from '../styles.module.scss';
import AddCustomerModal from './AddCustomerModal';

const AddCustomersSection = (): ReactElement => {
	const { t } = useTranslation();

	return (
		<Card className={styles.addCustomerCard}>
			<CardContent>
				<Grid container justifyContent="space-between">
					<Text variant="h3">{t('customersPage.addCustomer.title')}</Text>
					<AddCustomerModal />
				</Grid>
				<Text variant="body1" color="textSecondary">
					{t('customersPage.addCustomer.description')}
				</Text>
			</CardContent>
		</Card>
	);
};

export default AddCustomersSection;
