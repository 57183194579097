import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';

import StripeProvider from '../../features/stripe/StripeProvider';
import Content, { TProductIdWithDemo } from './components/Content';
import styles from './styles.module.scss';

type TSubscriptionModalProps = {
	productIdsWithDemo: TProductIdWithDemo[];
	onCloseCallback?: () => void;
	onOpenCallback?: () => void;
	title?: string;
	subtitle?: string;
	hasPurchasePermission?: boolean;
	openButtonText?: string;
	noPermissionsMessage?: string;
};

const SubscriptionModal = (props: TSubscriptionModalProps) => {
	const {
		productIdsWithDemo,
		onCloseCallback,
		onOpenCallback,
		title,
		subtitle,
		hasPurchasePermission,
		openButtonText,
		noPermissionsMessage,
	} = props;
	const [hasCloseIcon, setHasCloseIcon] = useState(true);
	const [isOpen, setIsOpen] = useState(false);

	const { t } = useTranslation();

	const toggleOpen = () => {
		if (!isOpen && onOpenCallback) {
			onOpenCallback();
		}

		if (isOpen && onCloseCallback) {
			onCloseCallback();
		}

		setIsOpen(!isOpen);
	};

	const onStripeProcessingStarted = () => setHasCloseIcon(false);
	const onErrorHandler = () => setHasCloseIcon(true);

	const noPermissionMessage = (
		<Text className={styles.noOwnerInfo}>
			{noPermissionsMessage ||
				t('noAccess.noSubscriptionPage.noPermissionToBuy')}
		</Text>
	);

	const subtitleText =
		hasPurchasePermission && subtitle ? (
			<Text className={styles.modalSubtitle} variant="secondaryBody1">
				{subtitle}
			</Text>
		) : null;

	return (
		<>
			<Button variant={'outlined'} onClick={toggleOpen}>
				{openButtonText ||
					t('logsPages.jobLogsPage.exportButton.noSubscription')}
			</Button>
			<StripeProvider>
				<Modal.UncontrolledModal open={isOpen}>
					<Modal.DialogTitle
						title={title}
						closeIcon={hasCloseIcon}
						closeIconHandler={toggleOpen}
					/>
					{subtitleText}
					<Modal.DialogContent>
						{hasPurchasePermission ? (
							<Content
								productIdsWithDemo={productIdsWithDemo}
								onStripeProcessingStarted={onStripeProcessingStarted}
								onErrorHandler={onErrorHandler}
							/>
						) : (
							noPermissionMessage
						)}
					</Modal.DialogContent>
				</Modal.UncontrolledModal>
			</StripeProvider>
		</>
	);
};

export default SubscriptionModal;
