import React, { useState, useCallback, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Radio from 'UI/inputs/Radio';
import RadioGroup from 'UI/inputs/RadioGroup';
import Box from 'UI/layout/Box';

import { addTreatmentPageState } from '../../../state';
import { TSelectCropCategoryInput } from '../../../types';

function SelectCropCategory() {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const state = useReactiveVar(addTreatmentPageState);

	const categoriesEn = t('productCategories', {
		returnObjects: true,
		lng: 'en',
	});

	const categories = t('productCategories', {
		returnObjects: true,
	});

	const categoriesList = Object.values<string>(categoriesEn).sort();

	const translate = useCallback(
		(value: string): string => {
			const path = Object.keys(categoriesEn).find(
				(key) => categoriesEn[key] === value
			);

			const result = path ? categories[path] : null;
			return result || value;
		},
		[categories, categoriesEn]
	);

	const cropCategoriesList = useMemo(
		() =>
			categoriesList
				.filter((categoryName) =>
					categoryName.toLowerCase().includes(searchValue.toLowerCase())
				)
				.map((category) => (
					<Radio key={category} value={category} label={translate(category)} />
				)),
		[categoriesList, searchValue, translate]
	);

	if (state.status !== 'SELECT_CROP_CATEGORY') {
		return null;
	}

	const setSelectedCrop = (categoryName: string) => {
		addTreatmentPageState({
			...state,
			editedCategoryName: categoryName,
		} as TSelectCropCategoryInput);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			overflow="auto"
			maxHeight="35.25rem"
		>
			<FreeTextSearch
				inputPlaceholder={t('labels.searchCrop')}
				onChange={(newValue: string): void => setSearchValue(newValue)}
				value={searchValue}
				sx={{
					height: '3.5rem',
					boxShadow: 'none',
					marginLeft: '-0.75rem',
				}}
			/>
			<Divider />
			<Box display="flex" overflow="auto" height="100%">
				<RadioGroup.Controlled
					value={state.editedCategoryName}
					valueChangeHandler={(e) => setSelectedCrop(e.target.value)}
					sx={{
						width: '100%',
						'.MuiFormControlLabel-root > .MuiRadio-root': {
							padding: '0.5rem 0.75rem',
						},
					}}
				>
					{cropCategoriesList?.length ? (
						cropCategoriesList
					) : (
						<Box marginY="1rem">{t('labels.noCategoriesFound')}</Box>
					)}
				</RadioGroup.Controlled>
			</Box>
		</Box>
	);
}

export default SelectCropCategory;
