import React, { ChangeEvent, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import SearchIcon from 'UI/icons/Search';
import _throttle from 'lodash/throttle';

import useManageHistory from 'Hooks/useManageHistory';

type TTextSearchInputProps = {
	placeholder?: string;
} & OutlinedInputProps;

const TextSearchInput = (props: TTextSearchInputProps) => {
	const { paramsFromURL, historyPush } = useManageHistory();
	const { t } = useTranslation();
	const { search } = paramsFromURL.filters;
	const [focused, setFocused] = useState(false);
	const [value, setValue] = useState(search);

	const setSearchValue = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		if (event.target.value.length > 2) {
			const newParams = {
				...paramsFromURL,
				filters: {
					...paramsFromURL.filters,
					search: event.target.value || undefined,
				},
			};

			historyPush(newParams);
		} else if (event.target.value.length && paramsFromURL.filters.search) {
			const newParams = {
				...paramsFromURL,
				filters: {
					...paramsFromURL.filters,
					search: undefined,
				},
			};

			historyPush(newParams);
		}
	};

	return (
		<FormControl>
			<OutlinedInput
				id={props.id}
				onClick={() => setFocused(true)}
				onBlur={() => {
					if (!search) {
						setFocused(false);
					}
				}}
				startAdornment={
					<InputAdornment position="start">
						<SearchIcon color="primary" />
					</InputAdornment>
				}
				value={value}
				onChange={(event) => {
					setValue(event.target.value);
					_throttle(setSearchValue, 500)(event);
				}}
				placeholder={focused ? props.placeholder || t('labels.search') : ''}
				sx={{
					borderRadius: '1rem',
					height: '2rem',
					minWidth: '3rem',
					padding: '0 0.2rem 0 0.7rem',
					'.MuiOutlinedInput-input': {
						transition: 'width 300ms ease',
						fontSize: '0.875rem',
						padding: focused ? '0.4rem 0.5rem 0.4rem 0' : 0,
						width: focused ? '9rem' : 0,
					},
				}}
			/>
		</FormControl>
	);
};

export default TextSearchInput;
