import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import { ValidatorType } from 'vet/types';

const _isIdentifyTraits = isShape({
	email: optional(isString),
	firstName: optional(isString),
	lastName: optional(isString),
	userId: isString,
	firstSignup: isString,
});

// https://github.com/microsoft/TypeScript/issues/34596
const isIdentifyTraits: typeof _isIdentifyTraits = _isIdentifyTraits;

export type TIdentifyTraits = ValidatorType<typeof isIdentifyTraits>;

export default isIdentifyTraits;
