import React from 'react';

import { TFieldInput } from 'Models/fields/isFieldInput';
import { FieldTypes } from 'Models/fields/isFieldType';
import Divider from 'UI/display/Divider';
import Box from 'UI/layout/Box';

import { TTransactionChangeFunction } from 'Hooks/useTransactionalInput';

import FieldName from './FieldName';
import FieldType from './FieldType';
import OptionalDetails from './OptionalDetails';
import DistanceBetweenPlantsInRows from './OptionalDetails/DistanceBetweenPlantsInRows';
import DistanceBetweenRows from './OptionalDetails/DistanceBetweenRows';
import PlantHeight from './OptionalDetails/PlantHeight';
import PlantSupplier from './OptionalDetails/PlantSupplier';
import ProductionMethodSelect from './OptionalDetails/ProductionMethodSelect';
import ProductiveArea from './OptionalDetails/ProductiveArea';
import Rootstock from './OptionalDetails/Rootstock';
import TotalPlants from './OptionalDetails/TotalPlants';
import Varieties from './Varieties';
import styles from './styles.module.scss';

type TModalFormProps = {
	fieldInput: TFieldInput;
	onTransactionChange: TTransactionChangeFunction<TFieldInput>;
};

const ModalForm = (props: TModalFormProps) => {
	const { fieldInput, onTransactionChange } = props;
	const {
		fieldType,
		name,
		averageDistanceBetweenRows,
		productionMethod,
		averageDistanceBetweenTreesInRows,
		plantHeight,
		productiveArea,
		rootstock,
		treeSupplier,
		varieties,
		numberOfPlants,
	} = fieldInput;

	const shouldShowInput = <TKey extends keyof TFieldInput>(key: TKey) => {
		switch (key) {
			case 'plantHeight':
				return (
					FieldTypes.FIELD !== fieldType && FieldTypes.GREEN_HOUSE !== fieldType
				);
			case 'averageDistanceBetweenRows':
			case 'averageDistanceBetweenTreesInRows':
			case 'rootstock':
			case 'numberOfPlants':
			case 'treeSupplier':
				return FieldTypes.FIELD !== fieldType;
			default:
				return true;
		}
	};

	function getInputChangeHandler<TKey extends keyof TFieldInput>(key: TKey) {
		return (value: TFieldInput[TKey]) => {
			onTransactionChange({ ...fieldInput, [key]: value });
		};
	}

	const fieldInputProperties = fieldInput ? (
		<div className={styles.fieldInputForms}>
			<FieldType
				fieldType={fieldType}
				transactionChangeHandler={onTransactionChange}
			/>
			<FieldName name={name} setName={getInputChangeHandler('name')} />
			<Varieties
				fieldType={fieldType}
				varieties={varieties}
				setVarieties={getInputChangeHandler('varieties')}
			/>
			<Box>
				<Divider variant="fullWidth" />
			</Box>
			<OptionalDetails>
				{shouldShowInput('productionMethod') ? (
					<ProductionMethodSelect
						productionMethod={productionMethod}
						onChange={getInputChangeHandler('productionMethod')}
					/>
				) : null}
				{shouldShowInput('averageDistanceBetweenRows') ? (
					<DistanceBetweenRows
						distance={averageDistanceBetweenRows}
						setDistance={getInputChangeHandler('averageDistanceBetweenRows')}
					/>
				) : null}
				{shouldShowInput('averageDistanceBetweenTreesInRows') ? (
					<DistanceBetweenPlantsInRows
						distance={averageDistanceBetweenTreesInRows}
						setDistance={getInputChangeHandler(
							'averageDistanceBetweenTreesInRows'
						)}
					/>
				) : null}

				{shouldShowInput('plantHeight') ? (
					<PlantHeight
						height={plantHeight}
						setHeight={getInputChangeHandler('plantHeight')}
					/>
				) : null}

				{shouldShowInput('numberOfPlants') ? (
					<TotalPlants
						numberOfPlants={numberOfPlants}
						setNumberOfPlants={getInputChangeHandler('numberOfPlants')}
					/>
				) : null}

				{shouldShowInput('productiveArea') ? (
					<ProductiveArea
						area={productiveArea}
						setArea={getInputChangeHandler('productiveArea')}
					/>
				) : null}

				{shouldShowInput('rootstock') ? (
					<Rootstock
						rootstock={rootstock}
						setRootstock={getInputChangeHandler('rootstock')}
					/>
				) : null}

				{shouldShowInput('treeSupplier') ? (
					<PlantSupplier
						plantSupplier={treeSupplier}
						setPlantSupplier={getInputChangeHandler('treeSupplier')}
					/>
				) : null}
			</OptionalDetails>
		</div>
	) : null;

	return <Box padding="-0.75rem">{fieldInputProperties}</Box>;
};

export default ModalForm;
