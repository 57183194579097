import { TJobFieldSnapshot } from '../jobs/isJobFieldSnapshot';
import { AreaUnits } from '../units';
import convertAreaUnit from '../units/convertAreaUnit';
import { TField } from './isField';

const getTotalFieldsArea = (
	fields: (Pick<TField, 'areaSize'> | Pick<TJobFieldSnapshot, 'areaSize'>)[],
	areaUnit?: AreaUnits
): number => {
	return convertAreaUnit(
		fields
			.map((field) => field.areaSize || 0)
			.reduce((previous, current) => previous + current, 0),
		areaUnit
	);
};

export default getTotalFieldsArea;
