import format from 'date-fns/format';

import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';

const jobDateCompletedTimeString = (
	job: Pick<TJob, 'completedDate' | 'startDate'>
): string =>
	job.completedDate
		? format(toLocalDate(job.completedDate), 'HH:mm')
		: job.startDate
		? 'N/A'
		: '-';

export default jobDateCompletedTimeString;
