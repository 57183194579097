import React, { FC, useCallback, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProduct } from 'Models/products/Product';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import MoreIcon from 'UI/icons/More';
import Button from 'UI/inputs/Button';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { FarmableColors } from 'UI/theme/Colors';

interface ProductMenuButtonProps {
	product: TProduct;
	isMenuOpened: boolean;
	toggleMenu: () => void;
	onDeleteProduct: (productId: string) => void;
	onEditProduct: () => void;
}

export const ProductMenuButton: FC<ProductMenuButtonProps> = ({
	product,
	isMenuOpened,
	toggleMenu,
	onDeleteProduct,
	onEditProduct,
}) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isDeleteProductModalOpened, setDeleteProductModalOpened] =
		useState(false);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();
			event.stopPropagation();
			toggleMenu();
			setAnchorEl(event.currentTarget);
		},
		[toggleMenu]
	);

	const handleClose = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();
			event.stopPropagation();
			toggleMenu();
			setAnchorEl(null);
		},
		[toggleMenu]
	);

	const handleToggleDeleteModal = useCallback(
		(event?: React.SyntheticEvent<Element, Event>) => {
			event?.preventDefault();
			event?.stopPropagation();
			setDeleteProductModalOpened((prevState) => !prevState);
		},
		[]
	);

	const handleEditProductClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();
			event.stopPropagation();
			onEditProduct();
		},
		[onEditProduct]
	);

	return (
		<>
			<IconButton size="medium" sx={{ margin: 'auto' }} onClick={handleClick}>
				<MoreIcon />
			</IconButton>
			<Menu.Menu
				id="library-settings-menu"
				open={isMenuOpened}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{!product.governmentProductSpec?.governmentProductId && (
					<Menu.Item onClick={handleEditProductClick}>
						{t('buttons.editProductLibrary')}
					</Menu.Item>
				)}
				{!product.governmentProductSpec?.governmentProductId && <Divider />}
				<Menu.Item
					sx={{ color: FarmableColors.RED }}
					onClick={handleToggleDeleteModal}
				>
					{t('buttons.deleteProductLibrary')}
				</Menu.Item>
			</Menu.Menu>
			<Modal.UncontrolledModal
				open={isDeleteProductModalOpened}
				toggleOpen={handleToggleDeleteModal}
			>
				<Modal.DialogTitle
					title={t('treatmentLibrary.deleteLibraryProductModal.title')}
					closeIconHandler={handleToggleDeleteModal}
				/>
				<Modal.DialogContent>
					<Text>{t('treatmentLibrary.deleteLibraryProductModal.message')}</Text>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant="text" onClick={handleToggleDeleteModal}>
							{t('buttons.back')}
						</Button>
						<Button
							variant="outlined"
							onClick={() => onDeleteProduct(product.id)}
						>
							{t('buttons.delete')}
						</Button>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};
