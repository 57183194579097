import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import useLocationID from 'Hooks/useLocationID';

import LoadingContent from '../../common/components/LoadingContent';
import GET_CUSTOMERS_DATA from '../api/getCustomersData';
import AddCustomersSection from './AddCustomerSection';
import CustomersTable from './CustomersTable';

const PageContent = () => {
	const locationId = useLocationID();
	const { data, loading, error } = useQuery(GET_CUSTOMERS_DATA, {
		variables: {
			locationId,
		},
	});

	if (loading) {
		return <LoadingContent />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const customers = [...data.getCustomers].sort((a, b) =>
			a.name.localeCompare(b.name)
		);

		return (
			<>
				<AddCustomersSection />
				<CustomersTable customers={customers} />
			</>
		);
	}

	return null;
};

export default PageContent;
