import {
	TSubscriptionType,
	TFeatureFlag,
} from 'Models/locations/isLocationSubscriptions';

import useLocationSubscriptions from './useLocationSubscriptions';

function hasSubscription(
	featureFlags: TFeatureFlag[],
	subscriptionToValidate: TSubscriptionType
): boolean {
	const subscription = featureFlags.find(
		(flag) => flag.name === subscriptionToValidate
	);

	return subscription?.active === true;
}

function useSubscriptionsFeatureFlag(
	subscriptionFeatureFlag: TSubscriptionType
): boolean | null {
	const { locationSubscriptions } = useLocationSubscriptions();

	if (Array.isArray(locationSubscriptions?.featureFlags)) {
		return locationSubscriptions?.featureFlags.length
			? hasSubscription(
					locationSubscriptions?.featureFlags,
					subscriptionFeatureFlag
			  )
			: false;
	}

	return null;
}

export default useSubscriptionsFeatureFlag;
