import React, { ReactNode, ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/drawer';
import HideLeftIcon from 'UI/icons/HideLeft';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';

import styles from './styles.module.scss';

type TWithHideHandler = {
	onHideClick: () => void;
};

type TWithChildren = {
	children?: ReactNode;
};

type TDrawerProps = {
	open: boolean;
} & TWithChildren &
	TWithHideHandler;

function Container(props: TDrawerProps): ReactElement {
	const { open, children, onHideClick } = props;

	return (
		<Modal.UncontrolledDrawer toggleOpen={onHideClick} open={open}>
			<Box className={styles.scrollableDrawer}>{children}</Box>
		</Modal.UncontrolledDrawer>
	);
}

function Header(props: TWithHideHandler): ReactElement {
	const { onHideClick } = props;
	const { t } = useTranslation();

	return (
		<Box px={3} py={2.5} className={styles.drawerHeader}>
			<Text variant="h4">{t('logsPages.filtersModal.title')}</Text>
			<Box className={styles.hideButton}>
				<IconButton onClick={onHideClick} size="medium">
					<HideLeftIcon />
				</IconButton>
			</Box>
		</Box>
	);
}

function Content(props: TWithChildren): ReactElement {
	return (
		<Box px={3} className={styles.scrollableContent}>
			{props.children}
		</Box>
	);
}

function ContentSection(
	props: { title: string } & TWithChildren
): ReactElement {
	const { title, children } = props;

	return (
		<Box pb={1}>
			<Text gutterBottom variant="secondaryBody2">
				{title}
			</Text>
			<div>{children}</div>
		</Box>
	);
}

function Footer(props: TWithChildren): ReactElement {
	return (
		<Box px={2} py={1.75} className={styles.drawerFooter}>
			{props.children}
		</Box>
	);
}

export default {
	Container,
	Header,
	Content,
	ContentSection,
	Footer,
};
