import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getLocationVarieties from 'Models/fields/getLocationVarieties';
import { URLFiltersType } from 'Models/history/isURLFilters';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';
import _flatten from 'lodash/flatten';

import DropdownContent from '../DropdownContent';
import GET_PARTNER_FIELDS from '../common/api/getPartnerFields';
import parseGetPartnerFieldsResult from '../common/utils/parseGetPartnerFieldsResult';

type TVarietiesProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
	paramName?: 'varieties' | 'fieldVarieties';
};

const PartnerVarieties = (props: TVarietiesProps) => {
	const { filters, updateFilters, paramName = 'varieties' } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();

	const getPartnerFieldsResult = useQuery(GET_PARTNER_FIELDS, {
		variables: {
			filterBy: {
				locationIds: filters.locations?.length ? filters.locations : null,
			},
		},
		onCompleted: (data) => {
			const varieties = _flatten(
				data.getPartnerFields.fields.map((field) =>
					field.field.varieties.map((variety) => variety.name)
				)
			);
			updateFilters({
				...filters,
				[paramName]: filters[paramName]?.filter((variety) => {
					return varieties.includes(variety);
				}),
			});
		},
	});

	const open = Boolean(anchorEl);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	if (getPartnerFieldsResult.loading) {
		return null;
	}

	const dropdownContent = getLocationVarieties(
		parseGetPartnerFieldsResult(getPartnerFieldsResult.data)
	);
	const appliedVarieties = filters[paramName] || [];
	const appliedVarietiesAmount = appliedVarieties.length
		? `(${appliedVarieties.length})`
		: '';

	if (getPartnerFieldsResult.error) {
		throw new ApolloError(getPartnerFieldsResult.error);
	}

	const onItemClick = (variety: string, active: boolean) => {
		const newVarieties: string[] = active
			? appliedVarieties.filter((fieldVariety) => fieldVariety !== variety)
			: [...appliedVarieties, variety];

		updateFilters({
			...filters,
			[paramName]: newVarieties,
		});
	};

	const onClear = () => {
		updateFilters({
			...filters,
			[paramName]: undefined,
		});
	};
	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
				sx={{ marginRight: '0.5rem' }}
			>
				{t('labels.variety', { count: 1 })} {appliedVarietiesAmount}
			</Button>
			<Menu.Menu
				id="varieties-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={getPartnerFieldsResult.loading}
					dropdownItems={dropdownContent}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={appliedVarieties}
				/>
			</Menu.Menu>
		</>
	);
};

export default PartnerVarieties;
