import { gql, TypedDocumentNode } from '@apollo/client';

type TCheckoutParams = {
	priceId: string;
	mode: 'SUBSCRIPTION' | 'PAYMENT';
	quantity: number;
	successUrl: string;
	cancelUrl: string;
};

type TCreateCheckoutSessionResponse = { createCheckoutSession: string };

export type TCreateCheckoutSessionRequest = {
	locationId: string;
	checkoutParams: TCheckoutParams;
};

const CREATE_CHECKOUT_SESSION: TypedDocumentNode<
	TCreateCheckoutSessionResponse,
	TCreateCheckoutSessionRequest
> = gql`
	mutation createStripeCheckoutSession(
		$locationId: ID!
		$checkoutParams: CheckoutParams!
	) {
		createCheckoutSession(locationId: $locationId, input: $checkoutParams)
	}
`;

export default CREATE_CHECKOUT_SESSION;
