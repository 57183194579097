import React, { useState, useEffect } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CLEARED_FILTERS, URLFiltersType } from 'Models/history/isURLFilters';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import useManageHistory from 'Hooks/useManageHistory';

import Categories from '../../../../components/filters/Categories';
import Date from '../../../../components/filters/Date';
import FieldsList from '../../../../components/filters/FieldsList';
import TeamMembers from '../../../../components/filters/TeamMembers';
import Varieties from '../../../../components/filters/Varieties';
import Drawer from '../../common/components/Drawer';
import ExportButton from './ExportButton';

const FilterModal = () => {
	const [open, setOpen] = useState(false);

	function toggleOpen() {
		setOpen((prevState) => !prevState);
	}

	const { t } = useTranslation();
	const prefix = 'logsPages.filtersModal.sections.';
	const { paramsFromURL: searchParams, historyPush } = useManageHistory();

	const paramsFilters = searchParams.filters;

	const [filters, setFilters] = useState<URLFiltersType>({
		...CLEARED_FILTERS,
	});

	useEffect(() => {
		setFilters({
			...paramsFilters,
			dateFrom: paramsFilters.dateFrom,
			dateTo: paramsFilters.dateTo,
			fieldCategories: paramsFilters.fieldCategories,
			fieldVarieties: paramsFilters.fieldVarieties,
			fieldIds: paramsFilters.fieldIds,
			teamMemberIds: paramsFilters.teamMemberIds,
		});
	}, [open]);

	const onSave = () => {
		const newSearchParams = {
			...searchParams,
			filters: {
				...searchParams.filters,
				dateFrom: filters.dateFrom,
				dateTo: filters.dateTo,
				fieldCategories: filters.fieldCategories,
				fieldVarieties: filters.fieldVarieties,
				fieldIds: filters.fieldIds,
				teamMemberIds: filters.teamMemberIds,
			},
		};

		historyPush(newSearchParams);
		toggleOpen();
	};

	const onClear = () => {
		setFilters({ ...CLEARED_FILTERS });
	};

	return (
		<>
			<Box display="flex" flexGrow={1} justifyContent="space-between">
				<Button
					startIcon={<OptionsIcon />}
					variant="secondary"
					onClick={toggleOpen}
				>
					{t('buttons.filters')}
				</Button>
			</Box>
			<Drawer.Container onHideClick={toggleOpen} open={open}>
				<Drawer.Header onHideClick={toggleOpen} />
				<Drawer.Content>
					<Drawer.ContentSection title={t(`${prefix}date`)}>
						<Date filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection title={t(`${prefix}filters`)}>
						<Grid container spacing={1}>
							<Grid item>
								<Categories filters={filters} updateFilters={setFilters} />
							</Grid>
							<Grid item>
								<Varieties
									filters={filters}
									updateFilters={setFilters}
									paramName="fieldVarieties"
								/>
							</Grid>
							<Grid item>
								<TeamMembers filters={filters} updateFilters={setFilters} />
							</Grid>
						</Grid>
					</Drawer.ContentSection>
					<Drawer.ContentSection title={t(`${prefix}fields`)}>
						<FieldsList filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
				</Drawer.Content>
				<Drawer.Footer>
					<Button variant={'text'} onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={onSave}>{t('buttons.save')}</Button>
				</Drawer.Footer>
			</Drawer.Container>
		</>
	);
};

export default FilterModal;
