import React, { ReactElement } from 'react';

import MUIBreadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';

import ChevronRightIcon from '../../icons/ChevronRight';

const Breadcrumbs = (props: BreadcrumbsProps): ReactElement => {
	const { children, separator, ...MUIBreadcrumbsProps } = props;

	const defaultSeparator = (
		<ChevronRightIcon color="secondary" style={{ marginBottom: -4 }} />
	);

	return (
		<MUIBreadcrumbs
			separator={separator || defaultSeparator}
			aria-label="breadcrumb"
			{...MUIBreadcrumbsProps}
		>
			{children}
		</MUIBreadcrumbs>
	);
};

export default Breadcrumbs;
