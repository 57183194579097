import { AreaUnits } from 'Models/units';
import _uniq from 'lodash/uniq';

import { TCropSummaryCategory } from '../../../utils/getSummarySectionData';

type TProps = {
	varieties: TCropSummaryCategory[];
	areaUnit: AreaUnits;
	category: string;
};

const sumOtherVarieties = ({ varieties, areaUnit, category }: TProps) => {
	let areaSize = 0;
	let fieldIds = [] as string[];

	varieties.forEach((variety) => {
		areaSize = areaSize + variety.areaSize;
		fieldIds = _uniq([...fieldIds, ...variety.fieldIds]);
	});

	return {
		category,
		areaSize,
		areaUnit,
		fieldIds,
	};
};

export default sumOtherVarieties;
