import { TTranslateFunction } from 'Features/localization';
import { TJobExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import getVarietiesSowDate from 'Models/fields/getVarietiesSowDate';
import { THarvest } from 'Models/harvest/types/Harvest';
import { TJobTreatment } from 'Models/jobs/isJobTreatment';
import { TPartnerJob } from 'Models/jobs/isPartnerJob';
import jobActiveIngredient from 'Models/jobs/jobActiveIngredient';
import jobArea from 'Models/jobs/jobArea';
import jobAuthorNameString from 'Models/jobs/jobAuthorNameString';
import jobCategoriesString from 'Models/jobs/jobCategoriesString';
import jobCauseCategory from 'Models/jobs/jobCauseCategory';
import jobCausesString from 'Models/jobs/jobCausesString';
import jobCropVarieties from 'Models/jobs/jobCropVarieties';
import jobDriverEquipmentString from 'Models/jobs/jobDriverEquipmentString';
import jobDriversString from 'Models/jobs/jobDriversString';
import jobDurationTime from 'Models/jobs/jobDurationTime';
import jobFieldsNamesString from 'Models/jobs/jobFieldsNamesString';
import jobFinishedFullDateString from 'Models/jobs/jobFinishedFullDateString';
import jobHarvestsDateString from 'Models/jobs/jobHarvestsDateString';
import jobNotes, { jobNotesHeaders } from 'Models/jobs/jobNotes';
import jobStartedFullDateString from 'Models/jobs/jobStartedFullDateString';
import jobTotalUsedProduct from 'Models/jobs/jobTotalUsedProduct';
import jobTreatment from 'Models/jobs/jobTreatment';
import jobWaterConsumption from 'Models/jobs/jobWaterConsumption';
import jobWaterConsumptionPerArea from 'Models/jobs/jobWaterConsumptionPerArea';
import jobWeather from 'Models/jobs/jobWeather';
import locationUnit from 'Models/locations/locationUnit';
import { AreaUnits } from 'Models/units';
// import { JobDistanceUnits } from 'Packages/models/units';
import { toDisplayString } from 'Models/units/transpileUnits';

import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

type THeaderColumn = Record<string, string>;
type TValuesColumn = Record<string, string | number>;

type TGetRowDataOptions = {
	job: TPartnerJob;
	// unit: JobDistanceUnits;
	dictionary: TJobExportHeadersDictionary;
	treatment?: TJobTreatment;
	harvests?: THarvest[];
	isImperial: boolean;
	t: TTranslateFunction;
};

function getRowData(options: TGetRowDataOptions): TValuesColumn {
	const {
		job,
		// unit,
		dictionary,
		treatment,
		harvests,
		isImperial,
		t,
	} = options;
	const {
		jobStarted,
		jobFinished,
		duration,
		jobType,
		category,
		location,
		cropVarieties,
		fields,
		area,
		areaUnit,
		// distance,
		causes,
		causeCategory,
		products,
		activeIngredient,
		applicationRate,
		applicationRateUnit,
		fracNumber,
		totalUsedProduct,
		totalUsedProductUnit,
		status,
		plannedBy,
		operator,
		equipment,
		waterConsumption,
		waterConsumptionUnit,
		waterConsumptionPerArea,
		waterConsumptionPerAreaUnit,
		harvestsDate,
		sowDate,
		weather,
		comment,
	} = dictionary;

	const locationAreaUnit = { areaUnit: AreaUnits.HECTARE };

	const row = {
		[jobStarted]: jobStartedFullDateString(job),
		[jobFinished]: jobFinishedFullDateString(job),
		[duration]: jobDurationTime(job),
		[jobType]: jobTreatment(job),
		[category]: jobCategoriesString(job, t),
		[cropVarieties]: jobCropVarieties(job),
		[location]: job.location.name || '',
		[fields]: jobFieldsNamesString(job),
		[area]: String(jobArea(job, locationAreaUnit)),
		[areaUnit]: locationUnit(locationAreaUnit),
		// Disabled until the trackRaw field returns to the page query.
		// [distance]: jobDistanceString(job, unit),
		[causes]: jobCausesString(job),
		[causeCategory]: jobCauseCategory(job),
		[products]: treatment?.originalProduct?.name || '',
		[activeIngredient]: jobActiveIngredient(treatment),
		[applicationRate]: treatment?.amount || '-',
		[applicationRateUnit]: treatment?.unit
			? toDisplayString(treatment.unit)
			: '-',
		[fracNumber]: treatment?.originalProduct?.productSpec?.fracNumber || '-',
		[totalUsedProduct]: jobTotalUsedProduct(job, treatment).amount,
		[totalUsedProductUnit]: jobTotalUsedProduct(job, treatment).unit,
		[status]: stringToFirstUppercase(job.jobStatus),
		[plannedBy]: jobAuthorNameString(job),
		[operator]: jobDriversString(job),
		[equipment]: jobDriverEquipmentString(job),
		[waterConsumption]: jobWaterConsumption(job, locationAreaUnit).amount,
		[waterConsumptionUnit]: jobWaterConsumption(job, locationAreaUnit).unit,
		[waterConsumptionPerArea]: jobWaterConsumptionPerArea(job, isImperial)
			.amount,
		[waterConsumptionPerAreaUnit]: jobWaterConsumptionPerArea(job, isImperial)
			.unit,
		[weather]: jobWeather(job, isImperial),
		...jobNotes(job, comment),
	};

	return harvests?.length
		? {
				...row,
				[harvestsDate]: jobHarvestsDateString(job, harvests),
				[sowDate]: getVarietiesSowDate(job.jobFields?.[0].varieties),
		  }
		: row;
}

function getHeaders(
	dictionary: TJobExportHeadersDictionary,
	withHarvest?: boolean,
	maxNotesLength?: number
): THeaderColumn {
	const {
		jobStarted,
		jobFinished,
		duration,
		jobType,
		category,
		location,
		cropVarieties,
		fields,
		area,
		areaUnit,
		// distance,
		causes,
		causeCategory,
		products,
		productName,
		activeIngredient,
		applicationRate,
		applicationRateUnit,
		fracNumber,
		totalUsedProduct,
		totalUsedProductUnit,
		status,
		plannedBy,
		operator,
		equipment,
		waterConsumption,
		waterConsumptionUnit,
		waterConsumptionPerArea,
		waterConsumptionPerAreaUnit,
		harvestsDate,
		weather,
		comment,
	} = dictionary;

	const headers = {
		[jobStarted]: '',
		[jobFinished]: '',
		[duration]: '',
		[jobType]: '',
		[category]: '',
		[cropVarieties]: '',
		[location]: '',
		[fields]: '',
		[area]: '',
		[areaUnit]: '',
		// Disabled until the trackRaw field returns to the page query.
		// [distance]: '',
		[causes]: '',
		[causeCategory]: '',
		[products]: productName,
		[activeIngredient]: activeIngredient,
		[applicationRate]: applicationRate,
		[applicationRateUnit]: applicationRateUnit,
		[fracNumber]: fracNumber,
		[totalUsedProduct]: totalUsedProduct,
		[totalUsedProductUnit]: totalUsedProductUnit,
		[status]: '',
		[plannedBy]: '',
		[operator]: '',
		[equipment]: '',
		[waterConsumption]: '',
		[waterConsumptionUnit]: waterConsumptionUnit,
		[waterConsumptionPerArea]: '',
		[waterConsumptionPerAreaUnit]: waterConsumptionPerAreaUnit,
		[weather]: '',
		...jobNotesHeaders(comment, maxNotesLength),
	};

	return withHarvest
		? {
				...headers,
				[harvestsDate]: '',
		  }
		: headers;
}

export { getHeaders, getRowData };
export type { TValuesColumn, THeaderColumn };
