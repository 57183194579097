import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { ProductTreatmentInputSchema } from 'Models/products/ProductTreatmentInput';
import Button from 'UI/inputs/Button';

import useSpanishPartner from 'Hooks/useSpanishPartner';

import {
	addTreatmentPageState,
	govTreatmentsInputState,
	treatmentInputState,
	treatmentInputValidationError,
} from '../state';
import {
	AddTreatmentModalStatus,
	isAddTreatmentModalInput,
	isSpanishProductDetails,
} from '../types';

type TNextButtonProps = {
	onSave: () => void;
};

function NextButton(props: TNextButtonProps) {
	const { t } = useTranslation();
	const { onSave } = props;
	const state = useReactiveVar(addTreatmentPageState);
	const treatmentInput = useReactiveVar(treatmentInputState);
	const govTreatmentsInput = useReactiveVar(govTreatmentsInputState);
	const { isSpanishPartner } = useSpanishPartner();
	const { status, input } = state;
	const isDisabled =
		!input.selectedGovernmentProduct &&
		isSpanishPartner &&
		status === AddTreatmentModalStatus.SET_PRODUCT_DETAILS
			? !isSpanishProductDetails(state)
			: status === AddTreatmentModalStatus.SELECT_GOV_TREATMENTS
			? !govTreatmentsInput?.length
			: !isAddTreatmentModalInput(state);

	const nextHandler = () => {
		switch (status) {
			case AddTreatmentModalStatus.SELECT_PRODUCT: {
				if (state.input.product.governmentProductId) {
					addTreatmentPageState({
						...state,
						status: AddTreatmentModalStatus.SELECT_GOV_TREATMENTS,
						editedCategoryName: undefined,
					});
				} else {
					addTreatmentPageState({
						...state,
						status: AddTreatmentModalStatus.SET_PRODUCT_DETAILS,
						editedCategoryName: undefined,
					});
				}
				break;
			}
			case AddTreatmentModalStatus.SET_PRODUCT_DETAILS: {
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.SET_CROP_CATEGORIES,
				});
				break;
			}
			case AddTreatmentModalStatus.SELECT_CROP_CATEGORY: {
				if (state.editedCategoryName) {
					addTreatmentPageState({
						...state,
						editedCategoryName: state.editedCategoryName,
						status: AddTreatmentModalStatus.TREATMENT_DETAILS,
					});
				}
				break;
			}
			case AddTreatmentModalStatus.TREATMENT_DETAILS: {
				if (treatmentInput) {
					//validate input
					const validationResult =
						ProductTreatmentInputSchema.safeParse(treatmentInput);

					if (validationResult.success) {
						const treatments = state.input.product.treatments || [];
						const treatmentExists = treatments.some(
							(treatment) => treatment.category === state.editedCategoryName
						);

						const updatedTreatments = treatmentExists
							? treatments.map((treatment) =>
									treatment.category === state.editedCategoryName
										? treatmentInput
										: treatment
							  )
							: [...treatments, treatmentInput];

						addTreatmentPageState({
							status: AddTreatmentModalStatus.SET_CROP_CATEGORIES,
							editedCategoryName: undefined,
							input: {
								...state.input,
								product: {
									...state.input.product,
									treatments: updatedTreatments,
								},
							},
						});
						treatmentInputState(undefined);
						treatmentInputValidationError(undefined);
					} else {
						treatmentInputValidationError(
							'error' in validationResult ? validationResult.error : undefined
						);
					}
				}
				break;
			}
			case AddTreatmentModalStatus.SET_CROP_CATEGORIES: {
				// product must have at least one treatment - see docs: Creating libraries pt. 6.
				if (state.input.product.treatments?.length) {
					onSave();
				}

				break;
			}
			case AddTreatmentModalStatus.SELECT_GOV_TREATMENTS: {
				onSave();
				break;
			}
		}
	};

	return (
		<Button variant="outlined" onClick={nextHandler} disabled={isDisabled}>
			{[
				AddTreatmentModalStatus.SELECT_GOV_TREATMENTS,
				AddTreatmentModalStatus.SET_CROP_CATEGORIES,
			].includes(status)
				? t('buttons.save')
				: t('buttons.next')}
		</Button>
	);
}

export default NextButton;
