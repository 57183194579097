import { TTranslateFunction } from '../../features/localization';
import { JobTypes } from './JobTypes';
import { TCustomJobType } from './isCustomJob';

export type TJobForTypeIngForm = {
	jobType: string;
	customJobType?: Partial<TCustomJobType> | null;
};

function jobTypeIngForm(
	job: TJobForTypeIngForm,
	t: TTranslateFunction
): string {
	const { jobType } = job;
	let formattedJobType;

	switch (jobType) {
		case JobTypes.SPRAYING:
			formattedJobType = jobType.toLowerCase();
			break;
		case JobTypes.FERTIGATE:
			formattedJobType = t('jobTypes.fertigating');
			break;
		case JobTypes.FERTILIZER:
			formattedJobType = t('jobTypes.fertilizing');
			break;
		case JobTypes.SCOUTING:
			formattedJobType = t('jobTypes.scouting');
			break;
		case JobTypes.MULTI_LOCATION:
			formattedJobType = t('jobTypes.multiLocation');
			break;
		case JobTypes.CUSTOM:
			formattedJobType =
				job.customJobType && job.customJobType.name
					? job.customJobType.name.toLowerCase()
					: t('jobTypes.defaultCustomJobName');
			break;
		default:
			formattedJobType = t('jobTypes.defaultCustomJobName');
			break;
	}

	return formattedJobType;
}

export default jobTypeIngForm;
