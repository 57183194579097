import { gql, TypedDocumentNode } from '@apollo/client';
import { TTeam } from 'Models/teams/isTeam';

export type TGetTeamsResult = TTeam[];

const GET_TEAMS: TypedDocumentNode<
	{
		getTeams: TGetTeamsResult;
	},
	{
		locationId: string;
	}
> = gql`
	query getTeamsPageData($locationId: ID!) {
		getTeams(locationId: $locationId) {
			id
			name
			teamLead {
				id
				firstName
				lastName
				profileImage {
					id
					path
					filename
					mimetype
					createdAt
				}
			}
			hasTeamLeadUnclosedTimesheets
			teamMembers {
				id
				customId
				fullName
				position
				hasUnclosedTimesheets
			}
		}
	}
`;

export default GET_TEAMS;
