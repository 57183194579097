import React from 'react';

import Card from 'UI/surfaces/Card';

import ContentBody from './ContentBody';
import ContentHeader from './ContentHeader';

const PageContent = () => (
	<Card>
		<ContentHeader />
		<ContentBody />
	</Card>
);

export default PageContent;
