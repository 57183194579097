import React from 'react';

import MUITabs, { TabsProps } from '@mui/material/Tabs';

import './styles.scss';

export type TContainer = TabsProps;

const Tabs = (props: TContainer) => (
	<MUITabs
		aria-label="sidebar"
		className="sidebar"
		orientation="vertical"
		{...props}
	>
		{props.children}
	</MUITabs>
);
export default Tabs;
