import useParams from 'Features/router/hooks/useParams';
import ParamTypes from 'Models/history/paramTypes';

function useLocationID(): ParamTypes['locationID'] {
	const { locationID } = useParams();

	return locationID || '';
}

export default useLocationID;
