import React, { ReactElement } from 'react';

import getCompanyIdFromURL from 'Utils/getCompanyIdFromURL';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';

import Navbar from '../Navbar';
import LocationNavigationItems from './components/LocationNavigationItems';
import LocationSettingsDropdown from './components/LocationSettingsDropdown';
import PartnerNavigationItems from './components/PartnerNavigationItems';
import PartnerSettingsDropdown from './components/PartnerSettingsDropdown';
import UserProfileDropdown from './components/UserProfileDropdown';

const FarmableNavbar = (): ReactElement => {
	const locationId = getLocationIdFromUrl();
	const companyID = getCompanyIdFromURL();

	if (companyID) {
		return (
			<Navbar
				leftPlaceholder={<PartnerSettingsDropdown />}
				centerPlaceholder={<PartnerNavigationItems />}
				rightPlaceholder={<UserProfileDropdown />}
			/>
		);
	}

	if (locationId) {
		return (
			<Navbar
				leftPlaceholder={<LocationSettingsDropdown />}
				centerPlaceholder={<LocationNavigationItems />}
				rightPlaceholder={<UserProfileDropdown />}
			/>
		);
	}

	return (
		<Navbar
			leftPlaceholder={<></>}
			centerPlaceholder={<></>}
			rightPlaceholder={<UserProfileDropdown />}
		/>
	);
};

export default FarmableNavbar;
