import { TLocation } from '../locations/isLocation';
import { TJob } from './isJob';
import jobWaterConsumption from './jobWaterConsumption';

function jobWaterConsumptionString(
	job: Pick<TJob, 'driversWithEquipmentConfig' | 'jobFields'>,
	location?: Pick<TLocation, 'areaUnit'> | null,
	fieldIDs?: string[]
) {
	const { amount, unit } = jobWaterConsumption(job, location, fieldIDs);

	if (amount === 'N/A') return 'N/A';

	return `${amount} ${unit}`;
}

export default jobWaterConsumptionString;
