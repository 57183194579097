import React, { ReactElement } from 'react';

import Box from 'UI/layout/Box';
import Container from 'UI/layout/Container';
import Card from 'UI/surfaces/Card';

import ErrorBoundary from '../../../components/ErrorBoundary';
import PlannerChart from './components/PlannerChart';
import PlannerHeader from './components/PlannerHeader';
import SeasonDropdown from './components/SeasonDropdown';
import PlannerJobDetailsModal from './components/modals/DetailsModal';

function PlannerPage(): ReactElement {
	return (
		<Container
			sx={{
				height: '100%',
				overflow: 'auto',
				paddingBottom: '2rem',
			}}
		>
			<ErrorBoundary>
				<Box maxWidth="77.5rem" margin="auto" width="100%" paddingTop="1.5rem">
					<SeasonDropdown />
					<Card
						sx={{
							padding: '1rem 1.5rem',
							marginTop: '1.5rem',
						}}
					>
						<PlannerHeader />
						<ErrorBoundary withinComponent>
							<PlannerChart />
						</ErrorBoundary>
						<PlannerJobDetailsModal />
					</Card>
				</Box>
			</ErrorBoundary>
		</Container>
	);
}

export default PlannerPage;
