import { TLocations } from './isLocations';

type TLocation = Omit<TLocations, 'invitedBy'>;

function isAdvisor(location: TLocation): boolean {
	return location.relation ? location.relation.includes('ADVISOR') : false;
}

function compareByRole(a: TLocation, b: TLocation) {
	const first = isAdvisor(a) ? 0 : 1;
	const second = isAdvisor(b) ? 0 : 1;

	return first - second;
}

function sortByRole(locations: TLocation[]): TLocation[] {
	const locationsCopy = [...locations];
	return locationsCopy.sort(compareByRole);
}

export default sortByRole;
