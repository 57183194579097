import React from 'react';

import Box from 'UI/layout/Box';

import { TCropSummaryVariety } from '../../../FieldDashboardPage/components/CropSummary/utils/getSummarySectionData';
import { TCropSummaryCategory } from '../../utils/getSummarySectionData';
import Chart from './components/Chart';
import VarietiesList from './components/VarietiesList';

export type TCropSummaryProps = {
	varieties: TCropSummaryCategory[] | TCropSummaryVariety[];
};

export default function CropSummary(props: TCropSummaryProps) {
	const { varieties } = props;

	return (
		<Box display="flex" flexDirection="column" data-testid="crop-summary">
			<Chart varieties={varieties} />
			<VarietiesList varieties={varieties} />
		</Box>
	);
}
