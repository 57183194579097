import format from 'date-fns/format';

import { THarvest } from '../harvest/types/Harvest';
import { TJob } from './isJob';

function harvestsByDate(a: THarvest, b: THarvest) {
	return Date.parse(a.date) - Date.parse(b.date);
}

const DEFAULT_FORMAT = 'dd/MM/yyyy';

const jobHarvestsDateString = (job: TJob, harvests: THarvest[]): string => {
	if (harvests.length > 1) {
		const sortedByDate = harvests.sort(harvestsByDate);
		const firstHarvestDateString = format(
			Date.parse(sortedByDate[0].date),
			DEFAULT_FORMAT
		);
		const lastHarvestDateString = format(
			Date.parse(sortedByDate[sortedByDate.length - 1].date),
			DEFAULT_FORMAT
		);

		return `${firstHarvestDateString} - ${lastHarvestDateString}`;
	} else if (harvests.length === 1) {
		return format(Date.parse(harvests[0]?.date), DEFAULT_FORMAT);
	}

	return '-';
};

export default jobHarvestsDateString;
