import { TLocationType } from 'Models/locations/isLocation';

import { TGetLocationSubscriptionsResult } from 'Hooks/useLocationSubscriptions';

import { isPurchasedProduct } from '../../../features/stripe/models/isPurchasedProduct';
import getStripeModules from '../../../features/stripe/utils/getStripeModules';
import { TGetPaymentProductsResult } from '../types';

const SIEX_LOCATIONS_PRODUCTS = [
	'cuaderno_digital_siex',
	'product_inventory',
	'sales_management',
	'teams_and_timesheets',
];

const GLOBAL_LOCATIONS_PRODUCTS = [
	'farmable_pro',
	'product_inventory',
	'sales_management',
	'teams_and_timesheets',
];

type TProps = {
	products: TGetPaymentProductsResult;
	subscriptions: TGetLocationSubscriptionsResult;
	countryCode: string;
	locationType?: TLocationType;
};

function getAvailableProducts(props: TProps) {
	const { products, subscriptions, countryCode, locationType } = props;
	const stripeModules = getStripeModules();
	const productIds = Object.keys(stripeModules);

	const mappedProducts = products
		.filter((product) => productIds.includes(product.id))
		.map((product) => {
			const stripeModule = stripeModules[product.id];
			return {
				...product,
				isPurchased: isPurchasedProduct(
					stripeModule.features,
					subscriptions.featureFlags
				),
				name: stripeModule.names[countryCode] || product.name,
				description:
					stripeModule.descriptions[countryCode] || product.description,
				images: stripeModule.icon ? [stripeModule.icon] : product.images,
				productFlag: stripeModule.id,
				features: stripeModule.features || [],
			};
		});

	type TMappedProducts = typeof mappedProducts;

	const availableModules =
		locationType === 'SIEX'
			? SIEX_LOCATIONS_PRODUCTS
			: GLOBAL_LOCATIONS_PRODUCTS;

	return availableModules.reduce((result: TMappedProducts, product: string) => {
		const matchedProduct = mappedProducts.find(
			(pr) => pr.productFlag === product
		);
		if (matchedProduct) {
			result.push(matchedProduct);
		}
		return result;
	}, []);
}

export default getAvailableProducts;
