import React from 'react';

import { Trans } from 'Features/localization/components/Trans';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getAccountName from 'Models/account/getAccountName';
import { TAccount } from 'Models/account/isAccount';
import { TNotificationType } from 'Models/notification/isNotificationType';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import { makeStyles } from 'UI/utils/makeStyles';
import { formatDistanceToNow } from 'date-fns';

import UTCToLocalTime from 'Utils/UTCToLocalTime';
import getDateFnsLocale from 'Utils/getDateFnsLocale';

import Event from './components/Event';

// todo: update type after backend has finished migration #3268
type TRecentEventItemProps = {
	account: TAccount;
	action: TNotificationType;
	fields?: string;
	createdAt: string;
	location?: string;
};

const useStyles = makeStyles({
	fields: {
		fontWeight: 'bold',
		textOverflow: 'ellipsis',
		textWrap: 'nowrap',
		overflow: 'hidden',
	},
});

const RecentEvent = (props: TRecentEventItemProps) => {
	const { i18n } = useTranslation();
	const { account, action, createdAt, location, fields } = props;
	const styles = useStyles();
	const name = getAccountName(account);
	const nameFirstLetter = name?.charAt(0).toUpperCase();

	const avatar = (
		<Avatar aria-label="recipe" size="sm" src={account.profileImage?.path}>
			{nameFirstLetter}
		</Avatar>
	);

	const dateFnsOptions = {
		locale: getDateFnsLocale(i18n.language),
		addSuffix: true,
	};

	const createdAtLocalTime = UTCToLocalTime(String(createdAt));
	const formattedCreatedAt = formatDistanceToNow(
		createdAtLocalTime,
		dateFnsOptions
	);

	const title = (
		<Trans
			i18nKey={`notifications.${action}`}
			values={{
				user: name,
				fields: location || fields,
			}}
			components={{
				bold: <div className={styles.fields} title={location || fields} />,
			}}
		/>
	);

	const subheader = <Text variant="secondaryBody3">{formattedCreatedAt}</Text>;

	return (
		<Event
			avatar={avatar}
			avatarOrientation="right"
			title={title}
			subheader={subheader}
		/>
	);
};

export default RecentEvent;
