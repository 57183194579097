import { z } from 'zod';

import {
	ProductIngredientInputSchema,
	TProductIngredientInput,
	TSanitizedProductIngredientInput,
} from './ProductIngredientInput';
import { ProductSpecInputSchema, TProductSpecInput } from './ProductSpecInput';
import {
	ProductTreatmentInputSchema,
	TProductTreatmentInput,
} from './ProductTreatmentInput';

export const ProductType = {
	FERTILIZER: 'FERTILIZER',
	OTHER: 'OTHER',
} as const;

export const ProductTypes = [ProductType.FERTILIZER, ProductType.OTHER];

export type TProductType = typeof ProductTypes[number];

export const ProductInputSchema = z.object({
	name: z.string().min(1),
	treatments: z.array(ProductTreatmentInputSchema).min(1),
	productSpec: ProductSpecInputSchema.optional(),
	ingredients: ProductIngredientInputSchema.array().optional(),
	type: z
		.literal(ProductType.FERTILIZER)
		.or(z.literal(ProductType.OTHER))
		.optional(),
});

export type TProductInput = {
	name: string;
	treatments: TProductTreatmentInput[];
	productSpec?: TProductSpecInput;
	ingredients?: TProductIngredientInput[];
	type?: TProductType;
};

export const isProductInput = (input: unknown): input is TProductInput =>
	ProductInputSchema.safeParse(input).success;

export type TProductInputPartial = Partial<TProductInput>;

export type TSanitizedProductInput = {
	name: string;
	treatments: TProductTreatmentInput[];
	productSpec?: TProductSpecInput;
	ingredients?: TSanitizedProductIngredientInput[];
	type?: TProductType;
};
