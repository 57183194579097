import { TTimesheet } from './isTimesheet';

const MS_IN_ONE_MINUTE = 60 * 1000;

function timesheetDurationMs(
	timesheet: Pick<TTimesheet, 'endTime' | 'timesheetFieldHours'>
): number | null {
	const { endTime, timesheetFieldHours } = timesheet;

	if (endTime && timesheetFieldHours?.length) {
		return (
			timesheetFieldHours.reduce(
				(totalDuration, currentFieldHour) =>
					totalDuration + currentFieldHour.duration,
				0
			) * MS_IN_ONE_MINUTE
		);
	}

	return null;
}

export default timesheetDurationMs;
