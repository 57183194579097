import { TLocation } from '../locations/isLocation';
import { toDisplayString } from '../units/transpileUnits';
import { TJobFieldSnapshot } from './isJobFieldSnapshot';
import jobArea from './jobArea';

function jobAreaWithUnit(
	job: {
		jobFields?: Pick<TJobFieldSnapshot, 'id' | 'name' | 'areaSize'>[] | null;
	},
	location: Pick<TLocation, 'areaUnit'>
): string | JSX.Element {
	const area = String(jobArea(job, location));

	if (location.areaUnit) {
		// this character is &nbsp;
		return area + ' ' + toDisplayString(location.areaUnit);
	}

	return area;
}

export default jobAreaWithUnit;
