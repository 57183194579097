import {
	TFieldLogsTableJobs,
	TFieldLogsTableField,
} from '../components/FieldLogsTable';

type TFieldLogs = {
	field: TFieldLogsTableField;
	jobs: TFieldLogsTableJobs;
};

const getFieldLogsFromJobs = (jobs: TFieldLogsTableJobs): TFieldLogs[] => {
	const fields = jobs.reduce<Record<string, TFieldLogs>>((aggr, job) => {
		if (!job.jobFields) {
			return aggr;
		}

		return job.jobFields.reduce((ag, field) => {
			let entry = ag[field.id];
			if (!entry) {
				entry = {
					field,
					jobs: [job],
				};
			} else {
				entry.jobs.push(job);
			}
			ag[field.id] = entry;

			return ag;
		}, aggr);
	}, {});

	return Object.values(fields).sort((a, b) =>
		b.field.name && a.field.name ? a.field.name.localeCompare(b.field.name) : 0
	);
};

export default getFieldLogsFromJobs;
