import React from 'react';

import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import usePartnerCompany from 'Hooks/usePartnerCompany';

function CompanyName() {
	const { company } = usePartnerCompany();

	return (
		<Box padding="1rem 1.5rem">
			<Text variant="label2">{company?.name}</Text>
		</Box>
	);
}

export default CompanyName;
