import React, { ReactElement } from 'react';

import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';
import MuiTextField from '@mui/material/TextField';

type TTextArea = {
	label?: string;
	placeholder?: string;
	rows?: number;
	rowsMax?: number;
	value?: string;
	fullWidth?: boolean;
	onChange?: (newValue: string, value?: string) => void;
	helperText?: string;
	disabled?: boolean;
	inputProps?: Partial<OutlinedInputProps>;
};

const TextArea = (props: TTextArea): ReactElement => {
	const {
		rows,
		placeholder,
		label,
		onChange,
		value,
		fullWidth,
		helperText,
		rowsMax,
		disabled,
		inputProps,
	} = props;
	const DEFAULT_ROWS_VALUE = 6;
	const DEFAULT_ROWS_MAX = 12;
	const DEFAULT_INPUT_PROPS = { sx: { p: 0 } };

	const onChangeEvent = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValue = event.target.value;
		if (onChange) {
			onChange(newValue, value);
		}
	};

	return (
		<MuiTextField
			fullWidth={fullWidth !== false}
			color={'primary'}
			label={label}
			multiline
			onChange={onChangeEvent}
			placeholder={placeholder}
			rows={rows || DEFAULT_ROWS_VALUE}
			maxRows={rowsMax || DEFAULT_ROWS_MAX}
			value={value}
			variant="outlined"
			disabled={disabled}
			helperText={helperText || undefined}
			InputProps={inputProps || DEFAULT_INPUT_PROPS}
		/>
	);
};

export default TextArea;
