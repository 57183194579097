import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import LogsTableHeaderValues from 'Models/jobs/jobLogsTableData';
import Table from 'UI/display/Table';

import { TPartnerJobTableData } from '../api/getPartnerJobsTableData';
import { detailsModalData, isDetailsModalOpen } from '../state';
import getPartnerJobRowData from '../utils/getPartnerJobRowData';

type TJobLogsTableRowProps = {
	job: TPartnerJobTableData;
};

const TableRow = (props: TJobLogsTableRowProps) => {
	const { job } = props;
	const { t } = useTranslation();

	function onRowClick() {
		isDetailsModalOpen(true);
		detailsModalData(job);
	}

	return (
		<Table.Row hover sx={{ cursor: 'pointer' }} onClick={onRowClick}>
			{getPartnerJobRowData(job, t).map((cell, index) => (
				<Table.Cell key={`cell-${LogsTableHeaderValues[index] || index}`}>
					{cell}
				</Table.Cell>
			))}
		</Table.Row>
	);
};

export default TableRow;
