import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TLocation } from 'Models/locations/isLocation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Card from 'UI/surfaces/Card';

import useManageHistory from 'Hooks/useManageHistory';

import getFieldLogsFromJobs from '../utils/getFieldLogsFromJobs';
import FieldLogsTable, { TFieldLogsTableJobs } from './FieldLogsTable';

type TFieldLogs = {
	jobs: TFieldLogsTableJobs | null;
	location?: Pick<TLocation, 'areaUnit'> | null;
};

const FieldLogs = (props: TFieldLogs) => {
	const { jobs, location } = props;
	const { t } = useTranslation();
	const manageHistory = useManageHistory();
	const filters = manageHistory.paramsFromURL.filters;

	const fieldLogs = getFieldLogsFromJobs(jobs || []).filter((fieldLog) => {
		return filters.fieldIds?.length
			? filters.fieldIds.includes(fieldLog.field.id)
			: true;
	});

	return (
		<Box>
			{fieldLogs.length ? (
				fieldLogs.map(({ field, jobs }) => (
					<Card
						key={field.id}
						sx={{
							marginY: '1.5rem',
							padding: '1rem',
						}}
					>
						<FieldLogsTable field={field} jobs={jobs} location={location} />
					</Card>
				))
			) : (
				<Text variant="body2">
					{t('treatmentLogsExportPage.sprayLog.noJobs')}
				</Text>
			)}
		</Box>
	);
};

export default FieldLogs;
