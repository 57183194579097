import React, { useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCustomer } from 'Models/customers/isCustomer';
import { TCustomerInput } from 'Models/customers/isCustomerInput';
import Text from 'UI/display/Text';
import Menu from 'UI/navigation/Menu';
import { enqueueSnackbar } from 'notistack';

import useLocationID from 'Hooks/useLocationID';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import throwErrorSilently from 'Utils/throwErrorSilently';

import { TSelectedFile } from '../../../../components/UploadImage';
import EDIT_CUSTOMERS_DATA from '../api/editCustomerMutation';
import GET_CUSTOMERS_DATA from '../api/getCustomersData';
import UPLOAD_CUSTOMER_IMAGE from '../api/uploadCustomerImage';
import CustomerModalContainer from './CustomerModalContainer';

type TProps = {
	customer: TCustomer;
};

const UpdateCustomerModal = (props: TProps) => {
	const { customer } = props;
	const { t } = useTranslation();
	const locationId = useLocationID();
	const [open, setOpen] = useState(false);

	const [editCustomer, editCustomerTask] = useMutation(EDIT_CUSTOMERS_DATA, {
		refetchQueries: [GET_CUSTOMERS_DATA],
	});
	const [uploadImage, uploadImageTask] = useMutation(UPLOAD_CUSTOMER_IMAGE);

	const toggleOpen = () => setOpen(!open);

	useSnackbarNotifications({
		mutationResult: editCustomerTask,
		messageSuccess: t('customersPage.editCustomerSuccess'),
		messageFailure: t('customersPage.editCustomerFail'),
	});

	const handleSave = (input: TCustomerInput, image?: TSelectedFile) => {
		if (image) {
			void uploadImage({
				variables: {
					locationId,
					file: image.file,
				},
				onCompleted: (data) => {
					const inputWithImage = {
						...input,
						imageName: data.uploadCustomerImage.filename,
					};
					void editCustomer({
						variables: {
							locationId,
							customerId: customer.id,
							input: inputWithImage,
						},
						onCompleted: toggleOpen,
					});
				},
			}).catch((error: ApolloError) => {
				enqueueSnackbar(t('customersPage.editCustomerFail'), {
					variant: 'error',
				});
				toggleOpen();
				throwErrorSilently(error);
			});
		} else {
			void editCustomer({
				variables: {
					locationId,
					customerId: customer.id,
					input,
				},
				onCompleted: toggleOpen,
			});
		}
	};

	const isMutationLoading = editCustomerTask.loading || uploadImageTask.loading;

	return (
		<>
			<Menu.Item onClick={toggleOpen}>
				<Text variant="body2">{t('buttons.editCustomer')}</Text>
			</Menu.Item>
			<CustomerModalContainer
				isModalOpen={open}
				onCloseModal={toggleOpen}
				onSaveHandler={handleSave}
				isMutationLoading={isMutationLoading}
				customer={customer}
			/>
		</>
	);
};

export default UpdateCustomerModal;
