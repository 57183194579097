import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import classicFormat from 'Models/dates/classicFormat';
import getTotalFieldsArea from 'Models/fields/getTotalFieldsArea';
import { TJob } from 'Models/jobs/isJob';
import jobInitialDateEnd from 'Models/jobs/jobInitialDateEnd';
import jobInitialDateStart from 'Models/jobs/jobInitialDateStart';
import { TLocation } from 'Models/locations/isLocation';
import { AreaUnits } from 'Models/units';
import convertAreaUnit from 'Models/units/convertAreaUnit';
import { toDisplayString } from 'Models/units/transpileUnits';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import { parseISO } from 'date-fns';

import useManageHistory from '../../../../hooks/useManageHistory';
import getFieldsFromLocationJobs from '../utils/getFieldsFromLocationJobs';

type TSprayLogFiltersCardJobs = Pick<
	TJob,
	'startDate' | 'completedDate' | 'jobFields'
>;

type TSprayLogFiltersCard = {
	jobs: TSprayLogFiltersCardJobs[];
	location?: Pick<TLocation, 'areaUnit'> | null;
};

const SprayLogFiltersCard = (props: TSprayLogFiltersCard) => {
	const { jobs, location } = props;
	const { t } = useTranslation();
	const manageHistory = useManageHistory();
	const filters = manageHistory.paramsFromURL.filters;
	const fields = jobs
		? getFieldsFromLocationJobs(jobs).filter((field) =>
				filters.fieldIds?.includes(field.id)
		  )
		: [];

	const prefix = 'treatmentLogsExportPage.sprayLog.';

	const areaUnit = location?.areaUnit || AreaUnits.HECTARE;

	const startDate = filters.completedDateFrom
		? parseISO(filters.completedDateFrom)
		: jobInitialDateStart(jobs);
	const endDate = filters.completedDateTo
		? parseISO(filters.completedDateTo)
		: jobInitialDateEnd(jobs);

	const startDateOutput = startDate ? classicFormat(startDate) : '-';
	const endDateOutput = endDate ? classicFormat(endDate) : '-';

	const areaSize = fields
		? convertAreaUnit(getTotalFieldsArea(fields), areaUnit)
		: 0;

	const fieldText = t('labels.field', {
		count: fields.length,
	}).toLowerCase();

	return (
		<Card
			sx={{
				height: '100%',
			}}
		>
			<Grid container padding="1.5rem">
				<Grid item xs={3}>
					{filters.season && <Text variant="body2">{t('labels.season')}:</Text>}
					<Text variant="body2">{t(`${prefix}periodFrom`)}:</Text>
					<Text variant="body2">{t(`${prefix}periodTo`)}:</Text>
					<Text variant="body2">{t(`${prefix}area`)}:</Text>
				</Grid>
				<Grid item xs={9}>
					{filters.season && <Text variant="body2">{filters.season}</Text>}
					<Text variant="body2">{startDateOutput}</Text>
					<Text variant="body2">{endDateOutput}</Text>
					<Text variant="body2">
						{fields.length} {fieldText} ({areaSize} {toDisplayString(areaUnit)})
					</Text>
				</Grid>
			</Grid>
		</Card>
	);
};

export default SprayLogFiltersCard;
