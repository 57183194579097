import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';
import { TProduct } from 'Models/products/Product';
import { TSanitizedProductInput } from 'Models/products/ProductInput';

import { treatmentLibraryProductFragmentFields } from './fragments/productFragment';
import { GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS } from './getTreatmentLibraryProducts';

type TCreateTreatmentLibraryProductResult = {
	createTreatmentLibraryProduct: TProduct;
};

type TCreateTreatmentLibraryProductInput = {
	libraryId: string;
	input: TSanitizedProductInput;
};

export const CREATE_TREATMENT_LIBRARY_PRODUCT: TypedDocumentNode<
	TCreateTreatmentLibraryProductResult,
	TCreateTreatmentLibraryProductInput
> = gql`
	mutation createTreatmentLibraryProduct(
		$libraryId: ID!
		$input: ProductInput!
	) {
		createTreatmentLibraryProduct(libraryId: $libraryId, input: $input) {
			${treatmentLibraryProductFragmentFields}
		}
	}
`;

export const addCreatedTreatmentLibraryProductInCache: MutationUpdaterFunction<
	TCreateTreatmentLibraryProductResult,
	TCreateTreatmentLibraryProductInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }, options) => {
	const libraryId = options.variables?.libraryId;
	const query = {
		query: GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS,
		variables: { libraryId: libraryId || '' },
	};

	const existingData = cache.readQuery(query)?.getTreatmentLibraryProducts;

	if (existingData && data) {
		cache.writeQuery({
			...query,
			data: {
				getTreatmentLibraryProducts: {
					...existingData,
					products: [
						...existingData.products,
						data.createTreatmentLibraryProduct,
					],
				},
			},
		});
	}
};
