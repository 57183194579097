import React, { useState, ReactElement, useEffect } from 'react';

import useHistory from 'Features/router/hooks/useHistory';
import { isOrderStatus } from 'Models/history/isURLOrderStatus';
import PaymentCard from 'UI/display/PaymentCard';
import Modal from 'UI/display/modal/dialog';
import URI from 'urijs';

import paramsFromURL from '../../utils/paramsFromURL';
import paramsToURL from '../../utils/paramsToURL';

const OrderStatusModal = (): ReactElement | null => {
	const currentURL = window.location.href;
	const searchParams = paramsFromURL(currentURL);
	const history = useHistory();

	const [isOpen, setIsOpen] = useState(isOrderStatus(searchParams.orderStatus));

	useEffect(() => {
		setIsOpen(isOrderStatus(searchParams.orderStatus));
	}, [currentURL]);

	function handleClose() {
		setIsOpen(false);

		const newURL = paramsToURL(currentURL, {
			...searchParams,
			orderStatus: undefined,
		});

		const uri = new URI(newURL);
		const path = uri.path();
		const search = uri.search();

		history.push(path + search);
	}

	if (isOrderStatus(searchParams.orderStatus)) {
		return (
			<Modal.UncontrolledModal open={isOpen} onClose={handleClose}>
				<Modal.DialogTitle title=" " closeIconHandler={handleClose} />
				<Modal.DialogContent>
					<PaymentCard status={searchParams.orderStatus} />
				</Modal.DialogContent>
			</Modal.UncontrolledModal>
		);
	}

	return null;
};

export default OrderStatusModal;
