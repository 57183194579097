import React, { useEffect } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { THoursExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import { ExportType } from 'Models/exports/ExportType';
import { LogTypes } from 'Models/logs/LogType';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useExportType from 'Hooks/useExportType';
import useManageHistory from 'Hooks/useManageHistory';
import useProfile from 'Hooks/useProfile';

import ExportLoadingSpinner from '../../../common/components/ExportLoadingSpinner';
import LogsExportPageLayout from '../../../common/components/ExportPageLayout';
import handleExport from '../../../common/utils/handleExport';
import GET_HOURS_EXPORT_DATA from '../api/getHoursExportData';
import getExportData from '../utils/getExportData';

const LIMIT = 30;

function ExportToFile() {
	const { paramsFromURL: searchParams } = useManageHistory();
	const { t } = useTranslation();
	const type = useExportType();
	const { profile } = useProfile();
	const location = useCurrentLocation();
	const dictionary: THoursExportHeadersDictionary = t(
		'exportPages.headers.hours',
		{
			returnObjects: true,
		}
	);
	const { data, loading, error, fetchMore } = useQuery(GET_HOURS_EXPORT_DATA, {
		variables: {
			filterBy: {
				timeFrom: searchParams.filters.timeFrom,
				timeTo: searchParams.filters.timeTo,
				teamId: searchParams.filters.teamId,
				fieldIds: searchParams.filters.fieldIds,
				categories: searchParams.filters.categories,
				varieties: searchParams.filters.varieties,
			},
			pageInput: {
				offset: 0,
				limit: LIMIT,
				sortBy: [{ direction: 'DESC', field: 'startTime' }],
			},
			locationId: searchParams.locationID,
		},
	});

	const total = data?.getTimesheets.totalElements || 0;
	const rawTimesheets = data?.getTimesheets.timesheets;
	useEffect(() => {
		if (rawTimesheets && rawTimesheets.length < total) {
			void fetchMore({
				variables: {
					pageInput: {
						sortBy: [{ direction: 'DESC', field: 'startTime' }],
						offset: rawTimesheets.length,
						limit: LIMIT,
					},
				},
			});
		}
	}, [rawTimesheets, fetchMore, total]);

	if (loading || (rawTimesheets && rawTimesheets.length < total)) {
		return <ExportLoadingSpinner />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (!data?.getTimesheets.timesheets.length && total === 0) {
		return (
			<Box padding="1.5rem">
				<Text>{t('labels.noLogsFound')}</Text>
			</Box>
		);
	}
	if (rawTimesheets && profile && location && type) {
		const exportData = getExportData(rawTimesheets, dictionary);
		return (
			<LogsExportPageLayout
				title={t('exportPages.readyTitle', { context: type })}
				downloadTrigger={() =>
					handleExport({
						exportData,
						location: location,
						fileType: type,
						userID: profile.id,
						logType: LogTypes.Hours,
					})
				}
			/>
		);
	}

	return null;
}

export default ExportToFile;
