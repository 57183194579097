import React, { useState, useEffect } from 'react';

import {
	useReactiveVar,
	useMutation,
	useApolloClient,
	ApolloError,
} from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJobTreatmentInput } from 'Models/jobs/JobInput';
import { TPartnerJobTreatment } from 'Models/planner/PartnerJobTreatment';
import { TPartnerPlannerJob } from 'Models/planner/PartnerPlannerJob';
import Modal from 'UI/display/modal/dialog';
import EditIcon from 'UI/icons/Edit';
import IconButton from 'UI/inputs/IconButton';
import { enqueueSnackbar } from 'notistack';

import throwErrorSilently from 'Utils/throwErrorSilently';

import CREATE_PLANNER_JOB_TREATMENT from '../../api/createPlannerJobTreatment';
import DELETE_PLANNER_JOB_TREATMENT from '../../api/deletePlannerJobTreatment';
import EDIT_PLANNER_JOB_MUTATION from '../../api/editPlannerJobMutation';
import EDIT_PLANNER_JOB_TREATMENT from '../../api/editPlannerJobTreatment';
import { selectedJob, selectedJobId } from '../../state';
import getJobInputData from '../../utils/getJobInputData';
import parseJobInputData from '../../utils/parseEditPlannerJobInput';
import ModalContent from './components/ModalContent';
import NextButton from './components/NextButton';
import PreviousButton from './components/PreviousButton';
import { addJobModalState } from './state';
import styles from './styles.module.scss';

type TProps = {
	job: TPartnerPlannerJob | null;
};

function shouldUpdate(
	existingTreatment: TPartnerJobTreatment,
	treatmentInput: TJobTreatmentInput
) {
	return (
		existingTreatment.amount !== treatmentInput.amount ||
		existingTreatment.unit !== treatmentInput.unit ||
		existingTreatment.originalTreatmentId !==
			existingTreatment.originalTreatmentId
	);
}

const EditJobModal = (props: TProps) => {
	const { job } = props;
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const { jobInputData } = useReactiveVar(addJobModalState);
	const jobId = useReactiveVar(selectedJobId);
	const cache = useApolloClient().cache;

	const [updatePartnerPlanner, mutationResult] = useMutation(
		EDIT_PLANNER_JOB_MUTATION
	);

	const [updatePlannerJobTreatment] = useMutation(EDIT_PLANNER_JOB_TREATMENT);
	const [createPlannerJobTreatment] = useMutation(CREATE_PLANNER_JOB_TREATMENT);
	const [deletePlannerJobTreatment] = useMutation(DELETE_PLANNER_JOB_TREATMENT);

	const { loading: isUpdating } = mutationResult;

	useEffect(() => {
		if (job && isOpen) {
			addJobModalState({
				status: 'SET_CAUSE_AND_TREATMENTS',
				jobInputData: getJobInputData(job),
			});
		}
	}, [job, isOpen]);

	if (!jobInputData || !jobId) {
		return null;
	}

	const toggleHandler = () => {
		setOpen(!isOpen);
	};

	const onActionEnd = () => {
		setOpen(false);
		addJobModalState({
			status: 'SET_CAUSE_AND_TREATMENTS',
			jobInputData: undefined,
		});
	};

	const onUpdate = () => {
		const treatmentsToDelete =
			job?.jobDetails.treatments.filter((treatment) => {
				return !jobInputData.jobDetails.treatments.find((inputTreatment) => {
					return inputTreatment.id === treatment.id;
				});
			}) || [];

		const treatmentsToCreate = jobInputData.jobDetails.treatments.filter(
			(treatment) => {
				return !treatment.id;
			}
		);

		const treatmentsToUpdate = jobInputData.jobDetails.treatments.filter(
			(treatmentInput) => {
				const existingTreatment = job?.jobDetails.treatments.find(
					(_treatment) => {
						return _treatment.id === treatmentInput.id;
					}
				);

				return (
					existingTreatment && shouldUpdate(existingTreatment, treatmentInput)
				);
			}
		);

		void updatePartnerPlanner({
			variables: {
				plannerJobId: jobId,
				input: parseJobInputData(jobInputData),
			},
		})
			.catch((error: ApolloError) => {
				onActionEnd();
				enqueueSnackbar(t('errors.editPlannerJobFailed'), {
					variant: 'error',
				});
				throwErrorSilently(error);
			})
			.then(() => {
				return Promise.all([
					...treatmentsToCreate.map((treatment) => {
						return createPlannerJobTreatment({
							variables: {
								plannerJobId: jobId,
								input: {
									amount: treatment.amount,
									originalTreatmentId: treatment.originalTreatmentId,
									unit: treatment.unit,
								},
							},
						});
					}),
					...treatmentsToUpdate.map((treatment) => {
						return updatePlannerJobTreatment({
							variables: {
								plannerJobId: jobId,
								treatmentId: treatment.id || '',
								input: {
									amount: treatment.amount,
									originalTreatmentId: treatment.originalTreatmentId,
									unit: treatment.unit,
								},
							},
						});
					}),
					...treatmentsToDelete.map((treatment) => {
						return deletePlannerJobTreatment({
							variables: {
								plannerJobId: jobId,
								treatmentId: treatment.id,
							},
						});
					}),
				]);
			})
			.catch((error: ApolloError) => {
				onActionEnd();
				enqueueSnackbar(t('errors.editPlannerJobFailed'), {
					variant: 'error',
				});
				throwErrorSilently(error);
			})
			.then(() => {
				onActionEnd();
				enqueueSnackbar(t('info.editPlannerJobSuccess'), {
					variant: 'success',
				});
				const normalizedId = cache.identify({
					id: jobId,
					__typename: 'PartnerPlannerJob',
				});
				cache.evict({ id: normalizedId });
				selectedJob(null);
			});
	};

	const translatedJobType = t(
		`common.jobs.types.${jobInputData.jobDetails.jobType}`
	);

	return (
		<>
			<IconButton onClick={toggleHandler}>
				<EditIcon fontSize="small" />
			</IconButton>
			<Modal.UncontrolledModal toggleOpen={toggleHandler} open={isOpen}>
				<Modal.DialogTitle
					title={
						t('plannerPage.editJobModal.title', {
							jobType: translatedJobType,
							variety: jobInputData.variety.name,
						}) || ''
					}
					closeIconHandler={onActionEnd}
				/>
				<Modal.DialogContent>
					<ModalContent />
				</Modal.DialogContent>
				<Modal.DialogActions className={styles.dialogActions}>
					<PreviousButton isLoading={isUpdating} onClose={onActionEnd} />
					<NextButton
						isLoading={isUpdating}
						actionHandler={onUpdate}
						isEditModal
					/>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default EditJobModal;
