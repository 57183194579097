import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

const Header = () => {
	const { t } = useTranslation();
	return (
		<Box paddingTop={2.25} paddingX={3} paddingBottom={2}>
			<Box paddingBottom={0.5}>
				<Text variant="h3" component="h2">
					{t('farmSalesPage.salesStats.sidebar.header')}
				</Text>
			</Box>
			<Text variant={'secondaryBody2'}>
				{t('farmSalesPage.salesStats.sidebar.description')}
			</Text>
		</Box>
	);
};

export default Header;
