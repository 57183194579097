import React, { ReactElement, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	TTeamInput,
	TTeamInputPartial,
	isTeamInput,
} from 'Models/teams/isTeamInput';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import useLocationID from 'Hooks/useLocationID';

import GET_TEAMS from '../../api/getTeams';
import { editingTeamIdVar, teamInputVar } from '../../state';
import getOverlappingIDs from '../../utils/getOverlappingIDs';
import { resetCache } from '../../utils/getTeamMemberId';
import hasInputDuplicatedIDs, {
	duplicatedIDs,
} from '../../utils/hasInputDuplicatedIDs';
import populateMembersPositions from '../../utils/populateMembersPositions';
import TeamLeadSelect from './components/TeamLeadSelect';
import TeamMembers from './components/TeamMembers';
import TeamNameInput from './components/TeamNameInput';

type TProps = {
	modalTitle: string;
	open: boolean;
	toggleOpen: () => void;
	saveTeam: (input: TTeamInput) => void;
	isLoading: boolean;
};

function isEditing(teamInput: TTeamInputPartial) {
	if (teamInput.teamMemberInput?.length) {
		return teamInput.teamMemberInput.reduce(
			(hasAnyID, member) => Boolean(member.id) || hasAnyID,
			false
		);
	}
	return false;
}

const ManageTeamTemplate = (props: TProps): ReactElement | null => {
	const { toggleOpen, open, modalTitle, saveTeam, isLoading } = props;
	const teamInput = useReactiveVar(teamInputVar);
	const editingTeamId = useReactiveVar(editingTeamIdVar);
	const isValid = isTeamInput(teamInput);
	const [error, setError] = useState<string | null>();
	const { t } = useTranslation();
	const locationId = useLocationID();

	const { data } = useQuery(GET_TEAMS, {
		variables: { locationId },
	});

	const teams = data?.getTeams || [];

	const onSaveTeam = () => {
		setError(null);

		if (data?.getTeams) {
			const overlappingIDs = getOverlappingIDs(teams, teamInput, editingTeamId);

			if (overlappingIDs.length) {
				setError(
					t('errors.overlappingMemberIdentifiers.message', {
						id: overlappingIDs.join(', '),
					})
				);
				return;
			}

			if (hasInputDuplicatedIDs(teamInput)) {
				setError(
					t('errors.conflictingIdentifiers.message', {
						id: duplicatedIDs(teamInput).join(', '),
					})
				);
				return;
			}

			if (isTeamInput(teamInput)) {
				resetCache(teamInput);
				saveTeam({
					...teamInput,
					teamMemberInput: populateMembersPositions(teamInput.teamMemberInput),
				});
			}
		}
	};

	const saveTeamButton = (
		<Button
			disabled={!isValid || isLoading}
			variant="outlined"
			onClick={onSaveTeam}
			startIcon={isLoading ? <LoadingIcon /> : null}
		>
			{t('buttons.save')}
		</Button>
	);

	const onCancel = () => {
		setError(null);
		if (isEditing(teamInput)) {
			resetCache(teamInput);
		}
		toggleOpen();
		teamInputVar({});
	};

	const errorMessage = error ? (
		<Box display="flex" flexDirection="column" mt={1}>
			<Text color="error">{error}</Text>
		</Box>
	) : null;

	return (
		<Modal.UncontrolledModal open={open} toggleOpen={toggleOpen}>
			<Modal.DialogTitle
				title={modalTitle}
				closeIconHandler={toggleOpen}
				closeIcon={false}
			/>
			<Modal.DialogContent sx={{ height: 450 }}>
				<TeamLeadSelect />
				<TeamNameInput />
				<TeamMembers />
				{errorMessage}
			</Modal.DialogContent>
			<Modal.DialogActions>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Button disabled={isLoading} variant="outlined" onClick={onCancel}>
							{t('buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>{saveTeamButton}</Grid>
				</Grid>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};

export default ManageTeamTemplate;
