import React, { ReactElement } from 'react';

import MUIMenu, { MenuProps } from '@mui/material/Menu';

export type TMenu = MenuProps & { narrow?: boolean };

function Menu(props: TMenu): ReactElement {
	const { children, narrow, ...MUIProps } = props;
	const minWidth = narrow ? '4rem' : '12rem';

	return (
		<MUIMenu sx={{ '.MuiMenu-paper': { minWidth } }} {...MUIProps}>
			{children}
		</MUIMenu>
	);
}

export default Menu;
