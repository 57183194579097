import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SignOutIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				d="M3.5 17a1.5 1.5 0 0 1-1.493-1.356L2 15.5v-11a1.5 1.5 0 0 1 1.356-1.493L3.5 3H9a.5.5 0 0 1 .09.992L9 4H3.5a.5.5 0 0 0-.492.41L3 4.5v11a.5.5 0 0 0 .41.492L3.5 16h5a.5.5 0 0 1 .09.992L8.5 17h-5zM13.78 5.589l.069.057 4 4 .037.042.043.062.029.06.02.062.012.056.005.072-.002.053-.014.075-.021.063-.051.093-.046.057-4.012 4.013a.5.5 0 0 1-.765-.638l.058-.07 3.146-3.146H8a.5.5 0 0 1-.09-.992L8 9.5h8.288l-3.146-3.146a.5.5 0 0 1-.058-.638l.058-.07a.5.5 0 0 1 .562-.1l.076.043z"
				fillRule="nonzero"
			/>
		</SvgIcon>
	);
}

export default SignOutIcon;
