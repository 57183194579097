import { TAggregatedSales } from 'Models/sales/isAggregatedSales';
import { TCost } from 'Models/sales/isCost';
import { TSeasonParams } from 'Models/seasons/isSeasonParams';

import {
	TData,
	TCostsByCurrency,
	TProfitsByCurrency,
	TRevenuesByCurrency,
} from '../types';
import mapChartData from './mapChartData';

type TAggregatedSale = Pick<
	TAggregatedSales,
	'currency' | 'totalRevenue' | 'month'
>;

function _getCurrencyBySymbol<T extends { currency: string }>(
	currencies: T[],
	symbol: string
) {
	return currencies.find((item) => item.currency === symbol);
}

function _accumulateCosts(costs: TCost[]) {
	return costs.reduce<TCostsByCurrency[]>((costs, currentCost) => {
		const { currency, totalCost, month } = currentCost;
		const existingCurrency = _getCurrencyBySymbol(costs, currency);

		if (!existingCurrency) {
			const newCostsPerCurrency = [{ y: totalCost, x: month }];

			costs.push({ currency, costs: newCostsPerCurrency });

			return costs;
		}

		// Check whether the existing currency already has the matching cost month.
		// If yes, accumulate costs.
		const existingMonth = existingCurrency.costs.find(
			(cost) => cost.x === month
		);

		if (existingMonth) {
			existingMonth.y = existingMonth.y + totalCost;
		} else {
			existingCurrency.costs.push({ y: totalCost, x: month });
		}

		return costs;
	}, []);
}

function _calculateRevenues(revenues: TAggregatedSale[]) {
	return revenues.reduce<TRevenuesByCurrency[]>((revenues, currentRevenue) => {
		const { currency, totalRevenue, month } = currentRevenue;
		const existingCurrency = _getCurrencyBySymbol(revenues, currency);

		if (!existingCurrency) {
			const newRevenuesPerCurrency = [{ y: totalRevenue, x: month }];

			revenues.push({ currency, revenues: newRevenuesPerCurrency });

			return revenues;
		}

		existingCurrency.revenues.push({ y: totalRevenue, x: month });

		return revenues;
	}, []);
}

function _calculateProfits(
	costs: TCostsByCurrency[],
	revenues: TRevenuesByCurrency[]
) {
	// Need to iterate on both costs and revenues to calculate all possible profits
	const profitsFromCosts = costs.reduce<TProfitsByCurrency[]>(
		(profitsByCurrency, currentCost) => {
			const { currency, costs } = currentCost;

			const sameCurrencyFromRevenues = _getCurrencyBySymbol(revenues, currency);

			// When revenues include the same currency and same month
			// then calculate profit, or loss
			if (sameCurrencyFromRevenues) {
				const profits: TData[] = costs.map((cost) => {
					const matchingMonthFromRevenues =
						sameCurrencyFromRevenues.revenues.find(
							(revenue) => cost.x === revenue.x
						);

					return {
						x: cost.x,
						y: matchingMonthFromRevenues
							? matchingMonthFromRevenues.y - cost.y
							: -Math.abs(cost.y),
					};
				});

				return [...profitsByCurrency, { currency, profits }];
			}

			const newCurrency = {
				currency,
				profits: costs.map(({ y, x }) => ({
					x,
					y: -Math.abs(y),
				})),
			};

			return [...profitsByCurrency, newCurrency];
		},
		[]
	);

	return revenues.reduce<TProfitsByCurrency[]>(
		(profitsByCurrency, currentRevenue) => {
			const { currency, revenues } = currentRevenue;
			const existingCurrency = _getCurrencyBySymbol(
				profitsByCurrency,
				currency
			);

			if (!existingCurrency) {
				profitsByCurrency.push({ currency, profits: [...revenues] });

				return profitsByCurrency;
			}

			// If the profit is already calculated for a given pair of revenue and cost, then omit it.
			const remainingProfits = currentRevenue.revenues.reduce<TData[]>(
				(remainingRevenues, currentRevenue) => {
					const { x, y } = currentRevenue;
					const existingMonth = existingCurrency.profits.find(
						(profit) => profit.x === x
					);

					return existingMonth
						? remainingRevenues
						: [...remainingRevenues, { x, y }];
				},
				[]
			);

			existingCurrency.profits.push(...remainingProfits);

			return profitsByCurrency;
		},
		profitsFromCosts
	);
}

type TChartData = {
	sales: TAggregatedSale[];
	productsCosts: TCost[];
	workLogsCosts: TCost[];
};

function getProfitChartData(data: TChartData, seasonParams: TSeasonParams) {
	const { sales, productsCosts, workLogsCosts } = data;

	const costs = _accumulateCosts([...productsCosts, ...workLogsCosts]);
	const revenues = _calculateRevenues(sales);
	const profits = _calculateProfits(costs, revenues);

	return mapChartData(costs, revenues, profits, seasonParams);
}

export default getProfitChartData;
