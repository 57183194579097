import { ExportType, TExportType } from 'Models/exports/ExportType';
import { LogTypes } from 'Models/logs/LogType';
import format from 'date-fns/format';
import { utils, writeFile } from 'xlsx';

import truncateString from 'Utils/truncateString';

import getHarvestExportData from '../../partner/harvest/utils/getExportData';
import getHoursExportData from '../../partner/hours/utils/getExportData';
import {
	getHeaderColumnsMerges,
	getProductColumnsMerges,
} from '../../partner/jobs/utils/jobExportFunction/jobMerges';

// s: start, e:end, r: rows, c: columns
export type TMerge = {
	s: {
		r: number;
		c: number;
	};
	e: {
		r: number;
		c: number;
	};
};

type TJobsExportSetup = {
	logType: typeof LogTypes.Jobs;
	exportData: {
		exportData: Record<string, string | number>[];
		additionalProductsMerges: TMerge[];
	};
	fileType: TExportType;
	totalColumns: number;
	fileName: string;
};

type THoursExportSetup = {
	logType: typeof LogTypes.Hours;
	exportData: ReturnType<typeof getHoursExportData>;
	fileType: Exclude<TExportType, 'PDF'>;
	fileName: string;
};

type THarvestExportSetup = {
	logType: typeof LogTypes.Harvest;
	exportData: ReturnType<typeof getHarvestExportData>;
	fileType: Exclude<TExportType, 'PDF'>;
	fileName: string;
};

type TExportFunctionSetup =
	| TJobsExportSetup
	| THoursExportSetup
	| THarvestExportSetup;

const MAX_REPORT_NAME_LENGTH = 30;

export function handleExport(setup: TExportFunctionSetup): void {
	const { exportData, fileType, logType, fileName } = setup;

	const reportDate = format(new Date(), 'yyyy-MM-dd');
	const reportName = truncateString(
		`${fileName} - ${reportDate}`,
		MAX_REPORT_NAME_LENGTH
	);

	const isCSV = fileType === ExportType.CSV;
	const data = exportData.exportData;
	const workSheet = utils.json_to_sheet(data);

	if (logType === LogTypes.Jobs) {
		const headerColumnsMerges = getHeaderColumnsMerges(setup.totalColumns);
		const productColumnsMerges = getProductColumnsMerges();
		const additionalProductsMerges =
			'additionalProductsMerges' in exportData
				? exportData.additionalProductsMerges
				: undefined;

		if (!isCSV && additionalProductsMerges) {
			workSheet['!merges'] = [
				...productColumnsMerges,
				...headerColumnsMerges,
				...additionalProductsMerges,
			];
		}
	}

	const workBook = utils.book_new();
	utils.book_append_sheet(workBook, workSheet, reportName);
	writeFile(workBook, `${reportName}.${fileType.toLowerCase()}`);
}

export default handleExport;
