import React, { useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TCause } from 'Models/causes/isCause';
import List from 'UI/display/List';
import Popover from 'UI/display/Popover';
import Text from 'UI/display/Text';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import { treatmentInputState } from '../../../../../state';

type TProps = {
	causes?: TCause[];
};

function AddCausesDropdown(props: TProps) {
	const { t } = useTranslation();
	const { causes } = props;
	const [searchValue, setSearchValue] = useState('');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const treatmentInput = useReactiveVar(treatmentInputState);

	if (!treatmentInput) {
		return null;
	}

	const treatmentCauses = treatmentInput.causeIds || [];

	const handleClick = (event) => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const handleJobInput = (cause: string) => {
		treatmentInputState({
			...treatmentInput,
			causeIds: [...treatmentCauses, cause],
		});
		setSearchValue('');
		handleClose();
	};

	const matchesSearchAndNonSelected = (cause: TCause) =>
		cause.name.toUpperCase().includes(searchValue.toUpperCase()) &&
		!treatmentCauses?.includes(cause.id);

	const filteredCauses = causes
		?.filter(matchesSearchAndNonSelected)
		.map((cause) => (
			<List.Item
				onClick={() => handleJobInput(cause.id)}
				key={cause.id}
				sx={{
					cursor: 'pointer',
					'&.MuiListItem-root:hover': {
						backgroundColor: FarmableColors.BLACK_4,
					},
				}}
			>
				{cause.name}
			</List.Item>
		));

	const causesList = !searchValue ? null : (
		<Box sx={{ overflow: 'auto' }}>
			<List.List>
				{filteredCauses?.length ? (
					filteredCauses
				) : (
					<Text sx={{ paddingX: '1rem' }} variant="secondaryBody2">
						{t('prompts.noRegisteredCauses')}
					</Text>
				)}
			</List.List>
		</Box>
	);

	return (
		<>
			<Button
				variant="secondary"
				color="primary"
				startIcon={<AddIcon />}
				onClick={handleClick}
			>
				{t('buttons.addCauses')}
			</Button>
			<Popover
				id="job-type-select"
				open={isMenuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<Box
					display="flex"
					flexDirection="column"
					sx={{ overflow: 'hidden', maxHeight: '32rem', width: '28rem' }}
				>
					<FreeTextSearch
						inputPlaceholder={t('plannerPage.addJobModal.searchCauseInput')}
						onChange={(newValue: string): void => {
							setSearchValue(newValue);
						}}
						onClear={() => setSearchValue('')}
						value={searchValue}
						sx={{
							height: '2.25rem',
							boxShadow: 'none',
							border: `1px solid ${FarmableColors.BLACK_12}`,
							margin: '1rem',
						}}
					/>
					{causesList}
				</Box>
			</Popover>
		</>
	);
}

export default AddCausesDropdown;
