import { makeVar, useReactiveVar } from '@apollo/client';

import { TAddJobModalState } from '../../types';

export const selectedLocationIds = makeVar<string[] | undefined>(undefined);

export const addJobModalState = makeVar<TAddJobModalState>({
	status: 'SET_CAUSE_AND_TREATMENTS',
	jobInputData: undefined,
});

export const useJobInputData = () => {
	const { jobInputData } = useReactiveVar(addJobModalState);
	if (!jobInputData) {
		throw new Error('The job input has not been initialized');
	}

	return jobInputData;
};

export const useAddJobModalStatus = () => {
	const { status } = useReactiveVar(addJobModalState);

	return status;
};
