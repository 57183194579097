import { gql, TypedDocumentNode } from '@apollo/client';
import { TCause } from 'Models/causes/isCause';
import { TGovernmentCauseFilterInput } from 'Models/causes/types/GovernmentCauseFilterInput';

export type TGovernmentCause = Pick<TCause, 'id' | 'name' | 'causeType'>;

type TGetGovernmentCauseInput = {
	filterBy: TGovernmentCauseFilterInput;
};

const GET_GOVERNMENT_CAUSES: TypedDocumentNode<
	{
		governmentCauses: { causes: TGovernmentCause[] };
	},
	TGetGovernmentCauseInput
> = gql`
	query getGovernmentCauses($filterBy: GovernmentCauseFilterInput!) {
		governmentCauses(filterBy: $filterBy) {
			causes {
				id
				name
				causeType
			}
		}
	}
`;

export default GET_GOVERNMENT_CAUSES;
