import React, { ReactElement } from 'react';

import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';

type TCommentConnectorProps = {
	hidden?: boolean;
};

const CommentConnector = (
	props: TCommentConnectorProps
): ReactElement | null => {
	const useCommentConnectorStyles = makeStyles({
		commentsConnector: {
			position: 'relative',
			height: '100%',
			width: '100%',
			'&:before': {
				content: '""',
				width: '2px',
				height: '100%',
				display: 'block',
				position: 'absolute',
				left: '50%',
				backgroundColor: FarmableColors.BLACK_12,
			},
		},
	});
	const styles = useCommentConnectorStyles();

	return props.hidden ? null : <div className={styles.commentsConnector}></div>;
};

export default CommentConnector;
