import { TypedDocumentNode, gql } from '@apollo/client';

const EDIT_MEMBER_ACCESS_TO_LOCATIONS: TypedDocumentNode<
	{
		editMemberAccessToLocations: boolean;
	},
	{
		memberId: string;
		locationIds: string[];
	}
> = gql`
	mutation editMemberAccessToLocations($memberId: ID!, $locationIds: [ID!]) {
		editMemberAccessToLocations(memberId: $memberId, locationIds: $locationIds)
	}
`;

export default EDIT_MEMBER_ACCESS_TO_LOCATIONS;
