import { gql, TypedDocumentNode } from '@apollo/client';

export type TVolumeChartData = {
	totalVolume: number;
	season: string;
};

type TGetVolumechartDataResponse = {
	getAggregatedHarvestBatches: TVolumeChartData[];
};

type TGetVolumechartDataRequest = {
	locationId: string;
	query: {
		filterBy: {
			varieties?: string[];
		};
		groupBy: 'SEASON';
	};
};

const GET_VOLUME_BY_SEASON_CHART_DATA: TypedDocumentNode<
	TGetVolumechartDataResponse,
	TGetVolumechartDataRequest
> = gql`
	query getVolumeChartData(
		$locationId: ID!
		$query: AggregatedHarvestBatchQuery
	) {
		getAggregatedHarvestBatches(locationId: $locationId, query: $query) {
			totalVolume
			season
		}
	}
`;

export default GET_VOLUME_BY_SEASON_CHART_DATA;
