import React from 'react';

import Box from 'UI/layout/Box';

import LogsPageHeader from '../../../components/logs/LogsPageHeader';
import LogsPageLayout from '../../../components/logs/LogsPageLayout';
import { CreateReportButton } from '../CreateReport';
import Filters from '../common/components/Filters';
import TableTabs from '../common/components/TableTabs';
import ExportButton from './components/ExportButton';
import FilterModal from './components/FilterModal';
import HoursLogsTable from './components/HoursLogsTable';
import Pagination from './components/Pagination';

function HoursLogsPage() {
	return (
		<LogsPageLayout>
			<LogsPageHeader>{<CreateReportButton />}</LogsPageHeader>
			<TableTabs>
				<Box
					padding="0.5rem 1rem 1rem 1rem"
					display="flex"
					gap="0.5rem"
					justifyContent="space-between"
				>
					<Box display="flex" gap="0.5rem">
						<FilterModal />
						<Filters />
					</Box>
					<ExportButton />
				</Box>
				<HoursLogsTable />
				<Pagination />
			</TableTabs>
		</LogsPageLayout>
	);
}

export default HoursLogsPage;
