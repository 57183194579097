import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TPartnerPlannerJob } from 'Models/planner/PartnerPlannerJob';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import ShareIcon from 'UI/icons/Share';
import Button from 'UI/inputs/Button';
import IconButton from 'UI/inputs/IconButton';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import FarmsMultiselectList from '../../../../../components/FarmsMultiselectList';
import GET_PLANNER_JOB_DETAILS from '../../api/getPlannerJobDetails';
import SHARE_PLANNER_JOB_MUTATION from '../../api/sharePlannerJobMutation';
import styles from './styles.module.scss';

type TShareJobModal = {
	job: Pick<
		TPartnerPlannerJob,
		'jobDetails' | 'id' | 'sharedLocations' | 'variety'
	>;
};

const ShareJobModal = (props: TShareJobModal) => {
	const { t } = useTranslation();
	const { job } = props;
	const [isOpen, setOpen] = useState(false);
	const [locationIds, setLocationIds] = useState<string[]>([]);
	const sharedLocationsIds =
		job.sharedLocations?.map((location) => location.id) || [];

	const [sharePartnerPlanner, mutationResult] = useMutation(
		SHARE_PLANNER_JOB_MUTATION,
		{
			refetchQueries: [GET_PLANNER_JOB_DETAILS],
		}
	);

	const { data, loading: isSharing, error } = mutationResult;
	useSnackbarNotifications({
		mutationResult,
		messageSuccess: t('info.sharePlannerJobSuccess'),
		messageFailure: t('errors.sharePlannerJobFailed'),
	});

	useEffect(() => {
		if (data) {
			setOpen(false);
		} else if (error) {
			setOpen(false);
		}
	}, [t, error, data]);

	if (!job) {
		return null;
	}

	const toggleHandler = () => setOpen(!isOpen);

	const onUpdate = () => {
		void sharePartnerPlanner({
			variables: { plannerJobId: job.id, locationIds: locationIds },
		});
	};

	const translatedJobType = t(`common.jobs.types.${job.jobDetails.jobType}`);

	return (
		<>
			<IconButton onClick={toggleHandler}>
				<ShareIcon fontSize="small" />
			</IconButton>
			<Modal.UncontrolledModal toggleOpen={toggleHandler} open={isOpen}>
				<Modal.DialogTitle
					title={
						t('plannerPage.shareJobModal.title', {
							jobType: translatedJobType,
							variety: job.variety.name,
						}) || ''
					}
					closeIconHandler={toggleHandler}
				/>
				<Modal.DialogContent>
					<Text sx={{ marginBottom: '1rem' }}>
						{t('plannerPage.addJobModal.locationsText')}
					</Text>
					<FarmsMultiselectList
						onChangeCallback={setLocationIds}
						selectedFarmsIds={sharedLocationsIds}
					/>
				</Modal.DialogContent>
				<Modal.DialogActions className={styles.dialogActions}>
					<Button
						variant="text"
						onClick={toggleHandler}
						className={styles.backButton}
					>
						{t('buttons.cancel')}
					</Button>
					<Button variant="outlined" onClick={onUpdate} disabled={isSharing}>
						{t('buttons.shareJob')}
					</Button>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
};

export default ShareJobModal;
