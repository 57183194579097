import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import Permissions from 'Models/permissions/Permissions';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';

import DropdownContent from '../DropdownContent';
import {
	GET_FIELDS_QUERY,
	GET_FIELDS_ADVISOR_QUERY,
} from '../common/api/getFields';
import parseGetFieldsResult from '../common/utils/parseGetFieldsResult';

type TFieldsDropdownProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const FieldsDropdown = (props: TFieldsDropdownProps) => {
	const { filters, updateFilters } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();
	const locationId = useLocationID();
	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY
	);

	const { data, loading, error } = useQuery(
		hasPermissionsToRegularQuery ? GET_FIELDS_QUERY : GET_FIELDS_ADVISOR_QUERY,
		{
			variables: {
				locationId: locationId,
			},
		}
	);

	const open = Boolean(anchorEl);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const parsedGetFieldsResult = parseGetFieldsResult(data);
	const dropdownContent = parsedGetFieldsResult.map((field) => field.name);
	const appliedFieldIds = filters.fieldIds || [];
	const appliedFields = parsedGetFieldsResult
		.filter((field) => appliedFieldIds.includes(field.id))
		.map((field) => field.name);

	const appliedFieldsAmount = appliedFields.length
		? `(${appliedFields.length})`
		: '';

	if (error) {
		throw new ApolloError(error);
	}

	const onItemClick = (fieldName: string, active: boolean) => {
		const fieldId = parsedGetFieldsResult.find(
			(field) => field.name === fieldName
		)?.id;

		if (fieldId) {
			const newFieldIds = active
				? appliedFieldIds.filter((appliedFieldId) => appliedFieldId !== fieldId)
				: [...appliedFieldIds, fieldId];

			updateFilters({
				...filters,
				fieldIds: newFieldIds,
			});
		}
	};

	const onClear = () => {
		updateFilters({
			...filters,
			fieldIds: undefined,
		});
	};

	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{t('labels.fields', { count: 1 })} {appliedFieldsAmount}
			</Button>
			<Menu.Menu
				id="fields-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={loading}
					dropdownItems={dropdownContent}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={appliedFields}
				/>
			</Menu.Menu>
		</>
	);
};

export default FieldsDropdown;
