import React, { ReactElement } from 'react';

import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import makeStyles from '@mui/styles/makeStyles';

type TCheckboxCustomProps = {
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	label?: string | ReactElement;
	dense?: boolean;
	fullWidth?: boolean;
};

type TCheckboxControlledProps = TCheckboxCustomProps & CheckboxProps;

type TCustomThemeProps = { fullWidth?: boolean };

const useStyles = makeStyles(() => ({
	defaultLabel: {
		fontSize: '0.875rem',
		width: ({ fullWidth }: TCustomThemeProps) => (fullWidth ? '100%' : 'auto'),
	},
	compactLabelRoot: {
		marginLeft: '-0.5rem',
	},
	root: {
		padding: '0.375rem',
	},
}));

const Controlled = (props: TCheckboxControlledProps): ReactElement => {
	const {
		className,
		checked,
		onChange,
		disabled,
		label,
		dense,
		size,
		fullWidth,
		...MUIProps
	} = props;

	const { defaultLabel, compactLabelRoot, root } = useStyles({ fullWidth });

	return (
		<FormControlLabel
			label={label}
			disabled={disabled}
			classes={{
				label: defaultLabel,
				root: dense ? compactLabelRoot : undefined,
			}}
			control={
				<MUICheckbox
					className={className}
					checked={checked}
					value={label}
					onChange={onChange}
					color="primary"
					size={size}
					classes={{
						root: dense ? root : undefined,
					}}
					{...MUIProps}
				/>
			}
		/>
	);
};

export default Controlled;
