import React, { ReactElement } from 'react';

import MUIContainer, { ContainerProps } from '@mui/material/Container';

const Container = (props: ContainerProps): ReactElement => {
	const { className, children, ...MUIContainerProps } = props;

	return (
		<MUIContainer className={className} {...MUIContainerProps}>
			{children}
		</MUIContainer>
	);
};

export default Container;
