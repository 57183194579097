import { TTeam } from 'Models/teams/isTeam';
import { TTeamInput } from 'Models/teams/isTeamInput';

import moveMemberBetweenTeams from './moveMemberBetweenTeams';
import teamMembersByPosition from './teamMembersByPosition';

function _getSerializedTeamInput(team: TTeam): TTeamInput {
	return {
		name: team.name,
		teamLeadId: team.teamLead.id,
		teamMemberInput: team.teamMembers.length
			? team.teamMembers.map((member, index) => ({
					id: member.id,
					fullName: member.fullName,
					customId: member.customId || undefined,
					position: index,
			  }))
			: [],
	};
}

function _getTeamById(teams: TTeam[], id: string) {
	const teamId = teams.findIndex((team) => team.id === id);
	const team = teams[teamId];
	return {
		...team,
		teamMembers: [...team.teamMembers].sort(teamMembersByPosition),
	};
}

type TQueryInput = {
	input: TTeamInput;
	teamId: string;
	locationId: string;
};

type TGetEditTeamParametersResult = {
	queryInput: TQueryInput;
	changedTeams: TTeam[];
	allTeams: TTeam[];
};

const getEditTeamParameters = (
	result: any,
	teams: TTeam[],
	locationId: string
): TGetEditTeamParametersResult | void => {
	const { source, destination } = result;

	if (destination && teams.length) {
		// same card, but different order
		if (source.droppableId === destination.droppableId) {
			const teamToEdit = _getTeamById(teams, source.droppableId);
			const editedTeam = moveMemberBetweenTeams(
				source.index,
				teamToEdit,
				destination.index
			);
			const allTeams = [...teams].map((team) => {
				return team.id === source.droppableId ? editedTeam : team;
			});

			return {
				queryInput: {
					input: _getSerializedTeamInput(editedTeam),
					teamId: editedTeam.id,
					locationId,
				},
				changedTeams: [editedTeam],
				allTeams,
			};

			// moving a member between the team cards
		} else {
			const sourceTeam = _getTeamById(teams, source.droppableId);
			const destinationTeam = _getTeamById(teams, destination.droppableId);

			const editedTeams = moveMemberBetweenTeams(
				source.index,
				sourceTeam,
				destination.index,
				destinationTeam
			);

			const allTeams = teams.map((team) => {
				return (
					editedTeams.find((editedTeam) => team.id === editedTeam.id) || team
				);
			});

			// we don't need to request changes in the source team - it happens on the backend side
			return {
				queryInput: {
					input: _getSerializedTeamInput(editedTeams[1]),
					teamId: destinationTeam.id,
					locationId,
				},
				changedTeams: editedTeams,
				allTeams,
			};
		}
	}
};

export default getEditTeamParameters;
