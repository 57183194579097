import React, { ReactElement, useEffect, useState } from 'react';

import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TTeam } from 'Models/teams/isTeam';
import Box from 'UI/layout/Box';
import Masonry from 'UI/layout/Masonry';
import { DragDropContext } from 'react-beautiful-dnd';

import useLocationID from 'Hooks/useLocationID';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import LoadingContent from '../../common/components/LoadingContent';
import EDIT_TEAM from '../api/editTeam';
import GET_TEAMS from '../api/getTeams';
import getEditTeamParameters from '../utils/getEditTeamParameters';
import DraggableTeamCard from './DraggableTeamCard';
import EditTeamModal from './EditTeamModal';

const TeamsBoard = (): ReactElement | null => {
	const locationId = useLocationID();
	const apolloCache = useApolloClient().cache;
	const [open, setOpen] = useState(false);
	const [teams, setTeams] = useState<TTeam[]>([]);
	const toggleOpen = () => setOpen(!open);
	const { t } = useTranslation();

	const { data, loading } = useQuery(GET_TEAMS, {
		variables: { locationId },
	});

	const [editTeam, editAction] = useMutation(EDIT_TEAM);

	// We need to set up teams like below in order to avoid
	// flickering problem while dragging and dropping teammates
	useEffect(() => {
		if (data) {
			setTeams(data.getTeams);
		}
	}, [data?.getTeams]);

	useSnackbarNotifications({
		mutationResult: editAction,
		messageFailure: t('errors.editTeamError'),
		messageSuccess: t('info.editTeamSuccess'),
	});

	const onDragEnd = (result) => {
		const { source, destination } = result;

		// Do nothing when dropped on the same place
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		const editTeamParameters = getEditTeamParameters(result, teams, locationId);

		if (editTeamParameters && editTeamParameters.changedTeams.length) {
			const {
				queryInput: { input, locationId, teamId },
			} = editTeamParameters;

			setTeams(editTeamParameters.allTeams);
			// We can't use mutation's `update` function, because the backend
			// requires only one team to be edited while moving members between teams
			editTeamParameters.changedTeams.forEach((team) => {
				apolloCache.modify({
					id: apolloCache.identify(team),
					fields: {
						teamMembers: () => team.teamMembers,
					},
				});
			});

			void editTeam({
				variables: { input, locationId, teamId },
				onError: (e) => {
					const teamIsMissing = e.message.match(/Team with id \d+ not found/);
					if (teamIsMissing) {
						// delete the missing team from cache
						apolloCache.modify({
							id: apolloCache.identify(editTeamParameters.changedTeams[1]),
							fields: {
								teamMembers: (_refs, { DELETE }) => DELETE,
							},
						});

						// remove missing team from view
						setTeams(
							teams.filter(
								(t) => t.id !== editTeamParameters.changedTeams[1].id
							)
						);
					}
				},
			});
		}
	};

	if (loading) {
		return (
			<Box marginTop={3}>
				<LoadingContent />
			</Box>
		);
	}

	if (data?.getTeams.length === 0) {
		return null;
	}

	return (
		<>
			<EditTeamModal open={open} toggleOpen={toggleOpen} />
			<DragDropContext onDragEnd={onDragEnd}>
				<Masonry>
					{teams.map((team) => (
						<DraggableTeamCard
							toggleOpen={toggleOpen}
							team={team}
							key={team.id}
						/>
					))}
				</Masonry>
			</DragDropContext>
		</>
	);
};
export default TeamsBoard;
