import React from 'react';

import Link from 'Features/router/components/Link';
import { TLocations } from 'Models/locations/isLocations';
import Avatar from 'UI/display/Avatar';
import Button from 'UI/inputs/Button';
import { FarmablePlaceholdersColors } from 'UI/theme/Colors';
import { FarmableColors } from 'UI/theme/Colors';

import stringToInitials from '../../../utils/stringToInitials';
import styles from './styles.module.scss';

const colors = Object.values(FarmablePlaceholdersColors);

type TFarmItemProps = Omit<TLocations, 'invitedBy'> & {
	image?: {
		path: string;
	};
};

const FarmItem = (props: TFarmItemProps) => {
	const { name, image, id } = props;
	return (
		<Link to={`/mobile/${id}/store`} className={styles.buttonLink}>
			<Button
				variant="outlined"
				customTextColor={FarmableColors.BLACK_87}
				fullWidth
			>
				<Avatar
					alt={name || ''}
					title={name || ''}
					src={image?.path}
					size="sm"
					color={colors[(parseInt(id, 10) || 0) % colors.length]}
					variant="rounded"
					className={styles.buttonIcon}
				>
					{stringToInitials(name)}
				</Avatar>
				<span className={styles.loginButtonDescription}>{name}</span>
			</Button>
		</Link>
	);
};

export default FarmItem;
