// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Ulz7OcE-vQIzfgQVuYPNX {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  width: -moz-available;\n  width: -webkit-fill-available;\n  width: fill-available;\n}\n\n._2LBvBCXxe8FVm7FXAz5n7_ {\n  font-weight: normal;\n  white-space: nowrap;\n}\n\n._235XGofKTsEbD2GlpCU1zy {\n  margin-left: .25rem;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n", ""]);
// Exports
exports.locals = {
	"labelContainer": "_2Ulz7OcE-vQIzfgQVuYPNX",
	"caption": "_2LBvBCXxe8FVm7FXAz5n7_",
	"farmName": "_235XGofKTsEbD2GlpCU1zy"
};
module.exports = exports;
