import { getBoundingBox } from 'geolocation-utils';
import _flatten from 'lodash/flatten';
import _flattenDepth from 'lodash/flattenDepth';

import { TCoordinatePoint } from './isCoordinatePoint';
import { TField } from './isField';

export type TMapBoundingBox = [[number, number], [number, number]];

type TFieldWithGeometry = Pick<TField, 'geometry'>;

const fieldsBoundingBox = (
	...fields: [TFieldWithGeometry | TFieldWithGeometry[]]
): TMapBoundingBox => {
	const flattenedFieldGeometries = _flatten(fields).map(
		(field) => field.geometry
	);
	const latLonPoints = _flattenDepth<TCoordinatePoint>(
		flattenedFieldGeometries,
		2
	).map((point) => ({
		longitude: point.x,
		latitude: point.y,
	}));

	const bounds = getBoundingBox(
		latLonPoints,
		latLonPoints.length > 1 ? 0 : 1000
	);

	return [
		[bounds.topLeft.latitude, bounds.topLeft.longitude],
		[bounds.bottomRight.latitude, bounds.bottomRight.longitude],
	];
};

export default fieldsBoundingBox;
