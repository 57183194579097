import React from 'react';

import Text from 'UI/display/Text';
import { useTranslation } from 'react-i18next';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import SelectProductInput from './SelectProductInput';

function SelectProduct() {
	const { t } = useTranslation();
	const { company } = usePartnerCompany();

	return company?.country ? (
		<>
			<Text variant="label">
				{t('treatmentLibrary.addTreatmentModal.product')}
			</Text>
			<SelectProductInput country={company.country} />
		</>
	) : null;
}

export default SelectProduct;
