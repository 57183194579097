import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Tooltip from 'UI/display/Tooltip';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../../state';
import TooltipBase from './TooltipBase';
import Treatment from './Treatment';

const AddTreatments = () => {
	const status = useAddJobModalStatus();
	const jobInput = useJobInputData();
	const { t } = useTranslation();

	const treatments = jobInput.jobDetails.treatments;

	const tooltipContent = (
		<Box padding="0.25rem">
			<Text variant="body2">{t('plannerPage.treatments.helperText')}</Text>
		</Box>
	);
	const isDisabledButton = treatments.some(
		(treatment) => treatment.originalTreatmentId === ''
	);

	const onAddTreatment = () => {
		addJobModalState({
			status,
			jobInputData: {
				...jobInput,
				jobDetails: {
					...jobInput.jobDetails,
					treatments: [
						...jobInput.jobDetails.treatments,
						{
							originalTreatmentId: '',
							amount: 0,
							unit: 'GRAM_PER_100_LITER',
							productName: '',
						},
					],
				},
			},
		});
	};

	return (
		<>
			<Box display="flex" marginTop="1.5rem">
				<Text variant="label">{t('labels.treatment')}</Text>
				<Tooltip
					arrow
					placement="top"
					title={tooltipContent}
					arrowColor={FarmableColors.LIGHT_GREY}
					backgroundColor={FarmableColors.LIGHT_GREY}
					boxShadow
				>
					<TooltipBase />
				</Tooltip>
			</Box>
			{treatments.map((treatment) => (
				<Treatment key={treatment.originalTreatmentId} treatment={treatment} />
			))}
			<Box marginTop="1rem" marginBottom="1.5rem">
				<Button
					variant="secondary"
					color="primary"
					disabled={isDisabledButton}
					startIcon={<AddIcon />}
					onClick={onAddTreatment}
				>
					{t('buttons.addOneMore')}
				</Button>
			</Box>
		</>
	);
};

export default AddTreatments;
