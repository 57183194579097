const INV_MOVEMENT_LOGS_TABLE_HEADERS = [
	'date',
	'movementType',
	'productName',
	'amount',
	'unit',
	'totalValue',
] as const;

const InvMovementLogsTableHeaderValues = [...INV_MOVEMENT_LOGS_TABLE_HEADERS];

export default InvMovementLogsTableHeaderValues;
