import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { FarmableColors } from '../../theme/Colors';

type TPrecipitationIconProps = {
	rainColor?: string;
};

function PrecipitationIcon(props: SvgIconProps & TPrecipitationIconProps) {
	return (
		<SvgIcon viewBox="0 0 32 32" fontSize="large" {...props}>
			<path
				d="M20 17v8m-8-8v8m4-6v8"
				stroke={FarmableColors.BLUE}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				d="M24 20.58A5 5 0 0 0 22 11h-1.26A8 8 0 1 0 8 19.25"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeOpacity=".87"
				strokeWidth="2"
				fill="none"
				stroke="black"
			/>
		</SvgIcon>
	);
}

export default PrecipitationIcon;
