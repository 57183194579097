import React, { ReactElement } from 'react';

import MUIListItemButton, {
	ListItemButtonProps,
} from '@mui/material/ListItemButton';

import { FarmableColors } from '../../theme/Colors';

export type TItemButton = ListItemButtonProps & {
	isActive?: boolean;
};

const ItemButton = (props: TItemButton): ReactElement => {
	const { children, isActive, ...MUIProps } = props;

	return (
		<MUIListItemButton
			sx={{
				backgroundColor: isActive ? FarmableColors.ORANGE_8 : 'unset',
			}}
			{...MUIProps}
		>
			{children}
		</MUIListItemButton>
	);
};

export default ItemButton;
