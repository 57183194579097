import { TTranslateFunction } from 'Features/localization';
import { THarvestExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import harvestCategoryString from 'Models/harvest/harvestCategoryString';
import harvestCommentString from 'Models/harvest/harvestCommentString';
import harvestCrewString from 'Models/harvest/harvestCrewString';
import harvestFieldsNameString from 'Models/harvest/harvestFieldsNameString';
import harvestStandardDateString from 'Models/harvest/harvestStandardDateString';
import harvestTeamMembersNameString from 'Models/harvest/harvestTeamMembersNameString';
import harvestTeamsNameString from 'Models/harvest/harvestTeamsNameString';
import harvestVariety from 'Models/harvest/harvestVariety';
import harvestVolume from 'Models/harvest/harvestVolume';
import harvestWeight from 'Models/harvest/harvestWeight';
import harvestWeightUnit from 'Models/harvest/harvestWeightUnit';
import { THarvestLogsPage } from 'Models/harvest/isHarvestWithTeams';

function getHarvestExportHeaders(dictionary: THarvestExportHeadersDictionary) {
	const {
		date,
		field,
		category,
		variety,
		boxes,
		weight,
		weightUnit,
		teams,
		teamName,
		teamMember,
		responsible,
		quality,
		comment,
	} = dictionary;

	return {
		[date]: '-',
		[field]: '-',
		[category]: '-',
		[variety]: '-',
		[boxes]: '-',
		[weight]: '-',
		[weightUnit]: '-',
		[responsible]: '-',
		[teams]: teamName,
		[teamMember]: teamMember,
		[quality]: '-',
		[comment]: '-',
	};
}

function getHarvestRowExportData(
	harvest: THarvestLogsPage,
	dictionary: THarvestExportHeadersDictionary,
	t: TTranslateFunction,
	hasTimesheetsSubscription?: boolean
) {
	const {
		date,
		field,
		category,
		variety,
		boxes,
		weight,
		weightUnit,
		responsible,
		teams,
		teamMember,
		quality,
		comment,
	} = dictionary;

	const baseRowData = {
		[date]: harvestStandardDateString(harvest),
		[field]: harvestFieldsNameString(harvest),
		[category]: harvestCategoryString(harvest, t),
		[variety]: harvestVariety(harvest),
		[boxes]: harvestVolume(harvest),
		[weight]: harvestWeight(harvest),
		[weightUnit]: harvestWeightUnit(harvest),
		[responsible]: harvestCrewString(harvest),
		[quality]: harvest.quality,
		[comment]: harvestCommentString(harvest),
	};

	if (hasTimesheetsSubscription) {
		const teamsRowData = {
			[teams]: harvestTeamsNameString(harvest, true, true),
			[teamMember]: harvestTeamMembersNameString(harvest, true, true),
		};

		return {
			...baseRowData,
			...teamsRowData,
		};
	}

	return baseRowData;
}

export { getHarvestExportHeaders, getHarvestRowExportData };
