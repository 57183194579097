import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import { ALL as STATUSES, TJobStatuses } from 'Models/jobs/JobStatuses';
import isJobStatus from 'Models/jobs/isJobStatus';
import translateJobStatus from 'Models/jobs/translateJobStatus';
import Chip from 'UI/display/Chip';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

import useCompanyID from 'Hooks/useCompanyID';

type TStatusesProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const Statuses = (props: TStatusesProps): ReactElement => {
	const { t } = useTranslation();
	const { updateFilters, filters } = props;
	const statuses = filters?.jobStatuses || [];
	const isPartner = Boolean(useCompanyID());

	const onStatusClick = (status: TJobStatuses) => {
		const active = statuses.includes(status);
		const newStatuses = active
			? statuses.filter((s) => s !== status)
			: [...statuses, status];

		updateFilters({
			...filters,
			jobStatuses: newStatuses.filter(isJobStatus),
		});
	};

	return (
		<Grid container spacing={1}>
			{STATUSES.map((status: TJobStatuses, i) => {
				const active = statuses.includes(status);
				const label = translateJobStatus(status, t, isPartner);

				return (
					<Grid item key={status}>
						<Chip
							chipColor={active ? FarmableColors.ORANGE : undefined}
							key={`${status}-${i}`}
							label={label}
							onClick={() => onStatusClick(status)}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default Statuses;
