import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Box from 'UI/layout/Box';
import { format } from 'date-fns';

import useLocationID from 'Hooks/useLocationID';
import useManageHistory from 'Hooks/useManageHistory';

import LoadingSection from '../../../../../components/ListLoadingSection';
import BatchItem from './BatchItem';
import GET_HARVEST_BATCHES from './api/getHarvestBatches';

const BatchList = () => {
	const { t } = useTranslation();
	const { paramsFromURL } = useManageHistory();
	const locationId = useLocationID();

	const { data, loading, error, fetchMore } = useQuery(GET_HARVEST_BATCHES, {
		skip: !paramsFromURL.filters.dateFrom,
		variables: {
			locationId,
			filterBy: {
				dateFrom: paramsFromURL.filters?.dateFrom
					? format(new Date(paramsFromURL.filters?.dateFrom), 'yyyy-MM-dd')
					: undefined,
				dateTo: paramsFromURL.filters?.dateTo
					? format(new Date(paramsFromURL.filters?.dateTo), 'yyyy-MM-dd')
					: undefined,
				varieties: paramsFromURL.filters.varieties,
				onlySoldBatches: paramsFromURL.filters.onlySoldBatches,
			},
			pageInput: {
				limit: 10,
				offset: 0,
				sortBy: [],
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	if (loading) {
		return (
			<Box textAlign="center" padding={4}>
				<LoadingSpinner width={64} height={64} />
			</Box>
		);
	}

	if (data) {
		const total = data?.getHarvestBatches.totalElements || 0;
		const count = data?.getHarvestBatches.harvestBatches.length || 0;
		const showLoadMoreButton = count < total;

		const harvestBatchesList = count ? (
			data.getHarvestBatches.harvestBatches.map((harvestBatch, i) => (
				<BatchItem key={i} harvestBatch={harvestBatch} />
			))
		) : (
			<Box textAlign="center" marginTop="1.5rem">
				<Text>{t('labels.noHarvestBatchesFound')}</Text>
			</Box>
		);

		return (
			<Box overflow="auto">
				{harvestBatchesList}
				<LoadingSection
					status={loading ? 'loading' : 'success'}
					onClick={() => {
						if (count < total) {
							void fetchMore({
								variables: {
									pageInput: { offset: count },
								},
							});
						}
					}}
					displayButton={showLoadMoreButton}
				/>
			</Box>
		);
	}

	return null;
};

export default BatchList;
