import z from 'zod';

export const PartnerVarietySchema = z.object({
	name: z.string(),
	category: z.string(),
});

export type TPartnerVariety = z.infer<typeof PartnerVarietySchema>;

export type TPartnerVarietyInput = TPartnerVariety;
