import { TTranslateFunction } from 'Features/localization';
import { TField } from 'Models/fields/isField';
import { TVariety } from 'Models/fields/isVariety';
import { AreaUnits } from 'Models/units';
import isString from 'vet/strings/isString';

const MAX_VAR_NUMBER = 5;

export type TCropSummaryVariety = {
	category: string;
	areaSize: number;
	areaUnit: AreaUnits;
};

export type TSummarySectionField = Pick<TField, 'areaSize'> & {
	varieties?: Omit<TVariety, 'sowDate'>[] | null;
};

function getSummarySectionData(
	field: TSummarySectionField | null | undefined,
	areaUnit: AreaUnits | undefined | null,
	translateFunction: TTranslateFunction
): TCropSummaryVariety[] {
	if (!field?.varieties?.length) {
		return [];
	}
	const otherCategoryName = translateFunction('productCategories.other');

	const fieldAreaSize = field.areaSize || 0;
	const parsedVarieties = field.varieties
		// filter out irrelevant data
		.filter((variety): variety is TVariety & { category: string } =>
			isString(variety.category)
		)
		.map(({ name, percentage }) => {
			// in some cases field varieties have 0 percentage,
			// when there is only one variety, we just assume its 100,
			// otherwise we probably should estimate the area (not implemented)

			const varietyShare = (percentage || 100) / 100;

			return {
				// For a particular field the category is common, we display the variety name instead
				category: name,
				areaSize: varietyShare * fieldAreaSize,
				areaUnit: areaUnit || AreaUnits.HECTARE,
			};
		})
		.sort((a, b) => b.areaSize - a.areaSize);

	if (parsedVarieties.length > MAX_VAR_NUMBER) {
		const otherVarieties = parsedVarieties.slice(MAX_VAR_NUMBER);
		const otherVarietiesSummary = {
			category: otherCategoryName,
			areaSize: 0,
			areaUnit: areaUnit || AreaUnits.HECTARE,
		};

		otherVarieties.forEach((variety) => {
			otherVarietiesSummary.areaSize += variety.areaSize;
		});

		const deleteCount = parsedVarieties.length - MAX_VAR_NUMBER;

		parsedVarieties.splice(MAX_VAR_NUMBER, deleteCount, otherVarietiesSummary);
	}

	return parsedVarieties;
}

export default getSummarySectionData;
