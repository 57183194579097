import isArrayOf from 'vet/arrays/isArrayOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import { ValidatorType } from 'vet/types';

const _isTemperatureChartPoint = isShape({
	x: isNumber,
	y: isNumber,
});

const _isTemperatureChartSeries = isShape({
	options: isShape({
		data: isArrayOf(_isTemperatureChartPoint),
	}),
});

export type TTemperatureChartSeries = ValidatorType<
	typeof _isTemperatureChartSeries
>;

export const isTemperatureChartSeries: typeof _isTemperatureChartSeries =
	_isTemperatureChartSeries;
