import { WaterConsumptionUnits } from '../units';
import { toDisplayString } from '../units/transpileUnits';
import { TJob } from './isJob';

function jobWaterConsumptionPerArea(job: TJob, isImperial?: boolean) {
	const { driversWithEquipmentConfig } = job;

	const waterApplicationRate = driversWithEquipmentConfig?.reduce(
		(acc, current) => {
			const waterConsumptionConfig = current.equipment?.rate;
			if (waterConsumptionConfig) {
				return acc + Number(waterConsumptionConfig.value);
			}
			return acc;
		},
		0
	);

	if (waterApplicationRate) {
		return {
			amount: waterApplicationRate,
			unit: toDisplayString(
				isImperial
					? WaterConsumptionUnits.GALLONS_PER_ACRE
					: WaterConsumptionUnits.LITERS_PER_HECTARE
			),
		};
	} else {
		return {
			amount: 'N/A',
			unit: 'N/A',
		};
	}
}

export default jobWaterConsumptionPerArea;
