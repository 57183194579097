import React, { useState, useEffect } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CLEARED_FILTERS, URLFiltersType } from 'Models/history/isURLFilters';
import OptionsIcon from 'UI/icons/Options';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import useManageHistory from 'Hooks/useManageHistory';

import Categories from '../../../../components/filters/Categories';
import Date from '../../../../components/filters/Date';
import FieldsDropdown from '../../../../components/filters/FieldsDropdown';
import TeamMembers from '../../../../components/filters/TeamMembers';
import Teams from '../../../../components/filters/Teams';
import Varieties from '../../../../components/filters/Varieties';
import Drawer from '../../common/components/Drawer';
import ExportButton from './ExportButton';

const FilterModal = () => {
	const [open, setOpen] = useState(false);

	function toggleOpen() {
		setOpen((prevState) => !prevState);
	}

	const { t } = useTranslation();
	const { historyPush, paramsFromURL } = useManageHistory();
	const paramsFilters = paramsFromURL.filters;

	const [filters, setFilters] = useState<URLFiltersType>({
		...CLEARED_FILTERS,
	});

	useEffect(() => {
		setFilters({
			...paramsFilters,
			timeFrom: paramsFilters.timeFrom,
			timeTo: paramsFilters.timeTo,
			categories: paramsFilters.categories,
			fieldIds: paramsFilters.fieldIds,
			varieties: paramsFilters.varieties,
			teamId: paramsFilters.teamId,
			teamMemberIds: paramsFilters.teamMemberIds,
		});
	}, [open]);

	const onSearch = () => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFilters,
				timeFrom: filters.timeFrom,
				timeTo: filters.timeTo,
				categories: filters.categories,
				fieldIds: filters.fieldIds,
				varieties: filters.varieties,
				teamId: filters.teamId,
				teamMemberIds: filters.teamMemberIds,
			},
		};

		historyPush(newSearchParams);
		toggleOpen();
	};

	const onClear = () => {
		setFilters({ ...CLEARED_FILTERS });
	};

	return (
		<>
			<Box display="flex" flexGrow={1} justifyContent="space-between">
				<Button
					startIcon={<OptionsIcon />}
					variant="secondary"
					onClick={toggleOpen}
				>
					{t('buttons.filters')}
				</Button>
			</Box>
			<Drawer.Container onHideClick={toggleOpen} open={open}>
				<Drawer.Header onHideClick={toggleOpen} />
				<Drawer.Content>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.date')}
					>
						<Date filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.filters')}
					>
						<Grid container spacing={1}>
							<Grid item>
								<Categories filters={filters} updateFilters={setFilters} />
							</Grid>
							<Grid item>
								<Varieties filters={filters} updateFilters={setFilters} />
							</Grid>
							<Grid item>
								<FieldsDropdown filters={filters} updateFilters={setFilters} />
							</Grid>
							<Grid item>
								<TeamMembers filters={filters} updateFilters={setFilters} />
							</Grid>
						</Grid>
					</Drawer.ContentSection>
					<Drawer.ContentSection
						title={t('logsPages.filtersModal.sections.teams')}
					>
						<Teams filters={filters} updateFilters={setFilters} />
					</Drawer.ContentSection>
				</Drawer.Content>
				<Drawer.Footer>
					<Button variant={'text'} onClick={onClear}>
						{t('buttons.clear')}
					</Button>
					<Button onClick={onSearch}>{t('buttons.save')}</Button>
				</Drawer.Footer>
			</Drawer.Container>
		</>
	);
};

export default FilterModal;
