import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import TextField from 'UI/inputs/TextField';

import useSpanishPartner from 'Hooks/useSpanishPartner';

import {
	addTreatmentPageState,
	selectedGovernmentProduct,
} from '../../../../../state';
import { TAddTreatmentsModalInput } from '../../../../../types';

function ManufacturerName() {
	const { t } = useTranslation();

	const state = useReactiveVar(addTreatmentPageState);
	const selectedProduct = useReactiveVar(selectedGovernmentProduct);
	const { isSpanishPartner } = useSpanishPartner();

	const { input } = state;

	const product = input.product;
	const hasSpec = 'productSpec' in product;
	const manufacturerName =
		selectedProduct?.productSpec.manufacturerName ||
		(hasSpec && product.productSpec?.manufacturerName) ||
		'';

	const onChangeName = (value: string) => {
		if (!input.selectedGovernmentProduct) {
			addTreatmentPageState({
				...state,
				input: {
					...input,
					product: {
						...input.product,
						productSpec: {
							...input.product.productSpec,
							manufacturerName: value,
						},
					},
				},
			} as TAddTreatmentsModalInput);
		}
	};

	return (
		<TextField
			value={manufacturerName}
			label={t('labels.manufacturerName')}
			onChange={onChangeName}
			helperText={t('helperText.manufacturerProvidedBy', {
				name: 'Isourcel',
				date: '01.02.22',
			})}
			type="string"
			margin="dense"
			sx={{ marginBottom: '1rem' }}
			disabled={Boolean(selectedProduct)}
			required={isSpanishPartner && !selectedProduct}
		/>
	);
}

export default ManufacturerName;
