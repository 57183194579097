import React from 'react';

import Table from 'UI/display/Table';

import ErrorBoundary from './../../../../../components/ErrorBoundary';
import TableBody from './components/TableBody';
import TableHeaders from './components/TableHeaders';

const CurrentInventoryLogsTable = () => (
	<Table.Container style={{ overflow: 'auto', flex: 1 }}>
		<Table.Table stickyHeader aria-label="Current inventory logs table">
			<TableHeaders />
			<ErrorBoundary withinComponent>
				<TableBody />
			</ErrorBoundary>
		</Table.Table>
	</Table.Container>
);

export default CurrentInventoryLogsTable;
