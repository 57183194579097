import { TAuthUserObject, Provider } from '../types';

function getAuthenticationSource(user: TAuthUserObject) {
	if (user.sub) {
		const isEmail = user.sub.includes('auth0');
		if (isEmail) {
			return 'email';
		}

		const providers = Object.values(Provider);
		const provider = providers.find((provider) => user.sub?.includes(provider));

		return provider || null;
	}

	return null;
}

export { getAuthenticationSource };
