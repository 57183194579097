import React, { ReactElement } from 'react';

import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import { allCountries } from 'material-ui-phone-number/src/country_data';
import isString from 'vet/strings/isString';

const FALLBACK_COUNTRY_CODE = 'NO';
const NAVIGATOR_COUNTRY_CODE = navigator.language.slice(-2).toLocaleLowerCase();

//
// This function is a support for already saved in the database numbers without the dialing code
// due to material-ui-phone-number uses guessSelectedCountry feature which cannot be disabled
//
const addDiallingCode = (
	phoneNumber?: string,
	defaultCountry?: string
): string => {
	if (phoneNumber?.charAt(0) === '+') {
		return phoneNumber;
	}

	if (phoneNumber && defaultCountry) {
		const country = allCountries.find(
			(country) => country.iso2?.toUpperCase() === defaultCountry.toUpperCase()
		);

		if (country) {
			return country.dialCode.concat(phoneNumber);
		}
	}

	return '';
};

type TPhoneNumberProps = MuiPhoneNumberProps & {
	value?: string;
	onChange: (newValue: string, value?: string) => void;
};

const PhoneNumber = (props: TPhoneNumberProps): ReactElement => {
	const {
		placeholder,
		label,
		margin,
		onChange,
		value,
		fullWidth,
		helperText,
		type,
		disabled,
		error,
		inputProps = {},
		defaultCountry,
		...MUIProps
	} = props;

	const onChangeEvent = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
	) => {
		const newValue = isString(event) ? event : event.target.value;
		onChange(newValue, value);
	};

	const defaultCountryCode: string =
		defaultCountry ||
		allCountries.find((country) => country.iso2 === NAVIGATOR_COUNTRY_CODE)
			?.iso2 ||
		FALLBACK_COUNTRY_CODE;

	return (
		<MuiPhoneNumber
			fullWidth={fullWidth !== false}
			label={label}
			onChange={onChangeEvent}
			placeholder={placeholder}
			value={addDiallingCode(value, defaultCountryCode)}
			variant="outlined"
			helperText={helperText}
			type={type}
			disabled={disabled}
			error={error}
			defaultCountry={defaultCountryCode?.toLocaleLowerCase()}
			inputProps={
				margin === 'dense'
					? {
							style: {
								padding: '0.65rem 0.875rem',
								...inputProps?.style,
							},
							...inputProps,
					  }
					: undefined
			}
			{...MUIProps}
		/>
	);
};

export default PhoneNumber;
