import React from 'react';

import { TJobInputTreatment } from 'Models/jobs/JobInput';
import SubtractIcon from 'UI/icons/Subtract';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../../../state';
import SelectTreatment from './components/SelectTreatment';
import TreatmentAmount from './components/TreatmentAmount';
import TreatmentUnit from './components/TreatmentUnit';

type TTreatmentProps = {
	treatment: TJobInputTreatment;
};

const Treatment = (props: TTreatmentProps) => {
	const { originalTreatmentId } = props.treatment;
	const jobInput = useJobInputData();
	const status = useAddJobModalStatus();
	const isSingle = jobInput.jobDetails.treatments.length === 1;

	const handleDeleteTreatment = () => {
		const treatments = jobInput.jobDetails.treatments.filter(
			(treatment) => treatment.originalTreatmentId !== originalTreatmentId
		);

		jobInput.jobDetails.treatments = treatments;
		addJobModalState({
			status,
			jobInputData: { ...jobInput },
		});
	};

	return (
		<Box display="flex" marginTop="0.75rem">
			<Grid container item spacing={1.5}>
				<Grid item xs={12}>
					<SelectTreatment treatment={props.treatment} />
				</Grid>
				<Grid item xs={6}>
					<TreatmentAmount
						treatment={props.treatment}
						disabled={!originalTreatmentId}
					/>
				</Grid>
				<Grid item xs={6}>
					<TreatmentUnit
						treatment={props.treatment}
						disabled={!originalTreatmentId}
					/>
				</Grid>
			</Grid>
			<Box marginLeft="1rem" marginTop="0.75rem">
				<IconButton
					onClick={handleDeleteTreatment}
					disabled={isSingle}
					size="small"
					sx={{
						borderWidth: 1,
						borderStyle: 'solid',
						borderColor: FarmableColors.BLACK_12,
					}}
				>
					<SubtractIcon color={isSingle ? 'disabled' : 'action'} />
				</IconButton>
			</Box>
		</Box>
	);
};

export default Treatment;
