import { TRevenueData } from '../../../api/getRevenueChartData';

export type TRevenueDataWithDates = {
	totalRevenue: number;
	currency: string;
	date: string;
};

export type TSortDataPerCurrencyResult = {
	[key: string]: TRevenueData[];
};

function sortDataPerCurrency(data: TRevenueData[]): TSortDataPerCurrencyResult {
	return data.reduce((dataPerCurrency, data) => {
		if (data.currency) {
			const currencyData = dataPerCurrency[data.currency];
			if (!currencyData) {
				dataPerCurrency[data.currency] = [data];
			} else {
				currencyData.push(data);
			}
		}
		return dataPerCurrency;
	}, {});
}

export default sortDataPerCurrency;
