import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TLocation } from 'Models/locations/isLocation';
import { TPartnerJob } from 'Models/partnershipCompany/PartnerJob';

export type TPartnerJobTableData = Pick<
	TPartnerJob,
	| 'id'
	| 'jobStatus'
	| 'jobType'
	| 'completedDate'
	| 'jobCauses'
	| 'customJobType'
	| 'treatments'
	| 'jobFields'
	| 'location'
	| 'author'
>;

export type TGetPartnerJobsTableResponse = {
	getPartnerJobs: {
		totalPages: number;
		totalElements: number;
		jobs: {
			job: TPartnerJobTableData;
			location: TLocation;
		}[];
	};
};

type TGetPartnerJobsTableRequest = {
	filterBy?: {
		jobFilters?: Partial<{
			jobStatuses: string[];
			jobTypes: string[];
			completedDateFrom: string;
			completedDateTo: string;
			fieldCategories: string[];
			locations: string[];
			fieldIds: string[];
		}>;
		locationIds?: string[];
	};
	pageInput?: TPageInput;
	hasPagination?: boolean;
};

const GET_PARTNER_JOBS_TABLE_DATA: TypedDocumentNode<
	TGetPartnerJobsTableResponse,
	TGetPartnerJobsTableRequest
> = gql`
	query getPartnerJobsTableData(
		$filterBy: PartnerJobFilterInput
		$pageInput: PageInput
	) {
		getPartnerJobs(filterBy: $filterBy, pageInput: $pageInput) {
			totalPages
			totalElements
			jobs {
				location {
					id
					name
				}
				job {
					id
					jobType
					jobStatus
					completedDate
					jobCauses {
						id
						name
						causeType
					}
					customJobType {
						id
						name
					}
					treatments {
						originalProduct {
							name
						}
					}
					jobFields {
						id
						fieldVarieties {
							id
							name
							category
						}
						areaSize
					}
					author {
						id
						firstName
						lastName
					}
				}
			}
		}
	}
`;

export default GET_PARTNER_JOBS_TABLE_DATA;
