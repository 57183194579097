import React from 'react';

import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Page from 'UI/layout/Page';

const SplashPage = (props) => {
	return (
		<Page center>
			<LoadingSpinner />
			{props.children}
		</Page>
	);
};

export default SplashPage;
