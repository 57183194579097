import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductInventoryLog } from 'Models/inventory/ProductInventoryLog';
import LogsTableHeaderValues from 'Models/jobs/jobLogsTableData';
import Table from 'UI/display/Table';

import getInvMovementLogsRowData from '../../../utils/getInvMovementLogsRowData';

type TInvMovementLogsTableRowProps = {
	log: TProductInventoryLog;
};

const TableRow = (props: TInvMovementLogsTableRowProps) => {
	const { log } = props;
	const { t, i18n } = useTranslation();

	return (
		<Table.Row hover>
			{getInvMovementLogsRowData(log, t, i18n.language).map((cell, index) => (
				<Table.Cell key={`cell-${LogsTableHeaderValues[index] || index}`}>
					{cell}
				</Table.Cell>
			))}
		</Table.Row>
	);
};

export default TableRow;
