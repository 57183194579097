import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function FieldTypeIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				fillRule="nonzero"
				d="M20.5 19c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0-5.667c.828 0 1.5.672 1.5 1.5 0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5zm0-5.666c.828 0 1.5.671 1.5 1.5 0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5c0-.829.672-1.5 1.5-1.5zM20.5 2c.828 0 1.5.672 1.5 1.5S21.328 5 20.5 5 19 4.328 19 3.5 19.672 2 20.5 2zm-5.667 17c.829 0 1.5.672 1.5 1.5s-.671 1.5-1.5 1.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5zm0-5.667c.829 0 1.5.672 1.5 1.5 0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5zm0-5.666c.829 0 1.5.671 1.5 1.5 0 .828-.671 1.5-1.5 1.5-.828 0-1.5-.672-1.5-1.5 0-.829.672-1.5 1.5-1.5zm0-5.667c.829 0 1.5.672 1.5 1.5s-.671 1.5-1.5 1.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5zM9.167 19c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5c-.829 0-1.5-.672-1.5-1.5s.671-1.5 1.5-1.5zm0-5.667c.828 0 1.5.672 1.5 1.5 0 .829-.672 1.5-1.5 1.5-.829 0-1.5-.671-1.5-1.5 0-.828.671-1.5 1.5-1.5zm0-5.666c.828 0 1.5.671 1.5 1.5 0 .828-.672 1.5-1.5 1.5-.829 0-1.5-.672-1.5-1.5 0-.829.671-1.5 1.5-1.5zm0-5.667c.828 0 1.5.672 1.5 1.5S9.995 5 9.167 5c-.829 0-1.5-.672-1.5-1.5S8.338 2 9.167 2zM3.5 19c.828 0 1.5.672 1.5 1.5S4.328 22 3.5 22 2 21.328 2 20.5 2.672 19 3.5 19zm0-5.667c.828 0 1.5.672 1.5 1.5 0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5zm0-5.666c.828 0 1.5.671 1.5 1.5 0 .828-.672 1.5-1.5 1.5S2 9.995 2 9.167c0-.829.672-1.5 1.5-1.5zM3.5 2C4.328 2 5 2.672 5 3.5S4.328 5 3.5 5 2 4.328 2 3.5 2.672 2 3.5 2z"
				transform="translate(-323 -216) translate(16 116) translate(175 88) translate(132 12)"
			/>
		</SvgIcon>
	);
}

export default FieldTypeIcon;
