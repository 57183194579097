// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ILYfIhF_RTBsbvzo1N0Re {\n  overflow: visible;\n}\n\n._1ztAdfwCJeGjC1sTtSEzsv {\n  pointer-events: none;\n}\n\n._311awRpxMCo1Wd42OYYLdi {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 1.25rem 1.5rem;\n}\n\n._311awRpxMCo1Wd42OYYLdi:hover {\n  background-color: #f8f8f8;\n}\n\n._3PllrROPwbX9eN8OE_TmJ3 {\n  padding-top: 0 !important;\n  padding-bottom: 0 !important;\n}\n\n", ""]);
// Exports
exports.locals = {
	"disableClipping": "_2ILYfIhF_RTBsbvzo1N0Re",
	"noPointerEvents": "_1ztAdfwCJeGjC1sTtSEzsv",
	"modalOption": "_311awRpxMCo1Wd42OYYLdi",
	"noVerticalPadding": "_3PllrROPwbX9eN8OE_TmJ3"
};
module.exports = exports;
