import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Tooltip from 'UI/display/Tooltip';
import Info from 'UI/icons/Info';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import BetaTag from '../../BetaTag';
import styles from '../styles.module.scss';

type TProps = {
	seasonFilter?: ReactElement;
};

const Header = (props: TProps): ReactElement => {
	const { seasonFilter } = props;
	const { t } = useTranslation();

	const tooltipContent = (
		<Box padding="0.25rem">
			<Text variant="body2">
				{t('farmDashboardPage.profitChart.infoTooltip')}
			</Text>
		</Box>
	);

	const TooltipBase = React.forwardRef<HTMLDivElement>((props, ref) => (
		<div {...props} className={styles.tooltipBase} ref={ref}>
			<Info color="secondary" />
		</div>
	));

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			paddingX="1.5rem"
			paddingY="1rem"
		>
			<Box display="flex" alignItems="center">
				<Box marginRight="0.125rem">
					<Text variant={'h4'}>{t('farmDashboardPage.profitChart.title')}</Text>
				</Box>
				<Tooltip
					arrow
					placement="top"
					title={tooltipContent}
					arrowColor={FarmableColors.LIGHT_GREY}
					backgroundColor={FarmableColors.LIGHT_GREY}
					boxShadow
				>
					<TooltipBase />
				</Tooltip>
				<BetaTag />
			</Box>
			{seasonFilter}
		</Box>
	);
};

export default Header;
