import React, { ReactElement, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { analyticsTrack, TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useHistory from 'Features/router/hooks/useHistory';
import getOrderStatusURL from 'Models/history/getOrderStatusURL';
import { TSubscriptionType } from 'Models/locations/isLocationSubscriptions';
import ChevronRight from 'UI/icons/ChevronRight';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';
import Breakpoints from 'UI/theme/breakpoints';
import useBreakpoint from 'UI/utils/useBreakpoint';
import URI from 'urijs';

import useLocationID from 'Hooks/useLocationID';
import useLocationSubscriptions from 'Hooks/useLocationSubscriptions';
import useLocations from 'Hooks/useLocations';
import useProfile from 'Hooks/useProfile';

import CREATE_CHECKOUT_SESSION from '../../features/stripe/api/createCheckoutSession';
import { TStripe } from '../../features/stripe/hooks/useStripe';
import { isPurchasedProduct } from '../../features/stripe/models/isPurchasedProduct';
import getStripeModules from '../../features/stripe/utils/getStripeModules';

type TRedirectToStripeButton = {
	priceId: string;
	stripe: TStripe;
	onStripeProcessingStarted?: () => void;
	onError?: (error) => void;
	productId: string;
	moduleName: TSubscriptionType;
};

const RedirectToStripeButton = (
	props: TRedirectToStripeButton
): ReactElement => {
	const { priceId, stripe, onStripeProcessingStarted, moduleName, productId } =
		props;
	const { t } = useTranslation();
	const history = useHistory();
	const isMobile = useBreakpoint(Breakpoints.MOBILE);
	const locationId = useLocationID();
	const { profile } = useProfile();
	const { locations } = useLocations();
	const { locationSubscriptions, loading } = useLocationSubscriptions();
	const stripeModules = getStripeModules();
	const [isRedirecting, setIsRedirecting] = useState(false);
	const [createCheckoutSession, { data, error }] = useMutation(
		CREATE_CHECKOUT_SESSION
	);

	const location = locations?.find((location) => location.id === locationId);

	const successUrl = getOrderStatusURL('success');
	const cancelUrl = getOrderStatusURL('canceled');

	const envStripeProduct = stripeModules[productId];
	const isSIEX = envStripeProduct.id.includes('siex');

	useEffect(() => {
		if (error) {
			// backend error
			props.onError?.(error);
		}
		if (data) {
			stripe
				.redirectToCheckout({
					sessionId: data.createCheckoutSession,
				})
				.then((result) => {
					// stripe error
					if (result.error) {
						props.onError?.(result.error);
					}
				})
				.catch((error) => {
					// If the promise throws an error
					props.onError?.(error);
				});
		}
	});
	function redirectToStripe() {
		if (!isRedirecting && profile && location && locationSubscriptions) {
			const checkoutParams = {
				priceId: priceId,
				mode: 'SUBSCRIPTION' as const,
				quantity: 1,
				successUrl,
				cancelUrl,
			};

			setIsRedirecting(true);

			onStripeProcessingStarted?.();

			const isPurchased =
				envStripeProduct?.features &&
				locationSubscriptions?.featureFlags &&
				isPurchasedProduct(
					envStripeProduct?.features,
					locationSubscriptions?.featureFlags
				);

			if (!isPurchased) {
				void createCheckoutSession({
					variables: {
						locationId,
						checkoutParams,
					},
				}).then(() => {
					analyticsTrack(TrackEvents.CheckoutStarted, {
						userId: profile.id,
						groupId: locationId,
						farmName: location.name,
						moduleName: moduleName,
					});
				});
			} else {
				const currentURL = window.location.href;
				const uri = new URI(currentURL);
				const path = uri.path();
				history.push(path + '?&os=rejected');
			}
		}
	}

	const purchaseButtonText = isSIEX
		? t('storePage.purchaseSIEXButton')
		: t('storePage.purchaseButton');

	return (
		<IconButton
			disabled={(isRedirecting && !profile && !location) || loading}
			onClick={redirectToStripe}
			size="large"
		>
			{isRedirecting || loading ? (
				<LoadingSpinner width={40} height={40} />
			) : isMobile ? (
				<Box marginLeft="-0.75rem">
					<Button>{purchaseButtonText}</Button>
				</Box>
			) : (
				<ChevronRight color="secondary" />
			)}
		</IconButton>
	);
};

export default RedirectToStripeButton;
