import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AdvisoryOrganizationIcon(props: SvgIconProps) {
	const dimension = 112;

	return (
		<SvgIcon
			viewBox="0 0 112 112"
			width={props.width || dimension}
			height={props.height || dimension}
			{...props}
		>
			<g fill="none" fillRule="evenodd">
				<path
					d="M87 22a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm7 10v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zM21 88a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm7 10v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zm63-21a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm7 10v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zM54 57a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm7 10v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zM25 25a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm7 10v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14z"
					fillOpacity=".12"
					fill="#D83E14"
				/>
				<path
					d="m38 89 36-9m17-19-3.35-20M70 24l-28 2m-5 12.218 5.722 6.76m21.25-.672L75 36.526M40.767 69 32 75.939M24.363 43 21 72m55.551-3-7.754-2.5M91 95c9.389 0 17-7.611 17-17s-7.611-17-17-17-17 7.611-17 17 7.611 17 17 17zm7-8v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zm-7-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm-70 29c9.389 0 17-7.611 17-17s-7.611-17-17-17S4 79.611 4 89s7.611 17 17 17zm7-8v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zm-7-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm33-13c9.389 0 17-7.611 17-17s-7.611-17-17-17-17 7.611-17 17 7.611 17 17 17zm7-8v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zm-7-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm33-17c9.389 0 17-7.611 17-17S96.389 6 87 6s-17 7.611-17 17 7.611 17 17 17zm7-8v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zm-7-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM25 43c9.389 0 17-7.611 17-17S34.389 9 25 9 8 16.611 8 26s7.611 17 17 17zm7-8v-2a4 4 0 0 0-4-4h-6a4 4 0 0 0-4 4v2h14zm-7-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
					stroke="#D83E14"
					strokeWidth="2"
				/>
			</g>
		</SvgIcon>
	);
}

export default AdvisoryOrganizationIcon;
