import { gql, TypedDocumentNode } from '@apollo/client';

export type TFieldGeometry = {
	id: string;
	name: string;
	geometry: [{ x: number; y: number }][];
};

export type TFieldsGeometryResponse = {
	getFields: {
		fields: TFieldGeometry[];
	};
};

export const GET_FIELDS_GEOMETRY_QUERY: TypedDocumentNode<
	TFieldsGeometryResponse,
	{ locationId: string }
> = gql`
	query getFieldsGeometry($locationId: ID!) {
		getFields(locationId: $locationId) {
			fields {
				id
				geometry {
					x
					y
				}
			}
		}
	}
`;
