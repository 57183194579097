import React, { ReactElement, ReactNode } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Collapse from 'UI/display/Collapse';

import styles from './styles.module.scss';

type OptionalDetailsProps = {
	children: ReactNode;
};

function OptionalDetails(props: OptionalDetailsProps): ReactElement {
	const { t } = useTranslation();

	return (
		<Collapse.Uncontrolled buttonTitle={t('buttons.optionalDetails')}>
			<div className={styles.optionalDetails}>{props.children}</div>
		</Collapse.Uncontrolled>
	);
}

export default OptionalDetails;
