import React, { ReactElement } from 'react';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import useProfile from 'Hooks/useProfile';

import NoAccessPage from '../../../no-access/NoAccessPage';

type TPageValidatorProps = {
	children: ReactElement;
};

const PageAccessValidator = (props: TPageValidatorProps): ReactElement => {
	const { profile } = useProfile();
	const { company } = usePartnerCompany();
	const { children } = props;

	const hasCompanyCreateAccess = profile?.enabledCompanyType && !company;

	return <>{hasCompanyCreateAccess ? children : <NoAccessPage />}</>;
};

export default PageAccessValidator;
