import React, { ReactElement } from 'react';

import Chip from 'UI/display/Chip';
import CheckIcon from 'UI/icons/Check';
import { FarmableColors } from 'UI/theme/Colors';

import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

type TFilterTag = {
	name: string;
	value: string | number;
};

type TFilterTagCustom = {
	customLabel: string;
};

const FilterTag = (
	props: TFilterTag | TFilterTagCustom
): ReactElement | null => {
	const label =
		'customLabel' in props
			? props.customLabel
			: `${props.name}: ${stringToFirstUppercase(String(props.value))}`;

	return (
		<Chip
			chipColor={FarmableColors.ORANGE}
			label={label}
			startIcon={<CheckIcon sx={{ fill: FarmableColors.ORANGE }} />}
		/>
	);
};

export default FilterTag;
