import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import MoreIcon from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Menu from 'UI/navigation/Menu';

import { TPartnerCause } from '../types';
import DeleteCauseModal from './DeleteCauseModal';
import EditCauseModal from './EditCauseModal';

type TCauseDropdownProps = {
	cause: TPartnerCause;
};

const CauseDropdown = (props: TCauseDropdownProps) => {
	const { t } = useTranslation();
	const { cause } = props;

	const [isEditModalOpen, setEditModalOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onEditInfoClick = () => {
		setEditModalOpen(true);
		handleClose();
	};

	return (
		<>
			<IconButton size="medium" onClick={handleClick}>
				<MoreIcon />
			</IconButton>
			<Menu.Menu
				id="partner-member-menu"
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Menu.Item onClick={onEditInfoClick}>
					{t('buttons.editCause')}
				</Menu.Item>
				<Divider sx={{ marginY: '0.5rem' }} />
				<DeleteCauseModal cause={cause} />
			</Menu.Menu>
			<EditCauseModal
				isModalOpen={isEditModalOpen}
				modalStateHandler={setEditModalOpen}
				cause={cause}
			/>
		</>
	);
};

export default CauseDropdown;
