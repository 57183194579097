import React from 'react';

import Box from 'UI/layout/Box';

import LogsPageHeader from '../../../components/logs/LogsPageHeader';
import LogsPageLayout from '../../../components/logs/LogsPageLayout';
import { CreateReportButton } from '../CreateReport';
import TableTabs from '../common/components/TableTabs';
import CurrentInventoryLogsTable from './components/CurrentInventoryLogsTable';
import Filters from './components/Filters';
import Pagination from './components/Pagination';

function CurrentInventoryLogsPage() {
	return (
		<LogsPageLayout>
			<LogsPageHeader>{<CreateReportButton />}</LogsPageHeader>
			<TableTabs>
				<Box display="inline-flex" justifyContent="flex-end" margin="0 1rem">
					<Filters />
				</Box>
				<CurrentInventoryLogsTable />
				<Pagination />
			</TableTabs>
		</LogsPageLayout>
	);
}

export default CurrentInventoryLogsPage;
