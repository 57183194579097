import React, { ReactElement, ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';

type TScrollableListProps = {
	children?: ReactNode;
	maxHeight: number;
};

const ScrollableList = (props: TScrollableListProps): ReactElement => {
	const { children, maxHeight } = props;
	const useScrollableListStyles = makeStyles({
		scrollableList: {
			maxHeight: maxHeight,
			height: '100%',
			overflow: 'auto',
		},
	});

	const styles = useScrollableListStyles();

	return <div className={styles.scrollableList}>{children}</div>;
};

export default ScrollableList;
