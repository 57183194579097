import React, { ReactElement, useState } from 'react';

import { CollapseProps } from '@mui/material/Collapse';
import makeStyles from '@mui/styles/makeStyles';

import ChevronLeft from '../../icons/ChevronLeft';
import Box from '../../layout/Box';
import Text from '../Text';
import Controlled from './Controlled';

type TUncontrolledCollapseProps = {
	buttonTitle: string;
} & CollapseProps;

const useStyles = makeStyles({
	clickableArea: {
		cursor: 'pointer',
	},
});

const Uncontrolled = (props: TUncontrolledCollapseProps): ReactElement => {
	const { buttonTitle, children } = props;
	const [checked, setChecked] = useState(false);

	const styles = useStyles();

	const icon = checked ? (
		<ChevronLeft color="action" style={{ transform: 'rotate(90deg)' }} />
	) : (
		<ChevronLeft color="action" style={{ transform: 'rotate(-90deg)' }} />
	);

	return (
		<>
			<Box
				display="flex"
				className={styles.clickableArea}
				alignItems="center"
				onClick={() => setChecked(!checked)}
			>
				<Text variant="label" overrideMUIColor="ORANGE">
					{buttonTitle}
				</Text>
				{icon}
			</Box>
			<Controlled in={checked}>{children}</Controlled>
		</>
	);
};

export default Uncontrolled;
