import React from 'react';

import {
	TextField as MUITextField,
	OutlinedInputProps,
	TextFieldProps,
} from '@mui/material';

export type TTextFieldProps = {
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	value?: string;
	fullWidth?: boolean;
	onChange?: (newValue: any, value?: string) => void;
	helperText?: string;
	type?: string;
	icon?: Partial<OutlinedInputProps>;
	error?: boolean;
} & Omit<TextFieldProps, 'onChange'>;

const TextField = (props: TTextFieldProps): JSX.Element => {
	const {
		placeholder,
		label,
		margin,
		onChange,
		value,
		fullWidth,
		helperText,
		type,
		disabled,
		icon,
		error,
		...MUIProps
	} = props;

	const onChangeEvent = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValue = event.target.value;
		if (onChange) {
			onChange(newValue, value);
		}
	};

	return (
		<MUITextField
			fullWidth={fullWidth !== false}
			label={label}
			onChange={onChangeEvent}
			placeholder={placeholder}
			value={value}
			variant="outlined"
			helperText={helperText}
			type={type}
			disabled={disabled}
			InputProps={icon}
			error={error}
			margin={margin}
			{...MUIProps}
		/>
	);
};

export default TextField;
