import Item, { TItem } from './item';
import Menu, { TMenu } from './menu';

export type Theme = TMenu & TItem;

const Export = {
	Menu,
	Item,
};

export default Export;
