import { AreaUnits } from 'Models/units';

import useLocationID from './useLocationID';
import useLocations from './useLocations';

function useImperialUnits(): boolean | null {
	const { locations } = useLocations();
	const locationID = useLocationID();
	const location = locations?.find((location) => location.id === locationID);
	const isImperial = location?.areaUnit === AreaUnits.ACRES;

	return location ? isImperial : null;
}

export default useImperialUnits;
