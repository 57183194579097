import round from '../units/round';
import { TJob } from './isJob';
import jobDurationTimeInMs from './jobDurationTimeInMs';

const MILLISECONDS_IN_HOUR = 3600 * 1000;

function jobDurationTime(job: TJob): number | string {
	if (job.startDate && job.completedDate) {
		const jobDurationInMs = jobDurationTimeInMs(job) || 0;
		const hasPositiveDatesDifference = jobDurationInMs > 0;

		if (jobDurationInMs && hasPositiveDatesDifference) {
			const durationInHours = jobDurationInMs / MILLISECONDS_IN_HOUR;
			return round(durationInHours, 2);
		}

		return '-';
	}

	return '-';
}

export default jobDurationTime;
