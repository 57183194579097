import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TNotification } from 'Models/notification/isNotification';

type TNotificationFilterInput = { fieldId: string };

export type TGetNotificationsResponse = {
	getNotifications: { totalElements: number; notifications: TNotification[] };
};

type TGetNotificationsRequest = {
	locationId: string;
	pageInput: TPageInput;
	filterBy?: TNotificationFilterInput;
};

const GET_NOTIFICATIONS_QUERY: TypedDocumentNode<
	TGetNotificationsResponse,
	TGetNotificationsRequest
> = gql`
	query getDashboardNotifications(
		$locationId: ID!
		$filterBy: NotificationFilterInput
		$pageInput: PageInput!
	) {
		getNotifications(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalElements
			notifications {
				id
				type
				createdAt
				seen
				createdBy {
					id
					firstName
					lastName
					profileImage {
						id
						path
						filename
						mimetype
						createdAt
					}
				}
				entity {
					... on Job {
						id
						jobFields: fields {
							id
							name
						}
					}
					... on Note {
						id
					}
					... on FieldNote {
						id
						fieldNoteField: field {
							id
							name
						}
					}
					... on FieldNoteComment {
						id
					}
					... on Harvest {
						id
						harvestFields: fields {
							id
							name
						}
					}
					... on Timesheet {
						id
						timesheetFields: fields {
							id
							name
						}
					}
				}
				parentEntity {
					... on Job {
						id
						jobFields: fields {
							id
							name
						}
					}
					... on FieldNote {
						id
						fieldNoteField: field {
							id
							name
						}
					}
				}
			}
		}
	}
`;

export default GET_NOTIFICATIONS_QUERY;
