enum TrackEvents {
	ProductViewed = 'Product viewed',
	CheckoutStarted = 'Checkout started',
	ProductModified = 'Product modified',
	DataExported = 'Data exported',
	DataDownloaded = 'Data downloaded',
	AccountModified = 'Account modified',
	SignedIn = 'Signed In',
	SignedUp = 'Signed Up',
	SignedOut = 'Signed Out',
	NoteCreated = 'Note created',
	SeeDemoReportClicked = '"See demo report" clicked',
	TeamDeleted = 'Team deleted',
	TeamCreated = 'Team created',
	TeamModified = 'Team modified',
}

export default TrackEvents;
