import isNull from 'vet/isNull';
import isOneOf from 'vet/isOneOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

const _isCurrentWeather = isShape.partial({
	sunrise: isString,
	sunset: isString,
	temp: isNumber,
	feelsLike: isNumber,
	pressure: isNumber,
	humidity: isNumber,
	dewPoint: isNumber,
	windSpeed: isNumber,
	windGust: isNumber,
	windDeg: isNumber,
	clouds: isNumber,
	uvi: isNumber,
	visibility: isNumber,
	rain: isOneOf(isNumber, isNull),
	snow: isNumber,
});

const _isBasicWeather = isShape.partial({
	temp: isNumber,
	windSpeed: isNumber,
	windDeg: isNumber,
	rain: isNumber,
});

export const isCurrentWeather: typeof _isCurrentWeather = _isCurrentWeather;
export const isBasicWeather: typeof _isBasicWeather = _isBasicWeather;

export type TCurrentWeather = ValidatorType<typeof isCurrentWeather>;
export type TBasicWeather = ValidatorType<typeof isBasicWeather>;
