import React, {
	Dispatch,
	FormEvent,
	ReactElement,
	SetStateAction,
	useEffect,
	useRef,
} from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import AddPhotoIcon from 'UI/icons/AddPhoto';
import Button, { TButtonProps } from 'UI/inputs/Button';

import ImagesPreview from '../ImagesPreview';
import styles from './styles.module.scss';

type TSelectedFile = {
	file: File;
	preview: string;
};

type TUploadImageProps = {
	maxAmount?: number;
	selectedImages: TSelectedFile[];
	selectedImagesControl: Dispatch<SetStateAction<TSelectedFile[]>>;
	children?: ReactElement;
	ButtonProps?: TButtonProps;
};

export const UploadImage = (props: TUploadImageProps): ReactElement => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const {
		selectedImages,
		selectedImagesControl,
		maxAmount,
		children,
		ButtonProps,
	} = props;

	const defaultButton = (
		<Button
			className={styles.addImageButton}
			variant="outlined"
			color="primary"
			onClick={handleClick}
		>
			<AddPhotoIcon color="action" />
		</Button>
	);

	const { t } = useTranslation();

	// Cleanup ObjectURL when component unmounts
	useEffect(() => {
		return () => {
			selectedImages?.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	});

	function onSelectFile(event: FormEvent<HTMLInputElement>): void {
		const target = event.target as HTMLInputElement;

		if (!target.files || target.files.length === 0) {
			selectedImagesControl([]);
			return;
		}

		const newFiles = Array.from(target.files).map((file) => {
			return { file, preview: URL.createObjectURL(file) };
		});

		selectedImagesControl((prevState) => [...prevState, ...newFiles]);
	}

	function deleteFile(index: number): void {
		selectedImagesControl((prevState) => {
			return prevState?.filter((file, fileIndex) => fileIndex !== index);
		});

		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	}

	function handleClick(): void {
		fileInputRef.current?.click();
	}

	return (
		<section className={styles.previewSection}>
			{!maxAmount || (maxAmount && maxAmount > selectedImages.length) ? (
				<label>
					<input
						accept="image/*"
						className={styles.fileInput}
						multiple={!!(maxAmount && maxAmount > 1)}
						type="file"
						onChange={onSelectFile}
						ref={fileInputRef}
					/>
					{children ? (
						<Button onClick={handleClick} variant="text" {...ButtonProps}>
							{children}
						</Button>
					) : (
						defaultButton
					)}
				</label>
			) : null}
			<ImagesPreview
				images={selectedImages}
				imagesAlt={t('common.uploadImages.altText')}
				onDelete={deleteFile}
			/>
		</section>
	);
};

export default UploadImage;

export type { TSelectedFile };
