import { ApolloError, useQuery } from '@apollo/client';
import { AggregatedSalesGroupOption } from 'Models/sales/isAggregatedSalesGroupOption';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useFieldID from 'Hooks/useFieldID';
import useManageHistory from 'Hooks/useManageHistory';
import getSeasonParams from 'Utils/getSeasonParams';

import getProfitChartData from '../../../../../../components/ProfitChart/utils/getProfitChartData';
import GET_AGGR_PRODUCTS_COST_QUERY from '../api/getAggregatedProductsCost';
import GET_AGGR_SALES_QUERY from '../api/getAggregatedSales';
import GET_AGGR_HOURS_COST_QUERY from '../api/getAggregatedWorkingHoursCost';
import sortByMonth from '../utils/sortByMonth';

const useProfitChartData = () => {
	const location = useCurrentLocation();
	const fieldId = useFieldID();
	const { paramsFromURL } = useManageHistory();
	let data;
	const seasonParams = getSeasonParams(
		paramsFromURL.filters,
		location?.seasonCutoffDate
	);
	const variables = {
		locationId: location?.id || '',
		filterBy: {
			dateFrom: seasonParams?.from,
			dateTo: seasonParams?.to,
			fieldId,
		},
	};

	const costsTask = useQuery(GET_AGGR_PRODUCTS_COST_QUERY, {
		skip: !location || !seasonParams,
		variables,
	});

	const hoursTask = useQuery(GET_AGGR_HOURS_COST_QUERY, {
		skip: !location || !seasonParams,
		variables,
	});

	const salesTask = useQuery(GET_AGGR_SALES_QUERY, {
		skip: !location || !seasonParams,
		variables: {
			locationId: location?.id || '',
			query: {
				filterBy: {
					dateFrom: seasonParams.from,
					dateTo: seasonParams.to,
					fieldId,
				},
				groupBy: AggregatedSalesGroupOption.MONTH,
			},
		},
	});

	if (costsTask.error) {
		throw new ApolloError(costsTask.error);
	}
	if (salesTask.error) {
		throw new ApolloError(salesTask.error);
	}
	if (hoursTask.error) {
		throw new ApolloError(hoursTask.error);
	}

	if (
		seasonParams &&
		location &&
		costsTask.data &&
		salesTask.data &&
		hoursTask.data
	) {
		data = getProfitChartData(
			{
				productsCosts: sortByMonth(
					costsTask.data.getAggregatedProductsCost.productsCosts
				),
				workLogsCosts: sortByMonth(
					hoursTask.data.getAggregatedWorkingHoursCost.workLogsCosts
				),
				sales: sortByMonth(salesTask.data.getAggregatedSales),
			},
			seasonParams
		);
	}

	return {
		loading: costsTask.loading || salesTask.loading || hoursTask.loading,
		data,
	};
};

export default useProfitChartData;
