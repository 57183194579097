import set from 'lodash/set';

import { TSupportedLang } from '../utils/isSupportedLang';
import { TSchema } from './_schema';
import partnerPortalTranslations from './translations/partner-portal/_translations.json';
import webPortalTranslations from './translations/web-portal/_translations.json';

const translations = [...webPortalTranslations, ...partnerPortalTranslations];

const table = translations.reduce((table, row) => {
	for (const key in row) {
		if (key !== 'path') {
			const value = row[key];
			const translation = value === '' ? undefined : value;
			table[key] = table[key] != null ? table[key] : { translation: {} };
			set(table[key].translation, row.path, translation);
		}
	}

	return table;
}, {} as Record<TSupportedLang, TSchema>);

export default table;

export type TTranslations = typeof table;
