import { TEmployeeTableData } from '../api/getEmployeesTableData';

function getEmployeeLogsRowData(employee: TEmployeeTableData) {
	return Object.values({
		name: employee.name,
		id: employee.id,
		email: employee.email || '-',
		hours: Math.round(employee.workHours * 2) / 2,
	});
}

export default getEmployeeLogsRowData;
