import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { ProductUnitsArray, TProductUnits } from 'Models/units';
import NumericField from 'UI/inputs/NumericField';
import Select from 'UI/inputs/Select';
import Grid from 'UI/layout/Grid';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import {
	getError,
	treatmentInputState,
	treatmentInputValidationError,
} from '../../../../../state';

function WaterMixRate() {
	const { t } = useTranslation();
	const treatmentInput = useReactiveVar(treatmentInputState);
	const inputErrors = useReactiveVar(treatmentInputValidationError);

	if (!treatmentInput) {
		return null;
	}

	const { waterMin, waterMax, waterUnit } = treatmentInput;

	const waterMinError = getError('waterMin', inputErrors);
	const waterMaxError = getError('waterMax', inputErrors);

	return (
		<ModalSection title={t('labels.waterMixReate')} noDivider>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<NumericField
						placeholder={t('labels.from')}
						value={waterMin?.toString()}
						onValueParsed={(value: number) =>
							treatmentInputState({
								...treatmentInput,
								waterMin: value,
							})
						}
						InputProps={{ inputProps: { min: 0 } }}
						margin="dense"
						inputValidator={() => !waterMinError}
						errorHelperText={t(
							'treatmentLibrary.cropModal.error.valueBelowZero'
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<NumericField
						placeholder={t('labels.to')}
						value={waterMax?.toString()}
						onValueParsed={(value: number) =>
							treatmentInputState({
								...treatmentInput,
								waterMax: value,
							})
						}
						InputProps={{ inputProps: { min: 0 } }}
						margin="dense"
						inputValidator={() => !waterMaxError}
						helperText={
							waterMaxError
								? t('treatmentLibrary.cropModal.error.valueBelowZero')
								: undefined
						}
					/>
				</Grid>
				<Grid item xs={4}>
					<Select
						value={waterUnit || ''}
						label={t('labels.unit')}
						options={ProductUnitsArray}
						optionFormatter={(value) =>
							t(`apiConstants.productUnits.${value as TProductUnits}`)
						}
						changeHandler={(value: TProductUnits) =>
							treatmentInputState({
								...treatmentInput,
								waterUnit: value,
							})
						}
						small
					/>
				</Grid>
			</Grid>
		</ModalSection>
	);
}

export default WaterMixRate;
