import isOneOf from 'vet/isOneOf';
import { ValidatorType, ExtendedValidator } from 'vet/types';

const GRAM = 'GRAM';
const KILOGRAM = 'KILOGRAM';
const TON = 'TON';
const POUND = 'POUND';
const GALLON = 'GALLON';
const OUNCE = 'OUNCE';
const THOUSANDS_OF_POUNDS = 'THOUSANDS_OF_POUNDS';
const MILLILITER = 'MILLILITER';
const FLUID_OUNCE = 'FLUID_OUNCE';
const LITER = 'LITER';
const METER = 'METER';

const GRAM_PER_SQUARE_METER = 'GRAM_PER_SQUARE_METER';
const KILOGRAM_PER_SQUARE_METER = 'KILOGRAM_PER_SQUARE_METER';
const LITER_PER_SQUARE_METER = 'LITER_PER_SQUARE_METER';
const MILLILITER_PER_SQUARE_METER = 'MILLILITER_PER_SQUARE_METER';

const GRAM_PER_1000_SQUARE_METER = 'GRAM_PER_1000_SQUARE_METER';
const KILOGRAM_PER_1000_SQUARE_METER = 'KILOGRAM_PER_1000_SQUARE_METER';
const LITER_PER_1000_SQUARE_METER = 'LITER_PER_1000_SQUARE_METER';
const MILLILITER_PER_1000_SQUARE_METER = 'MILLILITER_PER_1000_SQUARE_METER';
const KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT =
	'KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT';
const LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT =
	'LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT';

const GRAM_PER_HECTARE = 'GRAM_PER_HECTARE';
const TON_PER_HECTARE = 'TON_PER_HECTARE';
const KILOGRAM_PER_HECTARE = 'KILOGRAM_PER_HECTARE';
const LITER_PER_HECTARE = 'LITER_PER_HECTARE';
const MILLILITER_PER_HECTARE = 'MILLILITER_PER_HECTARE';

const POUND_PER_ACRE = 'POUND_PER_ACRE';
const GALLON_PER_ACRE = 'GALLON_PER_ACRE';
const OUNCE_PER_ACRE = 'OUNCE_PER_ACRE';

const GRAM_PER_LITER_WATER = 'GRAM_PER_LITER_WATER';
const KILOGRAM_PER_1000_LITER_WATER = 'KILOGRAM_PER_1000_LITER_WATER';
const LITER_PER_LITER_WATER = 'LITER_PER_LITER_WATER';
const LITER_PER_1000_LITER_WATER = 'LITER_PER_1000_LITER_WATER';

const GRAM_PER_100_LITER = 'GRAM_PER_100_LITER';
const MILLILITER_PER_100_LITER = 'MILLILITER_PER_100_LITER';

const GRAM_PER_PLANT = 'GRAM_PER_PLANT';
const OUNCE_PER_PLANT = 'OUNCE_PER_PLANT';
const MILLILITER_PER_PLANT = 'MILLILITER_PER_PLANT';
const FLUID_OUNCE_PER_PLANT = 'FLUID_OUNCE_PER_PLANT';

const UNKNOWN = 'UNKNOWN';

enum AreaUnits {
	HECTARE = 'HECTARE',
	DECARE = 'DECARE',
	ACRES = 'ACRES',
	SQUARE_METERS = 'SQUARE_METERS',
}

enum VolumeUnits {
	LITER = 'LITER',
	GALLON = 'GALLON',
	MILLILITER = 'MILLILITER',
}

enum WaterConsumptionUnits {
	LITERS_PER_HECTARE = 'liters/hectare',
	GALLONS_PER_ACRE = 'gallons/acre',
}

enum PackageSizeUnits {
	LITER = 'LITER',
	KILOGRAM = 'KILOGRAM',
	GALLON = 'GALLON',
}

enum JobDistanceUnits {
	KILOMETERS = 'kilometers',
	MILES = 'miles',
}

const SalesVolumeUnits = {
	KILOGRAM,
	TON,
	THOUSANDS_OF_POUNDS,
} as const;

const ConsumptionUnits = {
	GRAM,
	KILOGRAM,
	TON,
	POUND,
	OUNCE,
	MILLILITER,
	LITER,
	GALLON,
	FLUID_OUNCE,
} as const;

enum StockUnits {
	KILOGRAM,
	POUND,
	LITER,
	IMPERIAL_GALLON,
	US_GALLON,
}

const ProductUnits = {
	GRAM_PER_SQUARE_METER,
	GRAM_PER_1000_SQUARE_METER,
	LITER_PER_SQUARE_METER,
	LITER_PER_1000_SQUARE_METER,
	LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT,

	GRAM_PER_LITER_WATER,
	LITER_PER_LITER_WATER,
	LITER_PER_1000_LITER_WATER,
	KILOGRAM_PER_1000_LITER_WATER,
	MILLILITER_PER_1000_SQUARE_METER,
	MILLILITER_PER_100_LITER,

	GRAM_PER_100_LITER,
	GRAM_PER_HECTARE,
	MILLILITER_PER_HECTARE,
	KILOGRAM_PER_HECTARE,
	KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT,

	LITER_PER_HECTARE,
	TON_PER_HECTARE,
	POUND_PER_ACRE,
	GALLON_PER_ACRE,
	OUNCE_PER_ACRE,

	GRAM_PER_PLANT,
	OUNCE_PER_PLANT,
	MILLILITER_PER_PLANT,
	FLUID_OUNCE_PER_PLANT,
} as const;

export const isProductUnit = (value: string): value is TProductUnits =>
	Object.keys(ProductUnits).includes(value);

const AllUnits = {
	GRAM,
	KILOGRAM,
	TON,
	POUND,
	THOUSANDS_OF_POUNDS,
	OUNCE,
	KILOGRAM_PER_SQUARE_METER,
	MILLILITER_PER_SQUARE_METER,
	KILOGRAM_PER_1000_SQUARE_METER,
	...ProductUnits,
	...AreaUnits,
	...VolumeUnits,
	...JobDistanceUnits,
	UNKNOWN,
} as const;

export const isUnit = (value: string): value is TAllUnits =>
	Object.keys(AllUnits).includes(value);

const HarvestWeightUnits = {
	[AllUnits.KILOGRAM]: AllUnits.KILOGRAM,
	[AllUnits.POUND]: AllUnits.POUND,
	[AllUnits.TON]: AllUnits.TON,
	[AllUnits.THOUSANDS_OF_POUNDS]: AllUnits.THOUSANDS_OF_POUNDS,
} as const;

type TProductUnits = keyof typeof ProductUnits;
type TAreaUnits = keyof typeof AreaUnits;
type TVolumeUnits = keyof typeof VolumeUnits;
type TStockUnits = keyof typeof StockUnits;
type TWaterConsumptionUnits = keyof typeof WaterConsumptionUnits;
type TPackageSizeUnits = keyof typeof PackageSizeUnits;
type TSalesVolumeUnits = keyof typeof SalesVolumeUnits;
type TConsumptionUnits = keyof typeof ConsumptionUnits;
type TAllUnits =
	| keyof typeof AllUnits
	| TAreaUnits
	| TVolumeUnits
	| TWaterConsumptionUnits
	| TPackageSizeUnits;

const isAreaUnit = isOneOf(
	AreaUnits.HECTARE,
	AreaUnits.DECARE,
	AreaUnits.ACRES,
	AreaUnits.SQUARE_METERS
);

const ALL = Object.values(AllUnits);

const ProductUnitsArray = Object.values(ProductUnits);

const _isHarvestWeightUnit = isOneOf(
	HarvestWeightUnits.KILOGRAM,
	HarvestWeightUnits.POUND
) as ExtendedValidator<keyof typeof HarvestWeightUnits>;

// https://github.com/microsoft/TypeScript/issues/34596
const isHarvestWeightUnit: typeof _isHarvestWeightUnit = _isHarvestWeightUnit;

export type THarvestWeightUnit = ValidatorType<typeof isHarvestWeightUnit>;

export const HarvestCountingUnit = {
	BOXES: 'BOXES',
	WEIGHT: 'WEIGHT',
} as const;

export type THarvestCountingUnit = keyof typeof HarvestCountingUnit;

export {
	GRAM,
	KILOGRAM,
	TON,
	POUND,
	GALLON,
	OUNCE,
	METER,
	GRAM_PER_SQUARE_METER,
	KILOGRAM_PER_SQUARE_METER,
	LITER_PER_SQUARE_METER,
	MILLILITER_PER_SQUARE_METER,
	GRAM_PER_1000_SQUARE_METER,
	KILOGRAM_PER_1000_SQUARE_METER,
	LITER_PER_1000_SQUARE_METER,
	MILLILITER_PER_1000_SQUARE_METER,
	GRAM_PER_HECTARE,
	KILOGRAM_PER_HECTARE,
	TON_PER_HECTARE,
	POUND_PER_ACRE,
	GALLON_PER_ACRE,
	OUNCE_PER_ACRE,
	LITER_PER_HECTARE,
	MILLILITER_PER_HECTARE,
	GRAM_PER_LITER_WATER,
	LITER_PER_LITER_WATER,
	GRAM_PER_100_LITER,
	MILLILITER_PER_100_LITER,
	LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT,
	KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT,
	KILOGRAM_PER_1000_LITER_WATER,
	LITER_PER_1000_LITER_WATER,
	GRAM_PER_PLANT,
	OUNCE_PER_PLANT,
	MILLILITER_PER_PLANT,
	FLUID_OUNCE_PER_PLANT,
	ALL,
	AreaUnits,
	VolumeUnits,
	WaterConsumptionUnits,
	ProductUnits,
	PackageSizeUnits,
	isAreaUnit,
	JobDistanceUnits,
	isHarvestWeightUnit,
	HarvestWeightUnits,
	ProductUnitsArray,
	SalesVolumeUnits,
	UNKNOWN,
};

export type {
	TAreaUnits,
	TVolumeUnits,
	TAllUnits,
	TProductUnits,
	TStockUnits,
	TPackageSizeUnits,
	TSalesVolumeUnits,
	TConsumptionUnits,
};
