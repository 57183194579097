import { gql, TypedDocumentNode } from '@apollo/client';
import { TPartnerLocation } from 'Models/partnershipCompany/isPartnerLocation';
import { TTeam } from 'Models/teams/isTeam';

type TPartnerTeam = {
	location: TPartnerLocation;
	team: Pick<TTeam, 'id' | 'name'>;
};

export type TGetPartnerTeamsResponse = {
	getPartnerTeams: TPartnerTeam[];
};

export const GET_PARTNER_TEAMS_QUERY: TypedDocumentNode<
	TGetPartnerTeamsResponse,
	null
> = gql`
	query getPartnerTeams {
		getPartnerTeams {
			location {
				id
				name
			}
			team {
				id
				name
			}
		}
	}
`;
