import React, { useState, useEffect } from 'react';

import { ApolloError, useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useParams from 'Features/router/hooks/useParams';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import _difference from 'lodash/difference';
import { enqueueSnackbar } from 'notistack';

import throwErrorSilently from 'Utils/throwErrorSilently';

import FarmsMultiselectList from '../../../../../../../components/FarmsMultiselectList';
import LoadingContent from '../../../../../../../components/LoadingContent';
import { GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS } from '../../../../api/getTreatmentLibraryProducts';
import GET_TREATMENT_LIBRARY_LOCATIONS from './api/getTreatmentLibraryLocations';
import REVOKE_TREATMENT_LIBRARY_ACCESS from './api/revokeTreatmentLibraryAccess';
import SHARE_TREATMENT_LIBRARY from './api/shareTreatmentLibraryWithLocation';

function LibraryShare() {
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const [locationIds, setLocationIds] = useState<string[]>([]);
	const [isSharing, setIsSharing] = useState(false);
	const { libraryID } = useParams();

	const { data, loading, error, refetch } = useQuery(
		GET_TREATMENT_LIBRARY_LOCATIONS,
		{
			variables: {
				libraryId: libraryID,
			},
		}
	);

	const { data: getPartnerTreatmentLibraryProductsData } = useQuery(
		GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS,
		{ variables: { libraryId: libraryID }, skip: !libraryID }
	);

	const sharedLocationsId =
		data?.getTreatmentLibraryLocations
			.filter(
				(treatmentLibraryLocation) =>
					treatmentLibraryLocation.status === 'ACTIVE'
			)
			.map(
				(treatmentLibraryLocation) => treatmentLibraryLocation.location.id
			) || [];

	const pendingLocationsId =
		data?.getTreatmentLibraryLocations
			.filter(
				(treatmentLibraryLocation) =>
					treatmentLibraryLocation.status === 'PENDING'
			)
			.map(
				(treatmentLibraryLocation) => treatmentLibraryLocation.location.id
			) || [];

	const chosenLocations = [...sharedLocationsId, ...pendingLocationsId];

	useEffect(() => {
		if (chosenLocations.length) {
			setLocationIds(chosenLocations);
		}
	}, [data?.getTreatmentLibraryLocations]);

	const [shareTreatmentLibrary] = useMutation(SHARE_TREATMENT_LIBRARY);
	const [revokeTreatmentLibraryAccess] = useMutation(
		REVOKE_TREATMENT_LIBRARY_ACCESS
	);

	const toggleHandler = () => {
		if (isOpen) {
			onClose();
		}
		setOpen(!isOpen);
	};

	const onClose = () => {
		setLocationIds([]);
		setIsSharing(false);
		if (chosenLocations.length) {
			setLocationIds(chosenLocations);
		}
	};
	const locationsToShare = _difference(locationIds, chosenLocations);
	const locationsToRevoke = _difference(chosenLocations, locationIds);

	const canShare =
		getPartnerTreatmentLibraryProductsData?.getTreatmentLibraryProducts.products
			.length;

	const onUpdate = async () => {
		setIsSharing(true);
		await Promise.all([
			...locationsToShare.map((locationId) =>
				shareTreatmentLibrary({
					variables: { locationId: locationId, libraryId: libraryID },
				})
			),
			...locationsToRevoke.map((locationId) =>
				revokeTreatmentLibraryAccess({
					variables: { locationId: locationId, libraryId: libraryID },
				})
			),
		])
			.catch((error: ApolloError) => {
				onClose();
				enqueueSnackbar(t('treatmentLibrary.shareModal.shareFailure'), {
					variant: 'error',
				});
				throwErrorSilently(error);
			})
			.then(() => {
				void refetch();
				onClose();
				enqueueSnackbar(t('treatmentLibrary.shareModal.shareSuccess'), {
					variant: 'success',
				});
			});
	};

	if (error) {
		throw new ApolloError(error);
	}

	return (
		<>
			<Button variant="text" onClick={toggleHandler} disabled={!canShare}>
				{t('buttons.share')}
			</Button>
			<Modal.UncontrolledModal toggleOpen={toggleHandler} open={isOpen}>
				<Modal.DialogTitle
					title={t('buttons.shareLibrary')}
					closeIconHandler={toggleHandler}
				/>
				<Modal.DialogContent>
					<Text sx={{ marginBottom: '1rem' }}>
						{t('treatmentLibrary.shareModal.description')}
					</Text>
					{loading ? (
						<LoadingContent />
					) : (
						<FarmsMultiselectList
							onChangeCallback={setLocationIds}
							selectedFarmsIds={sharedLocationsId}
							pendingFarmsIds={pendingLocationsId}
						/>
					)}
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant="text" onClick={toggleHandler}>
							{t('buttons.cancel')}
						</Button>
						<Button
							variant="outlined"
							onClick={() => void onUpdate()}
							disabled={
								isSharing ||
								(!locationsToShare.length && !locationsToRevoke.length)
							}
						>
							{t('buttons.shareLibrary')}
						</Button>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}

export default LibraryShare;
