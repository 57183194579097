import { useEffect } from 'react';

import useProfile from 'Hooks/useProfile';

import { analyticsIdentify, analyticsPage } from '..';

const useSegmentAnalytics = (pathname: string): void => {
	const { profile } = useProfile();
	const userID = profile ? profile.id : profile;

	useEffect(() => {
		if (profile) {
			const traits = {
				email: profile.email,
				firstName: profile.firstName,
				lastName: profile.lastName,
				userId: profile.id,
				firstSignup: profile.createdAt,
			};
			analyticsIdentify(profile.id, traits);
		}
	}, [userID, profile]);

	useEffect(() => {
		const category = pathname.split('/')?.[1];
		const name = category && pathname.split('/')?.[3];
		analyticsPage(category, name);
	}, [pathname]);
};

export default useSegmentAnalytics;
