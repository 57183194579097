import React, { ReactElement, ReactNode } from 'react';

import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Page from 'UI/layout/Page';

import useLocationSubscriptions from 'Hooks/useLocationSubscriptions';
import usePermission from 'Hooks/usePermission';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import NoAccessPage from '../../no-access/NoAccessPage';

type TPageValidatorProps = {
	children?: ReactNode;
};

function PageValidator(props: TPageValidatorProps): ReactElement | null {
	const { children } = props;
	const { locationSubscriptions, loading } = useLocationSubscriptions();

	const hasValidSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.SALE
	);
	const hasPermissionToAccessPage = usePermission(
		Permissions.FARM_SALES_PAGE_ACCESS
	);

	if (locationSubscriptions) {
		const hasSalesPageAccess =
			hasValidSubscription && hasPermissionToAccessPage;

		return <>{hasSalesPageAccess ? children : <NoAccessPage />}</>;
	}

	if (loading) {
		return (
			<Page noMinHeight center>
				<LoadingSpinner />
			</Page>
		);
	}

	return null;
}

export default PageValidator;
