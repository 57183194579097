import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import L, { LatLngExpression } from 'leaflet';
import ReactDOMServer from 'react-dom/server';

import ErrorIcon from '../../icons/Error';
import Marker from '../Marker';

type TProps = {
	position: LatLngExpression;
	noPointerEvents?: boolean;
	fill?: string;
};

const useStyles = makeStyles({
	marker: {
		pointerEvents: 'none!important' as 'none',
	},
});

const ExclamationMarker = (props: TProps): JSX.Element => {
	const { position, noPointerEvents, fill } = props;
	const styles = useStyles();
	const className = classnames(
		'exclamation-marker-icon',
		noPointerEvents ? styles.marker : null
	);

	const icon = L.divIcon({
		iconSize: [32, 32],
		iconAnchor: [16, 16],
		className: className,
		html: ReactDOMServer.renderToString(<ErrorIcon sx={{ color: fill }} />),
	});

	return <Marker position={position} icon={icon} />;
};

export default ExclamationMarker;
