import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TPartnerJobFilterInput } from 'Models/jobs/types/JobFilterInput';

import { TResponse } from '../types';

type TGetPartnerJobsExportDataInput = {
	filterBy?: Partial<TPartnerJobFilterInput>;
	pageInput?: TPageInput;
};

const GET_PARTNER_JOBS_FILE_EXPORT_DATA: TypedDocumentNode<
	{
		getPartnerJobs: TResponse;
	},
	TGetPartnerJobsExportDataInput
> = gql`
	query getPartnerJobsFileExportData(
		$filterBy: PartnerJobFilterInput
		$pageInput: PageInput
	) {
		getPartnerJobs(filterBy: $filterBy, pageInput: $pageInput) {
			totalElements
			jobs {
				location {
					id
					name
				}
				job {
					id
					jobType
					jobStatus
					startDate
					completedDate
					createdAt
					author {
						firstName
						lastName
					}
					jobCauses {
						id
						name
						causeType
					}
					customJobType {
						id
						name
					}
					treatments {
						originalProduct {
							name
						}
					}
					customJobType {
						id
						name
					}
					multiLocationJobType {
						name
					}
					jobFields {
						id
						name
						areaSize
						numberOfPlants
						fieldVarieties {
							id
							category
							name
							sowDate
						}
						varieties {
							identifier
							name
							category
						}
					}
					driversWithEquipmentConfig {
						driver {
							firstName
							lastName
						}
						equipment {
							id
							name
							equipmentType
							rate {
								value
								unit
							}
							tankSize {
								value
								unit
							}
						}
					}
					runStatusHistory {
						runId
						timestamp
						status
					}
					drivers {
						firstName
					}
					weather {
						temp
						windSpeed
						windDeg
						rain
					}
					treatments {
						amount
						unit
						originalProduct {
							name
							productSpec {
								fracNumber
								reEntryInterval
							}
							ingredients {
								name
								amount
								unit
								active
							}
						}
						originalTreatment {
							amount
							unit
							withholdingPeriod
						}
					}
					notes {
						id
						account {
							id
							firstName
							lastName
							profileImage {
								path
							}
						}
						note
						createdAt
					}
				}
			}
		}
	}
`;

export default GET_PARTNER_JOBS_FILE_EXPORT_DATA;
