import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';

const TYPENAME = 'PartnerPlannerJob' as const;

type TDeletePlannerJobResponse = {
	deletePartnerPlannerJob: boolean;
};

type TDeletePlannerJobInput = {
	plannerJobId: string;
};

const DELETE_PLANNER_JOB: TypedDocumentNode<
	TDeletePlannerJobResponse,
	TDeletePlannerJobInput
> = gql`
	mutation deletePartnerPlannerJob($plannerJobId: ID!) {
		deletePartnerPlannerJob(plannerJobId: $plannerJobId)
	}
`;

export const removeDeletedJobFromCache: MutationUpdaterFunction<
	TDeletePlannerJobResponse,
	TDeletePlannerJobInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }, { variables }) => {
	const isJobDeleted = data?.deletePartnerPlannerJob;

	if (isJobDeleted && variables?.plannerJobId) {
		const normalizedId = cache.identify({
			id: variables.plannerJobId,
			__typename: TYPENAME,
		});
		cache.evict({ id: normalizedId });
	}
};

export default DELETE_PLANNER_JOB;
