import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ProfilePageTemplate from '../common/components/ProfilePageTemplate';
import ProfileContent from './components/PartnerInfoContent';

function PartnerInfoPage(): ReactElement {
	const { t } = useTranslation();
	return (
		<ProfilePageTemplate
			header={t('partnerInfoPage.header')}
			subheader={t('partnerInfoPage.subheader')}
		>
			<ProfileContent />
		</ProfilePageTemplate>
	);
}

export default PartnerInfoPage;
