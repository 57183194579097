import React, { ReactElement, ReactNode } from 'react';

import { makeStyles } from '@mui/styles';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import SettingsList from './SettingsList';

type Props = {
	children?: ReactNode;
	header?: string | ReactElement;
	subheader?: string;
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: 'nowrap',
		overflow: 'auto',
		justifyContent: 'space-between',
		[theme.breakpoints.up('md')]: {
			columnSpacing: '1.5rem',
		},
		[theme.breakpoints.up('lg')]: {
			columnSpacing: '3rem',
		},
	},
	menu: {
		position: 'sticky',
		top: 0,
		paddingTop: '0.75rem',
		[theme.breakpoints.up('lg')]: {
			maxWidth: '18rem',
		},
	},
	main: {
		maxWidth: '42.5rem',
		margin: '0 0.5rem',
		'&>.MuiBox-root': {
			paddingBottom: '2rem',
		},
		[theme.breakpoints.up('md')]: {
			margin: '0 1.5rem',
			width: '42.5rem',
		},
		[theme.breakpoints.up('lg')]: {
			margin: '0 3rem',
		},
	},
}));

const ProfilePageTemplate = (props: Props) => {
	const { children, header, subheader } = props;
	const styles = useStyles();

	return (
		<ErrorBoundary>
			<Grid container className={styles.root}>
				<Grid item md={3} className={styles.menu}>
					<SettingsList />
				</Grid>
				<Grid item className={styles.main}>
					<Box>
						<Box textAlign="center">
							<Text variant="h2" marginTop="1.5rem">
								{header}
							</Text>
							<Text color="textSecondary" margin="0.5rem 0 1.5rem 0">
								{subheader}
							</Text>
						</Box>
						{children}
					</Box>
				</Grid>
				<Grid item xs={0} lg={2} flexShrink={2} />
			</Grid>
		</ErrorBoundary>
	);
};

export default ProfilePageTemplate;
