import { gql, TypedDocumentNode } from '@apollo/client';
import { TCustomJobType } from 'Models/jobs/isCustomJob';

export type TGetCustomJobTypesResponse = {
	customJobTypes: Pick<TCustomJobType, 'id' | 'name'>[];
};

type TGetCustomJobTypes = {
	locationId: string;
};

const GET_CUSTOM_JOB_TYPES: TypedDocumentNode<
	TGetCustomJobTypesResponse,
	TGetCustomJobTypes
> = gql`
	query getCustomJobTypes($locationId: ID!) {
		customJobTypes(locationId: $locationId) {
			id
			name
		}
	}
`;

export default GET_CUSTOM_JOB_TYPES;
