import { TLogType } from 'Models/logs/LogType';
import URI from 'urijs';

import useManageHistory from 'Hooks/useManageHistory';

const buildPartnerExportPageURL = (
	fileType: string,
	currentLocation: ReturnType<typeof useManageHistory>['currentLocation'],
	companyId: string,
	logType: TLogType
) => {
	const pagePath = `/partners/${companyId}/logs/${logType.toLocaleLowerCase()}/export/${fileType}`;

	return URI(pagePath).query(currentLocation.search).toString();
};

export default buildPartnerExportPageURL;
