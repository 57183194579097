import React, { useState } from 'react';

import useHistory from 'Features/router/hooks/useHistory';
import { TPartnershipCompanyUser } from 'Models/partnershipCompany/isPartnershipCompanyUser';
import Roles, { TRoles } from 'Models/permissions/Roles';
import MoreIcon from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Menu from 'UI/navigation/Menu';

import useProfileRoles from 'Hooks/useProfileRoles';
import { isPartnershipCompanyRole } from 'Utils/hasPartnerCompany';

import AdminDropdownView from './AdminDropdownView';
import MemberDropdownView from './MemberDropdownView';
import OwnerDropdownView from './OwnerDropdownView';

function getDropdownContent(
	memberItemRole: TRoles,
	memberItemId: string,
	onViewProfileClick: () => void,
	onEditInfoClick: () => void,
	onChangeToAdminAccessClick: () => void,
	onChangeFarmsAccessClick: () => void,
	onDeactivateAccountClick?: () => void,
	profileRole?: TRoles
) {
	if (profileRole === Roles.PA_MEMBER) {
		return <MemberDropdownView onViewProfileClick={onViewProfileClick} />;
	}

	if (profileRole === Roles.PA_OWNER) {
		return (
			<OwnerDropdownView
				memberItemRole={memberItemRole}
				onEditInfoClick={onEditInfoClick}
				onChangeToAdminAccessClick={onChangeToAdminAccessClick}
				onChangeFarmsAccessClick={onChangeFarmsAccessClick}
				onDeactivateAccountClick={onDeactivateAccountClick}
				onChangeToMemberClick={onChangeFarmsAccessClick}
				memberItemId={memberItemId}
			/>
		);
	}

	if (profileRole === Roles.PA_ADMIN) {
		return (
			<AdminDropdownView
				memberItemRole={memberItemRole}
				memberItemId={memberItemId}
				onEditInfoClick={onEditInfoClick}
				onChangeToAdminAccessClick={onChangeToAdminAccessClick}
				onViewProfileClick={onViewProfileClick}
				onDeactivateAccountClick={onDeactivateAccountClick}
				onChangeFarmsAccessClick={onChangeFarmsAccessClick}
			/>
		);
	}

	return null;
}

const MemberDropdown = (
	props: Pick<TPartnershipCompanyUser, 'role' | 'id'> & {
		displayInfoModal: () => void;
		displayChangeToAdminAccessModal: () => void;
		deactivatePartnershipUser: () => void;
		displayFarmsAccessModal: () => void;
	}
) => {
	const {
		role,
		id,
		deactivatePartnershipUser,
		displayInfoModal,
		displayFarmsAccessModal,
		displayChangeToAdminAccessModal,
	} = props;

	const { profileRoles } = useProfileRoles();
	const partnerPortalProfile = profileRoles.find((_role) =>
		isPartnershipCompanyRole(_role)
	);

	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onViewProfileClick = () => {
		displayInfoModal();
		handleClose();
	};
	const onEditInfoClick = () => {
		history.push('/profile-settings');
	};

	const onChangeToAdminAccessClick = () => {
		displayChangeToAdminAccessModal();
		handleClose();
	};

	const onDeactivateAccountClick = () => {
		handleClose();
		deactivatePartnershipUser();
	};

	const onChangeFarmsAccessClick = () => {
		displayFarmsAccessModal();
		handleClose();
	};

	return (
		<>
			<IconButton size="medium" onClick={handleClick}>
				<MoreIcon />
			</IconButton>
			<Menu.Menu
				id="partner-member-menu"
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{getDropdownContent(
					role,
					id,
					onViewProfileClick,
					onEditInfoClick,
					onChangeToAdminAccessClick,
					onChangeFarmsAccessClick,
					onDeactivateAccountClick,
					partnerPortalProfile?.role
				)}
			</Menu.Menu>
		</>
	);
};

export default MemberDropdown;
