import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function TemperatureIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 32 32" fontSize="large" {...props}>
			<path
				d="M18 18.76V7.5a2.5 2.5 0 1 0-5 0v11.26a4.5 4.5 0 1 0 5 0h0z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeOpacity=".87"
				strokeWidth="2"
				fill="none"
				stroke="black"
			/>
		</SvgIcon>
	);
}

export default TemperatureIcon;
