import { TLocation } from '../locations/isLocation';
import { AreaUnits, VolumeUnits, WaterConsumptionUnits } from '../units';
import convertAreaUnit from '../units/convertAreaUnit';
import convertVolume from '../units/convertVolume';
import convertVolumePerArea from '../units/convertVolumePerArea';
import roundToTwoDecimal from '../units/roundToTwoDecimal';
import { toDisplayString } from '../units/transpileUnits';
import { TJob } from './isJob';
import jobArea from './jobArea';

function getAreaConverter(attributeUnits: string | undefined | null) {
	switch (attributeUnits) {
		case WaterConsumptionUnits.LITERS_PER_HECTARE:
			return (area: number) => convertAreaUnit(area, AreaUnits.HECTARE);
		case WaterConsumptionUnits.GALLONS_PER_ACRE:
			return (area: number) => convertAreaUnit(area, AreaUnits.ACRES);
		default:
			return undefined;
	}
}

function jobWaterConsumption(
	job: Pick<TJob, 'driversWithEquipmentConfig' | 'jobFields'>,
	location?: Pick<TLocation, 'areaUnit'> | null,
	fieldIDs?: string[]
) {
	const { driversWithEquipmentConfig } = job;
	const isImperial = location?.areaUnit === AreaUnits.ACRES;

	const waterApplicationRate = driversWithEquipmentConfig?.reduce(
		(acc, current) => {
			if (current.equipment?.rate) {
				const waterConsumptionConfig = current.equipment.rate;

				if (waterConsumptionConfig) {
					const { unit, value } = waterConsumptionConfig;
					const converter = getAreaConverter(unit);

					if (converter) {
						const area = jobArea(
							job,
							location,
							(area) => convertAreaUnit(area, AreaUnits.HECTARE),
							fieldIDs
						);

						let attributeUnitsInLitersPerHectare;

						switch (unit) {
							case WaterConsumptionUnits.GALLONS_PER_ACRE:
								attributeUnitsInLitersPerHectare = convertVolumePerArea(
									Number(value),
									WaterConsumptionUnits.GALLONS_PER_ACRE,
									WaterConsumptionUnits.LITERS_PER_HECTARE
								);
								break;
							case WaterConsumptionUnits.LITERS_PER_HECTARE:
								attributeUnitsInLitersPerHectare = Number(value);
								break;
							default:
								throw new Error(`${__filename}: Unexpected unit in ${unit}`);
						}

						return acc + attributeUnitsInLitersPerHectare * area;
					}
				}
			}
			return acc;
		},
		0
	);

	if (waterApplicationRate && isImperial) {
		const waterApplicationRateInGallons = convertVolume(
			waterApplicationRate,
			VolumeUnits.LITER,
			VolumeUnits.GALLON
		);
		return {
			amount: waterApplicationRateInGallons,
			unit: toDisplayString(VolumeUnits.GALLON),
		};
	} else if (waterApplicationRate) {
		return {
			amount: roundToTwoDecimal(waterApplicationRate),
			unit: toDisplayString(VolumeUnits.LITER),
		};
	} else {
		return { amount: 'N/A', unit: 'N/A' };
	}
}

export default jobWaterConsumption;
