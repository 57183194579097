import React, { useEffect } from 'react';

import Link from 'Features/router/components/Link';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Box from 'UI/layout/Box';
import MenuItem from 'UI/navigation/Menu/item';
import Menu from 'UI/navigation/Menu/menu';
import { FarmablePlaceholdersColors } from 'UI/theme/Colors';

import useLocations from 'Hooks/useLocations';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';
import stringToInitials from 'Utils/stringToInitials';

const colors = Object.values(FarmablePlaceholdersColors);

const LocationsDropdown = () => {
	const { locations } = useLocations();
	const locationId = getLocationIdFromUrl();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		handleClose();
	}, [locationId]);

	if (!locations) {
		return null;
	}

	const filteredLocations = locations.filter(
		(location) =>
			location.relation.includes('ADMIN') ||
			location.relation.includes('FARMER')
	);
	const currentLocationName = filteredLocations?.find(
		(location) => location.id === locationId
	)?.name;

	if (!currentLocationName) {
		return null;
	}

	return (
		<>
			<Box
				onClick={handleClick}
				display="flex"
				alignItems="center"
				sx={{ cursor: locations.length > 1 ? 'pointer' : 'default' }}
			>
				<Avatar
					size="sm"
					variant="rounded"
					color={colors[(parseInt(locationId, 10) || 0) % colors.length]}
				>
					{stringToInitials(currentLocationName)}
				</Avatar>
				<Text variant="body1" fontWeight="bold" paddingLeft="0.5rem">
					{currentLocationName}
				</Text>
				{locations.length > 1 ? (
					<ArrowDropDownIcon
						sx={{
							transform: `rotate(${open ? 180 : 0}deg)`,
						}}
					/>
				) : null}
			</Box>
			{locations.length > 1 ? (
				<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
					{filteredLocations.map((location) => (
						<>
							<Link to={`/mobile/${location.id}/store`}>
								<MenuItem key={location.name}>{location.name}</MenuItem>
							</Link>
						</>
					))}
				</Menu>
			) : null}
		</>
	);
};

export default LocationsDropdown;
