import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import NoAccessPageTemplate from '../NoAccessPageTemplate';

function NoAccessPage(): ReactElement {
	const { t } = useTranslation();

	return (
		<NoAccessPageTemplate
			title={t('noAccess.noAccessPage.title')}
			description={t('noAccess.noAccessPage.description')}
		/>
	);
}

export default NoAccessPage;
