import isNumber from 'vet/numbers/isNumber';

import { TAuthUserObject } from '../types';

function getAuthUserLoginCount(user: TAuthUserObject): number | null {
	const keys = Object.keys(user);
	const loginCountKey = keys.find((key) => key.includes('login_count'));

	if (loginCountKey) {
		const loginCount = user[loginCountKey];
		return isNumber(loginCount) ? loginCount : null;
	}

	return null;
}

export { getAuthUserLoginCount };
