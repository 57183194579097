import React, { useEffect, Fragment, ReactElement } from 'react';

import classnames from 'classnames';

import 'leaflet/dist/leaflet.css';
import {
	FitBoundsOptions,
	latLng,
	latLngBounds,
	LatLngBoundsExpression,
	Map as TMap,
	Layer,
	LatLng,
	LatLngBounds,
} from 'leaflet';
import { MapContainer, ZoomControl, useMapEvents } from 'react-leaflet';

import OpenStreetMapLayer from '../layers/OpenStreetMapLayer';
import STYLES from './styles.module.scss';
import { Props } from './types';

type MapDerivativesProps = {
	onMapZoom?: (map: TMap) => void;
	onMapMove?: (map: TMap) => void;
	onDragEnd?: (map: TMap) => void;
	onMapZoomEnd?: (map: TMap) => void;
	onMapZoomLevelsChange?: (map: TMap) => void;
	onMapReflow?: (map: TMap) => void;
	bounds?: LatLngBoundsExpression;
	boundsOptions?: FitBoundsOptions;
	children?: ReactElement;
	animation?: boolean;
};

const southWest = latLng(-90, -Infinity);
const northEast = latLng(90, Infinity);
const worldMaxBounds = latLngBounds(southWest, northEast);

type TLayer = {
	getCenter(): LatLng;
} & Layer;

const MapDerivatives = (props: MapDerivativesProps) => {
	const {
		children,
		bounds,
		boundsOptions,
		animation,
		onMapZoom,
		onMapMove,
		onDragEnd,
		onMapZoomEnd,
		onMapZoomLevelsChange,
		onMapReflow,
	} = props;
	const map = useMapEvents({
		move: () => {
			onMapMove?.(map);
		},
		dragend: () => onDragEnd?.(map),
		zoomend: () => {
			// INTERNAL NOTE
			//
			// This is a workaround related to the leaflet bug
			// https://github.com/Leaflet/Leaflet/issues/5216
			// Same situation exists in our app - the initial render is without zoom
			// and after setting the bounding box initial tooltip is anchored far away from the polygon
			//
			map.eachLayer((l) => {
				const tooltip = l.getTooltip();

				if (
					tooltip?.options.permanent &&
					tooltip?.options.direction === 'center'
				) {
					const layerCenter = (l as TLayer).getCenter();
					tooltip.setLatLng(layerCenter);
				}
			});
			onMapZoomEnd?.(map);
		},
		zoom: () => {
			onMapZoom?.(map);
		},
		zoomlevelschange: () => {
			onMapZoomLevelsChange?.(map);
		},
	});

	const derivatives = (
		<Fragment>
			{children || <OpenStreetMapLayer />}
			<ZoomControl position="bottomright" />
		</Fragment>
	);

	useEffect(() => {
		if (map && bounds) {
			map.fitBounds(bounds, boundsOptions);
		}
	}, [bounds, boundsOptions]);

	useEffect(() => {
		if (map) {
			setTimeout(() => map.setMinZoom(2), animation ? 250 : 0);
		}
	}, []);

	if (map && onMapReflow) {
		setTimeout(() => onMapReflow(map), 0);
	}

	return derivatives;
};

const START_BOUNDS = new LatLngBounds([
	[90, -180],
	[-90, 180],
]);

const Map = (props: Props) => {
	const {
		children,
		bounds = START_BOUNDS,
		boundsOptions,
		maxBoundsViscosity,
		fullscreen,
		fadeAnimation,
		zoomAnimation,
		maxBounds,
		roundedCorners,
		onMapZoom,
		onMapMove,
		onDragEnd,
		onMapZoomEnd,
		onMapZoomLevelsChange,
		onMapReflow,
		whenCreated,
	} = props;

	const styles = Map.defaultProps.theme.Map.styles;

	const containerClasses = classnames(
		styles.container,
		fullscreen ? styles.fullscreen : null
	);
	const mapClasses = classnames(styles.base);

	return (
		<div className={containerClasses}>
			<div className={mapClasses}>
				<MapContainer
					bounds={bounds}
					boundsOptions={boundsOptions}
					zoomControl={false}
					worldCopyJump={true}
					whenCreated={whenCreated}
					maxBounds={maxBounds || worldMaxBounds}
					maxBoundsViscosity={maxBoundsViscosity}
					fadeAnimation={fadeAnimation}
					zoomAnimation={zoomAnimation}
					className={roundedCorners ? styles.hasBorderRadius : ''}
				>
					<MapDerivatives
						bounds={bounds}
						boundsOptions={boundsOptions}
						animation={fadeAnimation || zoomAnimation}
						onMapZoom={onMapZoom}
						onMapMove={onMapMove}
						onDragEnd={onDragEnd}
						onMapZoomEnd={onMapZoomEnd}
						onMapZoomLevelsChange={onMapZoomLevelsChange}
						onMapReflow={onMapReflow}
					>
						{children}
					</MapDerivatives>
				</MapContainer>
			</div>
		</div>
	);
};

Map.defaultProps = {
	element: 'div',
	theme: {
		Map: {
			styles: {
				base: STYLES.map,
				container: STYLES.container,
				fullscreen: STYLES.fullscreen,
				hasBorderRadius: STYLES.hasBorderRadius,
			},
		},
	},
	fadeAnimation: true,
	zoomAnimation: true,
};

export default Map;
