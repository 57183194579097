import React, { useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJobExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import { TExportType } from 'Models/exports/ExportType';
import { LogTypes } from 'Models/logs/LogType';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import useManageHistory from 'Hooks/useManageHistory';
import usePartnerCompany from 'Hooks/usePartnerCompany';
import useProfile from 'Hooks/useProfile';

import ExportLoadingSpinner from '../../../common/components/ExportLoadingSpinner';
import ExportPageLayout from '../../../common/components/ExportPageLayout';
import handleExport from '../../../common/utils/handlePartnerExport';
import GET_PARTNER_JOBS_FILE_EXPORT_DATA from '../api/getPartnerJobsFileExportData';
import getMaxNotesLength from '../utils/getMaxNotesLength';
import getExportData from '../utils/jobExportFunction/getExportData';

type TProps = { type: TExportType };

const LIMIT = 30;

function ExportToFile(props: TProps) {
	const { profile } = useProfile();
	const { company } = usePartnerCompany();
	const { t } = useTranslation();
	const { paramsFromURL: searchParams } = useManageHistory();
	const filterBy = {
		jobFilters: {
			jobStatuses: searchParams.filters.jobStatuses,
			jobTypes: searchParams.filters.jobTypes,
			completedDateFrom: searchParams.filters.completedDateFrom,
			completedDateTo: searchParams.filters.completedDateTo,
			fieldCategories: searchParams.filters.categories,
		},
		locationIds: searchParams.filters.locations,
	};

	const { data, loading, error, fetchMore } = useQuery(
		GET_PARTNER_JOBS_FILE_EXPORT_DATA,
		{
			variables: {
				filterBy,
				pageInput: {
					offset: 0,
					limit: LIMIT,
					sortBy: [{ direction: 'DESC', field: 'completedDate' }],
				},
			},
		}
	);

	const total = data?.getPartnerJobs.totalElements || 0;
	const rawJobs = data?.getPartnerJobs.jobs;

	useEffect(() => {
		if (rawJobs && rawJobs.length < total) {
			void fetchMore({
				variables: {
					pageInput: {
						offset: rawJobs.length,
						limit: LIMIT,
						sortBy: [{ direction: 'DESC', field: 'completedDate' }],
					},
				},
			});
		}
	}, [rawJobs, fetchMore]);

	const dictionary: TJobExportHeadersDictionary = t(
		'exportPages.headers.logs',
		{
			returnObjects: true,
		}
	);

	if (data && total === 0) {
		return (
			<Box padding="1.5rem">
				<Text>{t('labels.noLogsFound')}</Text>
			</Box>
		);
	}

	if (loading || (rawJobs && rawJobs.length < total)) {
		return <ExportLoadingSpinner />;
	}

	if (rawJobs) {
		const jobs = rawJobs.map((job) => ({
			...job.job,
			location: job.location,
		}));

		const exportData = getExportData({
			jobs,
			fileType: props.type,
			dictionary,
			maxNotesLength: getMaxNotesLength(jobs),
			t,
		});

		return (
			<ExportPageLayout
				title={t('exportPages.readyTitle', { context: props.type })}
				downloadTrigger={() => {
					handleExport({
						exportData,
						fileType: props.type,
						logType: LogTypes.Jobs,
						totalColumns: exportData.columnsCount,
						fileName: company?.name || 'Treatment report',
					});
					if (profile && company) {
						analyticsTrack(TrackEvents.DataDownloaded, {
							userId: profile.id,
							groupId: company.id,
							exportType: props.type,
							logType: LogTypes.Jobs,
						});
					}
				}}
			/>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
}

export default ExportToFile;
