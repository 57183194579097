import isArrayOf from 'vet/arrays/isArrayOf';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

import isAccount from '../account/isAccount';
import { isRole } from '../roles/isRole';
import { isAreaUnit } from '../units';

export const locationsSchema = {
	id: isString,
	name: isString,

	relation: isRole,
	invitedBy: optional(isAccount),
	owner: isAccount,
	areaUnit: optional(isAreaUnit),
};

const _isLocations = isShape({
	...locationsSchema,
	relation: locationsSchema.relation,
});

const _isLocationsWithoutOwner = isShape({
	id: locationsSchema.id,
	name: locationsSchema.name,
	relation: isArrayOf(locationsSchema.relation),
});

// https://github.com/microsoft/TypeScript/issues/34596
const isLocations: typeof _isLocations = _isLocations;

export const isLocationsWithoutOwner: typeof _isLocationsWithoutOwner =
	_isLocationsWithoutOwner;

export type TLocations = ValidatorType<typeof isLocations>;
export type TLocationsWithoutOwner = ValidatorType<
	typeof isLocationsWithoutOwner
>;

export default isLocations;
