import React from 'react';

import { ExportType } from 'Models/exports/ExportType';

import useExportType from 'Hooks/useExportType';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import ExportToFile from './components/ExportToFile';

function HarvestExportPage() {
	const type = useExportType();

	if (type === ExportType.CSV || type === ExportType.XLSX) {
		return (
			<ErrorBoundary>
				<ExportToFile type={type} />
			</ErrorBoundary>
		);
	}

	return null;
}

export default HarvestExportPage;
