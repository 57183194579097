import React from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Chip from 'UI/display/Chip';
import CloseIcon from 'UI/icons/Close';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import ModalSection from '../../../../../../../../../../../../components/ModalSection';
import { treatmentInputState } from '../../../../../state';
import GET_GOVERNMENT_CAUSES from '../../api/getGovernmentCauses';
import GET_PARTNERSHIP_CAUSES from '../../api/getPartnershipCauses';
import AddCausesDropdown from './AddCausesDropdown';

function Causes() {
	const { company } = usePartnerCompany();
	const { data: partnerData } = useQuery(GET_PARTNERSHIP_CAUSES);
	const { data: govData } = useQuery(GET_GOVERNMENT_CAUSES, {
		skip: !company?.country,
		variables: company?.country
			? {
					filterBy: {
						country: company?.country,
					},
			  }
			: undefined,
	});

	const { t } = useTranslation();
	const treatmentInput = useReactiveVar(treatmentInputState);

	const handleDelete = (causeId: string) => {
		if (treatmentInput) {
			const newCause = treatmentInput.causeIds?.filter(
				(appliedCauseId) => appliedCauseId !== causeId
			);

			treatmentInputState({
				...treatmentInput,
				causeIds: newCause,
			});
		}
	};

	const allCauses = [
		...(partnerData?.getPartnershipCauses.causes || []),
		...(govData?.governmentCauses.causes || []),
	];

	const appliedCauses = treatmentInput?.causeIds?.length ? (
		<Box display="flex" flexWrap="wrap">
			{allCauses
				.filter((cause) => treatmentInput?.causeIds?.includes(cause.id))
				.map((cause) => (
					<Chip
						label={cause.name}
						key={cause.id}
						onDelete={() => handleDelete(cause.id)}
						deleteIcon={
							<CloseIcon
								width={16}
								height={16}
								fill={FarmableColors.WHITE}
								onMouseDown={(event) => event.stopPropagation()}
							/>
						}
						sx={{
							marginTop: '0.5rem',
							marginRight: '0.5rem',
							color: FarmableColors.WHITE,
							backgroundColor: FarmableColors.ORANGE,
						}}
					/>
				))}
		</Box>
	) : null;

	return (
		<ModalSection title={t('labels.cause')}>
			{appliedCauses}
			<Box marginTop="0.75rem" marginBottom="0.5rem">
				<AddCausesDropdown causes={allCauses} />
			</Box>
		</ModalSection>
	);
}

export default Causes;
