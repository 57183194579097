import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import LoadingMessage from '../../../LoadingMessage';

type TCreateFieldsLoadingProps = {
	fieldsAmount: number;
};

function CreateFieldsLoading(props: TCreateFieldsLoadingProps) {
	const { fieldsAmount } = props;

	const { t } = useTranslation();

	return (
		<LoadingMessage
			message={t('importPages.fieldImportsPage.createFields.loadingMessage', {
				count: fieldsAmount,
			})}
		/>
	);
}

export default CreateFieldsLoading;
