import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';

export type TGetTheOldestJobResponse = {
	getJobs: {
		jobs: {
			id: string;
			createdAt: string;
		}[];
	};
};

type TGetTheOldestJobInput = {
	locationId: string;
	pageInput?: TPageInput;
};

const GET_THE_OLDEST_JOB_QUERY: TypedDocumentNode<
	TGetTheOldestJobResponse,
	TGetTheOldestJobInput
> = gql`
	query getOldestJob($locationId: ID!, $pageInput: PageInput!) {
		getJobs(locationId: $locationId, pageInput: $pageInput) {
			jobs {
				id
				createdAt
			}
		}
	}
`;

export default GET_THE_OLDEST_JOB_QUERY;
