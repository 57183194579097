import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';
import { FieldTypes, TFieldType } from 'Models/fields/isFieldType';
import EMPTY_VARIETY from 'Models/jobs/mocks/mockEmptyVariety';
import Text from 'UI/display/Text';
import EcoZoneIcon from 'UI/icons/EcoZone';
import FieldTypeIcon from 'UI/icons/FieldType';
import GreenHouseIcon from 'UI/icons/GreenHouse';
import RowsIcon from 'UI/icons/Rows';
import Select, { MenuItem } from 'UI/inputs/Select';
import Box from 'UI/layout/Box';

import { TTransactionChangeFunction } from 'Hooks/useTransactionalInput';

import { shouldResetVarieties } from './Varieties/utils';

const FieldTypeIcons = {
	ROWS: <RowsIcon fontSize="small" color="action" />,
	ECO_ZONE: <EcoZoneIcon fontSize="small" color="success" />,
	GREEN_HOUSE: <GreenHouseIcon fontSize="small" color="action" />,
	FIELD: <FieldTypeIcon fontSize="small" color="action" />,
} as const;

const FieldTypesList = Object.keys(FieldTypes).filter(
	(value): value is TFieldType => typeof value === 'string'
);

type TFieldTypeProps = {
	fieldType: TFieldType;
	transactionChangeHandler: TTransactionChangeFunction<TFieldInput>;
};

const FieldType = (props: TFieldTypeProps): ReactElement => {
	const { fieldType, transactionChangeHandler } = props;
	const { t } = useTranslation();

	function handleChange(newFieldType) {
		transactionChangeHandler((previousInput) => {
			const shouldReset = shouldResetVarieties(
				previousInput.fieldType,
				newFieldType
			);
			const shouldShowPercentageVariety =
				newFieldType === FieldTypes.ROWS || newFieldType === FieldTypes.FIELD;

			const initialVariety = {
				...EMPTY_VARIETY,
				percentage: shouldShowPercentageVariety ? 100 : null,
			};

			return {
				...previousInput,
				fieldType: newFieldType,
				varieties: shouldReset ? [initialVariety] : previousInput.varieties,
			};
		});
	}

	const FieldTypesTranslations = t('fieldType', { returnObjects: true });
	const FieldTypeExamples = t(
		'importPages.fieldImportsPage.editFieldModal.fieldTypeExamples',
		{
			returnObjects: true,
		}
	);

	const types = FieldTypesList.map((option) => {
		return (
			<MenuItem value={option} key={option}>
				<Box display="flex" justifyContent="space-between" width="100%">
					<Text variant="label4">{FieldTypesTranslations[option]}</Text>
					{FieldTypeIcons[option]}
				</Box>
			</MenuItem>
		);
	});

	const helperText = `${FieldTypesTranslations[fieldType]} ${FieldTypeExamples[fieldType]}`;

	return (
		<Select
			value={fieldType || ''}
			label={t('labels.fieldType')}
			// It's required to cast the type of the newValue,
			// as material UI does not give ability to define
			// it generically.
			changeHandler={handleChange}
			helperText={helperText}
		>
			{types}
		</Select>
	);
};

export default FieldType;
