import { TypedDocumentNode, gql } from '@apollo/client';

const DEACTIVATE_PARTNERSHIP_USER: TypedDocumentNode<
	{
		deactivatePartnershipUser: boolean;
	},
	{
		partnershipUserId: string;
	}
> = gql`
	mutation deactivatePartnershipUser($partnershipUserId: ID!) {
		deactivatePartnershipUser(partnershipUserId: $partnershipUserId)
	}
`;

export default DEACTIVATE_PARTNERSHIP_USER;
