import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AppleIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				fillRule="nonzero"
				d="M12.8 2.1C13.69.869 15.084.099 16.6 0c.132 1.377-.301 2.748-1.2 3.8-.826 1.15-2.187 1.793-3.6 1.7-.146-1.222.216-2.451 1-3.4zm-5 3.7C5 5.8 2 8.3 2 13.1 2 18 5.5 24 8.3 24c1 0 2.5-1 4-1s2.6.9 4 .9c3.1 0 5.3-6.4 5.3-6.4-1.953-.843-3.214-2.772-3.2-4.9.001-1.857.992-3.572 2.6-4.5-1.042-1.563-2.822-2.472-4.7-2.4-2 0-3.5 1.1-4.3 1.1-.9 0-2.4-1-4.2-1z"
				transform="translate(-96 -634) translate(80 444) translate(16 190)"
			/>
		</SvgIcon>
	);
}

export default AppleIcon;
