import _chunk from 'lodash/chunk';

const asyncThrottle = async <T = unknown, R = unknown>(
	items: Array<T>,
	asyncFunction: (item: T) => Promise<R>,
	batchSize: number
) => {
	const output: Array<R | null> = [];
	const batches = _chunk(items, batchSize);
	const errors: string[] = [];

	for (const batch of batches) {
		const results = await Promise.all(
			batch.map((item) =>
				asyncFunction(item).catch((err: unknown) => {
					errors.push(err instanceof Error ? err.message : JSON.stringify(err));
					return null;
				})
			)
		);

		output.push(...results);
	}

	if (errors.length) {
		throw new Error(errors.join('\n'));
	}

	return output;
};

export default asyncThrottle;
