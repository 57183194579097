import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { format } from 'date-fns';
import L, { LatLngExpression } from 'leaflet';
import ReactDOMServer from 'react-dom/server';

import ToxicWarningIcon from '../../icons/ToxicWarning';
import Marker from '../Marker';
import Tooltip from '../Tooltip';

type TWarningMarkerProps = {
	position: LatLngExpression;
	reEntryAllowedDate: Date | null;
	harvestAllowedDate: Date | null;
};

const DATE_DISPLAY_FORMAT = 'dd.MM.yy';

const WarningMarker = (props: TWarningMarkerProps) => {
	const { t } = useTranslation();
	const { position, reEntryAllowedDate, harvestAllowedDate } = props;

	const icon = L.divIcon({
		iconSize: [64, 64],
		iconAnchor: [32, 32],
		className: 'warning-marker-icon',
		html: ReactDOMServer.renderToString(<ToxicWarningIcon />),
	});

	const reEntryInfo = reEntryAllowedDate ? (
		<>
			{t(`common.warningMarker.useProtectionEquipment`)}{' '}
			<b>{format(reEntryAllowedDate, DATE_DISPLAY_FORMAT)}</b>
			<br />
		</>
	) : null;

	const harvestInfo = harvestAllowedDate ? (
		<>
			{t(`common.warningMarker.earliestHarvest`)}{' '}
			<b>{format(harvestAllowedDate, DATE_DISPLAY_FORMAT)}</b>
			<br />
		</>
	) : null;

	return (
		<Marker position={position} icon={icon}>
			<Tooltip direction={'top'} variant={'dark'} offset={[-2, -22]}>
				{reEntryInfo}
				{harvestInfo}
			</Tooltip>
		</Marker>
	);
};

export default WarningMarker;
