// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1U2Ywpggov8nGLgfd10iRS {\n  height: 100%;\n  font-weight: bold;\n  font-size: .875rem;\n  color: rgba(0,0,0,.6);\n}\n\n._1U2Ywpggov8nGLgfd10iRS:hover {\n  background-color: rgba(0,0,0,.03);\n  cursor: pointer;\n}\n\n._5x1N6bN53fC4jYGNf8Sh_ {\n  padding: .75rem 1.5rem;\n}\n\n._5x1N6bN53fC4jYGNf8Sh_:hover {\n  background-color: rgba(0,0,0,.03);\n  cursor: pointer;\n}\n\n", ""]);
// Exports
exports.locals = {
	"navbarLink": "_1U2Ywpggov8nGLgfd10iRS",
	"profileButton": "_5x1N6bN53fC4jYGNf8Sh_"
};
module.exports = exports;
