import React, { useEffect, useRef, useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { AggregatedSalesGroupOption } from 'Models/sales/isAggregatedSalesGroupOption';
import Chart, { TChartRef } from 'UI/display/Chart';
import { AllPlaceholderColors, FarmableTextColors } from 'UI/theme/Colors';

import useManageHistory from 'Hooks/useManageHistory';

import GET_CUSTOMERS_CHART_DATA from './api/getCustomersChartData';
import getChartConfig from './utils/getChartConfig';
import getMultipleCurrencyChartData from './utils/getMultipleCurrencyChartData';

// where 24 is a point width and 16 is a padding - from mockup
const CATEGORY_WIDTH = 40;
// where 56 is a title height and 24 is a margin bottom - from mockup
const RESERVED_SPACE = 80;

const CustomersChart = () => {
	const chartComponent = useRef<TChartRef>(null);
	const { t, i18n } = useTranslation();
	const { paramsFromURL } = useManageHistory();

	const [chartOptions, setChartOptions] = useState(
		getChartConfig(t, i18n.language)
	);
	const { data, loading, error } = useQuery(GET_CUSTOMERS_CHART_DATA, {
		skip: !paramsFromURL.filters.dateFrom,
		variables: {
			locationId: paramsFromURL.locationID,
			query: {
				filterBy: {
					varieties: paramsFromURL.filters.fieldVarieties,
				},
				groupBy: AggregatedSalesGroupOption.CUSTOMER,
			},
		},
	});
	const colors = useRef([FarmableTextColors.ORANGE, ...AllPlaceholderColors]);

	const chart = chartComponent?.current?.chart;

	if (error) {
		throw new ApolloError(error);
	}

	useEffect(() => {
		if (chart) {
			if (loading) {
				chart.hideNoData();
				chart.showLoading();

				setChartOptions({
					series: [],
				});
			} else if (data) {
				const chartData = getMultipleCurrencyChartData(data.getAggregatedSales);
				const noData = !chartData.length;

				const series = noData
					? [
							{
								type: 'bar' as const,
								data: [],
							},
					  ]
					: chartData;

				chart.hideLoading();
				chart.reflow();

				setChartOptions({
					series,
					colors: colors.current,
					chart: {
						scrollablePlotArea: noData
							? undefined
							: {
									minHeight: chartData.length * CATEGORY_WIDTH + RESERVED_SPACE,
									opacity: 1,
							  },
					},
					xAxis: {
						visible: !noData,
						type: 'category',
					},
				});
			}
		}
	}, [data, loading]);

	return (
		<Chart
			options={chartOptions}
			ref={chartComponent}
			containerProps={{
				style: {
					padding: '1rem 0 1rem 1rem',
					height: '22rem',
				},
			}}
		/>
	);
};

export default CustomersChart;
