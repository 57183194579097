import { LatLngBoundsExpression } from 'leaflet';

import ControlledMap from './component';
import { Props as _Props, Theme as _Theme } from './types';
import UncontrolledMap from './uncontrolled';

export type Props = _Props;
export type Theme = _Theme;

const DEFAULT_BOUNDS: LatLngBoundsExpression = [
	[90, -180],
	[-90, 180],
];

export default {
	Controlled: ControlledMap,
	Uncontrolled: UncontrolledMap,
	DEFAULT_BOUNDS,
};
