import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';

import ProfilePageAccessValidator from '../common/components/ProfilePageAccessValidator';
import PageContent from './components/PageContent';

function CustomerPage(): ReactElement {
	const { t } = useTranslation();

	return (
		<ProfilePageAccessValidator
			header={t('customersPage.title')}
			subheader={t('customersPage.description')}
		>
			<PageContent />
		</ProfilePageAccessValidator>
	);
}

export default CustomerPage;
