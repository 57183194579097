import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import NoLogsStatement from '../../../../common/components/NoLogsStatement';
import GET_HOURS_TABLE_DATA from '../../../api/getHoursTableData';
import TableRow from './TableRow';

const TableBody = () => {
	const { paramsFromURL: searchParams } = useManageHistory();
	const filteredFields = searchParams.filters?.fieldIds;

	const { data, loading, error } = useQuery(GET_HOURS_TABLE_DATA, {
		variables: {
			filterBy: {
				timeFrom: searchParams.filters.timeFrom,
				timeTo: searchParams.filters.timeTo,
				teamId: searchParams.filters.teamId,
				fieldIds: searchParams.filters.fieldIds,
				categories: searchParams.filters.categories,
				varieties: searchParams.filters.varieties,
				teamMemberIds: searchParams.filters.teamMemberIds,
				teamLeadIds: searchParams.filters.teamLeadIds,
			},
			pageInput: searchParams.pageInput,
			locationId: searchParams.locationID,
			hasPagination: true,
		},
	});

	if (loading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (!data?.getTimesheets.timesheets.length) {
		return (
			<NoDataTableContainer>
				<NoLogsStatement logsType="Hours" />
			</NoDataTableContainer>
		);
	}

	const timesheets = filteredFields?.length
		? data.getTimesheets.timesheets.map((timesheet) => ({
				...timesheet,
				fields: timesheet.fields.filter((field) =>
					filteredFields.includes(field.id)
				),
				timesheetFieldHours: timesheet.timesheetFieldHours?.filter(
					(timesheetFieldHour) =>
						filteredFields.includes(timesheetFieldHour.fieldId)
				),
		  }))
		: data.getTimesheets.timesheets;

	return (
		<Table.Body>
			{timesheets.map((timesheet) => (
				<TableRow key={timesheet.id} timesheet={timesheet} />
			))}
		</Table.Body>
	);
};

export default TableBody;
