import { gql, TypedDocumentNode } from '@apollo/client';

const DELETE_LOCATION_MUTATION: TypedDocumentNode<
	{
		deleteLocation: { id: string };
	},
	{ locationId: string }
> = gql`
	mutation deleteCurrentLocation($locationId: ID!) {
		deleteLocation(locationId: $locationId) {
			id
		}
	}
`;

export default DELETE_LOCATION_MUTATION;
