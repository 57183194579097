import React, { ReactElement } from 'react';

import Modal from '.';
import { TDialogModal } from './body';

export type TUncontrolledDialog = TDialogModal;

const UncontrolledDialog = (props: TUncontrolledDialog): ReactElement => {
	const { children, ...rest } = props;

	return <Modal.DialogModal {...rest}>{children}</Modal.DialogModal>;
};

export default UncontrolledDialog;
