import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJob } from 'Models/jobs/isJob';
import { toDisplayString } from 'Models/units/transpileUnits';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import Card from 'UI/surfaces/Card';

import useManageHistory from 'Hooks/useManageHistory';

import getProductConsumptionFromJobs from '../utils/getProductConsumptionFromJobs';
import ProductsChart from './ProductsChart';

type TProductsConsumption = {
	jobs?: Pick<
		TJob,
		'treatments' | 'jobFields' | 'driversWithEquipmentConfig'
	>[];
};

const ProductsConsumption = (props: TProductsConsumption) => {
	const { jobs } = props;
	const { t } = useTranslation();
	const sectionTitle = t('treatmentLogsExportPage.sprayLog.productConsumption');
	const manageHistory = useManageHistory();
	const filters = manageHistory.paramsFromURL.filters;

	const stats = jobs
		? getProductConsumptionFromJobs(jobs, filters).map((job) => (
				<Card
					sx={{
						marginY: '1.5rem',
					}}
					key={job.unit}
				>
					<Box padding="1.5rem">
						<Text variant="label2">
							{sectionTitle} ({toDisplayString(job.unit)})
						</Text>
						<ProductsChart products={job.products} />
					</Box>
				</Card>
		  ))
		: null;

	return <Box>{stats}</Box>;
};

export default ProductsConsumption;
