import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { sortByCreatedDateDesc } from 'Models/api/PageInput';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';

import useFieldID from 'Hooks/useFieldID';
import useLocationID from 'Hooks/useLocationID';

import RecentEvent from '../../../../../components/RecentEvent';
import getListedFields from '../../utils/getListedFields';
import GET_NOTIFICATIONS_QUERY from './api/getNotifications';
import styles from './styles.module.scss';

const limit = 10;

function RecentEvents() {
	const locationId = useLocationID();
	const fieldId = useFieldID();
	const { t } = useTranslation();
	const { data, loading, error, fetchMore } = useQuery(
		GET_NOTIFICATIONS_QUERY,
		{
			variables: {
				locationId,
				pageInput: {
					limit,
					offset: 0,
					sortBy: sortByCreatedDateDesc,
				},
				filterBy: fieldId ? { fieldId: String(fieldId) } : undefined,
			},
		}
	);

	const total = data?.getNotifications.totalElements || 0;
	const notifications = data?.getNotifications.notifications || [];
	const showLoadMoreButton = data && notifications.length < total;

	const loadMoreEvents = () =>
		void fetchMore({
			variables: {
				pageInput: {
					limit,
					offset: notifications.length,
					sortBy: sortByCreatedDateDesc,
				},
			},
		});

	if (error) {
		throw new ApolloError(error);
	}

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" padding="1rem">
				<LoadingSpinner width={40} height={40} />
			</Box>
		);
	}

	if (total === 0) {
		return (
			<Text padding="0.875rem" variant="secondaryBody2">
				{t('notifications.noRecentEvents')}
			</Text>
		);
	}

	if (notifications) {
		const list = notifications.map((notification) => {
			const fieldsString = getListedFields(notification)
				.map((field) => field.name)
				.join(', ');

			return (
				<>
					<Box key={notification.id} paddingY="0.875rem">
						<RecentEvent
							account={notification.createdBy}
							action={notification.type}
							createdAt={notification.createdAt}
							fields={fieldsString}
						/>
					</Box>
					<Divider />
				</>
			);
		});

		return (
			<Box
				data-testid="recent-events"
				key="events"
				className={styles.eventsList}
			>
				{list}
				{showLoadMoreButton ? (
					<Box display="flex" justifyContent="center" padding="1rem">
						<Button variant="text" onClick={loadMoreEvents}>
							{t('buttons.showMore')}
						</Button>
					</Box>
				) : null}
			</Box>
		);
	}

	return null;
}

export default RecentEvents;
