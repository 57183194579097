import React, { useEffect, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { CauseType, TCauseType, TCauseInput } from 'Models/causes/isCause';
import { TImage } from 'Models/image/isImage';
import Chip from 'UI/display/Chip';
import Modal from 'UI/display/modal/dialog';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextArea from 'UI/inputs/TextArea';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

import ImagesPreview from '../../../../components/ImagesPreview';
import ModalSection from '../../../../components/ModalSection';
import UploadImage, { TSelectedFile } from '../../../../components/UploadImage';
import { TPartnerCause } from '../types';

type TCauseModalTemplate = {
	isModalOpen: boolean;
	onCloseModal: () => void;
	onSaveHandler: (
		input: TCauseInput,
		images: TSelectedFile[],
		deleteImageIDs?: string[] | null
	) => void | Promise<void>;
	onBackHandler: () => void;
	isMutationLoading?: boolean;
	cause?: TPartnerCause;
};

const MAX_IMAGES_AMOUNT = 10;

const getDeletedImagesId = (
	curCauseImages: TImage[],
	stateCauseImages: TImage[]
) =>
	stateCauseImages.length < curCauseImages.length
		? curCauseImages
				.map((image) => String(image.id))
				.filter(
					(id) =>
						!stateCauseImages.some((newImage) => String(newImage.id) === id)
				)
		: null;

export default function CauseModalContainer(props: TCauseModalTemplate) {
	const {
		isModalOpen,
		onCloseModal,
		onSaveHandler,
		onBackHandler,
		isMutationLoading,
		cause,
	} = props;

	const [nameError, setNameError] = useState<boolean>(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [causeType, setCauseType] = useState<TCauseType | undefined>(undefined);
	const [selectedImages, setSelectedImages] = useState<TSelectedFile[]>([]);
	const [stateCauseImages, setStateCauseImages] = useState<TImage[]>([]);

	useEffect(() => {
		if (cause) {
			const { name, causeType, description, causeImages } = cause;
			setName(name);
			setCauseType(causeType);
			setStateCauseImages(causeImages || []);
			setDescription(description || '');
		} else {
			setName('');
			setDescription('');
			setCauseType(undefined);
			setStateCauseImages([]);
		}
		setSelectedImages([]);
	}, [cause, isModalOpen]);

	const { t } = useTranslation();

	const handleNameChange = (newValue: string) => {
		setNameError(newValue.length === 0);
		setName(newValue);
	};

	const handleDescriptionChange = (newValue: string) => {
		setDescription(newValue);
	};

	const handleCauseTypeChange = (newValue: TCauseType) => {
		setCauseType(newValue);
	};

	const closeModal = () => {
		onCloseModal();
	};

	const canCreateCause =
		name.length > 0 && !nameError && causeType && !isMutationLoading;
	const canEditCause =
		canCreateCause &&
		cause &&
		(name !== cause.name ||
			causeType !== cause.causeType ||
			description !== cause.description ||
			selectedImages.length ||
			stateCauseImages.length !== cause.causeImages?.length);

	const causeTypes = Object.values(CauseType).map((_causeType, i) => (
		<Grid item key={_causeType}>
			<Chip
				chipColor={causeType === _causeType ? FarmableColors.ORANGE : undefined}
				key={`${_causeType}-${i}`}
				label={t(`common.jobs.treatments.${_causeType}`)}
				onClick={() => handleCauseTypeChange(_causeType)}
			/>
		</Grid>
	));

	const onSave = () => {
		if (canCreateCause || canEditCause) {
			const input = {
				name,
				description,
				type: causeType,
			};
			void onSaveHandler(
				input,
				selectedImages,
				getDeletedImagesId(cause?.causeImages || [], stateCauseImages)
			);
		}
	};

	const onDeleteImage = (index: number) => {
		setStateCauseImages((prevState) => {
			return prevState?.filter((_, fileIndex) => fileIndex !== index);
		});
	};

	return (
		<>
			<Modal.UncontrolledModal toggleOpen={closeModal} open={isModalOpen}>
				<Modal.DialogTitle
					title={t('causesPage.modal.title')}
					closeIconHandler={closeModal}
				/>
				<Modal.DialogContent>
					<Box paddingBottom="1.5rem">
						<ModalSection title={t('labels.name')}>
							<TextField
								onChange={handleNameChange}
								error={nameError}
								value={name}
							/>
						</ModalSection>
						<ModalSection title={t('labels.causeType')}>
							<Grid mt="0" container spacing={1}>
								{causeTypes}
							</Grid>
						</ModalSection>
						<ModalSection title={t('labels.description')}>
							<TextArea
								value={description}
								placeholder={t('labels.description')}
								onChange={handleDescriptionChange}
								rows={3}
							/>
						</ModalSection>
						<ModalSection noDivider title={t('labels.photos')}>
							<Box display="flex">
								<UploadImage
									selectedImages={selectedImages}
									selectedImagesControl={setSelectedImages}
									maxAmount={MAX_IMAGES_AMOUNT}
								/>
								{stateCauseImages.length ? (
									<ImagesPreview
										images={stateCauseImages}
										imagesAlt={t('common.uploadImages.altText')}
										onDelete={onDeleteImage}
									/>
								) : null}
							</Box>
						</ModalSection>
					</Box>
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Button variant="text" onClick={onBackHandler}>
							{t('buttons.back')}
						</Button>
						<Box display="flex" flexDirection="row" alignItems="center">
							<Button
								variant="outlined"
								disabled={cause ? !canEditCause : !canCreateCause}
								onClick={onSave}
								startIcon={
									isMutationLoading ? <LoadingIcon fontSize="small" /> : null
								}
							>
								{t('buttons.save')}
							</Button>
						</Box>
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}
