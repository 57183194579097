import React, { FC, useCallback } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProduct } from 'Models/products/Product';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';
import { enqueueSnackbar } from 'notistack';

import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import {
	addLibraryTreatmentInCache,
	CREATE_LIBRARY_TREATMENT,
	UPDATE_TREATMENT_LIBRARY_PRODUCT,
} from '../../../../api';
import { ProductDetails } from '../CreateTreatmentModal/components/ModalContent/components/ProductDetails';
import {
	addTreatmentPageState,
	clearState,
} from '../CreateTreatmentModal/state';
import { makeProductInput } from '../utils';

interface EditProductModalProps {
	product: TProduct;
	onClose: () => void;
}
export const EditProductModal: FC<EditProductModalProps> = ({
	product,
	onClose,
}) => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const libraryId = getTreatmentLibraryIdFromURL();

	const [updateTreatmentsLibraryProduct] = useMutation(
		UPDATE_TREATMENT_LIBRARY_PRODUCT
	);

	const [createProductTreatment] = useMutation(CREATE_LIBRARY_TREATMENT, {
		update: addLibraryTreatmentInCache,
	});

	const handleSaveClick = useCallback(() => {
		if (state.input.selectedGovernmentProduct) {
			state.input.product.treatments?.map(
				(treatment) =>
					void createProductTreatment({
						variables: {
							libraryId,
							productId: product.id,
							input: treatment,
						},
					})
			);
		} else {
			void updateTreatmentsLibraryProduct({
				variables: {
					libraryId,
					productId: product.id,
					input: makeProductInput(state.input.product as TProduct),
				},
			})
				.then(() => {
					enqueueSnackbar(
						t('treatmentLibrary.addTreatmentModal.updateProduct.success'),
						{
							variant: 'success',
						}
					);
					clearState();
					onClose();
				})
				.catch(() => {
					enqueueSnackbar(
						t('treatmentLibrary.addTreatmentModal.updateProduct.failure'),
						{
							variant: 'error',
						}
					);
				});
		}
	}, [
		createProductTreatment,
		libraryId,
		onClose,
		product.id,
		state,
		t,
		updateTreatmentsLibraryProduct,
	]);

	return (
		<Modal.UncontrolledModal toggleOpen={onClose} open={true}>
			<Modal.DialogTitle
				title={t(
					product?.governmentProductSpec?.governmentProductId
						? 'buttons.viewTreatmentLibraryProduct'
						: 'buttons.editProductLibrary'
				)}
				closeIconHandler={onClose}
			/>
			<Modal.DialogContent
				sx={
					product?.governmentProductSpec?.governmentProductId
						? undefined
						: { display: 'flex', flexDirection: 'column' }
				}
			>
				<ProductDetails />
			</Modal.DialogContent>
			{!product?.governmentProductSpec?.governmentProductId && (
				<Modal.DialogActions>
					<Button variant="text" onClick={onClose}>
						{t('buttons.cancel')}
					</Button>
					<Button variant="outlined" onClick={handleSaveClick} disabled={false}>
						{t('buttons.save')}
					</Button>
				</Modal.DialogActions>
			)}
		</Modal.UncontrolledModal>
	);
};
