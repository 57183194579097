import React, { ReactElement } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJob } from 'Models/jobs/isJob';
import Grid from 'UI/layout/Grid';

import ImagesPreview from '../../../../../components/ImagesPreview';
import DetailModalSection from '../../../../../components/ModalSection';

type TPhotosProps = {
	job: Pick<TJob, 'productsImages'>;
};

const Photos = (props: TPhotosProps): ReactElement | null => {
	const { job } = props;
	const { t } = useTranslation();
	const prefix = 'logsPages.jobLogsPage.detailsModal.';

	return (
		<DetailModalSection title={t(`${prefix}sections.photos`)}>
			<Grid container spacing={1}>
				<Grid item>
					{job.productsImages?.length ? (
						<ImagesPreview
							images={job.productsImages}
							imagesAlt={t(`${prefix}attachedPhotosAlt`)}
						/>
					) : (
						t('labels.noPhotos')
					)}
				</Grid>
			</Grid>
		</DetailModalSection>
	);
};

export default Photos;
