import { addDays } from 'date-fns';
import format from 'date-fns/format';

import toLocalDate from '../dates/toLocalDate';
import { TJob } from './isJob';
import { TJobTreatment } from './isJobTreatment';
import jobWithholdingPeriod from './jobWithholdingPeriod';

function jobEarliestHarvestPermitted(
	job: Pick<TJob, 'completedDate'> & {
		treatments: Pick<TJobTreatment, 'amount' | 'unit'>[];
	}
): string {
	const withholdingPeriod = jobWithholdingPeriod(job);
	return job.completedDate && withholdingPeriod
		? format(
				addDays(toLocalDate(job?.completedDate), withholdingPeriod),
				'dd.MM.yy'
		  )
		: '-';
}

export default jobEarliestHarvestPermitted;
