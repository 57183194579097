import React from 'react';

import Text from 'UI/display/Text';

import FailureMessage from '../FailureMessage';

type TFailureFallbackProps = { error: unknown; title: string };

const FailureFallback = (props: TFailureFallbackProps) => {
	const { error, title } = props;

	const errorBlock =
		error instanceof Error ? (
			<React.Fragment>
				<Text>
					({error.name}) {error.message}
				</Text>
			</React.Fragment>
		) : null;

	return <FailureMessage message={title}>{errorBlock}</FailureMessage>;
};

export default FailureFallback;
