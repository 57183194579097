import React, {
	ReactNode,
	ReactElement,
	Fragment,
	useLayoutEffect,
	useState,
} from 'react';

type TPageAccessValidatorProps = {
	children: ReactNode;
	fallback: ReactNode;
	displayContent: boolean | null;
};

function PageAccessValidator(props: TPageAccessValidatorProps): ReactElement {
	const { children, fallback, displayContent } = props;
	const [refresh, setRefresh] = useState<boolean | null>(displayContent);

	const triggerRefresh = () => setRefresh(displayContent);

	useLayoutEffect(triggerRefresh, [displayContent]);

	return <Fragment>{refresh ? children : fallback}</Fragment>;
}

export default PageAccessValidator;
