import { FarmableColors } from 'UI/theme/Colors';

import numberToDisplayString from 'Utils/numberToDisplayString';

const ZERO_KELVIN_IN_CELSIUS = 273.15;
const ZERO_KELVIN_IN_FAHRENHEIT = 459.67;

const parseKelvinToFahrenheit = (tempValue: number) =>
	(tempValue * 9) / 5 - ZERO_KELVIN_IN_FAHRENHEIT;

type TTemperature = {
	tempColor: FarmableColors;
	tempValue: string;
};

export default function (
	temperature: number,
	isImperial?: boolean | null
): TTemperature {
	return isImperial
		? {
				tempColor:
					parseKelvinToFahrenheit(temperature) <= 32
						? FarmableColors.BLUE
						: FarmableColors.RED,
				tempValue:
					numberToDisplayString(parseKelvinToFahrenheit(temperature), 0) + '°F',
		  }
		: {
				tempColor:
					temperature - ZERO_KELVIN_IN_CELSIUS <= 0
						? FarmableColors.BLUE
						: FarmableColors.RED,
				tempValue:
					numberToDisplayString(temperature - ZERO_KELVIN_IN_CELSIUS, 0) + '°C',
		  };
}
