import React from 'react';

import { useReactiveVar } from '@apollo/client';
import Stepper from 'UI/display/Stepper';
import Box from 'UI/layout/Box';

import { FieldImportsStatus } from '../../models';
import { fieldImportsPageState } from '../../state';
import { TFieldImportsStatuses } from '../../types';

const FIELD_IMPORTS_STEPS = ['Upload shape file', 'Edit fields', 'Review'];

function getActiveStep(status: TFieldImportsStatuses): number {
	switch (status) {
		case FieldImportsStatus.ADD_FIELDS_FORM:
			return 0;
		case FieldImportsStatus.ADD_FIELDS_SUCCESS:
			return 0;
		case FieldImportsStatus.ADD_FIELDS_FAILURE:
			return 0;
		case FieldImportsStatus.ADD_FIELDS_LOADING:
			return 0;
		case FieldImportsStatus.EDIT_FIELDS:
			return 1;
		case FieldImportsStatus.REVIEW_FIELDS:
			return 2;
		case FieldImportsStatus.CREATE_FIELDS_SUCCESS:
			return 2;
		case FieldImportsStatus.CREATE_FIELDS_LOADING:
			return 2;
		case FieldImportsStatus.CREATE_FIELDS_FAILURE:
			return 2;

		default:
			// eslint-disable-next-line
			const exhaustiveCheck: never = status;
			return 0;
	}
}

const FieldImportsStepper = () => {
	const state = useReactiveVar(fieldImportsPageState);
	const activeStep = getActiveStep(state.status);

	return (
		<Box padding="1.5rem">
			<Stepper activeStep={activeStep} steps={FIELD_IMPORTS_STEPS} />
		</Box>
	);
};

export default FieldImportsStepper;
