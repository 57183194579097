const SUCCESS = 'success' as const;
const CANCELED = 'canceled' as const;
const REJECTED = 'rejected' as const;

export const AllOrderStatuses = {
	SUCCESS,
	CANCELED,
	REJECTED,
} as const;

export type TOrderStatus =
	typeof AllOrderStatuses[keyof typeof AllOrderStatuses];
export const isOrderStatus = (
	status?: string | null
): status is TOrderStatus => {
	return status === SUCCESS || status === CANCELED || status === REJECTED;
};
