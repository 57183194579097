import { ReactNode } from 'react';

const PartnerLogsTableHeaderValues = [
	{ id: 'completedDate', numeric: false },
	{ id: 'category', numeric: false },
	{ id: 'farm', numeric: false },
	{ id: 'area', numeric: true },
	{ id: 'cause', numeric: false },
	{ id: 'treatmentType', numeric: false },
	{ id: 'product', numeric: false },
	{ id: 'recommendedBy', numeric: false },
	{ id: 'jobStatus', numeric: false },
] as const;

export type TJobLogsTableHeader = typeof PartnerLogsTableHeaderValues[number];

export type TPartnerJobLogsTableData = {
	[key in TJobLogsTableHeader['id']]: ReactNode;
};
export type TJobLogsTableHeaders = keyof TPartnerJobLogsTableData;

export default PartnerLogsTableHeaderValues;
