import { TField } from 'Models/notification/isEntity';
import { TNotification } from 'Models/notification/isNotification';
import { NotificationType } from 'Models/notification/isNotificationType';

const getListedFields = (notification: TNotification): TField[] => {
	const {
		JOB_RECOMMENDED,
		JOB_COMPLETED,
		JOB_ASSIGNED,
		JOB_NOTE_CREATED,
		TIMESHEET_CLOCKED_OUT,
		HARVEST_CREATED,
		FIELD_NOTE_COMMENT_CREATED,
		FIELD_NOTE_CREATED,
	} = NotificationType;

	switch (notification.type) {
		// Types that are able to refer to one field only
		case FIELD_NOTE_CREATED:
			return [notification.entity.fieldNoteField];
		case FIELD_NOTE_COMMENT_CREATED:
			return [notification.parentEntity.fieldNoteField];

		// Types that are able to refer to multiple fields
		case HARVEST_CREATED:
			return notification.entity.harvestFields;
		case TIMESHEET_CLOCKED_OUT:
			return notification.entity.timesheetFields;
		case JOB_NOTE_CREATED:
			return notification.parentEntity.jobFields;
		case JOB_ASSIGNED:
		case JOB_COMPLETED:
		case JOB_RECOMMENDED:
			return notification.entity.jobFields;

		default:
			throw new Error(
				`Unknown notification type: ${JSON.stringify(notification)}`
			);
	}
};
export default getListedFields;
