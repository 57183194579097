import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldInput } from 'Models/fields/isFieldInput';
import isFieldInput from 'Models/fields/isFieldInput';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import isArrayOf from 'vet/arrays/isArrayOf';

import { fieldImportsPageState } from '../../../../state';
import ContentLayout from '../ContentLayout';
import PreviewContent from './PreviewContent';

type TImportedFieldsPreviewProps = {
	fieldsInput: TFieldInput[];
};

const ImportedFieldsPreview = (props: TImportedFieldsPreviewProps) => {
	const { fieldsInput } = props;

	const { t } = useTranslation();

	const title = (
		<Text variant="h3" component="h2">
			{t('importPages.fieldImportsPage.editFieldsStep.title')}
		</Text>
	);

	const description = (
		<Text variant="body1" color="textSecondary">
			{t('importPages.fieldImportsPage.editFieldsStep.description')}
		</Text>
	);

	const isEditFieldsInput = isArrayOf(isFieldInput);

	const handlePreviousArrow = () =>
		fieldImportsPageState({ status: 'ADD_FIELDS_SUCCESS', fieldsInput });

	const handleNextArrow = isEditFieldsInput(fieldsInput)
		? () => fieldImportsPageState({ status: 'REVIEW_FIELDS', fieldsInput })
		: undefined;

	return (
		<ContentLayout
			previousArrowHandler={handlePreviousArrow}
			nextArrowHandler={handleNextArrow}
		>
			<Box marginTop={2}>
				{title}
				<Box paddingTop={2} paddingBottom={4}>
					{description}
				</Box>
			</Box>
			<PreviewContent fieldsInput={fieldsInput} />
		</ContentLayout>
	);
};

export default ImportedFieldsPreview;
