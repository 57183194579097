import { TPartnerPlannerJob } from 'Models/planner/PartnerPlannerJob';

export default function getJobInputData(
	job: Pick<
		TPartnerPlannerJob,
		'variety' | 'jobDetails' | 'dueDate' | 'comment'
	>
) {
	return {
		variety: job.variety,
		comment: job.comment,
		dueDate: job.dueDate,
		jobDetails: {
			jobType: job.jobDetails.jobType,
			causeIds: job.jobDetails.causes?.map((cause) => cause.id) || [],
			justificationIds:
				job.jobDetails.justifications?.map(
					(justification) => justification.id
				) || [],
			treatments: job.jobDetails.treatments.map((treatment) => ({
				amount: treatment.amount,
				unit: treatment.unit,
				originalTreatmentId: treatment.originalTreatmentId,
				productName: treatment.originalProduct.name,
				id: treatment.id,
			})),
		},
	};
}
