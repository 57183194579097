import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { THarvest } from 'Models/harvest/types/Harvest';
import Grid from 'UI/layout/Grid';

import DetailModalSection from '../../../../../components/ModalSection';

type QualityProps = {
	harvest: Pick<THarvest, 'quality'>;
};

const Quality = (props: QualityProps) => {
	const { harvest } = props;
	const { t } = useTranslation();
	const title = t(`logsPages.harvestLogsPage.detailsModal.sections.quality`);

	return (
		<DetailModalSection title={title}>
			<Grid container spacing={1}>
				<Grid item>{harvest.quality}</Grid>
			</Grid>
		</DetailModalSection>
	);
};

export default Quality;
