import {
	isSupportedLang,
	SupportedLangs,
	TSupportedLang,
} from './isSupportedLang';

export function findSupportedLanguage(
	languages: readonly string[]
): TSupportedLang {
	const supportedLang = languages.find((lang): lang is TSupportedLang =>
		isSupportedLang(lang)
	);

	return supportedLang || SupportedLangs.en;
}
