import React from 'react';

import { useQuery } from '@apollo/client';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import NoLogsStatement from '../../../../common/components/NoLogsStatement';
import GET_HARVEST_TABLE_DATA from '../../../api/getHarvestTableData';
import GET_TEAMS_FOR_HARVEST_TABLE_DATA from '../../../api/getTeamsForHarvestData';
import getQueryVariables from '../../../utils/getQueryVariables';
import HarvestLogsTableRow from './TableRow';

const TableBody = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const {
		data: harvestData,
		loading: isHarvestDataLoading,
		error: harvestQueryError,
	} = useQuery(GET_HARVEST_TABLE_DATA, {
		variables: getQueryVariables(searchParams),
	});

	const {
		data: teamsData,
		loading: isTeamsDataLoading,
		error: teamsQueryError,
	} = useQuery(GET_TEAMS_FOR_HARVEST_TABLE_DATA, {
		variables: {
			locationID: searchParams.locationID,
		},
	});

	if (isHarvestDataLoading || isTeamsDataLoading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	if (harvestQueryError) {
		throw harvestQueryError;
	}

	if (teamsQueryError) {
		throw teamsQueryError;
	}

	if (!harvestData?.getHarvest.harvest?.length) {
		return (
			<NoDataTableContainer>
				<NoLogsStatement logsType="Jobs" />
			</NoDataTableContainer>
		);
	}

	const harvestsWithTeam = harvestData.getHarvest.harvest.map((harvest) => {
		if (harvest.teamIds?.length) {
			const teams =
				teamsData?.getTeamsPageable.teams.filter((team) =>
					harvest.teamIds?.some((teamId) => teamId === team.id)
				) || [];

			return { ...harvest, teams };
		}

		return { ...harvest, teams: [] };
	});

	return (
		<Table.Body>
			{harvestsWithTeam.map((harvest) => (
				<HarvestLogsTableRow key={harvest.id} harvest={harvest} />
			))}
		</Table.Body>
	);
};

export default TableBody;
