import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import harvestDateString from 'Models/harvest/harvestDateString';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';

import Comment from '../../../common/components/HarvestDetailsModal/CommentSection';
import Details from '../../../common/components/HarvestDetailsModal/Details';
import Photos from '../../../common/components/HarvestDetailsModal/Photos';
import Quality from '../../../common/components/HarvestDetailsModal/Quality';
import { detailsModalData, isDetailsModalOpen } from '../../state';
import Fields from './components/Fields';

const HarvestDetailsModal = () => {
	const { t } = useTranslation();
	const isOpen = useReactiveVar(isDetailsModalOpen);
	const data = useReactiveVar(detailsModalData);

	function onClose() {
		isDetailsModalOpen(false);
	}

	return (
		data && (
			<Modal.UncontrolledModal open={isOpen} toggleOpen={onClose}>
				<Modal.DialogTitle
					title={`${t('labels.harvest')} - ${harvestDateString(data)}`}
					closeIconHandler={onClose}
				/>
				<Modal.DialogContent>
					<Details harvest={data} />
					<Quality harvest={data} />
					<Photos harvest={data} />
					<Comment harvest={data} />
					<Fields harvest={data} />
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Button variant={'outlined'} onClick={onClose}>
						{t('buttons.close')}
					</Button>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		)
	);
};

export default HarvestDetailsModal;
