import z from 'zod';

type TCoordSchema = typeof isGeometryPoint;

const isGeometryPoint = z.tuple([z.number(), z.number()]);

// latlong format is (lat,long) which is (+/-90', +/-180') for global WGS84 system
// we use it as a fallback when no crs info is given
const isGlobalGeometryPoint = z.tuple([
	z.number().min(-90).max(90),
	z.number().min(-180).max(180),
]);

const isPolyArray = (coordSchema: TCoordSchema) =>
	z.array(z.array(coordSchema));

export const coordinatesType = {
	POLYGON: 'Polygon',
	MULTIPOLYGON: 'MultiPolygon',
	POINT: 'Point',
} as const;

const pointSchema = (coordSchema: TCoordSchema) =>
	z.object({
		type: z.literal(coordinatesType.POINT),
		coordinates: coordSchema,
	});

const polygonSchema = (coordSchema: TCoordSchema) =>
	z.object({
		type: z.literal(coordinatesType.POLYGON),
		coordinates: isPolyArray(coordSchema),
	});

const multiPolygonSchema = (coordSchema: TCoordSchema) =>
	z.object({
		type: z.literal(coordinatesType.MULTIPOLYGON),
		coordinates: z.array(isPolyArray(coordSchema)),
	});

const supportedGeometrySchema = z.union([
	polygonSchema(isGeometryPoint),
	multiPolygonSchema(isGeometryPoint),
]);

const geometrySchema = (coordSchema: TCoordSchema) =>
	z.union([
		polygonSchema(coordSchema),
		multiPolygonSchema(coordSchema),
		pointSchema(coordSchema),
	]);

const propertySchema = z
	.object({
		name: z.string().optional(),
		Name: z.string().optional(),
		NAME: z.string().optional(),
	})
	.optional();

const featureSchema = (coordSchema: TCoordSchema) =>
	z.object({
		geometry: geometrySchema(coordSchema),
		properties: propertySchema,
	});

const CRSSchema = z.object({
	properties: z.object({
		name: z.string(),
	}),
});

const GeoJSONFieldSchema = z
	.object({
		crs: CRSSchema,
		features: z.array(featureSchema(isGeometryPoint)),
	})
	.or(
		z.object({
			crs: z.undefined(),
			features: z.array(featureSchema(isGlobalGeometryPoint)),
		})
	);

export const isGeoJSONField = (field: unknown): field is TGeoJSONField =>
	GeoJSONFieldSchema.safeParse(field).success;

export const isSupportedGeometry = (
	geometry: unknown
): geometry is TSupportedGeometry =>
	supportedGeometrySchema.safeParse(geometry).success;

export type TGeoJSONField = z.infer<typeof GeoJSONFieldSchema>;
export type TSupportedGeometry = z.infer<typeof supportedGeometrySchema>;
export type TGeometryPoint = z.infer<typeof isGeometryPoint>;
export type TProperty = z.infer<typeof propertySchema>;
