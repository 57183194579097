import React, { useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TJob } from 'Models/jobs/isJob';
import { TNote } from 'Models/jobs/isNotes';
import sortJobNotes from 'Models/jobs/sortJobNotes';
import Button from 'UI/inputs/Button';
import ScrollableList from 'UI/layout/ScrollableList';

import useProfile from 'Hooks/useProfile';

import Comment from '../../../../../components/Comment';
import DetailModalSection from '../../../../../components/ModalSection';
import styles from '../styles.module.scss';

type TJobNotesCommentsProps = {
	notes: TNote[];
	maxCommentsCount: number;
};

const JobNotesComments = (props: TJobNotesCommentsProps) => {
	const { notes, maxCommentsCount } = props;
	const { profile } = useProfile();

	const comments = notes.map((note, index) => {
		const isYourPost = note.account.id === profile?.id;
		const commentsCount =
			notes.length < maxCommentsCount ? notes.length : maxCommentsCount;
		const isLast = index === commentsCount - 1;

		if (index < maxCommentsCount) {
			return (
				<Comment
					key={`note_${note.createdAt}`}
					isYourPost={isYourPost}
					isLast={isLast}
					createdAt={note.createdAt}
					account={note.account}
					note={note.note}
				/>
			);
		}
	});

	return <ScrollableList maxHeight={400}>{comments}</ScrollableList>;
};

type TPreviousCommentsButtonProps = {
	onClick: () => void;
	open: boolean;
};

const PreviousCommentsButton = (props: TPreviousCommentsButtonProps) => {
	const { onClick, open } = props;
	const { t } = useTranslation();

	function handleClick() {
		onClick();
	}

	return (
		<Button
			onClick={handleClick}
			className={styles.previousCommentsButton}
			variant="text"
		>
			{t(
				open
					? 'common.comments.hidePreviousComments'
					: 'common.comments.viewPreviousComments'
			)}
		</Button>
	);
};

type TCommentsProps = {
	job: Pick<TJob, 'notes'>;
};

const Comments = (props: TCommentsProps) => {
	const { job } = props;
	const { t } = useTranslation();

	const [displayAllComments, setDisplayAllComments] = useState(false);
	const MAXIMUM_COMMENTS_NUMBER = 2;

	const hasNotes = job.notes.length > 0;

	if (!hasNotes) {
		return null;
	}

	const sortedNotes = sortJobNotes(job.notes);
	const commentsCount = displayAllComments
		? sortedNotes.length
		: MAXIMUM_COMMENTS_NUMBER;

	const previousCommentsButton =
		sortedNotes.length > MAXIMUM_COMMENTS_NUMBER ? (
			<PreviousCommentsButton
				open={displayAllComments}
				onClick={togglePreviousComments}
			/>
		) : null;

	function togglePreviousComments() {
		setDisplayAllComments((prevState) => !prevState);
	}

	return (
		<DetailModalSection
			title={t('logsPages.jobLogsPage.detailsModal.sections.comments')}
		>
			{previousCommentsButton}
			<JobNotesComments maxCommentsCount={commentsCount} notes={sortedNotes} />
		</DetailModalSection>
	);
};

export default Comments;
