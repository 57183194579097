import { ReactNode } from 'react';

const PARTNER_HARVEST_LOGS_TABLE_HEADERS = [
	'date',
	'location',
	'field',
	'category',
	'variety',
	'boxes',
	'weight',
	'responsible',
	'teams',
	'teamMembers',
] as const;

const PartnerHarvestLogsTableHeaderValues = [
	...PARTNER_HARVEST_LOGS_TABLE_HEADERS,
];

export type TPartnerHarvestLogsTableHeader =
	typeof PARTNER_HARVEST_LOGS_TABLE_HEADERS[number];

export default PartnerHarvestLogsTableHeaderValues;

export type THarvestLogsTableData = {
	[key in typeof PARTNER_HARVEST_LOGS_TABLE_HEADERS[number]]: ReactNode;
};
