import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function StripePlaceholderIcon(props: SvgIconProps) {
	const dimension = 112;

	return (
		<SvgIcon
			viewBox="0 0 112 112"
			width={props.width || dimension}
			height={props.height || dimension}
			{...props}
		>
			<g fill="none" fillRule="evenodd">
				<path
					d="M94 31.5a4 4 0 0 1 4 4v55a4 4 0 0 1-4 4H18a4 4 0 0 1-4-4v-55a4 4 0 0 1 4-4zm-1.25 5.25h-73.5v52.5h73.5v-52.5zM42 61.375l10.5 4.25v8.75L42 70.125v-8.75z"
					fillOpacity=".12"
					fill="#D83E14"
				/>
				<path
					d="m42 31.5 13.819-14L70 31.5M19.25 89.25h73.5v-52.5h-73.5v52.5zM18 94.5h76a4 4 0 0 0 4-4v-55a4 4 0 0 0-4-4H18a4 4 0 0 0-4 4v55a4 4 0 0 0 4 4zm18.75-42v22.75l21 8.75V61.25l-21-8.75zm40.25 0v22.75L57.75 84V61.25L77 52.5zm-40.25 0 20.125-8.75L77 52.5m-28.547-5.084 19.427 9.236M42 61.375v8.75l10.5 4.25v-8.75L42 61.375z"
					stroke="#D83E14"
					strokeWidth="2"
					strokeLinejoin="round"
				/>
			</g>
		</SvgIcon>
	);
}

export default StripePlaceholderIcon;
