import { TStockUnits } from '../units';

export const MovementType = {
	PURCHASE: 'PURCHASE',
	RECOUNT: 'RECOUNT',
	DISPOSE: 'DISPOSE',
	CONSUMPTION: 'CONSUMPTION',
} as const;

export type TMovementType = keyof typeof MovementType;

export const ALL = Object.values(MovementType)
	// used type guard to get appropriate type for validator
	.filter((value): value is TMovementType => typeof value === 'string');

type TProductLite = {
	id: string;
	name: string;
	createdAt: string;
	discriminator: string;
};

export type TProductInventoryLog = {
	date: string;
	movementType: TMovementType;
	product: TProductLite;
	unit: TStockUnits;
	amountChange: number;
	valueChange: number;
	currency?: string;
	purchaseData?: {
		packageSize: number;
		packagePrice: number;
		numPackages: number;
		number: number;
	};
	comment?: string;
};
