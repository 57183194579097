import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function GoogleIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path
				fill="#EA4335"
				d="M10 3.867c1.878 0 3.144.81 3.867 1.489L16.689 2.6C14.956.989 12.7 0 10 0 6.089 0 2.711 2.244 1.067 5.511L4.3 8.022c.811-2.41 3.056-4.155 5.7-4.155z"
				transform="translate(-96 -574) translate(80 444) translate(16 130) translate(2 2)"
			/>
			<path
				fill="#4285F4"
				d="M19.6 10.222c0-.822-.067-1.422-.211-2.044H10v3.71h5.511c-.111.923-.711 2.312-2.044 3.245l3.155 2.445c1.89-1.745 2.978-4.311 2.978-7.356z"
				transform="translate(-96 -574) translate(80 444) translate(16 130) translate(2 2)"
			/>
			<path
				fill="#FBBC05"
				d="M4.311 11.978c-.218-.637-.331-1.305-.333-1.978 0-.689.122-1.356.322-1.978l-3.233-2.51C.367 6.903 0 8.441 0 10c0 1.611.389 3.133 1.067 4.489l3.244-2.511z"
				transform="translate(-96 -574) translate(80 444) translate(16 130) translate(2 2)"
			/>
			<path
				fill="#34A853"
				d="M10 20c2.7 0 4.967-.889 6.622-2.422l-3.155-2.445c-.845.59-1.978 1-3.467 1-2.644 0-4.889-1.744-5.689-4.155l-3.233 2.51C2.722 17.757 6.088 20 10 20z"
				transform="translate(-96 -574) translate(80 444) translate(16 130) translate(2 2)"
			/>
		</SvgIcon>
	);
}

export default GoogleIcon;
