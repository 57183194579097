import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import LoadingContent from '../../common/components/LoadingContent';
import GET_PROFILE from '../api/getProfile';
import ProfileCard from './ProfileCard';

function ProfileContent() {
	const { data, loading, error } = useQuery(GET_PROFILE);

	if (loading) {
		return <LoadingContent />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		return <ProfileCard profile={data.profile} />;
	}

	return null;
}

export default ProfileContent;
